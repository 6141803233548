import { JSONSchemaType } from 'ajv'
import { BloodPressureAverage, MovingAverage } from '../../types/blood-pressure-moving-average'

const movingAverageSchema: JSONSchemaType<MovingAverage> = {
  type: 'object',
  properties: {
    high: {
      type: 'number',
    },
    low: {
      type: 'number',
    },
    average: {
      type: 'number',
    },
  },
  required: ['high', 'low', 'average'],
}

const schema: JSONSchemaType<BloodPressureAverage> = {
  type: 'object',
  properties: {
    beatsPerMinuteAverage: movingAverageSchema,
    calculatedAtTime: {
      type: 'string',
    },
    daysAgo: {
      type: 'number',
    },
    diastolicAverage: movingAverageSchema,
    earliestTimestamp: {
      type: 'string',
    },
    errorInfo: {
      type: 'string',
      nullable: true,
    },
    latestTimestamp: {
      type: 'string',
    },
    systolicAverage: movingAverageSchema,
  },
  required: [
    'beatsPerMinuteAverage',
    'calculatedAtTime',
    'daysAgo',
    'diastolicAverage',
    'earliestTimestamp',
    'latestTimestamp',
    'systolicAverage',
  ],
}

export default schema

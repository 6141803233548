import { ReactElement } from 'react'
import clsx from 'clsx'
import { ButtonVariant, MenuButton } from '../../../ui/button'
import { SvgIcon } from '../../../ui/icon'
import { ReactComponent as ChevronIcon } from '../../../icons/solid/chevron-down.svg'
import { useAppSelector } from '../../../../redux'
import { Menu } from '../../../ui/menu'
import TaskStatusMenuItems from '../task-status-menu-items'
import StatusIndicator from '../task-item/status-indicator'
import { getTaskStatus, isTaskClosed } from '../../../../redux/selectors/tasks'
import { getStatusText } from '../../../../utils/tasks'

interface Props {
  listId: string
  taskId: string
}

const I13N_VIEW = {
  View: 'Task Details',
}

const TaskDetailsStatus = ({ listId, taskId }: Props): ReactElement => {
  const status = useAppSelector((state) => getTaskStatus(state, taskId))
  const isClosed = useAppSelector((state) => isTaskClosed(state, taskId))

  return (
    <Menu
      menuButton={({ open }) => (
        <MenuButton
          active={open}
          className="mt-2 !px-3 max-w-full"
          disabled={isClosed}
          variant={ButtonVariant.SECONDARY}
        >
          <StatusIndicator taskId={taskId} />
          <span className="mx-2 truncate">{getStatusText(status)}</span>
          {isClosed ? null : (
            <SvgIcon
              className={clsx('flex-none transition-transform ease-in-out', {
                '-rotate-180': open,
              })}
              Icon={ChevronIcon}
            />
          )}
        </MenuButton>
      )}
    >
      <TaskStatusMenuItems i13n={I13N_VIEW} includeCompleted listId={listId} taskId={taskId} />
    </Menu>
  )
}

export default TaskDetailsStatus

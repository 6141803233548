import useFlag from '../../../hooks/use-flag'
import { useAppSelector } from '../../../redux'
import { getPatientMedicalRecordSource } from '../../../redux/selectors/patient'
import { Features } from '../../../types/features'
import { MedicalRecordSource } from '../../../types/patient'

const useIsTabEnabled = (
  flag: Features.ALLERGIES_TAB | Features.MEDICATIONS_TAB | Features.MEDICATIONS_PAID_TAB | Features.PROBLEMS_TAB,
  patientId: string,
): boolean => {
  const hasAthenaProfile = useAppSelector(
    (state) => getPatientMedicalRecordSource(state, patientId) === MedicalRecordSource.ATHENA,
  )

  return useFlag(flag) && hasAthenaProfile
}

export default useIsTabEnabled

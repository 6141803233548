import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import { context, WindowSize } from './context'

const WindowSizeProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const screenSize: WindowSize = useMemo(() => ({ width, height }), [width, height])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    const debouncedHandleResize = debounce(handleResize, 500)

    window.addEventListener('resize', debouncedHandleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return <context.Provider value={screenSize}>{children}</context.Provider>
}

export default WindowSizeProvider

import { ReactElement, ReactNode } from 'react'
import { Button, ButtonVariant } from '../ui/button'
import { Modal } from '../ui/modal'
import { ModalContainer } from '../ui/modal/modal-container'
import ModalFooter from '../ui/modal/modal-footer'
import ModalHeader from '../ui/modal/modal-header'

interface Props {
  children?: ReactNode
  confirmButtonText?: string
  confirmButtonVariant?: ButtonVariant
  disabled?: boolean
  isOpen: boolean
  onAccept: () => void
  onCancel: () => void
  subtitle?: string
  title: string
}

function noop() {
  /* just to appease the type */
}

const Confirm = ({
  title,
  subtitle,
  children,
  disabled,
  isOpen,
  onAccept,
  onCancel,
  confirmButtonText = 'Accept',
  confirmButtonVariant = ButtonVariant.ACTION,
}: Props): ReactElement => (
  <Modal shouldCloseOnOverlayClick={false} isOpen={isOpen} closeModal={noop}>
    <ModalContainer>
      <ModalHeader subtitle={subtitle} title={title}></ModalHeader>
      {children && <main className="mx-10">{children}</main>}
      <ModalFooter>
        <Button className="mr-3" variant={ButtonVariant.SECONDARY} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={disabled} variant={confirmButtonVariant} onClick={onAccept}>
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </ModalContainer>
  </Modal>
)

export default Confirm

import { produce } from 'immer'
import { Reducer } from 'redux'
import {
  ChatState,
  FETCH_CHAT_TOKEN_FAILED,
  FETCH_CHAT_TOKEN_FULFILLED,
  FetchChatTokenFailed,
  FetchChatTokenFulfilled,
  FETCHING_CHAT_TOKEN,
  FetchingChatToken,
} from '../../types/chat'
import { RecordState } from '../../types/record-state'

type ChatActions = FetchingChatToken | FetchChatTokenFailed | FetchChatTokenFulfilled

const chatReducer = produce(
  (draft: ChatState, action: ChatActions) => {
    switch (action.type) {
      case FETCHING_CHAT_TOKEN:
        draft.recordState = RecordState.LOADING

        return
      case FETCH_CHAT_TOKEN_FULFILLED: {
        const { token, expiresAt } = action.payload

        draft.recordState = RecordState.LOADED
        draft.token = token
        draft.expiresAt = expiresAt

        return
      }
      case FETCH_CHAT_TOKEN_FAILED:
        draft.recordState = RecordState.ERRORED

        return
    }
  },
  { token: '', recordState: RecordState.DOES_NOT_EXIST, expiresAt: 0 },
)

export const chat: Reducer<ChatState, ChatActions> = chatReducer

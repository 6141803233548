import { MouseEventHandler, ReactElement, useCallback, useEffect, useRef } from 'react'
import clsx from 'clsx'
import useTooltip from '../../../../hooks/use-tooltip'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getTaskOptions, getTaskStatus } from '../../../../redux/selectors/tasks'
import Tooltip from '../../../ui/tooltip'
import { isTaskStatusClosed } from '../../../../utils/tasks'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as ChevronIcon } from '../../../icons/solid/chevron-down.svg'
import ButtonGroup from '../../../ui/button-group'
import { Button, ButtonSize, ButtonVariant, MenuButton } from '../../../ui/button'
import { updateTaskStatus } from '../../../../redux/thunks/tasks'
import { TaskStatus } from '../../../../types/task'
import useTasksApi from '../../../../api/hooks/use-tasks-api'
import { ControlledMenu, useMenuState } from '../../../ui/menu'
import useOnClickOutside from '../../../../hooks/use-on-click-outside'
import TaskStatusMenuItems from '../task-status-menu-items'

interface Props {
  isScrolling?: boolean
  listId: string
  taskId: string
}

const I13N_VIEW = {
  View: 'Task List',
}

const StatusSelector = ({ isScrolling, listId, taskId }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLElement>(null)
  const status = useAppSelector((state) => getTaskStatus(state, taskId))
  const { closeMenu, menuProps, toggleMenu } = useMenuState()
  const options = useAppSelector((state) => getTaskOptions(state, taskId))
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    disabled: menuProps.state === 'open',
    offset: [-12, 6],
    placement: 'bottom-start',
  })

  const markAsCompleted = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      dispatch(
        updateTaskStatus(
          {
            id: taskId,
            listId,
            status: TaskStatus.TaskCompleting,
          },
          tasksApi,
        ),
      )
    },
    [dispatch, listId, taskId, tasksApi],
  )

  const onOverflowMenuClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      toggleMenu()
    },
    [toggleMenu],
  )
  const stopPropagation = useCallback<MouseEventHandler>((event) => event.stopPropagation(), [])

  useOnClickOutside(menuRef, closeMenu)

  useEffect(() => {
    if (isScrolling) {
      closeMenu()
    }
  }, [closeMenu, isScrolling])

  const { ref: triggerRef, ...triggerProps } = getTriggerProps()

  if (options.length) {
    return null
  }

  return (
    <ButtonGroup>
      <Button
        variant={ButtonVariant.SECONDARY}
        size={ButtonSize.XXS}
        disabled={isTaskStatusClosed(status)}
        onClick={markAsCompleted}
      >
        Mark as completed
      </Button>
      <MenuButton
        {...triggerProps}
        active={menuProps.state === 'open'}
        aria-label="Change status"
        ref={(el) => {
          triggerRef(el)

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          menuButtonRef.current = el
        }}
        onClick={onOverflowMenuClick}
        variant={ButtonVariant.SECONDARY}
        size={ButtonSize.XXS}
        className="!px-1"
      >
        <SvgIcon
          Icon={ChevronIcon}
          size={IconSize.X_SMALL}
          className={clsx('transition-transform ease-in-out', {
            '-rotate-180': menuProps.state === 'open',
          })}
        />
        <Tooltip className="z-50" {...getTooltipProps()} arrowProps={getArrowProps()}>
          Change status
        </Tooltip>
      </MenuButton>
      <ControlledMenu
        {...menuProps}
        anchorRef={menuButtonRef}
        align="start"
        className="cursor-default z-10"
        onClick={stopPropagation}
        ref={menuRef}
      >
        <TaskStatusMenuItems i13n={I13N_VIEW} listId={listId} onClose={closeMenu} taskId={taskId} />
      </ControlledMenu>
    </ButtonGroup>
  )
}

export default StatusSelector

import { Person } from './person'
import { RecordState } from './record-state'

export const FETCHING_PRACTITIONER = 'FETCHING_PRACTITIONER'
export const FETCH_PRACTITIONER_FULFILLED = 'FETCH_PRACTITIONER_FULFILLED'
export const FETCH_PRACTITIONER_FAILED = 'FETCH_PRACTITIONER_FAILED'

export interface Practitioner {
  id: string
  personId: string
  organizationId: string
  images: {
    small: string
    medium: string
    large: string
  }
  isActive: boolean
  role: PractitionerRoles
  prefix: string
  suffix: string
}

export interface PractitionerResponse {
  practitioner: Practitioner[]
  persons: Person[]
}

export enum PractitionerRoles {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export interface PractitionerState {
  recordState: RecordState
  practitionerMap: PractitionerMap
  personIdToPractitionerIdMap: PersonIdToPractitionerIdMap
}

export interface PractitionerMap {
  [practitionerId: string]: Practitioner
}

export interface PersonIdToPractitionerIdMap {
  [personId: string]: string
}

export interface PractitionerNameAndId {
  name: string
  id: string
}

export enum PractitionerPhotoSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

import clsx from 'clsx'
import { DateTime } from 'luxon'
import { ReactElement, useMemo } from 'react'

interface Props extends Intl.DateTimeFormatOptions {
  className?: string
  'data-testid'?: string
  titleVisible?: boolean
  value: string
}

const FormattedDateTime = ({
  className,
  'data-testid': testId,
  titleVisible = false,
  value,
  ...options
}: Props): ReactElement => {
  const parsed = useMemo(() => DateTime.fromISO(value), [value])

  return (
    <time
      className={clsx('tabular-nums', className)}
      data-testid={testId}
      data-timestamp={value}
      title={
        titleVisible
          ? parsed.toLocaleString({
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
              timeZoneName: 'short',
            })
          : undefined
      }
    >
      {parsed.toLocaleString(options)}
    </time>
  )
}

export default FormattedDateTime

export const CODING_SYSTEM_SNOMED = 'http://snomed.info/sct'

export const NO_KNOWN_ALLERGY = '716186003'
export const NO_KNOW_HISTORY_OF_DRUG_ALLERGY = '409137002'
export const NO_KNOWN_INSECT_ALLERGY = '428197003'
export const NO_KNOWN_ENVIRONMENTAL_ALLERGY = '428607008'
export const NO_KNOWN_FOOD_ALLERGY = '429625007'
export const NO_KNOWN_ANIMAL_ALLERGY = '716220001'
export const NO_KNOWN_LATEX_ALLERGY = '1003774007'

export const NoKnownAllergies = [
  NO_KNOWN_ALLERGY,
  NO_KNOW_HISTORY_OF_DRUG_ALLERGY,
  NO_KNOWN_INSECT_ALLERGY,
  NO_KNOWN_ENVIRONMENTAL_ALLERGY,
  NO_KNOWN_FOOD_ALLERGY,
  NO_KNOWN_ANIMAL_ALLERGY,
  NO_KNOWN_LATEX_ALLERGY,
]

export const ENTERED_IN_ERROR_CODE = 'entered-in-error'

export enum MedicationStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  DRAFT = 'draft',
  ENTERED_IN_ERROR = 'entered-in-error',
  ON_HOLD = 'on-hold',
  STOPPED = 'stopped',
  UNKNOWN = 'unknown',
}

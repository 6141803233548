import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { getErrorMessage, getErrorId } from '../../redux/selectors/error'
import { ReactComponent as IconErrorExclamationPoint } from '../icons/error-exclamation-point.svg'
import { ReactComponent as XButton } from '../icons/outline/x.svg'
import { ERROR_TOAST } from '../../consts/data-testids'
import { useAppSelector } from '../../redux'
import { IconSize, SvgIcon } from './icon'

export function ErrorToastContainer(): null {
  const errorMessage = useAppSelector((state) => getErrorMessage(state))
  const errorId = useAppSelector((state) => getErrorId(state))

  useEffect(() => {
    if (errorMessage) {
      toast.error(
        <ErrorToast header={errorMessage} subtext="Please try again or contact support if the problem persists." />,
      )
    }
  }, [errorMessage, errorId])

  return null
}

interface ErrorToastProps {
  header: string
  subtext: string
}
function ErrorToast({ header, subtext }: ErrorToastProps) {
  return (
    <div data-testid={ERROR_TOAST} className="text-rivaOffblack mt-2 relative">
      <div className="flex justify-end absolute right-0 top-0">
        <SvgIcon Icon={XButton} className="text-rivaOffwhite-700" size={IconSize.X_SMALL} />
      </div>
      <div className="flex items-center">
        <div className="bg-rivaFuchsia rounded-full p-1 mx-2">
          <SvgIcon Icon={IconErrorExclamationPoint} size={IconSize.SMALL} className="fill-current text-rivaWhite" />
        </div>
        <p className="text-rivaBlack">{header}</p>
      </div>
      <p className="text-rivaOffblack ml-11 my-2 text-sm">{subtext}</p>
    </div>
  )
}

import clsx from 'clsx'
import { useCombobox } from 'downshift'
import { ReactElement, ReactNode, RefCallback, useCallback, useEffect, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { ReactComponent as ChevronDown } from '../icons/solid/chevron-down.svg'
import { sameWidthModifier } from '../../utils/popper'
import FormHelperText from './form-helper-text'
import FormLabel from './form-label'
import { List, ListItem } from './list'
import TextInput from './text-input'
import { IconSize, SvgIcon } from './icon'

interface Props {
  disabled?: boolean
  helperText?: ReactNode
  invalid?: boolean
  itemMatches?: (item: string, inputValue: string) => boolean
  items: string[]
  label?: ReactNode
  loading?: boolean
  onBlur?: () => void
  onChange?: (value: string) => void
  onFocus?: () => void
  placeholder?: string
  required?: boolean
  value?: string
  warning?: boolean
}

const Combobox = ({
  disabled,
  helperText,
  invalid = false,
  itemMatches = (item, inputValue) => item.toLowerCase().includes(inputValue.toLowerCase()),
  items,
  label,
  loading,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  value,
  warning,
}: Props): ReactElement => {
  const inputItems = useMemo(
    () => (value ? items.filter((item) => itemMatches(item, value)) : items),
    [itemMatches, items, value],
  )

  const [inputElement, setInputElement] = useState<HTMLInputElement>()
  const [listElement, setListElement] = useState<HTMLUListElement>()

  const listRefCallback = useCallback<RefCallback<HTMLUListElement>>((element) => {
    if (element) {
      setListElement(element)
    }
  }, [])

  const { attributes, styles, update } = usePopper(inputElement, listElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      sameWidthModifier,
    ],
  })

  const { getInputProps, getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, highlightedIndex, isOpen } =
    useCombobox<string>({
      initialIsOpen: false,
      items: inputItems,
      onInputValueChange: ({ inputValue }) => {
        if (onChange) {
          onChange(inputValue ?? '')
        }
      },
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges

        switch (type) {
          case useCombobox.stateChangeTypes.FunctionSelectItem:
            return {
              ...changes,
              isOpen: false,
            }
          default:
            return changes
        }
      },
      inputValue: value,
    })

  useEffect(() => {
    if (isOpen && update) {
      update()
    }
  }, [isOpen, update, value, items.length])

  return (
    <>
      <div className="order-2 relative">
        <TextInput
          {...getInputProps({
            autoCorrect: 'off',
            className: clsx('w-full pr-10 transition-all duration-150 text-sm outline-none rounded border', {
              'border-rivaOffblack-300': !isOpen && !invalid && !warning,
              'border-rivaPurple-500': isOpen && !invalid && !warning,
              'border-rivaGold-300': warning,
              'border-rivaFuchsia-500': invalid,
              'bg-rivaGold-50': warning,
              'bg-rivaFuchsia-50': invalid,
              'text-rivaOffblack-900': !invalid && !warning,
              'text-rivaGold-800': warning,
              'text-rivaFuchsia-800': invalid,
              'hover:border-rivaOffblack-500': !isOpen && !invalid && !warning,
              'hover:border-rivaGold-800': !isOpen && warning,
              'hover:border-rivaFuchsia-800': !isOpen && invalid,
            }),
            disabled,
            onBlur,
            onFocus,
            placeholder,
            ref: (element) => {
              if (element) {
                setInputElement(element)
              }
            },
            required,
          })}
          variant={invalid ? 'invalid' : undefined}
        />
        <button
          {...getToggleButtonProps({
            className: 'absolute h-5 w-5 top-[10px] right-3',
          })}
          type="button"
        >
          <SvgIcon
            Icon={ChevronDown}
            size={IconSize.SMALL}
            className={clsx('fill-current transform transition-transform ease-out duration-150', {
              'text-rivaOffblack-900': !isOpen && !invalid,
              'text-rivaPurple-500': isOpen && !invalid,
              'text-rivaFuchsia-800': invalid,
              'rotate-180': isOpen,
            })}
          />
        </button>
      </div>
      <List
        {...getMenuProps({
          ...attributes.popper,
          className: clsx('border border-rivaOffblack-200 shadow-xl rounded', {
            hidden: !isOpen || (!inputItems.length && !loading),
          }),
          style: styles.popper,
          ref: listRefCallback,
        })}
      >
        {isOpen &&
          !loading &&
          inputItems.map((item, index) => (
            <ListItem
              {...getItemProps({
                className: clsx('cursor-pointer h-8', {
                  'bg-rivaPurple-100': highlightedIndex === index,
                }),
                index,
                item,
              })}
              key={item}
            >
              {item}
            </ListItem>
          ))}
        {isOpen && loading && <ListItem className="text-rivaOffblack-400 cursor-default">Loading...</ListItem>}
      </List>
      {label && (
        <FormLabel {...getLabelProps()} invalid={invalid} warning={warning}>
          {label}
        </FormLabel>
      )}
      {helperText && <FormHelperText invalid={invalid}>{helperText}</FormHelperText>}
    </>
  )
}

export default Combobox

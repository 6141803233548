import { ReactElement } from 'react'
import { TaskQueryParam } from '../../../types/task'
import { parseListId } from '../../../utils/lists'
import { getTaskListTitleByListId } from '../../../utils/tasks'
import TaskFilterMenu from './task-filter-menu'

interface Props {
  listId: string
}

const TaskListHeader = ({ listId }: Props): ReactElement => {
  const query = parseListId(listId).query as TaskQueryParam

  return (
    <header className="flex items-center bg-white px-7 pt-7 pb-4">
      <h2 className="text-lg leading-10 font-semibold m-0 capitalize flex-1">{getTaskListTitleByListId(listId)}</h2>
      {query === TaskQueryParam.ALL ? <TaskFilterMenu currentListId={listId} /> : null}
    </header>
  )
}

export default TaskListHeader

import { produce } from 'immer'
import { Reducer, combineReducers } from 'redux'
import { RecordState } from '../../types/record-state'
import {
  FETCHING_PRACTITIONER,
  FETCH_PRACTITIONER_FAILED,
  FETCH_PRACTITIONER_FULFILLED,
  PersonIdToPractitionerIdMap,
  PractitionerMap,
  PractitionerState,
} from '../../types/practitioner'
import { Action } from '../../types/actions'

function recordState(state: RecordState = RecordState.DOES_NOT_EXIST, action: Action) {
  switch (action.type) {
    case FETCHING_PRACTITIONER:
      return RecordState.LOADING
    case FETCH_PRACTITIONER_FULFILLED:
      return RecordState.LOADED
    case FETCH_PRACTITIONER_FAILED:
      return RecordState.ERRORED
    default:
      return state
  }
}

const practitionerMap = produce((draft: PractitionerMap, action: Action) => {
  switch (action.type) {
    case FETCH_PRACTITIONER_FULFILLED: {
      const { practitioner } = action.payload

      practitioner.forEach((practitioner) => {
        draft[practitioner.id] = practitioner
      })

      return
    }
  }
}, {})

const personIdToPractitionerIdMap = produce((draft: PersonIdToPractitionerIdMap, action: Action) => {
  switch (action.type) {
    case FETCH_PRACTITIONER_FULFILLED: {
      const { practitioner } = action.payload

      practitioner.forEach((practitioner) => {
        draft[practitioner.personId] = practitioner.id
      })

      return
    }
  }
}, {})

export const practitioner: Reducer<PractitionerState, Action> = combineReducers({
  recordState,
  practitionerMap,
  personIdToPractitionerIdMap,
})

import { useAuth0 } from '@auth0/auth0-react'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import type { ValidateFunction } from 'ajv'
import { useCallback } from 'react'
import { compile } from 'path-to-regexp'
import * as http from '../http'
import { createApiLogger } from '../../logger'
import { uuid } from '../../utils/uuid'

interface UseApiOptions<T> extends SWRConfiguration {
  loggerOptions?: Record<string, unknown>
  params?: Record<string, string>
  query?: Record<string, string[]>
  validate?: ValidateFunction<T>
}

const useApi = <T>(key: string | null, options: UseApiOptions<T> = {}): SWRResponse<T> => {
  const { validate = ((data) => true) as ValidateFunction<T>, ...config } = options
  const { getAccessTokenSilently } = useAuth0()
  const fetcher = useCallback(
    async (url: string) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      const requestId = uuid()
      const logger = createApiLogger('GET', key ?? 'Null request', {
        http: {
          request_id: requestId,
        },
      })

      try {
        const { body, statusCode } = await http.get(url, { accessToken, requestId })

        if (!validate(body)) {
          const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

          throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
            keyword,
            instancePath,
            schemaPath,
            propertyName,
          })
        }

        logger.logSuccess({
          statusCode,
        })

        return body
      } catch (error) {
        logger.logFailure(error instanceof Error ? error : undefined)

        throw error
      }
    },
    [getAccessTokenSilently, key, validate],
  )

  const toPath = key
    ? compile(key, {
        encode: encodeURIComponent,
      })
    : null

  let pathname = toPath ? toPath(options.params) : null

  if (pathname && options.query !== undefined) {
    const searchParams = new URLSearchParams()

    Object.keys(options.query).forEach((key) => {
      options.query?.[key].forEach((value) => {
        searchParams.append(key, value)
      })
    })

    const query = searchParams.toString()

    if (query) {
      pathname = pathname + '?' + query
    }
  }

  return useSWR(pathname, fetcher, config)
}

export default useApi

import { ComponentProps, forwardRef, useCallback, useState } from 'react'
import useTasksApi from '../../../api/hooks/use-tasks-api'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { getTaskStatus } from '../../../redux/selectors/tasks'
import { updateTaskStatus } from '../../../redux/thunks/tasks'
import { TaskStatus } from '../../../types/task'
import { getStatusIcon, getStatusText } from '../../../utils/tasks'
import StatusNoteModal from '../../modals/status-note-modal'
import { IconSize, SvgIcon } from '../../ui/icon'
import { MenuItem, MenuSeparator } from '../../ui/menu'

type MenuClickHandler = NonNullable<ComponentProps<typeof MenuItem>['onClick']>

interface Props {
  i13n?: Record<string, unknown>
  includeCompleted?: boolean
  listId: string
  onClose?: () => void
  taskId: string
}

const TaskStatusMenuItems = forwardRef<HTMLElement, Props>(
  ({ i13n, includeCompleted, listId, onClose, taskId }, ref) => {
    const dispatch = useAppDispatch()
    const tasksApi = useTasksApi()
    const status = useAppSelector((state) => getTaskStatus(state, taskId))
    const [openModal, setOpenModal] = useState<TaskStatus.TaskCompletingSkipping | TaskStatus.TaskNa | undefined>()

    const markAsCompleted = useCallback<MenuClickHandler>(
      (event) => {
        event.stopPropagation = true
        onClose?.()

        dispatch(
          updateTaskStatus(
            {
              i13n,
              id: taskId,
              listId,
              status: TaskStatus.TaskCompleting,
            },
            tasksApi,
          ),
        )
      },
      [dispatch, i13n, listId, onClose, taskId, tasksApi],
    )
    const markAsInProgress = useCallback<MenuClickHandler>(
      (event) => {
        event.stopPropagation = true
        onClose?.()

        dispatch(
          updateTaskStatus(
            {
              i13n,
              id: taskId,
              listId,
              status: TaskStatus.TaskInProgress,
            },
            tasksApi,
          ),
        )
      },
      [onClose, dispatch, i13n, taskId, listId, tasksApi],
    )
    const markAsNotStarted = useCallback<MenuClickHandler>(
      (event) => {
        event.stopPropagation = true
        onClose?.()

        dispatch(
          updateTaskStatus(
            {
              i13n,
              id: taskId,
              listId,
              status: TaskStatus.TaskPending,
            },
            tasksApi,
          ),
        )
      },
      [onClose, dispatch, i13n, taskId, listId, tasksApi],
    )
    const onSkippedClicked = useCallback<MenuClickHandler>((event) => {
      event.stopPropagation = true

      setOpenModal(TaskStatus.TaskCompletingSkipping)
    }, [])
    const onNotApplicableClick = useCallback<MenuClickHandler>((event) => {
      event.stopPropagation = true

      setOpenModal(TaskStatus.TaskNa)
    }, [])
    const onModalAccept = useCallback(() => {
      setOpenModal(undefined)

      onClose?.()
    }, [onClose])
    const onModalCancel = useCallback(() => {
      setOpenModal(undefined)
    }, [])

    return (
      <>
        <MenuItem disabled>
          <SvgIcon Icon={getStatusIcon(status)} size={IconSize.MEDIUM} />
          <span className="ml-2">{getStatusText(status)}</span>
        </MenuItem>
        <MenuSeparator />
        {status !== TaskStatus.TaskInProgress ? (
          <MenuItem onClick={markAsInProgress}>
            <SvgIcon Icon={getStatusIcon(TaskStatus.TaskInProgress)} size={IconSize.MEDIUM} />
            <span className="ml-2">{getStatusText(TaskStatus.TaskInProgress)}</span>
          </MenuItem>
        ) : null}
        {status !== TaskStatus.TaskPending && status !== TaskStatus.TaskCreated ? (
          <MenuItem onClick={markAsNotStarted}>
            <SvgIcon Icon={getStatusIcon(TaskStatus.TaskPending)} size={IconSize.MEDIUM} />
            <span className="ml-2">{getStatusText(TaskStatus.TaskPending)}</span>
          </MenuItem>
        ) : null}
        {includeCompleted && status !== TaskStatus.TaskCompleted && status !== TaskStatus.TaskCompleting ? (
          <MenuItem onClick={markAsCompleted}>
            <SvgIcon Icon={getStatusIcon(TaskStatus.TaskCompleted)} size={IconSize.MEDIUM} />
            <span className="ml-2">{getStatusText(TaskStatus.TaskCompleted)}</span>
          </MenuItem>
        ) : null}
        {status !== TaskStatus.TaskCompletedSkipped && status !== TaskStatus.TaskCompletingSkipping ? (
          <MenuItem onClick={onSkippedClicked}>
            <SvgIcon Icon={getStatusIcon(TaskStatus.TaskCompletedSkipped)} size={IconSize.MEDIUM} />
            <span className="ml-2">{getStatusText(TaskStatus.TaskCompletedSkipped)}</span>
          </MenuItem>
        ) : null}
        {status !== TaskStatus.TaskNa ? (
          <MenuItem onClick={onNotApplicableClick}>
            <SvgIcon Icon={getStatusIcon(TaskStatus.TaskNa)} size={IconSize.MEDIUM} />
            <span className="ml-2">{getStatusText(TaskStatus.TaskNa)}</span>
          </MenuItem>
        ) : null}
        {openModal !== undefined ? (
          <StatusNoteModal
            i13n={i13n}
            isOpen
            listId={listId}
            onAccept={onModalAccept}
            onCancel={onModalCancel}
            status={openModal}
            taskId={taskId}
          />
        ) : null}
      </>
    )
  },
)

export default TaskStatusMenuItems

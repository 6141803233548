import { ApolloClient } from '@apollo/client'
import patientStudiesQuery from '../../schemas/patient-studies-query'
import { parseListId } from '../../utils/lists'
import { createAppAsyncThunk } from '..'
import { Study, StudyEnrollment } from '../../types/study'
import { StudiesApi } from '../../api/hooks/use-studies-api'

export const fetchStudies = createAppAsyncThunk<
  {
    eligibleListId: string
    enrolledListId: string
    eligible: Study[]
    enrolled: Study[]
  },
  {
    eligibleListId: string
    enrolledListId: string
    apolloClient: ApolloClient<object>
  }
>('studies/fetch', async ({ eligibleListId, enrolledListId, apolloClient }) => {
  const { patientId } = parseListId(eligibleListId)
  const { data } = await apolloClient.query({
    fetchPolicy: 'network-only',
    query: patientStudiesQuery,
    variables: {
      patientId: patientId ? patientId[0] : '',
    },
  })

  return {
    eligibleListId,
    enrolledListId,
    eligible: data.patient?.eligibleStudies ?? [],
    enrolled: data.patient?.enrolledStudies ?? [],
  }
})

export const enrollToStudy = createAppAsyncThunk<
  StudyEnrollment,
  { listId: string; studyId: string; studiesApi: StudiesApi }
>('studies/enroll', async ({ listId, studyId, studiesApi }) => {
  const { patientId } = parseListId(listId)

  return studiesApi.enroll(studyId, patientId?.[0] ?? '')
})

export const withdrawFromStudy = createAppAsyncThunk<
  StudyEnrollment,
  { listId: string; studyId: string; studiesApi: StudiesApi; withdrawDate: string }
>('studies/withdraw', async ({ listId, studyId, studiesApi, withdrawDate }) => {
  const { patientId } = parseListId(listId)

  return studiesApi.withdraw(studyId, patientId?.[0] ?? '', {
    withdrawal_date: withdrawDate,
  })
})

import { combineReducers, createReducer } from '@reduxjs/toolkit'
import { Study } from '../../types/study'
import { enrollToStudy, fetchStudies, withdrawFromStudy } from '../thunks/study'
import { List } from '../../types/lists'
import { RecordState } from '../../types/record-state'

const lists = createReducer({} as Record<string, List>, (builder) => {
  builder
    .addCase(fetchStudies.pending, (state, action) => {
      const { eligibleListId, enrolledListId } = action.meta.arg

      if (!state[eligibleListId]) {
        state[eligibleListId] = {
          itemIds: [],
          hash: '',
          recordState: RecordState.DOES_NOT_EXIST,
        }
      }
      if (!state[enrolledListId]) {
        state[enrolledListId] = {
          itemIds: [],
          hash: '',
          recordState: RecordState.DOES_NOT_EXIST,
        }
      }
      state[eligibleListId].recordState = RecordState.LOADING
      state[enrolledListId].recordState = RecordState.LOADING
    })
    .addCase(fetchStudies.fulfilled, (state, action) => {
      const { eligibleListId, enrolledListId } = action.meta.arg

      state[eligibleListId].itemIds = action.payload.eligible.map(({ id }) => id)
      state[eligibleListId].recordState = RecordState.LOADED

      state[enrolledListId].itemIds = action.payload.enrolled.map(({ id }) => id)
      state[enrolledListId].recordState = RecordState.LOADED
    })
    .addCase(fetchStudies.rejected, (state, action) => {
      const { eligibleListId, enrolledListId } = action.meta.arg
      state[eligibleListId].recordState = RecordState.ERRORED
      state[enrolledListId].recordState = RecordState.ERRORED
    })
    .addCase(enrollToStudy.pending, (state, action) => {
      state[action.meta.arg.listId].itemIds.push(action.meta.arg.studyId)
    })
    .addCase(withdrawFromStudy.pending, (state, action) => {
      state[action.meta.arg.listId].itemIds = state[action.meta.arg.listId].itemIds.filter(
        (id) => id !== action.meta.arg.studyId,
      )
    })
})

const entities = createReducer({} as Record<string, Study>, (builder) => {
  builder.addCase(fetchStudies.fulfilled, (state, action) => {
    action.payload.eligible.forEach((study) => {
      state[study.id] = study
    })
    action.payload.enrolled.forEach((study) => {
      state[study.id] = study
    })
  })
})

export default combineReducers({
  lists,
  entities,
})

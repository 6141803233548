import { ReactElement, ReactNode } from 'react'

export interface Props<T extends string | number> {
  value: T
  children: ReactNode
}

const Option: <T extends string | number>(props: Props<T>) => ReactElement = ({ children, value }) => (
  <option value={value}>{children}</option>
)

export default Option

import { RecordState } from '../types/record-state'

export function isLoading(recordState: RecordState): boolean {
  return recordState === RecordState.LOADING
}

export function isLoadingOrDoesNotExist(recordState: RecordState): boolean {
  return recordState === RecordState.LOADING || recordState === RecordState.DOES_NOT_EXIST
}

export function isLoaded(recordState: RecordState): boolean {
  return recordState === RecordState.LOADED
}

export function isErrored(recordState: RecordState): boolean {
  return recordState === RecordState.ERRORED
}

import clsx from 'clsx'
import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import useGetSurveys from '../../../../api/hooks/use-get-surveys'
import { SurveySubmission, SurveySubmissionStatus } from '../../../../types/survey'
import { TableRow } from '../../../ui/table'
import SurveyResults from './survey-results'

type Props = SurveySubmission

const Submission = ({
  completedAt,
  createdAt,
  data: submissionData,
  sentAt,
  status,
  surveyId,
  url,
}: Props): ReactElement => {
  const { data } = useGetSurveys()
  const surveyName = data?.surveys?.find(({ id }) => id === surveyId)?.name ?? String(surveyId)
  const pending = status === SurveySubmissionStatus.SENT && !completedAt
  const failed = status === SurveySubmissionStatus.FAILED

  let date = completedAt

  if (failed) {
    date = createdAt ?? ''
  } else if (pending) {
    date = sentAt ?? ''
  }

  return (
    <TableRow
      href={url}
      className="flex flex-row text-sm"
      target="_blank"
      title={!url ? 'Older responses can only be seen in SurveySparrow' : undefined}
    >
      <span className="flex-none w-[200px] py-4 pr-3 flex" role="cell">
        {surveyName}
      </span>
      <span className="flex-1 capitalize py-4 overflow-x-hidden" role="cell">
        <SurveyResults submissionData={submissionData || {}} surveyId={surveyId} />
      </span>
      <span className="flex-none flex justify-end box-border w-[120px] p-4" role="cell">
        <span
          className={clsx('text-right', {
            'text-rivaFuchsia-600 bg-rivaFuchsia-50': failed,
            'bg-rivaOffblack-200': pending,
          })}
        >
          {failed ? 'Failed to send ' : pending ? 'Sent ' : ''}
          {DateTime.fromISO(date).toLocaleString({
            month: 'numeric',
            day: 'numeric',
            year: '2-digit',
          })}
        </span>
      </span>
    </TableRow>
  )
}

export default Submission

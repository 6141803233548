import { ReactElement } from 'react'
import deepEquals from 'lodash.isequal'
import { useAppSelector } from '../../../../../redux'
import { getLoggedInPractitionerId } from '../../../../../redux/selectors/app'
import { getTaskById } from '../../../../../redux/selectors/tasks'
import { TaskEvent } from '../../../../../types/task'
import { TaskEventType, TaskType } from '../../../../../__generated__/graphql'
import { getCarePathwayEventsByCarePathwayId } from '../../../../../redux/selectors/care-pathway'
import TaskEventAlert from './task-alert-item'

interface Props {
  taskId: string
}

const TaskEventAlerts = ({ taskId }: Props): ReactElement | null => {
  const loggedInPractitionerId = useAppSelector(getLoggedInPractitionerId)
  const task = useAppSelector((state) => getTaskById(state, taskId))
  const carePathwayEvents = useAppSelector((state) => {
    if (task?.taskType !== TaskType.SendLifestyleChange) {
      return []
    }

    return getCarePathwayEventsByCarePathwayId(state, task?.carePathwayId ?? '').filter((event) => {
      return event.eventType === TaskEventType.CreateCarePathway
    })
  }, deepEquals)
  const events = [...(task?.events ?? [])].reverse().reduce((events, event) => {
    if (
      (event.eventType === TaskEventType.VisitCancelled || event.eventType === TaskEventType.VisitRescheduled) &&
      !events.find(({ eventType }) => eventType === event.eventType)
    ) {
      return [...events, event]
    }

    if (event.eventType === TaskEventType.AssignTask && event.data.assignee === loggedInPractitionerId) {
      return [...events, event]
    }

    return events
  }, [] as TaskEvent[])

  return (
    <>
      {[...carePathwayEvents, ...events].map((event) => {
        return <TaskEventAlert event={event} key={event.id} />
      })}
    </>
  )
}

export default TaskEventAlerts

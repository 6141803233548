import { gql } from '../__generated__/gql'

export default gql(`#graphql
query bloodPressureMeasurementsQuery($patientId: ID! $start: String! $end: String!) {
  patient(patientId: $patientId) {
    id
    bloodPressureMeasurements(start: $start end: $end) {
      id
      bloodPressureEscalationCategory {
        type
        reasons
      }
      bloodPressureSetId
      bloodPressureSet {
        comment
        created {
          at
          by
        }
        id
        patientId
        timeZone
      }
      comment
      personId
      type
      systolicBloodPressure
      diastolicBloodPressure
      beatsPerMinute
      deviceType
      escalation {
        created {
          at
          by
        }
        events {
          carePathwayId
          created {
            at
            by
          }
          data
          eventType
          id
        }
        id
        patientId
        status
        type
      }
      timeZone
      created {
        at
        by
      }
      updated {
        at
        by
      }
    }
  }
}
`)

import { RootState } from '../reducers'
import { RecordState } from '../../types/record-state'
import { BloodPressureAverage } from '../../types/blood-pressure-moving-average'
import { daysFromNow } from '../../utils/dates'

type MovingAveragePeriod = number

function getMovingAverage(
  state: RootState,
  patientId: string,
  movingAveragePeriod: MovingAveragePeriod,
): BloodPressureAverage | null {
  const movingAverage = state.bloodPressureAverage[patientId]?.movingAverages.find(
    (average) => average.daysAgo === movingAveragePeriod,
  )

  if (!movingAverage || movingAverage.errorInfo) {
    return null
  }

  return movingAverage
}

export function getSystolicRange(
  state: RootState,
  patientId: string,
  movingAveragePeriod: MovingAveragePeriod,
): string {
  const movingAverage = getMovingAverage(state, patientId, movingAveragePeriod)

  if (!movingAverage) {
    return '-'
  } else if (!movingAverage.systolicAverage.high || !movingAverage.systolicAverage.low) {
    return '-'
  }

  return formatBloodPressureRange(movingAverage.systolicAverage.high, movingAverage.systolicAverage.low)
}

export function getDiastolicRange(
  state: RootState,
  patientId: string,
  movingAveragePeriod: MovingAveragePeriod,
): string {
  const movingAverage = getMovingAverage(state, patientId, movingAveragePeriod)

  if (!movingAverage) {
    return '-'
  } else if (!movingAverage.diastolicAverage.high || !movingAverage.diastolicAverage.low) {
    return '-'
  }

  return formatBloodPressureRange(movingAverage.diastolicAverage.high, movingAverage.diastolicAverage.low)
}

export function getHeartRateRange(
  state: RootState,
  patientId: string,
  movingAveragePeriod: MovingAveragePeriod,
): string {
  const movingAverage = getMovingAverage(state, patientId, movingAveragePeriod)

  if (!movingAverage) {
    return '-'
  } else if (!movingAverage.beatsPerMinuteAverage.high || !movingAverage.beatsPerMinuteAverage.low) {
    return '-'
  }

  return formatBloodPressureRange(movingAverage.beatsPerMinuteAverage.high, movingAverage.beatsPerMinuteAverage.low)
}

export function getBloodPressureAverage(
  state: RootState,
  patientId: string,
  movingAveragePeriod: MovingAveragePeriod,
): string {
  const movingAverage = getMovingAverage(state, patientId, movingAveragePeriod)

  if (!movingAverage) {
    return '-'
  } else if (!movingAverage.systolicAverage.average || !movingAverage.diastolicAverage.average) {
    return '-'
  }

  return `${Math.round(movingAverage.systolicAverage.average)}/${Math.round(movingAverage.diastolicAverage.average)}`
}

export function getHeartRateAverage(
  state: RootState,
  patientId: string,
  movingAveragePeriod: MovingAveragePeriod,
): string {
  const movingAverage = getMovingAverage(state, patientId, movingAveragePeriod)

  if (!movingAverage) {
    return '-'
  } else if (!movingAverage.beatsPerMinuteAverage.average) {
    return '-'
  }

  return `${Math.round(movingAverage.beatsPerMinuteAverage.average)}`
}

export const getBloodPressureAverageEarliestTime = (
  state: RootState,
  patientId: string,
  movingAveragePeriod: MovingAveragePeriod,
): string => {
  const movingAverage = getMovingAverage(state, patientId, movingAveragePeriod)

  if (!movingAverage) {
    return ''
  }

  return movingAverage.earliestTimestamp
}

export const getBloodPressureAverageLatestTime = (
  state: RootState,
  patientId: string,
  movingAveragePeriod: MovingAveragePeriod,
): string => {
  const movingAverage = getMovingAverage(state, patientId, movingAveragePeriod)

  if (!movingAverage) {
    return ''
  }

  return movingAverage.latestTimestamp
}

const MOVING_AVERAGE_STALE_DAYS = 3

export const getIsLatest12pointMovingAverageStale = (state: RootState, patientId: string): boolean => {
  const movingAverage = getMovingAverage(state, patientId, 0)

  if (!movingAverage) {
    return false
  }

  return daysFromNow(movingAverage.latestTimestamp) > MOVING_AVERAGE_STALE_DAYS
}

export function getBloodPressureAverageRecordState(state: RootState, patientId: string): RecordState {
  return state.bloodPressureAverage[patientId]?.recordState || RecordState.DOES_NOT_EXIST
}

function formatBloodPressureRange(high: number, low: number) {
  return `${Math.round(low)}-${Math.round(high)}`
}

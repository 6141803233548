import { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { getListRecordState } from '../../../redux/selectors/lists'
import { getPatientNamesAndIds } from '../../../redux/selectors/patient'
import { fetchPatients } from '../../../redux/thunks/patient'
import { RecordState } from '../../../types/record-state'
import { buildPatientListId } from '../../../utils/lists'
import MultiCombobox from '../multi-combobox'
import { Item } from './types'

interface Props {
  selectedItems: Item[]
  onChange: (selectedItems: Item[]) => void
}

const PatientMultiCombobox = ({ onChange, selectedItems }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const defaultPatientListId = buildPatientListId({})

  const recordState = useAppSelector((state) => getListRecordState(state, defaultPatientListId))
  const patients = useAppSelector((state) => getPatientNamesAndIds(state, defaultPatientListId))

  const items = useMemo(
    (): Item[] =>
      patients.map(
        ({ id, name }): Item => ({
          name,
          type: 'option',
          value: id,
        }),
      ),
    [patients],
  )

  const itemToKey = useCallback(({ value }: Item) => value, [])
  const itemMatches = useCallback(
    (inputValue: string, { name }: Item) => name.toLowerCase().includes(inputValue.toLowerCase()),
    [],
  )
  const renderPatientItem = useCallback(({ name }: Item) => <span className="flex-1 truncate">{name}</span>, [])

  useEffect(() => {
    if (recordState === RecordState.DOES_NOT_EXIST) {
      dispatch(fetchPatients(defaultPatientListId))
    }
  }, [dispatch, recordState, defaultPatientListId])

  return (
    <MultiCombobox
      errored={recordState === RecordState.ERRORED}
      itemMatches={itemMatches}
      items={items}
      itemToKey={itemToKey}
      label="Patients"
      loading={recordState === RecordState.DOES_NOT_EXIST || recordState === RecordState.LOADING}
      maxHeight="short"
      onChange={onChange}
      placeholder="Select patient"
      renderItem={renderPatientItem}
      selectedItems={selectedItems}
    />
  )
}

export default PatientMultiCombobox

import { ReactElement } from 'react'
import useTasksApi from '../../../../api/hooks/use-tasks-api'
import useMousetrap from '../../../../hooks/use-mousetrap'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getListSelectedItemId } from '../../../../redux/selectors/lists'
import { updateTaskStatus } from '../../../../redux/thunks/tasks'
import { TaskStatus } from '../../../../types/task'
import AssignCommand from './assign-command'
import {
  CHANGE_STATUS_COMPLETED_KEYS,
  CHANGE_STATUS_IN_PROGRESS_KEYS,
  CHANGE_STATUS_NOT_APPLICABLE_KEYS,
  CHANGE_STATUS_NOT_STARTED_KEYS,
} from './keys'
import StatusCommand from './status-command'

interface Props {
  listId: string
}

const TaskCommands = ({ listId }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const actionableItemId = useAppSelector((state) => getListSelectedItemId(state, listId))

  useMousetrap(CHANGE_STATUS_NOT_STARTED_KEYS, () => {
    if (!actionableItemId) {
      return
    }

    dispatch(
      updateTaskStatus(
        {
          id: actionableItemId,
          listId,
          status: TaskStatus.TaskInProgress,
        },
        tasksApi,
      ),
    )
  })

  useMousetrap(CHANGE_STATUS_IN_PROGRESS_KEYS, () => {
    if (!actionableItemId) {
      return
    }

    dispatch(
      updateTaskStatus(
        {
          id: actionableItemId,
          listId,
          status: TaskStatus.TaskInProgress,
        },
        tasksApi,
      ),
    )
  })

  useMousetrap(CHANGE_STATUS_COMPLETED_KEYS, () => {
    if (!actionableItemId) {
      return
    }

    dispatch(
      updateTaskStatus(
        {
          id: actionableItemId,
          listId,
          status: TaskStatus.TaskCompleting,
        },
        tasksApi,
      ),
    )
  })

  useMousetrap(CHANGE_STATUS_NOT_APPLICABLE_KEYS, () => {
    if (!actionableItemId) {
      return
    }

    dispatch(
      updateTaskStatus(
        {
          id: actionableItemId,
          listId,
          status: TaskStatus.TaskNa,
        },
        tasksApi,
      ),
    )
  })

  if (!actionableItemId) {
    return null
  }

  return (
    <>
      <AssignCommand listId={listId} />
      <StatusCommand listId={listId} />
    </>
  )
}

export default TaskCommands

import { Provider, ProviderType, ReferringProvider } from '../../types/provider'
import { RootState } from '../reducers'

const state: Record<
  Exclude<ReferringProvider, ReferringProvider.NOT_APPLICABLE | ReferringProvider.NOT_LISTED>,
  Provider
> = {
  [ReferringProvider.DIANE_YANG_MD]: {
    firstName: 'Diane',
    id: ReferringProvider.DIANE_YANG_MD,
    lastName: 'Yang',
    prefix: 'Dr.',
    providerType: ProviderType.MD,
  },
  [ReferringProvider.ANDREW_VINES_MD]: {
    firstName: 'Andrew',
    id: ReferringProvider.ANDREW_VINES_MD,
    lastName: 'Vines',
    prefix: 'Dr.',
    providerType: ProviderType.MD,
  },
  [ReferringProvider.KENJI_KAYE_MD]: {
    firstName: 'Kenji',
    id: ReferringProvider.KENJI_KAYE_MD,
    lastName: 'Kaye',
    prefix: 'Dr.',
    providerType: ProviderType.MD,
  },
  [ReferringProvider.RICHARD_PANG_MD]: {
    firstName: 'Richard',
    id: ReferringProvider.RICHARD_PANG_MD,
    lastName: 'Pang',
    prefix: 'Dr.',
    providerType: ProviderType.MD,
  },
  [ReferringProvider.ELI_GRILLO_MD]: {
    firstName: 'Eli',
    id: ReferringProvider.ELI_GRILLO_MD,
    lastName: 'Grillo',
    prefix: 'Dr.',
    providerType: ProviderType.MD,
  },
}

export const getProviderById = (s: RootState, id: string): Provider | undefined => state[id as keyof typeof state]

const providerIdsAndNames = Object.values(state).map(({ firstName, id, lastName, providerType }) => ({
  id,
  name: `${firstName} ${lastName}, ${providerType}`,
}))

export const getProviderIdsAndNames = (state: RootState): { id: string; name: string }[] => providerIdsAndNames

export enum Features {
  ALLERGIES_TAB = 'allergiesTab',
  ATHENA_PATIENT_SYNC = 'athenaPatientSync',
  BLOOD_PRESSURE_GRAPH = 'bloodPressureGraph',
  LIFESTYLE_CHANGE = 'lifestyleChange',
  MEDICATIONS_TAB = 'medicationsTab',
  MEDICATIONS_PAID_TAB = 'medicationsPaidTab',
  PATIENT_FILTERS_V2 = 'patientFiltersV2',
  PATIENT_PAGES = 'patientPages',
  PATIENT_SEARCH = 'patientSearch',
  PROBLEMS_TAB = 'problemsTab',
}

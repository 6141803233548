import { ReactElement, useCallback, useMemo } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { AcquisitionChannel, AcquisitionChannelNames } from '../../../../../types/patient'
import MultiCombobox, { ComboboxOptionItem } from '../../../../ui/multi-combobox'
import { parseListId, serializeListIdForRoute } from '../../../../../utils/lists'
import { ButtonSize, ButtonVariant, MenuButton } from '../../../../ui/button'
import { IconSize, SvgIcon } from '../../../../ui/icon'
import { ReactComponent as ChevronDownIcon } from '../../../../icons/solid/chevron-down.svg'
import { ReactComponent as XSmallIcon } from '../../../../icons/outline/x-small.svg'
import { ReactComponent as FilterIcon } from '../../../..//icons/outline/filter.svg'
import IconButton from '../../../../ui/icon-button'
import { I13n } from '../../../../../types/i13n'
import { track } from '../../../../../i13n'
import useGetRefreshRoute from './use-get-refresh-route'

export interface Item extends ComboboxOptionItem<AcquisitionChannel> {
  name: string
}

interface Props {
  i13n?: Partial<Omit<I13n, 'eventName'>>
  listId: string
}

const allAcquisitionChannels = Object.values(AcquisitionChannel).map(
  (acquisitionChannel): Item => ({
    name: AcquisitionChannelNames[acquisitionChannel],
    type: 'option',
    value: acquisitionChannel,
  }),
)

const AcquisitionChannelFilter = ({ i13n, listId }: Props): ReactElement => {
  const refreshRoute = useGetRefreshRoute()
  const match = useRouteMatch<{ patientId?: string }>(refreshRoute)
  const history = useHistory()
  const selectedChannels = useMemo(() => {
    const { acquisitionChannel } = parseListId(listId)

    return allAcquisitionChannels.filter(({ value }) => acquisitionChannel?.includes(value))
  }, [listId])

  const clearSelection = useCallback(() => {
    const { acquisitionChannel, ...descriptor } = parseListId(listId)

    history.push(
      generatePath(refreshRoute, {
        patientId: match?.params.patientId,
        serializedListId: serializeListIdForRoute(descriptor),
      }),
    )
  }, [history, listId, match?.params.patientId, refreshRoute])

  const itemToKey = useCallback(({ value }: Item) => value, [])
  const itemMatches = useCallback(
    (inputValue: string, { name }: Item) => name.toLowerCase().includes(inputValue.toLowerCase()),
    [],
  )
  const renderItem = useCallback(({ name }: Item) => <span className="flex-1 truncate">{name}</span>, [])
  const renderInput = useCallback(
    (isOpen: boolean, { className, onBlur, onClick, onFocus, ...props }: Record<string, unknown>) => {
      return (
        <span className="relative inline-block">
          <MenuButton
            {...props}
            onClick={(event) => {
              track('Acquisition Channel Filter Clicked', i13n?.properties)
              if (typeof onClick === 'function') {
                onClick(event)
              }
            }}
            className="!px-1 ml-2"
            active={isOpen}
            size={ButtonSize.XXS}
            variant={ButtonVariant.SECONDARY}
          >
            <SvgIcon Icon={FilterIcon} size={IconSize.X_SMALL} />
            <span className="mx-1">
              {selectedChannels.length > 1
                ? `${selectedChannels.length} acquisition sources`
                : selectedChannels.length === 1
                ? selectedChannels[0].name
                : 'Acquisition source'}
            </span>
            <SvgIcon
              className={clsx('transition-transform', {
                'rotate-180': selectedChannels.length === 0 && isOpen,
                invisible: selectedChannels.length > 0,
              })}
              Icon={ChevronDownIcon}
              size={IconSize.X_SMALL}
            />
          </MenuButton>
          {selectedChannels.length > 0 ? (
            <IconButton
              aria-label="Clear acquisition channels"
              borderless
              className="absolute right-1 top-1"
              icon={XSmallIcon}
              onClick={clearSelection}
              size="XXS"
            />
          ) : null}
        </span>
      )
    },
    [clearSelection, i13n?.properties, selectedChannels],
  )
  const onChange = useCallback(
    (items: Item[]) => {
      history.push(
        generatePath(refreshRoute, {
          patientId: match?.params.patientId,
          serializedListId: serializeListIdForRoute({
            ...parseListId(listId),
            acquisitionChannel: items.map(({ value }) => value),
            offset: 0,
          }),
        }),
      )
    },
    [history, listId, match?.params.patientId, refreshRoute],
  )

  return (
    <MultiCombobox
      containerClassName="!order-none"
      filtered
      itemMatches={itemMatches}
      items={allAcquisitionChannels}
      itemToKey={itemToKey}
      maxHeight="short"
      onChange={onChange}
      placeholder="Acquisition source"
      placement="bottom-start"
      renderInput={renderInput}
      renderItem={renderItem}
      selectedItems={selectedChannels}
    />
  )
}

export default AcquisitionChannelFilter

import { ComponentProps, ReactElement, useCallback, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { MenuButtonModifiers } from '@szhsin/react-menu'
import { buildUnpagedListId, parseListId, serializeListIdForRoute } from '../../../../../utils/lists'
import { getListTitleByManagementStatus } from '../../../../../utils/patient'
import { Button, ButtonVariant, MenuButton } from '../../../../ui/button'
import { IconSize, SvgIcon } from '../../../../ui/icon'
import { ReactComponent as PlusCircle } from '../../../../icons/outline/plus.svg'
import { AddPatientModal } from '../../../../modals/add-patient'
import { Menu, MenuItem } from '../../../../ui/menu'
import { useAppDispatch, useAppSelector } from '../../../../../redux'
import { downloadAllPatientBpReport, downloadChatSummaryReport } from '../../../../../redux/thunks/reports'
import { ReactComponent as DotsHorizontalIcon } from '../../../../icons/solid/dots-horizontal.svg'
import { TabList } from '../../../../ui/tabs'
import LinkTab from '../../../../ui/tabs/link_tab'
import { BloodPressureManagementStatus } from '../../../../../types/patient'
import useTabs from '../../../../ui/tabs/use-tabs'
import Pill from '../../../../ui/pill'
import { getListTotalCount } from '../../../../../redux/selectors/lists'
import { I13n } from '../../../../../types/i13n'
import { isMyPatientsListId } from '../../../../../redux/selectors/app'
import { track } from '../../../../../i13n'
import PractitionerFilter from './practitioner-filter'
import ClearFiltersButton from './clear-filters-button'
import AcquisitionChannelFilter from './acquisition-channel-filter'

interface Props {
  listId: string
}

const PatientListHeader = ({ listId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const auth0 = useAuth0()
  const listDescriptor = parseListId(listId)
  const { managementStatus } = listDescriptor
  const unpagedListId = useMemo(() => buildUnpagedListId(listId), [listId])
  const totalCount = useAppSelector((state) => getListTotalCount(state, unpagedListId))
  const myPatientsList = useAppSelector((state) => isMyPatientsListId(state, listId))
  const [isCreatePatientOpen, setIsCreatePatientOpen] = useState(false)
  const openCreatePatient = useCallback(() => {
    track('Create Patient Clicked')
    setIsCreatePatientOpen(true)
  }, [])
  const closeCreatePatient = useCallback(() => setIsCreatePatientOpen(false), [])
  const { getTabListProps, getTabProps } = useTabs({
    selectedIndex:
      managementStatus === BloodPressureManagementStatus.ACTIVE_MANAGEMENT
        ? 0
        : managementStatus === BloodPressureManagementStatus.ESTABLISHING_CARE
        ? 1
        : managementStatus === BloodPressureManagementStatus.ONBOARDING
        ? 2
        : managementStatus === BloodPressureManagementStatus.MAINTENANCE
        ? 3
        : 4,
  })
  const i13n = useMemo(
    (): Partial<I13n> => ({
      properties: {
        Location: myPatientsList ? 'My Patients' : 'All Patients',
      },
    }),
    [myPatientsList],
  )

  const onMenuChange = useCallback<NonNullable<ComponentProps<typeof Menu>['onMenuChange']>>(() => {
    track('Patients Panel More Actions Clicked')
  }, [])
  const renderMenuButton = useCallback<(m: MenuButtonModifiers) => ReactElement>(
    ({ open }) => (
      <MenuButton active={open} className="px-5" variant={ButtonVariant.SECONDARY}>
        <SvgIcon Icon={DotsHorizontalIcon} size={IconSize.MEDIUM} />
      </MenuButton>
    ),
    [],
  )

  const onBpReportDownloadClick = useCallback(() => {
    dispatch(downloadAllPatientBpReport({ auth0 }))
  }, [auth0, dispatch])

  const onChatSummariesDownloadClick = useCallback(() => {
    dispatch(downloadChatSummaryReport({ auth0 }))
  }, [auth0, dispatch])

  return (
    <header className="sticky top-0 bg-white flex-none">
      <div className="border-b border-rivaOffblack-200 pt-4 pr-7 flex flex-row">
        <TabList {...getTabListProps()} className="border-b-0">
          <LinkTab
            {...getTabProps(0)}
            i13n={i13n}
            to={`/patients/${serializeListIdForRoute({
              ...listDescriptor,
              offset: 0,
              managementStatus: BloodPressureManagementStatus.ACTIVE_MANAGEMENT,
            })}`}
          >
            {getListTitleByManagementStatus(BloodPressureManagementStatus.ACTIVE_MANAGEMENT)}
          </LinkTab>
          <LinkTab
            {...getTabProps(1)}
            i13n={i13n}
            to={`/patients/${serializeListIdForRoute({
              ...listDescriptor,
              offset: 0,
              managementStatus: BloodPressureManagementStatus.ESTABLISHING_CARE,
            })}`}
          >
            {getListTitleByManagementStatus(BloodPressureManagementStatus.ESTABLISHING_CARE)}
          </LinkTab>
          <LinkTab
            {...getTabProps(2)}
            i13n={i13n}
            to={`/patients/${serializeListIdForRoute({
              ...listDescriptor,
              offset: 0,
              managementStatus: BloodPressureManagementStatus.ONBOARDING,
            })}`}
          >
            {getListTitleByManagementStatus(BloodPressureManagementStatus.ONBOARDING)}
          </LinkTab>
          <LinkTab
            {...getTabProps(3)}
            i13n={i13n}
            to={`/patients/${serializeListIdForRoute({
              ...listDescriptor,
              offset: 0,
              managementStatus: BloodPressureManagementStatus.MAINTENANCE,
            })}`}
          >
            {getListTitleByManagementStatus(BloodPressureManagementStatus.MAINTENANCE)}
          </LinkTab>
          <LinkTab
            {...getTabProps(4)}
            i13n={i13n}
            to={`/patients/${serializeListIdForRoute({
              ...listDescriptor,
              offset: 0,
              managementStatus: BloodPressureManagementStatus.DEACTIVATED,
            })}`}
          >
            {getListTitleByManagementStatus(BloodPressureManagementStatus.DEACTIVATED)}
          </LinkTab>
        </TabList>
        <div className="flex-1"></div>
        <Button className="pr-4 mr-3" onClick={openCreatePatient} variant={ButtonVariant.PRIMARY}>
          <span className="mx-2">Create Patient</span>
          <SvgIcon Icon={PlusCircle} size={IconSize.MEDIUM} />
        </Button>
        <Menu align="end" menuButton={renderMenuButton} onMenuChange={onMenuChange} portal>
          <MenuItem>
            <a
              className="w-full"
              target="_blank"
              rel="noreferrer"
              href="https://fuzzy-adventure-y28r5yq.pages.github.io/"
            >
              Task Workflows
            </a>
          </MenuItem>
          <MenuItem onClick={onBpReportDownloadClick}>Download all patient BP data</MenuItem>
          <MenuItem onClick={onChatSummariesDownloadClick}>Download chat summaries</MenuItem>
        </Menu>
      </div>
      <div className="px-6 py-3 flex">
        <div className="flex-1 flex justify-start h-6">
          <PractitionerFilter i13n={i13n} listId={listId} />
          <AcquisitionChannelFilter i13n={i13n} listId={listId} />
          <ClearFiltersButton i13n={i13n} listId={listId} />
        </div>
        {(totalCount ?? 0) > 0 ? (
          <Pill>
            Patients{' '}
            <span className="bg-rivaOffblack-300 px-2 ml-1 rounded-full inline-block h-4 leading-4">{totalCount}</span>
          </Pill>
        ) : null}
      </div>
      {isCreatePatientOpen && (
        <AddPatientModal isOpen={isCreatePatientOpen} close={closeCreatePatient} key={String(isCreatePatientOpen)} />
      )}
    </header>
  )
}

export default PatientListHeader

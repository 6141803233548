import {
  ChangeEventHandler,
  FormEventHandler,
  ReactElement,
  RefCallback,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PractitionerFilter from '../../patients/panel/patient-list-header/practitioner-filter'
import AcquisitionChannelFilter from '../../patients/panel/patient-list-header/acquisition-channel-filter'
import ClearFiltersButton from '../../patients/panel/patient-list-header/clear-filters-button'
import Pill from '../../../ui/pill'
import { useAppSelector } from '../../../../redux'
import { getListTotalCount } from '../../../../redux/selectors/lists'
import { buildUnpagedListId, parseListId, serializeListIdForRoute } from '../../../../utils/lists'
import TextInput from '../../../ui/text-input'
import { Button, ButtonVariant } from '../../../ui/button'
import { SvgIcon } from '../../../ui/icon'
import { ReactComponent as SearchIcon } from '../../../icons/outline/search.svg'
import { Routes } from '../../../../types/route'
import { track } from '../../../../i13n'
import VIPFilter from './vip-filter'

interface Props {
  listId: string
}

const SearchHeader = ({ listId }: Props): ReactElement => {
  const history = useHistory()
  const unpagedListId = useMemo(() => buildUnpagedListId(listId), [listId])
  const totalCount = useAppSelector((state) => getListTotalCount(state, unpagedListId))
  const { query } = parseListId(listId)
  const [searchQuery, setSearchQuery] = useState(query ?? '')

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchQuery(event.target.value)
  }, [])
  const onSearchBoxClick = useCallback(() => {
    track('Search Box Clicked')
  }, [])
  const onSearchButtonClick = useCallback(() => {
    track('Search Button Clicked')
  }, [])
  const onSubmit = useCallback<FormEventHandler>(
    (event) => {
      event.preventDefault()

      if (!searchQuery) {
        return
      }

      const { offset, ...descriptor } = parseListId(listId)

      track('Search Query Submitted', {
        'Search term': searchQuery,
      })

      history.push(
        generatePath(Routes.SEARCH, {
          serializedListId: serializeListIdForRoute({
            ...descriptor,
            query: searchQuery || undefined,
          }),
        }),
      )
    },
    [history, listId, searchQuery],
  )

  const searchRef = useCallback<RefCallback<HTMLInputElement>>((element) => {
    if (element) {
      element.focus()
    }
  }, [])

  useEffect(() => {
    setSearchQuery(query ?? '')
  }, [query])

  return (
    <header className="sticky top-0 bg-white flex-none">
      <form className="py-4 px-3 border-b border-rivaOffblack-200 flex" onSubmit={onSubmit}>
        <TextInput
          className="!w-[504px] rounded-r-none !order-1"
          id="search-input"
          onChange={onChange}
          onClick={onSearchBoxClick}
          placeholder="Search patients"
          ref={searchRef}
          type="search"
          value={searchQuery}
        />
        <Button
          aria-label="Submit search"
          className="order-2 rounded-l-none w-20 flex items-center justify-center"
          onClick={onSearchButtonClick}
          type="submit"
          variant={ButtonVariant.PRIMARY}
        >
          <SvgIcon Icon={SearchIcon} />
        </Button>
      </form>
      <div className="px-6 py-3 flex">
        <div className="flex-1 flex h-6 justify-start">
          <PractitionerFilter listId={listId} />
          <AcquisitionChannelFilter listId={listId} />
          <VIPFilter listId={listId} />
          <ClearFiltersButton listId={listId} />
        </div>
        {(totalCount ?? 0) > 0 ? (
          <Pill>
            Patients{' '}
            <span className="bg-rivaOffblack-300 px-2 ml-1 rounded-full inline-block h-4 leading-4">{totalCount}</span>
          </Pill>
        ) : null}
      </div>
    </header>
  )
}

export default SearchHeader

import clsx from 'clsx'
import { useCombobox } from 'downshift'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import useMousetrap from '../../hooks/use-mousetrap'
import { useAppSelector } from '../../redux'
import { getListSelectedItemId } from '../../redux/selectors/lists'
import Layer from './layer'
import { ListItem } from './list'

interface Props<T> {
  listId: string
  command: string
  entity: ReactNode
  items: T[]
  itemKey: (item: T) => string
  itemToString: (item: T) => string
  onSelected: (item: T) => void
  placeholder?: string
  renderItem: (item: T) => ReactNode
}

const Command: <T>(props: Props<T>) => ReactElement | null = ({
  command,
  entity,
  items,
  itemKey,
  itemToString,
  listId,
  onSelected,
  placeholder,
  renderItem,
}) => {
  const [visibleItems, setVisibleItems] = useState(items)
  const actionableItemId = useAppSelector((state) => getListSelectedItemId(state, listId))

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex, isOpen, reset, openMenu, setInputValue } =
    useCombobox({
      items: visibleItems,
      itemToString: (item) => (item ? itemToString(item) : ''),
      onInputValueChange: ({ inputValue }) => {
        setVisibleItems(
          items.filter((item) =>
            itemToString(item)
              .toLowerCase()
              .includes(inputValue?.toLowerCase() ?? ''),
          ),
        )
      },
      onSelectedItemChange: (changes) => {
        if (changes.selectedItem) {
          onSelected(changes.selectedItem)
        }
      },
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges

        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
            return !changes.selectedItem ? state : changes
          case useCombobox.stateChangeTypes.InputKeyDownEscape:
          case useCombobox.stateChangeTypes.FunctionSelectItem:
            return {
              ...changes,
              isOpen: false,
            }
          case useCombobox.stateChangeTypes.InputChange:
            return {
              ...changes,
              highlightedIndex: 0,
            }
          default:
            return changes
        }
      },
    })

  useMousetrap(command, () => {
    if (actionableItemId) {
      openMenu()
    }
  })

  useEffect(() => {
    if (!isOpen) {
      reset()
    } else {
      setTimeout(() => setInputValue(''), 0)
    }
  }, [isOpen, reset, setInputValue])

  return (
    <Layer>
      <div
        className="absolute top-0 left-0 w-full h-full z-50"
        onMouseMove={(event) => {
          event.stopPropagation()
        }}
        hidden={!isOpen}
      >
        <div className="bg-white absolute top-[100px] left-1/2 -translate-x-1/2 w-[640px] shadow-2xl rounded-lg animate-fadein">
          <div className="px-4 pt-4">{entity}</div>
          <div className="border-b border-rivaOffblack-300">
            <input
              {...getInputProps()}
              type="text"
              className="w-full h-14 outline-none px-5 text-lg"
              placeholder={placeholder}
            ></input>
          </div>
          <ul
            {...getMenuProps({
              className: 'max-h-[200px] overflow-y-auto pt-1',
            })}
          >
            {visibleItems.map((item, index) => (
              <ListItem
                {...getItemProps({
                  className: clsx('cursor-pointer', {
                    'bg-rivaPurple-100': highlightedIndex === index,
                  }),
                  index,
                  item,
                })}
                key={itemKey(item)}
              >
                {renderItem(item)}
              </ListItem>
            ))}
          </ul>
        </div>
      </div>
    </Layer>
  )
}

export default Command

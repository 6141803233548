import React, { ReactElement } from 'react'
import { RecordState } from '../../../../types/record-state'
import { ErrorState } from '../../../ui/error-state'
import { EmptyState } from '../../../ui/empty-state'
import { getPatientLists } from '../../../../redux/selectors/patient-list'
import { useAppSelector } from '../../../../redux'
import HighlightCard from '../../../ui/highlight-card'
import { getListItemIds, getListRecordState } from '../../../../redux/selectors/lists'
import { Routes } from '../../../../types/route'
import { PatientList } from './patient-list'

interface Props {
  listId: string
}

export function Panel({ listId }: Props): ReactElement {
  const patientLists = useAppSelector((state) => getPatientLists(state, listId))
  const patientIds = useAppSelector((state) => getListItemIds(state, listId))
  const patientRecordState = useAppSelector((state) => getListRecordState(state, listId))

  if (patientRecordState === RecordState.ERRORED) {
    return (
      <ErrorState
        header="Sorry, we could not load the patients"
        subtext="Please refresh and try again. If the problem persists, contact support."
      />
    )
  }

  if (!patientIds.length) {
    return (
      <EmptyState
        header="Add a new patient"
        subtext="Once you add a patient, you can monitor their compliance, take notes and more"
      />
    )
  }

  return (
    <React.Fragment>
      {patientLists.activeManagement.length ? (
        <ListBlock
          headerTextPrimary="Active Management"
          headerTextEmpty="Active Management"
          subText="Patient moves from Active Management to Maintenance when their current 12 MA and 7 days prior 12 MA are at or below goal and the care team approves a status change."
          patientIds={patientLists.activeManagement}
        />
      ) : null}

      {patientLists.establishingCare.length ? (
        <ListBlock
          headerTextPrimary="Establishing Care"
          headerTextEmpty="Establishing Care"
          subText="Patient moves from Establishing Care to Active Management when their RH Prescriber and PharmD new patient visits are complete."
          patientIds={patientLists.establishingCare}
        />
      ) : null}

      {patientLists.onboarding.length ? (
        <ListBlock
          headerTextPrimary="Onboarding"
          headerTextEmpty="Onboarding"
          subText="Patient is defaulted into Onboarding status when their account is created. Patient moves from Onboarding to Establishing Care after their Coach onboarding visit is complete."
          patientIds={patientLists.onboarding}
        />
      ) : null}

      {patientLists.maintenance.length ? (
        <ListBlock
          headerTextPrimary="Maintenance"
          headerTextEmpty="Maintenance"
          subText="Patient moves from Maintenance to Active Management when their current 12 MA and 7 days prior 12 MA are above goal or at the care teams discretion."
          patientIds={patientLists.maintenance}
        />
      ) : null}

      {patientLists.inactive.length ? (
        <ListBlock
          headerTextPrimary="Deactivated"
          headerTextEmpty="Deactivated"
          subText="Patient is no longer under Riva Health’s care."
          patientIds={patientLists.inactive}
        />
      ) : null}
    </React.Fragment>
  )
}

interface ListBlockProps {
  headerTextPrimary: string
  headerTextEmpty: string
  subText: string
  patientIds: string[]
}
function ListBlock({ headerTextPrimary, headerTextEmpty, subText, patientIds }: ListBlockProps) {
  const headerText = patientIds.length ? headerTextPrimary : headerTextEmpty
  const title = (
    <React.Fragment>
      {headerText}
      {patientIds.length ? (
        <span className="px-2 font-normal text-base text-rivaOffblack-600">{patientIds.length}</span>
      ) : null}
    </React.Fragment>
  )

  return (
    <HighlightCard className="mb-7" title={title} subtitle={subText}>
      <PatientList patientIds={patientIds} route={Routes.DASHBOARD} />
    </HighlightCard>
  )
}

import { Note } from './note'
import { AuditStatus } from './audit-status'
import { Device } from './device'

export const FETCHING_PATIENTS = 'FETCHING_PATIENTS'
export const FETCH_PATIENTS_FULFILLED = 'FETCH_PATIENTS_FULFILLED'
export const FETCH_PATIENTS_FAILED = 'FETCH_PATIENTS_FAILED'

export const CREATE_PATIENT_FORM_INITIALIZED = 'CREATE_PATIENT_FORM_INITIALIZED'
export const CREATING_PATIENT = 'CREATING_PATIENT'
export const CREATE_PATIENT_FULFILLED = 'CREATE_PATIENT_FULFILLED'
export const CREATE_PATIENT_FAILED = 'CREATE_PATIENT_FAILED'

export const UPDATE_PATIENT_FORM_INITIALIZED = 'UPDATE_PATIENT_FORM_INITIALIZED'
export const UPDATING_PATIENT = 'UPDATING_PATIENT'
export const UPDATE_PATIENT_FULFILLED = 'UPDATE_PATIENT_FULFILLED'
export const UPDATE_PATIENT_FAILED = 'UPDATE_PATIENT_FAILED'

export const DEACTIVATE_PATIENT_FORM_INITIALIZED = 'DEACTIVATE_PATIENT_FORM_INITIALIZED'
export const DEACTIVATING_PATIENT = 'DEACTIVATING_PATIENT'
export const DEACTIVATE_PATIENT_FULFILLED = 'DEACTIVATE_PATIENT_FULFILLED'
export const DEACTIVATE_PATIENT_FAILED = 'DEACTIVATE_PATIENT_FAILED'

export const ACTIVATE_PATIENT_FORM_INITIALIZED = 'ACTIVATE_PATIENT_FORM_INITIALIZED'
export const ACTIVATING_PATIENT = 'ACTIVATING_PATIENT'
export const ACTIVATE_PATIENT_FULFILLED = 'ACTIVATE_PATIENT_FULFILLED'
export const ACTIVATE_PATIENT_FAILED = 'ACTIVATE_PATIENT_FAILED'

export const UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FORM_INITIALIZED =
  'UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FORM_INITIALIZED'
export const UPDATING_BLOOD_PRESSURE_MEASUREMENT_STATUS = 'UPDATING_BLOOD_PRESSURE_MEASUREMENT_STATUS'
export const UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FULFILLED = 'UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FULFILLED'
export const UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FAILED = 'UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FAILED'

export enum PatientType {
  MANAGED = 'MANAGED',
  UNMANAGED = 'UNMANAGED',
  VIP = 'VIP',
}

export interface PatientCareTeam {
  coachId: string
  pharmacistId: string
  prescriberId: string
}

export interface Patient {
  acquisitionChannel: string
  bloodPressureGoal: BloodPressureGoal
  bloodPressureManagementStatus: BloodPressureManagementStatus
  bloodPressureNotificationDisabled: boolean
  careTeam?: PatientCareTeam | null
  careTeamIds: string[]
  created: AuditStatus
  dateOfBirth: string
  departmentId: string
  devices?: Device[] | null
  deviceType: string
  email: string
  firstName: string
  id: string
  lastName: string
  medicalRecordNumber: string
  personId: string
  phoneNumber: string
  photoUrl: string
  preferredName: string
  primaryChatChannel: string
  pronouns: string
  referringProvider: string
  communicationPreferences: CommunicationPreferences
  reminderTimes: ReminderTimes
  status: string
  timeZone: string
  type: PatientType
}

export interface CreatePatientPayload {
  coachId: string
  acquisitionChannel: string
  communicationPreferences: CommunicationPreferences
  departmentId: string
  deviceType: string
  dateOfBirth: string
  email: string
  firstName: string
  lastName: string
  medicalRecordNumber: string
  pharmacistId: string
  phoneNumber: string
  preferredName: string
  prescriberId: string
  pronouns: string
  referringProvider: string
  reminderTimes: ReminderTimes
  type: PatientType

  driversLicenseNumber?: string
  driversLicenseExpirationDate?: string
  driversLicenseStateId?: string
  streetAddress?: string
  city?: string
  locationState?: string
  zipCode?: string
  emergencyContactName?: string
  emergencyContactPhoneNumber?: string
  emergencyContactRelationship?: string
  consentToCall?: boolean
  consentToText?: boolean
  legalSex?: string
  sexAssignedAtBirth?: string
  maritalStatus?: string
  language?: string
  race?: string
  ethnicity?: string
}

export type UpdatePatientPayload = Pick<
  Partial<Patient>,
  | 'acquisitionChannel'
  | 'bloodPressureGoal'
  | 'bloodPressureManagementStatus'
  | 'bloodPressureNotificationDisabled'
  | 'dateOfBirth'
  | 'departmentId'
  | 'deviceType'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'medicalRecordNumber'
  | 'communicationPreferences'
  | 'preferredName'
  | 'pronouns'
  | 'referringProvider'
  | 'reminderTimes'
  | 'status'
  | 'type'
> & {
  coachId?: string
  pharmacistId?: string
  prescriberId?: string

  driversLicenseNumber?: string
  driversLicenseExpirationDate?: string
  driversLicenseStateId?: string
  streetAddress?: string
  city?: string
  locationState?: string
  zipCode?: string
  emergencyContactName?: string
  emergencyContactPhoneNumber?: string
  emergencyContactRelationship?: string
  consentToCall?: boolean
  consentToText?: boolean
  legalSex?: string
  sexAssignedAtBirth?: string
  maritalStatus?: string
  language?: string
  race?: string
  ethnicity?: string
}

export enum PatientStatus {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
}

export enum BloodPressureManagementStatus {
  ONBOARDING = 'ONBOARDING',
  ESTABLISHING_CARE = 'ESTABLISHING_CARE',
  ACTIVE_MANAGEMENT = 'ACTIVE_MANAGEMENT',
  MAINTENANCE = 'MAINTENANCE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface BloodPressureGoal {
  systolicBloodPressure: number
  diastolicBloodPressure: number
  updated: {
    at: string
    by: string
  }
}

export interface CommunicationPreferences {
  dayOfWeek: string
  hour: string
}

export interface ReminderTime {
  hour: number
  minute: number
}

export interface ReminderTimes {
  am: ReminderTime
  pm: ReminderTime
}

export interface PatientResponse {
  count: number
  patients: Patient[]
  totalCount: number
}

export interface PatientMap {
  [patientId: string]: Patient
}

export interface FetchingPatientsAction {
  type: typeof FETCHING_PATIENTS
  payload: {
    listId: string
  }
}

export interface FetchPatientsFulfilledAction {
  type: typeof FETCH_PATIENTS_FULFILLED
  payload: {
    listId: string
    patients: Patient[]
    totalCount: number
  }
}

export interface FetchPatientsFailedAction {
  type: typeof FETCH_PATIENTS_FAILED
  payload: {
    listId: string
  }
}

export interface CreatePatientFormInitialized {
  type: typeof CREATE_PATIENT_FORM_INITIALIZED
  payload: undefined
}

export interface CreatingPatient {
  type: typeof CREATING_PATIENT
  payload: undefined
}

export interface CreatePatientFulfilled {
  type: typeof CREATE_PATIENT_FULFILLED
  payload: {
    listId: string
    patient: Patient
  }
}

export interface CreatePatientFailed {
  type: typeof CREATE_PATIENT_FAILED
  payload: {
    error: Error
  }
}

export interface UpdatePatientFormInitialized {
  type: typeof UPDATE_PATIENT_FORM_INITIALIZED
  payload: undefined
}

export interface UpdatingPatient {
  type: typeof UPDATING_PATIENT
  payload: undefined
}

export interface UpdatePatientFulfilled {
  type: typeof UPDATE_PATIENT_FULFILLED
  payload: {
    patient: Patient
  }
}

export interface UpdatePatientFailed {
  type: typeof UPDATE_PATIENT_FAILED
  payload: {
    error: Error
  }
}

export interface DeactivatingPatient {
  type: typeof DEACTIVATING_PATIENT
  payload: undefined
}

export interface DeactivatePatientFulfilled {
  type: typeof DEACTIVATE_PATIENT_FULFILLED
  payload: {
    note: Note
    patient: Patient
  }
}

export interface DeactivatePatientFailed {
  type: typeof DEACTIVATE_PATIENT_FAILED
  payload: {
    error: Error
  }
}

export interface DeactivatePatientFormInitialized {
  type: typeof DEACTIVATE_PATIENT_FORM_INITIALIZED
  payload: undefined
}

export interface ActivatingPatient {
  type: typeof ACTIVATING_PATIENT
  payload: undefined
}

export interface ActivatePatientFulfilled {
  type: typeof ACTIVATE_PATIENT_FULFILLED
  payload: {
    note: Note
    patient: Patient
  }
}

export interface ActivatePatientFailed {
  type: typeof ACTIVATE_PATIENT_FAILED
  payload: {
    error: Error
  }
}

export interface ActivatePatientFormInitialized {
  type: typeof ACTIVATE_PATIENT_FORM_INITIALIZED
  payload: undefined
}

export interface UpdateBloodPressureManagementStatusFormInitialized {
  type: typeof UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FORM_INITIALIZED
  payload: undefined
}

export interface UpdatingBloodPressureManagementStatus {
  type: typeof UPDATING_BLOOD_PRESSURE_MEASUREMENT_STATUS
  payload: undefined
}

export interface UpdateBloodPressureManagementStatusFulfilled {
  type: typeof UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FULFILLED
  payload: {
    patient: Patient
  }
}

export interface UpdateBloodPressureManagementStatusFailed {
  type: typeof UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FAILED
  payload: {
    error: Error
  }
}

export enum AcquisitionChannel {
  D2C = 'D2C',
  CANVAS_CREDIT_UNION = 'CanvasCreditUnion',
  UCH = 'UCH',
  N_A = 'N/A',
}

export const AcquisitionChannelNames: Record<AcquisitionChannel, string> = {
  [AcquisitionChannel.CANVAS_CREDIT_UNION]: 'Canvas Credit Union',
  [AcquisitionChannel.N_A]: 'Not applicable',
  [AcquisitionChannel.D2C]: 'Direct to consumer',
  [AcquisitionChannel.UCH]: 'UCHealth',
}

export enum MedicalRecordSource {
  ATHENA = 'ATHENA',
  EPIC = 'EPIC',
  UNKNOWN = 'UNKNOWN',
}

export enum DepartmentId {
  COLORADO = '1',
  FLORIDA = '8',
}

export enum MaritalStatus {
  Divorced = 'D',
  Married = 'M',
  Single = 'S',
  Unknown = 'U',
  Widowed = 'W',
  Separated = 'X',
  Partner = 'P',
}

export const MaritalStatusToText = {
  [MaritalStatus.Divorced]: 'Divorced',
  [MaritalStatus.Married]: 'Married',
  [MaritalStatus.Single]: 'Single',
  [MaritalStatus.Unknown]: 'Unknown',
  [MaritalStatus.Widowed]: 'Widowed',
  [MaritalStatus.Separated]: 'Separated',
  [MaritalStatus.Partner]: 'Partner',
}

export enum LanguageISO6392Code {
  ARABIC = 'ara',
  CHINESE = 'zho',
  ENGLISH = 'eng',
  POLISH = 'pol',
  PORTUGUESE = 'por',
  RUSSIAN = 'rus',
  SOMALI = 'som',
  SPANISH = 'spa',
  VIETNAMESE = 'vie',
  PATIENT_DECLINED = 'declined',
}

export const LanguageCodeToName = {
  [LanguageISO6392Code.ARABIC]: 'Arabic',
  [LanguageISO6392Code.CHINESE]: 'Chinese',
  [LanguageISO6392Code.ENGLISH]: 'English',
  [LanguageISO6392Code.POLISH]: 'Polish',
  [LanguageISO6392Code.PORTUGUESE]: 'Portuguese',
  [LanguageISO6392Code.RUSSIAN]: 'Russian',
  [LanguageISO6392Code.SOMALI]: 'Somali',
  [LanguageISO6392Code.SPANISH]: 'Spanish',
  [LanguageISO6392Code.VIETNAMESE]: 'Vietnamese',
  [LanguageISO6392Code.PATIENT_DECLINED]: 'Patient declined',
}

// See https://terminology.hl7.org/2.0.0/CodeSystem-v3-Race.html
export enum HL7RaceCode {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = '1002-5',
  ASIAN = '2028-9',
  BLACK_OR_AFRICAN_AMERICAN = '2054-5',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = '2076-8',
  WHITE = '2106-3',
  OTHER_RACE = '2131-1',
  PATIENT_DECLINED = 'declined',
}

export const RaceCodeToName = {
  [HL7RaceCode.AMERICAN_INDIAN_OR_ALASKA_NATIVE]: 'American Indian or Alaska native',
  [HL7RaceCode.ASIAN]: 'Asian',
  [HL7RaceCode.BLACK_OR_AFRICAN_AMERICAN]: 'Black or African American',
  [HL7RaceCode.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER]: 'Native Hawaiian or other Pacific islander',
  [HL7RaceCode.WHITE]: 'White',
  [HL7RaceCode.OTHER_RACE]: 'Other race',
  [HL7RaceCode.PATIENT_DECLINED]: 'Patient declined',
}

export enum HL7EthniticyCode {
  HISPANIC_OR_LATINO = '2135-2',
  SPANIARD = '2137-8',
  MEXICAN = '2148-5',
  CENTRAL_AMERICAN = '2155-0',
  SOUTH_AMERICAN = '2165-9',
  LATIN_AMERICAN = '2178-2',
  PUERTO_RICAN = '2180-8',
  CUBAN = '2182-4',
  DOMINICAN = '2184-0',
  NOT_HISPANIC_OR_LATINO = '2186-5',
  PATIENT_DECLINED = 'declined',
}

export const EthnicityCodeToName = {
  [HL7EthniticyCode.HISPANIC_OR_LATINO]: 'Hispanic or Latino',
  [HL7EthniticyCode.SPANIARD]: 'Spaniard',
  [HL7EthniticyCode.MEXICAN]: 'Mexican',
  [HL7EthniticyCode.CENTRAL_AMERICAN]: 'Central American',
  [HL7EthniticyCode.SOUTH_AMERICAN]: 'South American',
  [HL7EthniticyCode.LATIN_AMERICAN]: 'Latin American',
  [HL7EthniticyCode.PUERTO_RICAN]: 'Puerto Rican',
  [HL7EthniticyCode.CUBAN]: 'Cuban',
  [HL7EthniticyCode.DOMINICAN]: 'Dominican',
  [HL7EthniticyCode.NOT_HISPANIC_OR_LATINO]: 'Not Hispanic or Latino',
  [HL7EthniticyCode.PATIENT_DECLINED]: 'Patient declined',
}

export enum Pronouns {
  SHE_HER = 'she/her',
  HE_HIM = 'he/him',
  THEY_THEM = 'they/them',
}

import { MouseEventHandler, ReactElement, useCallback, useMemo } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { Button, ButtonSize, ButtonVariant } from '../../../../ui/button'
import { IconSize, SvgIcon } from '../../../../ui/icon'
import { ReactComponent as TrashIcon } from '../../../../icons/outline/trash.svg'
import { parseListId, serializeListIdForRoute } from '../../../../../utils/lists'
import { I13n } from '../../../../../types/i13n'
import { track } from '../../../../../i13n'
import { PatientType } from '../../../../../types/patient'
import useGetRefreshRoute from './use-get-refresh-route'

interface Props {
  i13n?: Partial<I13n>
  listId: string
}

const ClearFiltersButton = ({ i13n, listId }: Props): ReactElement | null => {
  const refreshRoute = useGetRefreshRoute()
  const match = useRouteMatch<{ patientId: string }>(refreshRoute)
  const { acquisitionChannel, patientType, practitionerId, ...descriptor } = useMemo(
    () => parseListId(listId),
    [listId],
  )
  const hasActiveFilters =
    !!acquisitionChannel?.length || !!practitionerId?.length || patientType?.includes(PatientType.VIP)
  const history = useHistory()

  const onClick = useCallback<MouseEventHandler>(() => {
    track(i13n?.eventName ?? 'Filters Cleared', i13n?.properties)
    history.push(
      generatePath(refreshRoute, {
        patientId: match?.params.patientId,
        serializedListId: serializeListIdForRoute({
          ...descriptor,
          patientType: [PatientType.MANAGED],
        }),
      }),
    )
  }, [descriptor, history, i13n?.eventName, i13n?.properties, match?.params.patientId, refreshRoute])

  if (!hasActiveFilters) {
    return null
  }

  return (
    <Button className="ml-2" onClick={onClick} size={ButtonSize.XXS} variant={ButtonVariant.SECONDARY}>
      <SvgIcon Icon={TrashIcon} size={IconSize.X_SMALL} />
      <span className="ml-1">Clear filters</span>
    </Button>
  )
}

export default ClearFiltersButton

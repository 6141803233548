import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const FormTitle = ({ children }: Props): ReactElement => {
  return <h3 className="uppercase text-rivaOffblack-700 text-xxs font-extrabold col-span-2">{children}</h3>
}

export default FormTitle

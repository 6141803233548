import {
  ActionCreator,
  CursorMovedAction,
  CursorMovedToTheTopAction,
  CursorResetAction,
  CursorSetByIdAction,
  CURSOR_MOVED,
  CURSOR_MOVED_TO_THE_TOP,
  CURSOR_RESET,
  CURSOR_SET_BY_ID,
} from '../../types/actions'

export const moveCursor: ActionCreator<CursorMovedAction> = (payload) => ({
  type: CURSOR_MOVED,
  payload,
})

export const cursorReset: ActionCreator<CursorResetAction> = (payload) => ({
  type: CURSOR_RESET,
  payload,
})

export const moveCursorToTheTop: ActionCreator<CursorMovedToTheTopAction> = (payload) => ({
  type: CURSOR_MOVED_TO_THE_TOP,
  payload,
})

export const setCursor: ActionCreator<CursorSetByIdAction> = (payload) => ({
  type: CURSOR_SET_BY_ID,
  payload,
})

import { ActionCreator } from '../../types/actions'
import {
  BloodPressureMeasurement,
  FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED,
  FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED,
  FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FAILED,
  FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FULFILLED,
  FetchBloodPressureMeasurementsFailed,
  FetchBloodPressureMeasurementsFulfilled,
  FetchCuffMeasurementDownloadUrlFailed,
  FetchCuffMeasurementDownloadUrlFulfilled,
  FETCHING_BLOOD_PRESSURE_MEASUREMENTS,
  FETCHING_CUFF_MEASUREMENT_DOWNLOAD_URL,
  FetchingBloodPressureMeasurements,
  FetchingCuffMeasurementDownloadUrl,
  UPDATE_BLOOD_PRESSURE_MEASUREMENT_FAILED,
  UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED,
  UPDATE_CUFF_READING_FORM_INITIALIZED,
  UpdateBloodPressureMeasurementFailed,
  UpdateBloodPressureMeasurementFulfilled,
  UpdateCuffReadingFormInitialized,
  UPDATING_BLOOD_PRESSURE_MEASUREMENT,
  UpdatingBloodPressureMeasurement,
} from '../../types/blood-pressure-measurement'
import { Note } from '../../types/note'

export const fetchingBloodPressureMeasurements: ActionCreator<FetchingBloodPressureMeasurements> = (payload) => ({
  type: FETCHING_BLOOD_PRESSURE_MEASUREMENTS,
  payload,
})

export const fetchBloodPressureMeasurementsFulfilled: ActionCreator<FetchBloodPressureMeasurementsFulfilled> = (
  payload,
) => ({
  type: FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED,
  payload,
})

export const fetchBloodPressureMeasurementsFailed: ActionCreator<FetchBloodPressureMeasurementsFailed> = (payload) => ({
  type: FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED,
  payload,
})

export function updateCuffReadingFormInitialized(): UpdateCuffReadingFormInitialized {
  return {
    type: UPDATE_CUFF_READING_FORM_INITIALIZED,
    payload: undefined,
  }
}

export function updatingBloodPressureMeasurement(): UpdatingBloodPressureMeasurement {
  return {
    type: UPDATING_BLOOD_PRESSURE_MEASUREMENT,
    payload: undefined,
  }
}

export function updateBloodPressureMeasurementFulfilled(
  bloodPressureMeasurement: BloodPressureMeasurement,
  note: Note,
): UpdateBloodPressureMeasurementFulfilled {
  return {
    type: UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED,
    payload: {
      bloodPressureMeasurement,
      note,
    },
  }
}

export function updateBloodPressureMeasurementFailed(error: Error): UpdateBloodPressureMeasurementFailed {
  return {
    type: UPDATE_BLOOD_PRESSURE_MEASUREMENT_FAILED,
    payload: {
      error,
    },
  }
}

export function fetchingCuffMeasurementDownloadUrl(measurementId: string): FetchingCuffMeasurementDownloadUrl {
  return {
    type: FETCHING_CUFF_MEASUREMENT_DOWNLOAD_URL,
    payload: {
      measurementId,
    },
  }
}

export function fetchCuffMeasurementDownloadUrlFulfilled(
  measurementId: string,
  signedUrl: string,
  expirationTime: string,
): FetchCuffMeasurementDownloadUrlFulfilled {
  return {
    type: FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FULFILLED,
    payload: {
      measurementId,
      signedUrl,
      expirationTime,
    },
  }
}

export function fetchCuffMeasurementDownloadUrlFailed(
  error: Error,
  measurementId: string,
): FetchCuffMeasurementDownloadUrlFailed {
  return {
    type: FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FAILED,
    payload: {
      measurementId,
      error,
    },
  }
}

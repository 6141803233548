import { ReactElement } from 'react'

const TaskLoadingItem = (): ReactElement => {
  return (
    <article className="box-border mb-[2px] cursor-pointer bg-white animate-pulse">
      <header className="flex flex-row items-center h-14 px-7">
        <div className="bg-rivaOffblack-200 rounded-full w-5 h-5 mr-3"></div>
        <div className="bg-rivaOffblack-200 rounded h-3 w-[268px]"></div>
        <div className="bg-rivaOffblack-200 rounded h-3 w-[92px] ml-auto"></div>
      </header>
      <footer className="flex py-4 px-7 border-t border-dashed border-rivaOffblack-300">
        <div className="bg-rivaOffblack-200 rounded-full h-6 w-16"></div>
        <div className="bg-rivaOffblack-200 rounded-full h-6 w-[84px] ml-2"></div>
        <div className="bg-rivaOffblack-200 rounded h-6 w-32 ml-auto"></div>
        <div className="bg-rivaOffblack-200 rounded h-6 w-6 ml-2"></div>
      </footer>
    </article>
  )
}

export default TaskLoadingItem

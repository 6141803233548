import { ReactElement } from 'react'
import Mustache from 'mustache'
import { useAppSelector } from '../../../../redux'
import { getTaskById } from '../../../../redux/selectors/tasks'
import useGetTemplateData from './use-get-template-data'

interface Props {
  taskId: string
}

const compiled = new Set<string>()

const TaskDescription = ({ taskId }: Props): ReactElement => {
  const description = useAppSelector((state) => getTaskById(state, taskId)?.description ?? '')
  const templateData = useGetTemplateData({ taskId })
  let renderedDescription = description

  try {
    if (!compiled.has(description)) {
      compiled.add(description)

      Mustache.parse(description)
    }

    renderedDescription = Mustache.render(description, templateData)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }

  return (
    <div className="mt-4 pt-4 whitespace-pre-wrap text-sm font-medium leading-6 border-t border-dashed border-t-rivaOffblack-300">
      {renderedDescription}
    </div>
  )
}

export default TaskDescription

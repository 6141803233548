import { RecordState } from './record-state'

export const APP_LOADING = 'APP_LOADING'
export const APP_LOADED = 'APP_LOADED'
export const APP_FAILED_TO_LOAD = 'APP_FAILED_TO_LOAD'

export interface LoadingApp {
  type: typeof APP_LOADING
  payload: undefined
}

export interface LoadedApp {
  type: typeof APP_LOADED
  payload: {
    loggedInPersonId: string
  }
}

export interface FailedAppLoad {
  type: typeof APP_FAILED_TO_LOAD
  payload: {
    error: Error
  }
}

export interface AppState {
  recordState: RecordState
  loggedInPersonId: string
}

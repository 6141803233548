import { ReactElement, useCallback, useState } from 'react'
import { DateTime } from 'luxon'
import { ButtonVariant } from '../ui/button'
import DatePicker from '../ui/datepicker'
import Confirm from './confirm'

interface Props {
  isOpen: boolean
  onAccept: (date: string) => void
  onCancel: () => void
  patientId: string
  studyId: string
}

const WithdrawFromStudyModal = ({ isOpen, onAccept, onCancel }: Props): ReactElement => {
  const [date, setDate] = useState(DateTime.now().toISODate() ?? '')
  const handleAccept = useCallback(() => {
    onAccept(date)
  }, [date, onAccept])
  const onChange = useCallback((date: Date) => {
    setDate(DateTime.fromJSDate(date).toISODate() ?? '')
  }, [])

  return (
    <Confirm
      confirmButtonText="Withdraw patient"
      confirmButtonVariant={ButtonVariant.ERROR}
      disabled={date === ''}
      isOpen={isOpen}
      onAccept={handleAccept}
      onCancel={onCancel}
      subtitle="Record when the participant withdrew from the study"
      title="Select withdrawal date"
    >
      <div className="pb-10 pt-2">
        <DatePicker maxDate={new Date()} inline onChange={onChange} />
      </div>
    </Confirm>
  )
}

export default WithdrawFromStudyModal

import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
  level: number
}

const Heading = ({ children, level }: Props): ReactElement | null => {
  switch (level) {
    case 2:
      return <h2 className="font-semibold text-lg flex-1 m-0">{children}</h2>
    default:
      throw new Error(`Unexpected level ${level}`)

      return null
  }
}

export default Heading

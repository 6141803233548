import { ReactElement, useCallback, useMemo } from 'react'
import clsx from 'clsx'
import { generatePath, useHistory } from 'react-router-dom'
import deepEquals from 'lodash.isequal'
import MultiCombobox, { ComboboxOptionItem } from '../../../../ui/multi-combobox'
import { useAppSelector } from '../../../../../redux'
import { getAllPractitionerNamesAndIds } from '../../../../../redux/selectors/practitioner'
import { ButtonSize, ButtonVariant, MenuButton } from '../../../../ui/button'
import { ReactComponent as SthetoscopeIcon } from '../../../../icons/outline/stethoscope.svg'
import { IconSize, SvgIcon } from '../../../../ui/icon'
import { ReactComponent as ChevronDownIcon } from '../../../../icons/solid/chevron-down.svg'
import { ReactComponent as XSmallIcon } from '../../../../icons/outline/x-small.svg'
import { parseListId, serializeListIdForRoute } from '../../../../../utils/lists'
import PractitionerAvatar, { AvatarSize } from '../../../../ui/practitioner-avatar'
import IconButton from '../../../../ui/icon-button'
import { I13n } from '../../../../../types/i13n'
import { track } from '../../../../../i13n'
import { getLoggedInPractitionerId } from '../../../../../redux/selectors/app'
import useGetRefreshRoute from './use-get-refresh-route'

export interface Item extends ComboboxOptionItem {
  name: string
}

interface Props {
  i13n?: Partial<Omit<I13n, 'eventName'>>
  listId: string
}

const PractitionerFilter = ({ i13n, listId }: Props): ReactElement => {
  const refreshRoute = useGetRefreshRoute()
  const loggedInPractitionerId = useAppSelector(getLoggedInPractitionerId)
  const history = useHistory()
  const practitioners = useAppSelector(getAllPractitionerNamesAndIds, deepEquals)
  const loggedInPractitioner = practitioners.find(({ id }) => id === loggedInPractitionerId)
  const otherPractitioners = useMemo(
    () => practitioners.filter(({ id }) => id !== loggedInPractitionerId),
    [loggedInPractitionerId, practitioners],
  )
  const { practitionerId } = parseListId(listId)
  const items = useMemo(
    (): Item[] => [
      ...(loggedInPractitioner
        ? [
            {
              hasDivider: true,
              name: loggedInPractitioner.name,
              type: 'option',
              value: loggedInPractitioner.id,
            } as Item,
          ]
        : []),
      ...otherPractitioners.map(
        ({ id, name }): Item => ({
          name,
          type: 'option',
          value: id,
        }),
      ),
    ],
    [loggedInPractitioner, otherPractitioners],
  )
  const selectedPractitioners = useMemo(() => {
    return practitioners
      .filter(({ id }) => practitionerId?.includes(id))
      .map(
        ({ id, name }): Item => ({
          name,
          type: 'option',
          value: id,
        }),
      )
  }, [practitionerId, practitioners])

  const clearSelection = useCallback(() => {
    const { practitionerId, ...descriptor } = parseListId(listId)

    history.push(
      generatePath(refreshRoute, {
        serializedListId: serializeListIdForRoute(descriptor),
      }),
    )
  }, [history, listId, refreshRoute])

  const itemToKey = useCallback(({ value }: Item) => value, [])
  const itemMatches = useCallback(
    (inputValue: string, { name }: Item) => name.toLowerCase().includes(inputValue.toLowerCase()),
    [],
  )
  const renderItem = useCallback(({ name }: Item) => <span className="flex-1 truncate">{name}</span>, [])
  const renderInput = useCallback(
    (isOpen: boolean, { className, onBlur, onClick, onFocus, ...props }: Record<string, unknown>) => {
      return (
        <span className="inline-block relative">
          <MenuButton
            {...props}
            onClick={(event) => {
              track('Practitioner Filter Clicked', i13n?.properties)
              if (typeof onClick === 'function') {
                onClick(event)
              }
            }}
            className="!px-1"
            active={isOpen}
            size={ButtonSize.XXS}
            variant={ButtonVariant.SECONDARY}
          >
            {selectedPractitioners.length > 0 ? (
              <span
                className={clsx('relative inline-block h-5', {
                  'pr-[9px]': selectedPractitioners.length > 1,
                })}
              >
                <PractitionerAvatar
                  border
                  className="!border-white"
                  practitionerId={selectedPractitioners[0].value}
                  size={AvatarSize.SMALL}
                />
                {selectedPractitioners.length > 1 ? (
                  <PractitionerAvatar
                    border
                    className="!bg-rivaOffblack-200 absolute w-5 h-5 -translate-x-[9px] !border-white"
                    practitionerId={selectedPractitioners[1].value}
                    size={AvatarSize.SMALL}
                  />
                ) : null}
              </span>
            ) : (
              <SvgIcon Icon={SthetoscopeIcon} size={IconSize.X_SMALL} />
            )}
            <span className="mx-1">
              {selectedPractitioners.length > 1
                ? `${selectedPractitioners.length} practitioners`
                : selectedPractitioners.length === 1
                ? selectedPractitioners[0].name
                : 'Practitioners'}
            </span>
            <SvgIcon
              className={clsx('transition-transform', {
                'rotate-180': selectedPractitioners.length === 0 && isOpen,
                invisible: selectedPractitioners.length > 0,
              })}
              Icon={ChevronDownIcon}
              size={IconSize.X_SMALL}
            />
          </MenuButton>
          {selectedPractitioners.length > 0 ? (
            <IconButton
              aria-label="Clear practitioners"
              borderless
              className="absolute right-1 top-1"
              icon={XSmallIcon}
              onClick={clearSelection}
              size="XXS"
            />
          ) : null}
        </span>
      )
    },
    [clearSelection, i13n?.properties, selectedPractitioners],
  )
  const onChange = useCallback(
    (items: Item[]) => {
      track('Practitioner Filter Submitted', i13n?.properties)
      history.push(
        generatePath(refreshRoute, {
          serializedListId: serializeListIdForRoute({
            ...parseListId(listId),
            offset: 0,
            practitionerId: items.map(({ value }) => value),
          }),
        }),
      )
    },
    [history, i13n?.properties, listId, refreshRoute],
  )

  return (
    <MultiCombobox
      containerClassName="!order-none"
      filtered
      itemMatches={itemMatches}
      items={items}
      itemToKey={itemToKey}
      maxHeight="short"
      onChange={onChange}
      placeholder="Select practitioner"
      placement="bottom-start"
      renderInput={renderInput}
      renderItem={renderItem}
      selectedItems={selectedPractitioners}
    />
  )
}

export default PractitionerFilter

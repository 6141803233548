import mousetrap from 'mousetrap'
import { useEffect, useRef } from 'react'

type KeyboardHandler = Parameters<typeof mousetrap.bind>[1] | null

const useMousetrap = (handlerKey: string, handlerCallback: KeyboardHandler, evtType?: string): void => {
  const actionRef = useRef<KeyboardHandler>(handlerCallback)

  actionRef.current = handlerCallback

  useEffect(() => {
    mousetrap.bind(
      handlerKey,
      (evt, combo) => {
        typeof actionRef.current === 'function' && actionRef.current(evt, combo)
      },
      evtType,
    )

    return () => {
      mousetrap.unbind(handlerKey)
    }
  }, [evtType, handlerKey])
}

export default useMousetrap

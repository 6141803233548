import Ajv from 'ajv'
import { TaskEventCancelCarePathway } from '../../../types/task'
import * as http from '../../http'
import { createApiLogger } from '../../../logger'
import { uuid } from '../../../utils/uuid'
import { TaskEventType } from '../../../__generated__/graphql'
import { cancelCarePathwayEventSchema } from '../../schema/care-pathway-event'

const ajv = new Ajv()

const validate = ajv.compile(cancelCarePathwayEventSchema)

interface TaskEventPayload {
  data: Record<string, unknown>
  eventType: string
  note?: string
  patientId: string
}

interface CancelCarePathwayEventPayload extends TaskEventPayload {
  eventType: TaskEventType.CancelCarePathway
  data: Record<string, never>
}

type CreateEventPayload = CancelCarePathwayEventPayload

const createCarePathwayEvent = async (
  carePathwayId: string,
  event: CreateEventPayload,
  accessToken: string,
): Promise<TaskEventCancelCarePathway> => {
  if (!carePathwayId) {
    throw new Error('Missing care pathway ID')
  }

  const requestId = uuid()
  const logger = createApiLogger('POST', '/v1/care-pathway/:carePathwayId/events', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, statusCode } = await http.post(`/v1/care-pathway/${carePathwayId}/events`, event, {
      accessToken,
      requestId,
    })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    logger.logSuccess({
      statusCode,
    })

    return body
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default createCarePathwayEvent

import { ReactElement } from 'react'
import { AcquisitionChannel, AcquisitionChannelNames } from '../../../types/patient'
import FormLabel from '../form-label'
import { Radio, RadioGroup, RadioLabel, useRadioGroup } from '../radio'

interface Props {
  onChange: (acquisitionChannel: AcquisitionChannel) => void
  required?: boolean
  value?: AcquisitionChannel
}

const AcquisitionChannelRadioGroup = ({ onChange, required, value }: Props): ReactElement => {
  const { getGroupLabelProps, getRadioGroupProps, getRadioProps } = useRadioGroup<AcquisitionChannel>({
    onChange,
    required,
    value,
  })

  return (
    <>
      <FormLabel {...getGroupLabelProps()} order={0} required={required}>
        Source
      </FormLabel>
      <RadioGroup {...getRadioGroupProps()}>
        {Object.values(AcquisitionChannel).map((acquisitionChannel) => {
          return (
            <RadioLabel key={acquisitionChannel} label={AcquisitionChannelNames[acquisitionChannel]}>
              <Radio {...getRadioProps(acquisitionChannel)} />
            </RadioLabel>
          )
        })}
      </RadioGroup>
    </>
  )
}

export default AcquisitionChannelRadioGroup

export enum ReferringProvider {
  ANDREW_VINES_MD = 'Andrew Vines, MD',
  DIANE_YANG_MD = 'Diane Yang, MD',
  ELI_GRILLO_MD = 'Eli Grillo, MD',
  KENJI_KAYE_MD = 'Kenji Kaye, MD',
  RICHARD_PANG_MD = 'Richard Pang, MD',
  NOT_LISTED = 'Provider not listed',
  NOT_APPLICABLE = 'Not applicable',
}

export enum ProviderType {
  MD = 'MD',
  FNP = 'FNP',
}

export interface Provider {
  firstName: string
  id: string
  lastName: string
  preferredName?: string
  prefix: string
  providerType: ProviderType
}

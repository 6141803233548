import { CarePathwayType } from '../__generated__/graphql'

export const getCarePathwayName = (carePathwayType?: CarePathwayType): string => {
  switch (carePathwayType) {
    case CarePathwayType.LifestyleChange:
      return 'Lifestyle Change'
    default:
      return 'Care pathway'
  }
}

import { JSONSchemaType } from 'ajv'
import { AssigneeType, TaskChoice, TaskOption, TaskWithUnknownEvents } from '../../types/task'
import { auditStatusSchema } from './audit-status'
import { taskNoteSchema } from './task-note'
import { unknownTaskEventSchema } from './task-event'

const taskChoiceSchema: JSONSchemaType<TaskChoice> = {
  type: 'object',
  properties: {
    label: {
      type: 'string',
    },
    event: unknownTaskEventSchema,
  },
  required: ['event', 'label'],
}

const taskOptionSchema: JSONSchemaType<TaskOption> = {
  type: 'object',
  properties: {
    choices: {
      type: 'array',
      items: taskChoiceSchema,
    },
    title: {
      type: 'string',
    },
  },
  required: ['choices', 'title'],
}

export const taskWithUnknownEventsSchema: JSONSchemaType<TaskWithUnknownEvents> = {
  type: 'object',
  properties: {
    assignee: {
      type: 'string',
    },
    assigneeType: {
      type: 'string',
      enum: [AssigneeType.AssigneeTypeCareTeam, AssigneeType.AssigneeTypePatient],
    },
    carePathwayId: {
      type: 'string',
    },
    comments: {
      type: 'array',
      items: taskNoteSchema,
      nullable: true,
    },
    copy: {
      type: 'string',
    },
    created: auditStatusSchema,
    description: {
      type: 'string',
    },
    dueDate: {
      type: 'string',
    },
    events: {
      type: 'array',
      items: unknownTaskEventSchema,
      nullable: true,
    },
    id: {
      type: 'string',
    },
    options: {
      type: 'array',
      items: taskOptionSchema,
      nullable: true,
    },
    patientId: {
      type: 'string',
    },
    patientTouchPoint: {
      type: 'boolean',
    },
    potentialAction: {
      type: 'array',
      items: {
        type: 'object',
        required: [],
      },
      nullable: true,
    },
    priority: {
      type: 'number',
    },
    status: {
      type: 'string',
    },
    summary: {
      type: 'string',
    },
    taskType: {
      type: 'string',
    },
    updated: auditStatusSchema,
  },
  required: [
    'assignee',
    'carePathwayId',
    'copy',
    'created',
    'description',
    'dueDate',
    'id',
    'patientId',
    'patientTouchPoint',
    'priority',
    'status',
    'summary',
    'taskType',
    'updated',
  ],
}

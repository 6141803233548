import { ReactElement } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../../../../redux'
import { getTaskCarePathwayId } from '../../../../../redux/selectors/tasks'
import { getCarePathwayById } from '../../../../../redux/selectors/care-pathway'
import { SvgIcon } from '../../../../ui/icon'
import { ReactComponent as LineChartUpIcon } from '../../../../icons/solid/line-chart-up.svg'
import { CarePathwayType } from '../../../../../__generated__/graphql'

interface Props {
  taskId: string
}

const BloodPressureEscalationTaskIcon = ({ taskId }: Props): ReactElement => {
  const carePathwayId = useAppSelector((state) => getTaskCarePathwayId(state, taskId))
  const carePathway = useAppSelector((state) => getCarePathwayById(state, carePathwayId))

  return (
    <SvgIcon
      Icon={LineChartUpIcon}
      className={clsx('text-rivaOffblack-300 flex-none', {
        '-scale-y-100': carePathway?.type === CarePathwayType.LowBloodPressureEscalation,
      })}
    />
  )
}

export default BloodPressureEscalationTaskIcon

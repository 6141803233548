import { ReactElement } from 'react'
import type { MedicationsQueryQuery } from '../../../../__generated__/graphql'
import { SvgIcon } from '../../../ui/icon'
import { ReactComponent as InformationIcon } from '../../../icons/solid/information-circle.svg'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import FormattedDateTime from '../../../ui/formatted-date-time'
import DosageInstruction from './dosage-instruction'

type patient = NonNullable<MedicationsQueryQuery['patient']>
type list = NonNullable<patient['medicationRequests']>

interface Props {
  medicationRequests: NonNullable<list[number]>
}

const MedicationRequest = ({
  medicationRequests: { authoredOn, dispenseRequest, dosageInstruction, medicationReference },
}: Props): ReactElement => {
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [12, 8],
  })

  const initialFill = dispenseRequest?.initialFill

  return (
    <div className="pt-4 pb-3 border-b border-rivaOffblack-200 last:border-none grid grid-cols-[1fr_24px] gap-x-5">
      <div className="row-span-1 text-sm font-bold text-rivaOffblack-700">{medicationReference?.display}</div>
      <div className="row-span-1 row-start-2 text-xs font-medium text-rivaOffblack-500">
        {dosageInstruction?.map((instruction, i) => {
          if (!instruction) {
            return null
          }

          if (instruction.text) {
            return <p key={i}>{instruction.text}</p>
          }

          return <DosageInstruction key={i} instruction={instruction} />
        })}
      </div>
      <div className="row-span-2 row-start-1 flex flex-col justify-end">
        <span className="w-6 h-6" {...getTriggerProps()}>
          <SvgIcon Icon={InformationIcon} className="text-rivaOffblack-700" />
        </span>
        <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
          <table className="mx-7 my-3">
            <thead className="text-xxs text-rivaOffblack-400 text-left">
              <tr>
                {authoredOn ? <th className="font-semibold">Ordered on</th> : null}
                {initialFill ? <th className="pl-10 font-semibold">Quantity</th> : null}
              </tr>
            </thead>
            <tbody>
              <tr>
                {authoredOn ? (
                  <td className="leading-6">
                    <FormattedDateTime value={authoredOn ?? ''} day="numeric" month="numeric" year="numeric" />
                  </td>
                ) : null}
                {initialFill ? (
                  <td className="pl-10 leading-6">
                    {initialFill.quantity?.value} {initialFill.quantity?.unit}
                  </td>
                ) : null}
              </tr>
            </tbody>
          </table>
        </Tooltip>
      </div>
    </div>
  )
}

export default MedicationRequest

import { ChangeEventHandler, ReactElement, useCallback, useEffect, useState } from 'react'
import useTasksApi from '../../api/hooks/use-tasks-api'
import { useAppDispatch } from '../../redux'
import { updateTaskStatus } from '../../redux/thunks/tasks'
import { TaskStatus } from '../../types/task'
import { getStatusText } from '../../utils/tasks'
import FieldSet from '../ui/field-set'
import FormLabel from '../ui/form-label'
import TextArea from '../ui/text-area'
import { track } from '../../i13n'
import Confirm from './confirm'

interface Props {
  i13n?: Record<string, unknown>
  isOpen: boolean
  listId: string
  onCancel: () => void
  onAccept: () => void
  status: TaskStatus
  taskId: string
}

const StatusNoteModal = ({ i13n, isOpen, listId, onCancel, onAccept, status, taskId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const [note, setNote] = useState('')
  const statusText = getStatusText(status)

  const handleTextareaChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((event) => {
    setNote(event.target.value)
  }, [])
  const handleCancel = useCallback(() => {
    onCancel()

    track(`${statusText} Note Cancelled`, {
      'Task ID': taskId,
    })
  }, [onCancel, statusText, taskId])
  const handleConfirmAccept = useCallback(() => {
    onAccept()

    dispatch(
      updateTaskStatus(
        {
          i13n,
          id: taskId,
          listId,
          note,
          status,
        },
        tasksApi,
      ),
    )
  }, [dispatch, i13n, listId, note, onAccept, status, taskId, tasksApi])

  useEffect(() => {
    track(`${statusText} Note Modal Shown`, {
      'Task ID': taskId,
    })
  }, [status, statusText, taskId])

  return (
    <Confirm
      confirmButtonText="Update status"
      disabled={note.length === 0}
      isOpen={isOpen}
      onAccept={handleConfirmAccept}
      onCancel={handleCancel}
      subtitle={`Add a note to document why the task was marked as ${statusText}`}
      title={`Change task status to ${statusText}`}
    >
      <div className="mb-10">
        <FieldSet>
          <TextArea
            id="event-note"
            value={note}
            onChange={handleTextareaChange}
            rows={note.split(/[\n\r]/g).length || 1}
          />
          <FormLabel htmlFor="event-note" required>
            Add a note
          </FormLabel>
        </FieldSet>
      </div>
    </Confirm>
  )
}

export default StatusNoteModal

import { Modifier } from 'react-popper'

export const sameWidthModifier: Modifier<'sameWidth', Record<string, unknown>> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    state.elements.popper.style.minWidth = `${(state.elements.reference as HTMLElement).offsetWidth}px`
  },
}

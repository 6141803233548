import React, { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

interface NavigationData {
  dataTestId: string
  to: string
  text: string
}
interface NavigationProps {
  navigationData: NavigationData[]
}
export function DrawerNavigation({ navigationData }: NavigationProps): ReactElement {
  return (
    <div key="drawer-nav" className="flex">
      {navigationData.map((data: NavigationData) => (
        <NavLink
          key={data.dataTestId}
          data-testid={data.dataTestId}
          className="mr-4 px-4 py-2 text-rivaOffblack-400 tracking-wider font-medium text-sm uppercase border-b-4 border-solid border-rivaPurple border-opacity-0"
          activeClassName="border-opacity-100"
          activeStyle={{
            color: 'rgba(6, 1, 22, 1)', // rivaBlack
          }}
          to={data.to}
        >
          {data.text}
        </NavLink>
      ))}
    </div>
  )
}

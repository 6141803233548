import { createListenerMiddleware } from '@reduxjs/toolkit'
import { datadogLogs } from '@datadog/browser-logs'

export const loggerMiddleware = createListenerMiddleware()

loggerMiddleware.startListening({
  predicate: () => true,
  effect: (action) => {
    // IMPORTANT: we must not log the action payload because the payload
    // can contain Personal Health Information
    if (action.type.endsWith('_FAILED')) {
      datadogLogs.logger.error(`Redux Action: ${action.type}`, {
        componentName: 'action-logger',
        actionType: action.type,
      })
    } else {
      datadogLogs.logger.debug(`Redux Action: ${action.type}`, {
        componentName: 'action-logger',
        actionType: action.type,
      })
    }
  },
})

import { ReactElement } from 'react'
import { useAppSelector } from '../../../../../redux'
import { getTaskById, getTaskCarePathwayId } from '../../../../../redux/selectors/tasks'
import { TaskEventType, TaskType } from '../../../../../__generated__/graphql'
import { getCarePathwayById, getCarePathwayEventById } from '../../../../../redux/selectors/care-pathway'
import { TaskEventLifestyleChangeSurveyResults } from '../../../../../types/care-pathway'
import TaskSurveyItem from './task-survey-item'

interface Props {
  taskId: string
}

const TaskSurveys = ({ taskId }: Props): ReactElement | null => {
  const taskType = useAppSelector((state) => getTaskById(state, taskId)?.taskType)
  const carePathwayId = useAppSelector((state) => getTaskCarePathwayId(state, taskId))
  const eventIds = useAppSelector((state) => getCarePathwayById(state, carePathwayId)?.eventIds)
  const events = useAppSelector((state) => eventIds?.map((id) => getCarePathwayEventById(state, id)))
  const surveyEvents =
    events?.filter(
      (event): event is TaskEventLifestyleChangeSurveyResults =>
        event?.eventType === TaskEventType.LifestyleChangeSurveyResults,
    ) ?? []

  if (taskType !== TaskType.ReviewSurveyResults) {
    return null
  }

  return (
    <div className="mt-3">
      {surveyEvents.map((event) => {
        if (!event) {
          return null
        }

        return (
          <TaskSurveyItem
            key={event.data.submissionId}
            submissionId={event.data.submissionId}
            surveyId={event.data.surveyId}
          />
        )
      })}
    </div>
  )
}

export default TaskSurveys

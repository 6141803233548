import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const DetailsRow = ({ children }: Props): ReactElement => {
  return (
    <div className="border-b border-dashed border-b-rivaOffblack-300 grid auto-cols-fr grid-flow-col gap-x-5 pt-5 pb-2 last:border-b-0 last:pb-0">
      {children}
    </div>
  )
}

export default DetailsRow

import clsx from 'clsx'
import { ReactElement } from 'react'
import { useRouteMatch } from 'react-router-dom'
import useTooltip from '../../../hooks/use-tooltip'
import { useAppSelector } from '../../../redux'
import { getListTotalCount } from '../../../redux/selectors/lists'
import { ListContentType } from '../../../types/lists'
import { buildListId } from '../../../utils/lists'
import { ReactComponent as IconClipboardListOutline } from '../../icons/outline/clipboard-list.svg'
import { ReactComponent as IconClipboardListSolid } from '../../icons/solid/clipboard-list.svg'
import Tooltip from '../../ui/tooltip'
import { SURVEY_QUERY_UNSEEN } from '../../../types/survey'
import { ReactComponent as IconCheckCircleSolid } from '../../icons/solid/check-circle.svg'
import { ReactComponent as IconCheckCircleOutline } from '../../icons/outline/check-circle.svg'
import NewSurveysNavItem from './new-surveys-nav-item'
import NavigationItem from './navigation-item'
import NewIndicator from './new-indicator'

const SurveysNavigationItem = (): ReactElement => {
  const path = '/surveys'
  const isSurveysPageActive = !!useRouteMatch(path)
  const newSurveyListId = buildListId({
    listContentType: ListContentType.SURVEY_SUBMISSION,
    query: SURVEY_QUERY_UNSEEN,
  })
  const newSurveyCount = useAppSelector((state) => getListTotalCount(state, newSurveyListId) ?? 0)
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    offset: [0, 20],
    placement: 'right',
  })

  return (
    <>
      <li
        className={clsx('rounded', {
          'bg-white bg-opacity-10': isSurveysPageActive,
        })}
        {...getTriggerProps()}
      >
        <NavigationItem
          exact
          level="SECONDARY"
          iconLeft={() => (
            <div className="w-6 h-6 relative">
              {isSurveysPageActive ? <IconClipboardListSolid /> : <IconClipboardListOutline />}
              {!isSurveysPageActive && newSurveyCount > 0 ? <NewIndicator /> : null}
            </div>
          )}
          iconRight={() =>
            !isSurveysPageActive && newSurveyCount > 0 ? (newSurveyCount > 99 ? '99+' : newSurveyCount) : undefined
          }
          label="Surveys"
          to={path}
        />
        {isSurveysPageActive && (
          <>
            <NewSurveysNavItem />
            <NavigationItem
              level="SECONDARY"
              to="/surveys/reviewed"
              label="Reviewed"
              iconLeft={({ isActive }) => (isActive ? <IconCheckCircleSolid /> : <IconCheckCircleOutline />)}
            />
          </>
        )}
      </li>
      {!isSurveysPageActive ? (
        <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
          {newSurveyCount === 0
            ? 'No new surveys'
            : newSurveyCount === 1
            ? 'You have 1 new survey'
            : `You have ${newSurveyCount} new surveys`}
        </Tooltip>
      ) : null}
    </>
  )
}

export default SurveysNavigationItem

import React, { ReactElement, useEffect, useState } from 'react'
import deepEquals from 'lodash.isequal'
import { Page, PageHeader } from '../../ui/page'
import { fetchPatients } from '../../../redux/thunks/patient'
import { RecordState } from '../../../types/record-state'
import { LoadingIndicator, LoadingIndicatorSize } from '../../ui/loading-indicator'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { getListRecordState } from '../../../redux/selectors/lists'
import useGetListIdFromRoute from '../../../hooks/use-get-list-id-from-route'
import { getPatientName, getVipIds } from '../../../redux/selectors/patient'
import { Button } from '../../ui/button'
import { UpdatePatientModal } from '../../modals/update-patient'

function VipHeader() {
  return (
    <PageHeader>
      <h1>VIPs</h1>
    </PageHeader>
  )
}

export function VIPs(): ReactElement {
  const dispatch = useAppDispatch()
  const listId = useGetListIdFromRoute()
  const recordState = useAppSelector((state) => getListRecordState(state, listId))
  const arePatientsEmpty = useAppSelector((state) => getVipIds(state).length === 0)
  const vipIds = useAppSelector(getVipIds, deepEquals)

  useEffect(() => {
    dispatch(fetchPatients(listId))
  }, [dispatch, listId])

  if (recordState === RecordState.DOES_NOT_EXIST || (arePatientsEmpty && recordState === RecordState.LOADING)) {
    return <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
  }

  return (
    <Page>
      <VipHeader />
      <section className="bg-white rounded-lg p-3 shadow-sm mb-7 max-w-[50%]">
        <main>
          <ul className="flex flex-col">
            {vipIds.map((vipId) => (
              <VipListItem key={vipId} patientId={vipId} />
            ))}
          </ul>
        </main>
      </section>
    </Page>
  )
}

function VipListItem({ patientId }: { patientId: string }) {
  const name = useAppSelector((state) => getPatientName(state, patientId))
  const [isUpdatePatientModalOpen, setIsUpdatePatientModalOpen] = useState(false)

  return (
    <li className="flex justify-between items-center p-3 list-none border-b border-b-rivaOffblack-100/100 last:border-b-rivaOffblack-100/0">
      <span>{name}</span>
      <Button onClick={() => setIsUpdatePatientModalOpen(true)}>Update VIP</Button>

      <UpdatePatientModal
        isOpen={isUpdatePatientModalOpen}
        close={() => setIsUpdatePatientModalOpen(false)}
        patientId={patientId}
      />
    </li>
  )
}

import { JSONSchemaType } from 'ajv'
import { TaskEventCancelCarePathway, TaskEventCreateCarePathway } from '../../types/task'
import { CarePathwayType, TaskEventType } from '../../__generated__/graphql'
import { auditStatusSchema } from './audit-status'

export const createCarePathwayEventSchema: JSONSchemaType<TaskEventCreateCarePathway> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        carePathwayType: {
          type: 'string',
          enum: [CarePathwayType.LifestyleChange],
        },
      },
      required: ['carePathwayType'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.CreateCarePathway,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['created', 'data', 'eventType', 'id', 'note', 'patientId', 'taskId'],
}

export const cancelCarePathwayEventSchema: JSONSchemaType<TaskEventCancelCarePathway> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      required: [],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.CancelCarePathway,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['created', 'data', 'eventType', 'id', 'note', 'patientId', 'taskId'],
}

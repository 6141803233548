import { combineReducers, Reducer } from 'redux'
import { produce } from 'immer'
import { RecordState } from '../../types/record-state'
import {
  ACTIVATE_PATIENT_FULFILLED,
  CREATE_PATIENT_FULFILLED,
  DEACTIVATE_PATIENT_FULFILLED,
  FETCH_PATIENTS_FULFILLED,
  PatientMap,
  UPDATE_PATIENT_FULFILLED,
} from '../../types/patient'
import { ASSIGN_DEVICE_TO_PATIENT_SUCCEEDED } from '../../types/device'
import {
  Action,
  FETCH_PATIENT_BY_CHAT_CHANNEL_FAILED,
  FETCH_PATIENT_BY_CHAT_CHANNEL_REQUESTED,
  FETCH_PATIENT_BY_CHAT_CHANNEL_SUCCEEDED,
  FETCH_PATIENT_BY_ID_FAILED,
  FETCH_PATIENT_BY_ID_REQUESTED,
  FETCH_PATIENT_BY_ID_SUCCEEDED,
} from '../../types/actions'

/**
 * A map for quickly looking up the patient object given a patient id
 * @see PatientMap
 */
const patientMap: Reducer<PatientMap, Action> = produce((draft: PatientMap, action: Action) => {
  switch (action.type) {
    case FETCH_PATIENTS_FULFILLED: {
      const { patients } = action.payload
      patients.forEach((patient) => {
        draft[patient.id] = patient
      })

      return
    }
    case CREATE_PATIENT_FULFILLED:
    case UPDATE_PATIENT_FULFILLED:
    case DEACTIVATE_PATIENT_FULFILLED:
    case ACTIVATE_PATIENT_FULFILLED: {
      const { patient } = action.payload

      draft[patient.id] = patient

      return
    }
    case ASSIGN_DEVICE_TO_PATIENT_SUCCEEDED: {
      const { device } = action.payload
      const patient = draft[device.patientId]

      if (!patient) {
        return
      }

      patient.devices = [...(patient.devices ?? []), device]

      return
    }
    case FETCH_PATIENT_BY_ID_SUCCEEDED:
      draft[action.payload.id] = action.payload.patient

      return
    case FETCH_PATIENT_BY_CHAT_CHANNEL_SUCCEEDED:
      draft[action.payload.patient.id] = action.payload.patient

      return
  }
}, {})

const recordStateByChatChannel: Reducer<Record<string, RecordState>, Action> = produce(
  (draft: Record<string, RecordState>, action) => {
    switch (action.type) {
      case FETCH_PATIENT_BY_CHAT_CHANNEL_REQUESTED:
        draft[action.payload.channelUrl] = RecordState.LOADING
        break
      case FETCH_PATIENT_BY_CHAT_CHANNEL_SUCCEEDED:
        draft[action.payload.channelUrl] = RecordState.LOADED
        break
      case FETCH_PATIENT_BY_CHAT_CHANNEL_FAILED:
        draft[action.payload.channelUrl] = RecordState.ERRORED
        break
    }
  },
  {},
)

const recordStateById: Reducer<Record<string, RecordState>, Action> = produce(
  (draft: Record<string, RecordState>, action: Action) => {
    switch (action.type) {
      case FETCH_PATIENT_BY_ID_REQUESTED:
        draft[action.payload.id] = RecordState.LOADING
        break
      case FETCH_PATIENT_BY_ID_SUCCEEDED:
        draft[action.payload.id] = RecordState.LOADED
        break
      case FETCH_PATIENT_BY_ID_FAILED:
        draft[action.payload.id] = RecordState.ERRORED
        break
    }
  },
  {},
)

export const patient = combineReducers({
  recordStateByChatChannel,
  recordStateById,
  patientMap,
})

import Ajv, { JSONSchemaType } from 'ajv'
import { AssigneeType, Task, TaskStatus, TaskType, TaskWithUnknownEvents } from '../../../types/task'
import * as http from '../../http'
import { taskWithUnknownEventsSchema } from '../../schema/task'
import { SortOrder } from '../../../types/lists'
import { createApiLogger } from '../../../logger'
import { uuid } from '../../../utils/uuid'
import { parseTask } from './utils'

export interface GetTasksQuery {
  assignee?: string[]
  patientId?: string[]
  status?: TaskStatus[]
  type?: TaskType[]
}

interface GetTasksWithUnknownEventsResponse {
  results: TaskWithUnknownEvents[] | null | undefined
  totalCount: number
}

const ajv = new Ajv()

const schema: JSONSchemaType<GetTasksWithUnknownEventsResponse> = {
  type: 'object',
  properties: {
    results: {
      type: 'array',
      items: taskWithUnknownEventsSchema,
      nullable: true,
    },
    totalCount: {
      type: 'number',
    },
  },
  required: ['totalCount'],
}

const validate = ajv.compile(schema)

export interface GetTasksResponse {
  response: {
    results: Task[] | null | undefined
    totalCount: number
  }
  hash: string
}

const getTasks = async (
  {
    assigneeType,
    includeComments,
    includeEvents,
    limit,
    lastHash,
    offset,
    query,
    sortOrder,
    sortPriority,
  }: {
    assigneeType?: AssigneeType
    includeComments?: boolean
    includeEvents?: boolean
    limit: number
    lastHash: string
    offset: number
    query: GetTasksQuery
    sortOrder?: SortOrder
    sortPriority?: boolean
  },
  accessToken: string,
): Promise<GetTasksResponse> => {
  const params = new URLSearchParams()

  params.append('offset', String(offset))
  params.append('limit', String(limit))

  query.assignee?.forEach((assignee) => {
    params.append('assignee', assignee)
  })
  query.patientId?.forEach((patientId) => {
    params.append('patientId', patientId)
  })
  query.status?.forEach((status) => {
    params.append('status', status)
  })
  query.type?.forEach((type) => {
    params.append('type', type)
  })

  if (typeof assigneeType === 'number') {
    params.append('assigneeType', String(assigneeType))
  }

  if (includeEvents) {
    params.append('includeEvents', '1')
  }

  if (includeComments) {
    params.append('includeComments', '1')
  }

  if (sortOrder) {
    params.append('sortOrder', sortOrder)
  }

  if (sortPriority) {
    params.append('sortPriority', 'true')
  }

  const requestId = uuid()
  const logger = createApiLogger('GET', '/v1/task', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, headers, statusCode } = await http.get(`/v1/task?${params}`, {
      accessToken,
      headers: {
        'If-None-Match': lastHash || '0',
      },
      requestId,
    })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    const eTag = headers.get(http.HttpHeaders.ETAG)

    const results = (body as GetTasksWithUnknownEventsResponse).results?.map(parseTask)

    logger.logSuccess({
      statusCode,
    })

    return {
      response: {
        results,
        totalCount: (body as GetTasksWithUnknownEventsResponse).totalCount,
      },
      hash: eTag ?? '',
    }
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default getTasks

import { ReactElement, useCallback, useState } from 'react'
import { Button, ButtonVariant } from '../../../ui/button'
import { SvgIcon } from '../../../ui/icon'
import { ReactComponent as CalendarIcon } from '../../../icons/outline/calendar.svg'
import { useAppSelector } from '../../../../redux'
import { getTaskDueDate } from '../../../../redux/selectors/tasks'
import FormattedDateTime from '../../../ui/formatted-date-time'
import ChangeDueDateModal from '../../../modals/change-due-date-modal'

interface Props {
  listId: string
  taskId: string
}

const I13N_VIEW = {
  View: 'Task Details',
}

const TaskDetailsDueDate = ({ listId, taskId }: Props): ReactElement => {
  const dueDate = useAppSelector((state) => getTaskDueDate(state, taskId))
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onClick = useCallback(() => setIsModalOpen(true), [])
  const onModalCLose = useCallback(() => setIsModalOpen(false), [])

  return (
    <Button className="mt-2 !px-3" onClick={onClick} variant={ButtonVariant.SECONDARY}>
      <SvgIcon Icon={CalendarIcon} />
      {dueDate ? (
        <FormattedDateTime className="ml-2" value={dueDate} day="numeric" month="numeric" year="2-digit" />
      ) : (
        ''
      )}
      {isModalOpen ? (
        <ChangeDueDateModal
          i13n={I13N_VIEW}
          isOpen
          listId={listId}
          onClose={onModalCLose}
          taskId={taskId}
          key={taskId}
        />
      ) : null}
    </Button>
  )
}

export default TaskDetailsDueDate

import Ajv from 'ajv'
import { studyEnrollmentSchema } from '../../schema/study-enrollment'
import { StudyEnrollment } from '../../../types/study'
import { uuid } from '../../../utils/uuid'
import { createApiLogger } from '../../../logger'
import * as http from '../../http'

const ajv = new Ajv()
const validate = ajv.compile(studyEnrollmentSchema)

const withdraw = async (
  studyId: string,
  patientId: string,
  payload: {
    withdrawal_date: string
    withdrawal_reason?: string
  },
  accessToken: string,
): Promise<StudyEnrollment> => {
  const requestId = uuid()
  const logger = createApiLogger('POST', '/v1/studies/:studyId/patient/:patientId/withdraw', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, statusCode } = await http.post(`/v1/studies/${studyId}/patient/${patientId}/withdraw`, payload, {
      accessToken,
      requestId,
    })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    logger.logSuccess({
      statusCode,
    })

    return body
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default withdraw

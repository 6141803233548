import { ReactElement } from 'react'
import { useInView } from 'react-intersection-observer'
import { useQuery } from '@apollo/client'
import { CarePathwayStatus, CarePathwayType, CarePathwaysQueryQuery } from '../../../../../__generated__/graphql'
import carePathwaysQuery from '../../../../../schemas/care-pathways-query'
import BloodPressureAlertPill from './blood-pressure-alert-pill'

interface Props {
  patientId: string
}

type Task = NonNullable<NonNullable<CarePathwaysQueryQuery['carePathways']>[number]['tasks']>[number]

const isTask = (task?: Task): task is Task => typeof task !== 'undefined'

const BloodPressureEscalationAlerts = ({ patientId }: Props): ReactElement | null => {
  const { inView, ref } = useInView()
  const { data } = useQuery(carePathwaysQuery, {
    pollInterval: 15000,
    skip: !inView,
    variables: {
      patientId,
      carePathwayStatus: [CarePathwayStatus.Created, CarePathwayStatus.InProgress],
      carePathwayTypes: [CarePathwayType.HighBloodPressureEscalation, CarePathwayType.LowBloodPressureEscalation],
    },
  })
  const { carePathways } = data ?? {}
  const highBpEscalationIds =
    carePathways
      ?.reduce((ids, { id, type }) => {
        if (type === CarePathwayType.HighBloodPressureEscalation) {
          ids.push(id)
        }

        return ids
      }, [] as string[])
      .join(',') ?? ''
  const lowBpEscalationIds =
    carePathways
      ?.reduce((ids, { id, type }) => {
        if (type === CarePathwayType.LowBloodPressureEscalation) {
          ids.push(id)
        }

        return ids
      }, [] as string[])
      .join(',') ?? ''
  const highBpEscalation =
    carePathways?.some(({ type }) => type === CarePathwayType.HighBloodPressureEscalation) ?? false
  const lowBpEscalation = carePathways?.some(({ type }) => type === CarePathwayType.LowBloodPressureEscalation) ?? false
  const highBpTasks =
    carePathways?.reduce((tasks, carePathway) => {
      if (carePathway.type !== CarePathwayType.HighBloodPressureEscalation || !carePathway.tasks) {
        return tasks
      }

      return [...tasks, ...carePathway.tasks.filter(isTask)]
    }, [] as Task[]) ?? []
  const lowBpTasks =
    carePathways?.reduce((tasks, carePathway) => {
      if (carePathway.type !== CarePathwayType.LowBloodPressureEscalation || !carePathway.tasks) {
        return tasks
      }

      return [...tasks, ...carePathway.tasks.filter(isTask)]
    }, [] as Task[]) ?? []

  return (
    <div ref={ref}>
      {highBpEscalation ? (
        <BloodPressureAlertPill escalationIds={highBpEscalationIds} tasks={highBpTasks} variant="high" />
      ) : null}
      {lowBpEscalation ? (
        <BloodPressureAlertPill escalationIds={lowBpEscalationIds} tasks={lowBpTasks} variant="low" />
      ) : null}
    </div>
  )
}

export default BloodPressureEscalationAlerts

import { CarePathwayType, TaskCarePathwayQueryQuery, TaskEventType, TaskStatus } from '../__generated__/graphql'
import { AuditStatus } from './audit-status'
import { CarePathwayEvent } from './care-pathway'
import { ListItem } from './lists'
import { SurveySubmission } from './survey'
import { VisitType } from './visit'

export enum AssigneeType {
  AssigneeTypeCareTeam = 'CareTeam',
  AssigneeTypePatient = 'Patient',
}

export enum TaskType {
  UCH_COMPLETE_VISIT_OBV = 'UCHCompleteVisitOBV',
  D2C_COMPLETE_VISIT_OBV = 'D2CCompleteVisitOBV',
  COMPLETE_PHARMD_VISIT = 'CompletePharmDVisit',
  COMPLETE_PRESCRIBER_VISIT = 'CompletePrescriberVisit',
  REMIND_TO_SCHEDULE_NPV_CALL = 'RemindToScheduleNPVCall',
  ASK_IF_PATIENT_HAS_PHARMD_VISIT_QUESTIONS = 'AskIfPatientHasPharmDVisitQuestions',
  ASK_IF_PATIENT_HAS_PRESCRIBER_VISIT_QUESTIONS = 'AskIfPatientHasPrescriberDVisitQuestions',
  SEND_VISIT_SURVEY_PRESCRIBER = 'SendVisitSurveyPrescriber',
  BP_ESCALATION_REVIEW_HIGH_READINGS = 'BpEscalationReviewHighReadings',
  BP_ESCALATION_REVIEW_LOW_READINGS = 'BpEscalationReviewLowReadings',
  BP_ESCALATION_FOLLOW_UP_CALL = 'BpEscalationFollowUpCall',
  BP_ESCALATION_FOLLOW_UP_HIGH_READINGS = 'BpEscalationFollowUpHighReadings',
  BP_ESCALATION_FOLLOW_UP_LOW_READINGS = 'BpEscalationFollowUpLowReadings',
  BP_ESCALATION_FOLLOW_UP_LOW_CALL = 'BpEscalationFollowUpLowCall',
  COMPLETE_LIFESTYLE_VISIT = 'CompleteLifestyleVisit',
}

export { TaskStatus, TaskEventType as TaskEventTypes } from '../__generated__/graphql'

// Only a few care pathways should be started from the web app
export type StartableCarePathwayType = CarePathwayType.LifestyleChange

export const TaskStatusOpen = [TaskStatus.TaskInProgress, TaskStatus.TaskPending]
export const BpEscalationTaskTypes = [
  TaskType.BP_ESCALATION_REVIEW_HIGH_READINGS,
  TaskType.BP_ESCALATION_REVIEW_LOW_READINGS,
  TaskType.BP_ESCALATION_FOLLOW_UP_CALL,
  TaskType.BP_ESCALATION_FOLLOW_UP_HIGH_READINGS,
  TaskType.BP_ESCALATION_FOLLOW_UP_LOW_READINGS,
  TaskType.BP_ESCALATION_FOLLOW_UP_LOW_CALL,
]

export const VisibleTaskStatuses = [
  TaskStatus.TaskCreated,
  TaskStatus.TaskInProgress,
  TaskStatus.TaskCompleting,
  TaskStatus.TaskCompletingSkipping,
  TaskStatus.TaskNa,
]

export enum TaskQueryParam {
  OPEN = 'open',
  CLOSED = 'closed',
  ALL = 'all',
}

export enum TaskPredefinedRole {
  PRESCRIBER = 'PRESCRIBER',
  PHARMD = 'PHARMD',
  HEALTH_COACH = 'HEALTH_COACH',
}

export enum TaskActionType {
  EXTERNAL_LINK = 'EXTERNAL_LINK',
}

interface EntryPoint {
  name: string
  urlTemplate: string
}

export interface TaskAction {
  resourceType: 'action'
  id: string
  type: TaskActionType
  target?: EntryPoint
}

interface TaskEventBase {
  created: AuditStatus
  id: string
  note: string
  patientId: string
  taskId: string
}

export interface TaskEventCreateTask extends TaskEventBase {
  eventType: TaskEventType.CreateTask
  data: {
    taskType: string
    status: string
  }
}

export interface TaskEventUpdateTaskStatus extends TaskEventBase {
  eventType: TaskEventType.UpdateTaskStatus
  data: {
    taskStatus: string
  }
}

export interface TaskEventAssignTask extends TaskEventBase {
  eventType: TaskEventType.AssignTask
  data: {
    assignee: string
  }
}

export interface TaskEventSetTaskDueDate extends TaskEventBase {
  eventType: TaskEventType.SetDueDate
  data: {
    dueDate: string
  }
}

export interface TaskEventAddNote extends TaskEventBase {
  eventType: TaskEventType.AddNote
  data: {
    body: string
  }
}

export interface TaskEventCancelPreviousEvent extends TaskEventBase {
  eventType: TaskEventType.CancelEvent
  data: {
    eventId: string
  }
}

export interface TaskEventVisitCancelled extends TaskEventBase {
  eventType: TaskEventType.VisitCancelled
  data: {
    visitType: VisitType
  }
}

export interface TaskEventVisitRescheduled extends TaskEventBase {
  eventType: TaskEventType.VisitRescheduled
  data: {
    visitType: VisitType
    startTime: string
    endTime: string
  }
}

export interface TaskEventCreateCarePathway extends TaskEventBase {
  eventType: TaskEventType.CreateCarePathway
  data: {
    carePathwayType: StartableCarePathwayType
  }
}

export interface TaskEventCancelCarePathway extends TaskEventBase {
  eventType: TaskEventType.CancelCarePathway
  data: Record<string, never>
}

export interface TaskEventCompleteWithChoice extends TaskEventBase {
  eventType: TaskEventType.CompleteWithChoice
  data: {
    choice: string
  }
}

export type TaskEvent =
  | TaskEventCreateTask
  | TaskEventUpdateTaskStatus
  | TaskEventAssignTask
  | TaskEventSetTaskDueDate
  | TaskEventAddNote
  | TaskEventCancelPreviousEvent
  | TaskEventVisitCancelled
  | TaskEventVisitRescheduled
  | TaskEventCreateCarePathway
  | TaskEventCancelCarePathway
  | TaskEventCompleteWithChoice

export interface UnknownTaskEvent extends TaskEventBase {
  eventType: string
  data: Record<string, unknown> | null | undefined
}

export interface TaskNote {
  body: string
  created: AuditStatus
  id: string
  taskId: string
  updated: AuditStatus
}

export interface Task {
  assignee: string
  assigneeType: AssigneeType
  carePathwayId: string
  copy: string
  created: AuditStatus
  description: string
  dueDate: string
  events?: TaskEvent[] | null
  id: string
  notes?: TaskNote[] | null
  options?: TaskOption[] | null
  patientId: string
  patientTouchPoint: boolean
  potentialAction?: TaskAction[]
  priority: number
  status: TaskStatus
  summary: string
  taskType: string
  updated: AuditStatus
}

export interface TaskChoice {
  label: string
  event: UnknownTaskEvent
}

export interface TaskOption {
  title: string
  choices: TaskChoice[]
}

export interface TaskWithUnknownEvents extends Omit<Task, 'events' | 'notes'> {
  comments?: TaskNote[] | null
  events?: UnknownTaskEvent[] | null
}

export const FETCH_TASK_CARE_PATHWAY_REQUESTED = 'FETCH_TASK_CARE_PATHWAY_REQUESTED'
export const FETCH_TASK_CARE_PATHWAY_FULFILLED = 'FETCH_TASK_CARE_PATHWAY_FULFILLED'
export const FETCH_TASK_CARE_PATHWAY_FAILED = 'FETCH_TASK_CARE_PATHWAY_FAILED'

export interface FetchTaskCarePathwayRequestedAction {
  type: typeof FETCH_TASK_CARE_PATHWAY_REQUESTED
  payload: {
    taskId: string
  }
}

export interface FetchTaskCarePathwayFulfilledAction {
  type: typeof FETCH_TASK_CARE_PATHWAY_FULFILLED
  payload: {
    carePathway: NonNullable<NonNullable<TaskCarePathwayQueryQuery['task']>['carePathway']>
    events: CarePathwayEvent[]
    surveySubmissions: SurveySubmission[]
    taskId: string
  }
}

export interface FetchTaskCarePathwayFailedAction {
  type: typeof FETCH_TASK_CARE_PATHWAY_FAILED
  payload: {
    taskId: string
  }
}

export const TASK_CHOICE_SELECT_REQUESTED = 'TASK_CHOICE_SELECT_REQUESTED'
export const TASK_CHOICE_SELECT_FULFILLED = 'TASK_CHOICE_SELECT_FULFILLED'
export const TASK_CHOICE_SELECT_FAILED = 'TASK_CHOICE_SELECT_FAILED'

export interface TaskChoiceSelectRequestedAction {
  type: typeof TASK_CHOICE_SELECT_REQUESTED
  payload: ListItem
}

export interface TaskChoiceSelectFulfilledAction {
  type: typeof TASK_CHOICE_SELECT_FULFILLED
  payload: ListItem
}

export interface TaskChoiceSelectFailedAction {
  type: typeof TASK_CHOICE_SELECT_FAILED
  payload: ListItem
}

import { RecordState } from './record-state'

export const FETCHING_NOTES = 'FETCHING_NOTES'
export const FETCH_NOTES_FULFILLED = 'FETCH_NOTES_FULFILLED'
export const FETCH_NOTES_FAILED = 'FETCH_NOTES_FAILED'

export const CREATE_NOTE_FORM_INITIALIZED = 'CREATE_NOTE_FORM_INITIALIZED'
export const CREATING_NOTE = 'CREATING_NOTE'
export const CREATE_NOTE_FULFILLED = 'CREATE_NOTE_FULFILLED'
export const CREATE_NOTE_FAILED = 'CREATE_NOTE_FAILED'

export const UPDATING_NOTE = 'UPDATING_NOTE'
export const UPDATE_NOTE_FULFILLED = 'UPDATE_NOTE_FULFILLED'
export const UPDATE_NOTE_FAILED = 'UPDATE_NOTE_FAILED'

export interface Note {
  id: string
  practitionerId: string
  personId: string
  text: string
  participantId?: string
  patientId?: string
  timeZone: string
  created: {
    at: string
    by: string
  }
  updated: {
    at: string
    by: string
  }
}

export interface NoteState {
  noteMap: NoteMap
  memberIdToNoteIdsMap: MemberIdToNoteIdsMap
  memberIdToRecordStateMap: MemberIdToRecordStateMap
}

export interface NoteMap {
  [noteId: string]: Note
}

export interface MemberIdToRecordStateMap {
  [memberId: string]: RecordState
}

export interface MemberIdToNoteIdsMap {
  [memberId: string]: string[]
}

export interface FetchingNotes {
  type: typeof FETCHING_NOTES
  payload: {
    memberId: string
  }
}

export interface FetchNotesFulfilled {
  type: typeof FETCH_NOTES_FULFILLED
  payload: {
    notes: Note[]
    memberId: string
  }
}

export interface FetchNotesFailed {
  type: typeof FETCH_NOTES_FAILED
  payload: {
    error: Error
    memberId: string
  }
}

export interface CreateNoteFormInitialized {
  type: typeof CREATE_NOTE_FORM_INITIALIZED
  payload: undefined
}

export interface CreatingNote {
  type: typeof CREATING_NOTE
  payload: undefined
}

export interface CreateNoteFulfilled {
  type: typeof CREATE_NOTE_FULFILLED
  payload: {
    note: Note
  }
}

export interface CreateNoteFailed {
  type: typeof CREATE_NOTE_FAILED
  payload: {
    error: Error
  }
}

export interface UpdatingNote {
  type: typeof UPDATING_NOTE
  payload: {
    text: string
    noteId: string
    updatedAt: string
    updatedBy: string
  }
}

export interface UpdateNoteFulfilled {
  type: typeof UPDATE_NOTE_FULFILLED
  payload: undefined
}

export interface UpdateNoteFailed {
  type: typeof UPDATE_NOTE_FAILED
  payload: {
    error: Error
    noteId: string
    text: string
    updatedAt: string
    updatedBy: string
  }
}

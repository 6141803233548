import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { getListItemIds, getListRecordState, getListTotalCount } from '../../../redux/selectors/lists'
import { fetchTasks } from '../../../redux/thunks/tasks'
import useTasksApi from '../../../api/hooks/use-tasks-api'
import { parseListId } from '../../../utils/lists'
import { TaskQueryParam } from '../../../types/task'
import { RecordState } from '../../../types/record-state'
import { TypedListItem } from '../../../types/lists'
import { getTaskMap } from '../../../redux/selectors/tasks'
import { isTaskOverdue } from '../../../utils/tasks'
import { PanelAction } from '../../ui/right-rail/types'
import TaskItem from './task-item'
import TaskCommands from './task-commands'
import TaskListLoadingIndicator from './task-list-loading-indicator'
import TaskRefreshIndicator from './task-refresh-indicator'
import TaskListHeader from './task-list-header'
import TaskListErrorState from './task-list-error-state'
import TaskListEmptyState from './task-list-empty-state'
import TaskListLoadingState from './task-list-loading-state'

interface SeparatorProps {
  children?: ReactNode
  variant?: 'normal' | 'alert' | 'success' | 'priority'
}

const Separator = ({ children, variant = 'normal' }: SeparatorProps): ReactElement => {
  return (
    <div className="bg-white p-3">
      <h3
        className={clsx('uppercase rounded px-4 py-3 text-xxs leading-4 font-extrabold', {
          'bg-rivaOffblack-100': variant === 'normal',
          'bg-rivaFuchsia-100 text-rivaFuchsia-700': variant === 'alert',
          'bg-rivaGreen-100 text-rivaGreen-800': variant === 'success',
          'bg-rivaBlue-50 text-rivaBlue-700': variant === 'priority',
        })}
      >
        {children}
      </h3>
    </div>
  )
}

const TaskQueueTitle = () => (
  <h1 className="pt-[60px] pl-7 pb-7 text-3xl leading-10 font-bold" key="TASK_QUEUE_TITLE">
    Task queue
  </h1>
)

interface Props {
  listId: string
  rightRailDispatch: (action: PanelAction) => void
}

const TaskList = ({ listId, rightRailDispatch }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const itemIds = useAppSelector((state) => getListItemIds(state, listId))
  const tasks = useAppSelector(getTaskMap)
  const totalCount = useAppSelector((state) => getListTotalCount(state, listId))
  const recordState = useAppSelector((state) => getListRecordState(state, listId))
  const taskQuery = useMemo(() => parseListId(listId).query, [listId])
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [isScrolling, setIsScrolling] = useState(false)

  const items = useMemo((): TypedListItem[] => {
    if (!itemIds.length) {
      return []
    }

    const items = itemIds.map((id) => ({
      id,
      listId,
      type: 'TASK',
    }))

    if (taskQuery === TaskQueryParam.CLOSED || taskQuery === TaskQueryParam.ALL) {
      return [
        {
          id: 'title-0',
          listId,
          type: 'TITLE',
        },
        {
          id: 'title-1',
          listId,
          type: 'LIST_TITLE',
        },
        ...items,
      ]
    }

    const priorityItems = items.filter(({ id }) => tasks[id]?.priority > 0)
    const nonPriorityItems = items.filter(({ id }) => !(tasks[id]?.priority > 0))

    if (priorityItems.length) {
      priorityItems.unshift({
        id: 'separator-2',
        listId,
        type: 'SEPARATOR_PRIORITY',
      })
    }

    const overdueItems = nonPriorityItems.filter(({ id }) => isTaskOverdue(tasks[id]))

    if (overdueItems.length) {
      overdueItems.unshift({
        id: 'separator-0',
        listId,
        type: 'SEPARATOR_OVERDUE',
      })
    }

    const nonOverdueItems = nonPriorityItems.filter(({ id }) => !isTaskOverdue(tasks[id]))

    if (nonOverdueItems.length && (overdueItems.length || priorityItems.length)) {
      nonOverdueItems.unshift({
        id: 'separator-1',
        listId,
        type: 'SEPARATOR_NEXT',
      })
    }

    return [
      {
        id: 'title-0',
        listId,
        type: 'TITLE',
      },
      {
        id: 'title-1',
        listId,
        type: 'LIST_TITLE',
      },
      ...priorityItems,
      ...overdueItems,
      ...nonOverdueItems,
    ]
  }, [itemIds, listId, taskQuery, tasks])

  useEffect(() => {
    if (recordState === RecordState.DOES_NOT_EXIST) {
      dispatch(fetchTasks({ listId, offset: 0 }, tasksApi))
    }
  }, [dispatch, listId, recordState, tasksApi])

  let content: ReactNode

  if (recordState === RecordState.ERRORED) {
    content = (
      <>
        <TaskQueueTitle key={`title-${listId}`} />
        <TaskListHeader listId={listId} key={`header-${listId}`} />
        <TaskListErrorState listId={listId} />
      </>
    )
  } else if (itemIds.length === 0 && recordState === RecordState.LOADING) {
    content = (
      <>
        <TaskQueueTitle key={`title-${listId}`} />
        <TaskListHeader listId={listId} key={`header-${listId}`} />
        <TaskListLoadingState listId={listId} />
      </>
    )
  } else if (totalCount === 0) {
    content = (
      <>
        <TaskQueueTitle key={`title-${listId}`} />
        <TaskListHeader listId={listId} key={`header-${listId}`} />
        <TaskListEmptyState />
      </>
    )
  } else {
    content = (
      <>
        {items.map(({ id, type }, index) => {
          switch (type) {
            case 'TITLE':
              return <TaskQueueTitle key={`title-${listId}`} />
            case 'LIST_TITLE':
              return <TaskListHeader listId={listId} key={`header-${listId}`} />
            case 'SEPARATOR_PRIORITY':
              return (
                <Separator key={id} variant="priority">
                  Priority tasks
                </Separator>
              )
            case 'SEPARATOR_OVERDUE':
              return (
                <Separator key={id} variant="alert">
                  Overdue
                </Separator>
              )
            case 'SEPARATOR_NEXT':
              return <Separator key={id}>{taskQuery === TaskQueryParam.ALL ? 'All tasks' : 'Due soon'}</Separator>
            case 'SEPARATOR_CLOSED':
              return (
                <Separator key={id} variant="success">
                  Closed tasks
                </Separator>
              )
            case 'TASK':
              return (
                <TaskItem
                  id={id}
                  isScrolling={isScrolling}
                  listId={listId}
                  key={`${id}-${index}`}
                  rightRailDispatch={rightRailDispatch}
                />
              )
            default:
              return ''
          }
        })}
        <TaskListLoadingIndicator listId={listId} />
      </>
    )
  }

  // SS to @Juan: I'm leaving the width set to 700px for now.
  // Otherwise, it extends to the width of the screen and sits on top of the right rail.
  // This needs to be made more responsive-friendly.
  return (
    <div
      className="h-full flex-1 overflow-y-auto"
      data-testid="task-list"
      onScroll={() => {
        if (!isScrolling) {
          setIsScrolling(true)
        }

        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current)
        }
        scrollTimeoutRef.current = setTimeout(() => setIsScrolling(false), 100)
      }}
    >
      <TaskCommands listId={listId} />
      <div className="w-[600px] flex flex-col min-h-full">{content}</div>
      <TaskRefreshIndicator listId={listId} />
    </div>
  )
}

export default TaskList

import { SurveysApi } from '../../api/hooks/use-surveys-api'
import { ListContentType, ListItem } from '../../types/lists'
import { SURVEY_QUERY_SEEN, SURVEY_QUERY_UNSEEN } from '../../types/survey'
import { parseListId } from '../../utils/lists'
import {
  fetchSurveySubmissionsFailed,
  fetchSurveySubmissionsRequested,
  fetchSurveySubmissionsSucceeded,
  surveyMarkedAsSeen,
  surveyMarkedAsSeenFailed,
  surveyMarkedAsSeenSucceeded,
  surveyMarkedAsUnseen,
  surveyMarkedAsUnseenFailed,
  surveyMarkedAsUnseenSucceeded,
} from '../actions/surveys'
import { AppThunk } from '../reducers'
import { getLoggedInPersonId } from '../selectors/app'

export const fetchSurveySubmissions =
  (listId: string, api: SurveysApi): AppThunk =>
  async (dispatch) => {
    const { listContentType, query } = parseListId(listId)

    if (
      listContentType !== ListContentType.SURVEY_SUBMISSION ||
      (query !== SURVEY_QUERY_UNSEEN && query !== SURVEY_QUERY_SEEN)
    ) {
      return
    }

    dispatch(
      fetchSurveySubmissionsRequested({
        listId,
      }),
    )

    try {
      const { submissions, total_count } = await (query === SURVEY_QUERY_UNSEEN
        ? api.getUnseenSubmissions()
        : api.getSeenSubmissions())

      dispatch(
        fetchSurveySubmissionsSucceeded({
          listId,
          surveySubmissions: submissions ?? [],
          totalCount: total_count,
        }),
      )
    } catch (_) {
      dispatch(
        fetchSurveySubmissionsFailed({
          listId,
        }),
      )
    }
  }

export const markSurveyAsSeen =
  (listItem: ListItem, api: SurveysApi): AppThunk =>
  async (dispatch, getState) => {
    const { id, listId } = listItem
    const state = getState()
    const personId = getLoggedInPersonId(state)

    dispatch(
      surveyMarkedAsSeen({
        id,
        listId,
        personId,
      }),
    )

    try {
      await api.markAsSeen(id, personId)

      dispatch(surveyMarkedAsSeenSucceeded(listItem))
    } catch (_) {
      dispatch(surveyMarkedAsSeenFailed(listItem))
    }
  }

export const markSurveyAsUnseen =
  (listItem: ListItem, api: SurveysApi): AppThunk =>
  async (dispatch, getState) => {
    const { id, listId } = listItem
    const state = getState()
    const personId = getLoggedInPersonId(state)

    dispatch(
      surveyMarkedAsUnseen({
        id,
        listId,
        personId,
      }),
    )

    try {
      await api.markAsUnseen(id, personId)

      dispatch(surveyMarkedAsUnseenSucceeded(listItem))
    } catch (_) {
      dispatch(surveyMarkedAsUnseenFailed(listItem))
    }
  }

import { ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import useTabs from '../../../ui/tabs/use-tabs'
import { Tab, TabList } from '../../../ui/tabs'
import ErrorState from '../error-state'
import { LoadingIndicator, LoadingIndicatorSize } from '../../../ui/loading-indicator'
import EmptyState from '../empty-state'
import FormattedDateTime from '../../../ui/formatted-date-time'
import conditionsQuery from '../../../../schemas/conditions-query'
import { Coding, Maybe } from '../../../../__generated__/graphql'
import { ENTERED_IN_ERROR_CODE } from '../../../../types/chart'
import Condition from './condition'

interface Props {
  patientId: string
}

const ProblemsCurrentI13n = {
  eventName: 'Tab - Problems - Current - Clicked',
}
const ProblemsHistoricalI13n = {
  eventName: 'Tab - Problems - Historical - Clicked',
}

const isEnteredInError = (coding: Maybe<Coding>): boolean => coding?.code === ENTERED_IN_ERROR_CODE

const ProblemsTab = ({ patientId }: Props): ReactElement => {
  const { getTabListProps, getTabProps, selectedIndex } = useTabs()

  const { data, loading, error } = useQuery(conditionsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      patientId,
    },
  })

  if (error) {
    return <ErrorState />
  }

  if (!data && loading) {
    return (
      <div className="h-full">
        <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
      </div>
    )
  }

  const lastUpdated =
    data?.patient?.conditions?.reduce((last, next) => {
      const lastDateTime = DateTime.fromISO(last)
      const nextDateTime = DateTime.fromISO(next?.meta?.lastUpdated ?? '')

      if (!nextDateTime.isValid) {
        return last
      }

      if (!lastDateTime.isValid) {
        return next?.meta?.lastUpdated ?? ''
      }

      return nextDateTime.diff(lastDateTime).milliseconds > 0 ? next?.meta?.lastUpdated ?? '' : last
    }, '') ?? ''

  const conditions = data?.patient?.conditions?.filter((condition) => {
    // filter out conditions entered in error
    if (condition?.verificationStatus?.coding?.some(isEnteredInError)) {
      return false
    }

    const clinicalStatus = condition?.clinicalStatus?.coding?.[0]?.code

    return selectedIndex === 0 ? clinicalStatus === 'active' : clinicalStatus !== 'active'
  })

  return (
    <section>
      <TabList {...getTabListProps()} className="bg-rivaOffblack-50">
        <Tab i13n={ProblemsCurrentI13n} {...getTabProps(0)}>
          Current
        </Tab>
        <Tab i13n={ProblemsHistoricalI13n} {...getTabProps(1)}>
          Historical
        </Tab>
      </TabList>
      {conditions?.length ? (
        <>
          <header className="pt-7 px-7 pb-3">
            <h2 className="text-lg font-semibold m-0 text-rivaOffblack-900">
              {selectedIndex === 0 ? 'Current' : 'Historical'} problems
            </h2>
            {lastUpdated ? (
              <p className="text-sm font-medium text-rivaOffblack-500">
                Updated <FormattedDateTime value={lastUpdated} day="numeric" month="numeric" year="numeric" />
              </p>
            ) : null}
          </header>
          <main className="mx-7 mt-2">
            {conditions.map((condition) => {
              if (!condition?.id) {
                return null
              }

              return <Condition key={condition.id} condition={condition} />
            })}
          </main>
        </>
      ) : (
        <EmptyState />
      )}
    </section>
  )
}

export default ProblemsTab

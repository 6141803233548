export const getMedicationEventText = (eventType: string): string => {
  switch (eventType) {
    case 'START':
      return 'Started'
    case 'END':
      return 'Ended'
    case 'ORDER':
      return 'Ordered'
    case 'ENTER':
      return 'Entered'
    case 'FILL':
      return 'Filled'
    case 'HIDE':
      return 'HIDDEN'
    default:
      return eventType
  }
}

import React, { DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react'
import cn from 'clsx'

interface CardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  borderless?: boolean
  children: React.ReactElement | React.ReactElement[]
  className?: string
}

export function Card({ borderless, children, className = '', ...rest }: CardProps): ReactElement {
  return (
    <div
      className={cn(
        'mb-4 py-4 px-2',
        {
          'rounded-lg shadow-md bg-rivaWhite': !borderless,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

import { DateTime } from 'luxon'
import { stringifyDateOfBirth, calculateAge, isBirthday } from '../../../utils/dates'

/** Returns MM/DD/YYYY (XX yo) 🎂 or "-" if string is empty*/
export function getDobAndAge(dateOfBirth: string, timeZone: string): string {
  if (!dateOfBirth) {
    return '-'
  }

  let today = DateTime.now()

  // Guard against the user not having a timezone - results in NaN otherwise.
  if (timeZone) {
    today = DateTime.now().setZone(timeZone)
  }

  return `${stringifyDateOfBirth(dateOfBirth)} (${calculateAge(dateOfBirth, today)}yo) ${
    isBirthday(dateOfBirth, today) ? '🎂' : ''
  }`
}

import { RecordState } from './record-state'

export const FETCHING_BLOOD_PRESSURE_AVERAGES = 'FETCHING_BLOOD_PRESSURE_AVERAGES'
export const FETCH_BLOOD_PRESSURE_AVERAGES_FULFILLED = 'FETCH_BLOOD_PRESSURE_AVERAGES_FULFILLED'
export const FETCH_BLOOD_PRESSURE_AVERAGES_FAILED = 'FETCH_BLOOD_PRESSURE_AVERAGES_FAILED'

export interface BloodPressureAveragesState {
  [patientId: string]: {
    recordState: RecordState
    status: BloodPressureAverageStatus
    movingAverages: BloodPressureAverage[]
  }
}

export enum BloodPressureAverageStatus {
  COMPLETE = 'COMPLETE',
  NO_ANCHOR_DATE = 'NO_ANCHOR_DATE',
  MISSING_DATA = 'MISSING_DATA',
}

export interface BloodPressureAveragesResponse {
  data: BloodPressureAverage[]
  patientId: string
  status: BloodPressureAverageStatus | ''
}

export interface BloodPressureAverage {
  beatsPerMinuteAverage: MovingAverage
  calculatedAtTime: string
  daysAgo: number
  diastolicAverage: MovingAverage
  earliestTimestamp: string
  errorInfo?: string
  latestTimestamp: string
  systolicAverage: MovingAverage
}

export interface MovingAverage {
  high: number
  low: number
  average: number
}

export interface FetchingBloodPressureAverages {
  type: typeof FETCHING_BLOOD_PRESSURE_AVERAGES
  payload: {
    patientId: string
  }
}

export interface FetchBloodPressureAveragesFulfilled {
  type: typeof FETCH_BLOOD_PRESSURE_AVERAGES_FULFILLED
  payload: {
    bloodPressureAverages: BloodPressureAveragesResponse
    patientId: string
  }
}

export interface FetchBloodPressureAveragesFailed {
  type: typeof FETCH_BLOOD_PRESSURE_AVERAGES_FAILED
  payload: {
    error: Error
    patientId: string
  }
}

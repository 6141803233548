import { ReactElement, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import ErrorState from '../error-state'
import { LoadingIndicator, LoadingIndicatorSize } from '../../../ui/loading-indicator'
import EmptyState from '../empty-state'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import {
  getAllergyIntolerance,
  getAllergyIntoleranceRecordState,
} from '../../../../redux/selectors/allergy-intolerance'
import { RecordState } from '../../../../types/record-state'
import { fetchAllergyIntolerance } from '../../../../redux/thunks/chart'
import AllergyTable from '../allergy-table'

interface Props {
  patientId: string
}

const AllergiesTab = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const apolloClient = useApolloClient()
  const recordState = useAppSelector((state) => getAllergyIntoleranceRecordState(state, patientId))
  const allergyIntolerance = useAppSelector((state) => getAllergyIntolerance(state, patientId))

  useEffect(() => {
    dispatch(fetchAllergyIntolerance(patientId, apolloClient))
  }, [apolloClient, dispatch, patientId])

  if (recordState === RecordState.ERRORED) {
    return <ErrorState />
  }

  if (!allergyIntolerance && recordState === RecordState.LOADING) {
    return (
      <div className="h-full">
        <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
      </div>
    )
  }

  if (!allergyIntolerance?.length) {
    return <EmptyState />
  }

  return (
    <div className="p-3">
      <h2 className="text-lg font-semibold mt-4 mx-4 mb-7">Allergies</h2>
      <AllergyTable patientId={patientId} />
    </div>
  )
}

export default AllergiesTab

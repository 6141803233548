import { AppThunk } from '../reducers'
import {
  fetchingNotes,
  fetchNotesFulfilled,
  fetchNotesFailed,
  creatingNote,
  createNoteFulfilled,
  createNoteFailed,
  updatingNote,
  updateNoteFulfilled,
  updateNoteFailed,
} from '../actions/note'
import { Note } from '../../types/note'
import { getNote, getNoteRecordState } from '../selectors/note'
import { RecordState } from '../../types/record-state'

export const fetchNotesForPatient =
  (patientId: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    const state = getState()
    const recordState = getNoteRecordState(state, patientId)

    if (recordState === RecordState.LOADED || recordState === RecordState.LOADING) {
      return
    }

    dispatch(fetchingNotes(patientId))

    try {
      const notes: Note[] = (await apiClient.getNotesForPatient(patientId)) || []

      dispatch(fetchNotesFulfilled(notes, patientId))
    } catch (e) {
      dispatch(fetchNotesFailed(e as Error, patientId))
    }
  }

export const createNoteForPatient =
  (text: string, patientId: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    dispatch(creatingNote())

    try {
      const note: Note = await apiClient.createNoteForPatient(text, patientId)
      dispatch(createNoteFulfilled(note))
    } catch (e) {
      dispatch(createNoteFailed(e as Error))
    }
  }

export const updateNote =
  (noteId: string, text: string, updatedBy: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    const state = getState()
    const oldNote = getNote(state, noteId)
    const updatedAt = new Date().toISOString()

    dispatch(updatingNote(noteId, text, updatedAt, updatedBy))

    try {
      await apiClient.updateNote(noteId, text)
      dispatch(updateNoteFulfilled())
    } catch (e) {
      dispatch(
        updateNoteFailed(
          new Error(`Failed to update note because of error: ${(e as Error).message}`),
          oldNote?.id ?? '',
          oldNote?.text ?? '',
          oldNote?.updated?.at ?? '',
          oldNote?.updated?.by ?? '',
        ),
      )
    }
  }

import { ReactElement, ReactNode, useMemo } from 'react'
import Cerebro from 'cerebro'
import features from '../../config/features.yaml'
import { context } from './context'

const FeatureProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const resolvedConfig = useMemo(
    () =>
      new Cerebro(features).resolveConfig({
        env: process.env.REACT_APP_ENV_NAME,
      }),
    [],
  )

  return <context.Provider value={resolvedConfig}>{children}</context.Provider>
}

export default FeatureProvider

import { ReactElement } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../../../redux'
import { getBloodPressureMeasurement } from '../../../../redux/selectors/blood-pressure-measurement'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as ArrowCircleBrokenDownIcon } from '../../../icons/solid/arrow-circle-broken-down.svg'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import { BloodPressureEscalationCategoryType } from '../../../../types/blood-pressure-measurement'
import { getEscalationTypeText } from '../../../../utils/escalation'

interface Props {
  id: string
}

const BloodPressureMeasurement = ({ id }: Props): ReactElement => {
  const {
    bloodPressureEscalationCategory,
    systolicBloodPressure = 0,
    diastolicBloodPressure = 0,
  } = useAppSelector((state) => getBloodPressureMeasurement(state, id)) ?? {}
  const { type: escalationType } = bloodPressureEscalationCategory ?? {}
  const hasEscalation = escalationType && escalationType !== BloodPressureEscalationCategoryType.NORMAL
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    disabled: !bloodPressureEscalationCategory?.reasons.length,
    offset: [8, 8],
  })

  return (
    <div
      className={clsx('text-sm mb-1 last:mb-0 flex items-center', {
        'bg-rivaFuchsia-50 text-rivaFuchsia-700': !!hasEscalation,
        'text-rivaOffblack-700': !hasEscalation,
      })}
      data-measurementid={id}
    >
      <span className="flex-1">{`${Math.round(systolicBloodPressure)}/${Math.round(diastolicBloodPressure)}`}</span>
      {hasEscalation ? (
        <SvgIcon
          {...getTriggerProps()}
          Icon={ArrowCircleBrokenDownIcon}
          className={clsx('text-rivaFuchsia-500 mr-1', {
            'rotate-180':
              escalationType === BloodPressureEscalationCategoryType.CRITICALLY_HIGH ||
              escalationType === BloodPressureEscalationCategoryType.HIGH ||
              escalationType === BloodPressureEscalationCategoryType.TRENDING_HIGH,
          })}
          size={IconSize.X_SMALL}
        />
      ) : null}
      <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
        {escalationType ? (
          <h5 className="text-xxs font-extrabold uppercase mt-3">{getEscalationTypeText(escalationType)}</h5>
        ) : null}
        <ul className="pt-1 pb-2">
          {bloodPressureEscalationCategory?.reasons.map((reason) => (
            <li className="list-disc h-6 leading-6 ml-5 text-sm font-semibold" key={reason}>
              {reason}
            </li>
          ))}
        </ul>
      </Tooltip>
    </div>
  )
}

export default BloodPressureMeasurement

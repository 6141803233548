import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
  severity?: 'normal' | 'warning' | 'error'
}

const TextInputNotice = ({ children, severity = 'normal' }: Props): ReactElement => {
  return (
    <div
      className={clsx('text-xxs leading-6 px-1 pt-2 order-3', {
        'text-rivaGold-800': severity === 'warning',
        'text-rivaOffblack-700': severity === 'normal',
        'text-rivaFuchsia-800': severity === 'error',
      })}
    >
      {children}
    </div>
  )
}

export default TextInputNotice

import { ReactElement, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { getSurveySubmissionById } from '../../../redux/selectors/surveys'
import SurveyResults from '../../views/patient-profile/surveys/survey-results'
import { fetchSurveyRules } from '../../../redux/thunks/survey_rules'

interface Props {
  id: string
}

const SurveyAttachment = ({ id }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const submission = useAppSelector((state) => getSurveySubmissionById(state, id))

  useEffect(() => {
    dispatch(fetchSurveyRules())
  }, [dispatch])

  if (!submission) {
    return null
  }

  return <SurveyResults submissionData={submission.data ?? {}} surveyId={submission.surveyId} />
}

export default SurveyAttachment

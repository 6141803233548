import { ChangeEventHandler, ReactElement, useCallback, useState } from 'react'
import { useAppDispatch } from '../../../../redux'
import { Button } from '../../../ui/button'
import { createNoteForPatient } from '../../../../redux/thunks/note'
import TextArea from '../../../ui/text-area'

interface Props {
  autofocus?: boolean
  patientId: string
}

const PatientNoteInput = ({ autofocus, patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState('')

  const onChange = useCallback<ChangeEventHandler>((event) => {
    setValue((event.target as HTMLTextAreaElement).value)
  }, [])
  const onSubmit = useCallback(() => {
    setValue('')
    dispatch(createNoteForPatient(value, patientId))
  }, [dispatch, value, patientId])

  const id = `patient-input-${patientId}`

  return (
    <div className="flex-shrink-0 bg-rivaOffblack-50 border-t border-rivaOffblack-200 p-3 relative">
      <TextArea
        className="text-sm font-medium py-5 pl-3 pr-[124px] box-border w-full resize-none"
        id={id}
        onChange={onChange}
        placeholder="Write a note..."
        ref={(element) => {
          if (element && autofocus) {
            element.focus()
          }
        }}
        rows={value.split(/[\n\r]/g).length || 1}
        value={value}
      />
      <label htmlFor={id} className="hidden">
        Write a note
      </label>
      <Button className="absolute right-6 bottom-[30px]" disabled={value.trim() === ''} onClick={onSubmit}>
        Add note
      </Button>
    </div>
  )
}

export default PatientNoteInput

import useGetChannel from './use-get-channel'

const useIsPersonInChannel = (
  channelUrl: string,
  personId: string,
): {
  inChannel: boolean
  isValidating: boolean
} => {
  const { data: channel, isValidating } = useGetChannel(channelUrl)

  return {
    inChannel: channel ? channel.members.findIndex((member) => member.userId === personId) >= 0 : false,
    isValidating,
  }
}

export default useIsPersonInChannel

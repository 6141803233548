import debounce from 'lodash.debounce'
import { ReactElement, useCallback } from 'react'
import TextInput from '../../ui/text-input'
import useFlag from '../../../hooks/use-flag'
import { Features } from '../../../types/features'

interface Props {
  onChange: (value: string) => void
}

const ChannelListHeader = ({ onChange }: Props): ReactElement | null => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeHandler = useCallback(
    debounce((event) => onChange(event.target.value), 300),
    [],
  )
  const isSearchEnabled = useFlag(Features.PATIENT_SEARCH)

  if (isSearchEnabled) {
    return null
  }

  return <TextInput className="w-[244px] mt-1 ml-1" onChange={changeHandler} placeholder="Search for people" />
}

export default ChannelListHeader

import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useMemo } from 'react'
import { DropLastParam } from '../../../types/utils'
import searchMethod from './search'

export interface PatientsApi {
  search: DropLastParam<typeof searchMethod>
}

const usePatientsApi = (): PatientsApi => {
  const { getAccessTokenSilently } = useAuth0()

  const search = useCallback<PatientsApi['search']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return searchMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  return useMemo(
    () => ({
      search,
    }),
    [search],
  )
}

export default usePatientsApi

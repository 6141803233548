import clsx from 'clsx'
import { ReactElement } from 'react'
import { useAppSelector } from '../../../redux'
import { getListTotalCount } from '../../../redux/selectors/lists'
import { TaskQueryParam } from '../../../types/task'
import { buildTaskListId } from '../../../utils/lists'
import NavigationItem from './navigation-item'

const MyOpenTasksNavItem = (): ReactElement => {
  const listId = buildTaskListId({
    query: TaskQueryParam.OPEN,
  })
  const myOpenTasksCount = useAppSelector((state) => getListTotalCount(state, listId) ?? 0)

  return (
    <NavigationItem
      className="capitalize"
      level="SECONDARY"
      to={`/tasks/${TaskQueryParam.OPEN}`}
      label="My open tasks"
      iconLeft={({ isActive }) => (
        <div className="w-6 h-6 flex items-center justify-center">
          <span
            className={clsx('w-[19px] h-[19px] border-2 rounded-full', {
              'border-rivaPurple-300': !isActive,
              'border-rivaPurple-100': isActive,
            })}
          ></span>
        </div>
      )}
      iconRight={() => (myOpenTasksCount > 0 ? myOpenTasksCount : undefined)}
    />
  )
}

export default MyOpenTasksNavItem

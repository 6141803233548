import { ReactElement, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import useFlag from '../../../hooks/use-flag'
import { Features } from '../../../types/features'
import { ReactComponent as IconSearch } from '../../icons/outline/search.svg'
import useTooltip from '../../../hooks/use-tooltip'
import Tooltip from '../../ui/tooltip'
import MousetrapHint from '../../ui/mousetrap-hint'
import { SEARCH_KEYS } from '../../pages/tasks/task-commands/keys'
import NavigationItem from './navigation-item'

const SearchNavigationItem = (): ReactElement | null => {
  const isEnabled = useFlag(Features.PATIENT_SEARCH)
  const isPageActive = !!useRouteMatch({
    path: '/search',
    exact: false,
  })

  const onSearchClick = useCallback(() => {
    document.getElementById('search-input')?.focus()
  }, [])
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    disabled: isPageActive,
    offset: [0, 20],
    placement: 'right',
  })

  if (!isEnabled) {
    return null
  }

  return (
    <>
      <li {...getTriggerProps()}>
        <NavigationItem label="Search" onClick={onSearchClick} tabIndex={0} to="/search" iconLeft={<IconSearch />} />
      </li>
      <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
        <span className="mr-2">Search patients</span>
        <MousetrapHint keys={SEARCH_KEYS} />
      </Tooltip>
    </>
  )
}

export default SearchNavigationItem

import { AuditStatus, CarePathwayStatus, CarePathwayType, TaskEventType } from '../__generated__/graphql'

export interface CarePathwayEventBase {
  carePathwayId?: string | null
  created: AuditStatus
  id: string
  note?: string
}

export interface BPEscalationSymptomsEventData {
  symptoms: boolean
}

export interface BPEscalationSymptomsEvent extends CarePathwayEventBase {
  eventType: TaskEventType.BpEscalationSymptoms
  data: BPEscalationSymptomsEventData
}

export interface AddHighBPEscalationSurveyResultsEventData {
  explanation: string
  explanationForEscalation: boolean
  medicationAdherence: string
  missedMedication: boolean
}

export interface AddHighBPEscalationSurveyResultsEvent extends CarePathwayEventBase {
  eventType: TaskEventType.AddHighBpEscalationSurveyResults
  data: AddHighBPEscalationSurveyResultsEventData
}

export interface AddLowBPEscalationSurveyResultsEventData {
  explanation: string
  explanationForEscalation: boolean
  lacksReasonForEscalation: boolean
  hasMedicalCondition: boolean
  wasCuffBorrowed: boolean
}

export interface AddLowBPEscalationSurveyResultsEvent extends CarePathwayEventBase {
  eventType: TaskEventType.AddLowBpEscalationSurveyResults
  data: AddLowBPEscalationSurveyResultsEventData
}

export interface BPEscalationMeasurementReceivedEventData {
  bloodPressureMeasurementId: string
  bloodPressureEscalationCategory: {
    type: string
    reasons: string[]
  }
  diastolicBloodPressure: number
  systolicBloodPressure: number
}

export interface BPEscalationMeasurementReceivedEvent extends CarePathwayEventBase {
  eventType: TaskEventType.BpEscalationMeasurementReceived
  data: BPEscalationMeasurementReceivedEventData
}

export interface TaskEventLifestyleChangeSurveyResults extends CarePathwayEventBase {
  eventType: TaskEventType.LifestyleChangeSurveyResults
  data: {
    submissionId: number
    surveyId: number
  }
}

export interface CreateCarePathwayEvent extends CarePathwayEventBase {
  eventType: TaskEventType.CreateCarePathway
  data: Record<string, never>
}

export interface WorkflowCompleteEvent extends CarePathwayEventBase {
  eventType: TaskEventType.WorkflowComplete
  data: Record<string, never>
}

export type CarePathwayEvent =
  | BPEscalationSymptomsEvent
  | AddHighBPEscalationSurveyResultsEvent
  | AddLowBPEscalationSurveyResultsEvent
  | BPEscalationMeasurementReceivedEvent
  | TaskEventLifestyleChangeSurveyResults
  | CreateCarePathwayEvent
  | WorkflowCompleteEvent

export interface CarePathway {
  created: AuditStatus
  eventIds: string[]
  id: string
  patientId: string
  status: CarePathwayStatus
  type: CarePathwayType
}

export const CARE_PATHWAY_START_REQUESTED = 'CARE_PATHWAY_START_REQUESTED'
export const CARE_PATHWAY_START_FULFILLED = 'CARE_PATHWAY_START_FULFILLED'
export const CARE_PATHWAY_START_FAILED = 'CARE_PATHWAY_START_FAILED'

export interface CarePathwayStartRequestedAction {
  type: typeof CARE_PATHWAY_START_REQUESTED
  payload: {
    carePathwayType: CarePathwayType
    patientId: string
  }
}

export interface CarePathwayStartFulfilledAction {
  type: typeof CARE_PATHWAY_START_FULFILLED
  payload: {
    carePathwayType: CarePathwayType
    listId: string
    patientId: string
  }
}

export interface CarePathwayStartFailedAction {
  type: typeof CARE_PATHWAY_START_FAILED
  payload: {
    carePathwayType: CarePathwayType
    patientId: string
  }
}

export const CARE_PATHWAY_CANCEL_REQUESTED = 'CARE_PATHWAY_CANCEL_REQUESTED'
export const CARE_PATHWAY_CANCEL_FULFILLED = 'CARE_PATHWAY_CANCEL_FULFILLED'
export const CARE_PATHWAY_CANCEL_FAILED = 'CARE_PATHWAY_CANCEL_FAILED'

export interface CarePathwayCancelRequestedAction {
  type: typeof CARE_PATHWAY_CANCEL_REQUESTED
  payload: {
    carePathwayId: string
  }
}

export interface CarePathwayCancelFulfilledAction {
  type: typeof CARE_PATHWAY_CANCEL_FULFILLED
  payload: {
    carePathwayId: string
    carePathwayType?: CarePathwayType
    listId: string
  }
}

export interface CarePathwayCancelFailedAction {
  type: typeof CARE_PATHWAY_CANCEL_FAILED
  payload: {
    carePathwayId: string
  }
}

export const FETCH_CARE_PATHWAYS_REQUESTED = 'FETCH_CARE_PATHWAYS_REQUESTED'
export const FETCH_CARE_PATHWAYS_FULFILLED = 'FETCH_CARE_PATHWAYS_FULFILLED'
export const FETCH_CARE_PATHWAYS_FAILED = 'FETCH_CARE_PATHWAYS_FAILED'

export interface FetchCarePathwaysRequestedAction {
  type: typeof FETCH_CARE_PATHWAYS_REQUESTED
  payload: {
    listId: string
  }
}

export interface FetchCarePathwaysFulfilledAction {
  type: typeof FETCH_CARE_PATHWAYS_FULFILLED
  payload: {
    carePathways: CarePathway[]
    listId: string
  }
}

export interface FetchCarePathwaysFailedAction {
  type: typeof FETCH_CARE_PATHWAYS_FAILED
  payload: {
    listId: string
  }
}

import { MouseEventHandler, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import uniq from 'lodash.uniq'
import clsx from 'clsx'
import { TaskQueryParam, TaskStatus, TaskStatusOpen, TaskType as ClientTaskType } from '../../../../../types/task'
import Pill from '../../../../ui/pill'
import { ReactComponent as ArrowCircleBrokenDownIcon } from '../../../../icons/solid/arrow-circle-broken-down.svg'
import { ReactComponent as ArrowCircleBrokenUpIcon } from '../../../../icons/solid/arrow-circle-broken-up.svg'
import { serializeListIdForRoute } from '../../../../../utils/lists'
import useTooltip from '../../../../../hooks/use-tooltip'
import Tooltip from '../../../../ui/tooltip'
import { IconSize } from '../../../../ui/icon'
import Assignee from '../../../tasks/task-item/assignee'
import StatusPill from '../../../tasks/task-item/status-pill'
import { CarePathwaysQueryQuery } from '../../../../../__generated__/graphql'
import BloodPressureEscalationTaskIcon from './blood-pressure-escalation-task-icon'

type Task = NonNullable<NonNullable<CarePathwaysQueryQuery['carePathways']>[number]['tasks']>[number]

interface Props {
  escalationIds: string
  tasks: Task[]
  variant: 'high' | 'low'
}

const BloodPressureAlertPill = ({ escalationIds, tasks, variant }: Props): ReactElement => {
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    disabled: tasks.length === 0,
    offset: [0, 8],
    placement: 'bottom-start',
  })
  const url = tasks.length
    ? `/tasks/${TaskQueryParam.ALL}/${serializeListIdForRoute({
        patientId: uniq(tasks.map(({ patientId }) => patientId)),
        taskStatus: TaskStatusOpen,
        taskType: uniq(tasks.map(({ taskType }) => taskType as unknown as ClientTaskType)),
      })}`
    : ''
  const history = useHistory()
  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    event.preventDefault()

    if (url) {
      history.push(url)
    }
  }

  return (
    <button {...getTriggerProps()} onClick={handleClick} data-escalationids={escalationIds}>
      <Pill
        className={clsx({
          'cursor-pointer': tasks.length > 0,
        })}
        variant="error"
        iconLeft={variant === 'high' ? ArrowCircleBrokenUpIcon : ArrowCircleBrokenDownIcon}
        iconLeftSize={IconSize.X_SMALL}
      >
        {variant === 'high' ? 'High BP' : 'Low BP'}
      </Pill>
      <Tooltip className="z-50" {...getTooltipProps()} arrowProps={getArrowProps()}>
        {tasks.map(({ assigneeId, id, summary, status, taskType }) => (
          <div key={id} className="py-3">
            <div className="flex h-6 gap-2">
              <BloodPressureEscalationTaskIcon taskId={id} />
              <div className="flex-1 font-semibold text-sm leading-6">{summary}</div>
              <Assignee isOnDarkBackground practitionerId={assigneeId ?? ''} />
              <StatusPill isOnDarkBackground status={status as unknown as TaskStatus} />
            </div>
          </div>
        ))}
      </Tooltip>
    </button>
  )
}

export default BloodPressureAlertPill

import { BloodPressureMeasurementType, BloodPressureMeasurementsQueryQuery } from '../__generated__/graphql'
import type { CarePathwayEvent } from './care-pathway'
import { Note } from './note'
import { RecordState } from './record-state'

export const FETCHING_BLOOD_PRESSURE_MEASUREMENTS = 'FETCHING_BLOOD_PRESSURE_MEASUREMENTS'
export const FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED = 'FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED'
export const FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED = 'FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED'
export const FETCH_MORE_BP_MEASUREMENTS_FULFILLED = 'FETCH_MORE_BP_MEASUREMENTS_FULFILLED'
export const FETCH_MORE_BP_MEASUREMENTS_FAILED = 'FETCH_MORE_BP_MEASUREMENTS_FAILED'

export const UPDATING_BLOOD_PRESSURE_MEASUREMENT = 'UPDATING_BLOOD_PRESSURE_MEASUREMENT'
export const UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED = 'UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED'
export const UPDATE_BLOOD_PRESSURE_MEASUREMENT_FAILED = 'UPDATE_BLOOD_PRESSURE_MEASUREMENT_FAILED'
export const UPDATE_CUFF_READING_FORM_INITIALIZED = 'UPDATE_CUFF_READING_FORM_INITIALIZED'

export const FETCHING_CUFF_MEASUREMENT_DOWNLOAD_URL = 'FETCHING_CUFF_MEASUREMENT_DOWNLOAD_URL'
export const FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FULFILLED = 'FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FULFILLED'
export const FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FAILED = 'FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FAILED'

export enum BloodPressureEscalationCategoryType {
  LOW = 'LOW',
  TRENDING_LOW = 'TRENDING_LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
  TRENDING_HIGH = 'TRENDING_HIGH',
  CRITICALLY_HIGH = 'CRITICALLY_HIGH',
}

export interface BloodPressureEscalationCategory {
  type: string
  reasons: string[]
}

export interface BloodPressureMeasurement {
  id: string
  bloodPressureEscalationCategory?: BloodPressureEscalationCategory | null
  bloodPressureSetId: string
  comment?: string | null
  personId: string
  type: BloodPressureMeasurementType
  systolicBloodPressure: number
  diastolicBloodPressure: number
  beatsPerMinute: number
  deviceType?: string // Optional, since cuff-based measurements won't have one
  timeZone: string
  created: {
    at: string
    by: string
  }
  updated: {
    at: string
    by: string
  }
}

export { BloodPressureMeasurementType }

export interface BloodPressureMeasurementState {
  recordStateByListId: Record<string, RecordState>
  bloodPressureMeasurementMap: BloodPressureMeasurementMap
  bloodPressureSetIdToBloodPressureMeasurementIdMap: BloodPressureSetIdToBloodPressureMeasurementIdMap
}

export interface BloodPressureMeasurementMap {
  [bloodPressureMeasurementId: string]: BloodPressureMeasurement
}

export interface BloodPressureSetIdToBloodPressureMeasurementIdMap {
  [bloodPressureSetId: string]: {
    auscultation: string[]
    cuff: string[]
    phone: string[]
  }
}

export interface CuffMeasurementDownloadUrlState {
  [bloodPressureMeasurementId: string]: {
    signedUrl: string
    expirationTime: string
    recordState: RecordState
  }
}

export interface FetchingBloodPressureMeasurements {
  type: typeof FETCHING_BLOOD_PRESSURE_MEASUREMENTS
  payload: {
    listId: string
  }
}

export interface FetchBloodPressureMeasurementsFulfilled {
  type: typeof FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED
  payload: {
    events: CarePathwayEvent[]
    listId: string
    measurements: NonNullable<BloodPressureMeasurementsQueryQuery['patient']>['bloodPressureMeasurements']
    patientId: string
  }
}

export interface FetchBloodPressureMeasurementsFailed {
  type: typeof FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED
  payload: {
    error: Error
    listId: string
  }
}

export interface UpdateCuffReadingFormInitialized {
  type: typeof UPDATE_CUFF_READING_FORM_INITIALIZED
  payload: undefined
}

export interface UpdatingBloodPressureMeasurement {
  type: typeof UPDATING_BLOOD_PRESSURE_MEASUREMENT
  payload: undefined
}

export interface UpdateBloodPressureMeasurementFulfilled {
  type: typeof UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED
  payload: {
    note: Note
    bloodPressureMeasurement: BloodPressureMeasurement
  }
}

export interface UpdateBloodPressureMeasurementFailed {
  type: typeof UPDATE_BLOOD_PRESSURE_MEASUREMENT_FAILED
  payload: {
    error: Error
  }
}

export interface FetchingCuffMeasurementDownloadUrl {
  type: typeof FETCHING_CUFF_MEASUREMENT_DOWNLOAD_URL
  payload: {
    measurementId: string
  }
}

export interface FetchCuffMeasurementDownloadUrlFulfilled {
  type: typeof FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FULFILLED
  payload: {
    measurementId: string
    signedUrl: string
    expirationTime: string
  }
}

export interface FetchCuffMeasurementDownloadUrlFailed {
  type: typeof FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FAILED
  payload: {
    measurementId: string
    error: Error
  }
}

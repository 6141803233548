import { ChangeEventHandler, ReactElement, RefCallback, useCallback, useEffect, useState } from 'react'
import Confirm from '../../../../modals/confirm'
import TextInput from '../../../../ui/text-input'
import { useAppDispatch } from '../../../../../redux'
import { startCarePathway } from '../../../../../redux/thunks/care-pathway'
import useTasksApi from '../../../../../api/hooks/use-tasks-api'
import { CarePathwayType } from '../../../../../__generated__/graphql'
import { track } from '../../../../../i13n'

interface Props {
  isOpen: boolean
  listId: string
  onClose: () => void
  patientId: string
}

const StartLifestyleChangeModal = ({ isOpen, listId, onClose, patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const [note, setNote] = useState('')

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => setNote(event.target.value), [])
  const onAccept = useCallback(() => {
    dispatch(
      startCarePathway(
        {
          carePathwayType: CarePathwayType.LifestyleChange,
          listId,
          note,
          patientId,
        },
        tasksApi,
      ),
    )
    onClose()

    track('Lifestyle Change Start Modal Submitted')
  }, [dispatch, listId, note, onClose, patientId, tasksApi])
  const onCancel = useCallback(() => {
    setNote('')
    onClose()

    track('Lifestyle Change Start Modal Cancelled')
  }, [onClose])

  const inputRef = useCallback<RefCallback<HTMLInputElement>>((node) => node?.focus(), [])

  useEffect(() => {
    track('Lifestyle Change Start Modal Shown')
  }, [])

  return (
    <Confirm
      confirmButtonText="Add note"
      disabled={!note}
      isOpen={isOpen}
      onAccept={onAccept}
      onCancel={onCancel}
      subtitle="Add a task note with helpful additional context"
      title="What context would help the coach start a lifestyle change?"
    >
      <TextInput className="w-full mb-7" onChange={onChange} ref={inputRef} value={note} />
    </Confirm>
  )
}

export default StartLifestyleChangeModal

import { gql } from '../__generated__/gql'

export default gql(`#graphql
query patientStudiesQuery($patientId: ID!) {
  patient(patientId: $patientId) {
    eligibleStudies {
      id
      name
    }
    enrolledStudies {
      id
      name
    }
  }
}`)

import { FormAction } from '../../../../types/form'
import formReducerFactory from '../form-reducer-factory'
import {
  UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FORM_INITIALIZED,
  UPDATE_PATIENT_FAILED,
  UPDATE_PATIENT_FULFILLED,
  UPDATING_PATIENT,
} from '../../../../types/patient'

export const updateBloodPressureManagementStatusForm = formReducerFactory({
  [FormAction.INITIALIZED]: [UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FORM_INITIALIZED],
  [FormAction.SUBMITTING]: [UPDATING_PATIENT],
  [FormAction.SUBMITTED]: [UPDATE_PATIENT_FULFILLED],
  [FormAction.ERRORED]: [UPDATE_PATIENT_FAILED],
})

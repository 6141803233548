export interface BloodPressureSet {
  patientId?: string | null
  id: string
  created: {
    at: string
    by: string
  }
  timeZone: string
  comment?: string | null
}

export enum BloodPressureSetTimeOfDay {
  MORNING = 'MORNING',
  EVENING = 'EVENING',
  INVALID = 'INVALID',
}

export enum BloodPressureSetType {
  CLINIC = 'CLINIC',
  AT_HOME = 'AT_HOME',
}

export interface BloodPressureSetsByDateTime {
  [dateString: string]: BloodPressureSetByDateTime
}

export interface BloodPressureSetByDateTime {
  dateString: string
  dayOfWeek: number
  am: string[]
  pm: string[]
}

export interface BloodPressureSetMap {
  [bloodPressureSetId: string]: BloodPressureSet
}

// a member id can be a patient id. we used to have multiple types of ids, but we cleaned that up
export interface MemberIdToBloodPressureSetIdMap {
  [memberId: string]: string[]
}

import { gql } from '../__generated__/gql'

export default gql(`#graphql
query ehrRecordtQuery($medicalRecordNumber: String!) {
  ehrRecord(medicalRecordNumber: $medicalRecordNumber) {
    birthDate
    id
    name {
      family
      given
    }
  }
}`)

import { combineReducers, Reducer } from 'redux'
import { produce } from 'immer'
import {
  AppState,
  APP_FAILED_TO_LOAD,
  APP_LOADED,
  APP_LOADING,
  FailedAppLoad,
  LoadedApp,
  LoadingApp,
} from '../../types/app'
import { RecordState } from '../../types/record-state'

type AppActions = FailedAppLoad | LoadedApp | LoadingApp

const recordState = produce((draft: RecordState, action: AppActions) => {
  switch (action.type) {
    case APP_LOADING:
      return RecordState.LOADING
    case APP_LOADED:
      return RecordState.LOADED
    case APP_FAILED_TO_LOAD:
      return RecordState.ERRORED
    default:
      return draft
  }
}, RecordState.DOES_NOT_EXIST)

const loggedInPersonId = produce((draft: string, action: AppActions) => {
  switch (action.type) {
    case APP_LOADED:
      return action.payload.loggedInPersonId
    default:
      return draft
  }
}, '')

export const app: Reducer<AppState, AppActions> = combineReducers({
  recordState,
  loggedInPersonId,
})

import { produce } from 'immer'
import { combineReducers, Reducer } from 'redux'
import { RecordState } from '../../types/record-state'
import {
  CreateNoteFailed,
  CreateNoteFulfilled,
  CREATE_NOTE_FULFILLED,
  CreatingNote,
  FetchingNotes,
  FETCHING_NOTES,
  FetchNotesFailed,
  FetchNotesFulfilled,
  FETCH_NOTES_FAILED,
  FETCH_NOTES_FULFILLED,
  NoteMap,
  NoteState,
  MemberIdToNoteIdsMap,
  UpdateNoteFailed,
  UpdateNoteFulfilled,
  UPDATE_NOTE_FAILED,
  UpdatingNote,
  UPDATING_NOTE,
  MemberIdToRecordStateMap,
} from '../../types/note'
import {
  UpdateBloodPressureMeasurementFulfilled,
  UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED,
} from '../../types/blood-pressure-measurement'
import {
  ActivatePatientFulfilled,
  DEACTIVATE_PATIENT_FULFILLED,
  ACTIVATE_PATIENT_FULFILLED,
  DeactivatePatientFulfilled,
} from '../../types/patient'

type NoteActions =
  | FetchingNotes
  | FetchNotesFulfilled
  | FetchNotesFailed
  | CreatingNote
  | CreateNoteFulfilled
  | CreateNoteFailed
  | UpdateNoteFailed
  | UpdateNoteFulfilled
  | UpdatingNote
  | UpdateBloodPressureMeasurementFulfilled
  | ActivatePatientFulfilled
  | DeactivatePatientFulfilled

const memberIdToRecordStateMap = produce((draft: MemberIdToRecordStateMap, action: NoteActions) => {
  switch (action.type) {
    case FETCHING_NOTES: {
      draft[action.payload.memberId] = RecordState.LOADING

      return draft
    }
    case FETCH_NOTES_FULFILLED: {
      draft[action.payload.memberId] = RecordState.LOADED

      return draft
    }
    case FETCH_NOTES_FAILED: {
      draft[action.payload.memberId] = RecordState.ERRORED

      return draft
    }
    default:
      return draft
  }
}, {})

function noteMap(state: NoteMap = {}, action: NoteActions) {
  switch (action.type) {
    case FETCH_NOTES_FULFILLED: {
      const { notes } = action.payload
      const newState = {
        ...state,
      }

      notes.forEach((note) => {
        newState[note.id] = note
      })

      return newState
    }
    case CREATE_NOTE_FULFILLED:
    case UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED: {
      const { note } = action.payload

      const newState = {
        ...state,
      }

      newState[note.id] = note

      return newState
    }
    case UPDATING_NOTE:
    case UPDATE_NOTE_FAILED: {
      const { noteId, text, updatedAt, updatedBy } = action.payload

      const updatedNote = {
        ...state[noteId],
        text,
        updatedAt,
        updatedBy,
      }

      return {
        ...state,
        [noteId]: updatedNote,
      }
    }

    default:
      return state
  }
}

function memberIdToNoteIdsMap(state: MemberIdToNoteIdsMap = {}, action: NoteActions) {
  switch (action.type) {
    case FETCH_NOTES_FULFILLED: {
      const { notes } = action.payload
      const newState: MemberIdToNoteIdsMap = {}

      notes.forEach((note) => {
        const { patientId } = note

        if (patientId) {
          if (!Object.prototype.hasOwnProperty.call(newState, patientId)) {
            newState[patientId] = []
          }

          newState[patientId].push(note.id)
        }
      })

      // we override the existing value for any new patient that comes in,
      // because we fetch all notes for an patient at once.
      // if this precondition changes, then we will have to write some merging logic.
      return {
        ...state,
        ...newState,
      }
    }
    case CREATE_NOTE_FULFILLED:
    case UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED:
    case DEACTIVATE_PATIENT_FULFILLED:
    case ACTIVATE_PATIENT_FULFILLED: {
      const { note } = action.payload
      let newState: MemberIdToNoteIdsMap = {}

      if (note.patientId) {
        const existingNoteIds: string[] = state[note.patientId] || []
        newState = {
          [note.patientId]: [note.id].concat(existingNoteIds),
        }
      }

      return {
        ...state,
        ...newState,
      }
    }
    default:
      return state
  }
}

export const note: Reducer<NoteState, NoteActions> = combineReducers({
  noteMap,
  memberIdToNoteIdsMap,
  memberIdToRecordStateMap,
})

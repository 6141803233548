import Ajv from 'ajv'
import { UnknownTaskEvent } from '../../../types/task'
import * as http from '../../http'
import { unknownTaskEventSchema } from '../../schema/task-event'
import { createApiLogger } from '../../../logger'
import { uuid } from '../../../utils/uuid'

const ajv = new Ajv()

const validate = ajv.compile(unknownTaskEventSchema)

const createUnknownTaskEvent = async (
  taskId: string,
  event: UnknownTaskEvent,
  accessToken: string,
): Promise<UnknownTaskEvent> => {
  const requestId = uuid()
  const logger = createApiLogger('POST', '/v1/task/:taskId/events', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, statusCode } = await http.post(`/v1/task/${taskId}/events`, event, { accessToken, requestId })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    logger.logSuccess({
      statusCode,
    })

    return body
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default createUnknownTaskEvent

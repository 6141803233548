import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import cn from 'clsx'
import { useInView } from 'react-intersection-observer'
import '@szhsin/react-menu/dist/index.css'
import { useRouteMatch } from 'react-router-dom'
import { Table, TableBody, TableHeader, TableRow } from '../../../ui/table'
import {
  getPatientBloodPressureGoalForDisplay,
  getPatientName,
  getPatientPreferredName,
} from '../../../../redux/selectors/patient'
import { fetchBloodPressureAverages } from '../../../../redux/thunks/blood-pressure-averages'
import {
  getBloodPressureAverage,
  getBloodPressureAverageRecordState,
  getDiastolicRange,
  getHeartRateRange,
  getSystolicRange,
  getBloodPressureAverageLatestTime,
} from '../../../../redux/selectors/blood-pressure-average'
import { RecordState } from '../../../../types/record-state'
import { isErrored, isLoadingOrDoesNotExist } from '../../../../utils/record-state'
import { Button } from '../../../ui/button'
import { ReactComponent as IconRefresh } from '../../../icons/solid/refresh.svg'
import { ReactComponent as IconExclamation } from '../../../icons/solid/exclamation.svg'
import Tooltip from '../../../ui/tooltip'
import { PATIENT_ITEM, PATIENT_ITEM_ERROR, PATIENT_ITEM_NAME } from '../../../../consts/data-testids'
import { daysFromNow } from '../../../../utils/dates'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import useTooltip from '../../../../hooks/use-tooltip'
import { Routes } from '../../../../types/route'
import MovingAverageStaleIndicator from './moving-average-stale-indicator'
import PatientAlerts from './patient-alerts'

interface PatientListProps {
  bodyClassName?: string
  headerClassName?: string
  patientIds: string[]
  route: Routes.DASHBOARD | Routes.PATIENT_LIST
}
export function PatientList({
  bodyClassName,
  headerClassName,
  patientIds,
  route,
}: PatientListProps): ReactElement | null {
  if (!patientIds.length) {
    return null
  }

  return (
    <Table>
      <TableHeader
        className={headerClassName}
        headers={[
          { text: 'Name', className: 'flex-0 flex-basis-200' },
          { text: 'Alerts' },
          { text: 'BP Average', subtext: 'Latest 12-MA' },
          { text: '7 days prior', subtext: '12-MA' },
          { text: '28 days prior', subtext: '12-MA' },
          { text: 'SBP Range', subtext: 'Latest 12-MA' },
          { text: 'DBP Range', subtext: 'Latest 12-MA' },
          { text: 'HR Range', subtext: 'Latest 12-MA' },
          { text: 'Goal BP' },
        ]}
      />
      <TableBody className={bodyClassName}>
        {patientIds.map((patientId, i) => (
          <Patient key={patientId} patientId={patientId} route={route} />
        ))}
      </TableBody>
    </Table>
  )
}

interface PatientProps {
  patientId: string
  route: Routes.DASHBOARD | Routes.PATIENT_LIST
}
function Patient({ patientId, route }: PatientProps) {
  const dispatch = useAppDispatch()
  const match = useRouteMatch(route)
  const { ref: observedRef, inView } = useInView()
  let patientName = useAppSelector((state) => getPatientName(state, patientId))

  const preferredName = useAppSelector((state) => getPatientPreferredName(state, patientId))
  const first12pointMovingAverage = useAppSelector((state) => getBloodPressureAverage(state, patientId, 0))
  const second12pointMovingAverage = useAppSelector((state) => getBloodPressureAverage(state, patientId, 7))
  const third12pointMovingAverage = useAppSelector((state) => getBloodPressureAverage(state, patientId, 28))
  const systolicRange = useAppSelector((state) => getSystolicRange(state, patientId, 0))
  const diastolicRange = useAppSelector((state) => getDiastolicRange(state, patientId, 0))
  const heartRateRange = useAppSelector((state) => getHeartRateRange(state, patientId, 0))
  const bpGoal = useAppSelector((state) => getPatientBloodPressureGoalForDisplay(state, patientId))
  const recordState = useAppSelector((state) => getBloodPressureAverageRecordState(state, patientId))
  const latestTimestamp = useAppSelector((state) => getBloodPressureAverageLatestTime(state, patientId, 0))
  const last12maCalculatedDays = useMemo(
    () => (!latestTimestamp ? -1 : daysFromNow(latestTimestamp)),
    [latestTimestamp],
  )
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [12, 8],
  })

  if (preferredName) {
    patientName += ` (${preferredName})`
  }

  useEffect(() => {
    if (inView) {
      dispatch(fetchBloodPressureAverages(patientId))
    }
  }, [dispatch, inView, patientId])

  return (
    <TableRow ref={observedRef} to={`${match?.url}/${patientId}`} data-testid={PATIENT_ITEM}>
      <p
        className={cn(
          'py-3 text-sm flex-0 flex-basis-200 overflow-ellipsis whitespace-nowrap overflow-hidden flex items-center',
          isErrored(recordState) ? 'text-rivaFuchsia-500' : '',
        )}
        data-testid={PATIENT_ITEM_NAME}
      >
        {patientName}
        {isErrored(recordState) ? (
          <span className="inline-block h-6 w-6 ml-1">
            <IconExclamation />
          </span>
        ) : null}
      </p>
      <div className="pl-2 flex-1 flex items-center">
        <PatientAlerts patientId={patientId} />
      </div>
      <TableRowText
        text={
          <span className="flex-1 flex items-center">
            <span {...(first12pointMovingAverage !== '-' ? getTriggerProps() : undefined)}>
              {first12pointMovingAverage}
            </span>
            {first12pointMovingAverage !== '-' && (
              <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
                <strong>{'Last 12-MA:'}</strong>
                {` ${last12maCalculatedDays} days ago on ${DateTime.fromISO(latestTimestamp).toLocaleString({
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}`}
              </Tooltip>
            )}
            <MovingAverageStaleIndicator patientId={patientId} />
          </span>
        }
        textColor="text-rivaOffblack-700"
        recordState={recordState}
      />
      <TableRowText text={second12pointMovingAverage} textColor="text-rivaOffblack-700" recordState={recordState} />
      <TableRowText text={third12pointMovingAverage} textColor="text-rivaOffblack-700" recordState={recordState} />
      <TableRowText text={systolicRange} textColor="text-rivaOffblack-700" recordState={recordState} />
      <TableRowText text={diastolicRange} textColor="text-rivaOffblack-700" recordState={recordState} />
      <TableRowText text={heartRateRange} textColor="text-rivaOffblack-700" recordState={recordState} />
      {isErrored(recordState) ? (
        <div className="flex-1" data-testid={PATIENT_ITEM_ERROR}>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              dispatch(fetchBloodPressureAverages(patientId))
            }}
          >
            Refresh
            <span className="inline-block h-4 w-4 ml-1">
              <IconRefresh />
            </span>
          </Button>
        </div>
      ) : (
        <TableRowText
          text={bpGoal !== '-' ? `< ${bpGoal}` : bpGoal}
          textColor="text-rivaPurple"
          recordState={recordState}
        />
      )}
    </TableRow>
  )
}

function TableRowText(props: { text: ReactNode; textColor: string; recordState: RecordState }) {
  let textToDisplay = props.text
  let textColor = props.textColor
  if (isLoadingOrDoesNotExist(props.recordState)) {
    textToDisplay = '...'
  }

  if (isErrored(props.recordState)) {
    textColor = 'text-rivaFuchsia-500'
  }

  return <p className={cn('text-sm pl-2 flex flex-1 items-center', textColor)}>{textToDisplay}</p>
}

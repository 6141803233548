import { ApolloClient } from '@apollo/client'
import { TasksApi } from '../../api/hooks/use-tasks-api'
import { ListItem } from '../../types/lists'
import { AssigneeType, TaskNote, TaskStatus, UnknownTaskEvent } from '../../types/task'
import { parseListId } from '../../utils/lists'
import { uuid } from '../../utils/uuid'
import {
  fetchTaskByIdFailed,
  fetchTaskByIdSucceeded,
  fetchTasksFailed,
  fetchTasksRequested,
  fetchTasksSucceeded,
  prefetchTasksSucceeded,
  taskAssigned,
  taskAssignFailed,
  taskAssignSucceeded,
  taskNoteAddFailed,
  taskNoteAddRequested,
  taskNoteAddSucceeded,
  taskDueDateChanged,
  taskDueDateChangeFailed,
  taskDueDateChangeSucceeded,
  taskStatusUpdated,
  taskStatusUpdateFailed,
  taskStatusUpdateSucceeded,
  taskEventUndone,
  taskEventUndoSucceeded,
  taskEventUndoFailed,
  fetchTaskCarePathwayRequested,
  fetchTaskCarePathwayFulfilled,
  fetchTaskCarePathwayFailed,
  taskChoiceSelectRequested,
  taskChoiceSelectFulfilled,
  taskChoiceSelectFailed,
} from '../actions/tasks'
import { AppThunk } from '../reducers'
import { getLoggedInPersonId } from '../selectors/app'
import { getListHash } from '../selectors/lists'
import { getTaskById } from '../selectors/tasks'
import { getStatusText } from '../../utils/tasks'
import taskCarePathwayQuery from '../../schemas/task-care-pathway-query'
import { CarePathwayEvent } from '../../types/care-pathway'
import { TaskEventType } from '../../__generated__/graphql'
import { SurveySubmission } from '../../types/survey'
import { track } from '../../i13n'

export const prefetchTasks =
  ({ listId }: { listId: string }, tasksApi: TasksApi): AppThunk =>
  async (dispatch, getState) => {
    const { patientId, practitionerId, sortOrder, sortPriority, taskStatus, taskType } = parseListId(listId)
    const state = getState()
    const oldHash = getListHash(state, listId)

    try {
      const {
        response: { results, totalCount },
        hash: newHash,
      } = await tasksApi.getTasks({
        assigneeType: AssigneeType.AssigneeTypeCareTeam,
        includeComments: true,
        includeEvents: true,
        lastHash: oldHash,
        limit: 10,
        offset: 0,
        query: {
          assignee: practitionerId,
          patientId,
          status: taskStatus,
          type: taskType,
        },
        sortOrder,
        sortPriority,
      })

      dispatch(
        prefetchTasksSucceeded({
          hash: newHash,
          listId,
          tasks: results ?? [],
          totalCount,
        }),
      )
      // eslint-disable-next-line no-empty
    } catch (_) {}
  }

export const fetchTasks =
  (
    {
      listId,
      offset = 0,
      limit = 10,
    }: {
      listId: string
      offset?: number
      limit?: number
    },
    tasksApi: TasksApi,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      fetchTasksRequested({
        listId,
      }),
    )

    try {
      const { patientId, practitionerId, sortOrder, sortPriority, taskStatus, taskType } = parseListId(listId)

      const {
        response: { results: tasks, totalCount },
        hash,
      } = await tasksApi.getTasks({
        assigneeType: AssigneeType.AssigneeTypeCareTeam,
        includeComments: true,
        includeEvents: true,
        lastHash: '',
        limit,
        offset,
        query: { assignee: practitionerId, patientId, status: taskStatus, type: taskType },
        sortOrder,
        sortPriority,
      })

      dispatch(
        fetchTasksSucceeded({
          hash,
          listId,
          startIndex: offset,
          tasks: tasks ?? [],
          totalCount,
        }),
      )
    } catch (_) {
      dispatch(
        fetchTasksFailed({
          listId,
        }),
      )
    }
  }

export const assignTask =
  (
    {
      listId,
      i13n,
      id,
      assignee,
      note,
    }: { listId: string; i13n?: Record<string, unknown>; id: string; assignee: string; note?: string },
    tasksApi: TasksApi,
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      const task = getTaskById(state, id)

      if (!task) {
        throw new Error(`unknown task ${id}`)
      }

      dispatch(
        taskAssigned({
          listId,
          id,
          assignee,
        }),
      )

      const event = await tasksApi.createTaskEvent(id, {
        carePathwayId: task.carePathwayId,
        data: {
          assignee,
        },
        eventType: TaskEventType.AssignTask,
        note,
        patientId: task.patientId,
      })

      track('Reassign Modal Submitted', {
        ...i13n,
        'Task ID': task.id,
        'Task Status': getStatusText(task.status),
        'Task Title': task.summary,
        'Note Added': note ? 'Yes' : 'No',
      })

      dispatch(
        taskAssignSucceeded({
          event,
          listId,
          id,
        }),
      )
    } catch (error) {
      dispatch(
        taskAssignFailed({
          listId,
          id,
        }),
      )
    }
  }

export const updateTaskStatus =
  (
    {
      id,
      listId,
      note,
      status,
      i13n,
    }: { i13n?: Record<string, unknown>; id: string; listId: string; note?: string; status: TaskStatus },
    tasksApi: TasksApi,
  ): AppThunk =>
  async (dispatch, getState) => {
    const task = getTaskById(getState(), id)

    if (!task) {
      dispatch(
        taskStatusUpdateFailed({
          id,
          listId,
          status,
        }),
      )

      return
    }

    if (status === task.status) {
      return
    }

    dispatch(
      taskStatusUpdated({
        id,
        listId,
        status,
      }),
    )

    try {
      const event = await tasksApi.createTaskEvent(id, {
        eventType: TaskEventType.UpdateTaskStatus,
        patientId: task.patientId,
        carePathwayId: task.carePathwayId,
        note,
        data: {
          taskStatus: status,
        },
      })

      track('Task Status Updated', {
        ...i13n,
        'Task ID': task.id,
        'Task Status': getStatusText(task.status),
        'Task Title': task.summary,
        Status: getStatusText(status),
      })

      dispatch(
        taskStatusUpdateSucceeded({
          event,
          id,
          listId,
          status,
        }),
      )
    } catch (error) {
      dispatch(
        taskStatusUpdateFailed({
          id,
          listId,
          status,
        }),
      )
    }
  }

export const changeTaskDueDate =
  (
    { dueDate, i13n, id }: { dueDate: string; i13n?: Record<string, unknown>; id: string },
    tasksApi: TasksApi,
  ): AppThunk =>
  async (dispatch, getState) => {
    const task = getTaskById(getState(), id)

    if (!task) {
      dispatch(
        taskDueDateChangeFailed({
          id,
        }),
      )

      return
    }

    dispatch(
      taskDueDateChanged({
        id,
        dueDate,
      }),
    )

    try {
      const event = await tasksApi.createTaskEvent(id, {
        eventType: TaskEventType.SetDueDate,
        patientId: task.patientId,
        carePathwayId: task.carePathwayId,
        data: {
          dueDate,
        },
      })

      track('Task Change Due Date Submitted', {
        ...i13n,
        'Task ID': task.id,
        'Task Status': getStatusText(task.status),
        'Task Title': task.summary,
      })

      dispatch(
        taskDueDateChangeSucceeded({
          event,
          id,
        }),
      )
    } catch (error) {
      dispatch(
        taskDueDateChangeFailed({
          id,
        }),
      )
    }
  }

export const fetchTaskById =
  (taskId: string, tasksApi: TasksApi): AppThunk =>
  async (dispatch) => {
    try {
      const task = await tasksApi.getTaskById(taskId, {
        includeComments: true,
        includeEvents: true,
      })

      dispatch(
        fetchTaskByIdSucceeded({
          id: taskId,
          task,
        }),
      )
    } catch (_) {
      dispatch(
        fetchTaskByIdFailed({
          id: taskId,
        }),
      )
    }
  }

export const addNote =
  (taskId: string, body: string, tasksApi: TasksApi): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const loggedInPersonId = getLoggedInPersonId(state)
    const task = getTaskById(state, taskId)

    const temporaryNote: TaskNote = {
      body,
      created: {
        at: new Date().toISOString(),
        by: loggedInPersonId,
      },
      id: uuid('temporary-comment-'),
      taskId,
      updated: {
        at: '',
        by: '',
      },
    }

    if (!task) {
      dispatch(
        taskNoteAddFailed({
          taskId,
          temporaryNote,
        }),
      )

      return
    }

    dispatch(
      taskNoteAddRequested({
        taskId,
        temporaryNote,
      }),
    )

    try {
      const event = await tasksApi.createTaskEvent(taskId, {
        eventType: TaskEventType.AddNote,
        patientId: task.patientId,
        carePathwayId: task.carePathwayId,
        data: {
          body,
        },
      })

      track('Task Details Notes Submitted', {
        'Task ID': task.id,
        'Task Status': getStatusText(task.status),
        'Task Title': task.summary,
      })

      dispatch(
        taskNoteAddSucceeded({
          event,
          taskId,
        }),
      )
    } catch (_) {
      dispatch(
        taskNoteAddFailed({
          taskId,
          temporaryNote,
        }),
      )
    }
  }

export const cancelTaskEvent =
  ({ id, eventId, listId }: ListItem & { eventId: string }, tasksApi: TasksApi): AppThunk =>
  async (dispatch, getState) => {
    const task = getTaskById(getState(), id)

    if (!task) {
      return
    }

    dispatch(taskEventUndone({ id, eventId, listId }))

    try {
      const event = await tasksApi.createTaskEvent(id, {
        carePathwayId: task.carePathwayId,
        data: {
          eventId,
        },
        eventType: TaskEventType.CancelEvent,
        patientId: task.patientId,
      })

      dispatch(
        taskEventUndoSucceeded({
          id,
          eventId,
          listId,
          event,
        }),
      )
    } catch (_) {
      dispatch(
        taskEventUndoFailed({
          id,
          eventId,
          listId,
        }),
      )
    }
  }

export const fetchTaskCarePathway =
  (taskId: string, apolloClient: ApolloClient<object>): AppThunk =>
  async (dispatch) => {
    dispatch(
      fetchTaskCarePathwayRequested({
        taskId,
      }),
    )

    try {
      const { data } = await apolloClient.query({
        query: taskCarePathwayQuery,
        variables: {
          taskId,
        },
      })

      if (!data.task?.carePathway) {
        throw new Error('Care pathway not found')
      }

      const surveySubmissions: SurveySubmission[] = []

      data.task.carePathway.events?.forEach(({ surveySubmission }) => {
        if (surveySubmission?.id) {
          surveySubmissions.push(surveySubmission as SurveySubmission)
        }
      })

      dispatch(
        fetchTaskCarePathwayFulfilled({
          carePathway: data.task.carePathway,
          events: (data.task.carePathway.events as CarePathwayEvent[]) ?? [],
          surveySubmissions,
          taskId,
        }),
      )
    } catch (_) {
      dispatch(
        fetchTaskCarePathwayFailed({
          taskId,
        }),
      )
    }
  }

export const selectTaskChoice =
  (
    {
      id,
      listId,
      event,
    }: ListItem & {
      event: UnknownTaskEvent
    },
    tasksApi: TasksApi,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      taskChoiceSelectRequested({
        id,
        listId,
      }),
    )

    try {
      await tasksApi.createUnknownTaskEvent(id, event)

      dispatch(
        taskChoiceSelectFulfilled({
          id,
          listId,
        }),
      )
    } catch (_) {
      dispatch(
        taskChoiceSelectFailed({
          id,
          listId,
        }),
      )
    }
  }

import { useCallback, MouseEventHandler, ReactNode, forwardRef, useImperativeHandle, useRef } from 'react'
import clsx from 'clsx'
import { I13n } from '../../../types/i13n'
import { track } from '../../../i13n'

export interface Props {
  children: ReactNode
  className?: string
  'aria-controls'?: string
  id?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  selected: boolean
  i13n?: Partial<I13n>
}

const Tab = forwardRef<HTMLButtonElement, Props>(
  ({ children, i13n, className, onClick, selected, ...a11yProps }, ref) => {
    const innerRef = useRef<HTMLButtonElement>(null)

    useImperativeHandle<HTMLButtonElement | null, HTMLButtonElement | null>(ref, () => innerRef.current)

    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        track(i13n?.eventName ?? 'Tab Clicked', {
          Tab: (event.target as HTMLButtonElement).textContent ?? '',
          ...i13n?.properties,
        })
        onClick && onClick(event)
      },
      [i13n?.eventName, i13n?.properties, onClick],
    )

    return (
      <button
        {...a11yProps}
        className={clsx(
          'text-sm ml-5 py-4 inline-block font-semibold',
          {
            'text-rivaOffblack-900 hover:text-rivaPurple-700': !selected,
            'text-rivaPurple-500': selected,
          },
          className,
        )}
        role="tab"
        aria-selected={selected}
        onClick={handleClick}
        ref={innerRef}
      >
        {children}
      </button>
    )
  },
)

export default Tab

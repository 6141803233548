import { ActionCreator } from '../../types/actions'
import {
  AssignDeviceToPatientFailedAction,
  AssignDeviceToPatientSucceededAction,
  ASSIGN_DEVICE_TO_PATIENT_FAILED,
  ASSIGN_DEVICE_TO_PATIENT_SUCCEEDED,
} from '../../types/device'

export const assignDeviceToPatientSucceeded: ActionCreator<AssignDeviceToPatientSucceededAction> = (payload) => ({
  type: ASSIGN_DEVICE_TO_PATIENT_SUCCEEDED,
  payload,
})

export const assignDeviceToPatientFailed = (payload: {
  patientId: string
  error: { message: string }
}): AssignDeviceToPatientFailedAction => ({
  type: ASSIGN_DEVICE_TO_PATIENT_FAILED,
  payload,
})

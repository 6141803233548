import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode } from 'react'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement> {
  children?: ReactNode
  maxHeight?: 'short' | 'normal'
  paddingLess?: boolean
}

export default forwardRef<HTMLUListElement, Props>(function List(
  { children, className, maxHeight = 'normal', paddingLess, ...props },
  ref,
) {
  return (
    <ul
      {...props}
      ref={ref}
      className={clsx(
        'bg-white z-50 pb-3 overflow-y-auto',
        {
          'max-h-96': maxHeight === 'normal',
          'max-h-52': maxHeight === 'short',
          'pt-4': !paddingLess,
        },
        className,
      )}
    >
      {children}
    </ul>
  )
})

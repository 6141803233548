import { AppThunk } from '../reducers'
import {
  fetchingPatients,
  fetchPatientsFailed,
  fetchPatientsFulfilled,
  creatingPatient,
  createPatientFulfilled,
  createPatientFailed,
  updatingPatient,
  updatePatientFulfilled,
  updatePatientFailed,
  deactivatingPatient,
  deactivatePatientFulfilled,
  deactivatePatientFailed,
  activatingPatient,
  activatePatientFulfilled,
  activatePatientFailed,
  fetchPatientByIdRequested,
  fetchPatientByIdSucceeded,
  fetchPatientByIdFailed,
  fetchPatientByChatChannelRequested,
  fetchPatientByChatChannelSucceeded,
  fetchPatientByChatChannelFailed,
} from '../actions/patient'
import {
  doesPatientExist,
  getPatientIdByChatChannel,
  getPatientRecordStateByChatChannel,
  getPatientRecordStateById,
  getPatientWirelessCuffId,
} from '../selectors/patient'
import {
  BloodPressureManagementStatus,
  CreatePatientPayload,
  Patient,
  PatientStatus,
  UpdatePatientPayload,
} from '../../types/patient'
import { assignDeviceToPatientFailed, assignDeviceToPatientSucceeded } from '../actions/device'
import { RecordState } from '../../types/record-state'
import { getListRecordState } from '../selectors/lists'
import { buildListId, parseListId } from '../../utils/lists'
import { ApiClient } from '../../api'
import { PatientsApi } from '../../api/hooks/use-patients-api'

const fetchPatientsForList = async (listId: string, dispatch: Parameters<AppThunk>[0], apiClient: ApiClient) => {
  const {
    acquisitionChannel = [],
    limit,
    managementStatus,
    offset,
    patientId = [],
    patientType,
    practitionerId = [],
  } = parseListId(listId)

  dispatch(
    fetchingPatients({
      listId,
    }),
  )

  try {
    const { patients, totalCount } = await apiClient.getPatients({
      acquisitionChannels: acquisitionChannel,
      bloodPressureManagementStatus: managementStatus,
      limit,
      offset,
      patientIds: patientId,
      patientType,
      practitionerIds: practitionerId,
    })

    dispatch(
      fetchPatientsFulfilled({
        listId,
        patients: patients ?? [],
        totalCount,
      }),
    )

    return totalCount
  } catch (e) {
    dispatch(
      fetchPatientsFailed({
        listId,
      }),
    )
  }
}

export const fetchPatients =
  (listId: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    const state = getState()
    const recordState = getListRecordState(state, listId)

    if (recordState === RecordState.LOADING) {
      return
    }

    const totalCount = (await fetchPatientsForList(listId, dispatch, apiClient)) ?? 0

    const descriptor = parseListId(listId)
    const { limit = 0, offset = 0 } = descriptor
    // Prefetch neighbors
    const prevListId = buildListId({
      ...descriptor,
      offset: offset - limit,
    })
    const nextListId = buildListId({
      ...descriptor,
      offset: offset + limit,
    })
    const prevRecordState = getListRecordState(state, prevListId)
    const nextRecordState = getListRecordState(state, nextListId)

    const promises: Promise<unknown>[] = []

    if (offset > 0 && prevRecordState === RecordState.DOES_NOT_EXIST) {
      promises.push(fetchPatientsForList(prevListId, dispatch, apiClient))
    }

    if (offset + limit < totalCount && nextRecordState === RecordState.DOES_NOT_EXIST) {
      promises.push(fetchPatientsForList(nextListId, dispatch, apiClient))
    }

    await Promise.all(promises)
  }

export const searchPatients =
  (listId: string, patientsApi: PatientsApi): AppThunk =>
  async (dispatch) => {
    const { acquisitionChannel, limit, managementStatus, offset, patientType, practitionerId, query } =
      parseListId(listId)

    dispatch(
      fetchingPatients({
        listId,
      }),
    )

    try {
      const { results, totalCount } = await patientsApi.search({
        acquisitionChannels: acquisitionChannel,
        fuzzyName: query,
        limit,
        managementStatuses: managementStatus ? [managementStatus] : undefined,
        offset,
        patientTypes: patientType,
        practitionerIds: practitionerId,
      })

      dispatch(
        fetchPatientsFulfilled({
          listId,
          patients: results ?? [],
          totalCount,
        }),
      )
    } catch (e) {
      dispatch(
        fetchPatientsFailed({
          listId,
        }),
      )
    }
  }

export const createPatient =
  (
    listId: string,
    {
      emergencyContactPhoneNumber,
      phoneNumber,
      wirelessCuffId,
      ...payload
    }: CreatePatientPayload & {
      wirelessCuffId: string
    },
  ): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    dispatch(creatingPatient())

    const e164FormattedPhoneNumber =
      '+1' +
      phoneNumber
        .split('')
        .filter((char) => char.match(/\d/g))
        .join('')

    let patient: Patient | undefined

    try {
      patient = await apiClient.createPatient({
        phoneNumber: e164FormattedPhoneNumber,
        emergencyContactPhoneNumber: emergencyContactPhoneNumber ? '+1' + emergencyContactPhoneNumber : '',
        ...payload,
      })

      dispatch(
        createPatientFulfilled({
          listId,
          patient,
        }),
      )
    } catch (e) {
      dispatch(createPatientFailed(e as Error))

      return
    }

    if (wirelessCuffId) {
      try {
        const device = await apiClient.assignDeviceToPatient(patient.id, wirelessCuffId)

        dispatch(assignDeviceToPatientSucceeded({ device }))
      } catch (err) {
        dispatch(
          assignDeviceToPatientFailed({
            patientId: patient.id,
            error: {
              message: 'Failed to assign device to patient.',
            },
          }),
        )
      }
    }
  }

export const updatePatient =
  (
    patientId: string,
    {
      emergencyContactPhoneNumber,
      wirelessCuffId,
      ...payload
    }: UpdatePatientPayload & {
      wirelessCuffId?: string
    },
  ): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    dispatch(updatingPatient())

    try {
      const existingWirelessCuffId = getPatientWirelessCuffId(getState(), patientId)

      if (typeof wirelessCuffId !== 'undefined') {
        if (wirelessCuffId !== existingWirelessCuffId) {
          if (existingWirelessCuffId) {
            await apiClient.unassignDeviceFromPatient(patientId, existingWirelessCuffId)
          }

          if (wirelessCuffId) {
            await apiClient.assignDeviceToPatient(patientId, wirelessCuffId)
          }
        }
      }

      const patient = await apiClient.updatePatient(patientId, {
        emergencyContactPhoneNumber: emergencyContactPhoneNumber ? '+1' + emergencyContactPhoneNumber : '',
        ...payload,
      })

      dispatch(updatePatientFulfilled(patient))
    } catch (e) {
      dispatch(updatePatientFailed(e as Error))
    }
  }

export const deactivatePatient =
  (patientId: string, noteText: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    dispatch(deactivatingPatient())

    try {
      const note = await apiClient.createNoteForPatient(noteText, patientId)
      const patient = await apiClient.deactivatePatient(patientId)

      dispatch(deactivatePatientFulfilled(patient as Patient, note))
    } catch (e) {
      dispatch(deactivatePatientFailed(e as Error))
    }
  }

export const activatePatient =
  (patientId: string, noteText: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    dispatch(activatingPatient())

    try {
      const { note, patient } = await apiClient.updatePatientStatusWithNote(
        patientId,
        noteText,
        PatientStatus.ACTIVE,
        BloodPressureManagementStatus.ACTIVE_MANAGEMENT,
      )

      dispatch(activatePatientFulfilled(patient as Patient, note))
    } catch (e) {
      dispatch(activatePatientFailed(e as Error))
    }
  }

export const fetchPatientById =
  (id: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    const state = getState()
    const exists = doesPatientExist(state, id)
    const recordState = getPatientRecordStateById(state, id)

    if (exists || recordState !== RecordState.DOES_NOT_EXIST) {
      return
    }

    dispatch(
      fetchPatientByIdRequested({
        id,
      }),
    )

    try {
      const { patient } = await apiClient.getPatientById(id)

      dispatch(
        fetchPatientByIdSucceeded({
          id,
          patient,
        }),
      )
    } catch (_) {
      dispatch(
        fetchPatientByIdFailed({
          id,
        }),
      )
    }
  }

export const fetchPatientByChatChannel =
  (channelUrl: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    const state = getState()
    const patientId = getPatientIdByChatChannel(state, channelUrl)
    const recordState = getPatientRecordStateByChatChannel(state, channelUrl)

    if (patientId || recordState !== RecordState.DOES_NOT_EXIST) {
      return
    }

    dispatch(
      fetchPatientByChatChannelRequested({
        channelUrl,
      }),
    )

    try {
      const { patient } = await apiClient.getPatientByChatChannel(channelUrl)

      dispatch(
        fetchPatientByChatChannelSucceeded({
          channelUrl,
          patient,
        }),
      )
    } catch (_) {
      dispatch(
        fetchPatientByChatChannelFailed({
          channelUrl,
        }),
      )
    }
  }

import clsx from 'clsx'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useAppSelector } from '../../../redux'
import { getAllToasts } from '../../../redux/selectors/ui'
import {
  TASK_CHOICE_SELECT_FAILED_TOAST,
  TASK_CHOICE_SELECT_SUCCESS_TOAST,
  TASK_STATUS_UPDATE_SUCCESS_TOAST,
} from '../../../types/toast'
import { Button, ButtonVariant } from '../button'
import { track } from '../../../i13n'
import TaskStatusUpdateSuccessToast from './definitions/task_status_update_success_toast'
import { TaskChoiceSelectFailedToast, TaskChoiceSelectSuccessToast } from './definitions/task-choice-select'

const ToastContainer = (): ReactElement | null => {
  const toasts = useAppSelector(getAllToasts)
  const [expanded, setExpanded] = useState(false)
  const close = useCallback(() => {
    setExpanded(false)

    track('Toast See Less Clicked')
  }, [])
  const open = useCallback(() => {
    if (toasts.length > 1) {
      setExpanded(true)
    }

    track('Toast Expanded', {
      Number: toasts.length,
    })
  }, [toasts.length])

  useEffect(() => {
    if (!toasts.length) {
      setExpanded(false)
    }
  }, [toasts.length])

  if (!toasts.length) {
    return null
  }

  return (
    <div
      className={clsx('absolute top-0 px-3 pt-[17px] box-border z-10', {
        'h-[128px]': !expanded,
        'h-full w-full': expanded,
        'cursor-pointer': toasts.length > 1,
      })}
      data-testid="toast_contaienr"
      onClick={!expanded ? open : undefined}
    >
      {expanded ? <div className="absolute top-0 right-0 bottom-0 left-0 bg-black/40" onClick={close}></div> : null}
      <div className="w-[576px] relative flex flex-col items-end">
        {expanded ? (
          <Button onClick={close} variant={ButtonVariant.PRIMARY_INVERTED}>
            See less
          </Button>
        ) : null}
        {(expanded ? toasts : toasts.slice(0, 3)).map((toast) => {
          switch (toast.type) {
            case TASK_STATUS_UPDATE_SUCCESS_TOAST:
              return <TaskStatusUpdateSuccessToast expanded={expanded} key={toast.id} {...toast} />
            case TASK_CHOICE_SELECT_SUCCESS_TOAST:
              return <TaskChoiceSelectSuccessToast expanded={expanded} key={toast.id} {...toast} />
            case TASK_CHOICE_SELECT_FAILED_TOAST:
              return <TaskChoiceSelectFailedToast expanded={expanded} key={toast.id} {...toast} />
            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

export default ToastContainer

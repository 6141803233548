import { ReactElement } from 'react'
import { useAppSelector } from '../../../../redux'
import { getListItemIds, getListRecordState } from '../../../../redux/selectors/lists'
import { RecordState } from '../../../../types/record-state'
import ErrorState from '../../../views/patient-profile/error-state'
import { LoadingIndicator } from '../../../ui/loading-indicator'
import EmptyState from '../../../views/patient-profile/empty-state'
import { parseListId } from '../../../../utils/lists'
import { I13n } from '../../../../types/i13n'
import PatientSearchTable from './patient-search-table'

interface Props {
  listId: string
}

const emptyI13n: I13n = {
  eventName: 'Search Results Empty Page Shown',
  properties: {},
}
const errorI13n: I13n = {
  eventName: 'Search Results Error Page Shown',
  properties: {},
}

const PatientSearchResultList = ({ listId }: Props): ReactElement | null => {
  const { query } = parseListId(listId)
  const recordState = useAppSelector((state) => getListRecordState(state, listId))
  const patientIds = useAppSelector((state) => getListItemIds(state, listId))

  if (!query) {
    return null
  }

  return (
    <main className="bg-white flex-grow-[1] basis-0 shrink-0 flex flex-col px-3">
      {recordState === RecordState.ERRORED ? (
        <ErrorState i13n={errorI13n} />
      ) : (!patientIds.length && recordState === RecordState.LOADING) || recordState === RecordState.DOES_NOT_EXIST ? (
        <LoadingIndicator flex />
      ) : !patientIds.length && recordState === RecordState.LOADED ? (
        <div className="flex-1 flex items-center justify-center">
          <EmptyState i13n={emptyI13n} />
        </div>
      ) : (
        <PatientSearchTable listId={listId} />
      )}
    </main>
  )
}

export default PatientSearchResultList

import Ajv, { JSONSchemaType } from 'ajv'
import * as http from '../../http'
import { createApiLogger } from '../../../logger'
import { uuid } from '../../../utils/uuid'
import { AcquisitionChannel, BloodPressureManagementStatus, Patient, PatientType } from '../../../types/patient'
import { patientSchema } from '../../schema/patient'

export interface SearchPatientsQuery {
  acquisitionChannels?: AcquisitionChannel[]
  fuzzyName?: string
  limit?: number
  managementStatuses?: BloodPressureManagementStatus[]
  offset?: number
  patientTypes?: PatientType[]
  practitionerIds?: string[]
}

export interface SearchPatientsResponse {
  results?: Patient[] | null
  totalCount: number
}

const ajv = new Ajv()

const schema: JSONSchemaType<SearchPatientsResponse> = {
  type: 'object',
  properties: {
    results: {
      type: 'array',
      items: patientSchema,
      nullable: true,
    },
    totalCount: {
      type: 'number',
    },
  },
  required: ['totalCount'],
}

const validate = ajv.compile(schema)

const search = async (query: SearchPatientsQuery, accessToken: string): Promise<SearchPatientsResponse> => {
  const requestId = uuid()
  const logger = createApiLogger('GET', '/v1/patient/search', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, statusCode } = await http.post('/v1/patient/search', query, {
      accessToken,
      requestId,
    })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    logger.logSuccess({
      statusCode,
    })

    return body
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default search

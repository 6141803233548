import { ReactElement } from 'react'
import { Radio, RadioGroup, RadioLabel, useRadioGroup } from '../radio'
import FieldSet from '../field-set'
import FormLabel from '../form-label'
import { Pronouns } from '../../../types/patient'

interface Props {
  isSubmitted: boolean
  onChange: (pronouns: string) => void
  required?: boolean
  value: string
}

const PronounsSelector = ({ isSubmitted, onChange, required, value }: Props): ReactElement => {
  const { getGroupLabelProps, getRadioGroupProps, getRadioProps } = useRadioGroup({ onChange, value })

  return (
    <FieldSet colspan={2}>
      <FormLabel
        {...getGroupLabelProps()}
        order={0}
        required={required}
        invalid={required && isSubmitted && value === ''}
      >
        Pronouns
      </FormLabel>
      <RadioGroup {...getRadioGroupProps()}>
        {Object.values(Pronouns).map((pronoun) => (
          <RadioLabel key={pronoun} label={pronoun}>
            <Radio {...getRadioProps(pronoun)} />
          </RadioLabel>
        ))}
      </RadioGroup>
    </FieldSet>
  )
}

export default PronounsSelector

import { JSONSchemaType } from 'ajv'
import { SurveySubmission, SurveySubmissionStatus, SurveySubmissionEvent } from '../../../types/survey'

const surveySubmissionEventSchema: JSONSchemaType<SurveySubmissionEvent> = {
  type: 'object',
  properties: {
    type: {
      type: 'string',
    },
    at: {
      type: 'string',
    },
    by: {
      type: 'string',
    },
  },
  required: ['type', 'at', 'by'],
}

const schema: JSONSchemaType<SurveySubmission> = {
  type: 'object',
  properties: {
    completedAt: {
      type: 'string',
    },
    createdAt: {
      type: 'string',
      nullable: true,
    },
    data: {
      type: 'object',
      nullable: true,
    },
    events: {
      type: 'array',
      items: surveySubmissionEventSchema,
      nullable: true,
    },
    id: {
      type: 'string',
    },
    lastSeenAt: {
      type: 'string',
      nullable: true,
    },
    patientId: {
      type: 'string',
    },
    seenBy: {
      type: 'array',
      items: {
        type: 'string',
      },
      nullable: true,
    },
    sentAt: {
      type: 'string',
      nullable: true,
    },
    startedAt: {
      type: 'string',
    },
    status: {
      type: 'string',
      enum: [
        SurveySubmissionStatus.FAILED,
        SurveySubmissionStatus.PENDING,
        SurveySubmissionStatus.SENT,
        SurveySubmissionStatus.TIMED_OUT,
        SurveySubmissionStatus.UNKNOWN,
      ],
      nullable: true,
    },
    submissionId: {
      type: 'number',
    },
    surveyId: {
      type: 'number',
    },
    url: {
      type: 'string',
      nullable: true,
    },
  },
  required: ['completedAt', 'patientId', 'startedAt', 'submissionId', 'surveyId'],
}

export default schema

import { ReactElement, useCallback } from 'react'
import range from 'lodash.range'
import FieldSet from '../field-set'
import FormLabel from '../form-label'
import { Option, Select } from '../select'

interface Props {
  hourValue?: number
  minuteValue?: number
  onHourChange: (value: number) => void
  onMinuteChange: (value: number) => void
  timeOfDay: 'am' | 'pm'
}

const ReminderTimeSelector = ({
  hourValue,
  minuteValue,
  onHourChange,
  onMinuteChange,
  timeOfDay,
}: Props): ReactElement => {
  const handleHourChange = useCallback<(value: number | undefined) => void>(
    (value) => onHourChange(value ?? -1),
    [onHourChange],
  )
  const handleMinuteChange = useCallback<(value: number | undefined) => void>(
    (value) => onMinuteChange(value ?? -1),
    [onMinuteChange],
  )

  return (
    <FieldSet>
      <FormLabel>{`${timeOfDay.toUpperCase()} reminder`}</FormLabel>
      <div className="flex order-2">
        <Select
          id={`${timeOfDay}ReminderHour`}
          value={hourValue}
          onChange={handleHourChange}
          className="mr-2 w-20"
          placeholder="Hr"
        >
          {range(3, 12).map((time) => {
            return (
              <Option key={time} value={timeOfDay === 'pm' ? time + 12 : time}>
                {time}
              </Option>
            )
          })}
        </Select>
        <Select
          id={`${timeOfDay}ReminderMinute`}
          value={minuteValue}
          onChange={handleMinuteChange}
          className="flex-1"
          placeholder="Minute"
        >
          <Option value={0}>00</Option>
          <Option value={15}>15</Option>
          <Option value={30}>30</Option>
          <Option value={45}>45</Option>
        </Select>
      </div>
    </FieldSet>
  )
}

export default ReminderTimeSelector

import Ajv from 'ajv'
import { StartableCarePathwayType, TaskEventCreateCarePathway } from '../../../types/task'
import * as http from '../../http'
import { createApiLogger } from '../../../logger'
import { uuid } from '../../../utils/uuid'
import { TaskEventType } from '../../../__generated__/graphql'
import { createCarePathwayEventSchema } from '../../schema/care-pathway-event'

const ajv = new Ajv()

const validate = ajv.compile(createCarePathwayEventSchema)

interface TaskEventPayload {
  data: Record<string, unknown>
  eventType: string
  note?: string
  patientId: string
}

interface CreateCarePathwayEventPayload extends TaskEventPayload {
  eventType: TaskEventType.CreateCarePathway
  data: {
    carePathwayType: StartableCarePathwayType
  }
}

type CreateEventPayload = CreateCarePathwayEventPayload

const createEvent = async (event: CreateEventPayload, accessToken: string): Promise<TaskEventCreateCarePathway> => {
  const requestId = uuid()
  const logger = createApiLogger('POST', '/v1/task-event', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, statusCode } = await http.post(`/v1/task-event`, event, { accessToken, requestId })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    logger.logSuccess({
      statusCode,
    })

    return body
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default createEvent

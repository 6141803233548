import { useRouteMatch } from 'react-router-dom'
import { useAppSelector } from '../../../redux'
import { getPatientIdByChatChannel } from '../../../redux/selectors/patient'
import { RightRailPanel } from '../../ui/right-rail/types'

export const useIsRightRailOpen = (rightRailPanel: RightRailPanel): boolean => {
  const match = useRouteMatch<{ channelUrl: string }>('/chat/:channelUrl')
  const isChatChannelOpen = !!match
  const channelUrl = match?.params?.channelUrl
  const patientId = useAppSelector((state) => (channelUrl ? getPatientIdByChatChannel(state, channelUrl) : undefined))

  return isChatChannelOpen && (rightRailPanel === 'patient-profile' ? !!patientId : rightRailPanel !== null)
}

import { CarePathwayStatus, CarePathwayType } from '../__generated__/graphql'
import { BloodPressureMeasurementType } from '../types/blood-pressure-measurement'
import { ListContentType, ListDescriptor } from '../types/lists'
import { AcquisitionChannel, BloodPressureManagementStatus, PatientType } from '../types/patient'
import { BpEscalationTaskTypes, TaskQueryParam, TaskStatus, TaskType } from '../types/task'
import { getTaskStatusFromQueryParam } from './tasks'

// @TODO: JSON.parse/stringify is fairly slow. At some point this should be
// changed to a faster algorithm
export const buildListId = (descriptor: ListDescriptor): string =>
  JSON.stringify(
    (Object.keys(descriptor) as (keyof ListDescriptor)[]).sort().reduce(
      (o, key) => {
        o[key] = descriptor[key]

        return o
      },
      {} as Record<string, unknown>,
    ),
  )

export const parseListId = (listId: string): ListDescriptor => {
  const descriptor = JSON.parse(listId)

  return descriptor as ListDescriptor
}

export type TaskListDescriptor = Pick<
  ListDescriptor,
  'patientId' | 'practitionerId' | 'query' | 'sortOrder' | 'sortPriority' | 'taskStatus' | 'taskType'
>

export const buildTaskListId = ({
  patientId,
  practitionerId,
  query,
  sortOrder,
  sortPriority,
  taskStatus,
  taskType,
}: TaskListDescriptor): string => {
  const listDescriptor: ListDescriptor = {
    listContentType: ListContentType.TASK,
  }

  if (query) {
    listDescriptor.query = query
  }

  if (practitionerId) {
    listDescriptor.practitionerId = practitionerId
  }

  if (patientId) {
    listDescriptor.patientId = patientId
  }

  if (sortOrder) {
    listDescriptor.sortOrder = sortOrder
  }

  if (taskStatus) {
    listDescriptor.taskStatus = taskStatus
  }

  if (sortPriority !== undefined) {
    listDescriptor.sortPriority = sortPriority
  }

  if (taskType) {
    listDescriptor.taskType = taskType
  }

  return buildListId(listDescriptor)
}

export const buildMyOpenTasksListId = (practitionerId: string): string =>
  buildTaskListId({
    query: TaskQueryParam.OPEN,
    practitionerId: [practitionerId],
    sortPriority: true,
    taskStatus: getTaskStatusFromQueryParam(TaskQueryParam.OPEN),
  })

export const serializeListIdForRoute = (descriptor: Partial<ListDescriptor>): string => {
  const params = new URLSearchParams()
  const keys = Object.keys(descriptor) as (keyof ListDescriptor)[]

  keys.forEach((key) => {
    if (key === 'listContentType') {
      return
    }

    const value = descriptor[key]

    if (Array.isArray(value)) {
      value.forEach((v) => {
        params.append(key, v)
      })
    } else if (value !== undefined) {
      params.append(key, String(value))
    }
  })

  return params.toString()
}

type ParsedSerializedListId = Omit<Partial<ListDescriptor>, 'listContentType'>

export const parseSerializedListId = (serializedListId: string): ParsedSerializedListId => {
  const descriptor: ParsedSerializedListId = {}
  const params = new URLSearchParams(serializedListId)

  params.forEach((value, key) => {
    const descriptorKey = key as keyof ParsedSerializedListId

    switch (descriptorKey) {
      case 'acquisitionChannel':
        if (!descriptor[descriptorKey]) {
          descriptor[descriptorKey] = []
        }

        descriptor[descriptorKey]?.push(value as AcquisitionChannel)
        break
      case 'patientId':
      case 'practitionerId':
      case 'personId':
        if (!descriptor[descriptorKey]) {
          descriptor[descriptorKey] = []
        }

        descriptor[descriptorKey]?.push(value)
        break
      case 'limit':
      case 'offset':
        descriptor[descriptorKey] = parseInt(value, 10) || 0
        break
      case 'managementStatus':
        descriptor[descriptorKey] = value as BloodPressureManagementStatus
        break
      case 'measurementType':
        descriptor[descriptorKey] = value as BloodPressureMeasurementType
        break
      case 'patientType':
        if (!descriptor[descriptorKey]) {
          descriptor[descriptorKey] = []
        }

        descriptor[descriptorKey]?.push(value as PatientType)
        break
      case 'query':
        descriptor[descriptorKey] = value
        break
      case 'taskStatus':
        if (!descriptor[descriptorKey]) {
          descriptor[descriptorKey] = []
        }

        descriptor[descriptorKey]?.push(value as TaskStatus)
        break
      case 'taskType':
        if (!descriptor[descriptorKey]) {
          descriptor[descriptorKey] = []
        }

        descriptor[descriptorKey]?.push(value as TaskType)
        break
    }
  })

  return descriptor
}

export const buildPatientListId = ({
  patientId,
  practitionerId,
  acquisitionChannel,
}: Pick<ListDescriptor, 'patientId' | 'practitionerId' | 'acquisitionChannel'>): string => {
  const listDescriptor: ListDescriptor = {
    listContentType: ListContentType.PATIENT,
    query: 'all',
  }

  if (practitionerId) {
    listDescriptor.practitionerId = practitionerId
  }

  if (patientId) {
    listDescriptor.patientId = patientId
  }

  if (acquisitionChannel) {
    listDescriptor.acquisitionChannel = acquisitionChannel
  }

  return buildListId(listDescriptor)
}

export const buildPatientListIdPaged = (descriptor: Omit<ListDescriptor, 'listContentType'>): string =>
  buildListId({
    ...descriptor,
    listContentType: ListContentType.PATIENT,
  })

export const buildPatientAlertListId = (patientId: string): string =>
  buildListId({
    listContentType: ListContentType.TASK,
    patientId: [patientId],
    taskStatus: [TaskStatus.TaskPending, TaskStatus.TaskInProgress],
    taskType: BpEscalationTaskTypes,
  })

export const buildBloodPressureMeasurementListId = (patientId: string): string =>
  buildListId({
    listContentType: ListContentType.BLOOD_PRESSURE_MEASUREMENT,
    patientId: [patientId],
  })

export const buildCarePathwayListId = (
  patientId: string,
  carePathwayType: CarePathwayType[],
  carePathwayStatus: CarePathwayStatus[],
): string =>
  buildListId({
    listContentType: ListContentType.CARE_PATHWAY,
    patientId: [patientId],
    carePathwayStatus,
    carePathwayType,
  })

export const buildOpenCarePathwaysListId = (patientId: string, carePathwayType: CarePathwayType[]): string =>
  buildCarePathwayListId(patientId, carePathwayType, [CarePathwayStatus.Created, CarePathwayStatus.InProgress])

export const emptyListId = serializeListIdForRoute({})

// In a paginated view, each page has its own list ID so we can track its
// loading state and get a list of IDs we can compare by identity.
// However, this would mean that whenever we change pages, we need to refetch
// the total count, which would cause the total number of pages to go blank while we load.
// To avoid this, we use a base list ID without offset and limit to store the total count
export const buildUnpagedListId = (listId: string): string => {
  const { offset, ...descriptor } = parseListId(listId)

  return buildListId(descriptor)
}

export const MAX_PATIENTS_PER_PAGE = 50

export const buildDefaultPatientListDescriptor = (loggedInPractitionerId: string): ListDescriptor => ({
  limit: MAX_PATIENTS_PER_PAGE,
  listContentType: ListContentType.PATIENT,
  offset: 0,
  managementStatus: BloodPressureManagementStatus.ACTIVE_MANAGEMENT,
  patientType: [PatientType.MANAGED],
  practitionerId: loggedInPractitionerId ? [loggedInPractitionerId] : undefined,
})

export const defaultSearchListId = buildPatientListIdPaged({
  limit: MAX_PATIENTS_PER_PAGE,
  patientType: [PatientType.MANAGED],
})

export const buildEligibleStudiesListId = (patientId: string): string =>
  buildListId({
    listContentType: ListContentType.STUDY,
    patientId: [patientId],
  })

export const buildEnrolledStudiesListId = (patientId: string): string =>
  buildListId({
    listContentType: ListContentType.STUDY_ENROLLMENT,
    patientId: [patientId],
  })

import { ChannelList } from '@sendbird/uikit-react'
import '../../../vendor/sendbird/index.css'
import { ReactElement, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import clsx from 'clsx'
import type { ChannelListQueries } from 'SendbirdUIKitGlobal'
import { getChatRecordState } from '../../../redux/selectors/chat'
import { isErrored, isLoadingOrDoesNotExist } from '../../../utils/record-state'
import { LoadingIndicator } from '../../ui/loading-indicator'
import { ErrorState } from '../../ui/error-state'
import { useAppSelector } from '../../../redux'
import useWindowSize from '../../../hooks/use-window-size'
import { RightRailReducer } from '../../ui/right-rail'
import useFlag from '../../../hooks/use-flag'
import { Features } from '../../../types/features'
import ChannelPreview from './channel-preview'
import ChannelChat from './channel-chat'
import { useIsRightRailOpen } from './utils'
import ChannelListHeader from './channel-list-header'

export function Chat(): ReactElement {
  const recordState = useAppSelector((state) => getChatRecordState(state))
  const { width } = useWindowSize()
  const shouldShowProfile = width > 1024
  // only start with the right rail panel open if the screen is wide enough
  const [rightRailPanel, togglePanel] = useReducer(RightRailReducer, {
    panel: shouldShowProfile ? 'patient-profile' : null,
  })
  const isRightRailOpen = useIsRightRailOpen(rightRailPanel.panel)
  const [filter, setFilter] = useState('')
  const renderHeader = useCallback(() => <ChannelListHeader onChange={setFilter} />, [])
  const queries = useMemo(
    (): ChannelListQueries => ({
      channelListQuery: {
        includeEmpty: true,
        nicknameContainsFilter: filter,
      },
    }),
    [filter],
  )
  const isSearchEnabled = useFlag(Features.PATIENT_SEARCH)

  useEffect(() => {
    if (!shouldShowProfile) {
      togglePanel({
        type: 'close',
      })
    }
  }, [shouldShowProfile])

  useEffect(() => {
    if (!isSearchEnabled) {
      return
    }

    try {
      document.querySelector('.sendbird-channel-list__header')?.classList.add('hidden')
    } catch (_) {
      // nothing
    }
  }, [isSearchEnabled])

  if (isLoadingOrDoesNotExist(recordState)) {
    return <LoadingIndicator />
  }

  if (isErrored(recordState)) {
    return (
      <ErrorState
        header="Sorry, but we failed to load critical data for the chat functionality."
        subtext="Please refresh and try again. If the problem persists, contact support."
      />
    )
  }

  return (
    <div className="h-full flex">
      <div
        className={clsx('w-[320px] flex-none', {
          'xl:block hidden': isRightRailOpen,
        })}
      >
        <ChannelList renderChannelPreview={ChannelPreview} renderHeader={renderHeader} queries={queries} />
      </div>
      <div className="flex-1 h-full flex overflow-hidden">
        <Switch>
          <Route
            path="/chat/:channelUrl"
            component={() => <ChannelChat rightRailPanel={rightRailPanel} onRightRailPanelClick={togglePanel} />}
          />
        </Switch>
      </div>
    </div>
  )
}

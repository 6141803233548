import { ReactElement, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { useAuth0 } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'
import { SendBirdProvider } from '@sendbird/uikit-react'
import { useApolloClient } from '@apollo/client'
import { setContext } from '@apollo/link-context'
import { loadApp } from '../redux/thunks/load-app'
import { getAppRecordState, getLoggedInPersonId } from '../redux/selectors/app'
import { isErrored, isLoadingOrDoesNotExist } from '../utils/record-state'
import { RecordState } from '../types/record-state'
import { APP_ERROR_STATE } from '../consts/data-testids'
import { IApiClient } from '../api'
import { useAppDispatch, useAppSelector } from '../redux'
import { getToken } from '../redux/selectors/chat'
import { fetchChatToken } from '../redux/thunks/chat'
import usePrefetchUnseenSurveys from '../hooks/use-prefetch-unseen-surveys'
import WindowSizeProvider from '../hooks/use-window-size/provider'
import FeatureProvider from '../hooks/use-flag/provider'
import usePrefetchNewTasks from '../hooks/use-prefetch-new-tasks'
import { usePageViews, useAnalyticsMetadata } from '../hooks/use-analytics'
import { HttpHeaders } from '../api/http'
import { uuid } from '../utils/uuid'
import { Sidebar } from './views/sidebar'
import { LoadingIndicator, LoadingIndicatorSize } from './ui/loading-indicator'
import { ErrorToastContainer } from './ui/error-toast'
import { ErrorState } from './ui/error-state'
import { SuccessToastContainer } from './ui/success-toast'
import ErrorBoundary from './ui/error-boundary'
import Navigation from './navigation'

export function App({ apiClient }: { apiClient: IApiClient }): ReactElement | null {
  const sendBirdAppId = process.env.REACT_APP_SENDBIRD_APP_ID || ''
  const dispatch = useAppDispatch()
  const appRecordState = useAppSelector((state) => getAppRecordState(state))
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, user } = useAuth0()
  const loggedInPersonId = useAppSelector(getLoggedInPersonId)
  const token = useAppSelector((state) => getToken(state))
  const apolloClient = useApolloClient()

  usePrefetchNewTasks()
  usePrefetchUnseenSurveys()
  useAnalyticsMetadata()
  usePageViews()

  useEffect(() => {
    if (isAuthenticated && appRecordState === RecordState.DOES_NOT_EXIST) {
      apiClient.setAccessTokenGetter(getAccessTokenSilently)
      getAccessTokenSilently().then((token) => {
        const decodedJwt = jwtDecode(token) as { 'https://www.rivahealth.com/personId': string }
        const personId = decodedJwt['https://www.rivahealth.com/personId']

        dispatch(fetchChatToken())
        dispatch(loadApp(personId))
      })
    }
  }, [dispatch, isAuthenticated, appRecordState, getAccessTokenSilently, apiClient, user])

  useEffect(() => {
    const authLink = setContext(async (_, { headers, ...rest }) => {
      const token = await getAccessTokenSilently()
      const requestId = uuid()

      return {
        ...rest,
        headers: {
          ...headers,
          [HttpHeaders.AUTHORIZATION]: `Bearer ${token}`,
          [HttpHeaders.X_CSRF_TOKEN]: 'true',
          [HttpHeaders.X_REQUEST_ID]: requestId,
        },
      }
    })

    apolloClient.setLink(authLink.concat(apolloClient.link))
  }, [apolloClient, getAccessTokenSilently])

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname + window.location.search,
      },
    })

    return null
  }

  if (appRecordState !== RecordState.LOADED) {
    return <LoadingIndicator />
  }

  return (
    <ErrorBoundary sectionName="app">
      <FeatureProvider>
        <SendBirdProvider
          appId={sendBirdAppId}
          userId={loggedInPersonId}
          accessToken={token}
          isVoiceMessageEnabled={false}
        >
          <WindowSizeProvider>
            <ToastContainer position="top-center" autoClose={8000} />
            <div className="w-full h-full flex" data-testid="app">
              <Sidebar />
              <div id="page-content" className="content h-full w-full overflow-y-auto">
                {isLoading || isLoadingOrDoesNotExist(appRecordState) ? (
                  <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
                ) : isErrored(appRecordState) ? (
                  <ErrorState
                    header="Sorry, but we failed to load critical data for the app."
                    subtext="Please refresh and try again. If the problem persists, contact support."
                    data-testid={APP_ERROR_STATE}
                  />
                ) : (
                  <Navigation />
                )}
              </div>
              <SuccessToastContainer />
              <ErrorToastContainer />
            </div>
          </WindowSizeProvider>
        </SendBirdProvider>
      </FeatureProvider>
    </ErrorBoundary>
  )
}

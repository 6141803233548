import { ReactElement, useCallback, useMemo } from 'react'
import deepEquals from 'lodash.isequal'
import { useAppSelector } from '../../../redux'
import { getAllPractitionerNamesAndIds } from '../../../redux/selectors/practitioner'
import MultiCombobox from '../multi-combobox'
import { Item } from './types'

interface Props {
  selectedItems: Item[]
  onChange: (selectedItems: Item[]) => void
}

const PractitionerMultiCombobox = ({ onChange, selectedItems }: Props): ReactElement => {
  const practitioners = useAppSelector(getAllPractitionerNamesAndIds, deepEquals)
  const items = useMemo(
    (): Item[] =>
      practitioners.map(
        ({ id, name }): Item => ({
          name,
          type: 'option',
          value: id,
        }),
      ),
    [practitioners],
  )

  const itemToKey = useCallback(({ value }: Item) => value, [])
  const itemMatches = useCallback(
    (inputValue: string, { name }: Item) => name.toLowerCase().includes(inputValue.toLowerCase()),
    [],
  )
  const renderPatientItem = useCallback(({ name }: Item) => <span className="flex-1 truncate">{name}</span>, [])

  return (
    <MultiCombobox
      itemMatches={itemMatches}
      items={items}
      itemToKey={itemToKey}
      label="Practitioners"
      maxHeight="short"
      onChange={onChange}
      placeholder="Select practitioner"
      renderItem={renderPatientItem}
      selectedItems={selectedItems}
    />
  )
}

export default PractitionerMultiCombobox

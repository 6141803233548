import { ChangeEventHandler, ReactElement, useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { Button, ButtonSize, ButtonVariant } from '../../../ui/button'
import { updateNote } from '../../../../redux/thunks/note'
import TextArea from '../../../ui/text-area'
import { getLoggedInPractitionerId } from '../../../../redux/selectors/app'

interface Props {
  autofocus?: boolean
  noteId: string
  initialText: string
  onClose: () => void
}

const PatientNoteEditInput = ({ autofocus, noteId, initialText, onClose }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState(initialText)
  const practitionerId = useAppSelector(getLoggedInPractitionerId)

  const onChange = useCallback<ChangeEventHandler>((event) => {
    setValue((event.target as HTMLTextAreaElement).value)
  }, [])
  const onSubmit = useCallback(() => {
    dispatch(updateNote(noteId, value, practitionerId))
    onClose()
  }, [dispatch, value, practitionerId, noteId, onClose])

  const id = `patient-input-${noteId}`

  return (
    <div className="flex-shrink-0 relative">
      <TextArea
        className="text-sm font-medium pt-2 px-2 pb-[36px] box-border w-full resize-none"
        id={id}
        onChange={onChange}
        placeholder="Write a note..."
        ref={(element) => {
          if (element && autofocus) {
            element.focus()
          }
        }}
        rows={value.split(/[\n\r]/g).length || 1}
        value={value}
      />
      <label htmlFor={id} className="hidden">
        Edit a note
      </label>
      <div className="absolute right-4 bottom-[15px]">
        <Button
          disabled={value.trim() === ''}
          onClick={onClose}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.XXS}
          className="mr-1"
        >
          Cancel
        </Button>
        <Button disabled={value.trim() === ''} onClick={onSubmit} size={ButtonSize.XXS}>
          Save Note
        </Button>
      </div>
    </div>
  )
}

export default PatientNoteEditInput

import clsx from 'clsx'
import { DetailedHTMLProps, HTMLAttributes, MouseEvent, MouseEventHandler, ReactElement } from 'react'
import { ReactComponent as CheckboxCheckedIcon } from '../icons/solid/checkbox-checked.svg'
import { ReactComponent as CheckboxEmptyIcon } from '../icons/outline/checkbox-empty.svg'
import { SvgIcon } from './icon'

interface Props
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'onChange' | 'onClick' | 'className' | 'role' | 'aria-checked' | 'type'
  > {
  checked?: boolean
  highlight?: boolean
  onChange?: (checked: boolean, event: MouseEvent<HTMLButtonElement>) => void
}

const Checkbox = ({ checked, highlight, onChange, ...props }: Props): ReactElement => {
  const handleChange: MouseEventHandler<HTMLButtonElement> = (event) => onChange && onChange(!checked, event)

  return (
    <span className="inline-block h-6 w-6 flex-none">
      <button
        {...props}
        className={clsx('peer inline-block z-10 w-6 h-6 box-border rounded', {
          'text-rivaOffblack-400': !checked,
          'text-rivaPurple-600': checked,
        })}
        onClick={handleChange}
        role="checkbox"
        aria-checked={checked}
        type="button"
      >
        {checked ? <SvgIcon Icon={CheckboxCheckedIcon} /> : <SvgIcon Icon={CheckboxEmptyIcon} />}
      </button>
      <span
        className={clsx(
          'absolute inline-block w-8 h-8 rounded-full top-1 left-1 -translate-x-1/4 -translate-y-1/4',
          'transition-all duration-100 ease-in-out',
          {
            'peer-hover:bg-rivaPurple-100 peer-active:bg-rivaPurple-200 peer-focus:bg-rivaPurple-300':
              checked && highlight,
            'peer-hover:bg-rivaOffblack-100 peer-active:bg-rivaOffblack-200 peer-focus:bg-rivaOffblack-300':
              !checked && highlight,
          },
        )}
        role="presentation"
      />
    </span>
  )
}

export default Checkbox

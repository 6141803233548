/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '#graphql\n      query labResults($patientId: ID!) {\n        patient(patientId: $patientId) {\n          observations(category: laboratory) {\n            code {\n              text\n            }\n            effectiveDateTime\n            id\n            interpretation {\n              text\n            }\n            meta {\n              security {\n                display\n              }\n            }\n            note {\n              text\n            }\n            referenceRange {\n              high {\n                id\n                unit\n                value\n              }\n              low {\n                id\n                unit\n                value\n              }\n              text\n            }\n            status\n            valueQuantity {\n              unit\n              value\n            }\n          }  \n        }\n      }\n    ':
    types.LabResultsDocument,
  '#graphql\n  query allergiesQuery($patientId: ID!) {\n    patient(patientId: $patientId) {\n      id\n      allergyIntolerances {\n        clinicalStatus {\n          text\n        }\n        code {\n          coding {\n            code\n            system\n          }\n          text\n        }\n        id\n        reaction {\n          description\n          id\n          severity\n        }\n      }\n    }\n  }\n':
    types.AllergiesQueryDocument,
  '#graphql\nquery bloodPressureMeasurementsQuery($patientId: ID! $start: String! $end: String!) {\n  patient(patientId: $patientId) {\n    id\n    bloodPressureMeasurements(start: $start end: $end) {\n      id\n      bloodPressureEscalationCategory {\n        type\n        reasons\n      }\n      bloodPressureSetId\n      bloodPressureSet {\n        comment\n        created {\n          at\n          by\n        }\n        id\n        patientId\n        timeZone\n      }\n      comment\n      personId\n      type\n      systolicBloodPressure\n      diastolicBloodPressure\n      beatsPerMinute\n      deviceType\n      escalation {\n        created {\n          at\n          by\n        }\n        events {\n          carePathwayId\n          created {\n            at\n            by\n          }\n          data\n          eventType\n          id\n        }\n        id\n        patientId\n        status\n        type\n      }\n      timeZone\n      created {\n        at\n        by\n      }\n      updated {\n        at\n        by\n      }\n    }\n  }\n}\n':
    types.BloodPressureMeasurementsQueryDocument,
  '#graphql\nquery carePathwaysQuery($patientId: ID! $carePathwayTypes: [CarePathwayType!] $carePathwayStatus: [CarePathwayStatus!]) {\n  carePathways(patientId: $patientId carePathwayTypes: $carePathwayTypes carePathwayStatus: $carePathwayStatus) {\n    created {\n      at\n      by\n    }\n    events {\n      carePathwayId\n      created {\n        at\n        by\n      }\n      data\n      id\n      eventType\n      note\n    }\n    id\n    patientId\n    status\n    tasks {\n      assigneeId\n      description\n      id\n      patientId\n      summary\n      status\n      taskType\n    }\n    type\n  }\n}':
    types.CarePathwaysQueryDocument,
  '#graphql\n  query problemsQuery($patientId: ID!) {\n    patient(patientId: $patientId) {\n      conditions {\n        clinicalStatus {\n          coding {\n            code\n          }\n          text\n        }\n        code {\n          text\n        }\n        id\n        meta {\n          lastUpdated\n        }\n        onsetDateTime\n        severity {\n          text\n        }\n        verificationStatus {\n          coding {\n            code\n          }\n        }\n      }\n    }\n  }\n':
    types.ProblemsQueryDocument,
  '#graphql\nquery ehrRecordtQuery($medicalRecordNumber: String!) {\n  ehrRecord(medicalRecordNumber: $medicalRecordNumber) {\n    birthDate\n    id\n    name {\n      family\n      given\n    }\n  }\n}':
    types.EhrRecordtQueryDocument,
  '#graphql\nquery medicationsPaidQuery($patientId: ID! $medicationType: MedicationType!) {\n  patient(patientId: $patientId) {\n    id\n    medications(medicationType: $medicationType) {\n      lastupdated\n      medications {\n        events {\n          eventdate\n          type\n          userdisplayname\n        }\n        medication\n        medicationentryid\n        unstructuredsig\n      }\n    }\n  }\n}\n':
    types.MedicationsPaidQueryDocument,
  '#graphql\nquery medicationsQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    id\n    medicationRequests {\n      authoredOn\n      dispenseRequest {\n        initialFill {\n          quantity {\n            unit\n            value\n          }\n        }\n      }\n      dosageInstruction {\n        asNeededBoolean\n        doseAndRate {\n          doseQuantity {\n            unit\n            value\n          }\n        }\n        method {\n          coding {\n            display\n          }\n        }\n        route {\n          coding {\n            display\n          }\n        }\n        text\n        timing {\n          repeat {\n            duration\n            durationUnit\n            frequency\n            periodUnit\n            period\n          }\n        }\n      }\n      id\n      medicationReference {\n        display\n      }\n      meta {\n        lastUpdated\n      }\n      status\n    }\n  }\n}\n':
    types.MedicationsQueryDocument,
  '#graphql\nquery patientAthenaProfilePaidQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    athenaProfile {\n      address1\n      assignedAtBirthSex\n      city\n      consentToCall\n      consentToText\n      contactName\n      contactMobilePhone\n      contactRelationship\n      driversLicenseExpirationDate\n      driversLicenseNumber\n      driversLicenseStateId\n      ethnicityCodes\n      language6392Code\n      maritalStatus\n      race\n      sex\n      state\n      zip\n    }\n    id\n  }\n}\n':
    types.PatientAthenaProfilePaidQueryDocument,
  '#graphql\nquery patientStudiesQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    eligibleStudies {\n      id\n      name\n    }\n    enrolledStudies {\n      id\n      name\n    }\n  }\n}':
    types.PatientStudiesQueryDocument,
  '#graphql\nquery taskCarePathwayQuery($taskId: ID!) {\n  task(id: $taskId) {\n    carePathway {\n      created {\n        at\n        by\n      }\n      events {\n        carePathwayId\n        created {\n          at\n          by\n        }\n        data\n        eventType\n        id\n        note\n        surveySubmission {\n          completedAt\n          data\n          events {\n            at\n            by\n            type\n          }\n          id\n          lastSeenAt\n          patientId\n          startedAt\n          seenBy\n          submissionId\n          surveyId\n          url\n        }\n      }\n      id\n      patientId\n      status\n      type\n    }\n  }\n}':
    types.TaskCarePathwayQueryDocument,
}

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\n      query labResults($patientId: ID!) {\n        patient(patientId: $patientId) {\n          observations(category: laboratory) {\n            code {\n              text\n            }\n            effectiveDateTime\n            id\n            interpretation {\n              text\n            }\n            meta {\n              security {\n                display\n              }\n            }\n            note {\n              text\n            }\n            referenceRange {\n              high {\n                id\n                unit\n                value\n              }\n              low {\n                id\n                unit\n                value\n              }\n              text\n            }\n            status\n            valueQuantity {\n              unit\n              value\n            }\n          }  \n        }\n      }\n    ',
): (typeof documents)['#graphql\n      query labResults($patientId: ID!) {\n        patient(patientId: $patientId) {\n          observations(category: laboratory) {\n            code {\n              text\n            }\n            effectiveDateTime\n            id\n            interpretation {\n              text\n            }\n            meta {\n              security {\n                display\n              }\n            }\n            note {\n              text\n            }\n            referenceRange {\n              high {\n                id\n                unit\n                value\n              }\n              low {\n                id\n                unit\n                value\n              }\n              text\n            }\n            status\n            valueQuantity {\n              unit\n              value\n            }\n          }  \n        }\n      }\n    ']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\n  query allergiesQuery($patientId: ID!) {\n    patient(patientId: $patientId) {\n      id\n      allergyIntolerances {\n        clinicalStatus {\n          text\n        }\n        code {\n          coding {\n            code\n            system\n          }\n          text\n        }\n        id\n        reaction {\n          description\n          id\n          severity\n        }\n      }\n    }\n  }\n',
): (typeof documents)['#graphql\n  query allergiesQuery($patientId: ID!) {\n    patient(patientId: $patientId) {\n      id\n      allergyIntolerances {\n        clinicalStatus {\n          text\n        }\n        code {\n          coding {\n            code\n            system\n          }\n          text\n        }\n        id\n        reaction {\n          description\n          id\n          severity\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\nquery bloodPressureMeasurementsQuery($patientId: ID! $start: String! $end: String!) {\n  patient(patientId: $patientId) {\n    id\n    bloodPressureMeasurements(start: $start end: $end) {\n      id\n      bloodPressureEscalationCategory {\n        type\n        reasons\n      }\n      bloodPressureSetId\n      bloodPressureSet {\n        comment\n        created {\n          at\n          by\n        }\n        id\n        patientId\n        timeZone\n      }\n      comment\n      personId\n      type\n      systolicBloodPressure\n      diastolicBloodPressure\n      beatsPerMinute\n      deviceType\n      escalation {\n        created {\n          at\n          by\n        }\n        events {\n          carePathwayId\n          created {\n            at\n            by\n          }\n          data\n          eventType\n          id\n        }\n        id\n        patientId\n        status\n        type\n      }\n      timeZone\n      created {\n        at\n        by\n      }\n      updated {\n        at\n        by\n      }\n    }\n  }\n}\n',
): (typeof documents)['#graphql\nquery bloodPressureMeasurementsQuery($patientId: ID! $start: String! $end: String!) {\n  patient(patientId: $patientId) {\n    id\n    bloodPressureMeasurements(start: $start end: $end) {\n      id\n      bloodPressureEscalationCategory {\n        type\n        reasons\n      }\n      bloodPressureSetId\n      bloodPressureSet {\n        comment\n        created {\n          at\n          by\n        }\n        id\n        patientId\n        timeZone\n      }\n      comment\n      personId\n      type\n      systolicBloodPressure\n      diastolicBloodPressure\n      beatsPerMinute\n      deviceType\n      escalation {\n        created {\n          at\n          by\n        }\n        events {\n          carePathwayId\n          created {\n            at\n            by\n          }\n          data\n          eventType\n          id\n        }\n        id\n        patientId\n        status\n        type\n      }\n      timeZone\n      created {\n        at\n        by\n      }\n      updated {\n        at\n        by\n      }\n    }\n  }\n}\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\nquery carePathwaysQuery($patientId: ID! $carePathwayTypes: [CarePathwayType!] $carePathwayStatus: [CarePathwayStatus!]) {\n  carePathways(patientId: $patientId carePathwayTypes: $carePathwayTypes carePathwayStatus: $carePathwayStatus) {\n    created {\n      at\n      by\n    }\n    events {\n      carePathwayId\n      created {\n        at\n        by\n      }\n      data\n      id\n      eventType\n      note\n    }\n    id\n    patientId\n    status\n    tasks {\n      assigneeId\n      description\n      id\n      patientId\n      summary\n      status\n      taskType\n    }\n    type\n  }\n}',
): (typeof documents)['#graphql\nquery carePathwaysQuery($patientId: ID! $carePathwayTypes: [CarePathwayType!] $carePathwayStatus: [CarePathwayStatus!]) {\n  carePathways(patientId: $patientId carePathwayTypes: $carePathwayTypes carePathwayStatus: $carePathwayStatus) {\n    created {\n      at\n      by\n    }\n    events {\n      carePathwayId\n      created {\n        at\n        by\n      }\n      data\n      id\n      eventType\n      note\n    }\n    id\n    patientId\n    status\n    tasks {\n      assigneeId\n      description\n      id\n      patientId\n      summary\n      status\n      taskType\n    }\n    type\n  }\n}']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\n  query problemsQuery($patientId: ID!) {\n    patient(patientId: $patientId) {\n      conditions {\n        clinicalStatus {\n          coding {\n            code\n          }\n          text\n        }\n        code {\n          text\n        }\n        id\n        meta {\n          lastUpdated\n        }\n        onsetDateTime\n        severity {\n          text\n        }\n        verificationStatus {\n          coding {\n            code\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['#graphql\n  query problemsQuery($patientId: ID!) {\n    patient(patientId: $patientId) {\n      conditions {\n        clinicalStatus {\n          coding {\n            code\n          }\n          text\n        }\n        code {\n          text\n        }\n        id\n        meta {\n          lastUpdated\n        }\n        onsetDateTime\n        severity {\n          text\n        }\n        verificationStatus {\n          coding {\n            code\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\nquery ehrRecordtQuery($medicalRecordNumber: String!) {\n  ehrRecord(medicalRecordNumber: $medicalRecordNumber) {\n    birthDate\n    id\n    name {\n      family\n      given\n    }\n  }\n}',
): (typeof documents)['#graphql\nquery ehrRecordtQuery($medicalRecordNumber: String!) {\n  ehrRecord(medicalRecordNumber: $medicalRecordNumber) {\n    birthDate\n    id\n    name {\n      family\n      given\n    }\n  }\n}']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\nquery medicationsPaidQuery($patientId: ID! $medicationType: MedicationType!) {\n  patient(patientId: $patientId) {\n    id\n    medications(medicationType: $medicationType) {\n      lastupdated\n      medications {\n        events {\n          eventdate\n          type\n          userdisplayname\n        }\n        medication\n        medicationentryid\n        unstructuredsig\n      }\n    }\n  }\n}\n',
): (typeof documents)['#graphql\nquery medicationsPaidQuery($patientId: ID! $medicationType: MedicationType!) {\n  patient(patientId: $patientId) {\n    id\n    medications(medicationType: $medicationType) {\n      lastupdated\n      medications {\n        events {\n          eventdate\n          type\n          userdisplayname\n        }\n        medication\n        medicationentryid\n        unstructuredsig\n      }\n    }\n  }\n}\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\nquery medicationsQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    id\n    medicationRequests {\n      authoredOn\n      dispenseRequest {\n        initialFill {\n          quantity {\n            unit\n            value\n          }\n        }\n      }\n      dosageInstruction {\n        asNeededBoolean\n        doseAndRate {\n          doseQuantity {\n            unit\n            value\n          }\n        }\n        method {\n          coding {\n            display\n          }\n        }\n        route {\n          coding {\n            display\n          }\n        }\n        text\n        timing {\n          repeat {\n            duration\n            durationUnit\n            frequency\n            periodUnit\n            period\n          }\n        }\n      }\n      id\n      medicationReference {\n        display\n      }\n      meta {\n        lastUpdated\n      }\n      status\n    }\n  }\n}\n',
): (typeof documents)['#graphql\nquery medicationsQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    id\n    medicationRequests {\n      authoredOn\n      dispenseRequest {\n        initialFill {\n          quantity {\n            unit\n            value\n          }\n        }\n      }\n      dosageInstruction {\n        asNeededBoolean\n        doseAndRate {\n          doseQuantity {\n            unit\n            value\n          }\n        }\n        method {\n          coding {\n            display\n          }\n        }\n        route {\n          coding {\n            display\n          }\n        }\n        text\n        timing {\n          repeat {\n            duration\n            durationUnit\n            frequency\n            periodUnit\n            period\n          }\n        }\n      }\n      id\n      medicationReference {\n        display\n      }\n      meta {\n        lastUpdated\n      }\n      status\n    }\n  }\n}\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\nquery patientAthenaProfilePaidQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    athenaProfile {\n      address1\n      assignedAtBirthSex\n      city\n      consentToCall\n      consentToText\n      contactName\n      contactMobilePhone\n      contactRelationship\n      driversLicenseExpirationDate\n      driversLicenseNumber\n      driversLicenseStateId\n      ethnicityCodes\n      language6392Code\n      maritalStatus\n      race\n      sex\n      state\n      zip\n    }\n    id\n  }\n}\n',
): (typeof documents)['#graphql\nquery patientAthenaProfilePaidQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    athenaProfile {\n      address1\n      assignedAtBirthSex\n      city\n      consentToCall\n      consentToText\n      contactName\n      contactMobilePhone\n      contactRelationship\n      driversLicenseExpirationDate\n      driversLicenseNumber\n      driversLicenseStateId\n      ethnicityCodes\n      language6392Code\n      maritalStatus\n      race\n      sex\n      state\n      zip\n    }\n    id\n  }\n}\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\nquery patientStudiesQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    eligibleStudies {\n      id\n      name\n    }\n    enrolledStudies {\n      id\n      name\n    }\n  }\n}',
): (typeof documents)['#graphql\nquery patientStudiesQuery($patientId: ID!) {\n  patient(patientId: $patientId) {\n    eligibleStudies {\n      id\n      name\n    }\n    enrolledStudies {\n      id\n      name\n    }\n  }\n}']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '#graphql\nquery taskCarePathwayQuery($taskId: ID!) {\n  task(id: $taskId) {\n    carePathway {\n      created {\n        at\n        by\n      }\n      events {\n        carePathwayId\n        created {\n          at\n          by\n        }\n        data\n        eventType\n        id\n        note\n        surveySubmission {\n          completedAt\n          data\n          events {\n            at\n            by\n            type\n          }\n          id\n          lastSeenAt\n          patientId\n          startedAt\n          seenBy\n          submissionId\n          surveyId\n          url\n        }\n      }\n      id\n      patientId\n      status\n      type\n    }\n  }\n}',
): (typeof documents)['#graphql\nquery taskCarePathwayQuery($taskId: ID!) {\n  task(id: $taskId) {\n    carePathway {\n      created {\n        at\n        by\n      }\n      events {\n        carePathwayId\n        created {\n          at\n          by\n        }\n        data\n        eventType\n        id\n        note\n        surveySubmission {\n          completedAt\n          data\n          events {\n            at\n            by\n            type\n          }\n          id\n          lastSeenAt\n          patientId\n          startedAt\n          seenBy\n          submissionId\n          surveyId\n          url\n        }\n      }\n      id\n      patientId\n      status\n      type\n    }\n  }\n}']

export function gql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<
  infer TType,
  any
>
  ? TType
  : never

import clsx from 'clsx'
import { forwardRef, DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  children: ReactNode
}

export default forwardRef<HTMLLIElement, Props>(function ListItem({ children, className, ...props }, ref) {
  return (
    <li
      {...props}
      className={clsx('px-3 mb-1 cursor-pointer text-sm font-medium box-border flex items-center truncate', className)}
      ref={ref}
    >
      {children}
    </li>
  )
})

import clsx from 'clsx'
import { ReactElement, useCallback, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { generatePath, useHistory } from 'react-router-dom'
import { useAppSelector } from '../../redux'
import { getListTotalCount } from '../../redux/selectors/lists'
import { buildUnpagedListId, parseListId, serializeListIdForRoute } from '../../utils/lists'
import { ReactComponent as ChevronUpIcon } from '../icons/solid/chevron-up.svg'
import useGetRefreshRoute from '../pages/patients/panel/patient-list-header/use-get-refresh-route'
import { track } from '../../i13n'
import { Button, ButtonVariant } from './button'
import { SvgIcon } from './icon'
import { Option, Select } from './select'

interface Props {
  listId: string
}

const Paging = ({ listId }: Props): ReactElement | null => {
  const refreshRoute = useGetRefreshRoute()
  // Each page has its own list ID so we can track its loading state and get
  // a list of IDs we can compare by identity. However, this would mean that
  // whenever we change pages, we need to refetch the total count, which would
  // cause the total number of pages to go blank while we load.
  // To avoid this, we use a base list ID without offset and limit to store
  // the total count
  const unpagedListId = useMemo(() => buildUnpagedListId(listId), [listId])
  const { currentPage, limit, prevListId, nextListId } = useMemo(() => {
    const { offset = 0, limit = 0, ...descriptor } = parseListId(listId)

    const prevListId = serializeListIdForRoute({
      ...descriptor,
      limit,
      offset: offset - limit,
    })
    const nextListId = serializeListIdForRoute({
      ...descriptor,
      limit,
      offset: offset + limit,
    })
    const currentPage = Math.floor(offset / limit)

    return {
      currentPage,
      limit,
      prevListId,
      nextListId,
    }
  }, [listId])
  const totalCount = useAppSelector((state) => getListTotalCount(state, unpagedListId) ?? 0)
  const history = useHistory()
  const { inView, ref } = useInView({
    threshold: 1,
  })
  const navigatePrev = useCallback(() => {
    track('Previous Page Clicked')

    history.push(
      generatePath(refreshRoute, {
        serializedListId: prevListId,
      }),
    )
  }, [history, prevListId, refreshRoute])
  const navigateNext = useCallback(() => {
    track('Next Page Clicked')

    history.push(
      generatePath(refreshRoute, {
        serializedListId: nextListId,
      }),
    )
  }, [history, nextListId, refreshRoute])
  const totalPages = Math.floor(totalCount / limit) + Math.min(totalCount % limit, 1)
  const pages = useMemo(() => Array.from({ length: totalPages }, (v, k) => k), [totalPages])

  const onSelectClick = useCallback(() => {
    track('Page Drop Down Clicked')
  }, [])
  const onChange = useCallback(
    (page?: number) => {
      const descriptor = parseListId(listId)
      const navigateListId = serializeListIdForRoute({
        ...descriptor,
        offset: (page ?? 0) * limit,
      })

      track('Page Drop Down Selected', {
        'Page Number': page,
      })

      history.push(
        generatePath(refreshRoute, {
          serializedListId: navigateListId,
        }),
      )
    },
    [history, limit, listId, refreshRoute],
  )

  if (totalPages <= 1) {
    return null
  }

  const prevDisabled = currentPage === 0
  const nextDisabled = currentPage + 1 === totalPages

  return (
    <div className="px-7 py-3 h-16" ref={ref}>
      <div
        className={clsx(
          'pl-[108px] xl:pl-[268px] pr-7 py-3 border-t border-rivaOffblack-200 fixed bottom-0 left-0 right-0 bg-white flex justify-center  ',
          {
            'shadow-xl': !inView,
          },
        )}
      >
        <Button
          className="!px-3 mr-3"
          disabled={prevDisabled}
          onClick={navigatePrev}
          variant={prevDisabled ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY}
        >
          <SvgIcon className="-rotate-90" Icon={ChevronUpIcon} />
        </Button>
        <Select
          className="border-rivaOffblack-900"
          hideRadio
          onClick={onSelectClick}
          onChange={onChange}
          placement="top-start"
          value={currentPage}
        >
          {pages.map((page) => (
            <Option key={page} value={page}>
              {String(page + 1)}
            </Option>
          ))}
        </Select>
        <span className="text-sm font-medium leading-10 mx-3 order-3">of {totalPages}</span>
        <Button
          className="!px-3 order-4"
          disabled={nextDisabled}
          onClick={navigateNext}
          variant={nextDisabled ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY}
        >
          <SvgIcon className="rotate-90" Icon={ChevronUpIcon} />
        </Button>
      </div>
    </div>
  )
}

export default Paging

import Ajv from 'ajv'
import { Task } from '../../../types/task'
import * as http from '../../http'
import { taskWithUnknownEventsSchema } from '../../schema/task'
import { createApiLogger } from '../../../logger'
import { uuid } from '../../../utils/uuid'
import { parseTask } from './utils'

const ajv = new Ajv()

const validate = ajv.compile(taskWithUnknownEventsSchema)

interface Options {
  includeComments?: boolean
  includeEvents?: boolean
}

const getTaskById = async (
  taskId: string,
  { includeComments, includeEvents }: Options,
  accessToken: string,
): Promise<Task> => {
  const params = new URLSearchParams()

  if (includeComments) {
    params.append('includeComments', '1')
  }

  if (includeEvents) {
    params.append('includeEvents', '1')
  }

  const requestId = uuid()
  const logger = createApiLogger('GET', '/v1/task/:taskId', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, statusCode } = await http.get(`/v1/task/${encodeURIComponent(taskId)}?${params}`, {
      accessToken,
      requestId,
    })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    const result = parseTask(body)

    logger.logSuccess({
      statusCode,
    })

    return result
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default getTaskById

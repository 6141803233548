import { DateTime } from 'luxon'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import deepEquals from 'lodash.isequal'
import { useQuery } from '@apollo/client'
import { useAppSelector } from '../../../../redux'
import {
  getPatientAcquisitionChannel,
  getPatientBloodPressureGoal,
  getPatientCareTeam,
  getPatientCommunicationPreferences,
  getPatientDateOfBirth,
  getPatientDeviceType,
  getPatientEmail,
  getPatientFirstName,
  getPatientLastName,
  getPatientPersonId,
  getPatientPhoneNumber,
  getPatientPreferredName,
  getPatientPronouns,
  getPatientReferringProvider,
  getPatientReminderTimes,
  getPatientTimeZone,
  getPatientType,
  getPatientWirelessCuffId,
} from '../../../../redux/selectors/patient'
import {
  AcquisitionChannel,
  AcquisitionChannelNames,
  EthnicityCodeToName,
  LanguageCodeToName,
  MaritalStatus,
  MaritalStatusToText,
  RaceCodeToName,
} from '../../../../types/patient'
import { SexCode, SexCodeToName, StateAbbreviations, StateNames, formatPhoneNumber } from '../../../../utils/l10n'
import { getDobAndAge } from '../utils'
import { getProviderById } from '../../../../redux/selectors/provider'
import { getIsDebugProfileEnabled } from '../../../../redux/selectors/ui'
import patientAthenaProfilePaidQuery from '../../../../schemas/patient-athena-profile-paid-query'
import ErrorState from '../error-state'
import { LoadingIndicator } from '../../../ui/loading-indicator'
import useHasAthenaProfile from '../../../../hooks/use-has-athena-profile'
import DetailsItem from './details-item'
import DetailsRow from './details-row'
import DetailsSection from './details-section'

interface Props {
  patientId: string
}

const PatientDetails = ({ patientId }: Props): ReactElement => {
  const isDebugProfileEnabled = useAppSelector(getIsDebugProfileEnabled)
  const personId = useAppSelector((state) => getPatientPersonId(state, patientId))
  const firstName = useAppSelector((state) => getPatientFirstName(state, patientId)) || '-'
  const lastName = useAppSelector((state) => getPatientLastName(state, patientId)) || '-'
  const preferredName = useAppSelector((state) => getPatientPreferredName(state, patientId)) || '-'
  const pronouns = useAppSelector((state) => getPatientPronouns(state, patientId)) || '-'
  const dateOfBirth = useAppSelector((state) => getPatientDateOfBirth(state, patientId))
  const phoneNumber = useAppSelector((state) => getPatientPhoneNumber(state, patientId)) || '-'
  const email = useAppSelector((state) => getPatientEmail(state, patientId)) || '-'
  const deviceType = useAppSelector((state) => getPatientDeviceType(state, patientId)) || '-'
  const acquisitionChannel = useAppSelector((state) => getPatientAcquisitionChannel(state, patientId)) || ''
  const referringProviderId = useAppSelector((state) => getPatientReferringProvider(state, patientId)) || '-'
  const referringProvider = useAppSelector((state) => getProviderById(state, referringProviderId))
  const reminderTimes = useAppSelector((state) => getPatientReminderTimes(state, patientId))
  const goalBp = useAppSelector((state) => getPatientBloodPressureGoal(state, patientId))
  const careTeam = useAppSelector((state) => getPatientCareTeam(state, patientId), deepEquals)
  const wirelessCuffId = useAppSelector((state) => getPatientWirelessCuffId(state, patientId))
  const communicationPreferencesDays = useAppSelector(
    (state) => getPatientCommunicationPreferences(state, patientId).dayOfWeek,
  )
  const timeZone = useAppSelector((state) => getPatientTimeZone(state, patientId))
  const patientType = useAppSelector((state) => getPatientType(state, patientId))
  const formatLocalTime = useCallback(
    () =>
      DateTime.local({
        zone: timeZone,
      }).toLocaleString({
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'long',
      }),
    [timeZone],
  )
  const [localTime, setLocalTime] = useState(formatLocalTime())
  const hasAthenaProfile = useHasAthenaProfile(acquisitionChannel, patientType)
  const { data, error, loading } = useQuery(patientAthenaProfilePaidQuery, {
    context: {
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
    skip: !hasAthenaProfile,
    variables: {
      patientId,
    },
  })
  const { athenaProfile } = data?.patient ?? {}

  useEffect(() => {
    if (!timeZone) {
      return
    }

    const interval = setInterval(() => {
      setLocalTime(formatLocalTime())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [formatLocalTime, timeZone])

  if (!data && error) {
    return (
      <div className="bg-white flex-1 pb-7 tabular-nums">
        <ErrorState />
      </div>
    )
  }

  if (!data && loading) {
    return (
      <div className="bg-white flex-1 pb-7 tabular-nums">
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <div className="bg-white flex-1 pb-7 tabular-nums">
      <header className="pt-7 pb-2 px-7">
        <h3 className="capitalize text-lg font-semibold text-rivaOffblack-900">Patient details</h3>
      </header>
      {isDebugProfileEnabled ? (
        <DetailsSection title="Debug information">
          <DetailsRow>
            <DetailsItem title="Person ID">{personId}</DetailsItem>
          </DetailsRow>
          <DetailsRow>
            <DetailsItem title="Patient ID">{patientId}</DetailsItem>
          </DetailsRow>
        </DetailsSection>
      ) : null}
      <DetailsSection title="Local time">
        <DetailsRow>
          <DetailsItem title="Time">{timeZone ? localTime : 'Unknown'}</DetailsItem>
        </DetailsRow>
      </DetailsSection>
      <DetailsSection title="Acquisition channel">
        <DetailsRow>
          <DetailsItem title="Source">
            {(AcquisitionChannelNames[acquisitionChannel as AcquisitionChannel] ?? acquisitionChannel) || '-'}
          </DetailsItem>
          {referringProvider ? (
            <DetailsItem title="Referring Provider">{`${referringProvider.firstName} ${referringProvider.lastName}, ${referringProvider.providerType}`}</DetailsItem>
          ) : null}
        </DetailsRow>
      </DetailsSection>
      <DetailsSection title="Care team">
        <DetailsRow>
          <DetailsItem title="Coach">{careTeam.coach.name}</DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem title="Pharmacist">{careTeam.pharmacist.name}</DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem title="Prescriber">{careTeam.prescriber.name}</DetailsItem>
        </DetailsRow>
      </DetailsSection>
      <DetailsSection title="Identification">
        <DetailsRow>
          <DetailsItem title="First Name">{firstName}</DetailsItem>
          <DetailsItem title="Last Name">{lastName}</DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem title="Preferred Name">{preferredName}</DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem title="Pronouns">{pronouns}</DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem title="Date of birth">{getDobAndAge(dateOfBirth, timeZone)}</DetailsItem>
        </DetailsRow>
        {hasAthenaProfile ? (
          <>
            <DetailsRow>
              <DetailsItem title="Driver's license number">{athenaProfile?.driversLicenseNumber || '-'}</DetailsItem>
              <DetailsItem title="Expiration date">{athenaProfile?.driversLicenseExpirationDate || '-'}</DetailsItem>
              <DetailsItem title="State">
                {(StateNames[(athenaProfile?.driversLicenseStateId ?? '') as keyof typeof StateAbbreviations] ??
                  athenaProfile?.driversLicenseStateId) ||
                  '-'}
              </DetailsItem>
            </DetailsRow>
          </>
        ) : null}
      </DetailsSection>
      <DetailsSection title="Personal contact information">
        <DetailsRow>
          <DetailsItem title="Email">{email}</DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem title="Phone Number">{formatPhoneNumber(phoneNumber)}</DetailsItem>
        </DetailsRow>
        {hasAthenaProfile ? (
          <>
            <DetailsRow>
              <DetailsItem title="Address">{athenaProfile?.address1 || '-'}</DetailsItem>
            </DetailsRow>
            <DetailsRow>
              <DetailsItem title="City">{athenaProfile?.city || '-'}</DetailsItem>
            </DetailsRow>
            <DetailsRow>
              <DetailsItem title="State">
                {(StateNames[(athenaProfile?.state ?? '') as keyof typeof StateAbbreviations] ??
                  athenaProfile?.state) ||
                  '-'}
              </DetailsItem>
              <DetailsItem title="Zip code">{athenaProfile?.zip || '-'}</DetailsItem>
            </DetailsRow>
          </>
        ) : null}
      </DetailsSection>
      <DetailsSection title="Clinical goals">
        <DetailsRow>
          <DetailsItem title="Goal BP">
            {goalBp ? `< ${goalBp.systolicBloodPressure}/${goalBp.diastolicBloodPressure}` : '-'}
          </DetailsItem>
        </DetailsRow>
      </DetailsSection>
      {hasAthenaProfile ? (
        <DetailsSection title="Emergency contact information">
          <DetailsRow>
            <DetailsItem title="Name">{athenaProfile?.contactName || '-'}</DetailsItem>
            <DetailsItem title="Phone number">
              {athenaProfile?.contactMobilePhone ? formatPhoneNumber('+1' + athenaProfile.contactMobilePhone) : '-'}
            </DetailsItem>
            <DetailsItem title="Relationship to patient">
              <span className="capitalize">{athenaProfile?.contactRelationship?.toLowerCase() || '-'}</span>
            </DetailsItem>
          </DetailsRow>
        </DetailsSection>
      ) : null}
      <DetailsSection title="Patient communication preferences">
        {hasAthenaProfile ? (
          <>
            <DetailsRow>
              <DetailsItem title="Consent to call">
                {athenaProfile?.consentToCall === true ? 'Yes' : athenaProfile?.consentToCall === false ? 'No' : '-'}
              </DetailsItem>
            </DetailsRow>
            <DetailsRow>
              <DetailsItem title="Consent to text">
                {athenaProfile?.consentToText === true ? 'Yes' : athenaProfile?.consentToText === false ? 'No' : '-'}
              </DetailsItem>
            </DetailsRow>
          </>
        ) : null}
        <DetailsRow>
          <DetailsItem title="Educational messages frequency">{communicationPreferencesDays || '-'}</DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem title="AM reminder time">
            {reminderTimes.am.hour < 0 || reminderTimes.am.minute < 0
              ? 'No reminder set'
              : DateTime.now().set(reminderTimes.am).toLocaleString({
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                })}
          </DetailsItem>
          <DetailsItem title="PM reminder time">
            {reminderTimes.am.hour < 0 || reminderTimes.am.minute < 0
              ? 'No reminder set'
              : DateTime.now().set(reminderTimes.pm).toLocaleString({
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                })}
          </DetailsItem>
        </DetailsRow>
      </DetailsSection>
      {hasAthenaProfile ? (
        <DetailsSection title="Demographic information">
          <DetailsRow>
            <DetailsItem title="Legal sex">
              {(SexCodeToName[athenaProfile?.sex as SexCode] ?? athenaProfile?.sex) || '-'}
            </DetailsItem>
            <DetailsItem title="Sex assigned at birth">
              {(SexCodeToName[athenaProfile?.assignedAtBirthSex as SexCode] ?? athenaProfile?.assignedAtBirthSex) ||
                '-'}
            </DetailsItem>
          </DetailsRow>
          <DetailsRow>
            <DetailsItem title="Marital status">
              {MaritalStatusToText[(athenaProfile?.maritalStatus ?? '') as MaritalStatus] || '-'}
            </DetailsItem>
          </DetailsRow>
          <DetailsRow>
            <DetailsItem title="Language">
              {LanguageCodeToName[(athenaProfile?.language6392Code ?? '') as keyof typeof LanguageCodeToName] || '-'}
            </DetailsItem>
          </DetailsRow>
          <DetailsRow>
            <DetailsItem title="Race">
              {RaceCodeToName[(athenaProfile?.race ?? '') as keyof typeof RaceCodeToName] || '-'}
            </DetailsItem>
          </DetailsRow>
          <DetailsRow>
            <DetailsItem title="Ethnicity">
              {EthnicityCodeToName[(athenaProfile?.ethnicityCodes ?? '') as keyof typeof EthnicityCodeToName] || '-'}
            </DetailsItem>
          </DetailsRow>
        </DetailsSection>
      ) : null}
      <DetailsSection title="Device information">
        <DetailsRow>
          <DetailsItem title="Cuff Serial Number">{wirelessCuffId || '-'}</DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem title="Phone type">{deviceType}</DetailsItem>
        </DetailsRow>
      </DetailsSection>
    </div>
  )
}

export default PatientDetails

import Ajv from 'ajv'
import { Task, TaskEvent, TaskWithUnknownEvents } from '../../../types/task'
import { taskEventSchema } from '../../schema/task-event'

const ajv = new Ajv()
const validateTaskEvent = ajv.compile(taskEventSchema)

export const parseTask = ({ comments, events, ...task }: TaskWithUnknownEvents): Task => {
  const newEvents: TaskEvent[] = []

  events?.forEach((event) => {
    if (!validateTaskEvent(event)) {
      validateTaskEvent.errors?.forEach((err) => {
        // This is too valuable not to log right now
        // eslint-disable-next-line no-console
        console.warn(err, event)
      })

      return
    }

    newEvents.push(event)
  })

  return {
    ...task,
    events: newEvents,
    notes: comments,
  }
}

import { ReactElement, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import useTasksApi from '../../../api/hooks/use-tasks-api'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { getListItemIds, getListRecordState, getListTotalCount } from '../../../redux/selectors/lists'
import { fetchTasks } from '../../../redux/thunks/tasks'
import { RecordState } from '../../../types/record-state'
import TaskLoadingItem from './task-loading-item'

interface Props {
  listId: string
}

const TaskListLoadingIndicator = ({ listId }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const itemCount = useAppSelector((state) => getListItemIds(state, listId).length)
  const totalCount = useAppSelector((state) => getListTotalCount(state, listId) ?? 0)
  const recordState = useAppSelector((state) => getListRecordState(state, listId))
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && itemCount < totalCount) {
      dispatch(
        fetchTasks(
          {
            listId,
            offset: itemCount,
          },
          tasksApi,
        ),
      )
    }
  }, [dispatch, inView, itemCount, listId, tasksApi, totalCount])

  return <div ref={ref}>{recordState === RecordState.LOADING ? <TaskLoadingItem /> : null}</div>
}

export default TaskListLoadingIndicator

import { ReactElement, useCallback, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { Button, ButtonSize, ButtonVariant } from '../../../ui/button'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as CheckboxCheckedIcon } from '../../../icons/solid/checkbox-checked.svg'
import { parseListId, serializeListIdForRoute } from '../../../../utils/lists'
import { PatientType } from '../../../../types/patient'
import { Routes } from '../../../../types/route'

interface Props {
  listId: string
}

const VIPFilter = ({ listId }: Props): ReactElement => {
  const history = useHistory()
  const pressed = useMemo(() => {
    const { patientType } = parseListId(listId)

    return patientType?.length === 1 && patientType[0] === PatientType.VIP
  }, [listId])

  const onClick = useCallback(() => {
    history.push(
      generatePath(Routes.SEARCH, {
        serializedListId: serializeListIdForRoute({
          ...parseListId(listId),
          offset: 0,
          patientType: [pressed ? PatientType.MANAGED : PatientType.VIP],
        }),
      }),
    )
  }, [history, listId, pressed])

  return (
    <Button
      className="ml-2 pl-1"
      onClick={onClick}
      size={ButtonSize.XS}
      type="button"
      variant={ButtonVariant.SECONDARY}
    >
      {pressed ? (
        <SvgIcon className="text-rivaPurple-500" size={IconSize.X_SMALL} Icon={CheckboxCheckedIcon} />
      ) : (
        <span className="w-3 h-3 inline-block m-[2px] border border-solid border-rivaOffblack-900 rounded-sm" />
      )}
      <span className="ml-1">Show only VIPs</span>
    </Button>
  )
}

export default VIPFilter

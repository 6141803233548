import { useEffect, useState } from 'react'

type ImageState = 'loading' | 'loaded' | 'error'

const cache = new Map<string, ImageState>()

const useImageState = (src: string): ImageState => {
  const [state, setImageState] = useState(!src ? 'error' : cache.get(src) ?? 'loading')

  useEffect(() => {
    if (!src || cache.has(src)) {
      return
    }

    cache.set(src, 'loading')

    const img = new Image()

    img.onload = () => {
      cache.set(src, 'loaded')
      const event = new CustomEvent('use-image-state-refresh', {
        detail: src,
      })

      document.body.dispatchEvent(event)
    }

    img.onerror = () => {
      cache.set('src', 'error')
      const event = new CustomEvent('use-image-state-refresh', {
        detail: src,
      })

      document.body.dispatchEvent(event)
    }

    img.src = src
  }, [src])

  useEffect(() => {
    const onRefresh: EventListener = (event) => {
      if ((event as CustomEvent).detail === src) {
        const cachedState = cache.get(src)

        if (cachedState && cachedState !== state) {
          setImageState(cachedState)
        }
      }
    }

    document.body.addEventListener('use-image-state-refresh', onRefresh)

    return () => {
      document.body.removeEventListener('use-image-state-refresh', onRefresh)
    }
  }, [src, state])

  return state
}

export default useImageState

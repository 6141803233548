import { MouseEventHandler, ReactElement, useCallback } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import clsx from 'clsx'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { ReactComponent as RivaLogo } from '../../icons/riva-logo-red-and-white.svg'
import { ReactComponent as IconSignOut } from '../../icons/outline/sign-out.svg'
import useAppKeyboardShortcuts from '../../../hooks/use-app-keyboard-shortcuts'
import { track } from '../../../i13n'
import useFlag from '../../../hooks/use-flag'
import { Features } from '../../../types/features'
import Navigation from './navigation'
import ChatNavigationItem from './chat-navigation-item'
import SurveysNavigationItem from './surveys-navigation-item'
import NavigationItem from './navigation-item'
import TasksNavigationItem from './tasks-navigation-item'
import PatientsNavigationItem from './patients-navigation-item'
import PatientsNavigationItemV2 from './patients-navigation-item-v2'
import SearchNavigationItem from './search-navigation-item'

export function Sidebar(): ReactElement {
  const { logout } = useAuth0()
  const isChatActive = !!useRouteMatch('/chat')
  const isPaginationEnabled = useFlag(Features.PATIENT_PAGES)
  const patientFiltersV2 = useFlag(Features.PATIENT_FILTERS_V2)

  useAppKeyboardShortcuts()

  const onLogOut = useCallback<MouseEventHandler>(
    (event) => {
      event.preventDefault()

      track('Sign Out Clicked')

      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    },
    [logout],
  )

  // Note by @juan: this is a bit ugly (aka, it's spaghetti HTML/CSS)
  // There are hardcoded values for all the widths and positions of things
  // like the logo. Feel free to refactor it to support changing the width
  // of the sidebar more easily.
  // If you try to refactor it and you get stuck, yell at me and I'll help.
  // All I have for you is this: https://youtu.be/hiKuxfcSrEU?t=22
  return (
    <div
      className={clsx('flex-none relative w-20', {
        'xl:w-60': !isChatActive,
        '2xl:w-60': isChatActive,
      })}
    >
      <div
        className={clsx(
          'group w-20 hover:w-60 focus-within:w-60 transition-all duration-[275ms] motion-reduce:duration-[0s] ease-out overflow-hidden absolute top-0 bottom-0 left-0 bg-rivaPurple-900 px-3 py-7 z-20 shadow-sm',
          {
            'xl:w-60': !isChatActive,
            '2xl:w-60': isChatActive,
          },
        )}
      >
        <div className="overflow-hidden h-full">
          <div className="h-full flex flex-col">
            <div
              className={clsx(
                'w-[22px] translate-x-[18px] group-hover:translate-x-[71px] group-hover:w-[74px] group-focus-within:translate-x-[71px] group-focus-within:w-[74px] transition-all duration-[275ms] motion-reduce:duration-[0s] ease-out flex items-center overflow-hidden',
                {
                  'xl:translate-x-[71px] xl:w-[74px]': !isChatActive,
                  '2xl:translate-x-[71px] 2xl:w-[74px]': isChatActive,
                },
              )}
            >
              <div
                className={clsx(
                  'w-[74px] h-[84px] -translate-x-[32px] group-hover:-translate-x-0 group-focus-within:-translate-x-0 transform-gpu transition-transform duration-[275ms] motion-reduce:duration-[0s] ease-out flex items-center relative',
                  {
                    'xl:-translate-x-0': !isChatActive,
                    '2xl:-translate-x-0': isChatActive,
                  },
                )}
              >
                <div
                  className={clsx(
                    'bg-rivaPurple-900 absolute top-0 left-0 bottom-0 w-[30px] opacity-100 group-hover:opacity-0 group-focus-within:opacity-0 transition-opacity duration-[275ms] motion-reduce:duration-[0s] ease-out',
                    {
                      'xl:opacity-0': !isChatActive,
                      '2xl:opacity-0': isChatActive,
                    },
                  )}
                ></div>
                <NavLink className="inline-block w-[74px] h-10" to={isPaginationEnabled ? '/patients' : '/dashboard'}>
                  <RivaLogo />
                </NavLink>
                <div
                  className={clsx(
                    'bg-rivaPurple-900 absolute top-0 right-0 transform rotate-12 bottom-0 w-[20px] opacity-100 group-hover:opacity-0 group-focus-within:opacity-0 transition-opacity duration-[275ms] motion-reduce:duration-[0s] ease-out',
                    {
                      'xl:opacity-0': !isChatActive,
                      '2xl:opacity-0': isChatActive,
                    },
                  )}
                ></div>
              </div>
            </div>
            <Navigation>
              <SearchNavigationItem />
              {patientFiltersV2 ? <PatientsNavigationItemV2 /> : <PatientsNavigationItem />}
              <ChatNavigationItem />
              <SurveysNavigationItem />
              <TasksNavigationItem />
              <li className="mt-auto">
                <NavigationItem onClick={onLogOut} to="/logout" label="Sign Out" iconLeft={<IconSignOut />} />
              </li>
            </Navigation>
          </div>
        </div>
      </div>
    </div>
  )
}

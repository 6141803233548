import { ReactElement, ReactNode } from 'react'

export interface Props {
  children: ReactNode
  invalid?: boolean
  label: string
}

const RadioLabel = ({ children, label }: Props): ReactElement => {
  return (
    <label className="flex items-center py-2 px-4 cursor-pointer hover:bg-rivaPurple-100 focus-within:bg-rivaPurple-100 radio-label capitalize">
      <span className="flex-1 text-sm font-normal text-rivaOffblack-900">{label}</span>
      {children}
    </label>
  )
}

export default RadioLabel

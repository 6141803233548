import { useCallback } from 'react'
import deepEquals from 'lodash.isequal'
import { createSelector } from 'reselect'
import { useAppSelector } from '..'
import { Task, TaskOption, TaskStatus } from '../../types/task'
import { RootState } from '../reducers'
import { isTaskStatusClosed, isTaskOverdue as isTaskOverdueUtil } from '../../utils/tasks'
import { CarePathwayType } from '../../__generated__/graphql'
import { getCarePathwayById } from './care-pathway'

export const getTaskById = (state: RootState, id: string): Task | undefined => state.tasks.byId[id]

export const doesTaskExist = (state: RootState, id: string): boolean => !!state.tasks.byId[id]

export const getTaskSummary = (state: RootState, id: string): string => state.tasks.byId[id]?.summary ?? ''

export const getTaskMap = (state: RootState): Record<string, Task> => state.tasks.byId

export const useDoesTaskExist: () => (id: string) => boolean = () => {
  const tasks = useAppSelector(getTaskMap, deepEquals)

  return useCallback((id: string) => !!tasks[id], [tasks])
}

export const getTaskAssignee = (state: RootState, id: string): string => getTaskById(state, id)?.assignee ?? ''

export const getTaskPatientId = (state: RootState, id: string): string | undefined => getTaskById(state, id)?.patientId

export const getTaskStatus = (state: RootState, id: string): TaskStatus =>
  getTaskById(state, id)?.status ?? TaskStatus.TaskCreated

export const getTaskDueDate = (state: RootState, id: string): string | undefined => getTaskById(state, id)?.dueDate

export const isTaskClosed = createSelector(getTaskStatus, isTaskStatusClosed)

export const isTaskOverdue = createSelector(getTaskById, (task) => !task || isTaskOverdueUtil(task))

export const isTaskStale = (state: RootState, taskId: string): boolean => state.tasks.isStale[taskId] ?? false

export const getTaskCarePathwayId = (state: RootState, taskId: string): string =>
  state.tasks.byId[taskId]?.carePathwayId ?? ''

export const isBpEscalationTask = (state: RootState, taskId: string): boolean => {
  const carePathwayId = getTaskCarePathwayId(state, taskId)
  const carePathway = getCarePathwayById(state, carePathwayId)

  return (
    carePathway?.type === CarePathwayType.HighBloodPressureEscalation ||
    carePathway?.type === CarePathwayType.LowBloodPressureEscalation
  )
}

const emptyTaskOptions: TaskOption[] = []

export const getTaskOptions = createSelector(getTaskById, (task) => task?.options ?? emptyTaskOptions)

import { MouseEventHandler, ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import deepEquals from 'lodash.isequal'
import clsx from 'clsx'
import { Button, ButtonVariant, MenuButton } from '../../../ui/button'
import { SvgIcon } from '../../../ui/icon'
import { ControlledMenu, useMenuState } from '../../../ui/menu'
import { ReactComponent as AdjustmentsIcon } from '../../../icons/outline/adjustments.svg'
import FieldSet from '../../../ui/field-set'
import FormLabel from '../../../ui/form-label'
import { buildPatientListId, parseListId, serializeListIdForRoute } from '../../../../utils/lists'
import { ListDescriptor } from '../../../../types/lists'
import { useAppSelector } from '../../../../redux'
import { getPractitionerName } from '../../../../redux/selectors/practitioner'
import { getPatientName } from '../../../../redux/selectors/patient'
import useOnClickOutside from '../../../../hooks/use-on-click-outside'
import PatientMultiCombobox from '../../../ui/forms/patient-multi-combobox'
import PractitionerMultiCombobox from '../../../ui/forms/practitioner-multi-combobox'
import { Item } from '../../../ui/forms/types'
import { AcquisitionChannel, AcquisitionChannelNames } from '../../../../types/patient'
import AcquisitionChannelMultiCombobox from '../../../ui/forms/acquisition-channel-multi-combobox'
import { Routes } from '../../../../types/route'

interface Props {
  currentListId: string
  className: string
}

const PatientFilterMenu = ({ currentListId, className }: Props): ReactElement => {
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLElement>(null)
  const { closeMenu, menuProps, toggleMenu } = useMenuState()
  const {
    practitionerId: currentPractitionerId,
    patientId: currentPatientId,
    acquisitionChannel: currentAcquisitionChannel,
  } = parseListId(currentListId)
  const currentPractitionerItems = useAppSelector(
    (state) =>
      currentPractitionerId?.map(
        (id): Item => ({
          name: getPractitionerName(state, id),
          type: 'option',
          value: id,
        }),
      ),
    deepEquals,
  )
  const currentPatientItems = useAppSelector(
    (state) =>
      currentPatientId?.map(
        (id): Item => ({
          name: getPatientName(state, id),
          type: 'option',
          value: id,
        }),
      ),
    deepEquals,
  )
  const currentAcquisitionChannelItems = useMemo(
    () =>
      currentAcquisitionChannel?.map(
        (id): Item => ({
          name: AcquisitionChannelNames[id as keyof typeof AcquisitionChannelNames],
          type: 'option',
          value: id,
        }),
      ),
    [currentAcquisitionChannel],
  )

  const [selectedPractitioners, setSelectedPractitioners] = useState<Item[]>(currentPractitionerItems ?? [])
  const [selectedPatients, setSelectedPatients] = useState<Item[]>(currentPatientItems ?? [])
  const [selectedAcquisitionChannels, setSelectedAcquisitionChannels] = useState<Item[]>(
    currentAcquisitionChannelItems ?? [],
  )
  const descriptor = useMemo(
    (): Partial<ListDescriptor> => ({
      query: 'all',
      practitionerId: selectedPractitioners.length ? selectedPractitioners.map(({ value }) => value) : undefined,
      patientId: selectedPatients.length ? selectedPatients.map(({ value }) => value) : undefined,
      acquisitionChannel: selectedAcquisitionChannels.length
        ? selectedAcquisitionChannels.map(({ value }) => value as AcquisitionChannel)
        : undefined,
    }),
    [selectedPatients, selectedPractitioners, selectedAcquisitionChannels],
  )
  const newListId = useMemo(
    () =>
      buildPatientListId({
        ...descriptor,
      }),
    [descriptor],
  )

  const history = useHistory()
  const onClear = useCallback(() => {
    if (selectedPractitioners.length) {
      setSelectedPractitioners([])
    }

    if (selectedPatients.length) {
      setSelectedPatients([])
    }

    if (selectedAcquisitionChannels.length) {
      setSelectedAcquisitionChannels([])
    }

    closeMenu()
    history.push(`/dashboard`)
  }, [closeMenu, history, selectedPatients.length, selectedPractitioners.length, selectedAcquisitionChannels.length])

  const onApply = useCallback(() => {
    history.push(
      generatePath(Routes.DASHBOARD, {
        serializedListId: serializeListIdForRoute(descriptor),
      }),
    )
  }, [descriptor, history])

  const onMenuButtonClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      toggleMenu()
    },
    [toggleMenu],
  )
  useOnClickOutside(menuRef, closeMenu)

  const currentFilterNumber =
    (currentPatientId?.length || 0) + (currentPractitionerId?.length || 0) + (currentAcquisitionChannel?.length || 0)

  return (
    <>
      <MenuButton
        active={menuProps.state === 'open'}
        className={clsx('group', className, {
          'pr-3': currentFilterNumber,
        })}
        onClick={onMenuButtonClick}
        ref={menuButtonRef}
        variant={ButtonVariant.SECONDARY}
      >
        <SvgIcon Icon={AdjustmentsIcon} />
        <span className="ml-2">Filter</span>
        {currentFilterNumber ? (
          <span className="inline-block w-6 h-6 ml-3 rounded-sm text-sm leading-6 font-semibold text-rivaOffblack-900 bg-rivaOffblack-200 group-active:bg-white group-[.active]:bg-white">
            {currentFilterNumber}
          </span>
        ) : null}
      </MenuButton>
      <ControlledMenu {...menuProps} align="end" anchorRef={menuButtonRef} ref={menuRef} className="cursor-default">
        <div
          className="w-[384px] p-7"
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
        >
          <FieldSet>
            <PractitionerMultiCombobox selectedItems={selectedPractitioners} onChange={setSelectedPractitioners} />
          </FieldSet>
          <FieldSet className="pt-7">
            <PatientMultiCombobox key={currentListId} selectedItems={selectedPatients} onChange={setSelectedPatients} />
          </FieldSet>
          <FieldSet className="pt-7">
            <AcquisitionChannelMultiCombobox
              selectedItems={selectedAcquisitionChannels}
              onChange={setSelectedAcquisitionChannels}
            />
            <FormLabel>Acquisition Channels</FormLabel>
          </FieldSet>
        </div>
        <footer className="p-7 flex justify-end border-t border-rivaOffblack-200">
          <Button
            disabled={
              !selectedPatients.length &&
              !selectedPractitioners.length &&
              !selectedAcquisitionChannels.length &&
              !currentFilterNumber
            }
            onClick={onClear}
            variant={ButtonVariant.SECONDARY}
          >
            Clear filters
          </Button>
          <Button className="ml-3" disabled={newListId === currentListId} onClick={onApply}>
            Apply filters
          </Button>
        </footer>
      </ControlledMenu>
    </>
  )
}

export default PatientFilterMenu

import { ReactElement, useEffect } from 'react'
import { SvgIcon } from '../../ui/icon'
import { ReactComponent as TeaIcon } from '../../icons/outline/tea.svg'
import { track } from '../../../i13n'

const TaskListEmptyState = (): ReactElement => {
  useEffect(() => {
    track('Task Empty Shown')
  }, [])

  return (
    <div className="flex-1 bg-white flex flex-col items-center justify-center">
      <SvgIcon className="text-rivaRed-400" Icon={TeaIcon} />
      <h2 className="text-sm font-semibold m-0">Nothing to see yet</h2>
      <p className="text-sm font-medium text-rivaOffblack-500">Take a coffee break in the meantime</p>
    </div>
  )
}

export default TaskListEmptyState

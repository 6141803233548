import { ReactElement } from 'react'
import { MedicationsPaidQueryQuery } from '../../../../__generated__/graphql'
import { SvgIcon } from '../../../ui/icon'
import useTooltip from '../../../../hooks/use-tooltip'
import { ReactComponent as InformationIcon } from '../../../icons/solid/information-circle.svg'
import Tooltip from '../../../ui/tooltip'
import { getMedicationEventText } from '../../../../utils/chart'

type Medication = NonNullable<
  NonNullable<MedicationsPaidQueryQuery['patient']>['medications']['medications'][number][number]
>

interface Props {
  medication: Medication
}

const MedicationItem = ({ medication }: Props): ReactElement => {
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [12, 8],
  })

  return (
    <div className="pt-4 pb-3 border-b border-rivaOffblack-200 last:border-none grid grid-cols-[1fr_24px] gap-x-5">
      <div className="row-span-1 text-sm font-bold text-rivaOffblack-700">{medication.medication}</div>
      <div className="row-span-1 row-start-2 text-xs font-medium text-rivaOffblack-500">
        {medication.unstructuredsig}
      </div>
      <div className="row-span-2 row-start-1 flex flex-col justify-end">
        <span className="w-6 h-6" {...getTriggerProps()}>
          <SvgIcon Icon={InformationIcon} className="text-rivaOffblack-700" />
        </span>
        <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
          <div className="py-3">
            {medication.events.map((event, i) => {
              if (!event || event.type === 'HIDDEN') {
                return null
              }

              return (
                <div key={i} className="pb-2 last:pb-0 px-5">
                  <div className="text-xxs font-semibold text-rivaOffblack-400">
                    {getMedicationEventText(event.type)}
                  </div>
                  <div className="text-sm font-medium text-white">
                    {event.eventdate}
                    {event.userdisplayname ? ` - ${event.userdisplayname}` : ''}
                  </div>
                </div>
              )
            })}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default MedicationItem

import { useCallback, useEffect, useMemo } from 'react'
import { MenuState, MenuStateOptions, useMenuState as reactUseMenuState } from '@szhsin/react-menu'
import { uuid } from '../../../utils/uuid'

export interface MenuProps {
  state?: MenuState
}

export interface UseMenuHook {
  menuProps: MenuProps
  openMenu: () => void
  closeMenu: () => void
  toggleMenu: () => void
  menuId: string
}

const useMenuState = (options?: MenuStateOptions): UseMenuHook => {
  const menuId = useMemo(() => uuid(), [])
  const [menuProps, reactMenuToggle] = reactUseMenuState(options)

  const openMenu = useCallback(() => reactMenuToggle(true), [reactMenuToggle])
  const closeMenu = useCallback(() => reactMenuToggle(false), [reactMenuToggle])
  const toggleMenu = useCallback(() => reactMenuToggle(), [reactMenuToggle])

  useEffect(() => {
    if (menuProps.state === 'open') {
      const event = new CustomEvent('react-menu-open', {
        detail: menuId,
      })

      document.body.dispatchEvent(event)
    }
  }, [menuId, menuProps.state])

  useEffect(() => {
    const onReactMenuOpen: EventListener = (event) => {
      if ((event as CustomEvent).detail !== menuId && (menuProps.state === 'open' || menuProps.state === 'opening')) {
        closeMenu()
      }
    }

    document.body.addEventListener('react-menu-open', onReactMenuOpen)

    return () => {
      document.body.removeEventListener('react-menu-open', onReactMenuOpen)
    }
  }, [closeMenu, menuId, menuProps.state])

  const hook: UseMenuHook = {
    menuProps,
    openMenu,
    closeMenu,
    toggleMenu,
    menuId,
  }

  return hook
}

export default useMenuState

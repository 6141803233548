import { ReactElement } from 'react'
import deepEquals from 'lodash.isequal'
import clsx from 'clsx'
import { useAppSelector } from '../../../redux'
import { getCarePathwayById, getCarePathwayEventById } from '../../../redux/selectors/care-pathway'
import { CarePathwayType, TaskEventType } from '../../../__generated__/graphql'
import {
  AddHighBPEscalationSurveyResultsEvent,
  AddLowBPEscalationSurveyResultsEvent,
  BPEscalationSymptomsEvent,
} from '../../../types/care-pathway'

interface Props {
  id: string
}

const BloodPressureEscalationSummary = ({ id }: Props): ReactElement | null => {
  const carePathway = useAppSelector((state) => getCarePathwayById(state, id))
  const events = useAppSelector(
    (state) => carePathway?.eventIds.map((id) => getCarePathwayEventById(state, id)),
    deepEquals,
  )
  const symptomsEvent = events?.find(
    (event): event is BPEscalationSymptomsEvent => event?.eventType === TaskEventType.BpEscalationSymptoms,
  )
  const highBpSurveyEvent = events?.find(
    (event): event is AddHighBPEscalationSurveyResultsEvent =>
      event?.eventType === TaskEventType.AddHighBpEscalationSurveyResults,
  )
  const lowBpSurveyEvent = events?.find(
    (event): event is AddLowBPEscalationSurveyResultsEvent =>
      event?.eventType === TaskEventType.AddLowBpEscalationSurveyResults,
  )

  if (!carePathway) {
    return null
  }

  return (
    <ul className="list-disc ml-5 text-sm font-medium text-rivaOffblack-900">
      {symptomsEvent ? <li>{symptomsEvent.data.symptoms ? 'Reported symptoms' : 'No symptoms reported'}</li> : null}
      {carePathway.type === CarePathwayType.HighBloodPressureEscalation ? (
        <>
          <li>
            <strong className="font-semibold">During reading: </strong>
            {highBpSurveyEvent?.data.explanation ?? '-'}
          </li>
          <li
            className={clsx({
              'text-rivaFuchsia-700 bg-rivaFuchsia-100': highBpSurveyEvent?.data.missedMedication,
            })}
          >
            <strong className="font-semibold">Medication adherence (in the past 3 days): </strong>
            {highBpSurveyEvent?.data.medicationAdherence ?? '-'}
          </li>
        </>
      ) : (
        <>
          <li>
            <strong className="font-semibold">Did family member/friend borrow the cuff? </strong>
            {!lowBpSurveyEvent
              ? '-'
              : lowBpSurveyEvent.data.wasCuffBorrowed
              ? 'Yes, someone else used my cuff'
              : 'No, no one else used my cuff'}
          </li>
          <li
            className={clsx({
              'text-rivaFuchsia-700 bg-rivaFuchsia-100': lowBpSurveyEvent?.data.hasMedicalCondition,
            })}
          >
            <strong className="font-semibold">Recent illness or infection? </strong>
            {!lowBpSurveyEvent ? '-' : lowBpSurveyEvent.data.hasMedicalCondition ? 'Yes' : 'No'}
          </li>
          <li>
            <strong className="font-semibold">During reading: </strong>
            {lowBpSurveyEvent?.data.lacksReasonForEscalation
              ? 'No explanation given'
              : lowBpSurveyEvent?.data.explanation ?? '-'}
          </li>
        </>
      )}
    </ul>
  )
}

export default BloodPressureEscalationSummary

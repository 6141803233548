import { ComponentProps, ReactElement, useCallback } from 'react'
import ReactDatePicker from 'react-datepicker'
import { ReactComponent as ChevronIcon } from '../icons/solid/chevron-left.svg'
import { IconSize, SvgIcon } from './icon'
import { Button, ButtonVariant } from './button'
import FormattedDateTime from './formatted-date-time'

type ReactDatePickerProps = ComponentProps<typeof ReactDatePicker>

type Props = Omit<ReactDatePickerProps, 'calendarClassName' | 'renderCustomHeader'>

const DatePicker = (props: Props): ReactElement => {
  const renderCustomHeader = useCallback<NonNullable<ReactDatePickerProps['renderCustomHeader']>>(
    ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
      <header className="mb-4 flex items-center">
        {prevMonthButtonDisabled ? (
          <span className="w-10 h-8 flex-none" />
        ) : (
          <Button
            className="!px-[7px] !py-[3px] !box-content !h-6"
            onClick={decreaseMonth}
            variant={ButtonVariant.SECONDARY}
          >
            <SvgIcon Icon={ChevronIcon} size={IconSize.MEDIUM} />
          </Button>
        )}
        <h6 className="text-sm font-semibold flex-1">
          <FormattedDateTime value={date.toISOString()} month="long" year="numeric" />
        </h6>
        {nextMonthButtonDisabled ? (
          <span className="w-10 h-8 flex-none" />
        ) : (
          <Button
            className="!px-[7px] !py-[3px] !box-content !h-6"
            onClick={increaseMonth}
            type="submit"
            variant={ButtonVariant.SECONDARY}
          >
            <SvgIcon className="rotate-180" Icon={ChevronIcon} size={IconSize.MEDIUM} />
          </Button>
        )}
      </header>
    ),
    [],
  )

  return (
    <ReactDatePicker
      {...props}
      calendarClassName="border-rivaOffblack-200 px-7 pt-7 pb-4 rounded"
      renderCustomHeader={renderCustomHeader}
    />
  )
}

export default DatePicker

import {
  ActionCreator,
  CopyTextFailedAction,
  CopyTextSucceededAction,
  COPY_TEXT_FAILED,
  COPY_TEXT_SUCCEEDED,
} from '../../types/actions'

export const copyTextSucceeded: ActionCreator<CopyTextSucceededAction> = (payload) => ({
  type: COPY_TEXT_SUCCEEDED,
  payload,
})

export const copyTextFailed: ActionCreator<CopyTextFailedAction> = (payload) => ({
  type: COPY_TEXT_FAILED,
  payload,
})

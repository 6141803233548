import { ComponentProps, FunctionComponent, MouseEventHandler, ReactElement, ReactNode } from 'react'
import useTooltip from '../../../hooks/use-tooltip'
import { IconSize } from '../icon'
import IconButton from '../icon-button'
import Tooltip from '../tooltip'

interface Props {
  active: boolean
  activeIcon: FunctionComponent
  'aria-label': string
  colorVariant?: ComponentProps<typeof IconButton>['colorVariant']
  inactiveIcon: FunctionComponent
  onClick: MouseEventHandler
  tooltip: ReactNode
}

const PanelButton = ({ active, activeIcon, inactiveIcon, onClick, tooltip, ...rest }: Props): ReactElement => {
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    placement: 'left',
    offset: [0, 10],
  })

  return (
    <>
      <IconButton
        {...getTriggerProps()}
        {...rest}
        className="mt-2 first:mt-0"
        icon={active ? activeIcon : inactiveIcon}
        onClick={onClick}
        pressed={active}
        size={IconSize.MEDIUM}
      ></IconButton>
      <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
        {tooltip}
      </Tooltip>
    </>
  )
}

export default PanelButton

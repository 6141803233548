import { ReactElement, useCallback, useState } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { DateTime } from 'luxon'
import clsx from 'clsx'
import { IconSize } from '../icon'
import { ReactComponent as ChevronIcon } from '../../icons/solid/chevron-left.svg'
import FormattedDateTime from '../formatted-date-time'
import IconButton from '../icon-button'
import HtmlInputDate from './html-input-date'

export interface Props {
  'data-testid'?: string
  id?: string
  max?: string
  min?: string
  name?: string
  onChange: (value: string) => void
  required?: boolean
  value: string
}

const DateInput = ({ max, min, onChange, required, value, ...props }: Props): ReactElement => {
  const [showYearPicker, setYearPicker] = useState(false)
  const toggleYearPicker = useCallback(() => setYearPicker(!showYearPicker), [showYearPicker])

  const renderCustomHeader = useCallback<NonNullable<ReactDatePickerProps['renderCustomHeader']>>(
    ({
      date,
      decreaseYear,
      decreaseMonth,
      increaseYear,
      increaseMonth,
      prevMonthButtonDisabled,
      prevYearButtonDisabled,
      nextMonthButtonDisabled,
      nextYearButtonDisabled,
    }) => (
      <header className="mb-4 flex items-center h-7">
        <h6 className="text-sm h-7 font-semibold flex-1 text-left pl-2 flex items-center">
          <FormattedDateTime
            className="mr-2 leading-7 inline-block"
            value={date.toISOString()}
            month="long"
            year="numeric"
          />
          <IconButton
            className={clsx('py-[6px] px-[2px] w-auto h-auto', {
              '-rotate-90': !showYearPicker,
              'rotate-90': showYearPicker,
            })}
            icon={ChevronIcon}
            onClick={toggleYearPicker}
            size={IconSize.SMALL}
            type="button"
          />
        </h6>
        <IconButton
          className="px-[6px] py-[2px] w-auto h-auto"
          disabled={showYearPicker ? prevYearButtonDisabled : prevMonthButtonDisabled}
          icon={ChevronIcon}
          onClick={showYearPicker ? decreaseYear : decreaseMonth}
          size={IconSize.SMALL}
          type="button"
        />
        <IconButton
          className="rotate-180 px-[6px] py-[2px] w-auto h-auto"
          disabled={showYearPicker ? nextYearButtonDisabled : nextMonthButtonDisabled}
          icon={ChevronIcon}
          onClick={showYearPicker ? increaseYear : increaseMonth}
          size={IconSize.SMALL}
          type="button"
        />
      </header>
    ),
    [showYearPicker, toggleYearPicker],
  )

  const handleChange = useCallback<ReactDatePickerProps['onChange']>(
    (newValue) => {
      const parsedNewValue = newValue ? DateTime.fromJSDate(newValue) : undefined
      const newIsoValue = parsedNewValue?.toISODate() ?? ''

      if (showYearPicker) {
        setYearPicker(false)
      }

      if (!newIsoValue) {
        onChange(newIsoValue)

        return
      }

      if (showYearPicker && value && parsedNewValue) {
        onChange(
          DateTime.fromISO(value)
            .set({
              year: parsedNewValue.year,
            })
            .toISODate() ?? '',
        )

        return
      }

      onChange(newIsoValue)
    },
    [onChange, showYearPicker, value],
  )
  const handleCalendarClose = useCallback(() => setYearPicker(false), [])

  return (
    <ReactDatePicker
      {...props}
      calendarClassName="border-rivaOffblack-200 px-7 pt-7 pb-4 rounded shadow-xl w-full"
      customInput={<HtmlInputDate />}
      dateFormat="yyyy-MM-dd"
      onChange={handleChange}
      onCalendarClose={handleCalendarClose}
      minDate={min ? DateTime.fromISO(String(min)).toJSDate() : undefined}
      maxDate={max ? DateTime.fromISO(String(max)).toJSDate() : undefined}
      popperModifiers={[
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
            tether: false,
            altAxis: true,
          },
        },
        {
          name: 'sameWidth',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            const width = state.rects.reference.width

            if (width > 278) {
              state.styles.popper.width = `${state.rects.reference.width}px`
            }
          },
          effect: ({ state }) => {
            const width = (state.elements.reference as HTMLElement).offsetWidth

            if (width > 278) {
              state.elements.popper.style.width = `${width}px`
            }
          },
        },
      ]}
      renderCustomHeader={renderCustomHeader}
      required={required}
      selected={value ? DateTime.fromISO(value).toJSDate() : undefined}
      shouldCloseOnSelect={!showYearPicker}
      showPopperArrow={false}
      showYearPicker={showYearPicker}
      yearItemNumber={30}
      wrapperClassName="inline-block order-2"
    />
  )
}

export default DateInput

import { gql } from '../__generated__/gql'

export default gql(`#graphql
  query problemsQuery($patientId: ID!) {
    patient(patientId: $patientId) {
      conditions {
        clinicalStatus {
          coding {
            code
          }
          text
        }
        code {
          text
        }
        id
        meta {
          lastUpdated
        }
        onsetDateTime
        severity {
          text
        }
        verificationStatus {
          coding {
            code
          }
        }
      }
    }
  }
`)

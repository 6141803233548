import { ReactElement, useCallback } from 'react'
import { ChannelSettings } from '@sendbird/uikit-react'
import Channel from '@sendbird/uikit-react/Channel'
import clsx from 'clsx'
import { ReactComponent as InformationCircleOutlineIcon } from '../../icons/outline/information-circle.svg'
import { ReactComponent as InformationCircleSolidIcon } from '../../icons/solid/information-circle.svg'
import { ReactComponent as IdentificationOutlineIcon } from '../../icons/outline/identification.svg'
import { ReactComponent as IdentificationSolidIcon } from '../../icons/solid/identification.svg'
import { ReactComponent as IconChatAltOutline } from '../../icons/outline/chat-alt.svg'
import { ReactComponent as IconChatAltSolid } from '../../icons/solid/chat-alt.svg'
import { ReactComponent as IconTicketOutline } from '../../icons/outline/ticket.svg'
import { ReactComponent as IconTicketSolid } from '../../icons/solid/ticket.svg'
import PatientProfile from '../../views/patient-profile'
import { IconSize } from '../icon'
import { CloseButton } from '../close-button'
import TaskDetails from '../../pages/tasks/task-details'
import { RightRailPanel, PanelAction, SelectableRightRailPanel } from './types'
import PanelButton from './panel-button'

interface Props {
  channelUrl: string
  onPanelButtonClick: (action: PanelAction) => void
  selectedPanel: {
    panel: RightRailPanel
    autofocus?: boolean
  }
  listId?: string
  patientId: string | undefined
  panels: RightRailPanel[]
  variant?: 'light' | 'dark'
}

interface RightRailState {
  panel: SelectableRightRailPanel | null
  autofocus?: boolean
}

export const RightRailReducer = (state: RightRailState, action: PanelAction): RightRailState => {
  switch (action.type) {
    case 'close':
      return {
        panel: null,
      }
    case 'open':
      return {
        panel: action.panel,
        autofocus: action.autofocus,
      }
    case 'toggle':
      return {
        panel: action.panel === state.panel ? null : action.panel,
      }
  }
}

const RightRail = ({
  channelUrl,
  onPanelButtonClick,
  selectedPanel,
  listId,
  patientId,
  panels,
  variant = 'light',
}: Props): ReactElement => {
  const toggleChannelSettings = useCallback(
    () =>
      onPanelButtonClick({
        panel: 'channel-settings',
        type: 'toggle',
      }),
    [onPanelButtonClick],
  )
  const togglePatientProfile = useCallback(
    () =>
      onPanelButtonClick({
        panel: 'patient-profile',
        type: 'toggle',
      }),
    [onPanelButtonClick],
  )
  const toggleChat = useCallback(() => {
    onPanelButtonClick({ panel: 'chat', type: 'toggle' })
  }, [onPanelButtonClick])
  const toggleTaskDetails = useCallback(() => {
    onPanelButtonClick({ panel: 'task-details', type: 'toggle' })
  }, [onPanelButtonClick])

  const closeRightRail = useCallback(() => onPanelButtonClick({ type: 'close' }), [onPanelButtonClick])
  const renderChannelHeader = useCallback(() => <span />, [])

  return (
    <div
      className={clsx('flex items-stretch', {
        // Last line of code. So long and thanks for all the fish
        'flex-1 lg:w-[660px] lg:flex-none': selectedPanel.panel !== null,
      })}
    >
      <nav
        className={clsx('flex flex-col items-center w-[60px] flex-none py-3', {
          'bg-white text-rivaOffblack-900': variant === 'light',
          'bg-rivaPurple-1000 text-white': variant === 'dark',
        })}
      >
        {panels.map((panel, index) => {
          switch (panel) {
            case 'channel-settings':
              return (
                <PanelButton
                  active={selectedPanel.panel === panel}
                  activeIcon={InformationCircleSolidIcon}
                  aria-label="Toggle chat settings"
                  colorVariant={variant === 'light' ? 'normal' : 'inverted'}
                  inactiveIcon={InformationCircleOutlineIcon}
                  key={panel}
                  onClick={toggleChannelSettings}
                  tooltip="Chat channel information"
                />
              )
            case 'chat':
              return (
                <PanelButton
                  active={selectedPanel.panel === panel}
                  activeIcon={IconChatAltSolid}
                  aria-label="Toggle chat"
                  colorVariant={variant === 'light' ? 'normal' : 'inverted'}
                  inactiveIcon={IconChatAltOutline}
                  key={panel}
                  onClick={toggleChat}
                  tooltip="Chat"
                />
              )
            case 'patient-profile':
              return (
                <PanelButton
                  active={selectedPanel.panel === panel}
                  activeIcon={IdentificationSolidIcon}
                  aria-label="Toggle patient profile"
                  colorVariant={variant === 'light' ? 'normal' : 'inverted'}
                  inactiveIcon={IdentificationOutlineIcon}
                  key={panel}
                  onClick={togglePatientProfile}
                  tooltip="Patient profile"
                />
              )
            case 'separator':
              return (
                <hr
                  className={clsx('border mt-2 w-[34px]', {
                    'border-rivaOffblack-900/25': variant === 'light',
                    'border-white/25': variant === 'dark',
                  })}
                  key={`separator-${index}`}
                ></hr>
              )
            case 'task-details':
              return (
                <PanelButton
                  active={selectedPanel.panel === panel}
                  activeIcon={IconTicketSolid}
                  aria-label="Toggle task details"
                  colorVariant={variant === 'light' ? 'normal' : 'inverted'}
                  inactiveIcon={IconTicketOutline}
                  key={panel}
                  onClick={toggleTaskDetails}
                  tooltip="Task details"
                />
              )
            default:
              return null
          }
        })}
      </nav>
      {selectedPanel.panel === 'channel-settings' && (
        <ChannelSettings channelUrl={channelUrl} onCloseClick={toggleChannelSettings} />
      )}
      {patientId && selectedPanel.panel === 'patient-profile' && (
        <div className="w-[calc(100%-61px)] border-l border-l-rivaOffblack-200 relative flex flex-col">
          <div className="absolute left-7 top-[30px]">
            <CloseButton close={closeRightRail} size={IconSize.SMALL} />
          </div>
          <PatientProfile patientId={patientId} />
        </div>
      )}
      {patientId && selectedPanel.panel === 'chat' && (
        <div>
          <Channel channelUrl={channelUrl} renderChannelHeader={renderChannelHeader} />
        </div>
      )}
      {selectedPanel.panel === 'task-details' && <TaskDetails autofocus={selectedPanel.autofocus} listId={listId} />}
    </div>
  )
}

export default RightRail

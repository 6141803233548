import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom'
import useMousetrap from '../use-mousetrap'
import { Routes } from '../../types/route'
import { SEARCH_KEYS } from '../../components/pages/tasks/task-commands/keys'

const useAppKeyboardShortcuts = (): void => {
  const history = useHistory()
  const location = useLocation()

  useMousetrap(SEARCH_KEYS, (event) => {
    event.preventDefault()

    if (matchPath(location.pathname, Routes.SEARCH)) {
      document.getElementById('search-input')?.focus()

      return
    }

    history.push(generatePath(Routes.SEARCH))
  })
}

export default useAppKeyboardShortcuts

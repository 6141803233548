import { Reducer, combineReducers } from 'redux'
import { produce } from 'immer'
import { Action } from '../../types/actions'
import {
  FETCH_ALLERGY_INTOLERANCE_FAILED,
  FETCH_ALLERGY_INTOLERANCE_REQUESTED,
  FETCH_ALLERGY_INTOLERANCE_SUCCEEDED,
} from '../actions/allergy-intolerance'
import { RecordState } from '../../types/record-state'
import { AllergyIntolerance } from '../../__generated__/graphql'
import { UPDATE_PATIENT_FULFILLED } from '../../types/patient'

const byPatientId: Reducer<Record<string, AllergyIntolerance[]>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_ALLERGY_INTOLERANCE_SUCCEEDED:
      draft[action.payload.patientId] = action.payload.allergyIntolerance
      break
    case UPDATE_PATIENT_FULFILLED:
      delete draft[action.payload.patient.id]
      break
  }
}, {})

const recordStateByPatientId: Reducer<Record<string, RecordState>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_ALLERGY_INTOLERANCE_REQUESTED:
      draft[action.payload.patientId] = RecordState.LOADING
      break
    case FETCH_ALLERGY_INTOLERANCE_SUCCEEDED:
      draft[action.payload.patientId] = RecordState.LOADED
      break
    case FETCH_ALLERGY_INTOLERANCE_FAILED:
      draft[action.payload.patientId] = RecordState.ERRORED
      break
    case UPDATE_PATIENT_FULFILLED:
      draft[action.payload.patient.id] = RecordState.DOES_NOT_EXIST
      break
  }
}, {})

export default combineReducers({
  byPatientId,
  recordStateByPatientId,
})

import { Auth0Provider } from '@auth0/auth0-react'
import { ReactElement, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  children: ReactNode
}

const AuthenticationProvider = ({ children }: Props): ReactElement => {
  const history = useHistory()

  return (
    <Auth0Provider
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE || '',
        redirect_uri: window.location.origin,
      }}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={(appState) => {
        if (appState?.returnTo) {
          history.replace(appState.returnTo)
        }
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthenticationProvider

import { ReactElement } from 'react'
import { DepartmentId } from '../../../types/patient'
import FieldSet from '../field-set'
import { getDepartmentName } from '../../../utils/patient'
import { Radio, RadioGroup, RadioLabel, useRadioGroup } from '../radio'
import FormLabel from '../form-label'

interface Props {
  acquisitionChannel: string
  isSubmitted: boolean
  onChange: (departmentId: string) => void
  required?: boolean
  value: string
}

const DepartmentIdSelector = ({ isSubmitted, onChange, required, value }: Props): ReactElement | null => {
  const { getGroupLabelProps, getRadioGroupProps, getRadioProps } = useRadioGroup({ onChange, required, value })

  return (
    <FieldSet colspan={2}>
      <FormLabel {...getGroupLabelProps()} order={0} invalid={required && isSubmitted && value === ''}>
        Athena Department
      </FormLabel>
      <RadioGroup {...getRadioGroupProps()} invalid={required && isSubmitted && !value}>
        <RadioLabel label={getDepartmentName(DepartmentId.COLORADO)}>
          <Radio {...getRadioProps(DepartmentId.COLORADO)} />
        </RadioLabel>
        <RadioLabel label={getDepartmentName(DepartmentId.FLORIDA)}>
          <Radio {...getRadioProps(DepartmentId.FLORIDA)} />
        </RadioLabel>
      </RadioGroup>
    </FieldSet>
  )
}

export default DepartmentIdSelector

import { createSelector } from 'reselect'
import { RootState } from '../reducers'
import { RecordState } from '../../types/record-state'
import {
  AcquisitionChannel,
  BloodPressureGoal,
  BloodPressureManagementStatus,
  CommunicationPreferences,
  Patient,
  PatientType,
  ReminderTimes,
} from '../../types/patient'
import { convertDateToDateString } from '../../utils/dates'
import { WIRELESS_CUFF_DEVICE_TYPE } from '../../types/device'
import { PractitionerNameAndId } from '../../types/practitioner'
import { getPatientMedicalRecordSource as getPatientMRSource } from '../../utils/patient'
import { getPractitionerNameAndId } from './practitioner'
import { getPatientMap } from './patient-list'
import { getListItemIds } from './lists'

export const doesPatientExist = (state: RootState, patientId: string): boolean => !!state.patient.patientMap[patientId]

export function getPatient(state: RootState, patientId: string): Patient | undefined {
  return state.patient.patientMap[patientId]
}

export function getVipIds(state: RootState): string[] {
  return Object.keys(state.patient.patientMap).filter((patientId) => state.patient.patientMap[patientId].type === 'VIP')
}

export function getPatientFirstName(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  return patient?.firstName || ''
}

export function getPatientLastName(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  return patient?.lastName || ''
}

export function getPatientName(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  if (!patient) {
    return ''
  }

  return `${patient.lastName}, ${patient.firstName}`
}

export function getPatientStatus(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  return patient?.status ?? ''
}

export function getPatientType(state: RootState, patientId: string): PatientType {
  return getPatient(state, patientId)?.type || PatientType.MANAGED
}

export function getPatientPhoneNumber(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  return patient?.phoneNumber || ''
}

export function getPatientEmail(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  return patient?.email || ''
}

export function getPatientPronouns(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  return patient?.pronouns || ''
}

export function getPatientDeviceType(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  return patient?.deviceType || ''
}

export function getPatientCommunicationPreferences(state: RootState, patientId: string): CommunicationPreferences {
  const patient = getPatient(state, patientId)

  return (
    patient?.communicationPreferences || {
      dayOfWeek: '',
      hour: '',
    }
  )
}

export function getPatientReminderTimes(state: RootState, patientId: string): ReminderTimes {
  const patient = getPatient(state, patientId)

  return (
    patient?.reminderTimes || {
      am: {
        hour: 0,
        minute: 0,
      },
      pm: {
        hour: 0,
        minute: 0,
      },
    }
  )
}

export function getPatientBloodPressureManagementStatus(
  state: RootState,
  patientId: string,
): BloodPressureManagementStatus {
  const patient = getPatient(state, patientId)

  return patient?.bloodPressureManagementStatus || BloodPressureManagementStatus.ONBOARDING
}

export function getPatientDaysInCare(state: RootState, patientId: string): string[] {
  const date = new Date()
  const startingDate = new Date(getPatient(state, patientId)?.created.at ?? '')
  const daysInCare = new Set<string>()

  while (startingDate < date) {
    daysInCare.add(convertDateToDateString(startingDate))
    startingDate.setDate(startingDate.getDate() + 1)
  }

  daysInCare.add(convertDateToDateString(date))

  return Array.from(daysInCare).reverse()
}

export function convertBloodPressureManagementStatusToDisplayName(
  managementStatus: BloodPressureManagementStatus,
): string {
  switch (managementStatus) {
    case BloodPressureManagementStatus.ONBOARDING:
      return 'Onboarding'
    case BloodPressureManagementStatus.ESTABLISHING_CARE:
      return 'Establishing Care'
    case BloodPressureManagementStatus.ACTIVE_MANAGEMENT:
      return 'Active Management'
    case BloodPressureManagementStatus.MAINTENANCE:
      return 'Maintenance'
    case BloodPressureManagementStatus.DEACTIVATED:
      return 'Deactivated'
  }
}

export function getPatientBloodPressureGoal(state: RootState, patientId: string): BloodPressureGoal | null {
  const patient = getPatient(state, patientId)

  return patient?.bloodPressureGoal || null
}

export function getPatientBloodPressureGoalForDisplay(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  if (!patient) {
    return '-'
  }

  if (!patient.bloodPressureGoal.systolicBloodPressure || !patient.bloodPressureGoal.diastolicBloodPressure) {
    return '-'
  }

  return `${Math.round(patient.bloodPressureGoal.systolicBloodPressure)}/${Math.round(
    patient.bloodPressureGoal.diastolicBloodPressure,
  )}`
}

export function getPatientMedicalRecordNumber(state: RootState, patientId: string): string {
  const patient = getPatient(state, patientId)

  return patient?.medicalRecordNumber || ''
}

export function getPatientBloodPressureNotificationDisabled(state: RootState, patientId: string): boolean {
  const patient = getPatient(state, patientId)

  return patient?.bloodPressureNotificationDisabled || false
}

export const getPatientPrimaryChatChannel = (state: RootState, patientId: string): string => {
  const patient = getPatient(state, patientId)

  return patient?.primaryChatChannel ?? ''
}

export const getPatientPersonId = (state: RootState, patientId: string): string => {
  const patient = getPatient(state, patientId)

  return patient?.personId ?? ''
}

export const getPatientAcquisitionChannel = (state: RootState, patientId: string): AcquisitionChannel | undefined => {
  const patient = getPatient(state, patientId)

  return patient?.acquisitionChannel as AcquisitionChannel
}

export const getPatientReferringProvider = (state: RootState, patientId: string): string => {
  const patient = getPatient(state, patientId)

  return patient?.referringProvider ?? ''
}

export const getPatientPreferredName = (state: RootState, patientId: string): string => {
  const patient = getPatient(state, patientId)

  return patient?.preferredName ?? ''
}

export const getPatientDateOfBirth = (state: RootState, patientId: string): string => {
  const patient = getPatient(state, patientId)

  return patient?.dateOfBirth ?? ''
}

export const getPatientIdByChatChannel = (state: RootState, chatChannel: string): string | undefined =>
  Object.values(state.patient.patientMap).find((patient) => patient.primaryChatChannel === chatChannel)?.id

export const getPatientWirelessCuffId = (state: RootState, patientId: string): string | undefined => {
  const patient = getPatient(state, patientId)

  return patient?.devices?.find((device) => device.type === WIRELESS_CUFF_DEVICE_TYPE)?.deviceId
}

export const getPatientCareTeam = (
  state: RootState,
  patientId: string,
): { coach: PractitionerNameAndId; prescriber: PractitionerNameAndId; pharmacist: PractitionerNameAndId } => {
  const careTeam = getPatient(state, patientId)?.careTeam || { coachId: '', pharmacistId: '', prescriberId: '' }

  return {
    coach: getPractitionerNameAndId(state, careTeam.coachId),
    pharmacist: getPractitionerNameAndId(state, careTeam.pharmacistId),
    prescriber: getPractitionerNameAndId(state, careTeam.prescriberId),
  }
}

export const getPatientTimeZone = (state: RootState, patientId: string): string => {
  const patient = getPatient(state, patientId)

  return patient?.timeZone ?? ''
}

export const getPatientRecordStateByChatChannel = (state: RootState, channelUrl: string): RecordState =>
  state.patient.recordStateByChatChannel[channelUrl] ?? RecordState.DOES_NOT_EXIST

export const getPatientRecordStateById = (state: RootState, patientId: string): RecordState => {
  if (doesPatientExist(state, patientId)) {
    return RecordState.LOADED
  }

  return state.patient.recordStateById[patientId] ?? RecordState.DOES_NOT_EXIST
}

export const getAllPatientIds = createSelector(getPatientMap, (byId) => Object.keys(byId))

export const getPatientNamesAndIds = createSelector(
  getListItemIds,
  (state: RootState) => state,
  (patientIds, state) => {
    return patientIds
      .map((patientId) => ({
        id: patientId,
        name: getPatientName(state, patientId),
      }))
      .sort(({ name: a }, { name: b }) => {
        a = a.toLowerCase()
        b = b.toLowerCase()

        if (a < b) {
          return -1
        }

        if (a > b) {
          return 1
        }

        return 0
      })
  },
)

export const getPatientMedicalRecordSource = createSelector(
  getPatientAcquisitionChannel,
  getPatientType,
  (acquisitionChannel, patientType) => {
    return getPatientMRSource(acquisitionChannel ?? '', patientType)
  },
)

export const getPatientDepartmentId = (state: RootState, patientId: string): string =>
  getPatient(state, patientId)?.departmentId ?? ''

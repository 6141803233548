import { gql } from '../__generated__/gql'

export default gql(`#graphql
  query allergiesQuery($patientId: ID!) {
    patient(patientId: $patientId) {
      id
      allergyIntolerances {
        clinicalStatus {
          text
        }
        code {
          coding {
            code
            system
          }
          text
        }
        id
        reaction {
          description
          id
          severity
        }
      }
    }
  }
`)

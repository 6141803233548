import { createSelector } from 'reselect'
import { RecordState } from '../../types/record-state'
import { RootState } from '../reducers'
import { parseListId } from '../../utils/lists'
import { ListContentType } from '../../types/lists'

/**
 * Returns the record state of the application.  Used to determine whether to show the initial page or not.
 */
export function getAppRecordState(state: RootState): RecordState {
  return state.app.recordState
}

/**
 * @returns The logged in person id
 */
export function getLoggedInPersonId(state: RootState): string {
  return state.app.loggedInPersonId
}

export const getLoggedInPractitionerId = createSelector(
  getLoggedInPersonId,
  (state: RootState) => state.practitioner.personIdToPractitionerIdMap,
  (personId, practitioners) => practitioners[personId] ?? '',
)

export const isMyPatientsListId = (state: RootState, listId: string): boolean => {
  const loggedInPractitionerId = getLoggedInPractitionerId(state)
  const { listContentType, practitionerId } = parseListId(listId)

  return (
    listContentType === ListContentType.PATIENT &&
    practitionerId?.length === 1 &&
    practitionerId[0] === loggedInPractitionerId
  )
}

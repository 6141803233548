import { ReactElement, ReactNode } from 'react'

interface Props {
  subtitle?: ReactNode
  title: ReactNode
}

const ModalHeader = ({ subtitle, title }: Props): ReactElement => (
  <header className="mx-10 mt-10 mb-5">
    <h2 className="text-rivaOffblack-900 text-lg font-semibold mb-1">{title}</h2>
    {subtitle && <p className="text-rivaOffblack-600 text-sm font-medium opacity-80">{subtitle}</p>}
  </header>
)

export default ModalHeader

import clsx from 'clsx'
import jexl from 'jexl'
import { DateTime } from 'luxon'
import { ReactElement, useMemo } from 'react'
import { useAppSelector } from '../../../../redux'
import { getSurveyRulesBySurveyId } from '../../../../redux/selectors/surveys'
import { SurveyRuleStyle } from '../../../../types/survey'

interface Props {
  submissionData: Record<string, unknown>
  surveyId: number
}

const SurveyResults = ({ submissionData, surveyId }: Props): ReactElement => {
  const rules = useAppSelector((state) => getSurveyRulesBySurveyId(state, surveyId))
  const results = useMemo(
    () =>
      rules.reduce(
        (results, rule) => {
          const result = jexl.evalSync(rule.expression, submissionData)

          if (typeof result === 'boolean' && result) {
            const value = submissionData[rule.field]
            let formattedValue = Array.isArray(value) ? value.join(', ') : String(value)

            if (rule.format === 'date') {
              formattedValue = DateTime.fromISO(formattedValue).toLocaleString({
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            }

            results.push({
              message: rule.message.replace('%v', formattedValue),
              style: rule.style,
            })
          }

          return results
        },
        [] as {
          message: string
          style: SurveyRuleStyle
        }[],
      ),
    [rules, submissionData],
  )

  if (results.length === 0) {
    return <div className="text-sm">-</div>
  }

  return (
    <ul className="text-sm">
      {results.map((result) => (
        <li
          key={result.message}
          className={clsx('relative mb-1', {
            'text-rivaGold-700': result.style === SurveyRuleStyle.HIGHLIGHT,
            'text-rivaFuchsia-600': result.style === SurveyRuleStyle.ALERT,
          })}
        >
          <span
            className={clsx('w-1 h-1 absolute top-2 left-2 rounded-full', {
              'bg-rivaOffblack-900': result.style === SurveyRuleStyle.NONE,
              'bg-rivaGold-500': result.style === SurveyRuleStyle.HIGHLIGHT,
              'bg-rivaFuchsia-600': result.style === SurveyRuleStyle.ALERT,
            })}
          ></span>
          <span className="pl-5 line-clamp-2">
            <span
              className={clsx({
                'bg-rivaGold-50': result.style === SurveyRuleStyle.HIGHLIGHT,
                'bg-rivaFuchsia-50': result.style === SurveyRuleStyle.ALERT,
              })}
            >
              {result.message}
            </span>
          </span>
        </li>
      ))}
    </ul>
  )
}

export default SurveyResults

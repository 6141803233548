import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  children?: ReactNode | ReactNode[]
  invalid?: boolean
  warning?: boolean
}

const FormHelperText = ({ children, invalid, warning }: Props): ReactElement => (
  <span
    className={clsx('truncate relative h-3 mt-2 text-xxs leading-3 order-3', {
      'text-rivaFuchsia-800': invalid,
      'text-rivaGold-800': warning,
    })}
  >
    {children}
  </span>
)

export default FormHelperText

export const LOADING_INDICATOR = 'loading-indicator'
export const ERROR_TOAST = 'error-toast'

export const APP_ERROR_STATE = 'app-error-state'

/* Patient List */
export const PATIENT_LIST_HEADER = 'patient-list-header'
export const PATIENT_ITEM = 'patient-item'
export const PATIENT_ITEM_NAME = 'patient-item-name'
export const PATIENT_ITEM_ERROR = 'patient-item-error'
export const MOVING_AVERAGE_STALE_INDICATOR = 'moving-average-stale-indicator'

/* Patient View */
export const PATIENT_VIEW = 'patient-view'
export const PATIENT_VIEW_NAME = 'patient-view-name'
export const PATIENT_VIEW_HEADER = 'patient-view-header'
export const PATIENT_VIEW_HEADER_EXPAND_BUTTON = 'patient-view-header-expand-button'
export const PATIENT_SUMMARY = 'patient-view-patient-summary'
export const PATIENT_SUMMARY_EXPAND_BUTTON = 'patient-view-patient-summary-expand-button'
export const BLOOD_PRESSURE_LOG = 'BLOOD_PRESSURE_LOG'
export const BLOOD_PRESSURE_SET_CARD = 'blood-pressure-set-card'
export const BLOOD_PRESSURE_SET_COMMENT = 'blood-pressure-set-comment'
export const PATIENT_VIEW_ACTIONS_MENU = 'patient-view-actions-menu'
export const SURVEYS_SUMMARY = 'surveys-summary'
export const SURVEYS_LIST = 'surveys-list'
export const ALLERGIES_COUNT = 'allergies-count'

/* Add Patient Modal */
export const ADD_PATIENT_MODAL = 'add-patient-modal'
export const PATIENT_FIRST_NAME_INPUT = 'patient-first-name-input'
export const PATIENT_PHONE_INPUT = 'patient-phone-input'
export const EMERGENCY_CONTACT_NAME_INPUT = 'emergency-name-input'
export const EMERGENCY_PHONE_INPUT = 'emergency-phone-input'

/* Deactivate Patient Modal */
export const DEACTIVATE_PATIENT_MODAL = 'deactivate-patient-modal'
export const DEACTIVATE_PATIENT_SUBMIT = 'deactivate-patient-submit'
export const DEACTIVATE_PATIENT_CLOSE = 'deactivate-patient-close'
export const DEACTIVATE_PATIENT_ERROR_MESSAGE = 'deactivate-patient-error-message'

/* Notes */
export const NOTE_ERROR_STATE = 'note-error-state'
export const NOTE_EMPTY_STATE = 'note-empty-state'
export const NOTE_CARD = 'note-card'
export const NOTE_EDIT_BUTTON = 'note-edit-button'
export const NOTE_TEXT_AREA = 'note-text-area'
export const NOTE_TEXT = 'note-text'
export const NOTE_SUBMIT_BUTTON = 'note-submit-button'
export const NOTE_EDIT_TEXT_AREA = 'note-edit-text-area'
export const NOTE_EDIT_SAVE_BUTTON = 'note-edit-save-button'
export const NOTE_CREATE_ERROR = 'note-create-error'

/* Blood Pressure Sets */
export const BLOOD_PRESSURE_SETS_ERROR_STATE = 'blood-pressure-sets-error-state'
export const BLOOD_PRESSURE_SETS_EMPTY_STATE = 'blood-pressure-sets-empty-state'
export const BLOOD_PRESSURE_SETS_CARD = 'blood-pressure-sets-card'

/* Blood Pressure Set View */
export const BLOOD_PRESSURE_SET_VIEW_BACK_BUTTON = 'blood-pressure-set-view-back-button'
export const BLOOD_PRESSURE_SET_VIEW_TITLE = 'blood-pressure-set-view-title'
export const BLOOD_PRESSURE_SET_VIEW_TOTAL_READINGS = 'blood-pressure-set-view-total-readings'
export const BLOOD_PRESSURE_SET_VIEW_DATE = 'blood-pressure-set-view-date'
export const BLOOD_PRESSURE_SET_VIEW_TIME = 'blood-pressure-set-view-time'
export const BLOOD_PRESSURE_SET_VIEW_CUFF_READING_CARD = 'blood-pressure-set-view-cuff-reading-card'
export const BLOOD_PRESSURE_SET_VIEW_CUFF_READING_BPM = 'blood-pressure-set-view-cuff-reading-bpm'
export const BLOOD_PRESSURE_SET_VIEW_CUFF_READING_MMHG = 'blood-pressure-set-view-cuff-reading-mmhg'
export const BLOOD_PRESSURE_SET_VIEW_CUFF_READING_EDIT_BUTTON = 'blood-pressure-set-view-cuff-reading-edit-button'
export const BLOOD_PRESSURE_SET_VIEW_PHONE_READING_CARD = 'blood-pressure-set-view-phone-reading-card'
export const BLOOD_PRESSURE_SET_VIEW_ERROR_STATE = 'blood-pressure-set-view-error-state'

/* Update Cuff Measurement Modal */
export const UPDATE_CUFF_MEASUREMENT_MODAL = 'update-cuff-measurement-modal'
export const UPDATE_CUFF_MEASUREMENT_SUBMIT = 'update-cuff-measurement-submit'
export const UPDATE_CUFF_MEASUREMENT_CLOSE = 'update-cuff-measurement-close'
export const UPDATE_CUFF_MEASUREMENT_ERROR_MESSAGE = 'update-cuff-measurement-error-message'

/* Measurement View */
export const MEASUREMENT_VIEW_BACK_BUTTON = 'measurement-view-back-button'
export const MEASUREMENT_VIEW_CLOSE_BUTTON = 'measurement-view-close-button'
export const MEASUREMENT_VIEW_TITLE = 'measurement-view-title'
export const MEASUREMENT_VIEW_TIME = 'measurement-view-time'
export const MEASUREMENT_VIEW_DATE = 'measurement-view-date'
export const MEASUREMENT_VIEW_MMHG = 'measurement-view-mmhg'
export const MEASUREMENT_VIEW_BPM = 'measurement-view-bpm'
export const MEASUREMENT_VIEW_7S_WINDOW_LINK = 'measurement-view-7s-window-link'
export const MEASUREMENT_VIEW_WAVEFORMS_VS_TIME_LINK = 'measurement-view-waveforms-vs-time-link'
export const MEASUREMENT_VIEW_ERROR_STATE = 'measurement-view-error-state'
export const MEASUREMENT_VIEW_7S_WINDOW_GRAPH = 'measurement-view-7s-window-graph'
export const MEASUREMENT_VIEW_WAVEFORMS_VS_TIME_GRAPH = 'measurement-view-waveforms-vs-time-graph'
export const MEASUREMENT_VIEW_CONTAINER = 'measurement-view-container'

/* Task List View */
export const TASK_LIST_ITEM = 'task-list-item'
export const TASK_LIST_ITEM_DUE_DATE = 'task-list-item-due-date'
export const TASK_LIST_ITEM_STATUS = 'task-list-item-status'
export const TASK_LIST_ITEM_COPY = 'task-list-item-copy'
export const TASK_LIST_EVENT_ALERT = 'task-list-event-alert'

/* Task Details View */
export const TASK_DETAILS = 'task-details'
export const TASK_DETAILS_ASSIGN_TASK = 'task-details-assign-task'
export const TASK_DETAILS_TASK_NOTE = 'task-details-task-note'
export const TASK_DETAILS_TASK_EVENT = 'task-details-task-event'

import { ReactElement } from 'react'
import { LanguageCodeToName, LanguageISO6392Code } from '../../../types/patient'
import { Option, Select } from '../select'

interface Props {
  onChange: (value?: LanguageISO6392Code) => void
  value?: LanguageISO6392Code
}

const LanguageSelector = ({ onChange, value }: Props): ReactElement => {
  return (
    <Select label="Language" onChange={onChange} placeholder="Select language" value={value}>
      {Object.values(LanguageISO6392Code).map((code) => {
        return (
          <Option key={code} value={code}>
            {LanguageCodeToName[code]}
          </Option>
        )
      })}
    </Select>
  )
}

export default LanguageSelector

import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import useGetBaselineMovingAverage, {
  getBaselineBloodPressureForDisplay,
} from '../../../api/hooks/use-get-baseline-moving-average'
import PatientSummaryCard from './patient-summary-card'

interface Props {
  className?: string
  personId: string
}

const BloodPressureBaselineCard = ({ className, personId }: Props): ReactElement => {
  const { data } = useGetBaselineMovingAverage(personId)

  const timestamp = data?.baseline?.latestTimestamp

  return (
    <PatientSummaryCard
      className={className}
      subtitle={
        timestamp
          ? DateTime.fromISO(timestamp).toLocaleString({
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })
          : undefined
      }
      title="12-MA Baseline"
    >
      {data?.baseline ? (
        <p className="text-rivaOffblack-900 font-bold text-base">{getBaselineBloodPressureForDisplay(data)}</p>
      ) : (
        '-'
      )}
    </PatientSummaryCard>
  )
}

export default BloodPressureBaselineCard

import { AnyAction } from 'redux'
import { ToastState } from '../../types/toast'
import { UPDATE_NOTE_FAILED } from '../../types/note'
import { DOWNLOAD_REPORT_FAILED } from '../../types/reports'
import { ASSIGN_DEVICE_TO_PATIENT_FAILED } from '../../types/device'
import { COPY_TEXT_FAILED } from '../../types/actions'
import {
  CARE_PATHWAY_CANCEL_FAILED,
  CARE_PATHWAY_START_FAILED,
  CarePathwayStartFailedAction,
} from '../../types/care-pathway'
import { getCarePathwayName } from '../../utils/care-pathway'

let errorId = 1

export function error(state: ToastState = { message: '', id: 0 }, action: AnyAction): ToastState {
  switch (action.type) {
    case ASSIGN_DEVICE_TO_PATIENT_FAILED:
    case UPDATE_NOTE_FAILED:
      return {
        message: action.payload.error.message,
        id: errorId++,
      }
    case DOWNLOAD_REPORT_FAILED:
      return {
        message: action.payload.error.message,
        id: errorId++,
      }
    case COPY_TEXT_FAILED:
      return {
        message: 'Failed to copy text. Check your browser permissions',
        id: errorId++,
      }
    case CARE_PATHWAY_START_FAILED:
      return {
        message: `Failed to start ${getCarePathwayName(
          (action as CarePathwayStartFailedAction).payload.carePathwayType,
        )} care pathway`,
        id: errorId++,
      }
    case CARE_PATHWAY_CANCEL_FAILED:
      return {
        message: 'Failed to cancel care pathway',
        id: errorId++,
      }
    default:
      return state
  }
}

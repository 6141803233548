import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { datadogLogs } from '@datadog/browser-logs'
import { useAppSelector } from '../../redux'
import { getAppRecordState, getLoggedInPersonId } from '../../redux/selectors/app'
import { getPractitionerByPersonId, getPractitionerName } from '../../redux/selectors/practitioner'
import { RecordState } from '../../types/record-state'
import { getPerson } from '../../redux/selectors/person'

const useAnalyticsMetadata = (): void => {
  const appRecordState = useAppSelector(getAppRecordState)
  const loggedInPersonId = useAppSelector(getLoggedInPersonId)
  const practitionerId = useAppSelector((state) => getPractitionerByPersonId(state, loggedInPersonId)?.id)
  const name = useAppSelector((state) => getPractitionerName(state, practitionerId ?? ''))
  const email = useAppSelector((state) => getPerson(state, loggedInPersonId)?.email ?? '')

  useEffect(() => {
    mixpanel.register({
      env: process.env.REACT_APP_ENV_NAME,
    })
  }, [])

  useEffect(() => {
    if (appRecordState !== RecordState.LOADED || !practitionerId) {
      return
    }

    mixpanel.identify(practitionerId)
    mixpanel.people.set({
      $name: name,
      'Person ID': loggedInPersonId,
      'Practitioner ID': practitionerId,
      Role: 'Practitioner',
    })
  }, [appRecordState, loggedInPersonId, name, practitionerId])

  useEffect(() => {
    if (appRecordState !== RecordState.LOADED || !practitionerId) {
      return
    }

    datadogLogs.setUser({
      id: loggedInPersonId,
      email,
      name,
      practitionerId,
    })
  }, [appRecordState, email, loggedInPersonId, name, practitionerId])
}

export default useAnalyticsMetadata

import { ReactElement } from 'react'
import BloodPressureEscalationAlerts from './blood-pressure-escalation-alerts'

interface Props {
  patientId: string
}

const PatientAlerts = ({ patientId }: Props): ReactElement => {
  return (
    <>
      <BloodPressureEscalationAlerts patientId={patientId} />
    </>
  )
}

export default PatientAlerts

import { FormAction } from '../../../../types/form'
import {
  CREATE_PATIENT_FAILED,
  CREATE_PATIENT_FORM_INITIALIZED,
  CREATE_PATIENT_FULFILLED,
  CREATING_PATIENT,
} from '../../../../types/patient'
import formReducerFactory from '../form-reducer-factory'

export const createPatientForm = formReducerFactory({
  [FormAction.INITIALIZED]: [CREATE_PATIENT_FORM_INITIALIZED],
  [FormAction.SUBMITTING]: [CREATING_PATIENT],
  [FormAction.SUBMITTED]: [CREATE_PATIENT_FULFILLED],
  [FormAction.ERRORED]: [CREATE_PATIENT_FAILED],
})

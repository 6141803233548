import { ReactElement, useCallback, useState } from 'react'
import clsx from 'clsx'
import { Menu, MenuItem, MenuSeparator } from '../../../ui/menu'
import { MenuButton } from '../../../ui/button'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as IconChevronDown } from '../../../icons/solid/chevron-down.svg'
import { ReactComponent as IconLightningBolt } from '../../../icons/outline/lightning-bolt.svg'
import { PatientStatus } from '../../../../types/patient'
import { PATIENT_VIEW_ACTIONS_MENU } from '../../../../consts/data-testids'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getPatientStatus } from '../../../../redux/selectors/patient'
import { DeactivatePatientModal } from '../../../modals/deactivate-patient'
import { ActivatePatientModal } from '../../../modals/activate-patient'
import { UpdateBloodPressureManagementStatusModal } from '../../../modals/update-blood-pressure-management-status'
import Checkbox from '../../../ui/checkbox'
import { getIsDebugProfileEnabled } from '../../../../redux/selectors/ui'
import { toggleDebugProfile } from '../../../../redux/actions/ui'
import PatientChatButton from '../patient-chat-button'
import useFlag from '../../../../hooks/use-flag'
import { Features } from '../../../../types/features'
import { track } from '../../../../i13n'
import LifestyleChangeMenuItem from './lifestyle-change-menu-item'
import EditPatientMenuItem from './edit-patient-menu-item'
import StudyEnrollmentMenuItem from './study-enrollment-menu-item'

interface Props {
  patientId: string
}

const i13n = {
  properties: {
    Location: 'Profile',
  },
}

const PatientProfileActions = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const status = useAppSelector((state) => getPatientStatus(state, patientId))
  const isDebugProfileEnabled = useAppSelector((state) => getIsDebugProfileEnabled(state))
  const [isDeactivatePatientModalOpen, setIsDeactivatePatientModalOpen] = useState(false)
  const [isActivatePatientModalOpen, setIsActivatePatientModalOpen] = useState(false)
  const [isUpdateBloodPressureManagementStatusModalOpen, setIsUpdateBloodPressureManagementStatusModalOpen] =
    useState(false)
  const onDebugProfileClick = useCallback(() => dispatch(toggleDebugProfile()), [dispatch])
  const isLifestyleChangeEnabled = useFlag(Features.LIFESTYLE_CHANGE)

  const onActionsToggled = useCallback(() => {
    track('Patients Profile More Actions Clicked')
  }, [])

  return (
    <div className="flex items-center justify-end px-7 py-5 border-b border-rivaOffblack-300 border-dashed">
      <PatientChatButton i13n={i13n} patientId={patientId} key={patientId} />
      <Menu
        align="end"
        menuButton={({ open }) => (
          <MenuButton active={open} className="pr-4 ml-3">
            <SvgIcon Icon={IconLightningBolt} size={IconSize.SMALL} />
            <span className="mx-2">Actions</span>
            <SvgIcon
              Icon={IconChevronDown}
              size={IconSize.SMALL}
              className={clsx('transition-transform', {
                '-rotate-180': open,
              })}
            />
          </MenuButton>
        )}
        data-testid={PATIENT_VIEW_ACTIONS_MENU}
        onMenuChange={onActionsToggled}
      >
        <MenuItem onClick={() => setIsUpdateBloodPressureManagementStatusModalOpen(true)}>Update Status</MenuItem>
        <EditPatientMenuItem patientId={patientId} />
        {isLifestyleChangeEnabled ? <LifestyleChangeMenuItem patientId={patientId} /> : null}
        <StudyEnrollmentMenuItem patientId={patientId} />
        {status === PatientStatus.ACTIVE ? (
          <MenuItem onClick={() => setIsDeactivatePatientModalOpen(true)}>
            <p className="text-rivaFuchsia-500">Deactivate Patient</p>
          </MenuItem>
        ) : (
          <MenuItem onClick={() => setIsActivatePatientModalOpen(true)}>Reactivate Patient</MenuItem>
        )}
        <MenuSeparator />
        <MenuItem onClick={onDebugProfileClick} className="flex">
          <span className="flex-1">Show debug information</span>
          <span className="flex-none w-5 h-5 ml-2">
            <Checkbox checked={isDebugProfileEnabled} onChange={onDebugProfileClick} />
          </span>
        </MenuItem>
      </Menu>
      <DeactivatePatientModal
        isOpen={isDeactivatePatientModalOpen}
        close={() => setIsDeactivatePatientModalOpen(false)}
        patientId={patientId}
      />
      <ActivatePatientModal
        isOpen={isActivatePatientModalOpen}
        close={() => setIsActivatePatientModalOpen(false)}
        patientId={patientId}
      />
      <UpdateBloodPressureManagementStatusModal
        isOpen={isUpdateBloodPressureManagementStatusModalOpen}
        close={() => setIsUpdateBloodPressureManagementStatusModalOpen(false)}
        patientId={patientId}
      />
    </div>
  )
}

export default PatientProfileActions

import { ReactElement, useCallback, useMemo } from 'react'
import { AcquisitionChannelNames } from '../../../types/patient'
import MultiCombobox from '../multi-combobox'
import { Item } from './types'

interface Props {
  selectedItems: Item[]
  onChange: (selectedItems: Item[]) => void
}

const AcquisitionChannelMultiCombobox = ({ onChange, selectedItems }: Props): ReactElement => {
  const items = useMemo(
    (): Item[] =>
      Object.keys(AcquisitionChannelNames).map(
        (id): Item => ({
          name: AcquisitionChannelNames[id as keyof typeof AcquisitionChannelNames],
          type: 'option',
          value: id,
        }),
      ),
    [],
  )

  const itemToKey = useCallback(({ value }: Item) => value, [])
  const itemMatches = useCallback(
    (inputValue: string, { name }: Item) => name.toLowerCase().includes(inputValue.toLowerCase()),
    [],
  )
  const renderItem = useCallback(({ name }: Item) => <span className="flex-1 truncate">{name}</span>, [])

  return (
    <MultiCombobox
      itemMatches={itemMatches}
      items={items}
      itemToKey={itemToKey}
      maxHeight="short"
      onChange={onChange}
      placeholder="Select acquisition channel"
      renderItem={renderItem}
      selectedItems={selectedItems}
    />
  )
}

export default AcquisitionChannelMultiCombobox

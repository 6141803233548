import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useAppSelector } from '../../redux'
import { getSuccessId, getSuccessMessage } from '../../redux/selectors/success'
import { ReactComponent as IconCheckmark } from '../icons/outline/check.svg'
import { IconSize, SvgIcon } from './icon'

export function SuccessToastContainer(): null {
  const message = useAppSelector((state) => getSuccessMessage(state))
  const id = useAppSelector((state) => getSuccessId(state))

  useEffect(() => {
    if (message) {
      toast.success(<SuccessToast message={message} />, {
        autoClose: 3000,
      })
    }
  }, [message, id])

  return null
}

interface SuccessToastProps {
  message: string
}
function SuccessToast({ message }: SuccessToastProps) {
  return (
    <div className="text-rivaOffblack relative">
      <div className="flex items-center">
        <div className="bg-rivaLime rounded-full mx-4">
          <SvgIcon Icon={IconCheckmark} size={IconSize.X_SMALL} className="fill-current text-rivaWhite" />
        </div>
        <p className="text-rivaBlack text-sm font-semibold">{message}</p>
      </div>
    </div>
  )
}

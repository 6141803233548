import { createSelector } from 'reselect'
import { AllergyIntolerance, Maybe, Coding } from '../../__generated__/graphql'
import { RecordState } from '../../types/record-state'
import { RootState } from '../reducers'
import { CODING_SYSTEM_SNOMED, NoKnownAllergies } from '../../types/chart'

export const getAllergyIntolerance = (state: RootState, patientId: string): AllergyIntolerance[] | undefined =>
  state.allergyIntolerance.byPatientId[patientId]

const isNoKnownAllergyCode = (coding: Maybe<Coding>): boolean =>
  !!coding && coding.system === CODING_SYSTEM_SNOMED && !!coding.code && NoKnownAllergies.includes(coding.code)

const isKnownAllergyIntolerance = ({ code }: AllergyIntolerance): boolean => {
  return !code?.coding?.some(isNoKnownAllergyCode)
}

export const getAllergyIntoleranceCount = createSelector(getAllergyIntolerance, (allergyIntolerances) => {
  return allergyIntolerances?.filter(isKnownAllergyIntolerance).length ?? 0
})

export const getAllergyIntoleranceRecordState = (state: RootState, patientId: string): RecordState =>
  state.allergyIntolerance.recordStateByPatientId[patientId] ?? RecordState.DOES_NOT_EXIST

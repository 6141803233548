import {
  Note,
  FetchingNotes,
  FetchNotesFulfilled,
  FetchNotesFailed,
  FETCHING_NOTES,
  FETCH_NOTES_FULFILLED,
  FETCH_NOTES_FAILED,
  CreatingNote,
  CREATING_NOTE,
  UpdatingNote,
  UPDATING_NOTE,
  CreateNoteFulfilled,
  CREATE_NOTE_FULFILLED,
  CreateNoteFailed,
  CREATE_NOTE_FAILED,
  UpdateNoteFulfilled,
  UPDATE_NOTE_FULFILLED,
  UpdateNoteFailed,
  UPDATE_NOTE_FAILED,
  CREATE_NOTE_FORM_INITIALIZED,
  CreateNoteFormInitialized,
} from '../../types/note'

export function fetchingNotes(memberId: string): FetchingNotes {
  return {
    type: FETCHING_NOTES,
    payload: { memberId },
  }
}

export function fetchNotesFulfilled(notes: Note[], memberId: string): FetchNotesFulfilled {
  return {
    type: FETCH_NOTES_FULFILLED,
    payload: {
      memberId,
      notes,
    },
  }
}

export function fetchNotesFailed(error: Error, memberId: string): FetchNotesFailed {
  return {
    type: FETCH_NOTES_FAILED,
    payload: {
      memberId,
      error,
    },
  }
}

export function createNoteFormInitialized(): CreateNoteFormInitialized {
  return {
    type: CREATE_NOTE_FORM_INITIALIZED,
    payload: undefined,
  }
}

export function creatingNote(): CreatingNote {
  return {
    type: CREATING_NOTE,
    payload: undefined,
  }
}

export function createNoteFulfilled(note: Note): CreateNoteFulfilled {
  return {
    type: CREATE_NOTE_FULFILLED,
    payload: {
      note,
    },
  }
}

export function createNoteFailed(error: Error): CreateNoteFailed {
  return {
    type: CREATE_NOTE_FAILED,
    payload: {
      error,
    },
  }
}

export function updatingNote(noteId: string, text: string, updatedAt: string, updatedBy: string): UpdatingNote {
  return {
    type: UPDATING_NOTE,
    payload: {
      noteId,
      text,
      updatedAt,
      updatedBy,
    },
  }
}

export function updateNoteFulfilled(): UpdateNoteFulfilled {
  return {
    type: UPDATE_NOTE_FULFILLED,
    payload: undefined,
  }
}

export function updateNoteFailed(
  error: Error,
  noteId: string,
  text: string,
  updatedAt: string,
  updatedBy: string,
): UpdateNoteFailed {
  return {
    type: UPDATE_NOTE_FAILED,
    payload: {
      error,
      noteId,
      text,
      updatedAt,
      updatedBy,
    },
  }
}

import { ChangeEventHandler, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import deepEquals from 'lodash.isequal'
import useTasksApi from '../../api/hooks/use-tasks-api'
import { useAppDispatch, useAppSelector } from '../../redux'
import { getPatientCareTeam } from '../../redux/selectors/patient'
import { getAllPractitionerNamesAndIds } from '../../redux/selectors/practitioner'
import { getTaskAssignee, getTaskPatientId, getTaskStatus, getTaskSummary } from '../../redux/selectors/tasks'
import { assignTask } from '../../redux/thunks/tasks'
import FieldSet from '../ui/field-set'
import FormLabel from '../ui/form-label'
import PractitionerAvatar, { AvatarSize } from '../ui/practitioner-avatar'
import { Option, Select, SelectDivider, SelectHeading } from '../ui/select'
import TextArea from '../ui/text-area'
import { getStatusText } from '../../utils/tasks'
import { track } from '../../i13n'
import Confirm from './confirm'

interface Props {
  i13n?: Record<string, unknown>
  isOpen: boolean
  listId: string
  onClose: () => void
  taskId: string
}

const ReassignTaskModal = ({ i13n, isOpen, listId, onClose, taskId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const initialAssignee = useAppSelector((state) => getTaskAssignee(state, taskId))
  const taskPatientId = useAppSelector((state) => getTaskPatientId(state, taskId))
  const taskStatus = useAppSelector((state) => getStatusText(getTaskStatus(state, taskId)))
  const taskTitle = useAppSelector((state) => getTaskSummary(state, taskId))
  const patientCareTeam = useAppSelector((state) =>
    taskPatientId ? getPatientCareTeam(state, taskPatientId) : undefined,
  )
  let practitioners = useAppSelector(getAllPractitionerNamesAndIds, deepEquals)
  const [assignee, setAssignee] = useState(initialAssignee)
  const [note, setNote] = useState('')
  const onAccept = useCallback(() => {
    onClose()
    dispatch(assignTask({ i13n, id: taskId, listId, assignee, note }, tasksApi))
  }, [assignee, dispatch, i13n, listId, note, onClose, taskId, tasksApi])
  const onCancel = useCallback(() => {
    onClose()

    track('Reassign Modal Cancelled', {
      ...i13n,
      'Task ID': taskId,
      'Task Status': taskStatus,
      'Task Title': taskTitle,
    })
  }, [i13n, onClose, taskId, taskStatus, taskTitle])
  const onChange = useCallback((value?: string) => {
    setAssignee(value ?? '')
  }, [])

  const handleTextareaChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((event) => {
    setNote(event.target.value)
  }, [])

  useEffect(() => {
    track('Reassign Modal Shown', {
      ...i13n,
      'Task ID': taskId,
      'Task Status': taskStatus,
      'Task Title': taskTitle,
    })
    // No need to fire again when the status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (patientCareTeam) {
    practitioners = practitioners.filter(
      ({ id }) =>
        id !== patientCareTeam.coach.id && id !== patientCareTeam.pharmacist.id && id !== patientCareTeam.prescriber.id,
    )
  }

  const getPractitionerItemContent = ({ id, name }: { id: string; name: string }): ReactNode => (
    <span className="flex-1 flex justify-start items-center">
      <PractitionerAvatar practitionerId={id} size={AvatarSize.SMALL} key={id} />
      <span className="ml-2 flex-1 truncate text-left">{name || id}</span>
    </span>
  )

  const children = practitioners.map((practitioner) => (
    <Option value={practitioner.id} key={practitioner.id}>
      {getPractitionerItemContent(practitioner)}
    </Option>
  ))

  if (patientCareTeam && practitioners.length) {
    const { coach, pharmacist, prescriber } = patientCareTeam
    const hasCareTeam = coach.id || pharmacist.id || prescriber.id

    if (hasCareTeam && practitioners.length) {
      children.unshift(
        <SelectDivider key="divider1" />,
        <SelectHeading key="other-practitioners">Other practitioners</SelectHeading>,
      )
    }

    if (prescriber.id) {
      children.unshift(
        <Option value={prescriber.id} key={prescriber.id}>
          {getPractitionerItemContent(prescriber)}
        </Option>,
      )
    }

    if (pharmacist.id) {
      children.unshift(
        <Option value={pharmacist.id} key={pharmacist.id}>
          {getPractitionerItemContent(pharmacist)}
        </Option>,
      )
    }

    if (coach.id) {
      children.unshift(
        <Option value={coach.id} key={coach.id}>
          {getPractitionerItemContent(coach)}
        </Option>,
      )
    }

    if (hasCareTeam) {
      children.unshift(<SelectHeading key="patient-care-team">Patient's care team</SelectHeading>)
    }
  }

  return (
    <Confirm
      confirmButtonText="Reassign task"
      disabled={assignee === initialAssignee}
      isOpen={isOpen}
      onAccept={onAccept}
      onCancel={onCancel}
      subtitle="Select a new practitioner to reassign this task"
      title="Reassign task"
    >
      <div className="mb-10">
        <FieldSet>
          <Select className="w-[340px]" value={assignee} id="assignee" onChange={onChange} placeholder="Unassigned">
            {children}
          </Select>
          <FormLabel htmlFor="assignee">Assignee</FormLabel>
        </FieldSet>
        <FieldSet className="mt-7">
          <TextArea
            id="event-note"
            value={note}
            onChange={handleTextareaChange}
            rows={note.split(/[\n\r]/g).length || 1}
          />
          <FormLabel htmlFor="event-note">Add a note</FormLabel>
        </FieldSet>
      </div>
    </Confirm>
  )
}

export default ReassignTaskModal

import { createSelector } from 'reselect'
import { DateTime } from 'luxon'
import { RootState } from '../reducers'
import {
  BloodPressureSet,
  BloodPressureSetMap,
  BloodPressureSetsByDateTime,
  BloodPressureSetTimeOfDay,
} from '../../types/blood-pressure-set'
import { convertDateStringToDate, convertDateToDateString, formatTime } from '../../utils/dates'
import { BloodPressureMeasurement } from '../../types/blood-pressure-measurement'
import { getPatientDaysInCare } from './patient'
import { getBloodPressureMeasurementsByPatient } from './blood-pressure-measurement'

export const getBloodPressureSets = (state: RootState): BloodPressureSetMap =>
  state.bloodPressureSet.bloodPressureSetMap

export const getAllBloodPressureSets = createSelector(getBloodPressureSets, (setMap) => Object.values(setMap))

/**
 * @param bloodPressureSetId The id of the bloodPressureSet to get
 * @returns BloodPressureSet
 */
export function getBloodPressureSet(state: RootState, bloodPressureSetId: string): BloodPressureSet | null {
  return state.bloodPressureSet.bloodPressureSetMap[bloodPressureSetId] || null
}

export function getBloodPressureSetsByDateTime(state: RootState, memberId: string): BloodPressureSetsByDateTime {
  const bloodPressureSetIds = getBloodPressureSetIdsForMember(state, memberId)
  const bloodPressureSetsByDateTime: BloodPressureSetsByDateTime = {}
  const patientDaysInCare = getPatientDaysInCare(state, memberId)

  patientDaysInCare.forEach((dateString) => {
    bloodPressureSetsByDateTime[dateString] = {
      dateString,
      dayOfWeek: convertDateStringToDate(dateString).getDay(),
      am: [],
      pm: [],
    }
  })

  bloodPressureSetIds.forEach((bloodPressureSetId) => {
    const bloodPressureSet = getBloodPressureSet(state, bloodPressureSetId)
    const createdAt = new Date(bloodPressureSet!.created.at)
    const dateString = convertDateToDateString(createdAt, bloodPressureSet?.timeZone)
    const timeOfDay = getBloodPressureSetTimeOfDayForPatients(bloodPressureSet)

    if (!bloodPressureSetsByDateTime[dateString]) {
      bloodPressureSetsByDateTime[dateString] = {
        dateString,
        dayOfWeek: convertDateStringToDate(dateString).getDay(),
        am: [],
        pm: [],
      }
    }

    if (timeOfDay === BloodPressureSetTimeOfDay.MORNING) {
      bloodPressureSetsByDateTime[dateString].am.unshift(bloodPressureSetId)
    } else if (timeOfDay === BloodPressureSetTimeOfDay.EVENING) {
      bloodPressureSetsByDateTime[dateString].pm.unshift(bloodPressureSetId)
    }
  })

  return bloodPressureSetsByDateTime
}

export interface MeasurementGroup {
  comment?: string
  dateTime: string
  id: string
  isSet: boolean
  measurements: BloodPressureMeasurement[]
  timeZone: string
}

interface MeasurementDay {
  date: string
  amGroups: MeasurementGroup[]
  pmGroups: MeasurementGroup[]
}

const sortMeasurementGroups = (a: MeasurementGroup, b: MeasurementGroup) => {
  if (a.dateTime < b.dateTime) {
    return -1
  }

  if (a.dateTime > b.dateTime) {
    return 1
  }

  return 0
}

export const getBloodPressureMeasurementGroupsByDate: (state: RootState, personId: string) => MeasurementDay[] =
  createSelector(
    getPatientDaysInCare,
    getBloodPressureMeasurementsByPatient,
    getBloodPressureSets,
    (patientDaysInCare, measurements, sets) => {
      const days: Record<
        string,
        {
          amGroups: Record<string, MeasurementGroup>
          pmGroups: Record<string, MeasurementGroup>
        }
      > = {}

      // measurements are sorted in descending order, but they are supposed
      // to be displayed in ascending order inside of each day.
      // So we must add groups to the dictionary in reverse order
      ;[...measurements].reverse().forEach((measurement) => {
        const { bloodPressureSetId } = measurement

        // A measurement may have a set assigned but that set may not "exist"
        // That means the set has not been completed
        // In that case, display the measurement as ad-hoc
        // See https://coda.io/d/Product-Specifications_dNVseXilcMR/Wireless-BP-Cuffs_suNLH#_lu6J5
        if (bloodPressureSetId && sets[bloodPressureSetId]) {
          const set = sets[bloodPressureSetId]
          const setTime = DateTime.fromISO(set.created.at, {
            zone: set.timeZone,
          })
          const setDate = setTime.toFormat('yyyy-MM-dd')

          if (!days[setDate]) {
            days[setDate] = {
              amGroups: {},
              pmGroups: {},
            }
          }

          const groupMap = setTime.hour < 12 ? days[setDate].amGroups : days[setDate].pmGroups

          if (!groupMap[set.id]) {
            groupMap[set.id] = {
              comment: set.comment ?? '',
              dateTime: set.created.at,
              id: set.id,
              isSet: true,
              measurements: [],
              timeZone: set.timeZone,
            }
          }

          groupMap[set.id].measurements.push(measurement)
        } else {
          // ad-hoc case
          const measurementTime = DateTime.fromISO(measurement.created.at, {
            zone: measurement.timeZone,
          })
          const measurementDate = measurementTime.toFormat('yyyy-MM-dd')

          if (!days[measurementDate]) {
            days[measurementDate] = {
              amGroups: {},
              pmGroups: {},
            }
          }

          const groupMap = measurementTime.hour < 12 ? days[measurementDate].amGroups : days[measurementDate].pmGroups

          groupMap[measurement.id] = {
            dateTime: measurement.created.at,
            id: measurement.id,
            isSet: false,
            measurements: [measurement],
            timeZone: measurement.timeZone,
          }
        }
      })

      return patientDaysInCare.map((date) => {
        if (!days[date]) {
          return {
            date,
            amGroups: [],
            pmGroups: [],
          }
        }

        return {
          date,
          amGroups: Object.values(days[date].amGroups).sort(sortMeasurementGroups),
          pmGroups: Object.values(days[date].pmGroups).sort(sortMeasurementGroups),
        }
      })
    },
  )

/**
 * @param memberId The member that we want the list of bloodPressureSets for
 * @returns string[] A list of bloodPressureSet ids
 */
export function getBloodPressureSetIdsForMember(state: RootState, memberId: string): string[] {
  if (!state.bloodPressureSet.memberIdToBloodPressureSetIdMap[memberId]) {
    return []
  }

  return Array.from(state.bloodPressureSet.memberIdToBloodPressureSetIdMap[memberId])
}

/**
 * Given an bloodPressureSet, it returns the hours, minutes, ampm, and timeZone,
 * all in the local time denoted in the bloodPressureSet
 */
function getTimeObject(bloodPressureSet: BloodPressureSet) {
  // Gives us something like 7:36 PM MST.  Format the time because javascript date does not do timezones well.
  const time = formatTime(new Date(bloodPressureSet.created.at), bloodPressureSet.timeZone)
  const [clockTime, ampm, timeZone] = time.split(' ')

  const [hoursString, minutesString] = clockTime.split(':')
  const hours = parseInt(hoursString, 10)
  const minutes = parseInt(minutesString, 10)

  return {
    hours,
    minutes,
    ampm,
    timeZone,
  }
}

/**
 * Return Morning, Evening, or Invalid based on the time of day the bloodPressureSet took place.
 *
 * Morning: 6:00am - 11:59am
 * Evening: 12:00pm - 11:59pm
 * Invalid: 12:00am - 5:59am
 *
 * @param bloodPressureSetId The bloodPressureSet that we want to get the time of
 * @returns The time of day the bloodPressureSet took place
 */
export function getBloodPressureSetTimeOfDay(bloodPressureSet: BloodPressureSet | null): BloodPressureSetTimeOfDay {
  if (!bloodPressureSet) {
    return BloodPressureSetTimeOfDay.INVALID
  }

  const { hours, ampm } = getTimeObject(bloodPressureSet)

  // if we are in the afternoon, it automatically counts as an evening measurement
  if (ampm === 'PM') {
    return BloodPressureSetTimeOfDay.EVENING
  }

  // we're in the morning, check whether the bloodPressureSet happened between 6:00 and 11:59
  if (hours >= 6 && hours <= 11) {
    return BloodPressureSetTimeOfDay.MORNING
  }

  return BloodPressureSetTimeOfDay.INVALID
}

/**
 * Return Morning, Evening, or Invalid based on the time of day the bloodPressureSet took place.
 *
 * Morning: 6:00am - 11:59am
 * Evening: 12:00pm - 11:59pm
 * Invalid: 12:00am - 5:59am
 *
 * @param bloodPressureSetId The bloodPressureSet that we want to get the time of
 * @returns The time of day the bloodPressureSet took place
 */
export function getBloodPressureSetTimeOfDayForPatients(
  bloodPressureSet: BloodPressureSet | null,
): BloodPressureSetTimeOfDay {
  if (!bloodPressureSet) {
    return BloodPressureSetTimeOfDay.INVALID
  }

  const { ampm } = getTimeObject(bloodPressureSet)

  // if we are in the afternoon, it automatically counts as an evening measurement
  if (ampm === 'PM') {
    return BloodPressureSetTimeOfDay.EVENING
  }

  return BloodPressureSetTimeOfDay.MORNING
}

import { ReactElement, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getNoteRecordState, getNoteIds, getNote } from '../../../../redux/selectors/note'
import { fetchNotesForPatient } from '../../../../redux/thunks/note'
import { RecordState } from '../../../../types/record-state'
import { EmptyState } from '../../../ui/empty-state'
import { LoadingIndicator, LoadingIndicatorSize } from '../../../ui/loading-indicator'
import PatientNoteInput from './patient-note-input'
import PatientNoteItem from './patient-note-item'

interface Props {
  patientId: string
}

const Notes = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const recordState = useAppSelector((state) => getNoteRecordState(state, patientId))
  const noteIds = useAppSelector((state) => getNoteIds(state, patientId))

  useEffect(() => {
    dispatch(fetchNotesForPatient(patientId))
  }, [dispatch, patientId])

  if (recordState === RecordState.DOES_NOT_EXIST || recordState === RecordState.LOADING) {
    return <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
  }

  if (!noteIds?.length) {
    return (
      <>
        <div className="flex-1 bg-white">
          <EmptyState header="Nothing to see yet" subtext="Enjoy some tea in the meantime" borderless />
        </div>
        <PatientNoteInput autofocus={true} patientId={patientId} />
      </>
    )
  }

  return (
    <>
      <div className="flex-1 overflow-y-auto p-7">
        {noteIds.map((noteId: string) => (
          <Note key={noteId} noteId={noteId} />
        ))}
      </div>
      <PatientNoteInput autofocus={true} patientId={patientId} />
    </>
  )
}

function Note({ noteId }: { noteId: string }) {
  const note = useAppSelector((state) => getNote(state, noteId))

  if (!note) return null

  return <PatientNoteItem note={note} />
}

export default Notes

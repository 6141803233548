import {
  FETCH_CHAT_TOKEN_FAILED,
  FETCH_CHAT_TOKEN_FULFILLED,
  FetchChatTokenFailed,
  FetchChatTokenFulfilled,
  FETCHING_CHAT_TOKEN,
  FetchingChatToken,
} from '../../types/chat'

export function fetchingChatToken(): FetchingChatToken {
  return {
    type: FETCHING_CHAT_TOKEN,
    payload: undefined,
  }
}

export function fetchChatTokenFulfilled(token: string, expiresAt: number): FetchChatTokenFulfilled {
  return {
    type: FETCH_CHAT_TOKEN_FULFILLED,
    payload: {
      token,
      expiresAt,
    },
  }
}

export function fetchChatTokenFailed(error: Error): FetchChatTokenFailed {
  return {
    type: FETCH_CHAT_TOKEN_FAILED,
    payload: {
      error,
    },
  }
}

import clsx from 'clsx'
import { ReactElement } from 'react'
import { useAppSelector } from '../../redux'
import { getLoggedInPractitionerId } from '../../redux/selectors/app'
import { getPractitionerName } from '../../redux/selectors/practitioner'

interface Props {
  className?: string
  practitionerId: string
}

const PractitionerReference = ({ className, practitionerId }: Props): ReactElement => {
  const loggedInPractitionerId = useAppSelector(getLoggedInPractitionerId)
  const name = useAppSelector((state) => getPractitionerName(state, practitionerId))

  const displayName = practitionerId === loggedInPractitionerId ? 'you' : name

  return (
    <strong
      className={clsx(
        'font-semibold',
        {
          'first:capitalize': practitionerId === loggedInPractitionerId,
        },
        className,
      )}
      data-practitionerid={practitionerId}
    >
      {displayName}
    </strong>
  )
}

export default PractitionerReference

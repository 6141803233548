import { ReactElement, useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import useGetListIdFromRoute from '../../../../hooks/use-get-list-id-from-route'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { fetchPatients } from '../../../../redux/thunks/patient'
import { getListItemIds, getListRecordState, getListTotalCount } from '../../../../redux/selectors/lists'
import Paging from '../../../ui/paging'
import { RecordState } from '../../../../types/record-state'
import { LoadingIndicator } from '../../../ui/loading-indicator'
import ErrorState from '../../../views/patient-profile/error-state'
import EmptyState from '../../../views/patient-profile/empty-state'
import { PatientView } from '../patient-view'
import { Routes } from '../../../../types/route'
import PatientListHeader from './patient-list-header'
import { PatientList } from './patient-list'

const PatientListPaged = (): ReactElement => {
  const [listScrolled, setListScrolled] = useState(false)
  const { url } = useRouteMatch()
  const dispatch = useAppDispatch()
  const listId = useGetListIdFromRoute()
  const patientIds = useAppSelector((state) => getListItemIds(state, listId))
  const recordState = useAppSelector((state) => getListRecordState(state, listId))
  const totalCount = useAppSelector((state) => getListTotalCount(state, listId))

  useEffect(() => {
    dispatch(fetchPatients(listId))
  }, [dispatch, listId])

  useEffect(() => {
    const page = document.getElementById('page-content')

    if (!page) {
      return
    }

    const handler = () => {
      setListScrolled(page.scrollTop > 0)
    }

    page.addEventListener('scroll', handler)

    return () => {
      page.removeEventListener('scroll', handler)
    }
  }, [])

  useEffect(() => {
    document.getElementById('page-content')?.scrollTo?.(0, 0)
  }, [/* do not remove, this scrolls to the top when the list changes */ listId])

  return (
    <div className="bg-white min-h-full flex flex-col">
      <PatientListHeader listId={listId} />
      {typeof totalCount !== 'number' && recordState === RecordState.ERRORED ? (
        <ErrorState />
      ) : typeof totalCount !== 'number' && recordState === RecordState.LOADING ? (
        <LoadingIndicator flex />
      ) : !patientIds.length && recordState === RecordState.LOADED ? (
        <div className="flex-1 flex items-center justify-center">
          <EmptyState />
        </div>
      ) : (
        <PatientList
          bodyClassName="px-3"
          headerClassName={clsx('px-3 sticky top-[121px] border-b', {
            'border-transparent': !listScrolled,
            'border-rivaOffblack-100 bg-white': listScrolled,
          })}
          patientIds={patientIds}
          route={Routes.PATIENT_LIST}
        />
      )}
      <Paging listId={listId} />
      <Switch>
        <Route path={`${url}/:patientId`} component={PatientView}></Route>
      </Switch>
    </div>
  )
}

export default PatientListPaged

import { RecordState } from './record-state'

export const FETCHING_CHAT_TOKEN = 'FETCHING_CHAT_TOKEN'
export const FETCH_CHAT_TOKEN_FULFILLED = 'FETCH_CHAT_TOKEN_FULFILLED'
export const FETCH_CHAT_TOKEN_FAILED = 'FETCH_CHAT_TOKEN_FAILED'

export interface FetchingChatToken {
  type: typeof FETCHING_CHAT_TOKEN
  payload: undefined
}

export interface FetchChatTokenFulfilled {
  type: typeof FETCH_CHAT_TOKEN_FULFILLED
  payload: {
    token: string
    expiresAt: number
  }
}

export interface FetchChatTokenFailed {
  type: typeof FETCH_CHAT_TOKEN_FAILED
  payload: {
    error: Error
  }
}

export interface ChatState {
  recordState: RecordState
  token: string
  expiresAt: number
}

export interface ChatTokenResponse {
  token: string
  expiresAt: number
}

import clsx from 'clsx'
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, FunctionComponent } from 'react'
import { IconSize, SvgIcon } from './icon'

interface Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  borderless?: boolean
  colorVariant?: 'normal' | 'inverted' | 'transparent'
  icon: FunctionComponent
  pressed?: boolean
  rounded?: boolean
  size: IconSize | 'XXS'
}

const IconButton = forwardRef<HTMLButtonElement, Props>(function IconButton(
  { borderless, className, colorVariant = 'normal', icon, pressed, rounded, size, ...props },
  ref,
) {
  return (
    <button
      {...props}
      aria-pressed={pressed}
      className={clsx(
        'inline-flex items-center justify-center transition-all duration-200 outline-none text-rivaOffblack-900 box-border focus-visible:border-rivaOffblack-900 disabled:border-transparent disabled:bg-transparent disabled:text-rivaOffblack-400',
        className,
        {
          'border-2': !borderless,
          'hover:bg-rivaOffblack-50 border-white hover:border-rivaOffblack-200 active:bg-rivaOffblack-200 active:border-rivaOffblack-200':
            colorVariant === 'normal' && !pressed,
          'bg-rivaOffblack-200 border-rivaOffblack-200': colorVariant === 'normal' && pressed,
          'hover:bg-white/10 border-white/0 hover:border-white/25 active:bg-white/25 active:border-white/25 text-rivaPurple-300 hover:text-rivaPurple-100':
            colorVariant === 'inverted' && !pressed,
          'bg-white/25 border-white/25 text-rivaPurple-100': colorVariant === 'inverted' && pressed,
          'bg-transparent hover:bg-rivaOffblack-100 active:bg-rivaOffblack-200':
            colorVariant === 'transparent' && !pressed,
          'bg-rivaOffblack-200': colorVariant === 'transparent' && pressed,
          'h-4 w-4': size === 'XXS',
          'h-6 w-6': size === IconSize.X_SMALL,
          'h-7 w-7': size === IconSize.SMALL,
          'h-8 w-9': size === IconSize.MEDIUM,
          'rounded-full': rounded,
          rounded: !rounded && size !== IconSize.MEDIUM,
          'rounded-md': !rounded && size === IconSize.MEDIUM,
        },
      )}
      ref={ref}
    >
      <SvgIcon Icon={icon} size={size === 'XXS' ? IconSize.X_SMALL : size} />
    </button>
  )
})

export default IconButton

import { JSONSchemaType } from 'ajv'
import {
  TaskEvent,
  TaskEventAddNote,
  TaskEventAssignTask,
  TaskEventCancelPreviousEvent,
  TaskEventCompleteWithChoice,
  TaskEventCreateTask,
  TaskEventSetTaskDueDate,
  TaskEventUpdateTaskStatus,
  TaskEventVisitCancelled,
  TaskEventVisitRescheduled,
  UnknownTaskEvent,
} from '../../types/task'
import { TaskEventType } from '../../__generated__/graphql'
import { auditStatusSchema } from './audit-status'

export const unknownTaskEventSchema: JSONSchemaType<UnknownTaskEvent> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      required: [],
      nullable: true,
    },
    eventType: {
      type: 'string',
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType'],
}

export const taskEventCreateTaskSchema: JSONSchemaType<TaskEventCreateTask> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        status: {
          type: 'string',
        },
        taskType: {
          type: 'string',
        },
      },
      required: ['status', 'taskType'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.CreateTask,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

export const taskEventUpdateTaskStatusSchema: JSONSchemaType<TaskEventUpdateTaskStatus> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        taskStatus: {
          type: 'string',
        },
      },
      required: ['taskStatus'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.UpdateTaskStatus,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

export const taskEventAssignTaskSchema: JSONSchemaType<TaskEventAssignTask> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        assignee: {
          type: 'string',
        },
      },
      required: ['assignee'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.AssignTask,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

export const taskEventSetTaskDueDate: JSONSchemaType<TaskEventSetTaskDueDate> = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
    created: auditStatusSchema,
    eventType: {
      type: 'string',
      const: TaskEventType.SetDueDate,
    },
    note: {
      type: 'string',
    },
    data: {
      type: 'object',
      properties: {
        dueDate: {
          type: 'string',
        },
      },
      required: ['dueDate'],
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

export const taskEventAddNote: JSONSchemaType<TaskEventAddNote> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        body: {
          type: 'string',
        },
      },
      required: ['body'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.AddNote,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

export const taskEventCancelPreviousEventSchema: JSONSchemaType<TaskEventCancelPreviousEvent> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        eventId: {
          type: 'string',
        },
      },
      required: ['eventId'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.CancelEvent,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

export const taskEventVisitCancelledSchema: JSONSchemaType<TaskEventVisitCancelled> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        visitType: {
          type: 'string',
        },
      },
      required: ['visitType'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.VisitCancelled,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

export const taskEventVisitRescheduledSchema: JSONSchemaType<TaskEventVisitRescheduled> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        endTime: {
          type: 'string',
        },
        startTime: {
          type: 'string',
        },
        visitType: {
          type: 'string',
        },
      },
      required: ['endTime', 'startTime', 'visitType'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.VisitRescheduled,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

const taskEventCompleteWithChoiceSchema: JSONSchemaType<TaskEventCompleteWithChoice> = {
  type: 'object',
  properties: {
    created: auditStatusSchema,
    data: {
      type: 'object',
      properties: {
        choice: {
          type: 'string',
        },
      },
      required: ['choice'],
    },
    eventType: {
      type: 'string',
      const: TaskEventType.CompleteWithChoice,
    },
    id: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'taskId', 'created', 'eventType', 'data'],
}

export const taskEventSchema: JSONSchemaType<TaskEvent> = {
  type: 'object',
  oneOf: [
    taskEventCreateTaskSchema,
    taskEventUpdateTaskStatusSchema,
    taskEventAssignTaskSchema,
    taskEventSetTaskDueDate,
    taskEventAddNote,
    taskEventCancelPreviousEventSchema,
    taskEventVisitCancelledSchema,
    taskEventVisitRescheduledSchema,
    taskEventCompleteWithChoiceSchema,
  ],
}

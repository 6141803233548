import { ReactElement } from 'react'
import { TaskStatus } from '../../../../types/task'
import { getStatusIcon, getStatusText } from '../../../../utils/tasks'
import { IconSize, SvgIcon } from '../../../ui/icon'
import Pill from '../../../ui/pill'

interface Props {
  isOnDarkBackground?: boolean
  selected?: boolean
  status: TaskStatus
}

const StatusPill = ({ isOnDarkBackground, selected, status }: Props): ReactElement => {
  return (
    <Pill
      isOnDarkBackground={isOnDarkBackground}
      variant={
        status === TaskStatus.TaskCompleted || status === TaskStatus.TaskCompleting
          ? 'success'
          : selected
          ? 'inverted'
          : 'normal'
      }
    >
      <SvgIcon Icon={getStatusIcon(status)} size={IconSize.X_SMALL} />
      <span className="ml-1">{getStatusText(status)}</span>
    </Pill>
  )
}

export default StatusPill

export enum FormAction {
  INITIALIZED = 'INITIALIZED',
  SUBMITTING = 'SUBMITTING',
  SUBMITTED = 'SUBMITTED',
  ERRORED = 'ERRORED',
}

export interface FormActions {
  [FormAction.INITIALIZED]: string[]
  [FormAction.SUBMITTING]: string[]
  [FormAction.SUBMITTED]: string[]
  [FormAction.ERRORED]: string[]
}

export enum FormSubmissionState {
  NONE = 'NONE',
  INITIALIZED = 'INITIALIZED',
  SUBMITTING = 'SUBMITTING',
  SUBMITTED = 'SUBMITTED',
  ERRORED = 'ERRORED',
}

export enum FormIds {
  CREATE_NOTE = 'createNoteForm',
  CREATE_PATIENT = 'createPatientForm',
  UPDATE_CUFF_READING = 'updateCuffReadingForm',
  UPDATE_PATIENT = 'updatePatientForm',
  DEACTIVATE_PATIENT = 'deactivatePatientForm',
  ACTIVATE_PATIENT = 'activatePatientForm',
  UPDATE_BLOOD_PRESSURE_MANAGEMENT_STATUS = 'updateBloodPressureManagementStatus',
}

import { combineReducers, Reducer } from 'redux'
import { FormIds } from '../../../types/form'
import {
  Action,
  DEBUG_PROFILE_TOGGLED,
  PATIENT_VIEW_HEADER_EXPANSION_TOGGLED,
  PATIENT_VIEW_SUMMARY_EXPANSION_TOGGLED,
} from '../../../types/actions'
import { createNoteForm } from './forms/create-note'
import { updateCuffReadingForm } from './forms/update-cuff-reading'
import { createPatientForm } from './forms/create-patient'
import { updatePatientForm } from './forms/update-patient'
import { deactivatePatientForm } from './forms/deactivate-patient'
import { activatePatientForm } from './forms/activate-patient'
import { updateBloodPressureManagementStatusForm } from './forms/update-blood-pressure-management-status'
import toasts from './toast'

const forms = combineReducers({
  [FormIds.CREATE_NOTE]: createNoteForm,
  [FormIds.CREATE_PATIENT]: createPatientForm,
  [FormIds.UPDATE_CUFF_READING]: updateCuffReadingForm,
  [FormIds.UPDATE_PATIENT]: updatePatientForm,
  [FormIds.DEACTIVATE_PATIENT]: deactivatePatientForm,
  [FormIds.ACTIVATE_PATIENT]: activatePatientForm,
  [FormIds.UPDATE_BLOOD_PRESSURE_MANAGEMENT_STATUS]: updateBloodPressureManagementStatusForm,
})

const debug: Reducer<{ profile: boolean }, Action> = (state = { profile: false }, action) => {
  switch (action.type) {
    case DEBUG_PROFILE_TOGGLED:
      return {
        ...state,
        profile: !state.profile,
      }
    default:
      return state
  }
}

const patientView: Reducer<{ summaryExpanded: boolean; headerExpanded: boolean }, Action> = (
  state = { summaryExpanded: false, headerExpanded: false },
  action,
) => {
  switch (action.type) {
    case PATIENT_VIEW_HEADER_EXPANSION_TOGGLED:
      return {
        ...state,
        headerExpanded: !state.headerExpanded,
      }
    case PATIENT_VIEW_SUMMARY_EXPANSION_TOGGLED:
      return {
        ...state,
        summaryExpanded: !state.summaryExpanded,
      }
    default:
      return state
  }
}

export const ui = combineReducers({
  debug,
  patientView,
  forms,
  toasts,
})

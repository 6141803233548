import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode | ReactNode[]
  invalid?: boolean
  labelledby?: string
  name: string
}

const RadioGroup = ({ children, invalid, ...props }: Props): ReactElement => {
  return (
    <div
      {...props}
      className={clsx('border border-solid rounded py-2', {
        'border-rivaOffblack-300': !invalid,
        'border-rivaFuchsia-300 bg-rivaFuchsia-50 group radio-group-invalid': invalid,
      })}
      role="radiogroup"
    >
      {children}
    </div>
  )
}

export default RadioGroup

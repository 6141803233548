import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  active?: boolean
  'aria-labelledby'?: string
  children: ReactNode
  className?: string
  id?: string
}

const TabPanel = ({ active, children, className, ...a11yProps }: Props): ReactElement => (
  <div
    {...a11yProps}
    role="tabpanel"
    className={clsx(className, {
      hidden: !active,
    })}
    hidden={!active}
  >
    {active ? children : null}
  </div>
)

export default TabPanel

import { FormAction } from '../../../../types/form'
import formReducerFactory from '../form-reducer-factory'
import {
  DEACTIVATE_PATIENT_FAILED,
  DEACTIVATE_PATIENT_FORM_INITIALIZED,
  DEACTIVATING_PATIENT,
  DEACTIVATE_PATIENT_FULFILLED,
} from '../../../../types/patient'

export const deactivatePatientForm = formReducerFactory({
  [FormAction.INITIALIZED]: [DEACTIVATE_PATIENT_FORM_INITIALIZED],
  [FormAction.SUBMITTING]: [DEACTIVATING_PATIENT],
  [FormAction.SUBMITTED]: [DEACTIVATE_PATIENT_FULFILLED],
  [FormAction.ERRORED]: [DEACTIVATE_PATIENT_FAILED],
})

import { MouseEventHandler, ReactElement, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import { useAppSelector } from '../../../../redux'
import {
  getPatientDateOfBirth,
  getPatientFirstName,
  getPatientLastName,
  getPatientMedicalRecordNumber,
  getPatientMedicalRecordSource,
  getPatientTimeZone,
} from '../../../../redux/selectors/patient'
import ehrRecordQuery from '../../../../schemas/ehr-record-query'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import { MedicalRecordSource } from '../../../../types/patient'
import EHRPatientLink from '../../../ui/ehr-patient-link'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as ExclamationIcon } from '../../../icons/solid/exclamation.svg'
import FormattedDateTime from '../../../ui/formatted-date-time'
import { I13n } from '../../../../types/i13n'
import { track } from '../../../../i13n'

interface Props {
  i13n?: Partial<I13n>
  patientId: string
}

const MedicalRecordNumber = ({ i13n, patientId }: Props): ReactElement => {
  const rivaFirstName = useAppSelector((state) => getPatientFirstName(state, patientId))
  const rivaLastName = useAppSelector((state) => getPatientLastName(state, patientId))
  const rivaBirthDate = useAppSelector((state) => getPatientDateOfBirth(state, patientId))
  const timeZone = useAppSelector((state) => getPatientTimeZone(state, patientId))
  const medicalRecordNumber = useAppSelector((state) => getPatientMedicalRecordNumber(state, patientId))
  const medicalRecordSource = useAppSelector((state) => getPatientMedicalRecordSource(state, patientId))
  const skip = medicalRecordSource !== MedicalRecordSource.ATHENA || medicalRecordNumber === ''
  const { data, loading, error } = useQuery(ehrRecordQuery, {
    skip,
    variables: {
      medicalRecordNumber,
    },
  })
  const disabled = skip || !!error || (!data && loading)
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    disabled,
    offset: [12, 8],
    placement: 'bottom-end',
  })
  const name = data?.ehrRecord?.name?.[0]
  const ehrFirstName = name?.given?.join(' ').trim() ?? ''
  const ehrLastName = name?.family?.trim() ?? ''
  const nameIncorrect = rivaFirstName !== ehrFirstName || rivaLastName !== ehrLastName
  const ehrBirthDate = data?.ehrRecord?.birthDate ?? ''

  const rivaBirthDateTime = DateTime.fromISO(rivaBirthDate)
  const ehrBirthDateTime = DateTime.fromISO(ehrBirthDate)
  const birthDateIncorrect =
    !ehrBirthDateTime.isValid || Math.abs(rivaBirthDateTime.diff(ehrBirthDateTime, 'days').days) >= 1
  const onClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()

      track(i13n?.eventName ?? 'Clicked MRN', {
        ...i13n?.properties,
        Patientid: patientId,
        service: 'Athena',
      })
    },
    [i13n?.eventName, i13n?.properties, patientId],
  )

  const isPatientMrnLinkEnabled = true

  return (
    <>
      {medicalRecordNumber && isPatientMrnLinkEnabled ? (
        <EHRPatientLink
          {...getTriggerProps()}
          className={clsx('tabular-nums', {
            'text-rivaPurple-500 hover:text-rivaPurple-600 active:text-rivaPurple-800 underline cursor-pointer': !skip,
            'cursor-default': skip,
          })}
          medicalRecordNumber={medicalRecordNumber}
          medicalRecordSource={medicalRecordSource}
          onClick={onClick}
        >
          <span>{medicalRecordNumber}</span>
        </EHRPatientLink>
      ) : (
        <span {...getTriggerProps()}>{medicalRecordNumber}</span>
      )}
      <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
        <header className="py-3">
          <h5 className="uppercase text-xxs font-extrabold text-rivaOffblack-400">
            {medicalRecordSource !== MedicalRecordSource.UNKNOWN ? medicalRecordSource : ''} medical record #
            {medicalRecordNumber}
          </h5>
        </header>
        <main className="flex pb-3">
          <div className="mr-10 max-w-[200px] tabular-nums">
            <dl>
              <dt className="capitalize text-xxs font-extrabold text-rivaOffblack-400">Patient name</dt>
              <dd
                className={clsx('text-sm font-medium text-white', {
                  'bg-rivaOffblack-600': nameIncorrect,
                })}
              >
                {ehrFirstName} {ehrLastName}
              </dd>
            </dl>
          </div>
          <div>
            <dl>
              <dt className="capitalize text-xxs font-extrabold text-rivaOffblack-400">Patient name</dt>
              <dd
                className={clsx('text-sm font-medium text-white tabular-nums', {
                  'bg-rivaOffblack-600': birthDateIncorrect,
                })}
              >
                {ehrBirthDate ? (
                  <FormattedDateTime
                    value={ehrBirthDate}
                    day="numeric"
                    month="numeric"
                    year="numeric"
                    timeZone={timeZone || undefined}
                  />
                ) : null}
              </dd>
            </dl>
          </div>
        </main>
        {nameIncorrect || birthDateIncorrect ? (
          <footer className="relative text-rivaGold-400 text-xxs font-medium pb-3">
            <div className="bg-rivaOffblack-700 h-[1px] mb-3 relative -left-3 w-[calc(100%+24px)]" />
            {nameIncorrect ? (
              <div className="flex items-center mb-2 last:mb-0">
                <SvgIcon Icon={ExclamationIcon} size={IconSize.X_SMALL} />
                <span>The patient's name does not match the medical record.</span>
              </div>
            ) : null}
            {birthDateIncorrect ? (
              <div className="flex items-center">
                <SvgIcon Icon={ExclamationIcon} size={IconSize.X_SMALL} />{' '}
                <span>The patient's date of birth does not match the medical record.</span>
              </div>
            ) : null}
          </footer>
        ) : null}
      </Tooltip>
    </>
  )
}

export default MedicalRecordNumber

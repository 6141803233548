export class ApiError extends Error {
  statusCode: number

  statusText: string

  metadata?: Record<string, unknown>

  constructor(statusCode: number, statusText: string, message?: string, metadata?: Record<string, unknown>) {
    super(message ?? 'API Error')

    this.statusCode = statusCode
    this.statusText = statusText
    this.metadata = metadata
  }
}

export enum HttpHeaders {
  AUTHORIZATION = 'Authorization',
  CONTENT_TYPE = 'Content-Type',
  ETAG = 'ETag',
  IF_NONE_MATCH = 'If-None-Match',
  X_CSRF_TOKEN = 'X-CSRF-Token',
  X_REQUEST_ID = 'X-Request-Id',
}

interface Options {
  accessToken: string
  headers?: HeadersInit
  requestId: string
}

function getHeaders(token: string, requestId: string, other?: HeadersInit): HeadersInit {
  return {
    ...other,
    [HttpHeaders.CONTENT_TYPE]: 'application/json',
    [HttpHeaders.AUTHORIZATION]: `Bearer ${token}`,
    [HttpHeaders.X_CSRF_TOKEN]: 'true',
    [HttpHeaders.X_REQUEST_ID]: requestId,
  }
}

function getBaseUrl() {
  return process.env.REACT_APP_API_BASE_URL || ''
}

function combineUrls(baseUrl: string, relativeUrl: string) {
  return relativeUrl ? baseUrl.replace(/\/+$/, '') + '/' + relativeUrl.replace(/^\/+/, '') : baseUrl
}

export interface HttpResponse {
  body: unknown
  headers: Headers
  statusCode: number
}

export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

const httpFetch = async (
  method: HttpMethod,
  url: string,
  body: unknown,
  { accessToken, headers: extraHeaders, requestId }: Options,
): Promise<HttpResponse> => {
  const fullUrl = combineUrls(getBaseUrl(), url)
  const headers = getHeaders(accessToken, requestId, extraHeaders)

  const res = await window.fetch(fullUrl, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  })

  if (!res.ok) {
    if (res.status === 304) {
      throw new ApiError(res.status, res.statusText)
    }

    let resBody: Record<string, unknown>

    try {
      resBody = await res.json()
    } catch (error) {
      throw new ApiError(res.status, res.statusText, (error as Error).message)
    }

    if (resBody.failures) {
      throw new ApiError(res.status, res.statusText, (resBody.failures as string[])[0])
    }

    throw new ApiError(res.status, res.statusText, JSON.stringify(resBody))
  }

  let resBody: Record<string, unknown>

  try {
    resBody = await res.json()
  } catch (error) {
    throw new ApiError(res.status, res.statusText, (error as Error).message)
  }

  return {
    body: resBody,
    headers: res.headers,
    statusCode: res.status,
  }
}

export const get = async (url: string, options: Options): Promise<HttpResponse> => {
  // only replace `|` because the AWS Gateway doesn't like it
  // we prob don't have to do any other encodeURI. Callers doing `queryString.stringify` would get double encoded.
  url = url.replaceAll('|', '%7C')

  return httpFetch('GET', url, undefined, options)
}

export const post = async (url: string, body: unknown, options: Options): Promise<HttpResponse> => {
  return httpFetch('POST', url, body, options)
}

export const patch = async (url: string, body: unknown, options: Options): Promise<HttpResponse> => {
  return httpFetch('PATCH', url, body, options)
}

export const put = async (url: string, body: unknown, options: Options): Promise<HttpResponse> => {
  return httpFetch('PUT', url, body, options)
}

export const del = async (url: string, options: Options): Promise<HttpResponse> => {
  return httpFetch('DELETE', url, undefined, options)
}

import { ReactElement, ReactNode, useState } from 'react'
import { TASK_DETAILS_TASK_EVENT, TASK_DETAILS_TASK_NOTE } from '../../../../consts/data-testids'
import { IconSize, SvgIcon } from '../../../ui/icon'
import PractitionerAvatar, { AvatarSize } from '../../../ui/practitioner-avatar'
import { ReactComponent as MagicWandIcon } from '../../../icons/outline/magic-wand.svg'
import { ReactComponent as PencilAltIcon } from '../../../icons/outline/pencil-alt.svg'
import NaturalTime from '../../../ui/natural-time'
import { Button, ButtonVariant, ButtonSize } from '../../../ui/button'
import { useAppSelector } from '../../../../redux'
import { getLoggedInPractitionerId } from '../../../../redux/selectors/app'
import PatientNoteEditInput from './patient-note-edit-input'

interface Props {
  children: ReactNode
  createdAt: string
  id: string
  kind: 'event' | 'note'
  note?: string
  practitionerId: string
}

const PatientActivityItem = ({ children, createdAt, id, kind, note, practitionerId }: Props): ReactElement => {
  const [isEditing, setIsEditing] = useState(false)
  const loggedInPractitionerId = useAppSelector(getLoggedInPractitionerId)

  return (
    <div
      className="mt-3 first:mt-0 grid gap-x-3 gap-y-2 grid-cols-[40px_1fr_100px] text-sm font-medium"
      data-id={id}
      data-testid={kind === 'note' ? TASK_DETAILS_TASK_NOTE : TASK_DETAILS_TASK_EVENT}
    >
      <div className="row-span-3 flex flex-col">
        {practitionerId ? (
          <PractitionerAvatar border practitionerId={practitionerId} size={AvatarSize.XL} />
        ) : (
          <div className="bg-rivaBlue-400 rounded-full w-10 h-10 box-border border-2 border-rivaOffblack-200 flex items-center justify-center text-white overflow-hidden">
            <SvgIcon Icon={MagicWandIcon} />
          </div>
        )}
        <div className="w-5 flex-1 border-r border-dashed border-rivaOffblack-300 group-last:hidden"></div>
      </div>
      <div>{children}</div>
      <NaturalTime className="text-right text-rivaOffblack-500" value={createdAt} />
      {note ? (
        isEditing ? (
          <div className="flex flex-col col-span-2">
            <PatientNoteEditInput autofocus noteId={id} initialText={note} onClose={() => setIsEditing(false)} />
          </div>
        ) : (
          <div className="flex flex-col bg-rivaOffblack-100 rounded p-3 col-span-2">
            <p className="whitespace-pre-wrap break-words">{note}</p>
            {loggedInPractitionerId === practitionerId ? (
              <Button
                className="self-end"
                onClick={() => setIsEditing(true)}
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.XXS}
              >
                <SvgIcon size={IconSize.SMALL} Icon={PencilAltIcon} />
                <span className="pl-1">Edit</span>
              </Button>
            ) : null}
          </div>
        )
      ) : null}
    </div>
  )
}

export default PatientActivityItem

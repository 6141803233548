import { ReactElement, useCallback, useState } from 'react'
import useTasksApi from '../../../../api/hooks/use-tasks-api'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getListSelectedItemId } from '../../../../redux/selectors/lists'
import { getTaskById } from '../../../../redux/selectors/tasks'
import { updateTaskStatus } from '../../../../redux/thunks/tasks'
import { TaskStatus, VisibleTaskStatuses } from '../../../../types/task'
import { getStatusKeyboardHint, getStatusText } from '../../../../utils/tasks'
import StatusNoteModal from '../../../modals/status-note-modal'
import Command from '../../../ui/command'
import MousetrapHint from '../../../ui/mousetrap-hint'
import { CHANGE_STATUS_KEYS } from './keys'

interface Props {
  listId: string
}

function identity<T>(x: T): T {
  return x
}

const StatusCommand = ({ listId }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const taskId = useAppSelector((state) => getListSelectedItemId(state, listId) ?? '')
  const task = useAppSelector((state) => (taskId ? getTaskById(state, taskId) : undefined))
  const [openModal, setOpenModal] = useState<TaskStatus.TaskNa | TaskStatus.TaskCompletingSkipping | undefined>()

  const onSelected = useCallback(
    (status: TaskStatus) => {
      if (status === TaskStatus.TaskNa || status === TaskStatus.TaskCompletingSkipping) {
        setOpenModal(status)

        return
      }

      dispatch(
        updateTaskStatus(
          {
            id: taskId,
            listId,
            status,
          },
          tasksApi,
        ),
      )
    },
    [dispatch, listId, taskId, tasksApi],
  )
  const renderItem = useCallback(
    (status: TaskStatus) => (
      <div data-status={status} className="flex items-center w-full">
        <span className="flex-1">{getStatusText(status)}</span>
        <MousetrapHint keys={getStatusKeyboardHint(status)} variant="dark" />
      </div>
    ),
    [],
  )
  const onModalClose = useCallback(() => {
    setOpenModal(undefined)
  }, [])

  return (
    <>
      <Command
        command={CHANGE_STATUS_KEYS}
        entity={
          <div className="h-6 px-2 bg-rivaOffblack-100 text-xs leading-6 text-rivaOffblack-600 rounded inline-block">{`Task - ${task?.id} ${task?.summary}`}</div>
        }
        items={VisibleTaskStatuses}
        itemKey={identity}
        itemToString={getStatusText}
        listId={listId}
        onSelected={onSelected}
        placeholder="Change status..."
        renderItem={renderItem}
      />
      {typeof openModal !== 'undefined' ? (
        <StatusNoteModal
          key={openModal}
          isOpen
          listId={listId}
          onAccept={onModalClose}
          onCancel={onModalClose}
          status={openModal}
          taskId={taskId}
        />
      ) : null}
    </>
  )
}

export default StatusCommand

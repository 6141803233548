import { ReactElement } from 'react'
import { Radio, RadioGroup, RadioLabel, useRadioGroup } from '../radio'
import FormLabel from '../form-label'

type ConsentString = 'Yes' | 'No'

interface Props {
  label: string
  onChange: (value: ConsentString) => void
  required?: boolean
  value?: ConsentString
}

const ConsentRadioGroup = ({ label, onChange, required, value }: Props): ReactElement => {
  const { getGroupLabelProps, getRadioGroupProps, getRadioProps } = useRadioGroup<ConsentString>({
    onChange,
    required,
    value,
  })

  return (
    <>
      <FormLabel {...getGroupLabelProps()} order={0}>
        {label}
      </FormLabel>
      <RadioGroup {...getRadioGroupProps()}>
        <RadioLabel label="Yes">
          <Radio {...getRadioProps('Yes')} />
        </RadioLabel>
        <RadioLabel label="No">
          <Radio {...getRadioProps('No')} />
        </RadioLabel>
      </RadioGroup>
    </>
  )
}

export default ConsentRadioGroup

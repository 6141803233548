import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useMemo } from 'react'
import { DropLastParam } from '../../../types/utils'
import createCarePathwayMethod from './create-care-pathway-event'
import createEventMethod from './create-event'
import createTaskEventMethod from './create-task-event'
import createUnknownTaskEventMethod from './create-unknown-task-event'
import getTaskByIdMethod from './get-task-by-id'
import getTasksMethod from './get-tasks'

export interface TasksApi {
  createCarePathwayEvent: DropLastParam<typeof createCarePathwayMethod>
  createEvent: DropLastParam<typeof createEventMethod>
  createTaskEvent: DropLastParam<typeof createTaskEventMethod>
  createUnknownTaskEvent: DropLastParam<typeof createUnknownTaskEventMethod>
  getTaskById: DropLastParam<typeof getTaskByIdMethod>
  getTasks: DropLastParam<typeof getTasksMethod>
}

const useTasksApi = (): TasksApi => {
  const { getAccessTokenSilently } = useAuth0()

  const createCarePathwayEvent = useCallback<TasksApi['createCarePathwayEvent']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return createCarePathwayMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  const createEvent = useCallback<TasksApi['createEvent']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return createEventMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  const createTaskEvent = useCallback<TasksApi['createTaskEvent']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return createTaskEventMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  const createUnknownTaskEvent = useCallback<TasksApi['createUnknownTaskEvent']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return createUnknownTaskEventMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  const getTaskById = useCallback<TasksApi['getTaskById']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return getTaskByIdMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  const getTasks = useCallback<TasksApi['getTasks']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return getTasksMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  return useMemo(
    () => ({
      createCarePathwayEvent,
      createEvent,
      createTaskEvent,
      createUnknownTaskEvent,
      getTaskById,
      getTasks,
    }),
    [createCarePathwayEvent, createEvent, createTaskEvent, createUnknownTaskEvent, getTaskById, getTasks],
  )
}

export default useTasksApi

import { AppThunk } from '../reducers'
import {
  fetchBloodPressureAveragesFailed,
  fetchBloodPressureAveragesFulfilled,
  fetchingBloodPressureAverages,
} from '../actions/blood-pressure-averages'
import { getBloodPressureAverageRecordState } from '../selectors/blood-pressure-average'
import { RecordState } from '../../types/record-state'

export const fetchBloodPressureAverages =
  (patientId: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    const state = getState()

    const recordState = getBloodPressureAverageRecordState(state, patientId)

    if (recordState === RecordState.LOADING || recordState === RecordState.LOADED) {
      return
    }

    dispatch(fetchingBloodPressureAverages(patientId))

    try {
      const bloodPressureAverages = await apiClient.getPatientBloodPressureMovingAverages(patientId)

      dispatch(fetchBloodPressureAveragesFulfilled(bloodPressureAverages, patientId))
    } catch (e) {
      dispatch(fetchBloodPressureAveragesFailed(e as Error, patientId))
    }
  }

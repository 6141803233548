import { MedicationsPaidQueryQuery } from '../../../../__generated__/graphql'

type Medication = NonNullable<MedicationsPaidQueryQuery['patient']>['medications']['medications'][number][number]

const dedupeMeds = (medications: (Medication | null)[]): NonNullable<Medication>[] => {
  const map = new Map<string, NonNullable<Medication>>()

  medications.forEach((medication) => {
    if (!medication) {
      return
    }

    const existing = map.get(medication.medication)

    if (!existing) {
      map.set(medication.medication, medication)
    } else {
      map.set(existing.medication, {
        ...existing,
        events: [...existing.events, ...medication.events],
      })
    }
  })

  return Array.from(map.values())
}

export default dedupeMeds

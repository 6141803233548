import { RootState } from '../reducers'
import { RecordState } from '../../types/record-state'

export function getChatRecordState(state: RootState): RecordState {
  return state.chat.recordState
}

export function getToken(state: RootState): string {
  return state.chat.token
}

export function getExpiresAt(state: RootState): number {
  return state.chat.expiresAt
}

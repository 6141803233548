import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReduxDispatch as AppDispatch, RootState } from './reducers'

// To be used instead of directly using `useDispatch` and `useSelector`
// See https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
export const useAppDispatch = useDispatch as () => AppDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
}>()

import clsx from 'clsx'
import { ReactElement, useCallback, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Channel from '@sendbird/uikit-react/Channel'
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react'
import { CloseButtonLink } from '../../ui/close-button'
import { IconSize } from '../../ui/icon'
import { getPatientIdByChatChannel } from '../../../redux/selectors/patient'
import { useAppDispatch, useAppSelector } from '../../../redux'

import RightRail from '../../ui/right-rail'
import { RightRailPanel, PanelAction } from '../../ui/right-rail/types'

import { fetchPatientByChatChannel } from '../../../redux/thunks/patient'
import { useIsRightRailOpen } from './utils'

interface Props {
  onRightRailPanelClick: (action: PanelAction) => void
  rightRailPanel: {
    panel: RightRailPanel
  }
}

const ChannelChat = ({ onRightRailPanelClick, rightRailPanel }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const { channelUrl } = useParams<{ channelUrl: string }>()
  const [channelName, setChannelName] = useState('')
  const patientId = useAppSelector((state) => getPatientIdByChatChannel(state, channelUrl))
  const panels = useMemo(
    () => (patientId ? ['channel-settings', 'patient-profile'] : ['channel-settings']) as RightRailPanel[],
    [patientId],
  )
  const isRightRailOpen = useIsRightRailOpen(rightRailPanel.panel)
  const context = useSendbirdStateContext()
  const getGroupChannel = sendbirdSelectors.getGetGroupChannel(context)

  const renderChannelHeader = useCallback(() => <span />, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const channel = await getGroupChannel(channelUrl)

      setChannelName(
        channel.name?.toLowerCase() === 'group channel'
          ? channel.members.map(({ nickname }) => nickname).join(', ')
          : channel.name,
      )
    })()
  }, [channelUrl, getGroupChannel])

  useEffect(() => {
    dispatch(fetchPatientByChatChannel(channelUrl))
  }, [channelUrl, dispatch])

  return (
    <>
      <div
        className={clsx('h-full flex-1 flex flex-col overflow-hidden', {
          'hidden lg:flex': isRightRailOpen,
        })}
      >
        <header className="col-span-2 flex px-4 items-center bg-white border-x border-x-rivaOffblack-200 h-[63px] flex-none">
          <div
            className={clsx('w-6 h-6 mr-4 ', {
              'xl:hidden block': isRightRailOpen,
              hidden: !isRightRailOpen,
            })}
          >
            <CloseButtonLink className="inline-block p-[2px]" kind="back" to="/chat" size={IconSize.SMALL} />
          </div>
          <h3 className="text-xl font-semibold flex-1">{channelName}</h3>
        </header>
        <div className="flex-1 overflow-hidden">
          <Channel channelUrl={channelUrl} renderChannelHeader={renderChannelHeader} />
        </div>
      </div>
      <RightRail
        panels={panels}
        channelUrl={channelUrl}
        onPanelButtonClick={onRightRailPanelClick}
        selectedPanel={rightRailPanel}
        patientId={patientId}
      />
    </>
  )
}

export default ChannelChat

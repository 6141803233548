import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, FunctionComponent, HTMLAttributes, ReactNode } from 'react'
import { IconSize, SvgIcon } from './icon'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: ReactNode
  iconLeft?: FunctionComponent
  iconLeftClassName?: string
  iconLeftSize?: IconSize
  iconRight?: FunctionComponent
  iconRightClassName?: string
  iconRightSize?: IconSize
  isOnDarkBackground?: boolean
  variant?: 'normal' | 'success' | 'error' | 'inverted'
}

const Pill = forwardRef<HTMLDivElement, Props>(function Pill(
  {
    children,
    className,
    iconLeft,
    iconLeftClassName,
    iconLeftSize = IconSize.SMALL,
    iconRight,
    iconRightClassName,
    iconRightSize = IconSize.SMALL,
    isOnDarkBackground,
    variant = 'normal',
    ...props
  },
  ref,
) {
  return (
    <div
      {...props}
      className={clsx(
        'rounded-full inline-flex flex-row items-center text-xxs tracking-wide font-semibold cursor-default px-2 py-1 h-6',
        {
          'bg-rivaOffblack-100': !isOnDarkBackground && variant === 'normal',
          'bg-rivaOffblack-700': isOnDarkBackground && variant === 'normal',
          'bg-rivaFuchsia-100 text-rivaFuchsia-700': variant === 'error',
          'bg-rivaGreen-100 text-rivaGreen-800': variant === 'success',
          'bg-white': variant === 'inverted',
        },
        className,
      )}
      ref={ref}
    >
      {iconLeft ? (
        <SvgIcon
          Icon={iconLeft}
          size={iconLeftSize}
          className={clsx(
            {
              'text-rivaFuchsia-500': variant === 'error',
              'mr-1': !!children,
            },
            iconLeftClassName,
          )}
        />
      ) : null}
      {children}
      {iconRight ? (
        <SvgIcon
          Icon={iconRight}
          size={iconRightSize}
          className={clsx(
            {
              'text-rivaFuchsia-500': variant === 'error',
              'ml-1': !!children,
            },
            iconRightClassName,
          )}
        />
      ) : null}
    </div>
  )
})

export default Pill

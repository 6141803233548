import { gql } from '../__generated__/gql'

export default gql(`#graphql
query taskCarePathwayQuery($taskId: ID!) {
  task(id: $taskId) {
    carePathway {
      created {
        at
        by
      }
      events {
        carePathwayId
        created {
          at
          by
        }
        data
        eventType
        id
        note
        surveySubmission {
          completedAt
          data
          events {
            at
            by
            type
          }
          id
          lastSeenAt
          patientId
          startedAt
          seenBy
          submissionId
          surveyId
          url
        }
      }
      id
      patientId
      status
      type
    }
  }
}`)

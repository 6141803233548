import { ReactElement, useCallback, useState } from 'react'
import useGetSurveySubmissionsByPatient from '../../../../api/hooks/use-get-survey-submissions-by-patient'
import { SURVEYS_LIST } from '../../../../consts/data-testids'
import FieldSet from '../../../ui/field-set'
import { Table, TableBody, TableHeader } from '../../../ui/table'
import SubmissionItem from './submission-item'
import SurveyFilter from './survey-filter'

interface Props {
  patientId: string
}

const SubmissionList = ({ patientId }: Props): ReactElement => {
  const { data: { submissions } = {} } = useGetSurveySubmissionsByPatient(patientId)

  const [selectedSurveyId, setSelectedSurveyId] = useState(-1)
  const handleChange = useCallback((value?: number) => {
    if (typeof value === 'number') {
      setSelectedSurveyId(value)
    }
  }, [])

  const items =
    (selectedSurveyId === -1
      ? submissions
      : // filter by the survey selected in the dropdown
        submissions?.filter(({ surveyId }) => surveyId === selectedSurveyId)) ?? []

  return (
    <section className="px-3 pb-7 bg-white" data-testid={SURVEYS_LIST}>
      <header className="relative flex h-24 px-4 items-center">
        <h2 className="font-semibold text-lg m-0">Surveys</h2>
        <FieldSet className="absolute top-7 right-3 w-[220px]">
          <SurveyFilter onChange={handleChange} patientId={patientId} value={selectedSurveyId} />
        </FieldSet>
      </header>
      <Table>
        <TableHeader
          headers={[
            { text: 'Name', className: 'w-[200px] flex items-center' },
            { text: 'Result', className: 'flex-1 flex items-center' },
            { text: 'Date Completed', className: 'w-[130px] flex flex-col items-center justify-stretch text-right' },
          ]}
        />
        <TableBody>
          {items.map((item) => {
            return <SubmissionItem key={item.id} {...item} />
          })}
        </TableBody>
      </Table>
    </section>
  )
}

export default SubmissionList

import { ReactElement } from 'react'
import { DateTime } from 'luxon'
import { useAppSelector } from '../../../../redux'
import { getTaskDueDate, isTaskOverdue } from '../../../../redux/selectors/tasks'
import Pill from '../../../ui/pill'
import { ReactComponent as CalendarIcon } from '../../../icons/outline/calendar.svg'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import FormattedDateTime from '../../../ui/formatted-date-time'
import { TASK_LIST_ITEM_DUE_DATE } from '../../../../consts/data-testids'

interface Props {
  selected: boolean
  taskId: string
}

const DueDate = ({ selected, taskId }: Props): ReactElement | null => {
  const dueDate = useAppSelector((state) => getTaskDueDate(state, taskId))
  const isOverdue = useAppSelector((state) => isTaskOverdue(state, taskId))
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [-12, 6],
    placement: 'bottom-start',
  })

  if (!dueDate) {
    return null
  }

  const parsedDueDate = DateTime.fromISO(dueDate)

  return (
    <>
      <Pill
        {...getTriggerProps()}
        iconLeft={CalendarIcon}
        variant={isOverdue ? 'error' : selected ? 'inverted' : 'normal'}
      >
        <FormattedDateTime
          className="tabular-nums"
          data-testid={TASK_LIST_ITEM_DUE_DATE}
          value={dueDate}
          day="numeric"
          month="numeric"
          year={DateTime.now().year !== parsedDueDate.year ? '2-digit' : undefined}
        />
        <Tooltip className="z-50" {...getTooltipProps()} arrowProps={getArrowProps()}>
          {`Task due on ${parsedDueDate.toLocaleString({
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}`}
        </Tooltip>
      </Pill>
      <span className="w-2 flex-none"></span>
    </>
  )
}

export default DueDate

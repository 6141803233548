import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useMemo } from 'react'
import { DropLastParam } from '../../../types/utils'
import enrollMethod from './enroll'
import withdrawMethod from './withdraw'

export interface StudiesApi {
  enroll: DropLastParam<typeof enrollMethod>
  withdraw: DropLastParam<typeof withdrawMethod>
}

const useStudiesApi = (): StudiesApi => {
  const { getAccessTokenSilently } = useAuth0()

  const enroll = useCallback<StudiesApi['enroll']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return enrollMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  const withdraw = useCallback<StudiesApi['withdraw']>(
    async (...params) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      return withdrawMethod(...params, accessToken)
    },
    [getAccessTokenSilently],
  )

  return useMemo(
    () => ({
      enroll,
      withdraw,
    }),
    [enroll, withdraw],
  )
}

export default useStudiesApi

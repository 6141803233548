import { useAppSelector } from '../../redux'
import { getLoggedInPersonId } from '../../redux/selectors/app'
import { buildMyOpenTasksListId } from '../../utils/lists'
import { getPractitionerByPersonId } from '../../redux/selectors/practitioner'
import usePollForNewTasks from '../use-poll-for-new-tasks'

const usePrefetchNewTasks = (): void => {
  const loggedInPersonId = useAppSelector((state) => getLoggedInPersonId(state))
  const practitionerId = useAppSelector((state) => getPractitionerByPersonId(state, loggedInPersonId)?.id)
  const listId = buildMyOpenTasksListId(practitionerId ?? '')

  return usePollForNewTasks(listId)
}

export default usePrefetchNewTasks

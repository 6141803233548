import { ReactElement, useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import useGetListIdFromRoute from '../../../hooks/use-get-list-id-from-route'
import { useAppDispatch } from '../../../redux'
import { parseListId } from '../../../utils/lists'
import { searchPatients } from '../../../redux/thunks/patient'
import { PatientView } from '../patients/patient-view'
import Paging from '../../ui/paging'
import usePatientsApi from '../../../api/hooks/use-patients-api'
import SearchHeader from './search-header'
import PatientSearchResultList from './patient-search-result-list'

const Search = (): ReactElement => {
  const dispatch = useAppDispatch()
  const patientsApi = usePatientsApi()
  const listId = useGetListIdFromRoute()
  const { url } = useRouteMatch()

  useEffect(() => {
    const { query } = parseListId(listId)

    if (!query) {
      return
    }

    dispatch(searchPatients(listId, patientsApi))
  }, [dispatch, listId, patientsApi])

  return (
    <section className="min-h-full bg-white flex flex-col">
      <SearchHeader listId={listId} />
      <PatientSearchResultList listId={listId} />
      <Paging listId={listId} />
      <Switch>
        <Route path={`${url}/:patientId`} component={PatientView}></Route>
      </Switch>
    </section>
  )
}

export default Search

import { ReactElement } from 'react'
import { useAppSelector } from '../../../../redux'
import { getCarePathwayById } from '../../../../redux/selectors/care-pathway'
import { CarePathwayType } from '../../../../__generated__/graphql'
import BloodPressureEscalationSummary from '../../blood-pressure-escalation-summary'

interface Props {
  escalationId: string
}

const BloodPressureMeasurementEscalation = ({ escalationId }: Props): ReactElement => {
  const carePathway = useAppSelector((state) => getCarePathwayById(state, escalationId))

  return (
    <article className="bg-rivaOffblack-100 text-sm text-rivaOffblack-900 col-start-3 col-span-2 px-3 py-2">
      <h5 className="text-rivaOffblack-900 text-xxs font-extrabold uppercase mb-1">
        {carePathway?.type === CarePathwayType.HighBloodPressureEscalation ? 'Elevated' : 'Low'} BP patient responses
      </h5>
      <BloodPressureEscalationSummary id={escalationId} />
    </article>
  )
}

export default BloodPressureMeasurementEscalation

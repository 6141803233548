import { ReactElement, useEffect } from 'react'
import { LoadingIndicator, LoadingIndicatorSize } from '../../../ui/loading-indicator'
import useGetSurveySubmissionsByPatient from '../../../../api/hooks/use-get-survey-submissions-by-patient'
import useGetSurveys from '../../../../api/hooks/use-get-surveys'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { fetchSurveyRules } from '../../../../redux/thunks/survey_rules'
import { getSurveyRulesState } from '../../../../redux/selectors/surveys'
import { RecordState } from '../../../../types/record-state'
import ErrorState from '../error-state'
import EmptyState from '../empty-state'
import SubmissionSummary from './submission-summary'
import SubmissionList from './submission-list'

interface Props {
  patientId: string
}

const Surveys = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const {
    data: { submissions } = {},
    isValidating: isValidatingSubmissions,
    error: submissionError,
  } = useGetSurveySubmissionsByPatient(patientId)
  const { data: { surveys } = {}, isValidating: isValidatingSurveys, error: errorSurvey } = useGetSurveys()
  const isLoadingSubmissions = !submissions && isValidatingSubmissions
  const isLoadingSurveys = !surveys && isValidatingSurveys

  const recordState = useAppSelector((state) => getSurveyRulesState(state))

  useEffect(() => {
    dispatch(fetchSurveyRules())
  }, [dispatch])

  if (submissionError || errorSurvey || recordState === RecordState.ERRORED) {
    return (
      <section className="flex-1 bg-white">
        <ErrorState />
      </section>
    )
  }

  if (isLoadingSubmissions || isLoadingSurveys || recordState === RecordState.DOES_NOT_EXIST) {
    return <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
  }

  if (!submissions?.length) {
    return (
      <section className="flex-1 bg-white">
        <EmptyState />
      </section>
    )
  }

  return (
    <section className="bg-rivaOffblack-200">
      <SubmissionSummary patientId={patientId} />
      <SubmissionList patientId={patientId} />
    </section>
  )
}

export default Surveys

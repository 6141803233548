import Ajv, { JSONSchemaType } from 'ajv'
import { SWRResponse } from 'swr'
import { AuditStatus } from '../../types/audit-status'
import { Device } from '../../types/device'
import useApi from './use-api'

const ajv = new Ajv()

export interface GetDevicesResponse {
  devices: Device[] | null | undefined
}

const auditStatusSchema: JSONSchemaType<AuditStatus> = {
  type: 'object',
  properties: {
    at: {
      type: 'string',
    },
    by: {
      type: 'string',
    },
  },
  required: ['at', 'by'],
}

const schema: JSONSchemaType<GetDevicesResponse> = {
  type: 'object',
  properties: {
    devices: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          activationState: {
            type: 'string',
          },
          created: auditStatusSchema,
          hardwareVersion: {
            type: 'string',
          },
          id: {
            type: 'string',
          },
          manufacturer: {
            type: 'string',
          },
          modelName: {
            type: 'string',
          },
          patientId: {
            type: 'string',
          },
          deviceId: {
            type: 'string',
          },
          timeZone: {
            type: 'object',
            properties: {
              timeZoneOffset: {
                type: 'number',
              },
              updated: auditStatusSchema,
            },
            required: ['timeZoneOffset', 'updated'],
          },
          type: {
            type: 'string',
          },
          updated: auditStatusSchema,
        },
        required: [
          'activationState',
          'created',
          'hardwareVersion',
          'id',
          'manufacturer',
          'modelName',
          'patientId',
          'deviceId',
          'timeZone',
          'type',
          'updated',
        ],
      },
      nullable: true,
    },
  },
  required: [],
}

const validate = ajv.compile(schema)

const useGetDevices = (
  { unassigned }: { unassigned: boolean } = { unassigned: false },
): SWRResponse<GetDevicesResponse> =>
  useApi(`/v1/devices`, {
    query: {
      unassigned: [String(unassigned)],
    },
    validate,
  })

export default useGetDevices

import { FormAction } from '../../../../types/form'
import {
  UPDATE_CUFF_READING_FORM_INITIALIZED,
  UPDATING_BLOOD_PRESSURE_MEASUREMENT,
  UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED,
  UPDATE_BLOOD_PRESSURE_MEASUREMENT_FAILED,
} from '../../../../types/blood-pressure-measurement'
import formReducerFactory from '../form-reducer-factory'

export const updateCuffReadingForm = formReducerFactory({
  [FormAction.INITIALIZED]: [UPDATE_CUFF_READING_FORM_INITIALIZED],
  [FormAction.SUBMITTING]: [UPDATING_BLOOD_PRESSURE_MEASUREMENT],
  [FormAction.SUBMITTED]: [UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED],
  [FormAction.ERRORED]: [UPDATE_BLOOD_PRESSURE_MEASUREMENT_FAILED],
})

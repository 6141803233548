import { ChangeEventHandler, useCallback, useMemo } from 'react'
import { uuid } from '../../../utils/uuid'
import { Props as RadioProps } from './radio'

interface Props<T> {
  onChange: (value: T) => void
  required?: boolean
  value?: T
}

interface UseRadioGroupHook<T> {
  getGroupLabelProps: () => {
    htmlFor: string
    id: string
  }
  getRadioGroupProps: () => {
    labelledby: string
    name: string
  }
  getRadioProps: (value: T) => RadioProps<T>
}

const useRadioGroup = <T extends string>({ onChange, required, value }: Props<T>): UseRadioGroupHook<T> => {
  const id = useMemo(() => uuid(), [])
  const labelId = `label-${id}`
  const radioName = `radiogroup-${id}`

  const getGroupLabelProps = useCallback(
    () => ({
      htmlFor: radioName,
      id: labelId,
      required,
    }),
    [labelId, radioName, required],
  )

  const getRadioGroupProps = useCallback(
    () => ({
      name: radioName,
      labelledby: labelId,
    }),
    [labelId, radioName],
  )

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(event.currentTarget.value as T)
    },
    [onChange],
  )

  const getRadioProps = useCallback(
    (itemValue: T) => {
      return {
        checked: itemValue === value,
        name: radioName,
        onChange: handleChange,
        required,
        value: itemValue,
      }
    },
    [handleChange, radioName, required, value],
  )

  return useMemo(
    () => ({
      getGroupLabelProps,
      getRadioGroupProps,
      getRadioProps,
    }),
    [getGroupLabelProps, getRadioGroupProps, getRadioProps],
  )
}

export default useRadioGroup

import {
  FetchSurveySubmissionsFailedAction,
  FetchSurveySubmissionsRequestedAction,
  FetchSurveySubmissionsSucceededAction,
  FETCH_SURVEY_SUBMISSIONS_FAILED,
  FETCH_SURVEY_SUBMISSIONS_REQUESTED,
  FETCH_SURVEY_SUBMISSIONS_SUCCEEDED,
  SurveyMarkedAsSeenAction,
  SurveyMarkedAsSeenFailedAction,
  SurveyMarkedAsSeenSucceededAction,
  SurveyMarkedAsUnseenAction,
  SurveyMarkedAsUnseenFailedAction,
  SurveyMarkedAsUnseenSucceededAction,
  SURVEY_MARKED_AS_SEEN,
  SURVEY_MARKED_AS_SEEN_FAILED,
  SURVEY_MARKED_AS_SEEN_SUCCEEDED,
  SURVEY_MARKED_AS_UNSEEN,
  SURVEY_MARKED_AS_UNSEEN_FAILED,
  SURVEY_MARKED_AS_UNSEEN_SUCCEEDED,
} from '../../types/actions'

export const fetchSurveySubmissionsRequested = (
  payload: FetchSurveySubmissionsRequestedAction['payload'],
): FetchSurveySubmissionsRequestedAction => ({
  type: FETCH_SURVEY_SUBMISSIONS_REQUESTED,
  payload,
})

export const fetchSurveySubmissionsSucceeded = (
  payload: FetchSurveySubmissionsSucceededAction['payload'],
): FetchSurveySubmissionsSucceededAction => ({
  type: FETCH_SURVEY_SUBMISSIONS_SUCCEEDED,
  payload,
})

export const fetchSurveySubmissionsFailed = (
  payload: FetchSurveySubmissionsFailedAction['payload'],
): FetchSurveySubmissionsFailedAction => ({
  type: FETCH_SURVEY_SUBMISSIONS_FAILED,
  payload,
})

export const surveyMarkedAsSeen = (payload: SurveyMarkedAsSeenAction['payload']): SurveyMarkedAsSeenAction => ({
  type: SURVEY_MARKED_AS_SEEN,
  payload,
})

export const surveyMarkedAsSeenFailed = (
  payload: SurveyMarkedAsSeenFailedAction['payload'],
): SurveyMarkedAsSeenFailedAction => ({
  type: SURVEY_MARKED_AS_SEEN_FAILED,
  payload,
})

export const surveyMarkedAsSeenSucceeded = (
  payload: SurveyMarkedAsSeenSucceededAction['payload'],
): SurveyMarkedAsSeenSucceededAction => ({
  type: SURVEY_MARKED_AS_SEEN_SUCCEEDED,
  payload,
})

export const surveyMarkedAsUnseen = (payload: SurveyMarkedAsUnseenAction['payload']): SurveyMarkedAsUnseenAction => ({
  type: SURVEY_MARKED_AS_UNSEEN,
  payload,
})

export const surveyMarkedAsUnseenFailed = (
  payload: SurveyMarkedAsUnseenFailedAction['payload'],
): SurveyMarkedAsUnseenFailedAction => ({
  type: SURVEY_MARKED_AS_UNSEEN_FAILED,
  payload,
})

export const surveyMarkedAsUnseenSucceeded = (
  payload: SurveyMarkedAsUnseenSucceededAction['payload'],
): SurveyMarkedAsUnseenSucceededAction => ({
  type: SURVEY_MARKED_AS_UNSEEN_SUCCEEDED,
  payload,
})

import { ApolloClient } from '@apollo/client'
import { RecordState } from '../../types/record-state'
import {
  fetchAllergyIntoleranceFailed,
  fetchAllergyIntoleranceRequested,
  fetchAllergyIntoleranceSucceeded,
} from '../actions/allergy-intolerance'
import { AppThunk } from '../reducers'
import { getAllergyIntoleranceRecordState } from '../selectors/allergy-intolerance'
import { AllergyIntolerance } from '../../__generated__/graphql'
import allergiesQuery from '../../schemas/allergies-query'

export const fetchAllergyIntolerance =
  (patientId: string, apolloClient: ApolloClient<object>): AppThunk =>
  async (dispatch, getState) => {
    const recordState = getAllergyIntoleranceRecordState(getState(), patientId)

    if (recordState === RecordState.LOADING) {
      return
    }

    dispatch(
      fetchAllergyIntoleranceRequested({
        patientId,
      }),
    )

    try {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: allergiesQuery,
        variables: {
          patientId,
        },
      })

      dispatch(
        fetchAllergyIntoleranceSucceeded({
          patientId,
          allergyIntolerance:
            result.data?.patient?.allergyIntolerances?.filter((ai): ai is AllergyIntolerance => !!ai) ?? [],
        }),
      )
    } catch (error) {
      dispatch(
        fetchAllergyIntoleranceFailed({
          patientId,
        }),
      )
    }
  }

import { produce } from 'immer'
import { combineReducers, Reducer } from 'redux'
import { RecordState } from '../../types/record-state'
import {
  FETCHING_PERSONS,
  FETCH_PERSONS_FAILED,
  FETCH_PERSONS_FULFILLED,
  Person,
  PersonMap,
  PersonState,
} from '../../types/person'
import { Action } from '../../types/actions'
import { FETCH_PRACTITIONER_FULFILLED } from '../../types/practitioner'

function recordState(state: RecordState = RecordState.DOES_NOT_EXIST, action: Action) {
  switch (action.type) {
    case FETCHING_PERSONS:
      return RecordState.LOADING
    case FETCH_PERSONS_FULFILLED:
      return RecordState.LOADED
    case FETCH_PERSONS_FAILED:
      return RecordState.ERRORED
    default:
      return state
  }
}

const personMap = produce((draft: PersonMap, action: Action) => {
  switch (action.type) {
    case FETCH_PRACTITIONER_FULFILLED:
    case FETCH_PERSONS_FULFILLED: {
      const { persons } = action.payload

      persons.forEach((person: Person) => {
        draft[person.id] = person
      })

      return
    }
  }
}, {})

export const person: Reducer<PersonState, Action> = combineReducers({
  recordState,
  personMap,
})

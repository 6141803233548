import { ComponentProps, ReactElement, useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import debounce from 'lodash.debounce'
import { DateTime } from 'luxon'
import clsx from 'clsx'
import TextInput from '../text-input'
import FormLabel from '../form-label'
import ehrRecordQuery from '../../../schemas/ehr-record-query'
import TextInputNotice from '../text-input-notice'
import SimpleLoadingIndicator from '../simple-loading-indicator'
import { IconSize, SvgIcon } from '../icon'
import { ReactComponent as ExclamationCircleIcon } from '../../icons/solid/exclamation-circle.svg'
import { getDobAndAge } from '../../views/patient-profile/utils'
import { AcquisitionChannel, PatientType } from '../../../types/patient'
import useHasAthenaProfile from '../../../hooks/use-has-athena-profile'

interface Props {
  acquisitionChannel: AcquisitionChannel | ''
  dateOfBirth: string
  firstName: string
  lastName: string
  onChange: (value: string) => void
  patientType?: PatientType
  required?: boolean
  value: string
}

const MedicalRecordNumberInput = ({
  acquisitionChannel,
  dateOfBirth,
  firstName,
  lastName,
  onChange,
  patientType,
  required,
  value,
}: Props): ReactElement => {
  const handleChange = useCallback<NonNullable<ComponentProps<typeof TextInput>['onChange']>>(
    (event) => onChange(event.target.value),
    [onChange],
  )
  const [shouldCheck, setShouldCheck] = useState(false)
  const hasAthenaProfile = useHasAthenaProfile(acquisitionChannel ?? '', patientType)
  const disabled = !hasAthenaProfile

  const { data, loading, error } = useQuery(ehrRecordQuery, {
    skip: disabled || !shouldCheck,
    variables: {
      medicalRecordNumber: value,
    },
  })

  const valueNumber = parseInt(value, 10)
  const isValueValid = value === '' || (!isNaN(valueNumber) && valueNumber >= 1)

  const ehrBirthDate = data?.ehrRecord?.birthDate ?? ''
  const ehrName = data?.ehrRecord?.name?.[0]
  const ehrFirstName = ehrName?.given?.join(' ') ?? ''
  const ehrLastName = ehrName?.family ?? ''
  const nameIncorrect = (shouldCheck && data && firstName !== ehrFirstName) || (data && lastName !== ehrLastName)

  const birthDateTime = DateTime.fromISO(dateOfBirth)
  const ehrBirthDateTime = DateTime.fromISO(ehrBirthDate)
  const birthDateIncorrect =
    shouldCheck &&
    data &&
    (!ehrBirthDateTime.isValid ||
      !birthDateTime.isValid ||
      Math.abs(birthDateTime.diff(ehrBirthDateTime, 'days').days) >= 1)
  const somethingIncorrect = !isValueValid || nameIncorrect || birthDateIncorrect

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateShouldCheck = useCallback(
    debounce((mrn: string) => setShouldCheck(mrn !== '' && isValueValid)),
    [],
  )

  useEffect(() => {
    updateShouldCheck(value)
  }, [updateShouldCheck, value])

  return (
    <>
      <TextInput
        id="mrn"
        onChange={handleChange}
        placeholder="MRN"
        required={required}
        showIcon
        type="number"
        value={value}
        variant={error ? 'invalid' : somethingIncorrect ? 'warning' : data && !loading ? 'success' : undefined}
      />
      <FormLabel htmlFor="mrn" required invalid={!!error} warning={somethingIncorrect}>
        Medical record number
      </FormLabel>
      {data || loading || error ? (
        <>
          <div
            className={clsx('p-5 box-border bg-rivaOffblack-50 border border-rivaOffblack-200 rounded order-3 mt-2', {
              'h-20 flex flex-col items-center justify-center': loading || error,
            })}
          >
            {loading ? (
              <>
                <SimpleLoadingIndicator />
                <p className="text-xxs font-medium text-rivaOffblack-700 mt-2">
                  Generating Athena medical record preview
                </p>
              </>
            ) : error ? (
              <>
                <SvgIcon className="text-rivaFuchsia-500" Icon={ExclamationCircleIcon} size={IconSize.SMALL} />
                <p className="text-xxs font-medium text-rivaOffblack-700 mt-2">Something went wrong</p>
              </>
            ) : (
              <>
                <header className="mb-3">
                  <h5 className="text-xxs font-extrabold text-rivaOffblack-900 uppercase">
                    Athena Medical Record #{value}
                  </h5>
                </header>
                <main className="flex pb-3">
                  <div className="flex-1 mr-3 tabular-nums">
                    <dl>
                      <dt className="capitalize text-xxs leading-4 font-semibold text-rivaOffblack-500">
                        Patient name
                      </dt>
                      <dd
                        className={clsx('text-sm font-medium text-rivaOffblack-900', {
                          'bg-rivaYellow-100': nameIncorrect,
                        })}
                      >
                        {ehrFirstName} {ehrLastName}
                      </dd>
                    </dl>
                  </div>
                  <div>
                    <dl>
                      <dt className="capitalize text-xxs leading-4 font-semibold text-rivaOffblack-500">
                        Date of birth
                      </dt>
                      <dd
                        className={clsx('text-sm font-medium text-rivaOffblack-900 tabular-nums', {
                          'bg-rivaYellow-100': birthDateIncorrect,
                        })}
                      >
                        {ehrBirthDate ? getDobAndAge(ehrBirthDate, '') : null}
                      </dd>
                    </dl>
                  </div>
                </main>
              </>
            )}
          </div>
          {!loading && !error && somethingIncorrect ? (
            <TextInputNotice severity="warning">
              <p className="text-xxs font-medium leading-4">
                Please make sure that you entered the correct patient information and medical record number
              </p>
              <ul className="list-disc pl-4 text-xxs leading-4 font-medium">
                {nameIncorrect ? <li>The patient's name does not match the medical record</li> : null}
                {birthDateIncorrect ? <li>The patient's date of birth does not match the medical record</li> : null}
              </ul>
            </TextInputNotice>
          ) : null}
        </>
      ) : hasAthenaProfile ? (
        <TextInputNotice>
          Be sure to enter the correct medical record number. Otherwise, the wrong patient's medical records will be
          associated with this patient.
        </TextInputNotice>
      ) : null}
    </>
  )
}

export default MedicalRecordNumberInput

import { DetailedHTMLProps, HTMLAttributes, ReactElement, ReactNode } from 'react'

interface Props extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className'> {
  children: ReactNode
}

const PatientProfileSection = ({ children, ...props }: Props): ReactElement => {
  return (
    <section {...props} className="p-3 bg-white mb-2 last:mb-0">
      {children}
    </section>
  )
}

export default PatientProfileSection

import Ajv, { JSONSchemaType } from 'ajv'
import { SWRResponse } from 'swr'
import { Survey } from '../../types/survey'
import useApi from './use-api'

const ajv = new Ajv()

export interface GetSurveysResponse {
  surveys: Survey[] | null | undefined
}

const schema: JSONSchemaType<GetSurveysResponse> = {
  type: 'object',
  properties: {
    surveys: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'number',
          },
          name: {
            type: 'string',
          },
        },
        required: ['id', 'name'],
      },
      nullable: true,
    },
  },
  required: [],
}

const validate = ajv.compile(schema)

const useGetSurveys = (): SWRResponse<GetSurveysResponse> =>
  useApi('/v1/survey', {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    validate,
  })

export default useGetSurveys

import { ReactElement } from 'react'
import PractitionerReference from '../../../ui/practitioner-reference'
import { Note } from '../../../../types/note'
import PatientActivityItem from './patient-activity-item'

interface Props {
  note: Note
}

const PatientNoteItem = ({ note }: Props): ReactElement => {
  return (
    <PatientActivityItem
      createdAt={note.created.at}
      id={note.id}
      kind="note"
      note={note.text}
      practitionerId={note.practitionerId}
    >
      <PractitionerReference practitionerId={note.practitionerId} /> added a note
    </PatientActivityItem>
  )
}

export default PatientNoteItem

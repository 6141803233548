import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  title: string
  subtitle?: ReactNode
  children: ReactNode
  className?: string
}

function PatientSummaryCard(props: Props): ReactElement {
  return (
    <div className={clsx('bg-rivaOffblack-100 text-rivaOffblack-700 rounded-lg mx-3', props.className)}>
      <h3 className="font-extrabold text-xxs uppercase px-6 pt-6">{props.title}</h3>
      {props.subtitle ? (
        <p className="text-xxs font-semibold text-rivaOffBlack700 uppercase px-6 pt-1">{props.subtitle}</p>
      ) : null}
      <div className="pt-6 pl-6 pb-6 flex">{props.children}</div>
    </div>
  )
}

export default PatientSummaryCard

import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux'
import { getAppRecordState } from '../../redux/selectors/app'
import { RecordState } from '../../types/record-state'
import { prefetchTasks } from '../../redux/thunks/tasks'
import useTasksApi from '../../api/hooks/use-tasks-api'

const usePollForNewTasks = (listId: string): void => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const dispatchPrefetch = useCallback(
    () => dispatch(prefetchTasks({ listId }, tasksApi)),
    [dispatch, listId, tasksApi],
  )
  const appRecordState = useAppSelector((state) => getAppRecordState(state))

  useEffect(() => {
    if (appRecordState !== RecordState.LOADED) {
      return
    }

    const interval = setInterval(dispatchPrefetch, 10000)

    const handleFocus = () => {
      dispatchPrefetch()
    }

    window.addEventListener('focus', handleFocus)

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      window.removeEventListener('focus', handleFocus)
    }
  }, [dispatch, appRecordState, dispatchPrefetch])
}

export default usePollForNewTasks

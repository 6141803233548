export enum RootRoutes {
  DASHBOARD = '/dashboard',
  PATIENTS = '/patients',
}

export interface PatientNoteParams {
  patientId: string
}

export interface PatientSurveyParams {
  patientId: string
}

export interface PatientBloodPressureSetsParams {
  patientId: string
}

export interface SurveySubmissionParams {
  patientId: string
  surveyId: string
  submissionId: string
}

export interface SurveysParams {
  query: string
}

export interface SurveyParams {
  taskId: string
}

import { ReactElement } from 'react'
import FieldSet from '../field-set'
import FormLabel from '../form-label'
import { Radio, RadioGroup, RadioLabel, useRadioGroup } from '../radio'

interface CommunicationPreferencesSelectorProps {
  communicationPreferencesDays: string
  setCommunicationPreferencesDays: (value: string) => void
}
export function CommunicationPreferencesSelector({
  communicationPreferencesDays,
  setCommunicationPreferencesDays,
}: CommunicationPreferencesSelectorProps): ReactElement {
  const { getGroupLabelProps, getRadioGroupProps, getRadioProps } = useRadioGroup({
    onChange: setCommunicationPreferencesDays,
    value: communicationPreferencesDays,
  })

  return (
    <FieldSet colspan={2}>
      <FormLabel {...getGroupLabelProps()} order={0}>
        Educational Messages Frequency
      </FormLabel>
      <RadioGroup {...getRadioGroupProps()}>
        <RadioLabel label="Daily (Weekdays)">
          <Radio {...getRadioProps('Mon,Tue,Weds,Thurs,Fri')} />
        </RadioLabel>
        <RadioLabel label="Monday, Wednesday, Friday">
          <Radio {...getRadioProps('Mon,Weds,Fri')} />
        </RadioLabel>
        <RadioLabel label="Do not send educational content">
          <Radio {...getRadioProps('')} />
        </RadioLabel>
      </RadioGroup>
    </FieldSet>
  )
}

import { TaskStatusUpdateFailedAction, TaskStatusUpdateSucceededAction } from './actions'
import { TaskChoiceSelectFailedAction, TaskChoiceSelectFulfilledAction } from './task'

export interface ToastState {
  message: string
  id: number
}

interface ToastBase {
  id: string
  timeout?: number
}

export const TASK_STATUS_UPDATE_SUCCESS_TOAST = 'TASK_STATUS_UPDATE_SUCCESS_TOAST'
export const TASK_STATUS_UPDATE_FAILURE_TOAST = 'TASK_STATUS_UPDATE_FAILURE_TOAST'

export interface TaskStatusUpdateSuccessToast extends ToastBase {
  type: typeof TASK_STATUS_UPDATE_SUCCESS_TOAST
  action: TaskStatusUpdateSucceededAction
}
export interface TaskStatusUpdateFailureToast extends ToastBase {
  type: typeof TASK_STATUS_UPDATE_FAILURE_TOAST
  action: TaskStatusUpdateFailedAction
}

export const TASK_CHOICE_SELECT_SUCCESS_TOAST = 'TASK_CHOICE_SELECT_SUCCESS_TOAST'
export const TASK_CHOICE_SELECT_FAILED_TOAST = 'TASK_CHOICE_SELECT_FAILED_TOAST'

export interface TaskChoiceSelectSuccessToast extends ToastBase {
  type: typeof TASK_CHOICE_SELECT_SUCCESS_TOAST
  action: TaskChoiceSelectFulfilledAction
}
export interface TaskChoiceSelectFailedToast extends ToastBase {
  type: typeof TASK_CHOICE_SELECT_FAILED_TOAST
  action: TaskChoiceSelectFailedAction
}

export type Toast =
  | TaskStatusUpdateSuccessToast
  | TaskStatusUpdateFailureToast
  | TaskChoiceSelectSuccessToast
  | TaskChoiceSelectFailedToast

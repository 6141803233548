import { forwardRef, useCallback } from 'react'
import { ControlledMenu as ReactMenuControlled, ControlledMenuProps, MenuModifiers } from '@szhsin/react-menu'
import { getMenuClassName } from './utils'

type Props = Omit<ControlledMenuProps, 'offsetY' | 'menuClassName'>

const ControlledMenu = forwardRef<HTMLElement, Props>(({ align, ...props }, ref) => {
  const menuClassName = useCallback<(m: MenuModifiers) => string>(
    (modifiers) => getMenuClassName(modifiers, align),
    [align],
  )

  return <ReactMenuControlled {...props} align={align} menuClassName={menuClassName} gap={4} ref={ref} />
})

export default ControlledMenu

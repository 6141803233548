import { produce } from 'immer'
import {
  CuffMeasurementDownloadUrlState,
  FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FAILED,
  FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FULFILLED,
  FetchCuffMeasurementDownloadUrlFailed,
  FetchCuffMeasurementDownloadUrlFulfilled,
  FETCHING_CUFF_MEASUREMENT_DOWNLOAD_URL,
  FetchingCuffMeasurementDownloadUrl,
} from '../../types/blood-pressure-measurement'
import { RecordState } from '../../types/record-state'

type CuffMeasurementActions =
  | FetchCuffMeasurementDownloadUrlFulfilled
  | FetchCuffMeasurementDownloadUrlFailed
  | FetchingCuffMeasurementDownloadUrl

export const cuffDownloadUrl = produce((draft: CuffMeasurementDownloadUrlState, action: CuffMeasurementActions) => {
  switch (action.type) {
    case FETCHING_CUFF_MEASUREMENT_DOWNLOAD_URL:
      draft[action.payload.measurementId] = {
        recordState: RecordState.LOADING,
        signedUrl: '',
        expirationTime: '',
      }

      return draft
    case FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FULFILLED:
      draft[action.payload.measurementId] = {
        recordState: RecordState.LOADED,
        signedUrl: action.payload.signedUrl,
        expirationTime: action.payload.expirationTime,
      }

      return draft
    case FETCH_CUFF_MEASUREMENT_DOWNLOAD_URL_FAILED:
      draft[action.payload.measurementId] = {
        recordState: RecordState.ERRORED,
        signedUrl: '',
        expirationTime: '',
      }

      return draft
    default:
      return draft
  }
}, {})

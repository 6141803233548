import { ApolloClient } from '@apollo/client'
import { AppThunk } from '../reducers'
import {
  fetchingBloodPressureMeasurements,
  fetchBloodPressureMeasurementsFulfilled,
  fetchBloodPressureMeasurementsFailed,
  fetchingCuffMeasurementDownloadUrl,
  fetchCuffMeasurementDownloadUrlFulfilled,
  fetchCuffMeasurementDownloadUrlFailed,
} from '../actions/blood-pressure-measurement'
import bloodPressureMeasurementsQuery from '../../schemas/blood-pressure-measurements-query'
import {
  AddHighBPEscalationSurveyResultsEventData,
  AddLowBPEscalationSurveyResultsEventData,
  BPEscalationMeasurementReceivedEventData,
  BPEscalationSymptomsEventData,
  CarePathwayEvent,
} from '../../types/care-pathway'
import { TaskEventType } from '../../__generated__/graphql'

export const fetchBloodPressureMeasurementsForPatient =
  (patientId: string, listId: string, apolloClient: ApolloClient<object>): AppThunk =>
  async (dispatch) => {
    dispatch(
      fetchingBloodPressureMeasurements({
        listId,
      }),
    )

    try {
      const { data } = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: bloodPressureMeasurementsQuery,
        variables: {
          patientId,
          start: '',
          end: '',
        },
      })

      const eventsMap = new Map<string, CarePathwayEvent>()

      data.patient?.bloodPressureMeasurements.forEach(({ escalation }) => {
        escalation?.events?.forEach((rawEvent) => {
          switch (rawEvent.eventType) {
            case TaskEventType.AddHighBpEscalationSurveyResults:
              eventsMap.set(rawEvent.id, {
                ...rawEvent,
                data: rawEvent.data as AddHighBPEscalationSurveyResultsEventData,
                eventType: rawEvent.eventType,
              })

              break
            case TaskEventType.AddLowBpEscalationSurveyResults:
              eventsMap.set(rawEvent.id, {
                ...rawEvent,
                data: rawEvent.data as AddLowBPEscalationSurveyResultsEventData,
                eventType: rawEvent.eventType,
              })
              break
            case TaskEventType.BpEscalationMeasurementReceived:
              eventsMap.set(rawEvent.id, {
                ...rawEvent,
                data: rawEvent.data as BPEscalationMeasurementReceivedEventData,
                eventType: rawEvent.eventType,
              })
              break
            case TaskEventType.BpEscalationSymptoms:
              eventsMap.set(rawEvent.id, {
                ...rawEvent,
                data: rawEvent.data as BPEscalationSymptomsEventData,
                eventType: rawEvent.eventType,
              })
              break
          }
        })
      })

      dispatch(
        fetchBloodPressureMeasurementsFulfilled({
          events: Array.from(eventsMap.values()),
          listId,
          measurements: data.patient?.bloodPressureMeasurements ?? [],
          patientId,
        }),
      )
    } catch (e) {
      dispatch(
        fetchBloodPressureMeasurementsFailed({
          error: e as Error,
          listId,
        }),
      )
    }
  }

export const fetchCuffMeasurementDownloadSignedUrl =
  (measurementId: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    dispatch(fetchingCuffMeasurementDownloadUrl(measurementId))

    try {
      const { signedUrl, expirationTime } = await apiClient.getCuffMeasurementDownloadSignedUrl(measurementId)

      dispatch(fetchCuffMeasurementDownloadUrlFulfilled(measurementId, signedUrl, expirationTime))
    } catch (e) {
      dispatch(fetchCuffMeasurementDownloadUrlFailed(e as Error, measurementId))
    }
  }

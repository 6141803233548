import clsx from 'clsx'
import { ReactElement } from 'react'
import { useAppSelector } from '../../../redux'
import { getListTotalCount } from '../../../redux/selectors/lists'
import { ListContentType } from '../../../types/lists'
import { SURVEY_QUERY_UNSEEN } from '../../../types/survey'
import { buildListId } from '../../../utils/lists'
import NavigationItem from './navigation-item'

const NewSurveysNavItem = (): ReactElement => {
  const newSurveyListId = buildListId({
    listContentType: ListContentType.SURVEY_SUBMISSION,
    query: SURVEY_QUERY_UNSEEN,
  })
  const newSurveyCount = useAppSelector((state) => getListTotalCount(state, newSurveyListId) ?? 0)

  return (
    <NavigationItem
      level="SECONDARY"
      to="/surveys/new"
      label="New"
      iconLeft={({ isActive }) => (
        <div className="w-6 h-6 flex items-center justify-center">
          <span
            className={clsx('w-[19px] h-[19px] border-2 rounded-full', {
              'border-rivaPurple-300': !isActive,
              'border-rivaPurple-100': isActive,
            })}
          ></span>
        </div>
      )}
      iconRight={() => (newSurveyCount > 0 ? newSurveyCount : undefined)}
    />
  )
}

export default NewSurveysNavItem

import cn from 'clsx'
import { ReactElement } from 'react'

interface TextBlockProps {
  text: string | number
  subtext?: string | number
  className?: string
}
const padding = 'py-2 pl-1'
const textClasses = 'text-lg font-bold'
const subtextClasses = 'pt-0.5 text-rivaOffblack-400 tracking-wider font-medium text-xs uppercase'

export function TextBlock(props: TextBlockProps): ReactElement {
  const { text, subtext = '', className = '', ...remainder } = props

  if (!subtext) {
    return (
      <p className={cn(padding, textClasses, className)} {...remainder}>
        {text}
      </p>
    )
  }

  return (
    <div className={cn(padding, className)} {...remainder}>
      <p className={textClasses}>{text}</p>
      <p className={subtextClasses}>{subtext}</p>
    </div>
  )
}

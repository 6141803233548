import { ApolloClient } from '@apollo/client'
import { TaskEventType } from '../../__generated__/graphql'
import { TasksApi } from '../../api/hooks/use-tasks-api'
import { StartableCarePathwayType } from '../../types/task'
import {
  carePathwayCancelFailed,
  carePathwayCancelFulfilled,
  carePathwayCancelRequested,
  carePathwayStartFailed,
  carePathwayStartFulfilled,
  carePathwayStartRequested,
  fetchCarePathwaysFailed,
  fetchCarePathwaysFulfilled,
  fetchCarePathwaysRequested,
} from '../actions/care-pathway'
import { AppThunk } from '../reducers'
import { getCarePathwayById } from '../selectors/care-pathway'
import carePathwaysQuery from '../../schemas/care-pathways-query'
import { parseListId } from '../../utils/lists'

export const fetchCarePathways =
  (listId: string, apolloClient: ApolloClient<object>): AppThunk =>
  async (dispatch) => {
    dispatch(
      fetchCarePathwaysRequested({
        listId,
      }),
    )

    try {
      const { patientId, carePathwayStatus, carePathwayType } = parseListId(listId)

      const { data } = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: carePathwaysQuery,
        variables: {
          patientId: patientId ? patientId[0] : '',
          carePathwayStatus,
          carePathwayTypes: carePathwayType,
        },
      })

      dispatch(
        fetchCarePathwaysFulfilled({
          carePathways:
            data.carePathways?.map((carePathway) => ({
              ...carePathway,
              eventIds: carePathway.events?.map(({ id }) => id) ?? [],
            })) ?? [],
          listId,
        }),
      )
    } catch (_) {
      dispatch(
        fetchCarePathwaysFailed({
          listId,
        }),
      )
    }
  }

export const startCarePathway =
  (
    {
      carePathwayType,
      listId,
      note,
      patientId,
    }: {
      carePathwayType: StartableCarePathwayType
      listId: string
      note: string
      patientId: string
    },
    tasksApi: TasksApi,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      carePathwayStartRequested({
        carePathwayType,
        patientId,
      }),
    )

    try {
      await tasksApi.createEvent({
        data: {
          carePathwayType,
        },
        eventType: TaskEventType.CreateCarePathway,
        note,
        patientId,
      })

      dispatch(
        carePathwayStartFulfilled({
          carePathwayType,
          listId,
          patientId,
        }),
      )
    } catch (_) {
      dispatch(
        carePathwayStartFailed({
          carePathwayType,
          patientId,
        }),
      )
    }
  }

export const cancelCarePathway =
  (carePathwayId: string, listId: string, patientId: string, tasksApi: TasksApi): AppThunk =>
  async (dispatch, getState) => {
    const carePathway = getCarePathwayById(getState(), carePathwayId)

    dispatch(
      carePathwayCancelRequested({
        carePathwayId,
      }),
    )

    try {
      await tasksApi.createCarePathwayEvent(carePathwayId, {
        data: {},
        eventType: TaskEventType.CancelCarePathway,
        patientId,
      })

      dispatch(
        carePathwayCancelFulfilled({
          carePathwayId,
          carePathwayType: carePathway?.type,
          listId,
        }),
      )
    } catch (_) {
      dispatch(
        carePathwayCancelFailed({
          carePathwayId,
        }),
      )
    }
  }

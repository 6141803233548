import { uniqBy } from 'lodash'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { NavLink, generatePath } from 'react-router-dom'
import useGetDevices from '../../../api/hooks/use-get-devices'
import { WIRELESS_CUFF_DEVICE_TYPE } from '../../../types/device'
import Combobox from '../combobox'
import FieldSet from '../field-set'
import { IconSize, SvgIcon } from '../icon'
import { ReactComponent as InformationCircleIcon } from '../../icons/solid/information-circle.svg'
import { Routes } from '../../../types/route'

interface Props {
  initialValue: string
  onChange: (value: string) => void
  onInvalidChange: (invalid: boolean) => void
  patientId?: string
  value: string
}

const WirelessCuffSelector = ({
  initialValue,
  onChange,
  onInvalidChange,
  patientId = '',
  value,
}: Props): ReactElement => {
  const { data, error } = useGetDevices()
  const [isFocused, setIsFocused] = useState(false)
  const onFocus = useCallback(() => setIsFocused(true), [])
  const onBlur = useCallback(() => setIsFocused(false), [])

  const wirelessCuffs = uniqBy(
    data?.devices?.filter(({ type }) => type === WIRELESS_CUFF_DEVICE_TYPE) ?? [],
    ({ deviceId }) => deviceId,
  )
  const assignedDevice = wirelessCuffs.find(
    ({ deviceId, patientId: assignedPatientId }) =>
      !!assignedPatientId && assignedPatientId !== patientId && deviceId === value,
  )
  const unassignedDevices = wirelessCuffs.filter(
    ({ patientId: assignedPatientId }) => !assignedPatientId || assignedPatientId === patientId,
  )
  const items = unassignedDevices.map(({ deviceId }) => deviceId)
  const invalid = !!error || (!isFocused && !!value && !items.includes(value))
  const warning = !invalid && initialValue !== '' && value === ''

  useEffect(() => {
    onInvalidChange(invalid)
  }, [invalid, onInvalidChange])

  return (
    <FieldSet className="relative mb-7" colspan={2}>
      <Combobox
        disabled={!data}
        items={items}
        invalid={invalid}
        label={
          <>
            {'Cuff Serial Number'}
            <a
              className="inline-block w-4 h-4 ml-1 relative top-1 text-rivaBlue-500"
              href="https://coda.io/d/_d7AOL-Hw8KB/Assign-a-Wireless-Cuff-to-a-Patient_su0HD#_lu770"
              rel="noreferrer"
              target="_blank"
              title="Wireless Cuffs FAQ"
            >
              <SvgIcon Icon={InformationCircleIcon} size={IconSize.X_SMALL} />
            </a>
          </>
        }
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        warning={warning}
      />
      {invalid && !isFocused ? (
        <p className="text-rivaFuchsia-500 text-xxs leading-6 px-1 py-2 absolute -bottom-10 left-0 order-3">
          {error ? (
            'There was an error loading the list of devices'
          ) : assignedDevice ? (
            <>
              {'This device is assigned to '}
              <NavLink
                className="underline"
                to={generatePath(Routes.DASHBOARD_PATIENT, {
                  serializedListId: 'query=all',
                  patientId: assignedDevice.patientId,
                })}
                target="_blank"
              >
                another patient
              </NavLink>
            </>
          ) : (
            'This device is not registered'
          )}
        </p>
      ) : warning ? (
        <p className="text-rivaGold-800 text-xxs leading-6 px-1 py-2 absolute -bottom-10 left-0 order-3">
          If a serial number is not entered, you will not receive cuff measurements
        </p>
      ) : null}
    </FieldSet>
  )
}

export default WirelessCuffSelector

import { DateTime } from 'luxon'
import { ReactElement, useEffect, useCallback } from 'react'
import { PATIENT_SUMMARY, PATIENT_SUMMARY_EXPAND_BUTTON } from '../../../../consts/data-testids'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import {
  getBloodPressureAverage,
  getBloodPressureAverageEarliestTime,
  getBloodPressureAverageLatestTime,
  getDiastolicRange,
  getHeartRateAverage,
  getHeartRateRange,
  getSystolicRange,
} from '../../../../redux/selectors/blood-pressure-average'
import { getPatientBloodPressureGoalForDisplay, getPatientPersonId } from '../../../../redux/selectors/patient'
import { TextBlock } from '../../../ui/text-block'
import BloodPressureBaselineCard from '../blood-pressure-baseline-card'
import PatientSummaryCard from '../patient-summary-card'
import { fetchBloodPressureAverages } from '../../../../redux/thunks/blood-pressure-averages'
import { togglePatientViewSummaryExpansion } from '../../../../redux/actions/ui'
import { getIsPatientViewSummaryExpanded } from '../../../../redux/selectors/ui'
import { SummaryContainer } from '../summary-container'
import { track } from '../../../../i13n'

interface Props {
  patientId: string
}

const PatientSummary = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const personId = useAppSelector((state) => getPatientPersonId(state, patientId))
  const bpAvgLatest = useAppSelector((state) => getBloodPressureAverage(state, patientId, 0))
  const bpmAvgLatest = useAppSelector((state) => getHeartRateAverage(state, patientId, 0))
  const bpmRangeLatest = useAppSelector((state) => getHeartRateRange(state, patientId, 0))
  const sbpRangeLatest = useAppSelector((state) => getSystolicRange(state, patientId, 0))
  const dbpRangeLatest = useAppSelector((state) => getDiastolicRange(state, patientId, 0))
  const bp12MAEndTimestampLatest = useAppSelector((state) => getBloodPressureAverageLatestTime(state, patientId, 0))
  const bp12MAStartTimestampLatest = useAppSelector((state) => getBloodPressureAverageEarliestTime(state, patientId, 0))
  const bp12MADateRangeLatest =
    bp12MAEndTimestampLatest && bp12MAStartTimestampLatest
      ? `${DateTime.fromISO(bp12MAStartTimestampLatest).toLocaleString({
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })} - ${DateTime.fromISO(bp12MAEndTimestampLatest).toLocaleString({
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}`
      : ''

  const bpAvg7 = useAppSelector((state) => getBloodPressureAverage(state, patientId, 7))
  const bpmAvg7 = useAppSelector((state) => getHeartRateAverage(state, patientId, 7))
  const bpmRange7 = useAppSelector((state) => getHeartRateRange(state, patientId, 7))
  const sbpRange7 = useAppSelector((state) => getSystolicRange(state, patientId, 7))
  const dbpRange7 = useAppSelector((state) => getDiastolicRange(state, patientId, 7))
  const bp12MAEndTimestamp7 = useAppSelector((state) => getBloodPressureAverageLatestTime(state, patientId, 7))
  const bp12MAStartTimestamp7 = useAppSelector((state) => getBloodPressureAverageEarliestTime(state, patientId, 7))
  const bp12MADateRange7 =
    bp12MAEndTimestamp7 && bp12MAStartTimestamp7
      ? `${DateTime.fromISO(bp12MAStartTimestamp7).toLocaleString({
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })} - ${DateTime.fromISO(bp12MAEndTimestamp7).toLocaleString({
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}`
      : ''

  const bpAvg28 = useAppSelector((state) => getBloodPressureAverage(state, patientId, 28))
  const bpmAvg28 = useAppSelector((state) => getHeartRateAverage(state, patientId, 28))
  const bpmRange28 = useAppSelector((state) => getHeartRateRange(state, patientId, 28))
  const sbpRange28 = useAppSelector((state) => getSystolicRange(state, patientId, 28))
  const dbpRange28 = useAppSelector((state) => getDiastolicRange(state, patientId, 28))
  const bpGoal = useAppSelector((state) => getPatientBloodPressureGoalForDisplay(state, patientId))
  const bp12MAEndTimestamp28 = useAppSelector((state) => getBloodPressureAverageLatestTime(state, patientId, 28))
  const bp12MAStartTimestamp28 = useAppSelector((state) => getBloodPressureAverageEarliestTime(state, patientId, 28))
  const bp12MADateRange28 =
    bp12MAEndTimestamp28 && bp12MAStartTimestamp28
      ? `${DateTime.fromISO(bp12MAStartTimestamp28).toLocaleString({
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })} - ${DateTime.fromISO(bp12MAEndTimestamp28).toLocaleString({
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}`
      : ''

  const isExpanded = useAppSelector((state) => getIsPatientViewSummaryExpanded(state))
  const onExpandCardClicked = useCallback(() => {
    if (isExpanded) {
      // if the card is expanded, and we click on it, we want to collapse it
      track('Patient BP Summary Collapsed')
    } else {
      // if the card is collapsed, and we click on it, we want to expand it
      track('Patient BP Summary Expanded')
    }
    dispatch(togglePatientViewSummaryExpansion())
  }, [dispatch, isExpanded])

  useEffect(() => {
    dispatch(fetchBloodPressureAverages(patientId))
  }, [dispatch, patientId])

  return (
    <SummaryContainer
      data-testid={PATIENT_SUMMARY}
      data-testid-expand={PATIENT_SUMMARY_EXPAND_BUTTON}
      onExpandCollapse={onExpandCardClicked}
      expanded={isExpanded}
      collapsible
      title="Summary"
    >
      <div className="flex flex-col">
        <div className="flex">
          <PatientSummaryCard
            title="Bp Goal"
            className="flex-grow-0 mb-4"
            subtitle={<span className="inline-block h-3"></span>}
          >
            <p className="text-rivaOffblack-900 font-bold text-base">{bpGoal}</p>
          </PatientSummaryCard>
          <BloodPressureBaselineCard className="flex-grow-0 mb-4" personId={personId} />
        </div>
        <PatientSummaryCard
          title="Latest 12-pt moving average window"
          subtitle={bp12MADateRangeLatest}
          className="mb-4"
        >
          <TextBlock text={bpAvgLatest} subtext="BP Average" className="flex-1" />
          <TextBlock text={bpmAvgLatest} subtext="HR Average" className="flex-1" />
          <TextBlock text={sbpRangeLatest} subtext="SBP Range" className="flex-1" />
          <TextBlock text={dbpRangeLatest} subtext="DBP Range" className="flex-1" />
          <TextBlock text={bpmRangeLatest} subtext="HR Range" className="flex-1" />
        </PatientSummaryCard>
        <PatientSummaryCard title="7 days prior to latest 12-MA window" subtitle={bp12MADateRange7} className="mb-4">
          <TextBlock text={bpAvg7} subtext="BP Average" className="flex-1" />
          <TextBlock text={bpmAvg7} subtext="HR Average" className="flex-1" />
          <TextBlock text={sbpRange7} subtext="SBP Range" className="flex-1" />
          <TextBlock text={dbpRange7} subtext="DBP Range" className="flex-1" />
          <TextBlock text={bpmRange7} subtext="HR Range" className="flex-1" />
        </PatientSummaryCard>
        <PatientSummaryCard title="28 days prior to latest 12-MA window" subtitle={bp12MADateRange28} className="mb-4">
          <TextBlock text={bpAvg28} subtext="BP Average" className="flex-1" />
          <TextBlock text={bpmAvg28} subtext="HR Average" className="flex-1" />
          <TextBlock text={sbpRange28} subtext="SBP Range" className="flex-1" />
          <TextBlock text={dbpRange28} subtext="DBP Range" className="flex-1" />
          <TextBlock text={bpmRange28} subtext="HR Range" className="flex-1" />
        </PatientSummaryCard>
      </div>
    </SummaryContainer>
  )
}

export default PatientSummary

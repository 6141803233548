import { ReactElement, useCallback, useEffect } from 'react'
import {} from 'react-router-dom'
import clsx from 'clsx'
import { useInView } from 'react-intersection-observer'
import { Tab, TabList } from '../../ui/tabs'
import useTabs from '../../ui/tabs/use-tabs'
import TabPanel from '../../ui/tabs/tab-panel'
import { I13n } from '../../../types/i13n'
import { PATIENT_VIEW_NAME, PATIENT_VIEW_HEADER_EXPAND_BUTTON } from '../../../consts/data-testids'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { IconSize, SvgIcon } from '../../ui/icon'
import { ReactComponent as ChevronDown } from '../../icons/solid/chevron-down.svg'
import { ReactComponent as ChevronUp } from '../../icons/solid/chevron-up.svg'
import { getIsPatientViewHeaderExpanded } from '../../../redux/selectors/ui'
import { togglePatientViewHeaderExpansion } from '../../../redux/actions/ui'
import {
  doesPatientExist,
  getPatientName,
  getPatientPreferredName,
  getPatientRecordStateById,
} from '../../../redux/selectors/patient'
import { RecordState } from '../../../types/record-state'
import { LoadingIndicator } from '../../ui/loading-indicator'
import { Features } from '../../../types/features'
import { Menu, MenuItem } from '../../ui/menu'
import { track } from '../../../i13n'
import { BloodPressureTabPanel } from './blood-pressure-tab-panel'
import Surveys from './surveys'
import Notes from './notes'
import { Header } from './header'
import PatientDetails from './patient-details'
import PatientActions from './patient-profile-actions'
import AllergiesTab from './allergies-tab'
import MedicationsTab from './medications-tab'
import ProblemsTab from './problems-tab'
import useIsTabEnabled from './use-is-tab-enabled'
import MedicationsPaidTab from './medications-paid-tab'

interface Props {
  patientId: string
  initialSelectedTab?: number
}

const i13nProps = {
  View: 'Patient Profile',
}

// This is a responsive set of tabs that changes to a menu when there is not
// enough free space for the tabs. We override the i13n events for the menu
// items so that the responsive nature of this menu is hidden from instrumentation
const measurementsI13n: I13n = {
  eventName: 'Tab Clicked',
  properties: {
    Tab: 'BP Measurements',
    ...i13nProps,
  },
}
const notesI13n: I13n = {
  eventName: 'Tab Clicked',
  properties: {
    Tab: 'Notes',
    ...i13nProps,
  },
}
const surveysI13n: I13n = {
  eventName: 'Tab Clicked',
  properties: {
    Tab: 'Surveys',
    ...i13nProps,
  },
}
const allergiesI13n: I13n = {
  eventName: 'Tab Clicked',
  properties: {
    Tab: 'Allergies',
    ...i13nProps,
  },
}
const medicationsI13n: I13n = {
  eventName: 'Tab Clicked',
  properties: {
    Tab: 'Medications',
    ...i13nProps,
  },
}
const problemsI13n: I13n = {
  eventName: 'Tab Clicked',
  properties: {
    Tab: 'Problems',
    ...i13nProps,
  },
}
const patientDetailsI13n: I13n = {
  eventName: 'Tab Clicked',
  properties: {
    Tab: 'Patient details',
    ...i13nProps,
  },
}

const PatientProfile = ({ initialSelectedTab, patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const { getTabListProps, getTabPanelProps, getTabProps, selectedIndex } = useTabs({
    initialSelectedIndex: initialSelectedTab,
  })
  const name = useAppSelector((state) => getPatientName(state, patientId))
  const preferredName = useAppSelector((state) => getPatientPreferredName(state, patientId))
  const patientExists = useAppSelector((state) => doesPatientExist(state, patientId))
  const recordState = useAppSelector((state) => getPatientRecordStateById(state, patientId))
  const isExpanded = useAppSelector((state) => getIsPatientViewHeaderExpanded(state))
  const allergiesTabEnabled = useIsTabEnabled(Features.ALLERGIES_TAB, patientId)
  const medicationsTabEnabled = useIsTabEnabled(Features.MEDICATIONS_TAB, patientId)
  const medicationsPaidTabEnabled = useIsTabEnabled(Features.MEDICATIONS_PAID_TAB, patientId)
  const problemsTabEnabled = useIsTabEnabled(Features.PROBLEMS_TAB, patientId)
  const onExpandHeaderClicked = useCallback(() => {
    if (isExpanded) {
      // if the card is expanded, and we click on it, we want to collapse it
      track('Patient Header Collapsed')
    } else {
      // if the card is collapsed, and we click on it, we want to expand it
      track('Patient Header Expanded')
    }
    dispatch(togglePatientViewHeaderExpansion())
  }, [isExpanded, dispatch])

  const { inView: notesInView, ref: notesRef } = useInView({ initialInView: true })
  const { inView: surveysInView, ref: surveysRef } = useInView({ initialInView: true })
  const { inView: medicationsInView, ref: medicationsRef } = useInView({ initialInView: true })
  const { inView: allergiesInView, ref: allergiesRef } = useInView({ initialInView: true })
  const { inView: problemsInView, ref: problemsRef } = useInView({ initialInView: true })
  const { inView: detailsInView, ref: detailsRef } = useInView({ initialInView: true })

  const selectedInMoreMenu =
    (selectedIndex === 6 && !detailsInView) ||
    (selectedIndex === 5 && !problemsInView) ||
    (selectedIndex === 4 && !allergiesInView) ||
    (selectedIndex === 3 && !medicationsInView) ||
    (selectedIndex === 2 && !surveysInView) ||
    (selectedIndex === 1 && !notesInView)

  const isLoading = !patientExists && recordState === RecordState.LOADING

  useEffect(() => {
    if (isLoading) {
      return
    }

    track('Patient Profile Viewed', {
      Patientid: patientId,
    })
  }, [isLoading, patientId])

  if (isLoading) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center overflow-hidden bg-white">
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <div className="flex-1 flex flex-col overflow-hidden bg-white">
      <PatientActions patientId={patientId} />
      <h1 data-testid={PATIENT_VIEW_NAME} className="text-lg font-semibold flex items-center px-7 pt-7 pb-5">
        <button
          data-testid={PATIENT_VIEW_HEADER_EXPAND_BUTTON}
          className="flex items-center w-full justify-between"
          onClick={onExpandHeaderClicked}
        >
          <span>{preferredName ? `${name} (${preferredName})` : name}</span>
          <SvgIcon Icon={isExpanded ? ChevronDown : ChevronUp} size={IconSize.MEDIUM} />
        </button>
      </h1>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="border-b border-rivaOffblack-300 border-dashed">
          <Header patientId={patientId} isExpanded={isExpanded} />
        </div>
        <div className="bg-white flex-none flex border-b border-b-rivaOffblack-200 h-14 overflow-hidden">
          <TabList {...getTabListProps()} hideBar={selectedInMoreMenu}>
            <Tab i13n={measurementsI13n} {...getTabProps(0)}>
              BP Measurements
            </Tab>
            <Tab i13n={notesI13n} {...getTabProps(1)} ref={notesRef}>
              Notes
            </Tab>
            <Tab i13n={surveysI13n} {...getTabProps(2)} ref={surveysRef}>
              Surveys
            </Tab>
            {medicationsTabEnabled || medicationsPaidTabEnabled ? (
              <Tab i13n={medicationsI13n} {...getTabProps(3)} ref={medicationsRef}>
                Medications
              </Tab>
            ) : null}
            {allergiesTabEnabled ? (
              <Tab i13n={allergiesI13n} {...getTabProps(4)} ref={allergiesRef}>
                Allergies
              </Tab>
            ) : null}
            {problemsTabEnabled ? (
              <Tab i13n={problemsI13n} {...getTabProps(5)} ref={problemsRef}>
                Problems
              </Tab>
            ) : null}
            <Tab i13n={patientDetailsI13n} {...getTabProps(6)} ref={detailsRef}>
              Patient details
            </Tab>
          </TabList>
          {!detailsInView ? (
            <>
              <div className="w-[1px] h-6 mt-4 mx-5 bg-rivaOffblack-400 flex-none"></div>
              <Menu
                align="end"
                menuButton={({ open }) => {
                  return (
                    <button
                      className={clsx(
                        'text-sm pt-4 pb-3 mr-5 box-border inline-block font-semibold border-b-4 flex-none',
                        {
                          'text-rivaOffblack-900 border-b-transparent': !selectedInMoreMenu && !open,
                          'text-rivaOffblack-900 border-b-rivaOffblack-900': !selectedInMoreMenu && open,
                          'text-rivaPurple-500 border-b-rivaPurple-500': selectedInMoreMenu,
                        },
                      )}
                      role="tab"
                      aria-selected={selectedInMoreMenu}
                    >
                      More
                    </button>
                  )
                }}
                portal
              >
                {!notesInView ? (
                  <MenuItem i13n={notesI13n} {...getTabProps(1)}>
                    Notes
                  </MenuItem>
                ) : null}
                {!surveysInView ? (
                  <MenuItem i13n={surveysI13n} {...getTabProps(2)}>
                    Surveys
                  </MenuItem>
                ) : null}
                {!medicationsInView && (medicationsTabEnabled || medicationsPaidTabEnabled) ? (
                  <MenuItem i13n={medicationsI13n} {...getTabProps(3)}>
                    Medications
                  </MenuItem>
                ) : null}
                {!allergiesInView && allergiesTabEnabled ? (
                  <MenuItem i13n={allergiesI13n} {...getTabProps(4)}>
                    Allergies
                  </MenuItem>
                ) : null}
                {!problemsInView && problemsTabEnabled ? (
                  <MenuItem i13n={problemsI13n} {...getTabProps(5)}>
                    Problems
                  </MenuItem>
                ) : null}
                {!detailsInView ? (
                  <MenuItem i13n={patientDetailsI13n} {...getTabProps(6)}>
                    Patient details
                  </MenuItem>
                ) : null}
              </Menu>
            </>
          ) : null}
        </div>
        <TabPanel {...getTabPanelProps(0)} className="flex-1 flex flex-col overflow-y-auto">
          <BloodPressureTabPanel patientId={patientId} />
        </TabPanel>
        <TabPanel {...getTabPanelProps(1)} className="flex-1 flex flex-col overflow-y-auto">
          <Notes patientId={patientId} />
        </TabPanel>
        <TabPanel {...getTabPanelProps(2)} className="flex-1 flex flex-col overflow-y-auto">
          <Surveys patientId={patientId} />
        </TabPanel>
        {medicationsTabEnabled ? (
          <TabPanel {...getTabPanelProps(3)} className="flex-1 flex flex-col overflow-y-auto">
            <MedicationsTab patientId={patientId} />
          </TabPanel>
        ) : null}
        {medicationsPaidTabEnabled ? (
          <TabPanel {...getTabPanelProps(3)} className="flex-1 flex flex-col overflow-y-auto">
            <MedicationsPaidTab patientId={patientId} />
          </TabPanel>
        ) : null}
        {allergiesTabEnabled ? (
          <TabPanel {...getTabPanelProps(4)} className="flex-1 flex flex-col overflow-y-auto">
            <AllergiesTab patientId={patientId} />
          </TabPanel>
        ) : null}
        {problemsTabEnabled ? (
          <TabPanel {...getTabPanelProps(5)} className="flex-1 flex flex-col overflow-y-auto">
            <ProblemsTab patientId={patientId} />
          </TabPanel>
        ) : null}
        <TabPanel {...getTabPanelProps(6)} className="flex-1 flex flex-col overflow-y-auto">
          <PatientDetails patientId={patientId} />
        </TabPanel>
      </div>
    </div>
  )
}

export default PatientProfile

import { useMemo } from 'react'
import { useLocation, matchPath, match as MatchType } from 'react-router-dom'
import { useAppSelector } from '../../redux'
import { getLoggedInPersonId } from '../../redux/selectors/app'
import { getPractitionerByPersonId } from '../../redux/selectors/practitioner'
import { ListContentType } from '../../types/lists'
import { TaskQueryParam } from '../../types/task'
import {
  MAX_PATIENTS_PER_PAGE,
  buildListId,
  buildPatientListId,
  buildPatientListIdPaged,
  buildTaskListId,
  defaultSearchListId,
  parseSerializedListId,
} from '../../utils/lists'
import { getTaskStatusFromQueryParam } from '../../utils/tasks'
import { BloodPressureManagementStatus } from '../../types/patient'
import { Routes } from '../../types/route'

function patientList(match: MatchType<Record<string, string>>): string {
  const { serializedListId } = match.params
  const descriptor = parseSerializedListId(serializedListId)

  return buildPatientListId(descriptor)
}

const useGetListIdFromRoute = (): string => {
  const location = useLocation()
  const loggedInPersonId = useAppSelector((state) => getLoggedInPersonId(state))
  const practitionerId = useAppSelector((state) => getPractitionerByPersonId(state, loggedInPersonId)?.id)

  return useMemo(() => {
    let match = matchPath<Record<string, string>>(location.pathname, Routes.DASHBOARD)

    if (match) {
      return patientList(match)
    }

    match = matchPath<Record<string, string>>(location.pathname, Routes.PATIENT_LIST)

    if (match) {
      const { serializedListId } = match.params
      const descriptor = parseSerializedListId(serializedListId)

      return buildPatientListIdPaged(descriptor)
    }

    match = matchPath(location.pathname, '/patients')

    if (match) {
      return buildPatientListIdPaged({
        limit: MAX_PATIENTS_PER_PAGE,
        offset: 0,
        managementStatus: BloodPressureManagementStatus.ACTIVE_MANAGEMENT,
        practitionerId: practitionerId ? [practitionerId] : undefined,
      })
    }

    match = matchPath(location.pathname, Routes.SEARCH)

    if (match) {
      const { serializedListId } = match.params

      if (!serializedListId) {
        return defaultSearchListId
      }

      return buildPatientListIdPaged(parseSerializedListId(serializedListId))
    }

    match = matchPath<Record<string, string>>(location.pathname, '/surveys/:query?')

    if (match) {
      return buildListId({
        listContentType: ListContentType.SURVEY_SUBMISSION,
        query: match.params.query,
      })
    }

    match = matchPath<Record<string, string>>(location.pathname, '/tasks/:query?/:serializedListId?')

    if (match) {
      const { query, serializedListId } = match.params
      const descriptor = parseSerializedListId(serializedListId)
      descriptor.query = (query as TaskQueryParam) || TaskQueryParam.OPEN

      if (query === TaskQueryParam.OPEN || query === TaskQueryParam.CLOSED) {
        if (!descriptor.practitionerId && practitionerId) {
          descriptor.practitionerId = [practitionerId]
        }
        descriptor.taskStatus = getTaskStatusFromQueryParam(query)
      }

      if (query === TaskQueryParam.CLOSED || query === TaskQueryParam.ALL) {
        descriptor.sortOrder = 'DESC'
      }

      if (query === TaskQueryParam.OPEN) {
        descriptor.sortPriority = true
      }

      return buildTaskListId(descriptor)
    }

    return buildListId({
      listContentType: ListContentType.NONE,
    })
  }, [location.pathname, practitionerId])
}

export default useGetListIdFromRoute

import { ReactElement } from 'react'
import clsx from 'clsx'
import { Table, TableBody, TableHeader, TableRow } from '../../ui/table'
import { useAppSelector } from '../../../redux'
import { getAllergyIntolerance } from '../../../redux/selectors/allergy-intolerance'
import { TableTheme, TableThemeContext } from '../../ui/table/theme'

const headers = [
  { text: 'Allergy', className: 'w-[172px] flex-none' },
  { text: 'Reaction', className: 'flex-1' },
  { text: 'Severity', className: 'w-[112px] flex-none' },
]

interface Props {
  patientId: string
  theme?: TableTheme
}

const AllergyTable = ({ patientId, theme }: Props): ReactElement => {
  const allergyIntolerance = useAppSelector((state) => getAllergyIntolerance(state, patientId))

  return (
    <Table theme={theme}>
      <TableHeader headers={headers} />
      <TableBody>
        {allergyIntolerance?.map((allergyIntolerance) => {
          if (!allergyIntolerance) {
            return null
          }

          return (
            <TableRow className="flex font-medium text-sm text-rivaOffblack-700" key={allergyIntolerance.id}>
              <div role="cell" className="w-[164px] mr-5 flex-none capitalize">
                {allergyIntolerance.code?.text}
              </div>
              <div role="cell" className="flex-1">
                <TableThemeContext.Consumer>
                  {(theme) => (
                    <ul
                      className={clsx('list-disc pl-4 min-w-[240px]', {
                        'text-rivaOffblack-300': theme === TableTheme.DARK,
                      })}
                    >
                      {allergyIntolerance.reaction ? (
                        allergyIntolerance.reaction?.map((reaction) => {
                          if (!reaction) {
                            return null
                          }

                          return (
                            <li key={reaction.id}>
                              {reaction.description?.charAt(0).toUpperCase()}
                              {reaction.description?.substring(1)}
                            </li>
                          )
                        })
                      ) : (
                        <li>Not specified</li>
                      )}
                    </ul>
                  )}
                </TableThemeContext.Consumer>
              </div>
              <div role="cell" className="w-[100px] ml-5 flex-none">
                <TableThemeContext.Consumer>
                  {(theme) => (
                    <ul
                      className={clsx({
                        'text-rivaOffblack-300': theme === TableTheme.DARK,
                      })}
                    >
                      {allergyIntolerance.reaction ? (
                        allergyIntolerance.reaction?.map((reaction) => {
                          if (!reaction) {
                            return null
                          }

                          return <li key={reaction.id}>{reaction.severity || 'Not specified'}</li>
                        })
                      ) : (
                        <li>Not specified</li>
                      )}
                    </ul>
                  )}
                </TableThemeContext.Consumer>
              </div>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default AllergyTable

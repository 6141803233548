import { ReactElement } from 'react'
import { useAppSelector } from '../../../../../redux'
import { getLoggedInPractitionerId } from '../../../../../redux/selectors/app'
import { getPractitionerByPersonId } from '../../../../../redux/selectors/practitioner'
import { TaskEvent } from '../../../../../types/task'
import FormattedDateTime from '../../../../ui/formatted-date-time'
import NaturalTime from '../../../../ui/natural-time'
import PractitionerAvatar from '../../../../ui/practitioner-avatar'
import PractitionerReference from '../../../../ui/practitioner-reference'
import { TASK_LIST_EVENT_ALERT } from '../../../../../consts/data-testids'
import SystemAvatar from '../../../../ui/system-avatar'
import { SvgIcon } from '../../../../ui/icon'
import { ReactComponent as CalendarIcon } from '../../../../icons/outline/calendar.svg'
import { ReactComponent as XCircleIcon } from '../../../../icons/outline/x-circle.svg'
import { TaskEventType } from '../../../../../__generated__/graphql'
import { CarePathwayEvent } from '../../../../../types/care-pathway'

interface Props {
  event: TaskEvent | CarePathwayEvent
}

const TaskAlertItem = ({ event }: Props): ReactElement | null => {
  const { eventType } = event
  const loggedInPractitionerId = useAppSelector(getLoggedInPractitionerId)
  const createdBy = useAppSelector((state) => getPractitionerByPersonId(state, event.created.by ?? '')?.id ?? '')

  // The intention here is to avoid having a big yellow box that only says
  // "you assigned this to yourself". However if someone wrote a note for themselves,
  // we should display it anyway
  if (createdBy === loggedInPractitionerId && !event.note) {
    return null
  }

  return (
    <div
      className="mt-4 px-5 py-4 grid grid-cols-[24px_1fr_auto] gap-x-2 gap-y-3 bg-rivaYellow-50 border-l-2 border-rivaYellow-700"
      data-eventid={event.id}
      data-testid={TASK_LIST_EVENT_ALERT}
    >
      {eventType === TaskEventType.VisitCancelled ? (
        <SvgIcon Icon={XCircleIcon} className="text-rivaYellow-700" />
      ) : eventType === TaskEventType.VisitRescheduled ? (
        <SvgIcon Icon={CalendarIcon} className="text-rivaYellow-700" />
      ) : createdBy ? (
        <PractitionerAvatar border colorVariant="alert" practitionerId={createdBy} />
      ) : (
        <SystemAvatar />
      )}
      <span className="text-sm font-medium">
        {eventType === TaskEventType.AssignTask ? (
          <>
            <PractitionerReference practitionerId={createdBy} /> reassigned this task to{' '}
            {createdBy === event.data.assignee ? 'yourself' : 'you'}
          </>
        ) : eventType === TaskEventType.VisitCancelled ? (
          <>This appointment was cancelled</>
        ) : eventType === TaskEventType.VisitRescheduled ? (
          <>
            This appointment was rescheduled to{' '}
            <FormattedDateTime
              value={event.data.startTime}
              day="numeric"
              month="numeric"
              year="numeric"
              hour="numeric"
              minute="numeric"
            />
          </>
        ) : eventType === TaskEventType.CreateCarePathway ? (
          <>
            <PractitionerReference practitionerId={createdBy} /> created this task for you
          </>
        ) : null}
      </span>
      <NaturalTime className="text-sm font-medium" value={event.created.at} />
      {event.note ? (
        <p className="col-span-3 bg-white px-4 py-3 rounded border border-rivaOffblack-200 text-rivaOffblack-700 text-sm font-medium">
          {event.note}
        </p>
      ) : null}
    </div>
  )
}

export default TaskAlertItem

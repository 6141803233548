import { ReactElement, useCallback, useState } from 'react'
import { Button, ButtonVariant } from '../../../ui/button'
import { useAppSelector } from '../../../../redux'
import { getTaskAssignee } from '../../../../redux/selectors/tasks'
import PractitionerAvatar from '../../../ui/practitioner-avatar'
import { getPractitionerName } from '../../../../redux/selectors/practitioner'
import ReassignTaskModal from '../../../modals/reassign-task'
import { TASK_DETAILS_ASSIGN_TASK } from '../../../../consts/data-testids'

interface Props {
  listId: string
  taskId: string
}

const I13N_VIEW = {
  View: 'Task Details',
}

const TaskDetailsAssignee = ({ listId, taskId }: Props): ReactElement => {
  const assignee = useAppSelector((state) => getTaskAssignee(state, taskId))
  const assigneeName = useAppSelector((state) => getPractitionerName(state, assignee))
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onClick = useCallback(() => setIsModalOpen(true), [])
  const onModalCLose = useCallback(() => setIsModalOpen(false), [])

  return (
    <Button
      className="mt-2 !px-3 max-w-full"
      data-testid={TASK_DETAILS_ASSIGN_TASK}
      onClick={onClick}
      variant={ButtonVariant.SECONDARY}
    >
      <PractitionerAvatar practitionerId={assignee} />
      <span className="ml-2 truncate">{assigneeName}</span>
      {isModalOpen ? (
        <ReassignTaskModal
          i13n={I13N_VIEW}
          isOpen
          listId={listId}
          onClose={onModalCLose}
          taskId={taskId}
          key={taskId}
        />
      ) : null}
    </Button>
  )
}

export default TaskDetailsAssignee

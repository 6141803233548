import { RefObject, useEffect } from 'react'

const useOnClickOutside = (ref: RefObject<HTMLElement>, callback: EventListener): void => {
  useEffect(() => {
    const onClick = (event: Event) => {
      const target = event.target as HTMLElement

      if (ref.current?.contains(target) || ref.current?.contains(target)) {
        return
      }

      callback(event)
    }

    document.body.addEventListener('click', onClick)

    return () => {
      document.body.removeEventListener('click', onClick)
    }
  }, [callback, ref])
}

export default useOnClickOutside

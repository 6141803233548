import Mustache from 'mustache'
import { ReactElement, useMemo } from 'react'
import { TaskAction } from '../../../../../types/task'
import { ButtonVariant, ExternalLinkButton } from '../../../../ui/button'
import useGetTemplateData from '../use-get-template-data'

interface Props {
  action: TaskAction
  taskId: string
}

const ExternalLinkAction = ({ action, taskId }: Props): ReactElement | null => {
  const templateData = useGetTemplateData({ taskId })
  const url = useMemo(
    () => Mustache.render(action.target?.urlTemplate ?? '', templateData),
    [action.target, templateData],
  )

  if (!action.target) {
    return null
  }

  return (
    <ExternalLinkButton className="ml-2 mt-4" href={url} target="_blank" variant={ButtonVariant.SECONDARY}>
      {action.target.name}
    </ExternalLinkButton>
  )
}

export default ExternalLinkAction

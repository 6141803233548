/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Any: { input: unknown; output: unknown }
  CivilDate: { input: string; output: string }
  Time: { input: string; output: string }
}

/**
 * An address expressed using postal conventions (as opposed to GPS or other location definition formats).
 * This data type may be used to convey addresses for use in delivering mail as well as for visiting locations
 * which might not be valid for mail delivery. There are a variety of postal address formats defined around the world.
 */
export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  district?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  line?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  period?: Maybe<Period>
  postalCode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
}

/** A duration of time during which an organism (or a process) has existed. */
export type Age = {
  __typename?: 'Age'
  code?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  system?: Maybe<Scalars['String']['output']>
  unit?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

/**
 * Risk of harmful or undesirable, physiological response which is unique to an individual and
 * associated with exposure to a substance.
 */
export type AllergyIntolerance = {
  __typename?: 'AllergyIntolerance'
  asserter?: Maybe<Reference>
  category: Array<Maybe<Scalars['String']['output']>>
  clinicalStatus?: Maybe<CodeableConcept>
  code?: Maybe<CodeableConcept>
  id: Scalars['ID']['output']
  lastOccurrence?: Maybe<Scalars['String']['output']>
  note?: Maybe<Array<Maybe<Annotation>>>
  onsetDateTime?: Maybe<Scalars['String']['output']>
  onsetPeriod?: Maybe<Period>
  onsetRange?: Maybe<Range>
  onsetString?: Maybe<Scalars['String']['output']>
  reaction?: Maybe<Array<Maybe<AllergyIntoleranceReaction>>>
  recordedDate?: Maybe<Scalars['String']['output']>
  recorder?: Maybe<Reference>
  type?: Maybe<Scalars['String']['output']>
  verificationStatus?: Maybe<CodeableConcept>
}

/**
 * Risk of harmful or undesirable, physiological response which is unique to an individual and
 * associated with exposure to a substance.
 */
export type AllergyIntoleranceReaction = {
  __typename?: 'AllergyIntoleranceReaction'
  description?: Maybe<Scalars['String']['output']>
  exposureRoute?: Maybe<CodeableConcept>
  id: Scalars['ID']['output']
  manifestation?: Maybe<Array<Maybe<CodeableConcept>>>
  note?: Maybe<Array<Maybe<Annotation>>>
  onset?: Maybe<Scalars['String']['output']>
  severity?: Maybe<Scalars['String']['output']>
  substance?: Maybe<CodeableConcept>
}

/** A text note which also contains information about who made the statement and when. */
export type Annotation = {
  __typename?: 'Annotation'
  authorReference?: Maybe<Reference>
  authorString?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  text?: Maybe<Scalars['String']['output']>
  time?: Maybe<Scalars['String']['output']>
}

export type AthenaProfile = {
  __typename?: 'AthenaProfile'
  address1?: Maybe<Scalars['String']['output']>
  assignedAtBirthSex?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  consentToCall?: Maybe<Scalars['Boolean']['output']>
  consentToText?: Maybe<Scalars['Boolean']['output']>
  contactMobilePhone: Scalars['String']['output']
  contactName?: Maybe<Scalars['String']['output']>
  contactRelationship?: Maybe<Scalars['String']['output']>
  dob: Scalars['String']['output']
  driversLicenseExpirationDate?: Maybe<Scalars['String']['output']>
  driversLicenseNumber?: Maybe<Scalars['String']['output']>
  driversLicenseStateId?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  ethnicityCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  language6392Code?: Maybe<Scalars['String']['output']>
  lastName: Scalars['String']['output']
  maritalStatus?: Maybe<Scalars['String']['output']>
  preferredName?: Maybe<Scalars['String']['output']>
  pronouns?: Maybe<Scalars['String']['output']>
  race?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  sex?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

/** For referring to data content defined in other formats. */
export type Attachment = {
  __typename?: 'Attachment'
  contentType?: Maybe<Scalars['String']['output']>
  creation?: Maybe<Scalars['String']['output']>
  data?: Maybe<Scalars['String']['output']>
  hash?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  language?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Float']['output']>
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type AuditStatus = {
  __typename?: 'AuditStatus'
  at: Scalars['Time']['output']
  by: Scalars['String']['output']
}

export type BloodPressureEscalationCategory = {
  __typename?: 'BloodPressureEscalationCategory'
  reasons: Array<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type BloodPressureMeasurement = {
  __typename?: 'BloodPressureMeasurement'
  beatsPerMinute: Scalars['Float']['output']
  bloodPressureEscalationCategory?: Maybe<BloodPressureEscalationCategory>
  bloodPressureEscalationId?: Maybe<Scalars['String']['output']>
  bloodPressureSet?: Maybe<BloodPressureSet>
  bloodPressureSetId: Scalars['String']['output']
  comment?: Maybe<Scalars['String']['output']>
  created: AuditStatus
  deviceType: Scalars['String']['output']
  diastolicBloodPressure: Scalars['Float']['output']
  escalation?: Maybe<CarePathway>
  id: Scalars['ID']['output']
  patient: Patient
  patientId: Scalars['String']['output']
  personId: Scalars['String']['output']
  photo?: Maybe<PhotoUploadStatus>
  systolicBloodPressure: Scalars['Float']['output']
  timeZone: Scalars['String']['output']
  type: BloodPressureMeasurementType
  updated: AuditStatus
}

export enum BloodPressureMeasurementType {
  Cuff = 'CUFF',
  RivaApp = 'RIVA_APP',
  RivaBaseline = 'RIVA_BASELINE',
  WirelessCuff = 'WIRELESS_CUFF',
}

export type BloodPressureSet = {
  __typename?: 'BloodPressureSet'
  bloodPressureMeasurementIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  bloodPressureMeasurementStatus?: Maybe<Scalars['String']['output']>
  cancelledAt?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  completedAt?: Maybe<Scalars['String']['output']>
  contextType?: Maybe<Scalars['String']['output']>
  created: AuditStatus
  id: Scalars['ID']['output']
  locationType?: Maybe<Scalars['String']['output']>
  patient: Patient
  patientId: Scalars['String']['output']
  person: Person
  personId: Scalars['String']['output']
  timeZone: Scalars['String']['output']
  updated: AuditStatus
}

export type CarePathway = {
  __typename?: 'CarePathway'
  created: AuditStatus
  description: Scalars['String']['output']
  events?: Maybe<Array<TaskEvent>>
  id: Scalars['ID']['output']
  patient: Patient
  patientId: Scalars['String']['output']
  status: CarePathwayStatus
  summary: Scalars['String']['output']
  tasks?: Maybe<Array<Task>>
  type: CarePathwayType
  updated: AuditStatus
}

export type CarePathwayTasksArgs = {
  status?: InputMaybe<Array<TaskStatus>>
  type?: InputMaybe<Array<TaskType>>
}

export enum CarePathwayStatus {
  Cancelled = 'CANCELLED',
  Cancelling = 'CANCELLING',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Errored = 'ERRORED',
  InProgress = 'IN_PROGRESS',
}

export enum CarePathwayType {
  HighBloodPressureEscalation = 'HIGH_BLOOD_PRESSURE_ESCALATION',
  LifestyleChange = 'LIFESTYLE_CHANGE',
  LowBloodPressureEscalation = 'LOW_BLOOD_PRESSURE_ESCALATION',
  MedicationChangePrototype = 'MEDICATION_CHANGE_PROTOTYPE',
  Onboarding = 'ONBOARDING',
  OnboardingD2C = 'ONBOARDING_D2C',
  OnboardingV2 = 'ONBOARDING_V2',
  ReviewBloodPresssureReading = 'REVIEW_BLOOD_PRESSSURE_READING',
  VisitNotesReview = 'VISIT_NOTES_REVIEW',
}

/**
 * A concept that may be defined by a formal reference to a terminology or ontology or may be provided by text.
 *
 * Confused? Well, imagine that the clinician entered “atopic asthma” and you wanted to represent that in a problem list.
 * You might want to code that as “Allergic Asthma” in snomed (389145006) and “Extrinsic asthma – unspecified” in ICD-9 (493.00).
 * The codeableConcept allows you to have both of these codes, plus the original text entered by the clinician,
 * and upon which the encoding was performed.
 *
 * https://fhirblog.com/2013/10/22/fhir-coding-and-the-codeableconcept-datatype/
 */
export type CodeableConcept = {
  __typename?: 'CodeableConcept'
  coding?: Maybe<Array<Maybe<Coding>>>
  text: Scalars['String']['output']
}

/** A reference to a code defined by a terminology system. */
export type Coding = {
  __typename?: 'Coding'
  code?: Maybe<Scalars['String']['output']>
  display?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  system?: Maybe<Scalars['String']['output']>
  userSelected?: Maybe<Scalars['Boolean']['output']>
  version?: Maybe<Scalars['String']['output']>
}

/**
 * A clinical condition, problem, diagnosis, or other event, situation, issue, or clinical concept
 * that has risen to a level of concern.
 */
export type Condition = {
  __typename?: 'Condition'
  abatementAge?: Maybe<Age>
  abatementDateTime?: Maybe<Scalars['String']['output']>
  abatementPeriod?: Maybe<Period>
  abatementRange?: Maybe<Range>
  abatementString?: Maybe<Scalars['String']['output']>
  asserter?: Maybe<Reference>
  bodySite?: Maybe<Array<Maybe<CodeableConcept>>>
  category?: Maybe<Array<Maybe<CodeableConcept>>>
  clinicalStatus?: Maybe<CodeableConcept>
  code?: Maybe<CodeableConcept>
  encounter?: Maybe<Reference>
  evidence?: Maybe<Array<Maybe<ConditionEvidence>>>
  id?: Maybe<Scalars['ID']['output']>
  meta?: Maybe<Meta>
  note?: Maybe<Array<Maybe<Annotation>>>
  onsetAge?: Maybe<Age>
  onsetDateTime?: Maybe<Scalars['String']['output']>
  onsetPeriod?: Maybe<Period>
  onsetRange?: Maybe<Range>
  onsetString?: Maybe<Scalars['String']['output']>
  recordedDate?: Maybe<Scalars['String']['output']>
  recorder?: Maybe<Reference>
  severity?: Maybe<CodeableConcept>
  stage?: Maybe<Array<Maybe<ConditionStage>>>
  subject?: Maybe<Reference>
  text?: Maybe<Narrative>
  verificationStatus?: Maybe<CodeableConcept>
}

/**
 * A clinical condition, problem, diagnosis, or other event, situation, issue, or clinical concept
 * that has risen to a level of concern.
 */
export type ConditionEvidence = {
  __typename?: 'ConditionEvidence'
  code?: Maybe<Array<Maybe<CodeableConcept>>>
  detail?: Maybe<Array<Maybe<Reference>>>
  id?: Maybe<Scalars['ID']['output']>
}

/**
 * A clinical condition, problem, diagnosis, or other event, situation, issue, or clinical concept
 * that has risen to a level of concern.
 */
export type ConditionStage = {
  __typename?: 'ConditionStage'
  assessment?: Maybe<Array<Maybe<Reference>>>
  id?: Maybe<Scalars['ID']['output']>
  summary?: Maybe<CodeableConcept>
  type?: Maybe<CodeableConcept>
}

/** Details for all kinds of technology mediated contact points for a person or organization, including telephone, email, etc. */
export type ContactPoint = {
  __typename?: 'ContactPoint'
  id?: Maybe<Scalars['ID']['output']>
  period?: Maybe<Period>
  rank?: Maybe<Scalars['Float']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type Dosage = {
  __typename?: 'Dosage'
  additionalInstruction?: Maybe<Array<Maybe<CodeableConcept>>>
  asNeededBoolean?: Maybe<Scalars['Boolean']['output']>
  asNeededCodeableConcept?: Maybe<CodeableConcept>
  doseAndRate?: Maybe<Array<Maybe<DosageDoseAndRate>>>
  maxDosePerAdministration?: Maybe<Quantity>
  maxDosePerLifetime?: Maybe<Quantity>
  maxDosePerPeriod?: Maybe<Ratio>
  method?: Maybe<CodeableConcept>
  patientInstruction?: Maybe<Scalars['String']['output']>
  route?: Maybe<CodeableConcept>
  sequence?: Maybe<Scalars['Float']['output']>
  site?: Maybe<CodeableConcept>
  text?: Maybe<Scalars['String']['output']>
  timing?: Maybe<Timing>
}

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRate = {
  __typename?: 'DosageDoseAndRate'
  doseQuantity?: Maybe<Quantity>
  doseRange?: Maybe<Range>
  rateQuantity?: Maybe<Quantity>
  rateRange?: Maybe<Range>
  rateRatio?: Maybe<Ratio>
  type?: Maybe<CodeableConcept>
}

/** A length of time. */
export type Duration = {
  __typename?: 'Duration'
  code?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  system?: Maybe<Scalars['String']['output']>
  unit?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

/** FHIR model, we'll need to migrate this model */
export type EhrPatientRecord = {
  __typename?: 'EHRPatientRecord'
  active?: Maybe<Scalars['Boolean']['output']>
  address?: Maybe<Array<Maybe<Address>>>
  birthDate?: Maybe<Scalars['String']['output']>
  communication?: Maybe<Array<Maybe<PatientCommunication>>>
  contact?: Maybe<Array<Maybe<PatientContact>>>
  deceasedBoolean?: Maybe<Scalars['Boolean']['output']>
  deceasedDateTime?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['String']['output']>
  generalPractitioner?: Maybe<Array<Maybe<Reference>>>
  id?: Maybe<Scalars['ID']['output']>
  language?: Maybe<Scalars['String']['output']>
  link?: Maybe<Array<Maybe<PatientLink>>>
  managingOrganization?: Maybe<Reference>
  maritalStatus?: Maybe<CodeableConcept>
  meta?: Maybe<Meta>
  multipleBirthBoolean?: Maybe<Scalars['Boolean']['output']>
  multipleBirthInteger?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Array<Maybe<HumanName>>>
  photo?: Maybe<Array<Maybe<Attachment>>>
  telecom?: Maybe<Array<Maybe<ContactPoint>>>
  text?: Maybe<Narrative>
}

export type Event = {
  __typename?: 'Event'
  created: AuditStatus
  data: Scalars['Any']['output']
}

export enum ExternalServiceProvider {
  Acuity = 'ACUITY',
  SurveySparrow = 'SURVEY_SPARROW',
}

/** A human's name with the ability to identify parts and usage. */
export type HumanName = {
  __typename?: 'HumanName'
  family?: Maybe<Scalars['String']['output']>
  given?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  id?: Maybe<Scalars['ID']['output']>
  period?: Maybe<Period>
  prefix?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  suffix?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  text?: Maybe<Scalars['String']['output']>
}

export type Identifier = {
  __typename?: 'Identifier'
  assigner?: Maybe<Reference>
  id?: Maybe<Scalars['ID']['output']>
  period?: Maybe<Period>
  system?: Maybe<Scalars['String']['output']>
  type?: Maybe<CodeableConcept>
  value?: Maybe<Scalars['String']['output']>
}

/** Proprietary Athena medication definition */
export type Medication = {
  __typename?: 'Medication'
  approvedby?: Maybe<Scalars['String']['output']>
  billingndc?: Maybe<Scalars['String']['output']>
  createdby?: Maybe<Scalars['String']['output']>
  earliestfilldate?: Maybe<Scalars['String']['output']>
  encounterid?: Maybe<Scalars['Int']['output']>
  events: Array<Maybe<MedicationEvent>>
  futuresubmitdate?: Maybe<Scalars['String']['output']>
  isdiscontinued?: Maybe<Scalars['Boolean']['output']>
  issafetorenew?: Maybe<Scalars['Boolean']['output']>
  isstructuredsig: Scalars['Boolean']['output']
  medication: Scalars['String']['output']
  medicationentryid?: Maybe<Scalars['String']['output']>
  medicationid: Scalars['Int']['output']
  ndcoptions?: Maybe<Scalars['String']['output']>
  orderingmode?: Maybe<Scalars['String']['output']>
  organclass?: Maybe<Scalars['String']['output']>
  patientnote?: Maybe<Scalars['String']['output']>
  pharmacy?: Maybe<Scalars['String']['output']>
  pharmacyncpdpid?: Maybe<Scalars['String']['output']>
  prescribedby?: Maybe<Scalars['String']['output']>
  providernote?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['Float']['output']>
  quantityunit?: Maybe<Scalars['String']['output']>
  refillsallowed?: Maybe<Scalars['Int']['output']>
  route?: Maybe<Scalars['String']['output']>
  rxnorm?: Maybe<Scalars['String']['output']>
  source?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  stopreason?: Maybe<Scalars['String']['output']>
  structuredsig?: Maybe<StructuredSig>
  therapeuticclass?: Maybe<Scalars['String']['output']>
  unstructuredsig: Scalars['String']['output']
}

/** Most common medication events are the medication being entered or ended. */
export type MedicationEvent = {
  __typename?: 'MedicationEvent'
  eventdate: Scalars['String']['output']
  type: Scalars['String']['output']
  userdisplayname?: Maybe<Scalars['String']['output']>
}

/**
 * An order or request for both supply of the medication and the instructions for administration
 * of the medication to a patient. The resource is called "MedicationRequest" rather than
 * "MedicationPrescription" or "MedicationOrder" to generalize the use across inpatient and
 * outpatient settings, including care plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequest = {
  __typename?: 'MedicationRequest'
  authoredOn?: Maybe<Scalars['String']['output']>
  basedOn?: Maybe<Array<Maybe<Reference>>>
  category?: Maybe<Array<Maybe<CodeableConcept>>>
  courseOfTherapyType?: Maybe<CodeableConcept>
  detectedIssue?: Maybe<Array<Maybe<Reference>>>
  dispenseRequest?: Maybe<MedicationRequestDispenseRequest>
  doNotPerform?: Maybe<Scalars['Boolean']['output']>
  dosageInstruction?: Maybe<Array<Maybe<Dosage>>>
  encounter?: Maybe<Reference>
  eventHistory?: Maybe<Array<Maybe<Reference>>>
  id?: Maybe<Scalars['ID']['output']>
  intent: Scalars['String']['output']
  medicationCodeableConcept?: Maybe<CodeableConcept>
  medicationReference?: Maybe<Reference>
  meta?: Maybe<Meta>
  note?: Maybe<Array<Maybe<Annotation>>>
  performer?: Maybe<Reference>
  performerType?: Maybe<CodeableConcept>
  priorPrescription?: Maybe<Reference>
  priority?: Maybe<Scalars['String']['output']>
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>
  reportedBoolean?: Maybe<Scalars['Boolean']['output']>
  requester?: Maybe<Reference>
  status: Scalars['String']['output']
  statusReason?: Maybe<CodeableConcept>
  substitution?: Maybe<MedicationRequestSubstitution>
  supportingInformation?: Maybe<Array<Maybe<Reference>>>
  text?: Maybe<Narrative>
}

/**
 * An order or request for both supply of the medication and the instructions for administration
 * of the medication to a patient. The resource is called "MedicationRequest" rather than
 * "MedicationPrescription" or "MedicationOrder" to generalize the use across inpatient and
 * outpatient settings, including care plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestDispenseRequest = {
  __typename?: 'MedicationRequestDispenseRequest'
  dispenseInterval?: Maybe<Duration>
  expectedSupplyDuration?: Maybe<Duration>
  initialFill?: Maybe<MedicationRequestInitialFill>
  numberOfRepeatsAllowed?: Maybe<Scalars['Float']['output']>
  performer?: Maybe<Reference>
  quantity?: Maybe<Quantity>
  validityPeriod?: Maybe<Period>
}

/**
 * An order or request for both supply of the medication and the instructions for administration
 * of the medication to a patient. The resource is called "MedicationRequest" rather than
 * "MedicationPrescription" or "MedicationOrder" to generalize the use across inpatient and
 * outpatient settings, including care plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestInitialFill = {
  __typename?: 'MedicationRequestInitialFill'
  duration?: Maybe<Duration>
  quantity?: Maybe<Quantity>
}

export enum MedicationRequestIntent {
  FillerOrder = 'filler_order',
  InstanceOrder = 'instance_order',
  Option = 'option',
  Order = 'order',
  OriginalOrder = 'original_order',
  Plan = 'plan',
  Proposal = 'proposal',
  ReflexOrder = 'reflex_order',
}

export type MedicationRequestSubstitution = {
  __typename?: 'MedicationRequestSubstitution'
  allowedBoolean?: Maybe<Scalars['Boolean']['output']>
  allowedCodeableConcept?: Maybe<CodeableConcept>
  id?: Maybe<Scalars['ID']['output']>
  reason?: Maybe<CodeableConcept>
}

/**
 * The Medication feature allows the user to add a new medication, modify an existing medication or retrieve a list of
 * medications of the patient. The Medication Reference will allow the user to retrieve the list of configured medication
 * in the system.
 */
export type MedicationResponse = {
  __typename?: 'MedicationResponse'
  lastdownloaddenialreason?: Maybe<Scalars['String']['output']>
  lastdownloaddenied?: Maybe<Scalars['String']['output']>
  lastdownloadeddate?: Maybe<Scalars['String']['output']>
  lastupdated?: Maybe<Scalars['String']['output']>
  medications: Array<Array<Maybe<Medication>>>
  nomedicationsreported?: Maybe<Scalars['Boolean']['output']>
  patientdownloadconsent?: Maybe<Scalars['Boolean']['output']>
  patientneedsdownloadconsent?: Maybe<Scalars['Boolean']['output']>
  sectionnote?: Maybe<Scalars['String']['output']>
}

export enum MedicationType {
  Active = 'ACTIVE',
  Denied = 'DENIED',
  Historical = 'HISTORICAL',
}

/**
 * The metadata about a resource. This is content in the resource that is maintained by the infrastructure.
 * Changes to the content might not always be associated with version changes to the resource.
 */
export type Meta = {
  __typename?: 'Meta'
  id?: Maybe<Scalars['ID']['output']>
  lastUpdated?: Maybe<Scalars['String']['output']>
  profile?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  security?: Maybe<Array<Maybe<Coding>>>
  source?: Maybe<Scalars['String']['output']>
  tag?: Maybe<Array<Maybe<Coding>>>
  versionId?: Maybe<Scalars['String']['output']>
}

export type Mutation = {
  __typename?: 'Mutation'
  scheduleVisit?: Maybe<Visit>
}

export type MutationScheduleVisitArgs = {
  date: Scalars['Time']['input']
  patientId: Scalars['String']['input']
  visitType: VisitType
}

/**
 * A human-readable summary of the resource conveying the essential clinical and business information
 * for the resource.
 */
export type Narrative = {
  __typename?: 'Narrative'
  div?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  status?: Maybe<Scalars['String']['output']>
}

/** Measurements and simple assertions made about a patient, device or other subject. */
export type Observation = {
  __typename?: 'Observation'
  basedOn?: Maybe<Array<Maybe<Reference>>>
  bodySite?: Maybe<CodeableConcept>
  category?: Maybe<Array<Maybe<CodeableConcept>>>
  code?: Maybe<CodeableConcept>
  component?: Maybe<Array<Maybe<ObservationComponent>>>
  dataAbsentReason?: Maybe<CodeableConcept>
  derivedFrom?: Maybe<Array<Maybe<Reference>>>
  device?: Maybe<Reference>
  effectiveDateTime?: Maybe<Scalars['String']['output']>
  effectiveInstant?: Maybe<Scalars['String']['output']>
  effectivePeriod?: Maybe<Period>
  effectiveTiming?: Maybe<Timing>
  encounter?: Maybe<Reference>
  focus?: Maybe<Array<Maybe<Reference>>>
  hasMember?: Maybe<Array<Maybe<Reference>>>
  id?: Maybe<Scalars['ID']['output']>
  interpretation?: Maybe<Array<Maybe<CodeableConcept>>>
  issued?: Maybe<Scalars['String']['output']>
  language?: Maybe<Scalars['String']['output']>
  meta?: Maybe<Meta>
  method?: Maybe<CodeableConcept>
  note?: Maybe<Array<Maybe<Annotation>>>
  partOf?: Maybe<Array<Maybe<Reference>>>
  performer?: Maybe<Array<Maybe<Reference>>>
  referenceRange?: Maybe<Array<Maybe<ObservationReferenceRange>>>
  specimen?: Maybe<Reference>
  status?: Maybe<Scalars['String']['output']>
  subject?: Maybe<Reference>
  text?: Maybe<Narrative>
  valueBoolean?: Maybe<Scalars['Boolean']['output']>
  valueCodeableConcept?: Maybe<CodeableConcept>
  valueDateTime?: Maybe<Scalars['String']['output']>
  valueInteger?: Maybe<Scalars['Float']['output']>
  valuePeriod?: Maybe<Period>
  valueQuantity?: Maybe<Quantity>
  valueRange?: Maybe<Range>
  valueRatio?: Maybe<Ratio>
  valueString?: Maybe<Scalars['String']['output']>
  valueTime?: Maybe<Scalars['String']['output']>
}

export enum ObservationCategory {
  Laboratory = 'laboratory',
  VitalSigns = 'vital_signs',
}

/** Measurements and simple assertions made about a patient, device or other subject. */
export type ObservationComponent = {
  __typename?: 'ObservationComponent'
  code?: Maybe<CodeableConcept>
  dataAbsentReason?: Maybe<CodeableConcept>
  id?: Maybe<Scalars['ID']['output']>
  interpretation?: Maybe<Array<Maybe<CodeableConcept>>>
  referenceRange?: Maybe<Array<Maybe<ObservationReferenceRange>>>
  valueBoolean?: Maybe<Scalars['Boolean']['output']>
  valueCodeableConcept?: Maybe<CodeableConcept>
  valueDateTime?: Maybe<Scalars['String']['output']>
  valueInteger?: Maybe<Scalars['Float']['output']>
  valuePeriod?: Maybe<Period>
  valueQuantity?: Maybe<Quantity>
  valueRange?: Maybe<Range>
  valueRatio?: Maybe<Ratio>
  valueString?: Maybe<Scalars['String']['output']>
  valueTime?: Maybe<Scalars['String']['output']>
}

/** Measurements and simple assertions made about a patient, device or other subject. */
export type ObservationReferenceRange = {
  __typename?: 'ObservationReferenceRange'
  age?: Maybe<Range>
  appliesTo?: Maybe<Array<Maybe<CodeableConcept>>>
  high?: Maybe<Quantity>
  id?: Maybe<Scalars['ID']['output']>
  low?: Maybe<Quantity>
  text?: Maybe<Scalars['String']['output']>
  type?: Maybe<CodeableConcept>
}

export type Patient = {
  __typename?: 'Patient'
  allergyIntolerances?: Maybe<Array<Maybe<AllergyIntolerance>>>
  athenaProfile?: Maybe<AthenaProfile>
  bloodPressureMeasurements: Array<BloodPressureMeasurement>
  carePathways?: Maybe<Array<Maybe<CarePathway>>>
  conditions?: Maybe<Array<Maybe<Condition>>>
  departmentId: Scalars['String']['output']
  ehrRecord?: Maybe<EhrPatientRecord>
  eligibleStudies?: Maybe<Array<Study>>
  enrolledStudies?: Maybe<Array<Study>>
  id: Scalars['ID']['output']
  medicalRecordNumber: Scalars['String']['output']
  medicationRequests?: Maybe<Array<Maybe<MedicationRequest>>>
  medications: MedicationResponse
  observations?: Maybe<Array<Maybe<Observation>>>
  person: Person
  personId: Scalars['ID']['output']
}

export type PatientBloodPressureMeasurementsArgs = {
  end?: InputMaybe<Scalars['String']['input']>
  start: Scalars['String']['input']
  type?: InputMaybe<Array<BloodPressureMeasurementType>>
}

export type PatientMedicationsArgs = {
  medicationType: MedicationType
}

export type PatientObservationsArgs = {
  category: ObservationCategory
}

/**
 * Demographics and other administrative information about an individual or animal receiving care
 * or other health-related services.
 */
export type PatientCommunication = {
  __typename?: 'PatientCommunication'
  id?: Maybe<Scalars['ID']['output']>
  language?: Maybe<CodeableConcept>
  preferred?: Maybe<Scalars['Boolean']['output']>
}

/**
 * Demographics and other administrative information about an individual or animal receiving care
 * or other health-related services.
 */
export type PatientContact = {
  __typename?: 'PatientContact'
  address?: Maybe<Address>
  gender?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<HumanName>
  organization?: Maybe<Reference>
  period?: Maybe<Period>
  relationship?: Maybe<Array<Maybe<CodeableConcept>>>
  telecom?: Maybe<Array<Maybe<ContactPoint>>>
}

/**
 * Demographics and other administrative information about an individual or animal receiving care
 * or other health-related services.
 */
export type PatientLink = {
  __typename?: 'PatientLink'
  id?: Maybe<Scalars['ID']['output']>
  other?: Maybe<Reference>
  type?: Maybe<Scalars['String']['output']>
}

export type Period = {
  __typename?: 'Period'
  end?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  start?: Maybe<Scalars['String']['output']>
}

export enum Permission {
  ReadPatient = 'READ_PATIENT',
}

export type Person = {
  __typename?: 'Person'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
}

export type PhotoUploadStatus = {
  __typename?: 'PhotoUploadStatus'
  error?: Maybe<Scalars['String']['output']>
  etag?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
}

export type Practitioner = {
  __typename?: 'Practitioner'
  created: AuditStatus
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  person: Person
  prefix?: Maybe<Scalars['String']['output']>
  profile?: Maybe<PractitionerProfile>
  suffix?: Maybe<Scalars['String']['output']>
  updated?: Maybe<AuditStatus>
  visitMeta?: Maybe<VisitMeta>
}

export type PractitionerVisitMetaArgs = {
  visitType: VisitType
}

export type PractitionerImages = {
  __typename?: 'PractitionerImages'
  large: Scalars['String']['output']
  medium: Scalars['String']['output']
  small: Scalars['String']['output']
}

export type PractitionerProfile = {
  __typename?: 'PractitionerProfile'
  bio: Scalars['String']['output']
  created: AuditStatus
  familyName: Scalars['String']['output']
  givenName: Scalars['String']['output']
  id: Scalars['ID']['output']
  images: PractitionerImages
  interests: Scalars['String']['output']
  location: Scalars['String']['output']
  practitionerId: Scalars['String']['output']
  primaryQualification: Scalars['String']['output']
  qualifications: Scalars['String']['output']
  title: Scalars['String']['output']
  updated?: Maybe<AuditStatus>
}

export type Quantity = {
  __typename?: 'Quantity'
  code?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  system?: Maybe<Scalars['String']['output']>
  unit?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type Query = {
  __typename?: 'Query'
  carePathways?: Maybe<Array<CarePathway>>
  /** @deprecated Use patient.ehrRecord */
  ehrRecord?: Maybe<EhrPatientRecord>
  patient?: Maybe<Patient>
  practitioner?: Maybe<Practitioner>
  practitioners?: Maybe<Array<Maybe<Practitioner>>>
  task?: Maybe<Task>
}

export type QueryCarePathwaysArgs = {
  carePathwayStatus?: InputMaybe<Array<CarePathwayStatus>>
  carePathwayTypes?: InputMaybe<Array<CarePathwayType>>
  endDate?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryEhrRecordArgs = {
  medicalRecordNumber: Scalars['String']['input']
}

export type QueryPatientArgs = {
  patientId: Scalars['ID']['input']
}

export type QueryPractitionerArgs = {
  practitionerId: Scalars['ID']['input']
}

export type QueryTaskArgs = {
  id: Scalars['ID']['input']
}

export type Range = {
  __typename?: 'Range'
  high?: Maybe<Quantity>
  id?: Maybe<Scalars['ID']['output']>
  low?: Maybe<Quantity>
}

export type Ratio = {
  __typename?: 'Ratio'
  denominator?: Maybe<Quantity>
  numerator?: Maybe<Quantity>
}

export type Reference = {
  __typename?: 'Reference'
  display?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  reference?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type Signature = {
  __typename?: 'Signature'
  data?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  onBehalfOf?: Maybe<Reference>
  sigFormat?: Maybe<Scalars['String']['output']>
  targetFormat?: Maybe<Scalars['String']['output']>
  type?: Maybe<Array<Maybe<Coding>>>
  when?: Maybe<Scalars['String']['output']>
  who?: Maybe<Reference>
}

export type StructuredSig = {
  __typename?: 'StructuredSig'
  dosageaction?: Maybe<Scalars['String']['output']>
  dosageadditionalinstructions?: Maybe<Scalars['String']['output']>
  dosagedurationunit?: Maybe<Scalars['String']['output']>
  dosagedurationvalue?: Maybe<Scalars['Int']['output']>
  dosagefrequencydescription?: Maybe<Scalars['String']['output']>
  dosagefrequencyunit?: Maybe<Scalars['String']['output']>
  dosagefrequencyvalue?: Maybe<Scalars['Float']['output']>
  dosagequantityunit?: Maybe<Scalars['String']['output']>
  dosagequantityvalue?: Maybe<Scalars['Int']['output']>
  dosageroute?: Maybe<Scalars['String']['output']>
}

export type Study = {
  __typename?: 'Study'
  created: AuditStatus
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  updated?: Maybe<AuditStatus>
}

export type SurveyRequest = {
  __typename?: 'SurveyRequest'
  surveyId: Scalars['Int']['output']
  urls: SurveyUrls
}

export type SurveySubmission = {
  __typename?: 'SurveySubmission'
  completedAt: Scalars['String']['output']
  data?: Maybe<Scalars['Any']['output']>
  events?: Maybe<Array<SurveySubmissionEvent>>
  id: Scalars['String']['output']
  lastSeenAt: Scalars['String']['output']
  patientId: Scalars['String']['output']
  seenBy?: Maybe<Array<Scalars['String']['output']>>
  startedAt: Scalars['String']['output']
  submissionId: Scalars['Int']['output']
  surveyId: Scalars['Int']['output']
  url: Scalars['String']['output']
}

export type SurveySubmissionEvent = {
  __typename?: 'SurveySubmissionEvent'
  at: Scalars['String']['output']
  by: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SurveyUrls = {
  __typename?: 'SurveyUrls'
  android: Scalars['String']['output']
  ios: Scalars['String']['output']
}

export type Task = {
  __typename?: 'Task'
  assigneeId: Scalars['String']['output']
  assigneePatient?: Maybe<Patient>
  assigneePractitioner?: Maybe<Practitioner>
  carePathway?: Maybe<CarePathway>
  carePathwayId: Scalars['String']['output']
  comments?: Maybe<Array<TaskComment>>
  /** Pre-filled text for communicating with a patient */
  copy?: Maybe<Scalars['String']['output']>
  created: AuditStatus
  /** A longer explanation of what needs to be done */
  description?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['String']['output']>
  events?: Maybe<Array<TaskEvent>>
  id: Scalars['ID']['output']
  /** In practitioner tasks, the benefactor of this task */
  patient: Patient
  /** In practitioner tasks, the benefactor of this task */
  patientId: Scalars['String']['output']
  /** In practitioner tasks, whether this task requires communicating with the patient */
  patientTouchPoint: Scalars['Boolean']['output']
  requestingPractitioner?: Maybe<Practitioner>
  requestingPractitionerId?: Maybe<Scalars['String']['output']>
  /** In practitioner tasks, the role of the practitioner this task is first assigned to */
  role?: Maybe<Scalars['String']['output']>
  status: TaskStatus
  /** A short explanation of what needs to be done */
  summary?: Maybe<Scalars['String']['output']>
  survey?: Maybe<SurveyRequest>
  taskType: TaskType
  updated: AuditStatus
  visit?: Maybe<Visit>
  visitNotes?: Maybe<VisitNotes>
  workflow?: Maybe<Workflow>
  workflowId?: Maybe<Scalars['String']['output']>
}

export type TaskComment = {
  __typename?: 'TaskComment'
  body: Scalars['String']['output']
  created: AuditStatus
  id: Scalars['ID']['output']
  updated?: Maybe<AuditStatus>
}

export type TaskEvent = {
  __typename?: 'TaskEvent'
  carePathway?: Maybe<CarePathway>
  carePathwayId?: Maybe<Scalars['String']['output']>
  created: AuditStatus
  data?: Maybe<Scalars['Any']['output']>
  eventType: TaskEventType
  id: Scalars['ID']['output']
  note?: Maybe<Scalars['String']['output']>
  patient: Patient
  patientId: Scalars['String']['output']
  surveySubmission?: Maybe<SurveySubmission>
  task?: Maybe<Task>
  taskId?: Maybe<Scalars['String']['output']>
  version: Scalars['String']['output']
}

export enum TaskEventType {
  AddHighBpEscalationSurveyResults = 'ADD_HIGH_BP_ESCALATION_SURVEY_RESULTS',
  AddLowBpEscalationSurveyResults = 'ADD_LOW_BP_ESCALATION_SURVEY_RESULTS',
  AddNote = 'ADD_NOTE',
  AssignTask = 'ASSIGN_TASK',
  BpEscalationMeasurementReceived = 'BP_ESCALATION_MEASUREMENT_RECEIVED',
  BpEscalationPatientExited = 'BP_ESCALATION_PATIENT_EXITED',
  BpEscalationSeekMedicalAttention = 'BP_ESCALATION_SEEK_MEDICAL_ATTENTION',
  BpEscalationSymptoms = 'BP_ESCALATION_SYMPTOMS',
  CancelAllCarePathwaYs = 'CANCEL_ALL_CARE_PATHWAYs',
  CancelCarePathway = 'CANCEL_CARE_PATHWAY',
  CancelEvent = 'CANCEL_EVENT',
  CompleteWithChoice = 'COMPLETE_WITH_CHOICE',
  CreateCarePathway = 'CREATE_CARE_PATHWAY',
  CreateTask = 'CREATE_TASK',
  LifestyleChangeSurveyResults = 'LIFESTYLE_CHANGE_SURVEY_RESULTS',
  RescheduleTask = 'RESCHEDULE_TASK',
  ResolveBpEscalation = 'RESOLVE_BP_ESCALATION',
  SetDueDate = 'SET_DUE_DATE',
  StartWorkflow = 'START_WORKFLOW',
  UpdateCarePathwayStatus = 'UPDATE_CARE_PATHWAY_STATUS',
  UpdateTaskStatus = 'UPDATE_TASK_STATUS',
  VisitCancelled = 'VISIT_CANCELLED',
  VisitCompleted = 'VISIT_COMPLETED',
  VisitRescheduled = 'VISIT_RESCHEDULED',
  VisitScheduled = 'VISIT_SCHEDULED',
  WorkflowComplete = 'WORKFLOW_COMPLETE',
  WorkflowStarted = 'WORKFLOW_STARTED',
}

export enum TaskStatus {
  TaskCancelled = 'TASK_CANCELLED',
  TaskCompleted = 'TASK_COMPLETED',
  TaskCompletedSkipped = 'TASK_COMPLETED_SKIPPED',
  TaskCompleting = 'TASK_COMPLETING',
  TaskCompletingSkipping = 'TASK_COMPLETING_SKIPPING',
  TaskCreated = 'TASK_CREATED',
  TaskErrored = 'TASK_ERRORED',
  TaskInProgress = 'TASK_IN_PROGRESS',
  TaskNa = 'TASK_NA',
  TaskPending = 'TASK_PENDING',
}

export enum TaskType {
  AnswerCoachOnboardingVisitSurvey = 'AnswerCoachOnboardingVisitSurvey',
  AskIfPatientHasPharmDVisitQuestions = 'AskIfPatientHasPharmDVisitQuestions',
  AskIfPatientHasPrescriberVisitQuestions = 'AskIfPatientHasPrescriberVisitQuestions',
  AskLevelOfAccountability = 'AskLevelOfAccountability',
  AthenaMedicalHistory = 'AthenaMedicalHistory',
  AthenaVisitNotesObv = 'AthenaVisitNotesOBV',
  AthenaVisitNotesPharmD = 'AthenaVisitNotesPharmD',
  AthenaVisitNotesPrescriber = 'AthenaVisitNotesPrescriber',
  BpEscalationFollowUpCall = 'BpEscalationFollowUpCall',
  BpEscalationFollowUpHighReadings = 'BpEscalationFollowUpHighReadings',
  BpEscalationFollowUpLowCall = 'BpEscalationFollowUpLowCall',
  BpEscalationFollowUpLowReadings = 'BpEscalationFollowUpLowReadings',
  BpEscalationReviewHighReadings = 'BpEscalationReviewHighReadings',
  BpEscalationReviewLowReadings = 'BpEscalationReviewLowReadings',
  CompleteLifestyleVisit = 'CompleteLifestyleVisit',
  CompletePatientIntakeSurvey = 'CompletePatientIntakeSurvey',
  CompletePharmDVisit = 'CompletePharmDVisit',
  CompletePrescriberVisit = 'CompletePrescriberVisit',
  CompleteVisitOnBoarding = 'CompleteVisitOnBoarding',
  ConfirmPreferredLabAndPharmacy = 'ConfirmPreferredLabAndPharmacy',
  CreateAthenaRecord = 'CreateAthenaRecord',
  CreateCommunicationTrackerProfile = 'CreateCommunicationTrackerProfile',
  CreateRingRxContact = 'CreateRingRxContact',
  D2CCompleteVisitObv = 'D2CCompleteVisitOBV',
  D2CSendWelcomeKit = 'D2CSendWelcomeKit',
  EduRivaKnowledgeCheck1 = 'EduRivaKnowledgeCheck1',
  EduRivaKnowledgeCheck2 = 'EduRivaKnowledgeCheck2',
  EduRivaKnowledgeCheck3 = 'EduRivaKnowledgeCheck3',
  EduRivaKnowledgeCheck4 = 'EduRivaKnowledgeCheck4',
  EduRivaKnowledgeCheck5 = 'EduRivaKnowledgeCheck5',
  EduRivaKnowledgeCheck6 = 'EduRivaKnowledgeCheck6',
  EduRivaKnowledgeCheck7 = 'EduRivaKnowledgeCheck7',
  EduRivaKnowledgeCheck8 = 'EduRivaKnowledgeCheck8',
  EduRivaKnowledgeCheck9 = 'EduRivaKnowledgeCheck9',
  EduRivaKnowledgeCheck10 = 'EduRivaKnowledgeCheck10',
  EduRivaKnowledgeCheck11 = 'EduRivaKnowledgeCheck11',
  EduRivaKnowledgeCheck12 = 'EduRivaKnowledgeCheck12',
  EduRivaKnowledgeCheck13 = 'EduRivaKnowledgeCheck13',
  EduRivaKnowledgeCheck14 = 'EduRivaKnowledgeCheck14',
  EduRivaKnowledgeCheck15 = 'EduRivaKnowledgeCheck15',
  EduRivaKnowledgeCheck16 = 'EduRivaKnowledgeCheck16',
  EduRivaKnowledgeCheck17 = 'EduRivaKnowledgeCheck17',
  EduRivaKnowledgeCheck18 = 'EduRivaKnowledgeCheck18',
  EpicCompleteRivaEnrollment = 'EpicCompleteRivaEnrollment',
  EpicVisitNotesObv = 'EpicVisitNotesOBV',
  ExpediteOnboarding = 'ExpediteOnboarding',
  HaveLifestyleGoalChat = 'HaveLifestyleGoalChat',
  InputDatesObv = 'InputDatesOBV',
  LifestyleChangeCheckIn1 = 'LifestyleChangeCheckIn1',
  LifestyleChangeCheckIn2 = 'LifestyleChangeCheckIn2',
  LifestyleChangeCheckIn3 = 'LifestyleChangeCheckIn3',
  LifestyleChangeCheckIn4 = 'LifestyleChangeCheckIn4',
  LifestyleChangeCheckIn5 = 'LifestyleChangeCheckIn5',
  LifestyleChangeCheckIn6 = 'LifestyleChangeCheckIn6',
  LifestyleChangeInterventionStatusOff = 'LifestyleChangeInterventionStatusOff',
  MeetCareTeam = 'MeetCareTeam',
  PostVisitCommunicationObv = 'PostVisitCommunicationOBV',
  PractitionerVisitRecap = 'PractitionerVisitRecap',
  PrepareVisitPharmD = 'PrepareVisitPharmD',
  PrepareVisitPrescriber = 'PrepareVisitPrescriber',
  PromptMedicalHistoryAvailable = 'PromptMedicalHistoryAvailable',
  ReachOutWhenReady = 'ReachOutWhenReady',
  RemindCompleteSurvey = 'RemindCompleteSurvey',
  RemindCompleteSurveySms = 'RemindCompleteSurveySMS',
  RemindToScheduleNpvCall = 'RemindToScheduleNPVCall',
  RemindToScheduleNpvChat = 'RemindToScheduleNPVChat',
  RemindToScheduleNpvEscalate = 'RemindToScheduleNPVEscalate',
  RemindToSchedulePharmdNpvChat = 'RemindToSchedulePharmdNPVChat',
  RemindToSchedulePrescriberNpvChat = 'RemindToSchedulePrescriberNPVChat',
  RestartLifestyleWhenReady = 'RestartLifestyleWhenReady',
  ReviewAndUpdateGoals = 'ReviewAndUpdateGoals',
  ReviewBloodPressureComments = 'ReviewBloodPressureComments',
  ReviewCoachOnboardingVisitRecap = 'ReviewCoachOnboardingVisitRecap',
  ReviewMedicationChangePrototype = 'ReviewMedicationChangePrototype',
  ReviewSurveyResults = 'ReviewSurveyResults',
  SchedulePharmDVisit = 'SchedulePharmDVisit',
  SchedulePrescriberVisit = 'SchedulePrescriberVisit',
  SendLifestyleChange = 'SendLifestyleChange',
  SendLifestyleResources = 'SendLifestyleResources',
  SendLifestyleSurvey = 'SendLifestyleSurvey',
  SendLifestyleSurveyReminder = 'SendLifestyleSurveyReminder',
  SendLifestyleThankYou = 'SendLifestyleThankYou',
  SendMedicalHistoryReminder = 'SendMedicalHistoryReminder',
  SendMedicalHistorySurvey = 'SendMedicalHistorySurvey',
  SendMentalHealthSurvey = 'SendMentalHealthSurvey',
  SendNpvScheduleLinks = 'SendNPVScheduleLinks',
  SendSurveyOverview = 'SendSurveyOverview',
  SendThankYouVisitRecapCoachOnboarding = 'SendThankYouVisitRecapCoachOnboarding',
  SendThankYouVisitRecapOnboarding = 'SendThankYouVisitRecapOnboarding',
  SendThankYouVisitRecapPharmD = 'SendThankYouVisitRecapPharmD',
  SendThankYouVisitRecapPrescriber = 'SendThankYouVisitRecapPrescriber',
  SendThankyouNoteLifestyleMeeting = 'SendThankyouNoteLifestyleMeeting',
  SendVideoLinks = 'SendVideoLinks',
  SendVisitScheduleReminderLifestyle = 'SendVisitScheduleReminderLifestyle',
  SendVisitScheduleReminderLifestyleSms = 'SendVisitScheduleReminderLifestyleSMS',
  SendVisitSurveyPharmd = 'SendVisitSurveyPharmd',
  SendVisitSurveyPrescriber = 'SendVisitSurveyPrescriber',
  SendWelcomeKit = 'SendWelcomeKit',
  SetAppReminderTimes = 'SetAppReminderTimes',
  SetBpGoal = 'SetBpGoal',
  TakeFirstReading = 'TakeFirstReading',
  UchCompleteVisitObv = 'UCHCompleteVisitOBV',
  UchSendThankYouVisitRecapOnboarding = 'UCHSendThankYouVisitRecapOnboarding',
  UchSendWelcomeKit = 'UCHSendWelcomeKit',
  UpdateBpStatusEstablishingCare = 'UpdateBPStatusEstablishingCare',
  UpdateDct = 'UpdateDCT',
  UpdateHipaaSlack = 'UpdateHIPAASlack',
  UpdateStatusActiveManagement = 'UpdateStatusActiveManagement',
  UpdateVisitNotes = 'UpdateVisitNotes',
  VerifyIdentity = 'VerifyIdentity',
  VisitNotesPharmD = 'VisitNotesPharmD',
  VisitNotesPrescriber = 'VisitNotesPrescriber',
  VisitSurveyPharmD = 'VisitSurveyPharmD',
  VisitSurveyPrescriber = 'VisitSurveyPrescriber',
}

export type Timing = {
  __typename?: 'Timing'
  code?: Maybe<CodeableConcept>
  event?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  id?: Maybe<Scalars['ID']['output']>
  repeat?: Maybe<TimingRepeat>
}

export type TimingRepeat = {
  __typename?: 'TimingRepeat'
  boundsDuration?: Maybe<Duration>
  boundsPeriod?: Maybe<Period>
  boundsRange?: Maybe<Range>
  count?: Maybe<Scalars['Float']['output']>
  countMax?: Maybe<Scalars['Float']['output']>
  dayOfWeek?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  duration?: Maybe<Scalars['Float']['output']>
  durationMax?: Maybe<Scalars['Float']['output']>
  durationUnit?: Maybe<Scalars['String']['output']>
  frequency?: Maybe<Scalars['Float']['output']>
  frequencyMax?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  offset?: Maybe<Scalars['Float']['output']>
  period?: Maybe<Scalars['Float']['output']>
  periodMax?: Maybe<Scalars['Float']['output']>
  periodUnit?: Maybe<Scalars['String']['output']>
  timeOfDay?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  when?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type Visit = {
  __typename?: 'Visit'
  carePathwayId?: Maybe<Scalars['String']['output']>
  created: AuditStatus
  endTime: Scalars['Time']['output']
  id: Scalars['ID']['output']
  patientId: Scalars['String']['output']
  practitionerId: Scalars['String']['output']
  startTime: Scalars['Time']['output']
  status: VisitStatus
  updated?: Maybe<AuditStatus>
  visitType: VisitType
}

export type VisitAvailabilityDates = {
  __typename?: 'VisitAvailabilityDates'
  /** e.g. ["2023-11-01", "2023-11-02", ...] */
  dates: Array<Scalars['CivilDate']['output']>
  /** e.g. "2023-11" */
  month: Scalars['String']['output']
}

export type VisitAvailabilityTimes = {
  __typename?: 'VisitAvailabilityTimes'
  /** A 'civil' date without a timezone, e.g. "2023-11-01" */
  date: Scalars['CivilDate']['output']
  /** e.g. ["2023-11-01T20:00:00Z", "2023-11-01T21:00:00Z", ...] */
  times: Array<Scalars['Time']['output']>
}

export type VisitMeta = {
  __typename?: 'VisitMeta'
  availableDates: VisitAvailabilityDates
  availableTimes: VisitAvailabilityTimes
  /** Duration in minutes */
  duration: Scalars['Int']['output']
  externalId: Scalars['Int']['output']
  externalProvider: ExternalServiceProvider
  id: Scalars['ID']['output']
}

export type VisitMetaAvailableDatesArgs = {
  month: Scalars['Int']['input']
  year: Scalars['Int']['input']
}

export type VisitMetaAvailableTimesArgs = {
  date: Scalars['CivilDate']['input']
}

export type VisitNotes = {
  __typename?: 'VisitNotes'
  notesHTML: Scalars['String']['output']
  practitioner: Practitioner
}

export enum VisitStatus {
  VisitStatusCancelled = 'VISIT_STATUS_CANCELLED',
  VisitStatusCancelling = 'VISIT_STATUS_CANCELLING',
  VisitStatusComplete = 'VISIT_STATUS_COMPLETE',
  VisitStatusCreating = 'VISIT_STATUS_CREATING',
  VisitStatusPending = 'VISIT_STATUS_PENDING',
  VisitStatusScheduling = 'VISIT_STATUS_SCHEDULING',
}

export enum VisitType {
  VisitTypeLifestyleChange = 'VISIT_TYPE_LIFESTYLE_CHANGE',
  VisitTypeOnboarding = 'VISIT_TYPE_ONBOARDING',
  VisitTypePharmd = 'VISIT_TYPE_PHARMD',
  VisitTypePrescriber = 'VISIT_TYPE_PRESCRIBER',
}

export type Workflow = {
  __typename?: 'Workflow'
  carePathway: CarePathway
  carePathwayId: Scalars['String']['output']
  created: AuditStatus
  updated: AuditStatus
  workflowId: Scalars['String']['output']
  workflowType: WorkflowType
}

export enum WorkflowType {
  EngagementMessaging = 'ENGAGEMENT_MESSAGING',
  HighBloodPressureEscalation = 'HIGH_BLOOD_PRESSURE_ESCALATION',
  LifestyleSurveyReminder = 'LIFESTYLE_SURVEY_REMINDER',
  LowBloodPressureEscalation = 'LOW_BLOOD_PRESSURE_ESCALATION',
  Onboarding = 'ONBOARDING',
  OnboardingD2C = 'ONBOARDING_D2C',
  OnboardingExperienceUch = 'ONBOARDING_EXPERIENCE_UCH',
  PharmdVisit = 'PHARMD_VISIT',
  PrescriberVisit = 'PRESCRIBER_VISIT',
  ScheduleVisitReminder = 'SCHEDULE_VISIT_REMINDER',
}

export type LabResultsQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
}>

export type LabResultsQuery = {
  __typename?: 'Query'
  patient?: {
    __typename?: 'Patient'
    observations?: Array<{
      __typename?: 'Observation'
      effectiveDateTime?: string | null
      id?: string | null
      status?: string | null
      code?: { __typename?: 'CodeableConcept'; text: string } | null
      interpretation?: Array<{ __typename?: 'CodeableConcept'; text: string } | null> | null
      meta?: {
        __typename?: 'Meta'
        security?: Array<{ __typename?: 'Coding'; display?: string | null } | null> | null
      } | null
      note?: Array<{ __typename?: 'Annotation'; text?: string | null } | null> | null
      referenceRange?: Array<{
        __typename?: 'ObservationReferenceRange'
        text?: string | null
        high?: { __typename?: 'Quantity'; id?: string | null; unit?: string | null; value?: number | null } | null
        low?: { __typename?: 'Quantity'; id?: string | null; unit?: string | null; value?: number | null } | null
      } | null> | null
      valueQuantity?: { __typename?: 'Quantity'; unit?: string | null; value?: number | null } | null
    } | null> | null
  } | null
}

export type AllergiesQueryQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
}>

export type AllergiesQueryQuery = {
  __typename?: 'Query'
  patient?: {
    __typename?: 'Patient'
    id: string
    allergyIntolerances?: Array<{
      __typename?: 'AllergyIntolerance'
      id: string
      clinicalStatus?: { __typename?: 'CodeableConcept'; text: string } | null
      code?: {
        __typename?: 'CodeableConcept'
        text: string
        coding?: Array<{ __typename?: 'Coding'; code?: string | null; system?: string | null } | null> | null
      } | null
      reaction?: Array<{
        __typename?: 'AllergyIntoleranceReaction'
        description?: string | null
        id: string
        severity?: string | null
      } | null> | null
    } | null> | null
  } | null
}

export type BloodPressureMeasurementsQueryQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
  start: Scalars['String']['input']
  end: Scalars['String']['input']
}>

export type BloodPressureMeasurementsQueryQuery = {
  __typename?: 'Query'
  patient?: {
    __typename?: 'Patient'
    id: string
    bloodPressureMeasurements: Array<{
      __typename?: 'BloodPressureMeasurement'
      id: string
      bloodPressureSetId: string
      comment?: string | null
      personId: string
      type: BloodPressureMeasurementType
      systolicBloodPressure: number
      diastolicBloodPressure: number
      beatsPerMinute: number
      deviceType: string
      timeZone: string
      bloodPressureEscalationCategory?: {
        __typename?: 'BloodPressureEscalationCategory'
        type: string
        reasons: Array<string>
      } | null
      bloodPressureSet?: {
        __typename?: 'BloodPressureSet'
        comment?: string | null
        id: string
        patientId: string
        timeZone: string
        created: { __typename?: 'AuditStatus'; at: string; by: string }
      } | null
      escalation?: {
        __typename?: 'CarePathway'
        id: string
        patientId: string
        status: CarePathwayStatus
        type: CarePathwayType
        created: { __typename?: 'AuditStatus'; at: string; by: string }
        events?: Array<{
          __typename?: 'TaskEvent'
          carePathwayId?: string | null
          data?: unknown | null
          eventType: TaskEventType
          id: string
          created: { __typename?: 'AuditStatus'; at: string; by: string }
        }> | null
      } | null
      created: { __typename?: 'AuditStatus'; at: string; by: string }
      updated: { __typename?: 'AuditStatus'; at: string; by: string }
    }>
  } | null
}

export type CarePathwaysQueryQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
  carePathwayTypes?: InputMaybe<Array<CarePathwayType> | CarePathwayType>
  carePathwayStatus?: InputMaybe<Array<CarePathwayStatus> | CarePathwayStatus>
}>

export type CarePathwaysQueryQuery = {
  __typename?: 'Query'
  carePathways?: Array<{
    __typename?: 'CarePathway'
    id: string
    patientId: string
    status: CarePathwayStatus
    type: CarePathwayType
    created: { __typename?: 'AuditStatus'; at: string; by: string }
    events?: Array<{
      __typename?: 'TaskEvent'
      carePathwayId?: string | null
      data?: unknown | null
      id: string
      eventType: TaskEventType
      note?: string | null
      created: { __typename?: 'AuditStatus'; at: string; by: string }
    }> | null
    tasks?: Array<{
      __typename?: 'Task'
      assigneeId: string
      description?: string | null
      id: string
      patientId: string
      summary?: string | null
      status: TaskStatus
      taskType: TaskType
    }> | null
  }> | null
}

export type ProblemsQueryQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
}>

export type ProblemsQueryQuery = {
  __typename?: 'Query'
  patient?: {
    __typename?: 'Patient'
    conditions?: Array<{
      __typename?: 'Condition'
      id?: string | null
      onsetDateTime?: string | null
      clinicalStatus?: {
        __typename?: 'CodeableConcept'
        text: string
        coding?: Array<{ __typename?: 'Coding'; code?: string | null } | null> | null
      } | null
      code?: { __typename?: 'CodeableConcept'; text: string } | null
      meta?: { __typename?: 'Meta'; lastUpdated?: string | null } | null
      severity?: { __typename?: 'CodeableConcept'; text: string } | null
      verificationStatus?: {
        __typename?: 'CodeableConcept'
        coding?: Array<{ __typename?: 'Coding'; code?: string | null } | null> | null
      } | null
    } | null> | null
  } | null
}

export type EhrRecordtQueryQueryVariables = Exact<{
  medicalRecordNumber: Scalars['String']['input']
}>

export type EhrRecordtQueryQuery = {
  __typename?: 'Query'
  ehrRecord?: {
    __typename?: 'EHRPatientRecord'
    birthDate?: string | null
    id?: string | null
    name?: Array<{
      __typename?: 'HumanName'
      family?: string | null
      given?: Array<string | null> | null
    } | null> | null
  } | null
}

export type MedicationsPaidQueryQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
  medicationType: MedicationType
}>

export type MedicationsPaidQueryQuery = {
  __typename?: 'Query'
  patient?: {
    __typename?: 'Patient'
    id: string
    medications: {
      __typename?: 'MedicationResponse'
      lastupdated?: string | null
      medications: Array<
        Array<{
          __typename?: 'Medication'
          medication: string
          medicationentryid?: string | null
          unstructuredsig: string
          events: Array<{
            __typename?: 'MedicationEvent'
            eventdate: string
            type: string
            userdisplayname?: string | null
          } | null>
        } | null>
      >
    }
  } | null
}

export type MedicationsQueryQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
}>

export type MedicationsQueryQuery = {
  __typename?: 'Query'
  patient?: {
    __typename?: 'Patient'
    id: string
    medicationRequests?: Array<{
      __typename?: 'MedicationRequest'
      authoredOn?: string | null
      id?: string | null
      status: string
      dispenseRequest?: {
        __typename?: 'MedicationRequestDispenseRequest'
        initialFill?: {
          __typename?: 'MedicationRequestInitialFill'
          quantity?: { __typename?: 'Quantity'; unit?: string | null; value?: number | null } | null
        } | null
      } | null
      dosageInstruction?: Array<{
        __typename?: 'Dosage'
        asNeededBoolean?: boolean | null
        text?: string | null
        doseAndRate?: Array<{
          __typename?: 'DosageDoseAndRate'
          doseQuantity?: { __typename?: 'Quantity'; unit?: string | null; value?: number | null } | null
        } | null> | null
        method?: {
          __typename?: 'CodeableConcept'
          coding?: Array<{ __typename?: 'Coding'; display?: string | null } | null> | null
        } | null
        route?: {
          __typename?: 'CodeableConcept'
          coding?: Array<{ __typename?: 'Coding'; display?: string | null } | null> | null
        } | null
        timing?: {
          __typename?: 'Timing'
          repeat?: {
            __typename?: 'TimingRepeat'
            duration?: number | null
            durationUnit?: string | null
            frequency?: number | null
            periodUnit?: string | null
            period?: number | null
          } | null
        } | null
      } | null> | null
      medicationReference?: { __typename?: 'Reference'; display?: string | null } | null
      meta?: { __typename?: 'Meta'; lastUpdated?: string | null } | null
    } | null> | null
  } | null
}

export type PatientAthenaProfilePaidQueryQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
}>

export type PatientAthenaProfilePaidQueryQuery = {
  __typename?: 'Query'
  patient?: {
    __typename?: 'Patient'
    id: string
    athenaProfile?: {
      __typename?: 'AthenaProfile'
      address1?: string | null
      assignedAtBirthSex?: string | null
      city?: string | null
      consentToCall?: boolean | null
      consentToText?: boolean | null
      contactName?: string | null
      contactMobilePhone: string
      contactRelationship?: string | null
      driversLicenseExpirationDate?: string | null
      driversLicenseNumber?: string | null
      driversLicenseStateId?: string | null
      ethnicityCodes?: Array<string | null> | null
      language6392Code?: string | null
      maritalStatus?: string | null
      race?: Array<string | null> | null
      sex?: string | null
      state?: string | null
      zip?: string | null
    } | null
  } | null
}

export type PatientStudiesQueryQueryVariables = Exact<{
  patientId: Scalars['ID']['input']
}>

export type PatientStudiesQueryQuery = {
  __typename?: 'Query'
  patient?: {
    __typename?: 'Patient'
    eligibleStudies?: Array<{ __typename?: 'Study'; id: string; name: string }> | null
    enrolledStudies?: Array<{ __typename?: 'Study'; id: string; name: string }> | null
  } | null
}

export type TaskCarePathwayQueryQueryVariables = Exact<{
  taskId: Scalars['ID']['input']
}>

export type TaskCarePathwayQueryQuery = {
  __typename?: 'Query'
  task?: {
    __typename?: 'Task'
    carePathway?: {
      __typename?: 'CarePathway'
      id: string
      patientId: string
      status: CarePathwayStatus
      type: CarePathwayType
      created: { __typename?: 'AuditStatus'; at: string; by: string }
      events?: Array<{
        __typename?: 'TaskEvent'
        carePathwayId?: string | null
        data?: unknown | null
        eventType: TaskEventType
        id: string
        note?: string | null
        created: { __typename?: 'AuditStatus'; at: string; by: string }
        surveySubmission?: {
          __typename?: 'SurveySubmission'
          completedAt: string
          data?: unknown | null
          id: string
          lastSeenAt: string
          patientId: string
          startedAt: string
          seenBy?: Array<string> | null
          submissionId: number
          surveyId: number
          url: string
          events?: Array<{ __typename?: 'SurveySubmissionEvent'; at: string; by: string; type: string }> | null
        } | null
      }> | null
    } | null
  } | null
}

export const LabResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'labResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'observations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'EnumValue', value: 'laboratory' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'code' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'effectiveDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interpretation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'security' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'note' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'referenceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'high' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'low' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueQuantity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabResultsQuery, LabResultsQueryVariables>
export const AllergiesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allergiesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allergyIntolerances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clinicalStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'code' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coding' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reaction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'severity' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllergiesQueryQuery, AllergiesQueryQueryVariables>
export const BloodPressureMeasurementsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'bloodPressureMeasurementsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bloodPressureMeasurements' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'start' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'end' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bloodPressureEscalationCategory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reasons' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bloodPressureSetId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bloodPressureSet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'systolicBloodPressure' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'diastolicBloodPressure' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'beatsPerMinute' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'escalation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'events' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'carePathwayId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BloodPressureMeasurementsQueryQuery, BloodPressureMeasurementsQueryQueryVariables>
export const CarePathwaysQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'carePathwaysQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'carePathwayTypes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'CarePathwayType' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'carePathwayStatus' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'CarePathwayStatus' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carePathways' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'carePathwayTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'carePathwayTypes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'carePathwayStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'carePathwayStatus' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'events' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'carePathwayId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'assigneeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CarePathwaysQueryQuery, CarePathwaysQueryQueryVariables>
export const ProblemsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'problemsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conditions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clinicalStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coding' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'code' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'lastUpdated' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'onsetDateTime' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'severity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verificationStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coding' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProblemsQueryQuery, ProblemsQueryQueryVariables>
export const EhrRecordtQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ehrRecordtQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'medicalRecordNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ehrRecord' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'medicalRecordNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'medicalRecordNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'family' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'given' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EhrRecordtQueryQuery, EhrRecordtQueryQueryVariables>
export const MedicationsPaidQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'medicationsPaidQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'medicationType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MedicationType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'medications' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'medicationType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'medicationType' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastupdated' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'medications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'events' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'eventdate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'userdisplayname' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'medication' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'medicationentryid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'unstructuredsig' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MedicationsPaidQueryQuery, MedicationsPaidQueryQueryVariables>
export const MedicationsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'medicationsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'medicationRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'authoredOn' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dispenseRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'initialFill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quantity' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dosageInstruction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'asNeededBoolean' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'doseAndRate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'doseQuantity' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'method' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coding' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'route' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coding' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timing' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'repeat' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'durationUnit' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'periodUnit' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'medicationReference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'lastUpdated' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MedicationsQueryQuery, MedicationsQueryQueryVariables>
export const PatientAthenaProfilePaidQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'patientAthenaProfilePaidQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'athenaProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assignedAtBirthSex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'consentToCall' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'consentToText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactMobilePhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactRelationship' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'driversLicenseExpirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'driversLicenseNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'driversLicenseStateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ethnicityCodes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'language6392Code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maritalStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'race' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatientAthenaProfilePaidQueryQuery, PatientAthenaProfilePaidQueryQueryVariables>
export const PatientStudiesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'patientStudiesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibleStudies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrolledStudies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatientStudiesQueryQuery, PatientStudiesQueryQueryVariables>
export const TaskCarePathwayQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'taskCarePathwayQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carePathway' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'events' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'carePathwayId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'surveySubmission' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'events' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'at' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'by' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastSeenAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'seenBy' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'submissionId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'surveyId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskCarePathwayQueryQuery, TaskCarePathwayQueryQueryVariables>
export type AddressKeySpecifier = (
  | 'city'
  | 'country'
  | 'district'
  | 'id'
  | 'line'
  | 'period'
  | 'postalCode'
  | 'state'
  | 'text'
  | AddressKeySpecifier
)[]
export type AddressFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>
  country?: FieldPolicy<any> | FieldReadFunction<any>
  district?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  line?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>
  state?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AgeKeySpecifier = ('code' | 'id' | 'system' | 'unit' | 'value' | AgeKeySpecifier)[]
export type AgeFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  system?: FieldPolicy<any> | FieldReadFunction<any>
  unit?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AllergyIntoleranceKeySpecifier = (
  | 'asserter'
  | 'category'
  | 'clinicalStatus'
  | 'code'
  | 'id'
  | 'lastOccurrence'
  | 'note'
  | 'onsetDateTime'
  | 'onsetPeriod'
  | 'onsetRange'
  | 'onsetString'
  | 'reaction'
  | 'recordedDate'
  | 'recorder'
  | 'type'
  | 'verificationStatus'
  | AllergyIntoleranceKeySpecifier
)[]
export type AllergyIntoleranceFieldPolicy = {
  asserter?: FieldPolicy<any> | FieldReadFunction<any>
  category?: FieldPolicy<any> | FieldReadFunction<any>
  clinicalStatus?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  lastOccurrence?: FieldPolicy<any> | FieldReadFunction<any>
  note?: FieldPolicy<any> | FieldReadFunction<any>
  onsetDateTime?: FieldPolicy<any> | FieldReadFunction<any>
  onsetPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  onsetRange?: FieldPolicy<any> | FieldReadFunction<any>
  onsetString?: FieldPolicy<any> | FieldReadFunction<any>
  reaction?: FieldPolicy<any> | FieldReadFunction<any>
  recordedDate?: FieldPolicy<any> | FieldReadFunction<any>
  recorder?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  verificationStatus?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AllergyIntoleranceReactionKeySpecifier = (
  | 'description'
  | 'exposureRoute'
  | 'id'
  | 'manifestation'
  | 'note'
  | 'onset'
  | 'severity'
  | 'substance'
  | AllergyIntoleranceReactionKeySpecifier
)[]
export type AllergyIntoleranceReactionFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>
  exposureRoute?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  manifestation?: FieldPolicy<any> | FieldReadFunction<any>
  note?: FieldPolicy<any> | FieldReadFunction<any>
  onset?: FieldPolicy<any> | FieldReadFunction<any>
  severity?: FieldPolicy<any> | FieldReadFunction<any>
  substance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnnotationKeySpecifier = (
  | 'authorReference'
  | 'authorString'
  | 'id'
  | 'text'
  | 'time'
  | AnnotationKeySpecifier
)[]
export type AnnotationFieldPolicy = {
  authorReference?: FieldPolicy<any> | FieldReadFunction<any>
  authorString?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AthenaProfileKeySpecifier = (
  | 'address1'
  | 'assignedAtBirthSex'
  | 'city'
  | 'consentToCall'
  | 'consentToText'
  | 'contactMobilePhone'
  | 'contactName'
  | 'contactRelationship'
  | 'dob'
  | 'driversLicenseExpirationDate'
  | 'driversLicenseNumber'
  | 'driversLicenseStateId'
  | 'email'
  | 'ethnicityCodes'
  | 'firstName'
  | 'id'
  | 'language6392Code'
  | 'lastName'
  | 'maritalStatus'
  | 'preferredName'
  | 'pronouns'
  | 'race'
  | 'sex'
  | 'state'
  | 'zip'
  | AthenaProfileKeySpecifier
)[]
export type AthenaProfileFieldPolicy = {
  address1?: FieldPolicy<any> | FieldReadFunction<any>
  assignedAtBirthSex?: FieldPolicy<any> | FieldReadFunction<any>
  city?: FieldPolicy<any> | FieldReadFunction<any>
  consentToCall?: FieldPolicy<any> | FieldReadFunction<any>
  consentToText?: FieldPolicy<any> | FieldReadFunction<any>
  contactMobilePhone?: FieldPolicy<any> | FieldReadFunction<any>
  contactName?: FieldPolicy<any> | FieldReadFunction<any>
  contactRelationship?: FieldPolicy<any> | FieldReadFunction<any>
  dob?: FieldPolicy<any> | FieldReadFunction<any>
  driversLicenseExpirationDate?: FieldPolicy<any> | FieldReadFunction<any>
  driversLicenseNumber?: FieldPolicy<any> | FieldReadFunction<any>
  driversLicenseStateId?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  ethnicityCodes?: FieldPolicy<any> | FieldReadFunction<any>
  firstName?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language6392Code?: FieldPolicy<any> | FieldReadFunction<any>
  lastName?: FieldPolicy<any> | FieldReadFunction<any>
  maritalStatus?: FieldPolicy<any> | FieldReadFunction<any>
  preferredName?: FieldPolicy<any> | FieldReadFunction<any>
  pronouns?: FieldPolicy<any> | FieldReadFunction<any>
  race?: FieldPolicy<any> | FieldReadFunction<any>
  sex?: FieldPolicy<any> | FieldReadFunction<any>
  state?: FieldPolicy<any> | FieldReadFunction<any>
  zip?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AttachmentKeySpecifier = (
  | 'contentType'
  | 'creation'
  | 'data'
  | 'hash'
  | 'id'
  | 'language'
  | 'size'
  | 'title'
  | 'url'
  | AttachmentKeySpecifier
)[]
export type AttachmentFieldPolicy = {
  contentType?: FieldPolicy<any> | FieldReadFunction<any>
  creation?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
  hash?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuditStatusKeySpecifier = ('at' | 'by' | AuditStatusKeySpecifier)[]
export type AuditStatusFieldPolicy = {
  at?: FieldPolicy<any> | FieldReadFunction<any>
  by?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BloodPressureEscalationCategoryKeySpecifier = (
  | 'reasons'
  | 'type'
  | BloodPressureEscalationCategoryKeySpecifier
)[]
export type BloodPressureEscalationCategoryFieldPolicy = {
  reasons?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BloodPressureMeasurementKeySpecifier = (
  | 'beatsPerMinute'
  | 'bloodPressureEscalationCategory'
  | 'bloodPressureEscalationId'
  | 'bloodPressureSet'
  | 'bloodPressureSetId'
  | 'comment'
  | 'created'
  | 'deviceType'
  | 'diastolicBloodPressure'
  | 'escalation'
  | 'id'
  | 'patient'
  | 'patientId'
  | 'personId'
  | 'photo'
  | 'systolicBloodPressure'
  | 'timeZone'
  | 'type'
  | 'updated'
  | BloodPressureMeasurementKeySpecifier
)[]
export type BloodPressureMeasurementFieldPolicy = {
  beatsPerMinute?: FieldPolicy<any> | FieldReadFunction<any>
  bloodPressureEscalationCategory?: FieldPolicy<any> | FieldReadFunction<any>
  bloodPressureEscalationId?: FieldPolicy<any> | FieldReadFunction<any>
  bloodPressureSet?: FieldPolicy<any> | FieldReadFunction<any>
  bloodPressureSetId?: FieldPolicy<any> | FieldReadFunction<any>
  comment?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  deviceType?: FieldPolicy<any> | FieldReadFunction<any>
  diastolicBloodPressure?: FieldPolicy<any> | FieldReadFunction<any>
  escalation?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  patient?: FieldPolicy<any> | FieldReadFunction<any>
  patientId?: FieldPolicy<any> | FieldReadFunction<any>
  personId?: FieldPolicy<any> | FieldReadFunction<any>
  photo?: FieldPolicy<any> | FieldReadFunction<any>
  systolicBloodPressure?: FieldPolicy<any> | FieldReadFunction<any>
  timeZone?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BloodPressureSetKeySpecifier = (
  | 'bloodPressureMeasurementIds'
  | 'bloodPressureMeasurementStatus'
  | 'cancelledAt'
  | 'comment'
  | 'completedAt'
  | 'contextType'
  | 'created'
  | 'id'
  | 'locationType'
  | 'patient'
  | 'patientId'
  | 'person'
  | 'personId'
  | 'timeZone'
  | 'updated'
  | BloodPressureSetKeySpecifier
)[]
export type BloodPressureSetFieldPolicy = {
  bloodPressureMeasurementIds?: FieldPolicy<any> | FieldReadFunction<any>
  bloodPressureMeasurementStatus?: FieldPolicy<any> | FieldReadFunction<any>
  cancelledAt?: FieldPolicy<any> | FieldReadFunction<any>
  comment?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  contextType?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  locationType?: FieldPolicy<any> | FieldReadFunction<any>
  patient?: FieldPolicy<any> | FieldReadFunction<any>
  patientId?: FieldPolicy<any> | FieldReadFunction<any>
  person?: FieldPolicy<any> | FieldReadFunction<any>
  personId?: FieldPolicy<any> | FieldReadFunction<any>
  timeZone?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CarePathwayKeySpecifier = (
  | 'created'
  | 'description'
  | 'events'
  | 'id'
  | 'patient'
  | 'patientId'
  | 'status'
  | 'summary'
  | 'tasks'
  | 'type'
  | 'updated'
  | CarePathwayKeySpecifier
)[]
export type CarePathwayFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  events?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  patient?: FieldPolicy<any> | FieldReadFunction<any>
  patientId?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  summary?: FieldPolicy<any> | FieldReadFunction<any>
  tasks?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeableConceptKeySpecifier = ('coding' | 'text' | CodeableConceptKeySpecifier)[]
export type CodeableConceptFieldPolicy = {
  coding?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodingKeySpecifier = (
  | 'code'
  | 'display'
  | 'id'
  | 'system'
  | 'userSelected'
  | 'version'
  | CodingKeySpecifier
)[]
export type CodingFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  display?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  system?: FieldPolicy<any> | FieldReadFunction<any>
  userSelected?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConditionKeySpecifier = (
  | 'abatementAge'
  | 'abatementDateTime'
  | 'abatementPeriod'
  | 'abatementRange'
  | 'abatementString'
  | 'asserter'
  | 'bodySite'
  | 'category'
  | 'clinicalStatus'
  | 'code'
  | 'encounter'
  | 'evidence'
  | 'id'
  | 'meta'
  | 'note'
  | 'onsetAge'
  | 'onsetDateTime'
  | 'onsetPeriod'
  | 'onsetRange'
  | 'onsetString'
  | 'recordedDate'
  | 'recorder'
  | 'severity'
  | 'stage'
  | 'subject'
  | 'text'
  | 'verificationStatus'
  | ConditionKeySpecifier
)[]
export type ConditionFieldPolicy = {
  abatementAge?: FieldPolicy<any> | FieldReadFunction<any>
  abatementDateTime?: FieldPolicy<any> | FieldReadFunction<any>
  abatementPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  abatementRange?: FieldPolicy<any> | FieldReadFunction<any>
  abatementString?: FieldPolicy<any> | FieldReadFunction<any>
  asserter?: FieldPolicy<any> | FieldReadFunction<any>
  bodySite?: FieldPolicy<any> | FieldReadFunction<any>
  category?: FieldPolicy<any> | FieldReadFunction<any>
  clinicalStatus?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  encounter?: FieldPolicy<any> | FieldReadFunction<any>
  evidence?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  note?: FieldPolicy<any> | FieldReadFunction<any>
  onsetAge?: FieldPolicy<any> | FieldReadFunction<any>
  onsetDateTime?: FieldPolicy<any> | FieldReadFunction<any>
  onsetPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  onsetRange?: FieldPolicy<any> | FieldReadFunction<any>
  onsetString?: FieldPolicy<any> | FieldReadFunction<any>
  recordedDate?: FieldPolicy<any> | FieldReadFunction<any>
  recorder?: FieldPolicy<any> | FieldReadFunction<any>
  severity?: FieldPolicy<any> | FieldReadFunction<any>
  stage?: FieldPolicy<any> | FieldReadFunction<any>
  subject?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
  verificationStatus?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConditionEvidenceKeySpecifier = ('code' | 'detail' | 'id' | ConditionEvidenceKeySpecifier)[]
export type ConditionEvidenceFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  detail?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConditionStageKeySpecifier = ('assessment' | 'id' | 'summary' | 'type' | ConditionStageKeySpecifier)[]
export type ConditionStageFieldPolicy = {
  assessment?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  summary?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ContactPointKeySpecifier = ('id' | 'period' | 'rank' | 'value' | ContactPointKeySpecifier)[]
export type ContactPointFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  rank?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DosageKeySpecifier = (
  | 'additionalInstruction'
  | 'asNeededBoolean'
  | 'asNeededCodeableConcept'
  | 'doseAndRate'
  | 'maxDosePerAdministration'
  | 'maxDosePerLifetime'
  | 'maxDosePerPeriod'
  | 'method'
  | 'patientInstruction'
  | 'route'
  | 'sequence'
  | 'site'
  | 'text'
  | 'timing'
  | DosageKeySpecifier
)[]
export type DosageFieldPolicy = {
  additionalInstruction?: FieldPolicy<any> | FieldReadFunction<any>
  asNeededBoolean?: FieldPolicy<any> | FieldReadFunction<any>
  asNeededCodeableConcept?: FieldPolicy<any> | FieldReadFunction<any>
  doseAndRate?: FieldPolicy<any> | FieldReadFunction<any>
  maxDosePerAdministration?: FieldPolicy<any> | FieldReadFunction<any>
  maxDosePerLifetime?: FieldPolicy<any> | FieldReadFunction<any>
  maxDosePerPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  method?: FieldPolicy<any> | FieldReadFunction<any>
  patientInstruction?: FieldPolicy<any> | FieldReadFunction<any>
  route?: FieldPolicy<any> | FieldReadFunction<any>
  sequence?: FieldPolicy<any> | FieldReadFunction<any>
  site?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
  timing?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DosageDoseAndRateKeySpecifier = (
  | 'doseQuantity'
  | 'doseRange'
  | 'rateQuantity'
  | 'rateRange'
  | 'rateRatio'
  | 'type'
  | DosageDoseAndRateKeySpecifier
)[]
export type DosageDoseAndRateFieldPolicy = {
  doseQuantity?: FieldPolicy<any> | FieldReadFunction<any>
  doseRange?: FieldPolicy<any> | FieldReadFunction<any>
  rateQuantity?: FieldPolicy<any> | FieldReadFunction<any>
  rateRange?: FieldPolicy<any> | FieldReadFunction<any>
  rateRatio?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DurationKeySpecifier = ('code' | 'id' | 'system' | 'unit' | 'value' | DurationKeySpecifier)[]
export type DurationFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  system?: FieldPolicy<any> | FieldReadFunction<any>
  unit?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EHRPatientRecordKeySpecifier = (
  | 'active'
  | 'address'
  | 'birthDate'
  | 'communication'
  | 'contact'
  | 'deceasedBoolean'
  | 'deceasedDateTime'
  | 'gender'
  | 'generalPractitioner'
  | 'id'
  | 'language'
  | 'link'
  | 'managingOrganization'
  | 'maritalStatus'
  | 'meta'
  | 'multipleBirthBoolean'
  | 'multipleBirthInteger'
  | 'name'
  | 'photo'
  | 'telecom'
  | 'text'
  | EHRPatientRecordKeySpecifier
)[]
export type EHRPatientRecordFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>
  address?: FieldPolicy<any> | FieldReadFunction<any>
  birthDate?: FieldPolicy<any> | FieldReadFunction<any>
  communication?: FieldPolicy<any> | FieldReadFunction<any>
  contact?: FieldPolicy<any> | FieldReadFunction<any>
  deceasedBoolean?: FieldPolicy<any> | FieldReadFunction<any>
  deceasedDateTime?: FieldPolicy<any> | FieldReadFunction<any>
  gender?: FieldPolicy<any> | FieldReadFunction<any>
  generalPractitioner?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  link?: FieldPolicy<any> | FieldReadFunction<any>
  managingOrganization?: FieldPolicy<any> | FieldReadFunction<any>
  maritalStatus?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  multipleBirthBoolean?: FieldPolicy<any> | FieldReadFunction<any>
  multipleBirthInteger?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  photo?: FieldPolicy<any> | FieldReadFunction<any>
  telecom?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EventKeySpecifier = ('created' | 'data' | EventKeySpecifier)[]
export type EventFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HumanNameKeySpecifier = (
  | 'family'
  | 'given'
  | 'id'
  | 'period'
  | 'prefix'
  | 'suffix'
  | 'text'
  | HumanNameKeySpecifier
)[]
export type HumanNameFieldPolicy = {
  family?: FieldPolicy<any> | FieldReadFunction<any>
  given?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  prefix?: FieldPolicy<any> | FieldReadFunction<any>
  suffix?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IdentifierKeySpecifier = (
  | 'assigner'
  | 'id'
  | 'period'
  | 'system'
  | 'type'
  | 'value'
  | IdentifierKeySpecifier
)[]
export type IdentifierFieldPolicy = {
  assigner?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  system?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MedicationKeySpecifier = (
  | 'approvedby'
  | 'billingndc'
  | 'createdby'
  | 'earliestfilldate'
  | 'encounterid'
  | 'events'
  | 'futuresubmitdate'
  | 'isdiscontinued'
  | 'issafetorenew'
  | 'isstructuredsig'
  | 'medication'
  | 'medicationentryid'
  | 'medicationid'
  | 'ndcoptions'
  | 'orderingmode'
  | 'organclass'
  | 'patientnote'
  | 'pharmacy'
  | 'pharmacyncpdpid'
  | 'prescribedby'
  | 'providernote'
  | 'quantity'
  | 'quantityunit'
  | 'refillsallowed'
  | 'route'
  | 'rxnorm'
  | 'source'
  | 'status'
  | 'stopreason'
  | 'structuredsig'
  | 'therapeuticclass'
  | 'unstructuredsig'
  | MedicationKeySpecifier
)[]
export type MedicationFieldPolicy = {
  approvedby?: FieldPolicy<any> | FieldReadFunction<any>
  billingndc?: FieldPolicy<any> | FieldReadFunction<any>
  createdby?: FieldPolicy<any> | FieldReadFunction<any>
  earliestfilldate?: FieldPolicy<any> | FieldReadFunction<any>
  encounterid?: FieldPolicy<any> | FieldReadFunction<any>
  events?: FieldPolicy<any> | FieldReadFunction<any>
  futuresubmitdate?: FieldPolicy<any> | FieldReadFunction<any>
  isdiscontinued?: FieldPolicy<any> | FieldReadFunction<any>
  issafetorenew?: FieldPolicy<any> | FieldReadFunction<any>
  isstructuredsig?: FieldPolicy<any> | FieldReadFunction<any>
  medication?: FieldPolicy<any> | FieldReadFunction<any>
  medicationentryid?: FieldPolicy<any> | FieldReadFunction<any>
  medicationid?: FieldPolicy<any> | FieldReadFunction<any>
  ndcoptions?: FieldPolicy<any> | FieldReadFunction<any>
  orderingmode?: FieldPolicy<any> | FieldReadFunction<any>
  organclass?: FieldPolicy<any> | FieldReadFunction<any>
  patientnote?: FieldPolicy<any> | FieldReadFunction<any>
  pharmacy?: FieldPolicy<any> | FieldReadFunction<any>
  pharmacyncpdpid?: FieldPolicy<any> | FieldReadFunction<any>
  prescribedby?: FieldPolicy<any> | FieldReadFunction<any>
  providernote?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  quantityunit?: FieldPolicy<any> | FieldReadFunction<any>
  refillsallowed?: FieldPolicy<any> | FieldReadFunction<any>
  route?: FieldPolicy<any> | FieldReadFunction<any>
  rxnorm?: FieldPolicy<any> | FieldReadFunction<any>
  source?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  stopreason?: FieldPolicy<any> | FieldReadFunction<any>
  structuredsig?: FieldPolicy<any> | FieldReadFunction<any>
  therapeuticclass?: FieldPolicy<any> | FieldReadFunction<any>
  unstructuredsig?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MedicationEventKeySpecifier = ('eventdate' | 'type' | 'userdisplayname' | MedicationEventKeySpecifier)[]
export type MedicationEventFieldPolicy = {
  eventdate?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  userdisplayname?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MedicationRequestKeySpecifier = (
  | 'authoredOn'
  | 'basedOn'
  | 'category'
  | 'courseOfTherapyType'
  | 'detectedIssue'
  | 'dispenseRequest'
  | 'doNotPerform'
  | 'dosageInstruction'
  | 'encounter'
  | 'eventHistory'
  | 'id'
  | 'intent'
  | 'medicationCodeableConcept'
  | 'medicationReference'
  | 'meta'
  | 'note'
  | 'performer'
  | 'performerType'
  | 'priorPrescription'
  | 'priority'
  | 'reasonCode'
  | 'reportedBoolean'
  | 'requester'
  | 'status'
  | 'statusReason'
  | 'substitution'
  | 'supportingInformation'
  | 'text'
  | MedicationRequestKeySpecifier
)[]
export type MedicationRequestFieldPolicy = {
  authoredOn?: FieldPolicy<any> | FieldReadFunction<any>
  basedOn?: FieldPolicy<any> | FieldReadFunction<any>
  category?: FieldPolicy<any> | FieldReadFunction<any>
  courseOfTherapyType?: FieldPolicy<any> | FieldReadFunction<any>
  detectedIssue?: FieldPolicy<any> | FieldReadFunction<any>
  dispenseRequest?: FieldPolicy<any> | FieldReadFunction<any>
  doNotPerform?: FieldPolicy<any> | FieldReadFunction<any>
  dosageInstruction?: FieldPolicy<any> | FieldReadFunction<any>
  encounter?: FieldPolicy<any> | FieldReadFunction<any>
  eventHistory?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  intent?: FieldPolicy<any> | FieldReadFunction<any>
  medicationCodeableConcept?: FieldPolicy<any> | FieldReadFunction<any>
  medicationReference?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  note?: FieldPolicy<any> | FieldReadFunction<any>
  performer?: FieldPolicy<any> | FieldReadFunction<any>
  performerType?: FieldPolicy<any> | FieldReadFunction<any>
  priorPrescription?: FieldPolicy<any> | FieldReadFunction<any>
  priority?: FieldPolicy<any> | FieldReadFunction<any>
  reasonCode?: FieldPolicy<any> | FieldReadFunction<any>
  reportedBoolean?: FieldPolicy<any> | FieldReadFunction<any>
  requester?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  statusReason?: FieldPolicy<any> | FieldReadFunction<any>
  substitution?: FieldPolicy<any> | FieldReadFunction<any>
  supportingInformation?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MedicationRequestDispenseRequestKeySpecifier = (
  | 'dispenseInterval'
  | 'expectedSupplyDuration'
  | 'initialFill'
  | 'numberOfRepeatsAllowed'
  | 'performer'
  | 'quantity'
  | 'validityPeriod'
  | MedicationRequestDispenseRequestKeySpecifier
)[]
export type MedicationRequestDispenseRequestFieldPolicy = {
  dispenseInterval?: FieldPolicy<any> | FieldReadFunction<any>
  expectedSupplyDuration?: FieldPolicy<any> | FieldReadFunction<any>
  initialFill?: FieldPolicy<any> | FieldReadFunction<any>
  numberOfRepeatsAllowed?: FieldPolicy<any> | FieldReadFunction<any>
  performer?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  validityPeriod?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MedicationRequestInitialFillKeySpecifier = (
  | 'duration'
  | 'quantity'
  | MedicationRequestInitialFillKeySpecifier
)[]
export type MedicationRequestInitialFillFieldPolicy = {
  duration?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MedicationRequestSubstitutionKeySpecifier = (
  | 'allowedBoolean'
  | 'allowedCodeableConcept'
  | 'id'
  | 'reason'
  | MedicationRequestSubstitutionKeySpecifier
)[]
export type MedicationRequestSubstitutionFieldPolicy = {
  allowedBoolean?: FieldPolicy<any> | FieldReadFunction<any>
  allowedCodeableConcept?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  reason?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MedicationResponseKeySpecifier = (
  | 'lastdownloaddenialreason'
  | 'lastdownloaddenied'
  | 'lastdownloadeddate'
  | 'lastupdated'
  | 'medications'
  | 'nomedicationsreported'
  | 'patientdownloadconsent'
  | 'patientneedsdownloadconsent'
  | 'sectionnote'
  | MedicationResponseKeySpecifier
)[]
export type MedicationResponseFieldPolicy = {
  lastdownloaddenialreason?: FieldPolicy<any> | FieldReadFunction<any>
  lastdownloaddenied?: FieldPolicy<any> | FieldReadFunction<any>
  lastdownloadeddate?: FieldPolicy<any> | FieldReadFunction<any>
  lastupdated?: FieldPolicy<any> | FieldReadFunction<any>
  medications?: FieldPolicy<any> | FieldReadFunction<any>
  nomedicationsreported?: FieldPolicy<any> | FieldReadFunction<any>
  patientdownloadconsent?: FieldPolicy<any> | FieldReadFunction<any>
  patientneedsdownloadconsent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionnote?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MetaKeySpecifier = (
  | 'id'
  | 'lastUpdated'
  | 'profile'
  | 'security'
  | 'source'
  | 'tag'
  | 'versionId'
  | MetaKeySpecifier
)[]
export type MetaFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  lastUpdated?: FieldPolicy<any> | FieldReadFunction<any>
  profile?: FieldPolicy<any> | FieldReadFunction<any>
  security?: FieldPolicy<any> | FieldReadFunction<any>
  source?: FieldPolicy<any> | FieldReadFunction<any>
  tag?: FieldPolicy<any> | FieldReadFunction<any>
  versionId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MutationKeySpecifier = ('scheduleVisit' | MutationKeySpecifier)[]
export type MutationFieldPolicy = {
  scheduleVisit?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NarrativeKeySpecifier = ('div' | 'id' | 'status' | NarrativeKeySpecifier)[]
export type NarrativeFieldPolicy = {
  div?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ObservationKeySpecifier = (
  | 'basedOn'
  | 'bodySite'
  | 'category'
  | 'code'
  | 'component'
  | 'dataAbsentReason'
  | 'derivedFrom'
  | 'device'
  | 'effectiveDateTime'
  | 'effectiveInstant'
  | 'effectivePeriod'
  | 'effectiveTiming'
  | 'encounter'
  | 'focus'
  | 'hasMember'
  | 'id'
  | 'interpretation'
  | 'issued'
  | 'language'
  | 'meta'
  | 'method'
  | 'note'
  | 'partOf'
  | 'performer'
  | 'referenceRange'
  | 'specimen'
  | 'status'
  | 'subject'
  | 'text'
  | 'valueBoolean'
  | 'valueCodeableConcept'
  | 'valueDateTime'
  | 'valueInteger'
  | 'valuePeriod'
  | 'valueQuantity'
  | 'valueRange'
  | 'valueRatio'
  | 'valueString'
  | 'valueTime'
  | ObservationKeySpecifier
)[]
export type ObservationFieldPolicy = {
  basedOn?: FieldPolicy<any> | FieldReadFunction<any>
  bodySite?: FieldPolicy<any> | FieldReadFunction<any>
  category?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  component?: FieldPolicy<any> | FieldReadFunction<any>
  dataAbsentReason?: FieldPolicy<any> | FieldReadFunction<any>
  derivedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  device?: FieldPolicy<any> | FieldReadFunction<any>
  effectiveDateTime?: FieldPolicy<any> | FieldReadFunction<any>
  effectiveInstant?: FieldPolicy<any> | FieldReadFunction<any>
  effectivePeriod?: FieldPolicy<any> | FieldReadFunction<any>
  effectiveTiming?: FieldPolicy<any> | FieldReadFunction<any>
  encounter?: FieldPolicy<any> | FieldReadFunction<any>
  focus?: FieldPolicy<any> | FieldReadFunction<any>
  hasMember?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  interpretation?: FieldPolicy<any> | FieldReadFunction<any>
  issued?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  method?: FieldPolicy<any> | FieldReadFunction<any>
  note?: FieldPolicy<any> | FieldReadFunction<any>
  partOf?: FieldPolicy<any> | FieldReadFunction<any>
  performer?: FieldPolicy<any> | FieldReadFunction<any>
  referenceRange?: FieldPolicy<any> | FieldReadFunction<any>
  specimen?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  subject?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
  valueBoolean?: FieldPolicy<any> | FieldReadFunction<any>
  valueCodeableConcept?: FieldPolicy<any> | FieldReadFunction<any>
  valueDateTime?: FieldPolicy<any> | FieldReadFunction<any>
  valueInteger?: FieldPolicy<any> | FieldReadFunction<any>
  valuePeriod?: FieldPolicy<any> | FieldReadFunction<any>
  valueQuantity?: FieldPolicy<any> | FieldReadFunction<any>
  valueRange?: FieldPolicy<any> | FieldReadFunction<any>
  valueRatio?: FieldPolicy<any> | FieldReadFunction<any>
  valueString?: FieldPolicy<any> | FieldReadFunction<any>
  valueTime?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ObservationComponentKeySpecifier = (
  | 'code'
  | 'dataAbsentReason'
  | 'id'
  | 'interpretation'
  | 'referenceRange'
  | 'valueBoolean'
  | 'valueCodeableConcept'
  | 'valueDateTime'
  | 'valueInteger'
  | 'valuePeriod'
  | 'valueQuantity'
  | 'valueRange'
  | 'valueRatio'
  | 'valueString'
  | 'valueTime'
  | ObservationComponentKeySpecifier
)[]
export type ObservationComponentFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  dataAbsentReason?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  interpretation?: FieldPolicy<any> | FieldReadFunction<any>
  referenceRange?: FieldPolicy<any> | FieldReadFunction<any>
  valueBoolean?: FieldPolicy<any> | FieldReadFunction<any>
  valueCodeableConcept?: FieldPolicy<any> | FieldReadFunction<any>
  valueDateTime?: FieldPolicy<any> | FieldReadFunction<any>
  valueInteger?: FieldPolicy<any> | FieldReadFunction<any>
  valuePeriod?: FieldPolicy<any> | FieldReadFunction<any>
  valueQuantity?: FieldPolicy<any> | FieldReadFunction<any>
  valueRange?: FieldPolicy<any> | FieldReadFunction<any>
  valueRatio?: FieldPolicy<any> | FieldReadFunction<any>
  valueString?: FieldPolicy<any> | FieldReadFunction<any>
  valueTime?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ObservationReferenceRangeKeySpecifier = (
  | 'age'
  | 'appliesTo'
  | 'high'
  | 'id'
  | 'low'
  | 'text'
  | 'type'
  | ObservationReferenceRangeKeySpecifier
)[]
export type ObservationReferenceRangeFieldPolicy = {
  age?: FieldPolicy<any> | FieldReadFunction<any>
  appliesTo?: FieldPolicy<any> | FieldReadFunction<any>
  high?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  low?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PatientKeySpecifier = (
  | 'allergyIntolerances'
  | 'athenaProfile'
  | 'bloodPressureMeasurements'
  | 'carePathways'
  | 'conditions'
  | 'departmentId'
  | 'ehrRecord'
  | 'eligibleStudies'
  | 'enrolledStudies'
  | 'id'
  | 'medicalRecordNumber'
  | 'medicationRequests'
  | 'medications'
  | 'observations'
  | 'person'
  | 'personId'
  | PatientKeySpecifier
)[]
export type PatientFieldPolicy = {
  allergyIntolerances?: FieldPolicy<any> | FieldReadFunction<any>
  athenaProfile?: FieldPolicy<any> | FieldReadFunction<any>
  bloodPressureMeasurements?: FieldPolicy<any> | FieldReadFunction<any>
  carePathways?: FieldPolicy<any> | FieldReadFunction<any>
  conditions?: FieldPolicy<any> | FieldReadFunction<any>
  departmentId?: FieldPolicy<any> | FieldReadFunction<any>
  ehrRecord?: FieldPolicy<any> | FieldReadFunction<any>
  eligibleStudies?: FieldPolicy<any> | FieldReadFunction<any>
  enrolledStudies?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  medicalRecordNumber?: FieldPolicy<any> | FieldReadFunction<any>
  medicationRequests?: FieldPolicy<any> | FieldReadFunction<any>
  medications?: FieldPolicy<any> | FieldReadFunction<any>
  observations?: FieldPolicy<any> | FieldReadFunction<any>
  person?: FieldPolicy<any> | FieldReadFunction<any>
  personId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PatientCommunicationKeySpecifier = ('id' | 'language' | 'preferred' | PatientCommunicationKeySpecifier)[]
export type PatientCommunicationFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  preferred?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PatientContactKeySpecifier = (
  | 'address'
  | 'gender'
  | 'id'
  | 'name'
  | 'organization'
  | 'period'
  | 'relationship'
  | 'telecom'
  | PatientContactKeySpecifier
)[]
export type PatientContactFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  gender?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  organization?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  relationship?: FieldPolicy<any> | FieldReadFunction<any>
  telecom?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PatientLinkKeySpecifier = ('id' | 'other' | 'type' | PatientLinkKeySpecifier)[]
export type PatientLinkFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  other?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PeriodKeySpecifier = ('end' | 'id' | 'start' | PeriodKeySpecifier)[]
export type PeriodFieldPolicy = {
  end?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  start?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PersonKeySpecifier = ('email' | 'firstName' | 'id' | 'lastName' | 'phoneNumber' | PersonKeySpecifier)[]
export type PersonFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>
  firstName?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  lastName?: FieldPolicy<any> | FieldReadFunction<any>
  phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PhotoUploadStatusKeySpecifier = ('error' | 'etag' | 'location' | 'state' | PhotoUploadStatusKeySpecifier)[]
export type PhotoUploadStatusFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  etag?: FieldPolicy<any> | FieldReadFunction<any>
  location?: FieldPolicy<any> | FieldReadFunction<any>
  state?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PractitionerKeySpecifier = (
  | 'created'
  | 'id'
  | 'isActive'
  | 'person'
  | 'prefix'
  | 'profile'
  | 'suffix'
  | 'updated'
  | 'visitMeta'
  | PractitionerKeySpecifier
)[]
export type PractitionerFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isActive?: FieldPolicy<any> | FieldReadFunction<any>
  person?: FieldPolicy<any> | FieldReadFunction<any>
  prefix?: FieldPolicy<any> | FieldReadFunction<any>
  profile?: FieldPolicy<any> | FieldReadFunction<any>
  suffix?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
  visitMeta?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PractitionerImagesKeySpecifier = ('large' | 'medium' | 'small' | PractitionerImagesKeySpecifier)[]
export type PractitionerImagesFieldPolicy = {
  large?: FieldPolicy<any> | FieldReadFunction<any>
  medium?: FieldPolicy<any> | FieldReadFunction<any>
  small?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PractitionerProfileKeySpecifier = (
  | 'bio'
  | 'created'
  | 'familyName'
  | 'givenName'
  | 'id'
  | 'images'
  | 'interests'
  | 'location'
  | 'practitionerId'
  | 'primaryQualification'
  | 'qualifications'
  | 'title'
  | 'updated'
  | PractitionerProfileKeySpecifier
)[]
export type PractitionerProfileFieldPolicy = {
  bio?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  familyName?: FieldPolicy<any> | FieldReadFunction<any>
  givenName?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  images?: FieldPolicy<any> | FieldReadFunction<any>
  interests?: FieldPolicy<any> | FieldReadFunction<any>
  location?: FieldPolicy<any> | FieldReadFunction<any>
  practitionerId?: FieldPolicy<any> | FieldReadFunction<any>
  primaryQualification?: FieldPolicy<any> | FieldReadFunction<any>
  qualifications?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QuantityKeySpecifier = ('code' | 'id' | 'system' | 'unit' | 'value' | QuantityKeySpecifier)[]
export type QuantityFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  system?: FieldPolicy<any> | FieldReadFunction<any>
  unit?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryKeySpecifier = (
  | 'carePathways'
  | 'ehrRecord'
  | 'patient'
  | 'practitioner'
  | 'practitioners'
  | 'task'
  | QueryKeySpecifier
)[]
export type QueryFieldPolicy = {
  carePathways?: FieldPolicy<any> | FieldReadFunction<any>
  ehrRecord?: FieldPolicy<any> | FieldReadFunction<any>
  patient?: FieldPolicy<any> | FieldReadFunction<any>
  practitioner?: FieldPolicy<any> | FieldReadFunction<any>
  practitioners?: FieldPolicy<any> | FieldReadFunction<any>
  task?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RangeKeySpecifier = ('high' | 'id' | 'low' | RangeKeySpecifier)[]
export type RangeFieldPolicy = {
  high?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  low?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RatioKeySpecifier = ('denominator' | 'numerator' | RatioKeySpecifier)[]
export type RatioFieldPolicy = {
  denominator?: FieldPolicy<any> | FieldReadFunction<any>
  numerator?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReferenceKeySpecifier = ('display' | 'id' | 'reference' | 'type' | ReferenceKeySpecifier)[]
export type ReferenceFieldPolicy = {
  display?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  reference?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SignatureKeySpecifier = (
  | 'data'
  | 'id'
  | 'onBehalfOf'
  | 'sigFormat'
  | 'targetFormat'
  | 'type'
  | 'when'
  | 'who'
  | SignatureKeySpecifier
)[]
export type SignatureFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  onBehalfOf?: FieldPolicy<any> | FieldReadFunction<any>
  sigFormat?: FieldPolicy<any> | FieldReadFunction<any>
  targetFormat?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  when?: FieldPolicy<any> | FieldReadFunction<any>
  who?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StructuredSigKeySpecifier = (
  | 'dosageaction'
  | 'dosageadditionalinstructions'
  | 'dosagedurationunit'
  | 'dosagedurationvalue'
  | 'dosagefrequencydescription'
  | 'dosagefrequencyunit'
  | 'dosagefrequencyvalue'
  | 'dosagequantityunit'
  | 'dosagequantityvalue'
  | 'dosageroute'
  | StructuredSigKeySpecifier
)[]
export type StructuredSigFieldPolicy = {
  dosageaction?: FieldPolicy<any> | FieldReadFunction<any>
  dosageadditionalinstructions?: FieldPolicy<any> | FieldReadFunction<any>
  dosagedurationunit?: FieldPolicy<any> | FieldReadFunction<any>
  dosagedurationvalue?: FieldPolicy<any> | FieldReadFunction<any>
  dosagefrequencydescription?: FieldPolicy<any> | FieldReadFunction<any>
  dosagefrequencyunit?: FieldPolicy<any> | FieldReadFunction<any>
  dosagefrequencyvalue?: FieldPolicy<any> | FieldReadFunction<any>
  dosagequantityunit?: FieldPolicy<any> | FieldReadFunction<any>
  dosagequantityvalue?: FieldPolicy<any> | FieldReadFunction<any>
  dosageroute?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StudyKeySpecifier = ('created' | 'id' | 'name' | 'updated' | StudyKeySpecifier)[]
export type StudyFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SurveyRequestKeySpecifier = ('surveyId' | 'urls' | SurveyRequestKeySpecifier)[]
export type SurveyRequestFieldPolicy = {
  surveyId?: FieldPolicy<any> | FieldReadFunction<any>
  urls?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SurveySubmissionKeySpecifier = (
  | 'completedAt'
  | 'data'
  | 'events'
  | 'id'
  | 'lastSeenAt'
  | 'patientId'
  | 'seenBy'
  | 'startedAt'
  | 'submissionId'
  | 'surveyId'
  | 'url'
  | SurveySubmissionKeySpecifier
)[]
export type SurveySubmissionFieldPolicy = {
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
  events?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  lastSeenAt?: FieldPolicy<any> | FieldReadFunction<any>
  patientId?: FieldPolicy<any> | FieldReadFunction<any>
  seenBy?: FieldPolicy<any> | FieldReadFunction<any>
  startedAt?: FieldPolicy<any> | FieldReadFunction<any>
  submissionId?: FieldPolicy<any> | FieldReadFunction<any>
  surveyId?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SurveySubmissionEventKeySpecifier = ('at' | 'by' | 'type' | SurveySubmissionEventKeySpecifier)[]
export type SurveySubmissionEventFieldPolicy = {
  at?: FieldPolicy<any> | FieldReadFunction<any>
  by?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SurveyUrlsKeySpecifier = ('android' | 'ios' | SurveyUrlsKeySpecifier)[]
export type SurveyUrlsFieldPolicy = {
  android?: FieldPolicy<any> | FieldReadFunction<any>
  ios?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TaskKeySpecifier = (
  | 'assigneeId'
  | 'assigneePatient'
  | 'assigneePractitioner'
  | 'carePathway'
  | 'carePathwayId'
  | 'comments'
  | 'copy'
  | 'created'
  | 'description'
  | 'dueDate'
  | 'events'
  | 'id'
  | 'patient'
  | 'patientId'
  | 'patientTouchPoint'
  | 'requestingPractitioner'
  | 'requestingPractitionerId'
  | 'role'
  | 'status'
  | 'summary'
  | 'survey'
  | 'taskType'
  | 'updated'
  | 'visit'
  | 'visitNotes'
  | 'workflow'
  | 'workflowId'
  | TaskKeySpecifier
)[]
export type TaskFieldPolicy = {
  assigneeId?: FieldPolicy<any> | FieldReadFunction<any>
  assigneePatient?: FieldPolicy<any> | FieldReadFunction<any>
  assigneePractitioner?: FieldPolicy<any> | FieldReadFunction<any>
  carePathway?: FieldPolicy<any> | FieldReadFunction<any>
  carePathwayId?: FieldPolicy<any> | FieldReadFunction<any>
  comments?: FieldPolicy<any> | FieldReadFunction<any>
  copy?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  dueDate?: FieldPolicy<any> | FieldReadFunction<any>
  events?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  patient?: FieldPolicy<any> | FieldReadFunction<any>
  patientId?: FieldPolicy<any> | FieldReadFunction<any>
  patientTouchPoint?: FieldPolicy<any> | FieldReadFunction<any>
  requestingPractitioner?: FieldPolicy<any> | FieldReadFunction<any>
  requestingPractitionerId?: FieldPolicy<any> | FieldReadFunction<any>
  role?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  summary?: FieldPolicy<any> | FieldReadFunction<any>
  survey?: FieldPolicy<any> | FieldReadFunction<any>
  taskType?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
  visit?: FieldPolicy<any> | FieldReadFunction<any>
  visitNotes?: FieldPolicy<any> | FieldReadFunction<any>
  workflow?: FieldPolicy<any> | FieldReadFunction<any>
  workflowId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TaskCommentKeySpecifier = ('body' | 'created' | 'id' | 'updated' | TaskCommentKeySpecifier)[]
export type TaskCommentFieldPolicy = {
  body?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TaskEventKeySpecifier = (
  | 'carePathway'
  | 'carePathwayId'
  | 'created'
  | 'data'
  | 'eventType'
  | 'id'
  | 'note'
  | 'patient'
  | 'patientId'
  | 'surveySubmission'
  | 'task'
  | 'taskId'
  | 'version'
  | TaskEventKeySpecifier
)[]
export type TaskEventFieldPolicy = {
  carePathway?: FieldPolicy<any> | FieldReadFunction<any>
  carePathwayId?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
  eventType?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  note?: FieldPolicy<any> | FieldReadFunction<any>
  patient?: FieldPolicy<any> | FieldReadFunction<any>
  patientId?: FieldPolicy<any> | FieldReadFunction<any>
  surveySubmission?: FieldPolicy<any> | FieldReadFunction<any>
  task?: FieldPolicy<any> | FieldReadFunction<any>
  taskId?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TimingKeySpecifier = ('code' | 'event' | 'id' | 'repeat' | TimingKeySpecifier)[]
export type TimingFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  event?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  repeat?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TimingRepeatKeySpecifier = (
  | 'boundsDuration'
  | 'boundsPeriod'
  | 'boundsRange'
  | 'count'
  | 'countMax'
  | 'dayOfWeek'
  | 'duration'
  | 'durationMax'
  | 'durationUnit'
  | 'frequency'
  | 'frequencyMax'
  | 'id'
  | 'offset'
  | 'period'
  | 'periodMax'
  | 'periodUnit'
  | 'timeOfDay'
  | 'when'
  | TimingRepeatKeySpecifier
)[]
export type TimingRepeatFieldPolicy = {
  boundsDuration?: FieldPolicy<any> | FieldReadFunction<any>
  boundsPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  boundsRange?: FieldPolicy<any> | FieldReadFunction<any>
  count?: FieldPolicy<any> | FieldReadFunction<any>
  countMax?: FieldPolicy<any> | FieldReadFunction<any>
  dayOfWeek?: FieldPolicy<any> | FieldReadFunction<any>
  duration?: FieldPolicy<any> | FieldReadFunction<any>
  durationMax?: FieldPolicy<any> | FieldReadFunction<any>
  durationUnit?: FieldPolicy<any> | FieldReadFunction<any>
  frequency?: FieldPolicy<any> | FieldReadFunction<any>
  frequencyMax?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  offset?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  periodMax?: FieldPolicy<any> | FieldReadFunction<any>
  periodUnit?: FieldPolicy<any> | FieldReadFunction<any>
  timeOfDay?: FieldPolicy<any> | FieldReadFunction<any>
  when?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisitKeySpecifier = (
  | 'carePathwayId'
  | 'created'
  | 'endTime'
  | 'id'
  | 'patientId'
  | 'practitionerId'
  | 'startTime'
  | 'status'
  | 'updated'
  | 'visitType'
  | VisitKeySpecifier
)[]
export type VisitFieldPolicy = {
  carePathwayId?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  endTime?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  patientId?: FieldPolicy<any> | FieldReadFunction<any>
  practitionerId?: FieldPolicy<any> | FieldReadFunction<any>
  startTime?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
  visitType?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisitAvailabilityDatesKeySpecifier = ('dates' | 'month' | VisitAvailabilityDatesKeySpecifier)[]
export type VisitAvailabilityDatesFieldPolicy = {
  dates?: FieldPolicy<any> | FieldReadFunction<any>
  month?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisitAvailabilityTimesKeySpecifier = ('date' | 'times' | VisitAvailabilityTimesKeySpecifier)[]
export type VisitAvailabilityTimesFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>
  times?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisitMetaKeySpecifier = (
  | 'availableDates'
  | 'availableTimes'
  | 'duration'
  | 'externalId'
  | 'externalProvider'
  | 'id'
  | VisitMetaKeySpecifier
)[]
export type VisitMetaFieldPolicy = {
  availableDates?: FieldPolicy<any> | FieldReadFunction<any>
  availableTimes?: FieldPolicy<any> | FieldReadFunction<any>
  duration?: FieldPolicy<any> | FieldReadFunction<any>
  externalId?: FieldPolicy<any> | FieldReadFunction<any>
  externalProvider?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisitNotesKeySpecifier = ('notesHTML' | 'practitioner' | VisitNotesKeySpecifier)[]
export type VisitNotesFieldPolicy = {
  notesHTML?: FieldPolicy<any> | FieldReadFunction<any>
  practitioner?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WorkflowKeySpecifier = (
  | 'carePathway'
  | 'carePathwayId'
  | 'created'
  | 'updated'
  | 'workflowId'
  | 'workflowType'
  | WorkflowKeySpecifier
)[]
export type WorkflowFieldPolicy = {
  carePathway?: FieldPolicy<any> | FieldReadFunction<any>
  carePathwayId?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  updated?: FieldPolicy<any> | FieldReadFunction<any>
  workflowId?: FieldPolicy<any> | FieldReadFunction<any>
  workflowType?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrictTypedTypePolicies = {
  Address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AddressKeySpecifier | (() => undefined | AddressKeySpecifier)
    fields?: AddressFieldPolicy
  }
  Age?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AgeKeySpecifier | (() => undefined | AgeKeySpecifier)
    fields?: AgeFieldPolicy
  }
  AllergyIntolerance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AllergyIntoleranceKeySpecifier | (() => undefined | AllergyIntoleranceKeySpecifier)
    fields?: AllergyIntoleranceFieldPolicy
  }
  AllergyIntoleranceReaction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AllergyIntoleranceReactionKeySpecifier
      | (() => undefined | AllergyIntoleranceReactionKeySpecifier)
    fields?: AllergyIntoleranceReactionFieldPolicy
  }
  Annotation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AnnotationKeySpecifier | (() => undefined | AnnotationKeySpecifier)
    fields?: AnnotationFieldPolicy
  }
  AthenaProfile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AthenaProfileKeySpecifier | (() => undefined | AthenaProfileKeySpecifier)
    fields?: AthenaProfileFieldPolicy
  }
  Attachment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AttachmentKeySpecifier | (() => undefined | AttachmentKeySpecifier)
    fields?: AttachmentFieldPolicy
  }
  AuditStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AuditStatusKeySpecifier | (() => undefined | AuditStatusKeySpecifier)
    fields?: AuditStatusFieldPolicy
  }
  BloodPressureEscalationCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BloodPressureEscalationCategoryKeySpecifier
      | (() => undefined | BloodPressureEscalationCategoryKeySpecifier)
    fields?: BloodPressureEscalationCategoryFieldPolicy
  }
  BloodPressureMeasurement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BloodPressureMeasurementKeySpecifier | (() => undefined | BloodPressureMeasurementKeySpecifier)
    fields?: BloodPressureMeasurementFieldPolicy
  }
  BloodPressureSet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BloodPressureSetKeySpecifier | (() => undefined | BloodPressureSetKeySpecifier)
    fields?: BloodPressureSetFieldPolicy
  }
  CarePathway?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CarePathwayKeySpecifier | (() => undefined | CarePathwayKeySpecifier)
    fields?: CarePathwayFieldPolicy
  }
  CodeableConcept?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CodeableConceptKeySpecifier | (() => undefined | CodeableConceptKeySpecifier)
    fields?: CodeableConceptFieldPolicy
  }
  Coding?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CodingKeySpecifier | (() => undefined | CodingKeySpecifier)
    fields?: CodingFieldPolicy
  }
  Condition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ConditionKeySpecifier | (() => undefined | ConditionKeySpecifier)
    fields?: ConditionFieldPolicy
  }
  ConditionEvidence?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ConditionEvidenceKeySpecifier | (() => undefined | ConditionEvidenceKeySpecifier)
    fields?: ConditionEvidenceFieldPolicy
  }
  ConditionStage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ConditionStageKeySpecifier | (() => undefined | ConditionStageKeySpecifier)
    fields?: ConditionStageFieldPolicy
  }
  ContactPoint?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ContactPointKeySpecifier | (() => undefined | ContactPointKeySpecifier)
    fields?: ContactPointFieldPolicy
  }
  Dosage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DosageKeySpecifier | (() => undefined | DosageKeySpecifier)
    fields?: DosageFieldPolicy
  }
  DosageDoseAndRate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DosageDoseAndRateKeySpecifier | (() => undefined | DosageDoseAndRateKeySpecifier)
    fields?: DosageDoseAndRateFieldPolicy
  }
  Duration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DurationKeySpecifier | (() => undefined | DurationKeySpecifier)
    fields?: DurationFieldPolicy
  }
  EHRPatientRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EHRPatientRecordKeySpecifier | (() => undefined | EHRPatientRecordKeySpecifier)
    fields?: EHRPatientRecordFieldPolicy
  }
  Event?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EventKeySpecifier | (() => undefined | EventKeySpecifier)
    fields?: EventFieldPolicy
  }
  HumanName?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HumanNameKeySpecifier | (() => undefined | HumanNameKeySpecifier)
    fields?: HumanNameFieldPolicy
  }
  Identifier?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IdentifierKeySpecifier | (() => undefined | IdentifierKeySpecifier)
    fields?: IdentifierFieldPolicy
  }
  Medication?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MedicationKeySpecifier | (() => undefined | MedicationKeySpecifier)
    fields?: MedicationFieldPolicy
  }
  MedicationEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MedicationEventKeySpecifier | (() => undefined | MedicationEventKeySpecifier)
    fields?: MedicationEventFieldPolicy
  }
  MedicationRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MedicationRequestKeySpecifier | (() => undefined | MedicationRequestKeySpecifier)
    fields?: MedicationRequestFieldPolicy
  }
  MedicationRequestDispenseRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MedicationRequestDispenseRequestKeySpecifier
      | (() => undefined | MedicationRequestDispenseRequestKeySpecifier)
    fields?: MedicationRequestDispenseRequestFieldPolicy
  }
  MedicationRequestInitialFill?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MedicationRequestInitialFillKeySpecifier
      | (() => undefined | MedicationRequestInitialFillKeySpecifier)
    fields?: MedicationRequestInitialFillFieldPolicy
  }
  MedicationRequestSubstitution?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MedicationRequestSubstitutionKeySpecifier
      | (() => undefined | MedicationRequestSubstitutionKeySpecifier)
    fields?: MedicationRequestSubstitutionFieldPolicy
  }
  MedicationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MedicationResponseKeySpecifier | (() => undefined | MedicationResponseKeySpecifier)
    fields?: MedicationResponseFieldPolicy
  }
  Meta?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MetaKeySpecifier | (() => undefined | MetaKeySpecifier)
    fields?: MetaFieldPolicy
  }
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier)
    fields?: MutationFieldPolicy
  }
  Narrative?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NarrativeKeySpecifier | (() => undefined | NarrativeKeySpecifier)
    fields?: NarrativeFieldPolicy
  }
  Observation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ObservationKeySpecifier | (() => undefined | ObservationKeySpecifier)
    fields?: ObservationFieldPolicy
  }
  ObservationComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ObservationComponentKeySpecifier | (() => undefined | ObservationComponentKeySpecifier)
    fields?: ObservationComponentFieldPolicy
  }
  ObservationReferenceRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ObservationReferenceRangeKeySpecifier
      | (() => undefined | ObservationReferenceRangeKeySpecifier)
    fields?: ObservationReferenceRangeFieldPolicy
  }
  Patient?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PatientKeySpecifier | (() => undefined | PatientKeySpecifier)
    fields?: PatientFieldPolicy
  }
  PatientCommunication?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PatientCommunicationKeySpecifier | (() => undefined | PatientCommunicationKeySpecifier)
    fields?: PatientCommunicationFieldPolicy
  }
  PatientContact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PatientContactKeySpecifier | (() => undefined | PatientContactKeySpecifier)
    fields?: PatientContactFieldPolicy
  }
  PatientLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PatientLinkKeySpecifier | (() => undefined | PatientLinkKeySpecifier)
    fields?: PatientLinkFieldPolicy
  }
  Period?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PeriodKeySpecifier | (() => undefined | PeriodKeySpecifier)
    fields?: PeriodFieldPolicy
  }
  Person?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PersonKeySpecifier | (() => undefined | PersonKeySpecifier)
    fields?: PersonFieldPolicy
  }
  PhotoUploadStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PhotoUploadStatusKeySpecifier | (() => undefined | PhotoUploadStatusKeySpecifier)
    fields?: PhotoUploadStatusFieldPolicy
  }
  Practitioner?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PractitionerKeySpecifier | (() => undefined | PractitionerKeySpecifier)
    fields?: PractitionerFieldPolicy
  }
  PractitionerImages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PractitionerImagesKeySpecifier | (() => undefined | PractitionerImagesKeySpecifier)
    fields?: PractitionerImagesFieldPolicy
  }
  PractitionerProfile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PractitionerProfileKeySpecifier | (() => undefined | PractitionerProfileKeySpecifier)
    fields?: PractitionerProfileFieldPolicy
  }
  Quantity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QuantityKeySpecifier | (() => undefined | QuantityKeySpecifier)
    fields?: QuantityFieldPolicy
  }
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier)
    fields?: QueryFieldPolicy
  }
  Range?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RangeKeySpecifier | (() => undefined | RangeKeySpecifier)
    fields?: RangeFieldPolicy
  }
  Ratio?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RatioKeySpecifier | (() => undefined | RatioKeySpecifier)
    fields?: RatioFieldPolicy
  }
  Reference?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReferenceKeySpecifier | (() => undefined | ReferenceKeySpecifier)
    fields?: ReferenceFieldPolicy
  }
  Signature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SignatureKeySpecifier | (() => undefined | SignatureKeySpecifier)
    fields?: SignatureFieldPolicy
  }
  StructuredSig?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StructuredSigKeySpecifier | (() => undefined | StructuredSigKeySpecifier)
    fields?: StructuredSigFieldPolicy
  }
  Study?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StudyKeySpecifier | (() => undefined | StudyKeySpecifier)
    fields?: StudyFieldPolicy
  }
  SurveyRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SurveyRequestKeySpecifier | (() => undefined | SurveyRequestKeySpecifier)
    fields?: SurveyRequestFieldPolicy
  }
  SurveySubmission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SurveySubmissionKeySpecifier | (() => undefined | SurveySubmissionKeySpecifier)
    fields?: SurveySubmissionFieldPolicy
  }
  SurveySubmissionEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SurveySubmissionEventKeySpecifier | (() => undefined | SurveySubmissionEventKeySpecifier)
    fields?: SurveySubmissionEventFieldPolicy
  }
  SurveyUrls?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SurveyUrlsKeySpecifier | (() => undefined | SurveyUrlsKeySpecifier)
    fields?: SurveyUrlsFieldPolicy
  }
  Task?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TaskKeySpecifier | (() => undefined | TaskKeySpecifier)
    fields?: TaskFieldPolicy
  }
  TaskComment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TaskCommentKeySpecifier | (() => undefined | TaskCommentKeySpecifier)
    fields?: TaskCommentFieldPolicy
  }
  TaskEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TaskEventKeySpecifier | (() => undefined | TaskEventKeySpecifier)
    fields?: TaskEventFieldPolicy
  }
  Timing?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimingKeySpecifier | (() => undefined | TimingKeySpecifier)
    fields?: TimingFieldPolicy
  }
  TimingRepeat?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimingRepeatKeySpecifier | (() => undefined | TimingRepeatKeySpecifier)
    fields?: TimingRepeatFieldPolicy
  }
  Visit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VisitKeySpecifier | (() => undefined | VisitKeySpecifier)
    fields?: VisitFieldPolicy
  }
  VisitAvailabilityDates?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VisitAvailabilityDatesKeySpecifier | (() => undefined | VisitAvailabilityDatesKeySpecifier)
    fields?: VisitAvailabilityDatesFieldPolicy
  }
  VisitAvailabilityTimes?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VisitAvailabilityTimesKeySpecifier | (() => undefined | VisitAvailabilityTimesKeySpecifier)
    fields?: VisitAvailabilityTimesFieldPolicy
  }
  VisitMeta?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VisitMetaKeySpecifier | (() => undefined | VisitMetaKeySpecifier)
    fields?: VisitMetaFieldPolicy
  }
  VisitNotes?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VisitNotesKeySpecifier | (() => undefined | VisitNotesKeySpecifier)
    fields?: VisitNotesFieldPolicy
  }
  Workflow?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WorkflowKeySpecifier | (() => undefined | WorkflowKeySpecifier)
    fields?: WorkflowFieldPolicy
  }
}
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies

import { produce } from 'immer'
import { Reducer } from 'redux'
import { Action, FETCH_SURVEY_SUBMISSIONS_SUCCEEDED, SURVEY_MARKED_AS_SEEN } from '../../../types/actions'
import { SurveySubmission } from '../../../types/survey'
import { FETCH_TASK_CARE_PATHWAY_FULFILLED } from '../../../types/task'

type ByIdState = Record<string, SurveySubmission>

const byId: Reducer<ByIdState, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSIONS_SUCCEEDED:
    case FETCH_TASK_CARE_PATHWAY_FULFILLED:
      action.payload.surveySubmissions.forEach((submission) => {
        draft[submission.id] = submission
      })
      break
    case SURVEY_MARKED_AS_SEEN: {
      const submission = draft[action.payload.id]

      if (!submission) {
        return
      }

      if (!submission.seenBy) {
        submission.seenBy = []
      }

      if (submission.seenBy.includes(action.payload.personId)) {
        return
      }

      submission.seenBy.push(action.payload.personId)
      break
    }
  }
}, {})

export const idBySubmissionId: Reducer<Record<string, string>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSIONS_SUCCEEDED:
    case FETCH_TASK_CARE_PATHWAY_FULFILLED:
      action.payload.surveySubmissions.forEach((submission) => {
        draft[String(submission.submissionId)] = submission.id
      })
      break
  }
}, {})

export default byId

import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
  title?: string
}

const DetailsSection = ({ children, title }: Props): ReactElement => {
  return (
    <section
      className={clsx('mx-7 pb-5 border-b border-b-rivaOffblack-500 last:border-b-0', {
        'pt-0': !title,
        'pt-5': !!title,
      })}
    >
      {title && (
        <header>
          <h3 className="text-xxs font-extrabold uppercase py-0">{title}</h3>
        </header>
      )}
      <main>{children}</main>
    </section>
  )
}

export default DetailsSection

import clsx from 'clsx'
import { ChangeEvent, DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react'
import { ReactComponent as CheckCircleIcon } from '../icons/solid/check-circle.svg'
import { ReactComponent as ExclamationIcon } from '../icons/solid/exclamation.svg'
import { ReactComponent as ExclamationCircleIcon } from '../icons/solid/exclamation-circle.svg'
import { IconSize, SvgIcon } from './icon'

interface Props extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onPaste'> {
  showIcon?: boolean
  variant?: 'invalid' | 'success' | 'warning'
}

const TextInput = forwardRef<HTMLInputElement, Props>(function TextInput(
  { className, type = 'text', onChange, showIcon, variant, ...props },
  ref,
) {
  return (
    <>
      <input
        {...props}
        className={clsx(
          'peer riva-input order-2 border disabled:border-rivaOffblack-300 disabled:text-rivaOffblack-500 disabled:bg-rivaOffblack-100 rounded py-3 pl-3 text-sm box-border h-10 outline-none w-full',
          {
            'bg-white border-rivaOffblack-300 hover:border-rivaOffblack focus:border-rivaPurple text-rivaOffblack-900 pr-3':
              variant === undefined,
            'bg-rivaGreen-50 border-rivaGreen-500 text-rivaGreen-800': variant === 'success',
            'bg-rivaYellow-50 border-rivaYellow-400 hover:border-rivaYellow-700 focus:border-rivaYellow-700 text-rivaYellow-700 pr-[44px]':
              variant === 'warning',
            'bg-rivaFuchsia-50 border-rivaFuchsia-500 hover:border-rivaFuchsia-800 !focus:border-rivaFuchsia-800 text-rivaFuchsia-800':
              variant === 'invalid',
            'pr-10': variant !== undefined,
          },
          className,
        )}
        type={type}
        onChange={onChange}
        onPaste={(event) => {
          const text = event.clipboardData.getData('text/plain')
          const hasNonDigits = /\d/.test(text)

          if (!hasNonDigits) {
            return
          }

          event.preventDefault()

          const result = text.replace(/[^0-9]/g, '')

          ;(event.target as HTMLInputElement).value = result

          if (onChange) {
            onChange(event as unknown as ChangeEvent<HTMLInputElement>)
          }
        }}
        ref={ref}
      />
      {showIcon && variant !== undefined ? (
        <div className="relative order-2 h-0">
          <SvgIcon
            Icon={
              variant === 'warning' ? ExclamationIcon : variant === 'success' ? CheckCircleIcon : ExclamationCircleIcon
            }
            className={clsx('absolute bottom-[10px] right-3', {
              'text-rivaYellow-700': variant === 'warning',
              'text-rivaFuchsia-500': variant === 'invalid',
              'text-rivaGreen-700': variant === 'success',
            })}
            size={IconSize.SMALL}
          />
        </div>
      ) : null}
    </>
  )
})

export default TextInput

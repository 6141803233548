import { ReactElement, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import {
  getAllergyIntoleranceCount,
  getAllergyIntoleranceRecordState,
} from '../../../../redux/selectors/allergy-intolerance'
import { fetchAllergyIntolerance } from '../../../../redux/thunks/chart'
import { RecordState } from '../../../../types/record-state'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as EyeIcon } from '../../../icons/solid/eye.svg'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import { ALLERGIES_COUNT } from '../../../../consts/data-testids'
import { TableTheme } from '../../../ui/table'
import AllergyTable from '../allergy-table'

interface Props {
  patientId: string
}

const AllergyHeaderContent = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const apolloClient = useApolloClient()
  const allergyCount = useAppSelector((state) => getAllergyIntoleranceCount(state, patientId))
  const allergyRecordState = useAppSelector((state) => getAllergyIntoleranceRecordState(state, patientId))

  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [12, 8],
    placement: 'bottom-end',
  })

  useEffect(() => {
    dispatch(fetchAllergyIntolerance(patientId, apolloClient))
  }, [apolloClient, dispatch, patientId])

  return (
    <span className="flex items-center" data-testid={ALLERGIES_COUNT}>
      {allergyRecordState === RecordState.LOADING ? (
        '-'
      ) : allergyRecordState === RecordState.ERRORED ? (
        '-error-'
      ) : (
        <>
          <span className="mr-1">
            {allergyCount === 0 ? 'No allergies' : allergyCount === 1 ? '1 allergy' : `${allergyCount} allergies`}
          </span>
          <SvgIcon
            {...getTriggerProps()}
            hidden={allergyCount === 0}
            Icon={EyeIcon}
            size={IconSize.X_SMALL}
            className={clsx('cursor-pointer', {
              hidden: allergyCount === 0,
            })}
          />
          <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()} className="rounded-lg z-30">
            <div className="my-3">
              <AllergyTable patientId={patientId} theme={TableTheme.DARK} />
            </div>
          </Tooltip>
        </>
      )}
    </span>
  )
}

export default AllergyHeaderContent

import { produce } from 'immer'
import { combineReducers } from 'redux'
import { BloodPressureSetMap, MemberIdToBloodPressureSetIdMap } from '../../types/blood-pressure-set'
import { FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED } from '../../types/blood-pressure-measurement'
import { Action } from '../../types/actions'

const bloodPressureSetMap = produce((draft: BloodPressureSetMap, action: Action) => {
  switch (action.type) {
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED: {
      const { measurements } = action.payload

      measurements.forEach(({ bloodPressureSet }) => {
        if (bloodPressureSet) {
          draft[bloodPressureSet.id] = bloodPressureSet
        }
      })

      return draft
    }
    default:
      return draft
  }
}, {})

const memberIdToBloodPressureSetIdMap = produce((draft: MemberIdToBloodPressureSetIdMap, action: Action) => {
  switch (action.type) {
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED:
      {
        const { measurements } = action.payload

        measurements.forEach(({ bloodPressureSet }) => {
          if (!bloodPressureSet) {
            return
          }

          const { patientId } = bloodPressureSet

          if (patientId) {
            if (!draft[patientId]) {
              draft[patientId] = []
            }

            if (!draft[patientId].includes(bloodPressureSet.id)) {
              draft[patientId].push(bloodPressureSet.id)
            }
          }
        })
      }
      break
    default:
      return draft
  }
}, {})

export const bloodPressureSet = combineReducers({
  bloodPressureSetMap,
  memberIdToBloodPressureSetIdMap,
})

import { ReactElement } from 'react'
import { ReactComponent as IconUserGroupSolid } from '../../icons/solid/user-group.svg'
import { ReactComponent as IconUserGroupOutline } from '../../icons/outline/user-group.svg'
import useFlag from '../../../hooks/use-flag'
import { Features } from '../../../types/features'
import NavigationItem from './navigation-item'

const PatientsNavigationItem = (): ReactElement | null => {
  const isPaginationEnabled = useFlag(Features.PATIENT_PAGES)

  if (!isPaginationEnabled) {
    return (
      <li>
        <NavigationItem
          tabIndex={0}
          to="/dashboard"
          label="Patients"
          iconLeft={({ isActive }) => (isActive ? <IconUserGroupSolid /> : <IconUserGroupOutline />)}
        />
      </li>
    )
  }

  return (
    <li>
      <NavigationItem
        tabIndex={0}
        iconLeft={({ isActive }) => (isActive ? <IconUserGroupSolid /> : <IconUserGroupOutline />)}
        label="Patients"
        to="/patients"
      />
    </li>
  )
}

export default PatientsNavigationItem

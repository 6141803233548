import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  label: string
  content: ReactNode
  contentClassName?: string
}

const HeaderBlock = ({ label, content, contentClassName = '' }: Props): ReactElement => {
  return (
    <div>
      <div className="text-xxs font-semibold text-rivaOffblack-500 capitalize">{label}</div>
      <div className={clsx('text-sm font-medium text-md tabular-nums', contentClassName)}>{content}</div>
    </div>
  )
}

export default HeaderBlock

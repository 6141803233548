import { createSelector } from 'reselect'
import { RootState } from '../reducers'
import { RecordState } from '../../types/record-state'
import { BloodPressureMeasurement, BloodPressureMeasurementType } from '../../types/blood-pressure-measurement'
import { getPatientPersonId } from './patient'

/**
 * @returns The record state for the blood pressure measurements
 */
export function getBloodPressureMeasurementRecordState(state: RootState, listId: string): RecordState {
  return state.bloodPressureMeasurement.recordStateByListId[listId] ?? RecordState.DOES_NOT_EXIST
}

/**
 * @param bloodPressureSetId The blood pressure set id we want all of the measurement ids for
 * @returns A list of measurement ids
 */
export function getBloodPressureMeasurementIdsForBloodPressureSetId(
  state: RootState,
  bloodPressureSetId: string,
  measurementType: BloodPressureMeasurementType,
): string[] {
  const measurements =
    state.bloodPressureMeasurement.bloodPressureSetIdToBloodPressureMeasurementIdMap[bloodPressureSetId]

  if (!measurements) {
    return []
  }

  switch (measurementType) {
    case BloodPressureMeasurementType.Cuff:
      return measurements.cuff
        .map((measurementId) => state.bloodPressureMeasurement.bloodPressureMeasurementMap[measurementId])
        .sort((a, b) => a.created.at.localeCompare(b.created.at))
        .map((measurement) => measurement.id)
    case BloodPressureMeasurementType.RivaApp:
      return measurements.phone
        .map((measurementId) => state.bloodPressureMeasurement.bloodPressureMeasurementMap[measurementId])
        .sort((a, b) => a.created.at.localeCompare(b.created.at))
        .map((measurement) => measurement.id)
    default:
      return []
  }
}

export const getBloodPressureMeasurements = (state: RootState): Record<string, BloodPressureMeasurement> =>
  state.bloodPressureMeasurement.bloodPressureMeasurementMap

export const getBloodPressureMeasurementsByPatient = createSelector(
  getBloodPressureMeasurements,
  getPatientPersonId,
  (measurementMap, personId) => {
    return Object.values(measurementMap).filter((measurement) => measurement.personId === personId)
  },
)

/**
 * @param measurementId The measurement id we want measurement of
 * @returns Measurement
 */
export function getBloodPressureMeasurement(state: RootState, measurementId: string): BloodPressureMeasurement | null {
  return state.bloodPressureMeasurement.bloodPressureMeasurementMap[measurementId]
}

export const getBloodPressureMeasurementCount = (state: RootState, personId: string): number =>
  Object.values(state.bloodPressureMeasurement.bloodPressureMeasurementMap).reduce((count, measurement) => {
    if (measurement.personId !== personId) {
      return count
    }

    return count + 1
  }, 0)

export function getCuffDownloadUrl(state: RootState, measurementId: string): string {
  if (!state.cuffDownloadUrl[measurementId]) {
    return ''
  }

  return state.cuffDownloadUrl[measurementId].signedUrl
}

export function getCuffDownloadUrlRecordState(state: RootState, measurementId: string): RecordState {
  if (!state.cuffDownloadUrl[measurementId]) {
    return RecordState.DOES_NOT_EXIST
  }

  return state.cuffDownloadUrl[measurementId].recordState
}

import { DateTime } from 'luxon'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import useTasksApi from '../../api/hooks/use-tasks-api'
import { useAppDispatch, useAppSelector } from '../../redux'
import { getTaskDueDate, getTaskStatus, getTaskSummary } from '../../redux/selectors/tasks'
import { changeTaskDueDate } from '../../redux/thunks/tasks'
import { isWeekday } from '../../utils/dates'
import DatePicker from '../ui/datepicker'
import { getStatusText } from '../../utils/tasks'
import { track } from '../../i13n'
import Confirm from './confirm'

interface Props {
  i13n?: Record<string, unknown>
  isOpen: boolean
  listId: string
  onClose: () => void
  taskId: string
}

const ChangeDueDateModal = ({ i13n, isOpen, onClose, taskId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const initialDueDate = useAppSelector((state) => getTaskDueDate(state, taskId))
  const taskStatus = useAppSelector((state) => getStatusText(getTaskStatus(state, taskId)))
  const taskTitle = useAppSelector((state) => getTaskSummary(state, taskId))
  const [dueDate, setDueDate] = useState(initialDueDate || (DateTime.now().toISODate() ?? ''))
  const [currentMonth, setCurrentMonth] = useState(
    initialDueDate ? DateTime.fromISO(initialDueDate).month : DateTime.now().month,
  )

  const onAccept = useCallback(() => {
    onClose()

    dispatch(changeTaskDueDate({ dueDate, i13n, id: taskId }, tasksApi))
  }, [dispatch, dueDate, i13n, onClose, taskId, tasksApi])
  const onCancel = useCallback(() => {
    onClose()

    track('Task Change Due Date Cancelled', {
      ...i13n,
      'Task ID': taskId,
      'Task Status': taskStatus,
      'Task Title': taskTitle,
    })
  }, [i13n, onClose, taskId, taskStatus, taskTitle])
  const onDateChange = useCallback<(date: Date) => void>((date) => {
    setDueDate(DateTime.fromJSDate(date).toISODate() ?? '')
  }, [])
  const onMonthChange = useCallback<(date: Date) => void>(
    (date) => setCurrentMonth(DateTime.fromJSDate(date).month),
    [],
  )

  const highlightWithRanges = useMemo(
    () =>
      initialDueDate
        ? [
            {
              'bg-rivaOffblack-200 rounded hover:bg-rivaOffblack-200': [DateTime.fromISO(initialDueDate).toJSDate()],
            },
          ]
        : [],
    [initialDueDate],
  )

  const filterDate = useCallback<(date: Date) => boolean>(
    (date) => isWeekday(date) && DateTime.fromJSDate(date).month === currentMonth,
    [currentMonth],
  )

  useEffect(() => {
    track('Task Change Due Date Modal Shown', {
      ...i13n,
      'Task ID': taskId,
      'Task Status': taskStatus,
      'Task Title': taskTitle,
    })
    // No need to fire this again if the status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Confirm
      confirmButtonText="Save due date"
      disabled={!dueDate || dueDate === initialDueDate}
      isOpen={isOpen}
      onAccept={onAccept}
      onCancel={onCancel}
      subtitle="Select a new date"
      title="Change due date"
    >
      <div className="pb-10 pt-2">
        <DatePicker
          filterDate={filterDate}
          highlightDates={highlightWithRanges}
          inline
          minDate={new Date()}
          onChange={onDateChange}
          onMonthChange={onMonthChange}
          selected={DateTime.fromISO(dueDate).toJSDate()}
        />
      </div>
    </Confirm>
  )
}

export default ChangeDueDateModal

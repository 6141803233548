import { gql } from '../__generated__/gql'

export default gql(`#graphql
query medicationsQuery($patientId: ID!) {
  patient(patientId: $patientId) {
    id
    medicationRequests {
      authoredOn
      dispenseRequest {
        initialFill {
          quantity {
            unit
            value
          }
        }
      }
      dosageInstruction {
        asNeededBoolean
        doseAndRate {
          doseQuantity {
            unit
            value
          }
        }
        method {
          coding {
            display
          }
        }
        route {
          coding {
            display
          }
        }
        text
        timing {
          repeat {
            duration
            durationUnit
            frequency
            periodUnit
            period
          }
        }
      }
      id
      medicationReference {
        display
      }
      meta {
        lastUpdated
      }
      status
    }
  }
}
`)

import { useCallback } from 'react'
import { useAppSelector } from '..'
import { Person, PersonMap } from '../../types/person'
import { RootState } from '../reducers'

/**
 * @param personId The id of the person we want to get
 * @returns the person
 */
export function getPerson(state: RootState, personId: string): Person | null {
  return state.person.personMap[personId] || null
}

/**
 * @param personId The id of the person whose first name we want to get
 * @returns the first name
 */
export function getFirstName(state: RootState, personId: string): string {
  const person = getPerson(state, personId)

  return person ? person.firstName : ''
}

/**
 * @param personId The id of the person whose last name we want to get
 * @returns the last name
 */
export function getLastName(state: RootState, personId: string): string {
  const person = getPerson(state, personId)

  return person ? person.lastName : ''
}

/**
 * @param personId The id of the person whose phone number we want to get
 * @returns the phone number
 */
export function getPhoneNumber(state: RootState, personId: string): string {
  const person = getPerson(state, personId)

  if (person) {
    return person.phoneNumber ? person.phoneNumber : ''
  } else {
    return ''
  }
}

export function getEmail(state: RootState, personId: string): string {
  const person = getPerson(state, personId)

  if (person) {
    return person.email ? person.email : ''
  } else {
    return ''
  }
}

export function getPronouns(state: RootState, personId: string): string {
  const person = getPerson(state, personId)

  if (person) {
    return person.pronouns ? person.pronouns : ''
  } else {
    return ''
  }
}

export const getPhotoUrl = (state: RootState, personId: string): string => getPerson(state, personId)?.photoUrl ?? ''

const getPersonMap = (state: RootState): PersonMap => state.person.personMap

export const useGetPersonFullName: () => (personId: string) => string = () => {
  const personMap = useAppSelector(getPersonMap)

  return useCallback(
    (personId: string) => {
      const person = personMap[personId]

      return person ? `${person.firstName} ${person.lastName}` : ''
    },
    [personMap],
  )
}

import React, {
  ReactNode,
  MouseEventHandler,
  ReactElement,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  ComponentProps,
  forwardRef,
  AnchorHTMLAttributes,
} from 'react'
import cn from 'clsx'
import { NavLink } from 'react-router-dom'

export enum ButtonVariant {
  ACTION = 'VARIANT_ACTION',
  ERROR = 'VARIANT_ERROR',
  DEFAULT = 'VARIANT_DEFAULT',
  PRIMARY = 'VARIANT_PRIMARY',
  PRIMARY_INVERTED = 'VARIANT_PRIMARY_INVERTED',
  SECONDARY = 'VARIANT_SECONDARY',
  TEXT = 'VARIANT_TEXT',
  UNDERSTATED = 'VARIANT_UNDERSTATED',
}

export enum ButtonSize {
  LARGE = 'SIZE_LARGE',
  MEDIUM = 'SIZE_MEDIUM',
  SMALL = 'SIZE_SMALL',
  XS = 'XS',
  XXS = 'XXS',
}

interface ButtonProps
  extends Pick<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'type' | 'title'> {
  children: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  className?: string
  variant?: ButtonVariant
  size?: ButtonSize
  'data-testid'?: string
}

interface MenuButtonProps extends ButtonProps {
  active?: boolean
}

export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(function MenuButton(props, ref) {
  const { active, variant = ButtonVariant.DEFAULT, size = ButtonSize.SMALL, className, children, ...remainder } = props
  const buttonClasses = 'inline-flex items-center box-border'
  const buttonVariantClasses = getButtonVariantClasses(variant, active)
  const buttonSizeClasses = getButtonSizeClasses(size, variant)

  return (
    <button
      aria-pressed={active}
      ref={ref}
      className={cn(buttonClasses, buttonVariantClasses, buttonSizeClasses, className, {
        active,
      })}
      {...remainder}
    >
      {children}
    </button>
  )
})

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const { children, className, variant = ButtonVariant.DEFAULT, size = ButtonSize.SMALL, ...remainder } = props
  const buttonClasses = 'inline-flex items-center box-border'
  const buttonVariantClasses = getButtonVariantClasses(variant)
  const buttonSizeClasses = getButtonSizeClasses(size, variant)

  return (
    <button className={cn(buttonClasses, buttonVariantClasses, buttonSizeClasses, className)} {...remainder} ref={ref}>
      {children}
    </button>
  )
})

interface ToggleButtonProps extends ButtonProps {
  pressed?: boolean
}

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(function ToggleButton(
  { pressed, ...props },
  ref,
) {
  const { children, className, variant = ButtonVariant.DEFAULT, size = ButtonSize.SMALL, ...remainder } = props
  const buttonClasses = 'inline-flex items-center box-border'
  const buttonVariantClasses = getButtonVariantClasses(variant, !!pressed)
  const buttonSizeClasses = getButtonSizeClasses(size, variant)

  return (
    <button className={cn(buttonClasses, buttonVariantClasses, buttonSizeClasses, className)} {...remainder} ref={ref}>
      {children}
    </button>
  )
})

function getButtonVariantClasses(variant: ButtonVariant, active?: boolean): string {
  switch (variant) {
    case ButtonVariant.ACTION:
    case ButtonVariant.DEFAULT:
    case ButtonVariant.PRIMARY:
      return (
        'transition-all duration-200 ease-in-out ' +
        'text-white font-semibold border-rivaPurple rounded border border-transparent ' +
        'group-[.btn-group]:rounded-none group-[.btn-group]:first:rounded-l group-[.btn-group]:last-of-type:rounded-r ' +
        (active ? 'bg-rivaPurple-800 shadow-xxs ' : 'bg-rivaPurple-500 hover:bg-rivaPurple-600 hover:shadow-xxs ') +
        'disabled:hover:shadow-none disabled:bg-rivaOffblack-200 disabled:text-rivaOffblack-400 active:bg-rivaPurple-800 active:shadow-xxs focus-visible:outline outline-4 outline-offset-0 outline-rivaPurple-300'
      )
    case ButtonVariant.PRIMARY_INVERTED:
      return (
        'transition-all duration-200 ease-in-out ' +
        'text-rivaOffblack-900 font-semibold rounded disabled:opacity-50 ' +
        (active ? 'bg-rivaOffblack-200 shadow-xxs ' : 'bg-white hover:bg-rivaOffblack-100 hover:shadow-xxs ') +
        'disabled:hover:shadow-none active:bg-rivaOffblack-200 active:shadow-xxs focus-visible:outline outline-4 outline-offset-0 outline-rivaPurple-300'
      )
    case ButtonVariant.ERROR:
      return 'bg-rivaRed text-white font-semibold border-rivaRed rounded disabled:opacity-50'
    case ButtonVariant.UNDERSTATED:
      return 'text-rivaPurple-500 font-semibold disabled:text-rivaOffblack-400'
    case ButtonVariant.SECONDARY:
      return (
        'transition-all duration-200 ease-in-out ' +
        'text-rivaOffblack-900 font-semibold border-rivaOffblack-900 border rounded disabled:opacity-50 ' +
        'group-[.btn-group]:rounded-none group-[.btn-group]:first:rounded-l group-[.btn-group]:last-of-type:rounded-r ' +
        'group-[.btn-group]:border-r-0 group-[.btn-group]:last-of-type:border-r ' +
        (active ? 'bg-rivaOffblack-200 shadow-xxs ' : 'bg-white hover:bg-rivaOffblack-100 hover:shadow-xxs ') +
        'disabled:hover:shadow-none active:bg-rivaOffblack-200 active:shadow-xxs focus-visible:outline outline-3 outline-offset-0 outline-rivaOffblack-900'
      )
    case ButtonVariant.TEXT:
      return (
        'transition-all duration-200 ease-in-out ' +
        'text-rivaPurple-500 font-semibold rounded disabled:opacity-50 ' +
        'hover:bg-rivaOffblack-100 active:bg-rivaOffblack-200 focus-visible:outline outline-offset-0 outline-rivaPurple-900'
      )
  }
}

function getButtonSizeClasses(size: ButtonSize, variant: ButtonVariant): string {
  switch (size) {
    case ButtonSize.LARGE:
      return 'w-3/6' + (variant !== ButtonVariant.UNDERSTATED ? ' px-5 py-3' : '')
    case ButtonSize.MEDIUM:
      return variant !== ButtonVariant.UNDERSTATED ? 'px-5 py-3' : ''
    case ButtonSize.SMALL:
      return 'text-sm leading-4' + (variant !== ButtonVariant.UNDERSTATED ? ' h-10 px-5 py-3' : '')
    case ButtonSize.XS:
      return 'text-xxs font-semibold leading-4' + (variant !== ButtonVariant.UNDERSTATED ? ' h-6 px-2 py-1' : '')
    case ButtonSize.XXS:
      return (
        'text-xxs overflow-hidden leading-4 font-semibold' +
        (variant !== ButtonVariant.UNDERSTATED ? ' h-6 px-2 py-1' : '')
      )
  }
}

interface ButtonLinkProps extends ComponentProps<typeof NavLink> {
  size?: ButtonSize
  variant?: ButtonVariant
}

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(function ButtonLink(
  { children, className, size = ButtonSize.SMALL, variant = ButtonVariant.ACTION, ...props },
  ref,
): ReactElement {
  const buttonClasses = 'inline-flex items-center box-border'
  const buttonVariantClasses = getButtonVariantClasses(variant)
  const buttonSizeClasses = getButtonSizeClasses(size, variant)

  return (
    <NavLink className={cn(buttonClasses, buttonVariantClasses, buttonSizeClasses, className)} {...props} ref={ref}>
      {children}
    </NavLink>
  )
})

interface ExternalLinkButtonProps
  extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  size?: ButtonSize
  variant?: ButtonVariant
}

export const ExternalLinkButton = forwardRef<HTMLAnchorElement, ExternalLinkButtonProps>(
  ({ children, className, size = ButtonSize.SMALL, variant = ButtonVariant.ACTION, ...props }, ref) => {
    const buttonClasses = 'inline-flex items-center box-border'
    const buttonVariantClasses = getButtonVariantClasses(variant)
    const buttonSizeClasses = getButtonSizeClasses(size, variant)

    return (
      <a className={cn(buttonClasses, buttonVariantClasses, buttonSizeClasses, className)} {...props} ref={ref}>
        {children}
      </a>
    )
  },
)

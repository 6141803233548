import { combineReducers, Action } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'

import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { ApiClient } from '../../api'
import allergyIntolerance from './allergy-intolerance'
import { app } from './app'
import { bloodPressureSet } from './blood-pressure-set'
import { bloodPressureAverage } from './blood-pressure-averages'
import { bloodPressureMeasurement } from './blood-pressure-measurement'
import { cuffDownloadUrl } from './cuff-download-url'
import { chat } from './chat'
import { error } from './error'
import lists from './lists'
import { note } from './note'
import { organization } from './organization'
import { patient } from './patient'
import { person } from './person'
import { site } from './site'
import { practitioner } from './practitioner'
import { success } from './success'
import surveys from './surveys'
import tasks from './tasks'
import { ui } from './ui'
import carePathway from './care-pathway'
import studies from './studies'

export const rootReducer = combineReducers({
  allergyIntolerance,
  app,
  bloodPressureSet,
  bloodPressureAverage,
  bloodPressureMeasurement,
  chat,
  cuffDownloadUrl,
  error,
  lists,
  note,
  organization,
  patient,
  person,
  site,
  success,
  practitioner,
  surveys,
  tasks,
  ui,
  carePathway,
  studies,
})

interface ExtraThunkArg {
  apolloClient: ApolloClient<NormalizedCacheObject>
  apiClient: ApiClient
}

export type RootState = ReturnType<typeof rootReducer>
export type AppThunk = ThunkAction<void, RootState, ExtraThunkArg, Action<string>>
export type ReduxDispatch = ThunkDispatch<RootState, ExtraThunkArg, Action>

import clsx from 'clsx'
import { ChangeEventHandler, ReactElement } from 'react'

export interface Props<T> {
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  name: string
  required?: boolean
  value: T
}

const Radio = <T extends string>({ checked, onChange, name, required, value }: Props<T>): ReactElement => {
  return (
    <span className="w-5 h-5 ml-2 flex-none relative inline-block">
      <input
        className="absolute top-0 left-0 opacity-0 cursor-pointer"
        onChange={onChange}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        required={required}
      />
      <span
        className={clsx('rounded-full w-5 h-5 box-border inline-block', {
          'border-8 border-rivaPurple-500': checked,
          'border-2 border-rivaOffblack-900 group-[.radio-group-invalid]:border-rivaFuchsia-500': !checked,
        })}
      ></span>
    </span>
  )
}

export default Radio

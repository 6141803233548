import { ReactElement, useEffect } from 'react'
import { useAppSelector } from '../../../../redux'
import { getListItemIds } from '../../../../redux/selectors/lists'
import { Table, TableBody, TableHeader } from '../../../ui/table'
import { track } from '../../../../i13n'
import PatientSearchResultItem from './patient-search-result-item'

interface Props {
  listId: string
}

const PatientSearchTable = ({ listId }: Props): ReactElement => {
  const patientIds = useAppSelector((state) => getListItemIds(state, listId))

  useEffect(() => {
    track('Search Results Overall Shown', {
      Results: patientIds.length,
    })
    // Do not remove listId, as this should send different events as it changes pages
  }, [patientIds.length, listId])

  return (
    <Table>
      <TableHeader
        className="sticky top-[121px] pb-3"
        headers={[
          { text: 'Name', className: 'flex-0 basis-72 truncate' },
          { text: 'Date of birth', className: 'flex-0 basis-36' },
          { text: 'MRN', className: 'flex-0 basis-36' },
          { text: 'Status', className: 'flex-0 basis-40' },
          { text: '', className: 'flex-1' },
        ]}
      />
      <TableBody>
        {patientIds.map((patientId) => (
          <PatientSearchResultItem key={patientId} listId={listId} patientId={patientId} />
        ))}
      </TableBody>
    </Table>
  )
}

export default PatientSearchTable

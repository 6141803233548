import { produce } from 'immer'
import { Reducer, combineReducers } from 'redux'
import { RecordState } from '../../types/record-state'
import {
  FetchingOrganizations,
  FETCHING_ORGANIZATIONS,
  FetchOrganizationsFailed,
  FetchOrganizationsFulfilled,
  FETCH_ORGANIZATIONS_FAILED,
  FETCH_ORGANIZATIONS_FULFILLED,
  OrganizationMap,
  OrganizationState,
} from '../../types/organization'

type OrganizationActions = FetchingOrganizations | FetchOrganizationsFulfilled | FetchOrganizationsFailed

function recordState(state: RecordState = RecordState.DOES_NOT_EXIST, action: OrganizationActions) {
  switch (action.type) {
    case FETCHING_ORGANIZATIONS:
      return RecordState.LOADING
    case FETCH_ORGANIZATIONS_FULFILLED:
      return RecordState.LOADED
    case FETCH_ORGANIZATIONS_FAILED:
      return RecordState.ERRORED
    default:
      return state
  }
}

const organizationMap = produce((draft: OrganizationMap, action: OrganizationActions) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_FULFILLED: {
      const { organizations } = action.payload

      organizations.forEach((organization) => {
        draft[organization.id] = organization
      })

      return
    }
  }
}, {})

export const organization: Reducer<OrganizationState, OrganizationActions> = combineReducers({
  recordState,
  organizationMap,
})

import { useState } from 'react'

type UseToggleHook = [value: boolean, toggle: () => void]

const useToggle = (initialValue = false): UseToggleHook => {
  const [value, setValue] = useState(initialValue)

  return [value, () => setValue(!value)]
}

export default useToggle

import { ReactElement, ReactNode, useEffect } from 'react'
import clsx from 'clsx'
import deepEquals from 'lodash.isequal'
import { useApolloClient } from '@apollo/client'
import uniqBy from 'lodash.uniqby'
import { useInView } from 'react-intersection-observer'
import uniq from 'lodash.uniq'
import {
  BLOOD_PRESSURE_LOG,
  BLOOD_PRESSURE_SETS_EMPTY_STATE,
  BLOOD_PRESSURE_SETS_ERROR_STATE,
  BLOOD_PRESSURE_SET_CARD,
  BLOOD_PRESSURE_SET_COMMENT,
} from '../../../../consts/data-testids'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import {
  getBloodPressureMeasurementCount,
  getBloodPressureMeasurementRecordState,
} from '../../../../redux/selectors/blood-pressure-measurement'
import {
  getBloodPressureMeasurementGroupsByDate,
  MeasurementGroup,
} from '../../../../redux/selectors/blood-pressure-set'
import { getPatientPersonId } from '../../../../redux/selectors/patient'
import { isErrored, isLoadingOrDoesNotExist } from '../../../../utils/record-state'
import { EmptyState } from '../../../ui/empty-state'
import { ErrorState } from '../../../ui/error-state'
import { LoadingIndicator, LoadingIndicatorSize } from '../../../ui/loading-indicator'
import { fetchBloodPressureMeasurementsForPatient } from '../../../../redux/thunks/blood-pressure-measurements'
import { buildBloodPressureMeasurementListId } from '../../../../utils/lists'
import { Table, TableHeader, TableRow } from '../../../ui/table'
import FormattedDateTime from '../../../ui/formatted-date-time'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as ChatIcon } from '../../../icons/solid/chat.svg'
import { getEscalationIdByMeasurementId } from '../../../../redux/selectors/care-pathway'
import { BloodPressureEscalationCategoryType } from '../../../../types/blood-pressure-measurement'
import { track } from '../../../../i13n'
import PatientProfileSection from '../patient-profile-section'
import BloodPressureMeasurement from './blood-pressure-measurement'
import BloodPressureMeasurementEscalation from './blood-pressure-measurement-escalation'

interface Props {
  patientId: string
}

const BloodPressureLog = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const apolloClient = useApolloClient()
  const personId = useAppSelector((state) => getPatientPersonId(state, patientId))
  const listId = buildBloodPressureMeasurementListId(patientId)
  const measurementRecordState = useAppSelector((state) => getBloodPressureMeasurementRecordState(state, listId))
  const measurementCount = useAppSelector((state) => getBloodPressureMeasurementCount(state, personId))

  useEffect(() => {
    dispatch(fetchBloodPressureMeasurementsForPatient(patientId, listId, apolloClient))
  }, [apolloClient, dispatch, listId, patientId])

  return (
    <PatientProfileSection data-testid={BLOOD_PRESSURE_LOG}>
      <header className="relative flex px-4 pt-4 pb-7 items-center">
        <h2 className="font-semibold text-lg flex-1 m-0">Blood pressure log</h2>
      </header>
      {measurementCount === 0 && isLoadingOrDoesNotExist(measurementRecordState) ? (
        <div className="h-52">
          <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
        </div>
      ) : isErrored(measurementRecordState) ? (
        <ErrorState
          borderless
          data-testid={BLOOD_PRESSURE_SETS_ERROR_STATE}
          header="Sorry, we could not fetch assessments"
          subtext="Please refresh and try again. If the problem persists, contact support."
        />
      ) : measurementCount === 0 ? (
        <EmptyState
          borderless
          data-testid={BLOOD_PRESSURE_SETS_EMPTY_STATE}
          header="No completed assessments"
          subtext="If you think the patient should have completed one by now, please check in with them."
        />
      ) : (
        <BloodPressureLogTable patientId={patientId} />
      )}
    </PatientProfileSection>
  )
}

interface BloodPressureLogTableProps {
  patientId: string
}

const BloodPressureLogTable = ({ patientId }: BloodPressureLogTableProps) => {
  const measurementDays = useAppSelector((state) => getBloodPressureMeasurementGroupsByDate(state, patientId))

  return (
    <Table>
      <TableHeader
        headers={[
          { text: 'Date', className: 'w-[84px] mr-2' },
          { text: 'Time', className: 'w-[156px] mr-2' },
          { text: 'BP', className: 'w-[96px] mr-2' },
          { text: 'HR' },
        ]}
      />
      {measurementDays.map((measurementDay) => (
        <MeasurementDay key={measurementDay.date} {...measurementDay} />
      ))}
    </Table>
  )
}

interface CellProps {
  align?: 'start' | 'end'
  children: ReactNode
  flex?: boolean
}

const Cell = ({ align = 'start', flex, children }: CellProps) => {
  return (
    <div
      className={clsx('font-medium', {
        'text-end': align === 'end',
        'flex-1': flex,
      })}
      role="cell"
    >
      {children}
    </div>
  )
}

interface IncompleteTagProps {
  border?: boolean
  date?: string
}

const rowGridClassName = 'grid gap-x-5 grid-cols-[84px_156px_84px_1fr]'

const IncompleteRow = ({ border, date }: IncompleteTagProps): ReactElement => {
  return (
    <TableRow
      className={clsx(rowGridClassName, {
        'border-b-0': !border,
      })}
      data-testid={BLOOD_PRESSURE_SET_CARD}
    >
      <Cell>
        {date ? (
          <FormattedDateTime className="text-sm" value={date} day="2-digit" month="2-digit" year="2-digit" />
        ) : null}
      </Cell>
      <Cell align="end">
        <span className="bg-rivaFuchsia-50 text-rivaFuchsia-700 text-sm font-semibold h-6 inline-block flex-1">
          Incomplete
        </span>
      </Cell>
      <Cell>-</Cell>
      <Cell>-</Cell>
    </TableRow>
  )
}

interface MeasurementDayProps {
  date: string
  amGroups: MeasurementGroup[]
  pmGroups: MeasurementGroup[]
}

const MeasurementDay = ({ date, amGroups, pmGroups }: MeasurementDayProps): ReactElement => {
  return (
    <>
      {amGroups.length === 0 ? (
        <IncompleteRow key={date + 'am'} date={date} />
      ) : (
        amGroups.map((group, i) => (
          <MeasurementGroupRow key={group.id} date={i === 0 ? date : undefined} group={group} />
        ))
      )}
      {pmGroups.length === 0 ? (
        <IncompleteRow key={date + 'pm'} border />
      ) : (
        pmGroups.map((group, i) => (
          <MeasurementGroupRow key={group.id} border={i === pmGroups.length - 1} group={group} />
        ))
      )}
    </>
  )
}

interface MeasurementGroupRowProps {
  border?: boolean
  date?: string
  group: MeasurementGroup
}

const MeasurementGroupRow = ({ border, date, group }: MeasurementGroupRowProps) => {
  const escalationIds = useAppSelector(
    (state) => uniq(group.measurements.map(({ id }) => getEscalationIdByMeasurementId(state, id))),
    deepEquals,
  )
  const shouldDisplayEscalation = group.measurements.some((measurement) => {
    const { type = BloodPressureEscalationCategoryType.NORMAL } = measurement.bloodPressureEscalationCategory ?? {}

    return type !== BloodPressureEscalationCategoryType.NORMAL
  })
  // Old measurement sets copied the same comment to all the measurements in the set
  // Deduping to keep the comment list sane
  const withComments = uniqBy(group.measurements, 'comment')

  return (
    <TableRow
      className={clsx(rowGridClassName, 'gap-y-2', {
        'border-b-0': !border,
      })}
      data-testid={BLOOD_PRESSURE_SET_CARD}
    >
      <Cell>
        {date ? (
          <FormattedDateTime className="text-sm" value={date} day="2-digit" month="2-digit" year="2-digit" />
        ) : null}
      </Cell>
      <Cell align="end">
        <FormattedDateTime
          className="text-sm font-semibold"
          value={group.dateTime}
          hour12
          hour="numeric"
          minute="numeric"
          timeZone={group.timeZone}
          timeZoneName="short"
        />{' '}
      </Cell>
      <Cell>
        {group.measurements.map(({ id }) => (
          <BloodPressureMeasurement key={id} id={id} />
        ))}
      </Cell>
      <Cell>
        {group.measurements.map((measurement) => (
          <div key={measurement.id} className="text-sm text-rivaOffblack-700 mb-1 last:mb-0">
            {Math.round(measurement.beatsPerMinute)}
          </div>
        ))}
      </Cell>
      {withComments.map(({ comment, id }) => {
        if (!comment) {
          return null
        }

        return <Comment key={comment} comment={comment} measurementId={id} />
      })}
      {shouldDisplayEscalation
        ? escalationIds.map((escalationId) =>
            escalationId ? <BloodPressureMeasurementEscalation key={escalationId} escalationId={escalationId} /> : null,
          )
        : null}
    </TableRow>
  )
}

interface CommentProps {
  comment: string
  measurementId: string
}

const Comment = ({ comment, measurementId }: CommentProps): ReactElement => {
  const { inView, ref } = useInView()

  useEffect(() => {
    if (!inView) {
      return
    }

    track('BP Comment Viewed', {
      'BP Measurement ID': measurementId,
    })
  }, [inView, measurementId])

  return (
    <div
      className="flex bg-rivaOffblack-100 text-sm text-rivaOffblack-900 col-start-3 col-span-2 px-3 py-2"
      data-testid={BLOOD_PRESSURE_SET_COMMENT}
      ref={ref}
    >
      <div className="flex-none w-5 mr-2">
        <SvgIcon Icon={ChatIcon} size={IconSize.SMALL} />
      </div>
      <p className="flex-1">{comment}</p>
    </div>
  )
}

export default BloodPressureLog

import {
  Organization,
  FetchingOrganizations,
  FetchOrganizationsFulfilled,
  FetchOrganizationsFailed,
  FETCHING_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_FULFILLED,
  FETCH_ORGANIZATIONS_FAILED,
} from '../../types/organization'

export function fetchingOrganizations(): FetchingOrganizations {
  return {
    type: FETCHING_ORGANIZATIONS,
    payload: undefined,
  }
}

export function fetchOrganizationsFulfilled(organizations: Organization[]): FetchOrganizationsFulfilled {
  return {
    type: FETCH_ORGANIZATIONS_FULFILLED,
    payload: {
      organizations,
    },
  }
}

export function fetchOrganizationsFailed(error: Error): FetchOrganizationsFailed {
  return {
    type: FETCH_ORGANIZATIONS_FAILED,
    payload: {
      error,
    },
  }
}

import { ReactElement, useEffect, useMemo } from 'react'
import { TaskQueryParam } from '../../../types/task'
import { parseListId } from '../../../utils/lists'
import { track } from '../../../i13n'
import TaskLoadingItem from './task-loading-item'

interface Props {
  listId: string
}

const TaskListLoadingState = ({ listId }: Props): ReactElement => {
  const taskQuery = useMemo(() => parseListId(listId).query, [listId])

  useEffect(() => {
    track('Task Loading Shown')
  }, [])

  return (
    <>
      {taskQuery === TaskQueryParam.OPEN ? (
        <div className="bg-white p-3">
          <div className="h-9 bg-rivaOffblack-200 rounded animate-pulse"></div>
        </div>
      ) : null}
      <TaskLoadingItem />
      <TaskLoadingItem />
      <TaskLoadingItem />
      <TaskLoadingItem />
      <TaskLoadingItem />
    </>
  )
}

export default TaskListLoadingState

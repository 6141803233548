import { MessageType, UserMessage } from '@sendbird/chat/message'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import type { RenderChannelPreviewProps } from 'SendbirdUIKitGlobal'

const linkHoverStyles =
  'group-hover:bg-rivaOffblack-50 group-hover:outline group-hover:outline-1 group-hover:outline-rivaOffblack-200'
const linkActiveStyles = 'bg-rivaOffblack-100 group-hover:bg-rivaOffblack-100'

const ChannelPreview = ({ channel }: RenderChannelPreviewProps): ReactElement => {
  const lastMessageTime =
    channel.lastMessage?.messageType === 'user'
      ? DateTime.fromMillis(channel.lastMessage.createdAt)
      : DateTime.fromISO('')
  const channelName =
    channel.name.toLowerCase().trim() === 'group channel'
      ? channel.members.map(({ nickname }) => nickname).join(', ')
      : channel.name

  return (
    <div className="group border-b border-b-rivaOffblack-100/100">
      <NavLink
        to={`/chat/${channel.url}`}
        className={clsx('grid gap-1 grid-cols-[8px,1fr,70px] p-3', linkHoverStyles)}
        activeClassName={linkActiveStyles}
      >
        <div className="row-span-2 pt-[9px]">
          {channel.unreadMessageCount > 0 ? (
            <span className="rounded-full block w-2 h-2 bg-rivaPurple-600"></span>
          ) : null}
        </div>
        <div
          className={clsx('text-sm truncate', {
            'font-bold': channel.unreadMessageCount > 0,
          })}
        >
          {channelName}
        </div>
        {channel.lastMessage?.messageType === 'user' && (
          <>
            <div className="text-xs text-right">
              {!lastMessageTime.isValid
                ? ''
                : lastMessageTime.diffNow('days').days > -1
                ? lastMessageTime.toRelative({
                    style: 'short',
                  })
                : lastMessageTime.toLocaleString({
                    day: 'numeric',
                    month: 'numeric',
                    year: '2-digit',
                  })}
            </div>
            <div className="text-xs col-span-2 text-rivaOffblack-600 h-8 line-clamp-2">
              {channel.lastMessage.messageType === MessageType.USER ? (channel.lastMessage as UserMessage).message : ''}
            </div>
          </>
        )}
      </NavLink>
    </div>
  )
}

export default ChannelPreview

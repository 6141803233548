import { configureStore, Reducer, Store, Action } from '@reduxjs/toolkit'
import { ApiClient } from '../api'
import { loggerMiddleware } from './logger-middleware'

export function createStore<S, A extends Action>(
  reducer: Reducer<S, A>,
  initialState: S = {} as S,
  apiClient = new ApiClient(),
): { store: Store<S, A>; apiClient: ApiClient } {
  const store = configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { apiClient },
        },
      }).concat(loggerMiddleware.middleware),
    devTools: process.env.NODE_ENV === 'development',
    preloadedState: initialState,
    reducer,
  })

  return { store, apiClient }
}

import { CarePathwayStatus, CarePathwayType } from '../__generated__/graphql'
import { BloodPressureMeasurementType } from './blood-pressure-measurement'
import { AcquisitionChannel, BloodPressureManagementStatus, PatientType } from './patient'
import { RecordState } from './record-state'
import { TaskStatus, TaskType } from './task'

export enum ListContentType {
  BLOOD_PRESSURE_MEASUREMENT = 'BLOOD_PRESSURE_MEASUREMENT',
  BLOOD_PRESSURE_SET = 'BLOOD_PRESSURE_SET',
  CARE_PATHWAY = 'CARE_PATHWAY',
  NONE = 'NONE',
  PATIENT = 'PATIENT',
  STUDY = 'STUDY',
  STUDY_ENROLLMENT = 'STUDY_ENROLLMENT',
  SURVEY_SUBMISSION = 'SURVEY_SUBMISSION',
  TASK = 'TASK',
}

export interface List {
  hash: string
  isStale?: boolean
  itemIds: string[]
  recordState: RecordState
  selectedIndex?: number
  totalCount?: number
}

export interface ListItem {
  id: string
  listId: string
}

export interface TypedListItem extends ListItem {
  type: string
}

export type SortOrder = 'ASC' | 'DESC'

export interface ListDescriptor {
  acquisitionChannel?: AcquisitionChannel[]
  carePathwayStatus?: CarePathwayStatus[]
  carePathwayType?: CarePathwayType[]
  listContentType: ListContentType
  limit?: number
  managementStatus?: BloodPressureManagementStatus
  measurementType?: BloodPressureMeasurementType
  offset?: number
  patientId?: string[]
  patientType?: PatientType[]
  personId?: string[]
  practitionerId?: string[]
  query?: string
  sortOrder?: SortOrder
  sortPriority?: boolean
  taskStatus?: TaskStatus[]
  taskType?: TaskType[]
}

import { DetailedHTMLProps, HTMLAttributes, forwardRef } from 'react'
import { MedicalRecordSource } from '../../types/patient'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  medicalRecordNumber: string
  medicalRecordSource: MedicalRecordSource
}

const EHRPatientLink = forwardRef<HTMLAnchorElement, Props>(
  ({ children, medicalRecordNumber, medicalRecordSource, ...props }, ref) => {
    const athenaEnvironment = process.env.REACT_APP_ENV_NAME === 'prod' ? 'athenanet' : 'preview'
    const url =
      medicalRecordSource === MedicalRecordSource.ATHENA
        ? `https://${athenaEnvironment}.athenahealth.com/${process.env.REACT_APP_ATHENA_PRACTICEID}/1/globalframeset.esp?&MAIN=client%2Fclientsummary.esp%3FPATIENTID%3D${medicalRecordNumber}`
        : '#'

    return (
      <a {...props} href={url} rel="noreferrer" target={url !== '#' ? '_blank' : undefined} ref={ref}>
        {children}
      </a>
    )
  },
)

export default EHRPatientLink

import { combineReducers, AnyAction, Reducer, CombinedState } from 'redux'
import { FormAction, FormSubmissionState, FormActions } from '../../../types/form'

export default function formReducerFactory(actions: FormActions): Reducer<
  CombinedState<{
    submissionState: FormSubmissionState
    data: unknown
    error: never
  }>,
  AnyAction
> {
  function submissionState(state: FormSubmissionState = FormSubmissionState.NONE, action: AnyAction) {
    if (actions[FormAction.INITIALIZED].includes(action.type)) {
      return FormSubmissionState.NONE
    }

    if (actions[FormAction.SUBMITTING].includes(action.type)) {
      return FormSubmissionState.SUBMITTING
    }

    if (actions[FormAction.SUBMITTED].includes(action.type)) {
      return FormSubmissionState.SUBMITTED
    }

    if (actions[FormAction.ERRORED].includes(action.type)) {
      return FormSubmissionState.ERRORED
    }

    return state
  }

  function data(state: Record<string, unknown> = {}, action: AnyAction) {
    if (actions[FormAction.INITIALIZED].includes(action.type)) {
      return action.payload?.data || {}
    }

    if (actions[FormAction.SUBMITTED].includes(action.type)) {
      return {}
    }

    return state
  }

  function error(state: Error | null = null, action: AnyAction) {
    if (actions[FormAction.SUBMITTED].includes(action.type) || actions[FormAction.SUBMITTING].includes(action.type)) {
      return null
    }

    if (actions[FormAction.ERRORED].includes(action.type)) {
      return {
        message: action.payload.error.message,
      }
    }

    return state
  }

  const form = combineReducers({
    submissionState,
    data,
    error,
  })

  return form
}

import { produce } from 'immer'
import difference from 'lodash.difference'
import { Reducer } from 'redux'
import {
  Action,
  FETCH_SURVEY_SUBMISSIONS_FAILED,
  FETCH_SURVEY_SUBMISSIONS_REQUESTED,
  FETCH_SURVEY_SUBMISSIONS_SUCCEEDED,
  SURVEY_MARKED_AS_UNSEEN,
  SURVEY_MARKED_AS_SEEN,
  FETCH_TASKS_SUCCEEDED,
  FETCH_TASKS_REQUESTED,
  CURSOR_MOVED,
  CURSOR_MOVED_TO_THE_TOP,
  CURSOR_RESET,
  TASK_STATUS_UPDATED,
  CURSOR_SET_BY_ID,
  FETCH_TASKS_FAILED,
  PREFETCH_TASKS_SUCCEEDED,
  LIST_RESET,
  TASK_OPENED,
  TASK_ASSIGNED,
  TASK_EVENT_UNDO_SUCCEEDED,
} from '../../types/actions'
import { List } from '../../types/lists'
import {
  CREATE_PATIENT_FULFILLED,
  FETCHING_PATIENTS,
  FETCH_PATIENTS_FAILED,
  FETCH_PATIENTS_FULFILLED,
} from '../../types/patient'
import { RecordState } from '../../types/record-state'
import { SURVEY_QUERY_UNSEEN } from '../../types/survey'
import { buildListId, buildUnpagedListId, parseListId } from '../../utils/lists'
import { getTaskStatusFromQueryParam } from '../../utils/tasks'
import {
  FETCHING_BLOOD_PRESSURE_MEASUREMENTS,
  FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED,
  FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED,
} from '../../types/blood-pressure-measurement'
import {
  CARE_PATHWAY_CANCEL_FULFILLED,
  CARE_PATHWAY_START_FULFILLED,
  FETCH_CARE_PATHWAYS_FAILED,
  FETCH_CARE_PATHWAYS_FULFILLED,
  FETCH_CARE_PATHWAYS_REQUESTED,
} from '../../types/care-pathway'
import { TASK_CHOICE_SELECT_REQUESTED } from '../../types/task'

type ByIdState = Record<string, List>

const buildListRecord = (): List => ({
  itemIds: [],
  hash: '',
  recordState: RecordState.DOES_NOT_EXIST,
})

const byId: Reducer<ByIdState, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case CURSOR_MOVED:
      {
        const { direction, listId } = action.payload
        const selectedIndex = draft[listId].selectedIndex
        const totalCount = draft[listId].totalCount ?? 1

        if (selectedIndex === undefined) {
          draft[listId].selectedIndex = 0
        } else {
          draft[listId].selectedIndex =
            direction === 'down' ? Math.min(totalCount - 1, selectedIndex + 1) : Math.max(0, selectedIndex - 1)
        }
      }
      break
    case CURSOR_MOVED_TO_THE_TOP:
      if (draft[action.payload.listId]?.totalCount) {
        draft[action.payload.listId].selectedIndex = 0
      }
      break
    case CURSOR_RESET:
      if (draft[action.payload.listId]) {
        draft[action.payload.listId].selectedIndex = undefined
      }
      break
    case TASK_OPENED:
    case CURSOR_SET_BY_ID: {
      const { id, listId } = action.payload

      if (!draft[listId]) {
        return
      }

      draft[listId].selectedIndex = draft[listId].itemIds.indexOf(id)
      break
    }
    case FETCHING_PATIENTS:
    case FETCH_SURVEY_SUBMISSIONS_REQUESTED:
    case FETCH_TASKS_REQUESTED:
    case FETCHING_BLOOD_PRESSURE_MEASUREMENTS:
    case FETCH_CARE_PATHWAYS_REQUESTED:
      {
        const { listId } = action.payload

        if (!draft[listId]) {
          draft[listId] = buildListRecord()
        }

        draft[listId].recordState = RecordState.LOADING
      }
      break
    case FETCH_SURVEY_SUBMISSIONS_SUCCEEDED:
      {
        const { listId, surveySubmissions, totalCount } = action.payload

        draft[listId].recordState = RecordState.LOADED
        draft[listId].itemIds = surveySubmissions.map(({ id }) => id)
        draft[listId].totalCount = totalCount
      }
      break
    case FETCH_SURVEY_SUBMISSIONS_FAILED:
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED:
    case FETCH_PATIENTS_FAILED:
    case FETCH_TASKS_FAILED:
    case FETCH_CARE_PATHWAYS_FAILED:
      draft[action.payload.listId].recordState = RecordState.ERRORED
      break
    case SURVEY_MARKED_AS_SEEN:
    case TASK_CHOICE_SELECT_REQUESTED:
      {
        const { listId, id } = action.payload
        draft[listId].itemIds = draft[listId].itemIds.filter((itemId) => itemId !== id)
        draft[listId].totalCount = (draft[listId].totalCount ?? 1) - 1
      }
      break
    case SURVEY_MARKED_AS_UNSEEN:
      {
        const { listId, id } = action.payload
        draft[listId].itemIds = draft[listId].itemIds.filter((itemId) => itemId !== id)

        const unseenListId = buildListId({
          ...parseListId(listId),
          query: SURVEY_QUERY_UNSEEN,
        })
        draft[unseenListId].totalCount = (draft[unseenListId].totalCount ?? 0) + 1
      }
      break
    case FETCH_TASKS_SUCCEEDED:
      {
        const { hash, listId, startIndex, tasks, totalCount } = action.payload

        draft[listId].recordState = RecordState.LOADED
        draft[listId].totalCount = totalCount
        draft[listId].hash = hash

        tasks.forEach((task, i) => {
          draft[listId].itemIds[i + startIndex] = task.id
        })
      }
      break
    case PREFETCH_TASKS_SUCCEEDED:
      {
        const { hash, listId, tasks, totalCount } = action.payload

        if (!draft[listId]) {
          draft[listId] = buildListRecord()
        }

        draft[listId].totalCount = totalCount
        draft[listId].hash = hash
        // The backend simply hashes the whole body of the response and compares
        // to the previous hash. That means that if a task changed, but no tasks
        // were added or removed, then it will return 200 instead of 307.
        // In that case we don't want to mark the whole list stale, just update
        // the tasks in the background
        draft[listId].isStale =
          (draft[listId].recordState === RecordState.LOADED || draft[listId].recordState === RecordState.ERRORED) &&
          difference(
            tasks.map(({ id }) => id),
            draft[listId].itemIds,
          ).length > 0
      }
      break
    case TASK_EVENT_UNDO_SUCCEEDED:
    case LIST_RESET:
      {
        const { listId } = action.payload

        draft[listId] = buildListRecord()
      }
      break
    case TASK_STATUS_UPDATED:
      {
        const { id, listId, status } = action.payload
        const { query, taskStatus } = parseListId(listId)
        const listStatus = taskStatus ?? getTaskStatusFromQueryParam(query ?? '')

        if (listStatus.includes(status)) {
          break
        }

        draft[listId].itemIds = draft[listId].itemIds.filter((itemId) => itemId !== id)
        draft[listId].totalCount = (draft[listId].totalCount ?? 1) - 1
      }
      break
    case TASK_ASSIGNED:
      {
        const { id, listId, assignee } = action.payload
        const { practitionerId } = parseListId(listId)

        if (practitionerId && !practitionerId.includes(assignee)) {
          draft[listId].itemIds = draft[listId].itemIds.filter((currentId) => currentId !== id)
          draft[listId].totalCount = (draft[listId].totalCount ?? 1) - 1
        }
      }
      break
    case FETCH_PATIENTS_FULFILLED:
      {
        const { listId, patients, totalCount } = action.payload
        const unpagedListId = buildUnpagedListId(listId)

        draft[listId].itemIds = patients.map(({ id }) => id)
        draft[listId].recordState = RecordState.LOADED
        draft[listId].isStale = false
        draft[listId].totalCount = totalCount

        if (!draft[unpagedListId]) {
          draft[unpagedListId] = buildListRecord()
        }

        draft[unpagedListId].totalCount = totalCount
        draft[unpagedListId].recordState = RecordState.LOADED
      }
      break
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED:
      {
        const { listId } = action.payload

        draft[listId].recordState = RecordState.LOADED
      }
      break
    case FETCH_CARE_PATHWAYS_FULFILLED:
      {
        const { listId, carePathways } = action.payload

        draft[listId].recordState = RecordState.LOADED
        draft[listId].itemIds = carePathways.map(({ id }) => id)
        draft[listId].isStale = false
      }
      break
    case CREATE_PATIENT_FULFILLED:
    case CARE_PATHWAY_START_FULFILLED:
    case CARE_PATHWAY_CANCEL_FULFILLED:
      {
        const { listId } = action.payload

        if (!draft[listId]) {
          draft[listId] = buildListRecord()
        }

        draft[listId].isStale = true
      }
      break
    default:
      return draft
  }
}, {})

export default byId

import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const ModalFooter = ({ children }: Props): ReactElement => {
  return (
    <footer className="flex-none flex justify-end border-t border-rivaOffblack-200 px-10 py-7 absolute bottom-0 left-0 right-0 bg-white bg-opacity-80 backdrop-blur-[3px] z-50">
      {children}
    </footer>
  )
}

export default ModalFooter

import { gql } from '../__generated__/gql'

export default gql(`#graphql
query carePathwaysQuery($patientId: ID! $carePathwayTypes: [CarePathwayType!] $carePathwayStatus: [CarePathwayStatus!]) {
  carePathways(patientId: $patientId carePathwayTypes: $carePathwayTypes carePathwayStatus: $carePathwayStatus) {
    created {
      at
      by
    }
    events {
      carePathwayId
      created {
        at
        by
      }
      data
      id
      eventType
      note
    }
    id
    patientId
    status
    tasks {
      assigneeId
      description
      id
      patientId
      summary
      status
      taskType
    }
    type
  }
}`)

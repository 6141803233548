export const getEscalationTypeText = (escalationType: string): string => {
  switch (escalationType) {
    case 'LOW':
      return 'Low'
    case 'TRENDING_LOW':
      return 'Trending low'
    case 'NORMAL':
      return 'Normal'
    case 'HIGH':
      return 'High'
    case 'TRENDING_HIGH':
      return 'Trending high'
    case 'CRITICALLY_HIGH':
      return 'Critically high'
    default:
      return ''
  }
}

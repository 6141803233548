import React, { ReactElement } from 'react'
import FieldSet from './field-set'
import FormLabel from './form-label'
import { Option, Select } from './select'

interface DeviceTypesProps {
  deviceType: string
  invalid?: boolean
  setDeviceType: (value: string) => void
}
export function DeviceTypes({ deviceType, invalid, setDeviceType }: DeviceTypesProps): ReactElement {
  return (
    <FieldSet colspan={2}>
      <Select
        id="deviceType"
        value={deviceType}
        onChange={(value) => setDeviceType(value ?? '')}
        invalid={invalid}
        placeholder="Phone Type"
      >
        <Option value="iPhone, model unknown">iPhone, model unknown</Option>
        <Option value="Android">Android</Option>
        <Option value="iPhone 14 Pro Max">iPhone 14 Pro Max</Option>
        <Option value="iPhone 14 Pro">iPhone 14 Pro</Option>
        <Option value="iPhone 14 Plus">iPhone 14 Plus</Option>
        <Option value="iPhone 14">iPhone 14</Option>
        <Option value="iPhone 13 Pro Max">iPhone 13 Pro Max</Option>
        <Option value="iPhone 13 Pro">iPhone 13 Pro</Option>
        <Option value="iPhone 13 mini">iPhone 13 mini</Option>
        <Option value="iPhone 13">iPhone 13</Option>
        <Option value="iPhone 12 Pro Max">iPhone 12 Pro Max</Option>
        <Option value="iPhone 12 Pro">iPhone 12 Pro</Option>
        <Option value="iPhone 12 mini">iPhone 12 mini</Option>
        <Option value="iPhone 12">iPhone 12</Option>
        <Option value="iPhone 11 Pro Max">iPhone 11 Pro Max</Option>
        <Option value="iPhone 11 Pro">iPhone 11 Pro</Option>
        <Option value="iPhone 11">iPhone 11</Option>
        <Option value="iPhone XR">iPhone XR</Option>
        <Option value="iPhone XS Max">iPhone XS Max</Option>
        <Option value="iPhone XS">iPhone XS</Option>
        <Option value="iPhone X">iPhone X</Option>
        <Option value="iPhone 8 Plus">iPhone 8 Plus</Option>
        <Option value="iPhone 8">iPhone 8</Option>
        <Option value="iPhone SE">iPhone SE</Option>
        <Option value="iPhone 7 Plus">iPhone 7 Plus</Option>
        <Option value="iPhone 7">iPhone 7</Option>
        <Option value="iPhone 6s Plus">iPhone 6s Plus</Option>
        <Option value="iPhone 6s">iPhone 6s</Option>
      </Select>
      <FormLabel htmlFor="deviceType" invalid={invalid}>
        Phone Type
      </FormLabel>
    </FieldSet>
  )
}

import { ActionCreator } from '../../types/actions'
import {
  CARE_PATHWAY_START_REQUESTED,
  CarePathwayStartFulfilledAction,
  CarePathwayStartRequestedAction,
  CarePathwayStartFailedAction,
  CARE_PATHWAY_START_FULFILLED,
  CARE_PATHWAY_START_FAILED,
  CARE_PATHWAY_CANCEL_REQUESTED,
  CARE_PATHWAY_CANCEL_FULFILLED,
  CARE_PATHWAY_CANCEL_FAILED,
  CarePathwayCancelRequestedAction,
  CarePathwayCancelFulfilledAction,
  CarePathwayCancelFailedAction,
  FETCH_CARE_PATHWAYS_REQUESTED,
  FETCH_CARE_PATHWAYS_FULFILLED,
  FETCH_CARE_PATHWAYS_FAILED,
  FetchCarePathwaysRequestedAction,
  FetchCarePathwaysFulfilledAction,
  FetchCarePathwaysFailedAction,
} from '../../types/care-pathway'

export const fetchCarePathwaysRequested: ActionCreator<FetchCarePathwaysRequestedAction> = (payload) => ({
  type: FETCH_CARE_PATHWAYS_REQUESTED,
  payload,
})

export const fetchCarePathwaysFulfilled: ActionCreator<FetchCarePathwaysFulfilledAction> = (payload) => ({
  type: FETCH_CARE_PATHWAYS_FULFILLED,
  payload,
})

export const fetchCarePathwaysFailed: ActionCreator<FetchCarePathwaysFailedAction> = (payload) => ({
  type: FETCH_CARE_PATHWAYS_FAILED,
  payload,
})

export const carePathwayStartRequested: ActionCreator<CarePathwayStartRequestedAction> = (payload) => ({
  type: CARE_PATHWAY_START_REQUESTED,
  payload,
})

export const carePathwayStartFulfilled: ActionCreator<CarePathwayStartFulfilledAction> = (payload) => ({
  type: CARE_PATHWAY_START_FULFILLED,
  payload,
})

export const carePathwayStartFailed: ActionCreator<CarePathwayStartFailedAction> = (payload) => ({
  type: CARE_PATHWAY_START_FAILED,
  payload,
})

export const carePathwayCancelRequested: ActionCreator<CarePathwayCancelRequestedAction> = (payload) => ({
  type: CARE_PATHWAY_CANCEL_REQUESTED,
  payload,
})

export const carePathwayCancelFulfilled: ActionCreator<CarePathwayCancelFulfilledAction> = (payload) => ({
  type: CARE_PATHWAY_CANCEL_FULFILLED,
  payload,
})

export const carePathwayCancelFailed: ActionCreator<CarePathwayCancelFailedAction> = (payload) => ({
  type: CARE_PATHWAY_CANCEL_FAILED,
  payload,
})

import { AllergyIntolerance } from '../../__generated__/graphql'
import { ActionCreator } from '../../types/actions'

export const FETCH_ALLERGY_INTOLERANCE_REQUESTED = 'FETCH_ALLERGY_INTOLERANCE_REQUESTED'
export const FETCH_ALLERGY_INTOLERANCE_FAILED = 'FETCH_ALLERGY_INTOLERANCE_FAILED'
export const FETCH_ALLERGY_INTOLERANCE_SUCCEEDED = 'FETCH_ALLERGY_INTOLERANCE_SUCCEEDED'

export interface FetchAllergyIntoleranceRequestedAction {
  type: typeof FETCH_ALLERGY_INTOLERANCE_REQUESTED
  payload: {
    patientId: string
  }
}

export interface FetchAllergyIntoleranceFailedAction {
  type: typeof FETCH_ALLERGY_INTOLERANCE_FAILED
  payload: {
    patientId: string
  }
}

export interface FetchAllergyIntoleranceSucceededAction {
  type: typeof FETCH_ALLERGY_INTOLERANCE_SUCCEEDED
  payload: {
    patientId: string
    allergyIntolerance: AllergyIntolerance[]
  }
}

export const fetchAllergyIntoleranceRequested: ActionCreator<FetchAllergyIntoleranceRequestedAction> = (payload) => ({
  type: FETCH_ALLERGY_INTOLERANCE_REQUESTED,
  payload,
})

export const fetchAllergyIntoleranceFailed: ActionCreator<FetchAllergyIntoleranceFailedAction> = (payload) => ({
  type: FETCH_ALLERGY_INTOLERANCE_FAILED,
  payload,
})

export const fetchAllergyIntoleranceSucceeded: ActionCreator<FetchAllergyIntoleranceSucceededAction> = (payload) => ({
  type: FETCH_ALLERGY_INTOLERANCE_SUCCEEDED,
  payload,
})

import { JSONSchemaType } from 'ajv'
import { AuditStatus } from '../../types/audit-status'

export const auditStatusSchema: JSONSchemaType<AuditStatus> = {
  type: 'object',
  properties: {
    at: {
      type: 'string',
    },
    by: {
      type: 'string',
    },
  },
  required: ['at', 'by'],
}

import clsx from 'clsx'
import { ReactElement, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useApolloClient } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { setCursor } from '../../../../redux/actions/lists'
import { getPatientName, getPatientRecordStateById } from '../../../../redux/selectors/patient'
import { getTaskById, isBpEscalationTask, isTaskClosed } from '../../../../redux/selectors/tasks'
import { fetchPatientById } from '../../../../redux/thunks/patient'
import { Button, ButtonSize, ButtonVariant } from '../../../ui/button'
import { getListSelectedItemId } from '../../../../redux/selectors/lists'
import { RecordState } from '../../../../types/record-state'
import { PanelAction } from '../../../ui/right-rail/types'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as NoteIcon } from '../../../icons/outline/note.svg'
import { TASK_LIST_ITEM } from '../../../../consts/data-testids'
import { getStatusText } from '../../../../utils/tasks'
import { TaskStatus } from '../../../../types/task'
import { fetchTaskCarePathway } from '../../../../redux/thunks/tasks'
import BloodPressureEscalationSummary from '../../../views/blood-pressure-escalation-summary'
import { track } from '../../../../i13n'
import StatusSelector from './status-selector'
import DueDate from './due-date'
import OverflowMenu from './overflow-menu'
import StatusIndicator from './status-indicator'
import StatusPill from './status-pill'
import Assignee from './assignee'
import TaskEventAlerts from './task-event-alerts'
import TaskCopy from './task-copy'
import TaskActions from './task-actions'
import TaskVisits from './task-visits'
import TaskDescription from './task-description'
import TaskOptions from './task-options'
import TaskSurveys from './task-surveys'

interface Props {
  id: string
  isScrolling?: boolean
  listId: string
  rightRailDispatch: (action: PanelAction) => void
}

const TaskItem = ({ id, isScrolling, listId, rightRailDispatch }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const apolloClient = useApolloClient()
  const task = useAppSelector((state) => getTaskById(state, id))
  const isClosed = useAppSelector((state) => isTaskClosed(state, id))
  const patientName = useAppSelector((state) => getPatientName(state, task?.patientId ?? ''))
  const patientRecordState = useAppSelector((state) =>
    task ? getPatientRecordStateById(state, task.patientId) : RecordState.DOES_NOT_EXIST,
  )
  const active = useAppSelector((state) => getListSelectedItemId(state, listId)) === id
  const { inView, ref } = useInView()
  const isEscalationTask = useAppSelector((state) => isBpEscalationTask(state, id))

  const onContainerClick = useCallback(() => {
    dispatch(
      setCursor({
        id,
        listId,
      }),
    )

    rightRailDispatch({
      type: 'open',
      panel: 'patient-profile',
    })

    track('Task Clicked', {
      'Task ID': id,
      'Task Status': getStatusText(task?.status ?? TaskStatus.TaskPending),
      'Task Title': task?.summary ?? '',
    })
  }, [dispatch, id, listId, rightRailDispatch, task?.status, task?.summary])
  const onNotesClick = useCallback(() => {
    dispatch(
      setCursor({
        id,
        listId,
      }),
    )

    rightRailDispatch({
      type: 'open',
      panel: 'task-details',
    })

    track('Task List Notes Clicked', {
      'Task ID': id,
      'Task Status': getStatusText(task?.status ?? TaskStatus.TaskPending),
      'Task Title': task?.summary ?? '',
    })
  }, [dispatch, id, listId, rightRailDispatch, task?.status, task?.summary])

  useEffect(() => {
    if (!task?.patientId || !inView) {
      return
    }

    dispatch(fetchPatientById(task.patientId))
  }, [dispatch, inView, task?.patientId])

  useEffect(() => {
    if (!task || !inView) {
      return
    }

    track('Task Viewed', {
      'Task ID': task.id,
      'Task Status': getStatusText(task.status),
      'Task Title': task.summary,
    })
  }, [inView, task])

  useEffect(() => {
    dispatch(fetchTaskCarePathway(id, apolloClient))
  }, [apolloClient, dispatch, id, isEscalationTask])

  if (!task) {
    return null
  }

  return (
    <article
      className={clsx('box-border mb-[2px] cursor-pointer flex flex-col', {
        'bg-rivaOffblack-100 border-y border-x-4 border-rivaPurple-500 px-5 py-[15px]': active,
        'bg-white hover:bg-rivaOffblack-50 border-y border-x-4 border-transparent hover:border-rivaPurple-200 px-5 py-[15px]':
          !active,
      })}
      data-carepathwayid={task.carePathwayId}
      data-taskid={id}
      data-tasktype={task.taskType}
      data-testid={TASK_LIST_ITEM}
      onClick={onContainerClick}
      ref={ref}
    >
      <header className="flex flex-none">
        <StatusIndicator taskId={id} />
        <h4 className="ml-2 flex-1 text-sm font-medium leading-6">{task.summary}</h4>
        {patientRecordState === RecordState.LOADING || patientRecordState === RecordState.DOES_NOT_EXIST ? (
          <div className="bg-rivaOffblack-200 rounded h-3 w-[92px] ml-auto animate-pulse"></div>
        ) : (
          <div className="text-sm leading-6" data-patientid={task.patientId}>
            {patientName}
          </div>
        )}
      </header>
      <main className="flex-1">
        <TaskEventAlerts taskId={id} />
        {task.description ? <TaskDescription taskId={id} /> : null}
        {task.copy ? <TaskCopy taskId={id} /> : null}
        {isEscalationTask ? (
          <div className="mt-2 bg-rivaOffblack-100">
            <BloodPressureEscalationSummary id={task.carePathwayId} />
          </div>
        ) : null}
        <TaskVisits taskId={id} />
        <TaskSurveys taskId={id} />
        <TaskOptions listId={listId} taskId={id} />
        <TaskActions taskId={id} />
      </main>
      <footer className="flex flex-none justify-end pt-4 mt-4 border-t border-dashed border-rivaOffblack-300">
        <DueDate selected={active} taskId={id} />
        <Assignee practitionerId={task.assignee} selected={active} />
        <span className="flex-1"></span>
        {task.notes?.length ? (
          <Button className="mr-2" onClick={onNotesClick} size={ButtonSize.XXS} variant={ButtonVariant.SECONDARY}>
            <SvgIcon Icon={NoteIcon} size={IconSize.X_SMALL} />
            <span className="ml-1">{task.notes.length === 1 ? '1 note' : `${task.notes.length} notes`}</span>
          </Button>
        ) : null}
        {isClosed ? (
          <StatusPill selected={active} status={task.status} />
        ) : (
          <StatusSelector isScrolling={isScrolling} listId={listId} taskId={id} />
        )}
        <span className="w-2 flex-none"></span>
        <OverflowMenu isScrolling={isScrolling} listId={listId} rightRailDispatch={rightRailDispatch} taskId={id} />
      </footer>
    </article>
  )
}

export default TaskItem

import { AppThunk } from '../reducers'
import { fetchPractitionerFulfilled } from '../actions/practitioner'
import { fetchOrganizationsFulfilled } from '../actions/organization'
import { fetchSitesFulfilled } from '../actions/site'
import { failedToLoadApp, loadedApp, loadingApp } from '../actions/app'

/**
 * This function fetches all of the data needed upon the initial load of the app.
 * If any data fails to fetch, we cannot properly display the app.
 */
export const loadApp =
  (personId: string): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    dispatch(loadingApp())

    try {
      const loggedInPerson = await apiClient.getPerson(personId)
      const practitionerResponse = await apiClient.getPractitionerByPersonId(loggedInPerson.id)

      dispatch(fetchPractitionerFulfilled(practitionerResponse))

      const organizationIds: string[] = practitionerResponse.practitioner.map(
        (practitionerMember) => practitionerMember.organizationId,
      )
      const organizationId: string = organizationIds[0]
      const [organization, sites, orgPractitionerResponse] = await Promise.all([
        apiClient.getOrganization(organizationId),
        apiClient.getSites(organizationIds),
        apiClient.getPractitionerByOrganizationIds(organizationIds),
      ])

      dispatch(fetchOrganizationsFulfilled([organization]))
      dispatch(fetchSitesFulfilled(sites))
      dispatch(fetchPractitionerFulfilled(orgPractitionerResponse))

      dispatch(loadedApp(loggedInPerson.id))
    } catch (e) {
      dispatch(failedToLoadApp(e as Error))
    }
  }

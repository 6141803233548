import { ReactElement, useCallback, useEffect } from 'react'
import Confirm from '../../../../modals/confirm'
import { ButtonVariant } from '../../../../ui/button'
import { useAppDispatch } from '../../../../../redux'
import useTasksApi from '../../../../../api/hooks/use-tasks-api'
import { cancelCarePathway } from '../../../../../redux/thunks/care-pathway'
import { track } from '../../../../../i13n'

interface Props {
  carePathwayId: string
  isOpen: boolean
  listId: string
  onClose: () => void
  patientId: string
}

const StopLifestyleChangeModal = ({ carePathwayId, isOpen, listId, onClose, patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()

  const onAccept = useCallback(() => {
    dispatch(cancelCarePathway(carePathwayId, listId, patientId, tasksApi))
    onClose()

    track('Lifestyle Change Stop Modal Submitted')
  }, [carePathwayId, dispatch, listId, onClose, patientId, tasksApi])
  const onCancel = useCallback(() => {
    onClose()

    track('Lifestyle Change Stop Modal Cancelled')
  }, [onClose])

  useEffect(() => {
    track('Lifestyle Change Stop Modal Shown')
  }, [])

  return (
    <Confirm
      confirmButtonText="Stop Lifestyle Change"
      confirmButtonVariant={ButtonVariant.ERROR}
      isOpen={isOpen}
      onAccept={onAccept}
      onCancel={onCancel}
      subtitle="Existing and future lifestyle change tasks for the patient will be canceled."
      title="Are you sure you want to stop the lifestyle change?"
    />
  )
}

export default StopLifestyleChangeModal

import { datadogLogs } from '@datadog/browser-logs'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { track } from '../../i13n'

const usePageViews = (): void => {
  const location = useLocation()
  const [pathname, setPathname] = useState('')

  // useLocation seems to have this weird behavior of causing this effect
  // to trigger multiple times even when the pathname does not change.
  // To avoid it, we keep the pathname in state
  useEffect(() => {
    if (pathname !== '') {
      mixpanel.register({
        location: pathname,
      })

      track('Page View', {
        pathname,
      })
    }
  }, [pathname])

  useEffect(() => {
    if (pathname !== '') {
      datadogLogs.logger.debug(`Navigated to: ${pathname}`, {
        componentName: 'navigation',
        pathname,
      })
    }
  }, [pathname])

  useEffect(() => {
    setPathname(location.pathname)
  }, [location.pathname])
}

export default usePageViews

import {
  Site,
  FetchingSites,
  FetchSitesFulfilled,
  FetchSitesFailed,
  FETCHING_SITES,
  FETCH_SITES_FULFILLED,
  FETCH_SITES_FAILED,
} from '../../types/site'

export function fetchingSites(): FetchingSites {
  return {
    type: FETCHING_SITES,
    payload: undefined,
  }
}

export function fetchSitesFulfilled(sites: Site[]): FetchSitesFulfilled {
  return {
    type: FETCH_SITES_FULFILLED,
    payload: {
      sites,
    },
  }
}

export function fetchSitesFailed(error: Error): FetchSitesFailed {
  return {
    type: FETCH_SITES_FAILED,
    payload: {
      error,
    },
  }
}

import Ajv, { JSONSchemaType } from 'ajv'
import { SWRResponse } from 'swr'
import { BloodPressureAverage, BloodPressureAverageStatus } from '../../types/blood-pressure-moving-average'
import bloodPressureAverageSchema from '../schema/blood-pressure-average'
import useApi from './use-api'

const ajv = new Ajv()

interface GetBaselineMovingAverageResponse {
  baseline: BloodPressureAverage | null | undefined
  status: BloodPressureAverageStatus
}

const schema: JSONSchemaType<GetBaselineMovingAverageResponse> = {
  type: 'object',
  properties: {
    baseline: {
      ...bloodPressureAverageSchema,
      nullable: true,
    },
    status: {
      type: 'string',
    },
  },
  required: ['status'],
}

const validate = ajv.compile(schema)

const useGetBaselineMovingAverage = (personId: string): SWRResponse<GetBaselineMovingAverageResponse> =>
  useApi(personId ? '/v1/person/:personId/baseline-blood-pressure-moving-average' : null, {
    params: {
      personId,
    },
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    validate,
  })

export const getBaselineBloodPressureForDisplay = (data: GetBaselineMovingAverageResponse | undefined): string => {
  if (!data?.baseline) {
    return '-'
  }

  return `${Math.round(data.baseline.systolicAverage.average)}/${Math.round(data.baseline.diastolicAverage.average)}`
}

export default useGetBaselineMovingAverage

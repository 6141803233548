import clsx from 'clsx'
import { ComponentProps, MouseEventHandler, ReactElement, ReactNode, useCallback } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { track } from '../../../i13n'

interface IconProps {
  isActive: boolean
}

interface NavigationItemProps {
  className?: string
  exact?: ComponentProps<typeof NavLink>['exact']
  iconLeft?: ReactNode | ((props: IconProps) => ReactNode)
  iconRight?: ReactNode | ((props: IconProps) => ReactNode)
  isActive?: ComponentProps<typeof NavLink>['isActive']
  label: string
  level?: 'PRIMARY' | 'SECONDARY'
  onClick?: ComponentProps<typeof NavLink>['onClick']
  tabIndex?: ComponentProps<typeof NavLink>['tabIndex']
  to: string
}

const NavigationItem = ({
  className,
  to,
  iconLeft,
  iconRight,
  isActive: getIsActive,
  exact,
  label,
  level = 'PRIMARY',
  onClick,
  tabIndex,
}: NavigationItemProps): ReactElement => {
  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      if (document.activeElement?.tagName === 'A') {
        const anchor = document.activeElement as HTMLAnchorElement
        anchor.blur()
      }

      track('Sidebar Link Clicked', {
        Name: event.currentTarget.textContent,
      })

      if (onClick) {
        onClick(event)
      }
    },
    [onClick],
  )
  const isActive = !!useRouteMatch(to)

  return (
    <NavLink
      className={(isActive: boolean) =>
        clsx(
          'w-full px-[14px] py-[10px] box-border flex items-center border-2 border-white border-opacity-0 hover:border-opacity-20',
          'active:border-opacity-0 active:bg-white active:bg-opacity-20',
          'focus-visible:border-rivaPurple-300 focus-visible:border-2 focus-visible:px-[15px] focus-visible:py-[11px] outline-none ',
          'transition-all duration-150',
          className,
          {
            rounded: level === 'PRIMARY',
            'first:rounded-t last:rounded-b': level === 'SECONDARY',
            'hover:bg-white hover:bg-opacity-10': !isActive,
            'bg-white text-white bg-opacity-20': isActive,
          },
        )
      }
      exact={exact}
      isActive={getIsActive}
      onClick={handleClick}
      tabIndex={tabIndex}
      to={to}
    >
      <div className="flex items-center w-[180px] flex-none">
        <div className="h-6 w-6 flex-none mr-4 nav-item-left-icon">
          {typeof iconLeft === 'function' ? iconLeft({ isActive }) : iconLeft}
        </div>
        <div className="flex-1 nav-item-content">{label}</div>
        {iconRight ? (
          <div className="ml-2 flex-none font-medium nav-item-right-icon">
            {typeof iconRight === 'function' ? iconRight({ isActive }) : iconRight}
          </div>
        ) : null}
      </div>
    </NavLink>
  )
}

export default NavigationItem

export enum Routes {
  DASHBOARD = '/dashboard/:serializedListId',
  DASHBOARD_PATIENT = '/dashboard/:serializedListId/:patientId',
  PATIENT_LIST = '/patients/:serializedListId',
  PATIENT_LIST_PATIENT = '/patients/:serializedListId/:patientId',
  SEARCH = '/search/:serializedListId?',
  SEARCH_PATIENT = '/search/:serializedListId/:patientId',
}

export interface SearchRouteParams {
  serializedListId?: string
}

import { DetailedHTMLProps, HTMLAttributes, ReactElement, ReactNode } from 'react'

interface Props extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className' | 'role'> {
  children?: ReactNode | ReactNode[]
}

const ButtonGroup = ({ children, ...rest }: Props): ReactElement => {
  return (
    <div {...rest} className="group btn-group inline-flex" role="group">
      {children}
    </div>
  )
}

export default ButtonGroup

import { FormAction } from '../../../../types/form'
import {
  CREATE_NOTE_FAILED,
  CREATE_NOTE_FORM_INITIALIZED,
  CREATE_NOTE_FULFILLED,
  CREATING_NOTE,
} from '../../../../types/note'
import formReducerFactory from '../form-reducer-factory'

export const createNoteForm = formReducerFactory({
  [FormAction.INITIALIZED]: [CREATE_NOTE_FORM_INITIALIZED],
  [FormAction.SUBMITTING]: [CREATING_NOTE],
  [FormAction.SUBMITTED]: [CREATE_NOTE_FULFILLED],
  [FormAction.ERRORED]: [CREATE_NOTE_FAILED],
})

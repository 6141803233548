import { ReactElement, useEffect } from 'react'
import useTasksApi from '../../../../api/hooks/use-tasks-api'
import { TASK_DETAILS } from '../../../../consts/data-testids'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getListSelectedItemId } from '../../../../redux/selectors/lists'
import { getPatientName } from '../../../../redux/selectors/patient'
import { getTaskById, isTaskStale } from '../../../../redux/selectors/tasks'
import { fetchTaskById } from '../../../../redux/thunks/tasks'
import FormattedDateTime from '../../../ui/formatted-date-time'
import HeaderBlock from '../../../ui/header-block'
import { getStatusText } from '../../../../utils/tasks'
import { TaskStatus } from '../../../../types/task'
import { track } from '../../../../i13n'
import TaskActivity from './task-activity'
import TaskDetailsAssignee from './task-details-assignee'
import TaskDetailsDueDate from './task-details-due-date'
import TaskDetailsStatus from './task-details-status'
import TaskNoteInput from './task-note-input'

interface Props {
  autofocus?: boolean
  listId?: string
}

const TaskDetails = ({ autofocus, listId = '' }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const taskId = useAppSelector((state) => getListSelectedItemId(state, listId)) ?? ''
  const task = useAppSelector((state) => getTaskById(state, taskId))
  const patientName = useAppSelector((state) => getPatientName(state, task?.patientId ?? ''))
  const isStale = useAppSelector((state) => isTaskStale(state, taskId))

  useEffect(() => {
    dispatch(fetchTaskById(taskId, tasksApi))
  }, [dispatch, taskId, tasksApi])

  useEffect(() => {
    if (isStale) {
      dispatch(fetchTaskById(taskId, tasksApi))
    }
  }, [dispatch, isStale, taskId, tasksApi])

  useEffect(() => {
    track('Task Details Shown', {
      'Task ID': taskId,
      'Task Status': getStatusText(task?.status ?? TaskStatus.TaskPending),
      'Task Title': task?.summary ?? '',
    })
  }, [task?.status, task?.summary, taskId])

  if (!task) {
    return null
  }

  return (
    <section className="w-[598px] bg-white text-rivaOffblack-900 flex flex-col" data-testid={TASK_DETAILS}>
      <header className="p-7 grid grid-cols-3 gap-5 border-b border-rivaOffblack-200 flex-none">
        <h2 className="text-lg font-semibold m-0 col-span-3">{task.summary}</h2>
        <HeaderBlock label="Patient" content={patientName} />
        <HeaderBlock
          label="Created"
          content={<FormattedDateTime value={task.created.at} day="numeric" month="numeric" year="2-digit" />}
        />
        <div />
        <HeaderBlock label="Due date" content={<TaskDetailsDueDate listId={listId} taskId={taskId} />} />
        <HeaderBlock label="Assignee" content={<TaskDetailsAssignee listId={listId} taskId={taskId} />} />
        <HeaderBlock label="Status" content={<TaskDetailsStatus listId={listId} taskId={taskId} />} />
      </header>
      <TaskActivity taskId={taskId} />
      <TaskNoteInput autofocus={autofocus} taskId={taskId} />
    </section>
  )
}

export default TaskDetails

import { ReactElement, useCallback } from 'react'
import { Menu as ReactMenu, MenuModifiers, MenuProps } from '@szhsin/react-menu'
import { getMenuClassName } from './utils'

type Props = Omit<MenuProps, 'offsetY' | 'menuClassName'>

const Menu = ({ align, ...props }: Props): ReactElement => {
  const menuClassName = useCallback<(m: MenuModifiers) => string>(
    (modifiers) => getMenuClassName(modifiers, align),
    [align],
  )

  return <ReactMenu {...props} align={align} menuClassName={menuClassName} gap={4} />
}

export default Menu

import { Note } from '../../types/note'
import { RecordState } from '../../types/record-state'
import { RootState } from '../reducers'
import { getFirstName, getLastName } from './person'

/**
 * @returns the record state of the notes
 */
export function getNoteRecordState(state: RootState, memberId: string): RecordState {
  return state.note.memberIdToRecordStateMap[memberId] || RecordState.DOES_NOT_EXIST
}

export function getNoteIds(state: RootState, memberId: string): string[] {
  return state.note.memberIdToNoteIdsMap[memberId] || []
}

/**
 * @param noteId the id of the note we are getting
 * @returns Note
 */
export function getNote(state: RootState, noteId: string): Note | null {
  return state.note.noteMap[noteId] || null
}

/**
 * @param noteId the id of the note we want the author for
 * @returns string First and last name of the author
 */
export function getNoteAuthor(state: RootState, noteId: string): string {
  const { personId = '' } = getNote(state, noteId) ?? {}

  return getFirstName(state, personId) + ' ' + getLastName(state, personId)
}

import { useEffect, useState } from 'react'
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react'
import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler'

const useGetChannelUnreadCount = (channelUrl: string | null): number => {
  const [unreadCount, setUnreadCount] = useState(0)
  const context = useSendbirdStateContext()
  const sdk = sendbirdSelectors.getSdk(context)
  const getGroupChannel = sendbirdSelectors.getGetGroupChannel(context)

  useEffect(() => {
    if (!sdk?.addUserEventHandler || !channelUrl) {
      return
    }

    const updateUnreadCount = async () => {
      const channel = await getGroupChannel(channelUrl)

      await channel.refresh()

      setUnreadCount(channel.unreadMessageCount)
    }

    updateUnreadCount()

    const userHandler = new UserEventHandler()
    userHandler.onTotalUnreadMessageCountUpdated = updateUnreadCount

    sdk.addUserEventHandler('use-get-channel-unread-count', userHandler)

    return () => {
      sdk.removeUserEventHandler('use-get-channel-unread-count')
    }
  }, [channelUrl, getGroupChannel, sdk, sdk?.addUserEventHandler])

  return unreadCount
}

export default useGetChannelUnreadCount

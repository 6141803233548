export const SEARCH_KEYS = '/'
export const PATIENT_LIST_KEYS = 'o p'

export const ASSIGN_COMMAND_KEYS = 'a'
export const CHANGE_STATUS_KEYS = 's'

export const CHANGE_STATUS_NOT_STARTED_KEYS = 'command+option+shift+1'
export const CHANGE_STATUS_IN_PROGRESS_KEYS = 'command+option+shift+2'
export const CHANGE_STATUS_COMPLETED_KEYS = 'command+option+shift+3'
export const CHANGE_STATUS_SKIPPED_KEYS = 'command+option+shift+4'
export const CHANGE_STATUS_NOT_APPLICABLE_KEYS = 'command+option+shift+5'

import { gql } from '../__generated__/gql'

export default gql(`#graphql
query patientAthenaProfilePaidQuery($patientId: ID!) {
  patient(patientId: $patientId) {
    athenaProfile {
      address1
      assignedAtBirthSex
      city
      consentToCall
      consentToText
      contactName
      contactMobilePhone
      contactRelationship
      driversLicenseExpirationDate
      driversLicenseNumber
      driversLicenseStateId
      ethnicityCodes
      language6392Code
      maritalStatus
      race
      sex
      state
      zip
    }
    id
  }
}
`)

import React, { ReactElement, useEffect, useState } from 'react'
import { Modal } from '../ui/modal'
import { FormIds, FormSubmissionState } from '../../types/form'
import { LoadingIndicator, LoadingIndicatorPosition } from '../ui/loading-indicator'
import { Button, ButtonVariant } from '../ui/button'
import { updatePatient } from '../../redux/thunks/patient'
import { getFormSubmissionState } from '../../redux/selectors/form'
import { updateBloodPressureMeasurementStatusFormInitialized } from '../../redux/actions/patient'
import {
  convertBloodPressureManagementStatusToDisplayName,
  getPatientBloodPressureManagementStatus,
} from '../../redux/selectors/patient'
import { BloodPressureManagementStatus } from '../../types/patient'
import { useAppDispatch, useAppSelector } from '../../redux'
import ModalHeader from '../ui/modal/modal-header'
import ModalFooter from '../ui/modal/modal-footer'
import { ModalContainer } from '../ui/modal/modal-container'

interface UpdateBloodPressureManagementStatusModalProps {
  isOpen: boolean
  close: () => void
  patientId: string
}

export function UpdateBloodPressureManagementStatusModal({
  isOpen,
  close,
  patientId,
}: UpdateBloodPressureManagementStatusModalProps): ReactElement {
  const dispatch = useAppDispatch()
  const initialBloodPressureManagementStatus = useAppSelector((state) =>
    getPatientBloodPressureManagementStatus(state, patientId),
  )
  const patientFormState = useAppSelector((state) =>
    getFormSubmissionState(state, FormIds.UPDATE_BLOOD_PRESSURE_MANAGEMENT_STATUS),
  )

  const [bloodPressureManagementStatus, setBloodPressureManagementStatus] = useState(
    initialBloodPressureManagementStatus,
  )
  const bloodPressureManagementStatuses = getBloodPressureManagementStatuses(initialBloodPressureManagementStatus)

  useEffect(() => {
    if (isOpen) {
      dispatch(updateBloodPressureMeasurementStatusFormInitialized())
      setBloodPressureManagementStatus(initialBloodPressureManagementStatus)
    }
  }, [isOpen, dispatch, initialBloodPressureManagementStatus])

  useEffect(() => {
    if (patientFormState === FormSubmissionState.SUBMITTED) {
      dispatch(updateBloodPressureMeasurementStatusFormInitialized())
      close()
    }
  }, [patientFormState, close, dispatch])

  return (
    <Modal isOpen={isOpen} closeModal={close}>
      <ModalContainer>
        <ModalHeader
          subtitle="Update the patient's status for blood pressure management"
          title="Update Management Status"
        ></ModalHeader>
        {patientFormState === FormSubmissionState.ERRORED && (
          <p className="text-rivaRed mb-2">
            Sorry, we failed to update the blood pressure management status. Please refresh the page and contact support
            if the problem persists.
          </p>
        )}
        <form
          className="mx-10 mb-7"
          onSubmit={(e) => {
            e.preventDefault()

            dispatch(
              updatePatient(patientId, {
                bloodPressureManagementStatus,
              }),
            )
          }}
        >
          <div className="flex flex-col">
            {bloodPressureManagementStatuses.map((ms) => {
              return (
                <label key={ms}>
                  <input
                    className="mr-2"
                    type="radio"
                    onChange={(e) => setBloodPressureManagementStatus(ms)}
                    checked={bloodPressureManagementStatus === ms}
                    value={ms}
                    name="bloodPressure"
                  />
                  {convertBloodPressureManagementStatusToDisplayName(ms)}
                </label>
              )
            })}
          </div>

          <div className="flex justify-end">
            {patientFormState === FormSubmissionState.SUBMITTING ? (
              <LoadingIndicator className="mr-4 mt-4 mb-2" position={LoadingIndicatorPosition.RIGHT} />
            ) : (
              <ModalFooter>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    close()
                  }}
                  variant={ButtonVariant.SECONDARY}
                  className="mr-3"
                >
                  Cancel
                </Button>
                <Button>Save</Button>
              </ModalFooter>
            )}
          </div>
        </form>
      </ModalContainer>
    </Modal>
  )
}

function getBloodPressureManagementStatuses(
  currentManagementStatus: BloodPressureManagementStatus,
): BloodPressureManagementStatus[] {
  switch (currentManagementStatus) {
    case BloodPressureManagementStatus.ONBOARDING:
      return [BloodPressureManagementStatus.ONBOARDING, BloodPressureManagementStatus.ESTABLISHING_CARE]

    case BloodPressureManagementStatus.ESTABLISHING_CARE:
      return [
        BloodPressureManagementStatus.ONBOARDING,
        BloodPressureManagementStatus.ESTABLISHING_CARE,
        BloodPressureManagementStatus.ACTIVE_MANAGEMENT,
      ]

    case BloodPressureManagementStatus.ACTIVE_MANAGEMENT:
      return [
        BloodPressureManagementStatus.ESTABLISHING_CARE,
        BloodPressureManagementStatus.ACTIVE_MANAGEMENT,
        BloodPressureManagementStatus.MAINTENANCE,
      ]

    case BloodPressureManagementStatus.MAINTENANCE:
      return [BloodPressureManagementStatus.ACTIVE_MANAGEMENT, BloodPressureManagementStatus.MAINTENANCE]

    default:
      return []
  }
}

import { produce } from 'immer'
import { combineReducers, Reducer } from 'redux'
import { RecordState } from '../../types/record-state'
import {
  FetchingSites,
  FETCHING_SITES,
  FetchSitesFailed,
  FetchSitesFulfilled,
  FETCH_SITES_FAILED,
  FETCH_SITES_FULFILLED,
  Site,
  SiteMap,
  SiteState,
} from '../../types/site'

type SiteActions = FetchingSites | FetchSitesFulfilled | FetchSitesFailed

function recordState(state: RecordState = RecordState.DOES_NOT_EXIST, action: SiteActions) {
  switch (action.type) {
    case FETCHING_SITES:
      return RecordState.LOADING
    case FETCH_SITES_FULFILLED:
      return RecordState.LOADED
    case FETCH_SITES_FAILED:
      return RecordState.ERRORED
    default:
      return state
  }
}
const siteMap = produce((draft: SiteMap, action: SiteActions) => {
  switch (action.type) {
    case FETCH_SITES_FULFILLED: {
      const { sites } = action.payload

      if (!sites) {
        return
      }

      sites.forEach((site: Site) => {
        draft[site.id] = site
      })

      return
    }
  }
}, {})

export const site: Reducer<SiteState, SiteActions> = combineReducers({
  recordState,
  siteMap,
})

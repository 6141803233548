import { matchPath, useLocation } from 'react-router-dom'
import { Routes } from '../../../../../types/route'

const useGetRefreshRoute = (): Routes => {
  const location = useLocation()

  return Object.values(Routes).find((route) => matchPath(location.pathname, route)) ?? Routes.DASHBOARD
}

export default useGetRefreshRoute

import { ReactElement } from 'react'
import { useRouteMatch } from 'react-router-dom'
import useTooltip from '../../../hooks/use-tooltip'
import useGetTotalUnreadCount from '../../../api/hooks/sendbird/use-get-total-unread-count'
import { ReactComponent as IconChatAltOutline } from '../../icons/outline/chat-alt.svg'
import { ReactComponent as IconChatAltSolid } from '../../icons/solid/chat-alt.svg'
import Tooltip from '../../ui/tooltip'
import NavigationItem from './navigation-item'
import NewIndicator from './new-indicator'

const ChatNavigationItem = (): ReactElement => {
  const path = '/chat'
  const isActive = !!useRouteMatch(path)
  const unreadCount = useGetTotalUnreadCount()
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    offset: [0, 20],
    placement: 'right',
  })

  return (
    <>
      <li {...getTriggerProps()}>
        <NavigationItem
          to={path}
          label="Chat"
          iconLeft={() => (
            <div className="w-6 h-6 relative">
              {isActive ? <IconChatAltSolid /> : <IconChatAltOutline />}
              {unreadCount > 0 ? <NewIndicator /> : null}
            </div>
          )}
          iconRight={() => (!isActive && unreadCount > 0 ? (unreadCount > 99 ? '99+' : unreadCount) : undefined)}
        />
      </li>
      {unreadCount > 0 && !isActive ? (
        <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
          {unreadCount === 0
            ? 'No unread messages'
            : unreadCount === 1
            ? 'You have 1 unread message'
            : `You have ${unreadCount} unread messages`}
        </Tooltip>
      ) : null}
    </>
  )
}

export default ChatNavigationItem

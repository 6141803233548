import clsx from 'clsx'
import { useSelect } from 'downshift'
import { MouseEventHandler, ReactElement, ReactNode, RefCallback, useCallback, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'
import { ReactComponent as ChevronDownIcon } from '../../icons/solid/chevron-down.svg'
import { ReactComponent as ExclamationIcon } from '../../icons/solid/exclamation.svg'
import FormLabel from '../form-label'
import { IconSize, SvgIcon } from '../icon'
import Layer from '../layer'
import { List, ListItem } from '../list'
import { sameWidthModifier } from '../../../utils/popper'
import OptionComponent, { Props as OptionProps } from './option'
import type { Props as HeadingProps } from './select-heading'

export type OptionElement<T extends string | number> = ReactElement<OptionProps<T>>
export type HeadingElement = ReactElement<HeadingProps>

interface Props<T extends string | number> {
  children: OptionElement<T> | HeadingElement | (OptionElement<T> | HeadingElement | null)[]
  className?: string
  hasAlert?: boolean
  hideRadio?: boolean
  id?: string
  invalid?: boolean
  label?: ReactNode
  onClick?: MouseEventHandler
  onChange?: (value?: T) => void
  placeholder?: string
  placement?: Placement
  required?: boolean
  warning?: boolean
  value?: T
}

interface Option<T extends string | number> {
  index: number
  label: ReactNode
  type: 'option'
  value: T
}

interface Heading {
  label: ReactNode
  type: 'heading'
}

interface Divider {
  label: ReactNode
  type: 'divider'
}

type Item<T extends string | number> = Option<T> | Heading | Divider

function Select<T extends string | number>({
  children,
  className,
  hasAlert = false,
  hideRadio = false,
  id,
  invalid,
  label,
  onClick,
  onChange,
  placeholder,
  placement = 'bottom-end',
  required,
  warning,
  value,
  ...props
}: Props<T>): ReactElement {
  const isOptionChild = (child: OptionElement<T> | HeadingElement | null): child is OptionElement<T> =>
    !!child && child.type === OptionComponent

  const items: Item<T>[] = (Array.isArray(children) ? children : [children]).reduce((items, child) => {
    if (!child) {
      return items
    }

    if (isOptionChild(child)) {
      items.push({
        index: items.filter(({ type }) => type === 'option').length,
        label: child.props.children,
        type: 'option',
        value: child.props.value,
      })
    } else {
      items.push({
        label: child,
        type: 'heading',
      })
    }

    return items
  }, [] as Item<T>[])
  const options = items.filter((item): item is Option<T> => item.type === 'option')

  const { getToggleButtonProps, getLabelProps, getMenuProps, getItemProps, highlightedIndex, isOpen, selectedItem } =
    useSelect<Option<T>>({
      id,
      items: options,
      itemToString: (item) => String(item?.value ?? ''),
      onSelectedItemChange: (item) => onChange?.(item?.selectedItem?.value),
      // it's important that we default to null, otherwise Downshift considers
      // this an uncontrolled component
      selectedItem: options.find(({ value: itemValue }) => itemValue === value) ?? null,
    })
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement>()
  const [listElement, setListElement] = useState<HTMLUListElement>()

  const buttonRefCallback = useCallback<RefCallback<HTMLButtonElement>>((element) => {
    if (element) {
      setButtonElement(element)
    }
  }, [])
  const listRefCallback = useCallback<RefCallback<HTMLUListElement>>((element) => {
    if (element) {
      setListElement(element)
    }
  }, [])

  const { attributes, styles, forceUpdate } = usePopper(buttonElement, listElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
        },
      },
      sameWidthModifier,
    ],
  })

  useEffect(() => {
    if (isOpen && forceUpdate) {
      setTimeout(forceUpdate, 0)
    }
  }, [isOpen, forceUpdate])

  useEffect(() => {
    if (!listElement) {
      return
    }

    const stopPropagation: EventListener = (event) => {
      event.stopPropagation()
    }

    listElement.addEventListener('keydown', stopPropagation)

    return () => {
      listElement.removeEventListener('keydown', stopPropagation)
    }
  }, [listElement])

  return (
    <>
      <button
        {...getToggleButtonProps({
          onClick,
          ref: buttonRefCallback,
        })}
        type="button"
        className={clsx(
          'inline-flex transition-all duration-150 items-center justify-between text-sm outline-none p-3 rounded border box-border order-2 h-10',
          {
            'border-rivaOffblack-300': !isOpen && !invalid && !warning,
            'border-rivaPurple-500': isOpen && !invalid && !warning,
            'border-rivaGold-300': warning,
            'border-rivaFuchsia-500': invalid,
            'bg-rivaGold-50': warning,
            'bg-rivaFuchsia-50': invalid,
            'text-rivaOffblack-400': !invalid && !warning && !selectedItem,
            'text-rivaOffblack-900': !invalid && !warning && selectedItem,
            'text-rivaGold-800': warning,
            'text-rivaFuchsia-800': invalid,
            'hover:border-rivaOffblack-500': !isOpen && !invalid && !warning,
            'hover:border-rivaGold-800': !isOpen && warning,
            'hover:border-rivaFuchsia-800': !isOpen && invalid,
          },
          className,
        )}
      >
        {selectedItem ? selectedItem.label : placeholder}
        <span className="h-5">
          {hasAlert && (warning || invalid) && (
            <SvgIcon
              Icon={ExclamationIcon}
              size={IconSize.SMALL}
              className={clsx('fill-current mr-1', {
                'text-rivaGold-500': warning,
                'text-rivaFuchsia-500': invalid,
              })}
            />
          )}
          <SvgIcon
            Icon={ChevronDownIcon}
            size={IconSize.SMALL}
            className={clsx('fill-current transform transition-transform ease-out duration-150', {
              'text-rivaOffblack-900': !isOpen && !invalid && !warning,
              'text-rivaPurple-500': isOpen && !invalid && !warning,
              'text-rivaGold-800': warning,
              'text-rivaFuchsia-800': invalid,
              'rotate-180': isOpen,
            })}
          />
        </span>
      </button>
      <Layer>
        <List
          {...getMenuProps({
            ...attributes.popper,
            style: {
              ...styles.popper,
            },
            ref: listRefCallback,
          })}
          className={clsx('order-3 border border-rivaOffblack-200 shadow-xl rounded', {
            hidden: !isOpen,
          })}
        >
          {isOpen &&
            items.map((item) => {
              if (item.type === 'heading' || item.type === 'divider') {
                return item.label
              }

              const isSelected = selectedItem ? selectedItem?.value === item.value : false

              return (
                <ListItem
                  data-value={item.value}
                  key={`${item.value}`}
                  {...getItemProps({
                    className: clsx('h-8', {
                      'bg-rivaPurple-100': highlightedIndex === item.index,
                    }),
                    item,
                    index: item.index,
                  })}
                >
                  {typeof item.label === 'string' ? <span className="flex-1 truncate">{item.label}</span> : item.label}
                  {hideRadio ? null : (
                    <span
                      className={clsx(
                        'rounded-full w-5 h-5 box-border ml-2 flex-none',
                        isSelected ? 'border-8 border-rivaPurple-500' : ' border-2 border-rivaOffblack-900',
                      )}
                    ></span>
                  )}
                </ListItem>
              )
            })}
        </List>
      </Layer>
      {label && (
        <FormLabel {...getLabelProps()} required={required} invalid={invalid}>
          {label}
        </FormLabel>
      )}
    </>
  )
}

export default Select

import uniq from 'lodash.uniq'
import { ReactElement } from 'react'
import useGetSurveySubmissionsByPatient from '../../../../api/hooks/use-get-survey-submissions-by-patient'
import { SURVEYS_SUMMARY } from '../../../../consts/data-testids'
import { SurveySubmission } from '../../../../types/survey'
import { Table, TableBody, TableHeader } from '../../../ui/table'
import { SummaryContainer } from '../summary-container'
import SubmissionItem from './submission-item'

// Submissions are sorted in DESCENDING order, the first matching one is is the latest
const getLatestSurveySubmission = (submissions: SurveySubmission[], surveyId: number) =>
  submissions.find(({ surveyId: id, completedAt }) => id === surveyId && completedAt !== '')

const getSurveyIds = (submissions: SurveySubmission[]) => uniq(submissions.map(({ surveyId }) => surveyId))

interface Props {
  patientId: string
}

const Summary = ({ patientId }: Props): ReactElement | null => {
  const { data: { submissions } = {} } = useGetSurveySubmissionsByPatient(patientId)

  const latestSubmissions =
    getSurveyIds(submissions ?? []).reduce((latest, surveyId) => {
      const submission = getLatestSurveySubmission(submissions ?? [], surveyId)

      if (submission) {
        latest.push(<SubmissionItem key={surveyId} {...submission} />)
      }

      return latest
    }, [] as ReactElement[]) ?? []

  if (latestSubmissions.length === 0) {
    return null
  }

  return (
    <div className="mb-2 bg-rivaOffwhite">
      <SummaryContainer
        title="Summary"
        subtitle="Most recent key results across all survey types"
        data-testid={SURVEYS_SUMMARY}
        collapsible={false}
      >
        <Table>
          <TableHeader
            headers={[
              { text: 'Survey name', className: 'w-[200px] flex items-center' },
              { text: 'Result', className: 'flex-1 flex items-center' },
              { text: 'Date Completed', className: 'w-[130px] flex flex-col items-center justify-stretch text-right' },
            ]}
          />
          <TableBody>{latestSubmissions}</TableBody>
        </Table>
      </SummaryContainer>
    </div>
  )
}

export default Summary

import { FETCH_SURVEY_RULES_FAILED, FETCH_SURVEY_RULES_SUCCEEDED } from '../../types/actions'
import { RecordState } from '../../types/record-state'
import { AppThunk } from '../reducers'
import { getSurveyRulesState } from '../selectors/surveys'

export const fetchSurveyRules =
  (): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    const recordState = getSurveyRulesState(getState())

    if (recordState !== RecordState.DOES_NOT_EXIST) {
      return
    }

    try {
      const response = await apiClient.getSurveyRules()

      dispatch({
        type: FETCH_SURVEY_RULES_SUCCEEDED,
        payload: {
          rules: response.rules || [],
        },
      })
    } catch (error) {
      dispatch({
        type: FETCH_SURVEY_RULES_FAILED,
      })
    }
  }

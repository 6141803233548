import { ReactElement, useEffect } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import useGetSurveys from '../../../api/hooks/use-get-surveys'
import useSurveysApi from '../../../api/hooks/use-surveys-api'
import { SurveysParams } from '../../../consts/routes'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { fetchSurveyRules } from '../../../redux/thunks/survey_rules'
import { fetchSurveySubmissions } from '../../../redux/thunks/surveys'
import { getListItemIds, getListRecordState } from '../../../redux/selectors/lists'
import { ListContentType } from '../../../types/lists'
import { RecordState } from '../../../types/record-state'
import { buildListId } from '../../../utils/lists'
import { EmptyState } from '../../ui/empty-state'
import { ErrorState } from '../../ui/error-state'
import HighlightCard from '../../ui/highlight-card'
import { LoadingIndicator, LoadingIndicatorSize } from '../../ui/loading-indicator'
import { Page } from '../../ui/page'
import { Table, TableBody, TableHeader } from '../../ui/table'
import { PatientView } from '../patients/patient-view'
import { SURVEY_QUERY_UNSEEN } from '../../../types/survey'
import SurveyItem from './survey-item'

const Surveys = (): ReactElement => {
  const { path } = useRouteMatch()
  const { query } = useParams<SurveysParams>()
  const listId = buildListId({
    listContentType: ListContentType.SURVEY_SUBMISSION,
    query,
  })
  const dispatch = useAppDispatch()
  const surveysApi = useSurveysApi()
  const recordState = useAppSelector((state) => getListRecordState(state, listId))
  const itemIds = useAppSelector((state) => {
    return getListItemIds(state, listId)
  })
  const { data, isValidating, error } = useGetSurveys()
  const title = query === SURVEY_QUERY_UNSEEN ? 'New Surveys' : 'Reviewed Surveys'

  useEffect(() => {
    dispatch(fetchSurveySubmissions(listId, surveysApi))
  }, [dispatch, listId, surveysApi])

  useEffect(() => {
    dispatch(fetchSurveyRules())
  }, [dispatch])

  if (error || recordState === RecordState.ERRORED) {
    return (
      <div className="p-12">
        <h1 className="pb-8">{title}</h1>
        <ErrorState header="Failed to load surveys" subtext="Try again in a few minutes" />
      </div>
    )
  }

  const submissionsDoNotExist = recordState === RecordState.DOES_NOT_EXIST
  const surveysLoading = !data && isValidating

  if (submissionsDoNotExist || surveysLoading) {
    return <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
  }

  if (itemIds.length === 0) {
    return (
      <div className="p-12">
        <h1 className="pb-8">{title}</h1>
        <EmptyState header="No surveys found" subtext="Try a different query" />
      </div>
    )
  }

  const activeHeaders = [
    { text: 'Patient', className: 'w-[200px] flex-none' },
    { text: 'Survey response', className: 'flex-1' },
    { text: 'Received', className: 'w-32 flex-none' },
  ]

  const doneHeaders = [
    { text: 'Patient', className: 'w-[200px] flex-none' },
    { text: 'Survey response', className: 'flex-1' },
    { text: 'Reviewed by', className: 'w-[200px] flex-none' },
    { text: 'Reviewed at', className: 'w-28 flex-none' },
  ]

  const headers = query === SURVEY_QUERY_UNSEEN ? activeHeaders : doneHeaders

  return (
    <Page>
      <div className="flex flex-col h-full">
        <h1 className="pb-8">{title}</h1>
        <HighlightCard title="Most Recent">
          <Table>
            <TableHeader headers={headers} />
            <TableBody>
              {itemIds.map((id) => (
                <SurveyItem key={id} id={id} listId={listId} />
              ))}
            </TableBody>
          </Table>
        </HighlightCard>
        <Switch>
          <Route path={`${path}/patient/:patientId`} component={PatientView} />
        </Switch>
      </div>
    </Page>
  )
}

export default Surveys

import React, { DetailedHTMLProps, forwardRef, FunctionComponent, HTMLAttributes } from 'react'
import cn from 'clsx'

export enum IconSize {
  LARGE = 'SIZE_LARGE',
  /* 20px x 20px */
  MEDIUM = 'SIZE_MEDIUM',
  SMALL = 'SIZE_SMALL',
  X_SMALL = 'SIZE_X_SMALL',
}

interface IconProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  Icon: FunctionComponent
  size?: IconSize
}

export const SvgIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ size = IconSize.MEDIUM, Icon, className = '', ...props }, ref) => {
    return (
      <span {...props} className={cn(getSize(size), 'inline-block', className)} ref={ref}>
        <Icon />
      </span>
    )
  },
)

function getSize(size: IconSize) {
  switch (size) {
    case IconSize.X_SMALL:
      return 'h-4 w-4'
    case IconSize.SMALL:
      return 'h-5 w-5'
    case IconSize.MEDIUM:
      return 'h-6 w-6'
    case IconSize.LARGE:
      return 'h-9 w-9'
    default:
      throw new Error('Invalid size passed to SvgIcon')
  }
}

import { produce } from 'immer'
import { Reducer } from 'redux'
import {
  BloodPressureAveragesState,
  FETCH_BLOOD_PRESSURE_AVERAGES_FAILED,
  FETCH_BLOOD_PRESSURE_AVERAGES_FULFILLED,
  FetchBloodPressureAveragesFailed,
  FetchBloodPressureAveragesFulfilled,
  FETCHING_BLOOD_PRESSURE_AVERAGES,
  FetchingBloodPressureAverages,
  BloodPressureAverageStatus,
} from '../../types/blood-pressure-moving-average'
import { RecordState } from '../../types/record-state'

type BloodPressureAverageActions =
  | FetchingBloodPressureAverages
  | FetchBloodPressureAveragesFulfilled
  | FetchBloodPressureAveragesFailed

const bloodPressureAverageState = produce((draft: BloodPressureAveragesState, action: BloodPressureAverageActions) => {
  switch (action.type) {
    case FETCHING_BLOOD_PRESSURE_AVERAGES: {
      const { patientId } = action.payload

      draft[patientId] = {
        recordState: RecordState.LOADING,
        status: BloodPressureAverageStatus.MISSING_DATA,
        movingAverages: [],
      }

      return
    }

    case FETCH_BLOOD_PRESSURE_AVERAGES_FULFILLED: {
      const { patientId, bloodPressureAverages } = action.payload

      draft[patientId] = {
        recordState: RecordState.LOADED,
        status: bloodPressureAverages.status || BloodPressureAverageStatus.MISSING_DATA,
        movingAverages: bloodPressureAverages.data || [],
      }

      return
    }

    case FETCH_BLOOD_PRESSURE_AVERAGES_FAILED: {
      const { error, patientId } = action.payload

      let recordState = RecordState.ERRORED

      // In this case, we do not want to show the UI as if it was a network failure
      // instead, treat it as a success with no data
      if (
        error.message === 'Could not find enough readings to establish an anchor date' ||
        error.message === 'unable to find patient measurements'
      ) {
        recordState = RecordState.LOADED
      }

      draft[patientId] = {
        recordState,
        status: BloodPressureAverageStatus.MISSING_DATA,
        movingAverages: [],
      }

      return
    }

    default:
      return draft
  }
}, {})

export const bloodPressureAverage: Reducer<BloodPressureAveragesState, BloodPressureAverageActions> =
  bloodPressureAverageState

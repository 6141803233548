import { produce } from 'immer'
import { combineReducers, Reducer } from 'redux'
import {
  Action,
  FETCH_TASKS_SUCCEEDED,
  FETCH_TASK_BY_ID_FAILED,
  FETCH_TASK_BY_ID_SUCCEEDED,
  PREFETCH_TASKS_SUCCEEDED,
  TASK_ASSIGNED,
  TASK_ASSIGN_FAILED,
  TASK_ASSIGN_SUCCEEDED,
  TASK_NOTE_ADD_FAILED,
  TASK_NOTE_ADD_REQUESTED,
  TASK_NOTE_ADD_SUCCEEDED,
  TASK_DUE_DATE_CHANGED,
  TASK_DUE_DATE_CHANGE_FAILED,
  TASK_DUE_DATE_CHANGE_SUCCEEDED,
  TASK_OPENED,
  TASK_STATUS_UPDATED,
  TASK_STATUS_UPDATE_FAILED,
  TASK_STATUS_UPDATE_SUCCEEDED,
  TASK_EVENT_UNDO_SUCCEEDED,
  TASK_EVENT_UNDO_FAILED,
} from '../../types/actions'
import { Task, TaskStatus } from '../../types/task'
import { CARE_PATHWAY_CANCEL_REQUESTED } from '../../types/care-pathway'

const byId: Reducer<Record<string, Task>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_TASKS_SUCCEEDED:
    case PREFETCH_TASKS_SUCCEEDED:
      action.payload.tasks.forEach((task) => {
        draft[task.id] = task
      })

      break
    case FETCH_TASK_BY_ID_SUCCEEDED:
      draft[action.payload.id] = action.payload.task
      break
    case TASK_ASSIGNED:
      draft[action.payload.id].assignee = action.payload.assignee

      break
    case TASK_DUE_DATE_CHANGED:
      draft[action.payload.id].dueDate = action.payload.dueDate

      break
    case TASK_STATUS_UPDATED:
      draft[action.payload.id].status = action.payload.status

      break
    case TASK_ASSIGN_SUCCEEDED:
    case TASK_DUE_DATE_CHANGE_SUCCEEDED:
    case TASK_STATUS_UPDATE_SUCCEEDED:
    case TASK_EVENT_UNDO_SUCCEEDED:
      {
        const task = draft[action.payload.id]

        task.events = [...(task.events ?? []), action.payload.event]
      }
      break
    case TASK_NOTE_ADD_REQUESTED:
      {
        const { taskId, temporaryNote } = action.payload
        const task = draft[taskId]

        task.notes = [...(task.notes ?? []), temporaryNote]
      }
      break
    case TASK_NOTE_ADD_FAILED:
      {
        const { taskId, temporaryNote } = action.payload
        const task = draft[taskId]

        task.notes = task.notes?.filter((note) => note.id !== temporaryNote.id)
      }
      break
    case CARE_PATHWAY_CANCEL_REQUESTED:
      {
        const { carePathwayId } = action.payload

        Object.values(draft).forEach((task) => {
          if (task.carePathwayId === carePathwayId) {
            task.status = TaskStatus.TaskCancelled
          }
        })
      }
      break
    default:
      return draft
  }
}, {})

const isStale: Reducer<Record<string, boolean>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case TASK_ASSIGN_SUCCEEDED:
    case TASK_ASSIGN_FAILED:
    case TASK_DUE_DATE_CHANGE_SUCCEEDED:
    case TASK_DUE_DATE_CHANGE_FAILED:
    case TASK_STATUS_UPDATE_SUCCEEDED:
    case TASK_STATUS_UPDATE_FAILED:
    case TASK_EVENT_UNDO_SUCCEEDED:
    case TASK_EVENT_UNDO_FAILED:
    case TASK_OPENED:
      draft[action.payload.id] = true
      break
    case TASK_NOTE_ADD_FAILED:
    case TASK_NOTE_ADD_SUCCEEDED:
      draft[action.payload.taskId] = true
      break
    case FETCH_TASK_BY_ID_SUCCEEDED:
    case FETCH_TASK_BY_ID_FAILED:
      draft[action.payload.id] = false
      break
  }
}, {})

export default combineReducers({
  byId,
  isStale,
})

import { produce } from 'immer'
import { Reducer } from 'redux'
import { Action, TASK_STATUS_UPDATE_FAILED, TASK_STATUS_UPDATE_SUCCEEDED, TOAST_CLOSED } from '../../../types/actions'
import { TASK_CHOICE_SELECT_FAILED, TASK_CHOICE_SELECT_FULFILLED, TaskStatus } from '../../../types/task'
import {
  TASK_CHOICE_SELECT_FAILED_TOAST,
  TASK_CHOICE_SELECT_SUCCESS_TOAST,
  TASK_STATUS_UPDATE_FAILURE_TOAST,
  TASK_STATUS_UPDATE_SUCCESS_TOAST,
  Toast,
} from '../../../types/toast'
import { uuid } from '../../../utils/uuid'

const toasts: Reducer<Toast[], Action> = produce((state = [], action) => {
  switch (action.type) {
    case TOAST_CLOSED:
      {
        const index = state.findIndex(({ id }) => action.payload.id)

        if (index >= 0) {
          state.splice(index, 1)
        }
      }
      break
    case TASK_STATUS_UPDATE_SUCCEEDED:
      if (action.payload.status !== TaskStatus.TaskCompleting && action.payload.status !== TaskStatus.TaskNa) {
        break
      }

      state.push({
        id: uuid(),
        type: TASK_STATUS_UPDATE_SUCCESS_TOAST,
        action,
        timeout: 5000,
      })
      break
    case TASK_STATUS_UPDATE_FAILED:
      state.push({
        id: uuid(),
        type: TASK_STATUS_UPDATE_FAILURE_TOAST,
        action,
        timeout: 3000,
      })
      break
    case TASK_CHOICE_SELECT_FULFILLED:
      state.push({
        id: uuid(),
        type: TASK_CHOICE_SELECT_SUCCESS_TOAST,
        action,
        timeout: 3000,
      })
      break
    case TASK_CHOICE_SELECT_FAILED:
      state.push({
        id: uuid(),
        type: TASK_CHOICE_SELECT_FAILED_TOAST,
        action,
        timeout: 3000,
      })
      break
  }
}, [])

export default toasts

import { AnyAction } from 'redux'
import { ToastState } from '../../types/toast'
import {
  ACTIVATE_PATIENT_FULFILLED,
  CREATE_PATIENT_FULFILLED,
  DEACTIVATE_PATIENT_FULFILLED,
  UPDATE_PATIENT_FULFILLED,
} from '../../types/patient'
import { DOWNLOAD_REPORT_REQUESTED, DOWNLOAD_REPORT_SUCCEEDED } from '../../types/reports'
import { COPY_TEXT_SUCCEEDED } from '../../types/actions'
import {
  CARE_PATHWAY_CANCEL_FULFILLED,
  CARE_PATHWAY_START_FULFILLED,
  CarePathwayCancelFulfilledAction,
  CarePathwayStartFulfilledAction,
} from '../../types/care-pathway'
import { getCarePathwayName } from '../../utils/care-pathway'
import { enrollToStudy, withdrawFromStudy } from '../thunks/study'

let toastId = 1

export function success(state: ToastState = { message: '', id: 0 }, action: AnyAction): ToastState {
  switch (action.type) {
    case UPDATE_PATIENT_FULFILLED:
      return {
        message: 'Successfully updated patient',
        id: toastId++,
      }
    case DEACTIVATE_PATIENT_FULFILLED:
      return {
        message: 'Patient deactivated',
        id: toastId++,
      }
    case ACTIVATE_PATIENT_FULFILLED:
      return {
        message: 'Patient reactivated',
        id: toastId++,
      }
    case CREATE_PATIENT_FULFILLED:
      return {
        message: 'Patient added',
        id: toastId++,
      }
    case DOWNLOAD_REPORT_REQUESTED:
      return {
        message: 'Downloading report...',
        id: toastId++,
      }
    case DOWNLOAD_REPORT_SUCCEEDED:
      return {
        message: '',
        id: toastId++,
      }
    case COPY_TEXT_SUCCEEDED: {
      return {
        message: 'Text copied to clipboard',
        id: toastId++,
      }
    }
    case CARE_PATHWAY_START_FULFILLED:
      return {
        message: `${getCarePathwayName((action as CarePathwayStartFulfilledAction).payload.carePathwayType)} started`,
        id: toastId++,
      }
    case CARE_PATHWAY_CANCEL_FULFILLED:
      return {
        message: `${getCarePathwayName((action as CarePathwayCancelFulfilledAction).payload.carePathwayType)} stopped`,
        id: toastId++,
      }
    case enrollToStudy.fulfilled.type:
      return {
        message: 'Patient enrolled in the Insight Study',
        id: toastId++,
      }
    case withdrawFromStudy.fulfilled.type:
      return {
        message: 'Patient withdrawn from the Insight Study',
        id: toastId++,
      }
    default:
      return state
  }
}

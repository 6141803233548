import { DateTime } from 'luxon'
import { MouseEventHandler, ReactElement, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'
import useGetSurveys from '../../../api/hooks/use-get-surveys'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { getSurveySubmissionById, getSurveySubmissionReviewedBy } from '../../../redux/selectors/surveys'
import { IconSize } from '../../ui/icon'
import { ReactComponent as CheckCircleIcon } from '../../icons/solid/check-circle.svg'
import { ReactComponent as CircleIcon } from '../../icons/solid/circle.svg'
import { markSurveyAsUnseen, markSurveyAsSeen } from '../../../redux/thunks/surveys'
import { ListItem } from '../../../types/lists'
import {
  doesPatientExist,
  getPatientName,
  getPatientPrimaryChatChannel,
  getPatientRecordStateById,
} from '../../../redux/selectors/patient'
import useSurveysApi from '../../../api/hooks/use-surveys-api'
import { parseListId } from '../../../utils/lists'
import { TableRow } from '../../ui/table'
import IconButton from '../../ui/icon-button'
import Tooltip from '../../ui/tooltip'
import useTooltip from '../../../hooks/use-tooltip'
import { ButtonLink, ButtonVariant } from '../../ui/button'
import { getFirstName, getLastName } from '../../../redux/selectors/person'
import { getLoggedInPersonId } from '../../../redux/selectors/app'
import useIsPersonInChannel from '../../../api/hooks/sendbird/use-is-person-in-channel'
import { SURVEY_QUERY_SEEN, SURVEY_QUERY_UNSEEN } from '../../../types/survey'
import { fetchPatientById } from '../../../redux/thunks/patient'
import { RecordState } from '../../../types/record-state'
import SurveyAttachment from './survey-attachment'

type Props = ListItem

const SurveyItem = ({ id, listId }: Props): ReactElement | null => {
  const { inView, ref } = useInView()
  const api = useSurveysApi()
  const dispatch = useAppDispatch()
  const { query } = parseListId(listId)
  const submission = useAppSelector((state) => getSurveySubmissionById(state, id))
  const reviewedBy = useAppSelector((state) => getSurveySubmissionReviewedBy(state, id))
  const resolvedByFirstName = useAppSelector((state) => (reviewedBy ? getFirstName(state, reviewedBy) : ''))
  const resolvedByLastName = useAppSelector((state) => (reviewedBy ? getLastName(state, reviewedBy) : ''))
  const patientId = submission?.patientId ?? ''
  const patientExists = useAppSelector((state) => doesPatientExist(state, patientId))
  const patientRecordState = useAppSelector((state) => getPatientRecordStateById(state, patientId))
  const patientName = useAppSelector((state) => getPatientName(state, patientId) || patientId)
  const patientChatChannel = useAppSelector((state) => getPatientPrimaryChatChannel(state, patientId))
  const practitionerPersonId = useAppSelector((state) => getLoggedInPersonId(state))
  const { inChannel } = useIsPersonInChannel(patientChatChannel, practitionerPersonId)
  const { data: { surveys } = {} } = useGetSurveys()
  const isReviewed = !!reviewedBy
  const handleMarkAsDone = useCallback<MouseEventHandler>(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (query === SURVEY_QUERY_UNSEEN) {
        dispatch(markSurveyAsSeen({ id, listId }, api))
      } else {
        dispatch(markSurveyAsUnseen({ id, listId }, api))
      }
    },
    [api, dispatch, id, listId, query],
  )
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [12, 8],
  })

  useEffect(() => {
    if (inView && patientId) {
      dispatch(fetchPatientById(patientId))
    }
  }, [dispatch, inView, patientId])

  if (!submission) {
    return null
  }

  const surveyName =
    surveys?.find((survey) => survey.id === submission.surveyId)?.name ?? `Survey ${submission.surveyId}`
  const completedAt = DateTime.fromISO(submission.completedAt)
  const iconLabel = isReviewed ? 'Mark as new' : 'Mark as reviewed'

  const patientNameCell = (
    <div role="cell" className="w-[200px] flex-none text-sm font-medium text-rivaOffblack-700" ref={ref}>
      {patientRecordState === RecordState.LOADING || patientRecordState === RecordState.DOES_NOT_EXIST ? (
        <div className="bg-rivaOffblack-200 rounded h-3 w-[92px] animate-pulse"></div>
      ) : (
        <span>{patientName}</span>
      )}
    </div>
  )
  const completedAtCell = (
    <div role="cell" className="w-32 flex-none">
      <time className="flex-none text-rivaOffblack-700 text-sm font-medium px-3">
        {completedAt.diffNow('days').days < -1
          ? completedAt.toLocaleString({
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })
          : completedAt.toRelative()}
      </time>
    </div>
  )
  const submissionCell = (
    <div role="cell" className="text-xs flex-1 flex pl-3">
      <div className="flex-none w-5">
        <IconButton
          icon={isReviewed ? CheckCircleIcon : CircleIcon}
          aria-label={iconLabel}
          onClick={handleMarkAsDone}
          rounded
          size={IconSize.X_SMALL}
          {...getTriggerProps()}
        />
        <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()} className="text-sm font-semibold">
          {iconLabel}
        </Tooltip>
      </div>
      <div role="cell" className="flex-1 ml-5">
        <h3 className="flex-1 text-sm font-medium text-rivaOffblack-900 mb-1">{surveyName}</h3>
        <SurveyAttachment key={id} id={id} />
      </div>
    </div>
  )

  return (
    <TableRow className="group flex justify-items-stretch relative" href={submission.url} ref={ref} target="_blank">
      {query === SURVEY_QUERY_SEEN ? (
        <>
          {patientNameCell}
          {submissionCell}
          <div role="cell" className="w-[200px] flex-none px-3 text-sm font-medium text-rivaOffblack-700">
            {resolvedByFirstName} {resolvedByLastName}
          </div>
          <div role="cell" className="w-28 flex-none">
            <time className="flex-none text-rivaOffblack-700 text-sm font-medium px-3">
              {submission.lastSeenAt
                ? DateTime.fromISO(submission.lastSeenAt).toLocaleString({
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZoneName: 'short',
                  })
                : '-'}
            </time>
          </div>
        </>
      ) : (
        <>
          {patientNameCell}
          {submissionCell}
          {completedAtCell}
        </>
      )}
      {patientExists && (
        <div
          role="cell"
          className={clsx(
            'hidden group-hover:flex absolute top-0 right-0 bottom-0 px-4 items-center justify-end w-[320px]',
            {
              'bg-white': !submission.url,
              'bg-rivaOffblack-50': !!submission.url,
            },
          )}
        >
          <ButtonLink
            to={`/surveys/${parseListId(listId).query}/patient/${patientId}?${submission ? 'surveys=1' : ''}`}
            variant={ButtonVariant.SECONDARY}
          >
            View profile
          </ButtonLink>
          {inChannel && (
            <ButtonLink className="ml-3" to={`/chat/${patientChatChannel}`}>
              Message patient
            </ButtonLink>
          )}
        </div>
      )}
    </TableRow>
  )
}

export default SurveyItem

import { AuditStatus } from './audit-status'

export enum SurveySubmissionStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SENT = 'SENT',
  TIMED_OUT = 'TIMED_OUT',
  UNKNOWN = '',
}

export enum SurveySubmissionEventType {
  MARKED_AS_SEEN = 'marked_as_seen',
  MARKED_AS_UNSEEN = 'marked_as_unseen',
}

export const SURVEY_QUERY_UNSEEN = 'new'
export const SURVEY_QUERY_SEEN = 'reviewed'

export interface SurveySubmissionEvent {
  type: string
  at: string
  by: string
}

export interface SurveySubmission {
  completedAt: string
  createdAt?: string
  data?: Record<string, unknown>
  events?: SurveySubmissionEvent[] | null
  id: string
  lastSeenAt?: string
  patientId: string
  sentAt?: string
  startedAt: string
  status?: SurveySubmissionStatus
  seenBy?: string[] | null
  submissionId: number
  surveyId: number
  url?: string
}

export interface Survey {
  id: number
  name: string
}

export enum SurveyRuleStyle {
  NONE = 'none',
  HIGHLIGHT = 'highlight',
  ALERT = 'alert',
}

export interface SurveyRule {
  created: AuditStatus
  field: string
  expression: string
  format?: string
  id: string
  message: string
  style: SurveyRuleStyle
  surveyId: number
  updated: AuditStatus
}

export interface GetSurveyRulesResponse {
  rules: SurveyRule[] | null
}

import {
  ACTIVATE_PATIENT_FAILED,
  ACTIVATE_PATIENT_FORM_INITIALIZED,
  ACTIVATE_PATIENT_FULFILLED,
  ActivatePatientFailed,
  ActivatePatientFormInitialized,
  ActivatePatientFulfilled,
  ACTIVATING_PATIENT,
  ActivatingPatient,
  CREATE_PATIENT_FAILED,
  CREATE_PATIENT_FORM_INITIALIZED,
  CREATE_PATIENT_FULFILLED,
  CreatePatientFailed,
  CreatePatientFormInitialized,
  CreatePatientFulfilled,
  CREATING_PATIENT,
  CreatingPatient,
  DEACTIVATE_PATIENT_FAILED,
  DEACTIVATE_PATIENT_FORM_INITIALIZED,
  DEACTIVATE_PATIENT_FULFILLED,
  DeactivatePatientFailed,
  DeactivatePatientFormInitialized,
  DeactivatePatientFulfilled,
  DEACTIVATING_PATIENT,
  DeactivatingPatient,
  FETCH_PATIENTS_FAILED,
  FETCH_PATIENTS_FULFILLED,
  FETCHING_PATIENTS,
  FetchingPatientsAction,
  Patient,
  UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FORM_INITIALIZED,
  UPDATE_PATIENT_FAILED,
  UPDATE_PATIENT_FORM_INITIALIZED,
  UPDATE_PATIENT_FULFILLED,
  UpdateBloodPressureManagementStatusFormInitialized,
  UpdatePatientFailed,
  UpdatePatientFormInitialized,
  UpdatePatientFulfilled,
  UPDATING_PATIENT,
  UpdatingPatient,
  FetchPatientsFulfilledAction,
  FetchPatientsFailedAction,
} from '../../types/patient'
import { Note } from '../../types/note'
import {
  ActionCreator,
  FetchPatientByChatChannelFailedAction,
  FetchPatientByChatChannelRequestedAction,
  FetchPatientByChatChannelSucceededAction,
  FetchPatientByIdFailedAction,
  FetchPatientByIdRequestedAction,
  FetchPatientByIdSucceededAction,
  FETCH_PATIENT_BY_CHAT_CHANNEL_FAILED,
  FETCH_PATIENT_BY_CHAT_CHANNEL_REQUESTED,
  FETCH_PATIENT_BY_CHAT_CHANNEL_SUCCEEDED,
  FETCH_PATIENT_BY_ID_FAILED,
  FETCH_PATIENT_BY_ID_REQUESTED,
  FETCH_PATIENT_BY_ID_SUCCEEDED,
} from '../../types/actions'

export const fetchingPatients: ActionCreator<FetchingPatientsAction> = (payload) => ({
  type: FETCHING_PATIENTS,
  payload,
})

export const fetchPatientsFulfilled: ActionCreator<FetchPatientsFulfilledAction> = (payload) => ({
  type: FETCH_PATIENTS_FULFILLED,
  payload,
})

export const fetchPatientsFailed: ActionCreator<FetchPatientsFailedAction> = (payload) => ({
  type: FETCH_PATIENTS_FAILED,
  payload,
})

export const fetchPatientByIdRequested: ActionCreator<FetchPatientByIdRequestedAction> = (payload) => ({
  type: FETCH_PATIENT_BY_ID_REQUESTED,
  payload,
})

export const fetchPatientByIdSucceeded: ActionCreator<FetchPatientByIdSucceededAction> = (payload) => ({
  type: FETCH_PATIENT_BY_ID_SUCCEEDED,
  payload,
})

export const fetchPatientByIdFailed: ActionCreator<FetchPatientByIdFailedAction> = (payload) => ({
  type: FETCH_PATIENT_BY_ID_FAILED,
  payload,
})

export const fetchPatientByChatChannelRequested: ActionCreator<FetchPatientByChatChannelRequestedAction> = (
  payload,
) => ({
  type: FETCH_PATIENT_BY_CHAT_CHANNEL_REQUESTED,
  payload,
})

export const fetchPatientByChatChannelSucceeded: ActionCreator<FetchPatientByChatChannelSucceededAction> = (
  payload,
) => ({
  type: FETCH_PATIENT_BY_CHAT_CHANNEL_SUCCEEDED,
  payload,
})

export const fetchPatientByChatChannelFailed: ActionCreator<FetchPatientByChatChannelFailedAction> = (payload) => ({
  type: FETCH_PATIENT_BY_CHAT_CHANNEL_FAILED,
  payload,
})

export function creatingPatient(): CreatingPatient {
  return {
    type: CREATING_PATIENT,
    payload: undefined,
  }
}

export const createPatientFulfilled: ActionCreator<CreatePatientFulfilled> = (payload) => ({
  type: CREATE_PATIENT_FULFILLED,
  payload,
})

export function createPatientFailed(error: Error): CreatePatientFailed {
  return {
    type: CREATE_PATIENT_FAILED,
    payload: {
      error,
    },
  }
}

export function createPatientFormInitialized(): CreatePatientFormInitialized {
  return {
    type: CREATE_PATIENT_FORM_INITIALIZED,
    payload: undefined,
  }
}

export function updatingPatient(): UpdatingPatient {
  return {
    type: UPDATING_PATIENT,
    payload: undefined,
  }
}

export function updatePatientFulfilled(patient: Patient): UpdatePatientFulfilled {
  return {
    type: UPDATE_PATIENT_FULFILLED,
    payload: {
      patient,
    },
  }
}

export function updatePatientFailed(error: Error): UpdatePatientFailed {
  return {
    type: UPDATE_PATIENT_FAILED,
    payload: {
      error,
    },
  }
}

export function updatePatientFormInitialized(): UpdatePatientFormInitialized {
  return {
    type: UPDATE_PATIENT_FORM_INITIALIZED,
    payload: undefined,
  }
}

export function deactivatingPatient(): DeactivatingPatient {
  return {
    type: DEACTIVATING_PATIENT,
    payload: undefined,
  }
}

export function deactivatePatientFulfilled(patient: Patient, note: Note): DeactivatePatientFulfilled {
  return {
    type: DEACTIVATE_PATIENT_FULFILLED,
    payload: {
      note,
      patient,
    },
  }
}

export function deactivatePatientFailed(error: Error): DeactivatePatientFailed {
  return {
    type: DEACTIVATE_PATIENT_FAILED,
    payload: {
      error,
    },
  }
}

export function deactivatePatientFormInitialized(): DeactivatePatientFormInitialized {
  return {
    type: DEACTIVATE_PATIENT_FORM_INITIALIZED,
    payload: undefined,
  }
}

export function activatingPatient(): ActivatingPatient {
  return {
    type: ACTIVATING_PATIENT,
    payload: undefined,
  }
}

export function activatePatientFulfilled(patient: Patient, note: Note): ActivatePatientFulfilled {
  return {
    type: ACTIVATE_PATIENT_FULFILLED,
    payload: {
      note,
      patient,
    },
  }
}

export function activatePatientFailed(error: Error): ActivatePatientFailed {
  return {
    type: ACTIVATE_PATIENT_FAILED,
    payload: {
      error,
    },
  }
}

export function activatePatientFormInitialized(): ActivatePatientFormInitialized {
  return {
    type: ACTIVATE_PATIENT_FORM_INITIALIZED,
    payload: undefined,
  }
}

export function updateBloodPressureMeasurementStatusFormInitialized(): UpdateBloodPressureManagementStatusFormInitialized {
  return {
    type: UPDATE_BLOOD_PRESSURE_MEASUREMENT_STATUS_FORM_INITIALIZED,
    payload: undefined,
  }
}

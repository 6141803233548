import React, { ReactElement, ReactNode } from 'react'

interface PageProps {
  children: ReactNode
}

export function Page({ children }: PageProps): ReactElement {
  return (
    <div className="w-full h-full flex justify-center">
      <div className="w-1280 p-12 flex-col h-full">{children}</div>
    </div>
  )
}

interface PageHeaderProps {
  'data-testid'?: string
  children: ReactNode
}
export function PageHeader({ children, ...rest }: PageHeaderProps): ReactElement {
  return (
    <div className="w-full flex justify-between mb-8" data-testid={rest['data-testid']}>
      {children}
    </div>
  )
}

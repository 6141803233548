export const DOWNLOAD_REPORT_REQUESTED = 'DOWNLOAD_REPORT_REQUESTED'
export const DOWNLOAD_REPORT_SUCCEEDED = 'DOWNLOAD_REPORT_SUCCEEDED'
export const DOWNLOAD_REPORT_FAILED = 'DOWNLOAD_REPORT_FAILED'

export interface DownloadAllPatientBpReportRequested {
  type: typeof DOWNLOAD_REPORT_REQUESTED
}

export interface DownloadAllPatientBpReportSucceeded {
  type: typeof DOWNLOAD_REPORT_SUCCEEDED
}

export interface DownloadAllPatientBpReportFailed {
  type: typeof DOWNLOAD_REPORT_FAILED
  payload: {
    error: {
      message: string
    }
  }
}

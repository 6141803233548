import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { MenuItem } from '../../../../ui/menu'
import { CarePathwayType } from '../../../../../__generated__/graphql'
import { buildOpenCarePathwaysListId } from '../../../../../utils/lists'
import { useAppDispatch, useAppSelector } from '../../../../../redux'
import { getListRecordState, isListStale } from '../../../../../redux/selectors/lists'
import { RecordState } from '../../../../../types/record-state'
import { fetchCarePathways } from '../../../../../redux/thunks/care-pathway'
import { findOpenCarePathwayByType } from '../../../../../redux/selectors/care-pathway'
import { track } from '../../../../../i13n'
import StopLifestyleChangeModal from './stop-lifestyle-change-modal'
import StartLifestyleChangeModal from './start-lifestyle-change-modal'

interface Props {
  patientId: string
}

const LifestyleChangeMenuItem = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const apolloClient = useApolloClient()
  const [wasLoaded, setWasLoaded] = useState(false)
  const listId = useMemo(() => buildOpenCarePathwaysListId(patientId, [CarePathwayType.LifestyleChange]), [patientId])
  const [openModal, setOpenModal] = useState<'start' | 'stop' | undefined>()
  const lifestyleCarePathway = useAppSelector((state) =>
    findOpenCarePathwayByType(state, patientId, CarePathwayType.LifestyleChange),
  )
  const isStale = useAppSelector((state) => isListStale(state, listId))
  const recordState = useAppSelector((state) => getListRecordState(state, listId))
  const disabled = isStale || recordState === RecordState.ERRORED || (!wasLoaded && recordState !== RecordState.LOADED)

  const closeModal = useCallback(() => setOpenModal(undefined), [])
  const onClick = useCallback(() => {
    setOpenModal(lifestyleCarePathway ? 'stop' : 'start')

    track(lifestyleCarePathway ? 'Stop Lifestyle Change Clicked' : 'Start Lifestyle Change Clicked')
  }, [lifestyleCarePathway])

  useEffect(() => {
    dispatch(fetchCarePathways(listId, apolloClient))

    const interval = setInterval(() => dispatch(fetchCarePathways(listId, apolloClient)), 10000)

    return () => {
      clearInterval(interval)
    }
  }, [apolloClient, dispatch, listId])

  useEffect(() => {
    if (isStale) {
      dispatch(fetchCarePathways(listId, apolloClient))
    }
  }, [apolloClient, dispatch, isStale, listId])

  useEffect(() => {
    if (recordState === RecordState.LOADED || recordState === RecordState.ERRORED) {
      setWasLoaded(true)
    }
  }, [recordState])

  return (
    <>
      <MenuItem className="capitalize" disabled={disabled} onClick={onClick}>
        {lifestyleCarePathway ? 'Stop' : 'Start'} lifestyle change
      </MenuItem>
      <StartLifestyleChangeModal
        isOpen={openModal === 'start'}
        listId={listId}
        onClose={closeModal}
        patientId={patientId}
      />
      <StopLifestyleChangeModal
        carePathwayId={lifestyleCarePathway?.id ?? ''}
        isOpen={openModal === 'stop'}
        listId={listId}
        onClose={closeModal}
        patientId={patientId}
      />
    </>
  )
}

export default LifestyleChangeMenuItem

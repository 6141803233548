import { ReactElement, useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router'
import { Button, ButtonVariant } from '../ui/button'
import { Modal } from '../ui/modal'
import { ModalContainer } from '../ui/modal/modal-container'
import { ReactComponent as IconTrashCan } from '../icons/outline/trash.svg'
import { IconSize, SvgIcon } from '../ui/icon'
import { FormIds, FormSubmissionState } from '../../types/form'
import { getFormSubmissionState } from '../../redux/selectors/form'
import { LoadingIndicator, LoadingIndicatorPosition } from '../ui/loading-indicator'
import { RootRoutes } from '../../consts/routes'
import { DEACTIVATE_PATIENT_SUBMIT } from '../../consts/data-testids'
import { getPatientName } from '../../redux/selectors/patient'
import { deactivatePatientFormInitialized } from '../../redux/actions/patient'
import { deactivatePatient } from '../../redux/thunks/patient'
import { useAppDispatch, useAppSelector } from '../../redux'
import ModalHeader from '../ui/modal/modal-header'
import FieldSet from '../ui/field-set'
import TextInput from '../ui/text-input'
import FormLabel from '../ui/form-label'
import TextArea from '../ui/text-area'
import ModalFooter from '../ui/modal/modal-footer'

interface DeactivatePatientModalProps {
  isOpen: boolean
  close: () => void
  patientId: string
}

export function DeactivatePatientModal({ isOpen, close, patientId }: DeactivatePatientModalProps): ReactElement {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const name = useAppSelector((state) => getPatientName(state, patientId))
  const [confirmationText, setConfirmationText] = useState('')
  const [noteText, setNoteText] = useState('')
  const deactivatePatientFormState = useAppSelector((state) =>
    getFormSubmissionState(state, FormIds.DEACTIVATE_PATIENT),
  )

  useEffect(() => {
    if (isOpen) {
      dispatch(deactivatePatientFormInitialized())
    }
  }, [isOpen, dispatch])

  useEffect(() => {
    if (deactivatePatientFormState === FormSubmissionState.SUBMITTED) {
      // need to initialize it after submitting,
      // otherwise the form will close immediately when this is run after a successful submission
      dispatch(deactivatePatientFormInitialized())
      history.push(generatePath(RootRoutes.PATIENTS, {}))
      close()
    }
  }, [deactivatePatientFormState, close, dispatch, history])

  return (
    <Modal isOpen={isOpen} closeModal={close}>
      <ModalContainer>
        <ModalHeader
          subtitle="Deactivating a patient's account will make them unable to use the app and remove them from care. Tread
          carefully"
          title={`Deactivate ${name}'s account?`}
        ></ModalHeader>

        {deactivatePatientFormState === FormSubmissionState.ERRORED && (
          <p className="text-rivaRed mb-2">
            Sorry, we failed to deactivate the patient. Please refresh the page and contact support if the problem
            persists.
          </p>
        )}

        <form
          className="mx-10"
          onSubmit={(e) => {
            e.preventDefault()
            dispatch(deactivatePatient(patientId, noteText))
          }}
        >
          <FieldSet className="pt-5">
            <TextArea
              className="resize-none"
              id="deactivationReason"
              onChange={(event) => setNoteText(event.target.value)}
              required
              rows={5}
              value={noteText}
            />
            <FormLabel className="normal-case" htmlFor="deactivationReason" required>
              Tell us why the patient is getting deactivated
            </FormLabel>
          </FieldSet>

          <FieldSet className="pt-5">
            <TextInput
              id="confirmationText"
              onChange={(event) => setConfirmationText(event.target.value)}
              pattern="[dD]{1}[eE]{1}[aA]{1}[cC]{1}[tT]{1}[iI]{1}[vV]{1}[aA]{1}[tT]{1}[eE]{1}"
              required
              value={confirmationText}
            />
            <FormLabel className="normal-case" htmlFor="confirmationText" required>
              Type "Deactivate" to confirm
            </FormLabel>
          </FieldSet>

          <p className="text-sm text-rivaOffblack-400 my-5">
            The patient's records are retained when their account is deactivated. They can be re-activated later in the
            event of an error.
          </p>

          {deactivatePatientFormState === FormSubmissionState.SUBMITTING ? (
            <LoadingIndicator position={LoadingIndicatorPosition.LEFT} className="ml-4 mt-4 mb-6" />
          ) : (
            <ModalFooter>
              <Button className="mr-3" variant={ButtonVariant.SECONDARY} onClick={close} type="button">
                Cancel
              </Button>
              <Button
                data-testid={DEACTIVATE_PATIENT_SUBMIT}
                variant={ButtonVariant.ERROR}
                disabled={confirmationText.toUpperCase().trim() !== 'DEACTIVATE'}
              >
                <SvgIcon className="mr-1" Icon={IconTrashCan} size={IconSize.SMALL} />
                Deactivate {name}
              </Button>
            </ModalFooter>
          )}
        </form>
      </ModalContainer>
    </Modal>
  )
}

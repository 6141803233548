import {
  ReactElement,
  forwardRef,
  ReactNode,
  useContext,
  DetailedHTMLProps,
  HTMLAttributes,
  MouseEventHandler,
} from 'react'
import cn, { clsx } from 'clsx'
import { NavLink } from 'react-router-dom'
import { TableThemeContext, TableTheme } from './theme'

interface TableProps {
  children: ReactNode
  className?: string
  theme?: TableTheme
}

export function Table({ children, className, theme = TableTheme.LIGHT }: TableProps): ReactElement {
  return (
    <TableThemeContext.Provider value={theme}>
      <ul className={clsx('flex flex-col', className)} role="table">
        {children}
      </ul>
    </TableThemeContext.Provider>
  )
}

interface TableHeaderProps {
  className?: string
  'data-testid'?: string
  headers: Header[]
}

interface Header {
  text: ReactNode
  subtext?: string
  className?: string
  doNotAutoSize?: boolean
}

export function TableHeader({ className, headers, ...props }: TableHeaderProps): ReactElement {
  const theme = useContext(TableThemeContext)

  return (
    <>
      <li data-testid={props['data-testid']} className={clsx('pb-2', className)} role="row">
        <div
          className={clsx('flex w-full text-xxs rounded px-4 tracking-wider', {
            'bg-rivaOffblack-100 text-rivaBlack': theme === TableTheme.LIGHT,
            'bg-rivaOffblack-700 text-white': theme === TableTheme.DARK,
          })}
        >
          {headers.map((header) => {
            const key = header.text + (header.subtext || '')

            return (
              <div key={key} className={cn(header.className || 'flex-1', 'group')} role="columnheader">
                <div className="px-3 group-first:px-0 py-2 group-last:pr-0">
                  <p className="font-extrabold uppercase">{header.text}</p>
                  {header.subtext ? <p className="font-semibold text-rivaOffblack-500">{header.subtext}</p> : null}
                </div>
              </div>
            )
          })}
        </div>
      </li>
    </>
  )
}

type TableBodyProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>, 'role'>

export function TableBody({ children, className, ...props }: TableBodyProps): ReactElement {
  return (
    <ul {...props} role="rowgroup" className={clsx('flex flex-col items-stretch', className)}>
      {children}
    </ul>
  )
}

interface TableRowProps {
  className?: string
  onClick?: MouseEventHandler
  to?: string
  href?: string
  'data-testid'?: string
  children: ReactNode
  target?: string
  title?: string
}

const liStyles = 'list-none border-b tabular-nums hover:rounded group'

const linkStyles = 'flex w-full rounded outline-hidden px-4 py-1 outline-offset-0'
const linkHoverStyles =
  'group-hover:bg-rivaOffblack-50 group-hover:outline group-hover:outline-1 group-hover:outline-rivaOffblack-200'
const linkFocusStyles =
  'focus-visible:outline focus-visible:outline-1 focus-visible:outline-rivaOffblack-900 focus-visible:border-[3px] focus-visible:border-rivaOffblack-900 focus-visible:px-[13px] focus-visible:py-[1px]'
const linkActiveStyles =
  'bg-rivaOffblack-50 outline outline-1 outline-rivaOffblack-400 group-hover:bg-rivaOffblack-50 group-hover:outline-rivaOffblack-400'

export const TableRow = forwardRef<HTMLLIElement, TableRowProps>(function TableRow({ onClick, ...props }, ref) {
  const theme = useContext(TableThemeContext)

  const borderStyles = {
    'border-b-rivaOffblack-100/100 last:border-b-rivaOffblack-100/0': theme === TableTheme.LIGHT,
    'border-b-rivaOffblack-700/100 last:border-b-rivaOffblack-700/0 text-white': theme === TableTheme.DARK,
  }

  if (props.to) {
    return (
      <li
        data-testid={props['data-testid']}
        ref={ref}
        role="row"
        className={cn(liStyles, borderStyles, props.className)}
      >
        <NavLink
          to={props.to}
          className={cn(linkStyles, linkHoverStyles, linkFocusStyles)}
          onClick={onClick}
          activeClassName={linkActiveStyles}
          title={props.title}
        >
          {props.children}
        </NavLink>
      </li>
    )
  }

  if (props.href) {
    return (
      <li
        data-testid={props['data-testid']}
        ref={ref}
        role="row"
        className={cn(liStyles, borderStyles, props.className)}
      >
        <a
          href={props.href}
          rel="noreferrer"
          className={cn(linkStyles, linkHoverStyles, linkFocusStyles)}
          onClick={onClick}
          target={props.target}
          title={props.title}
        >
          {props.children}
        </a>
      </li>
    )
  }

  return (
    <li
      data-testid={props['data-testid']}
      ref={ref}
      className={cn(liStyles, borderStyles, 'px-4 py-3', props.className)}
      onClick={onClick}
      role="row"
      title={props.title}
    >
      {props.children}
    </li>
  )
})

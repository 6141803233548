import { MouseEventHandler, ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import useGetTaskVisits, { Options } from '../../../../api/hooks/use-get-task-visits'
import { useAppSelector } from '../../../../redux'
import { getTaskById } from '../../../../redux/selectors/tasks'
import { SvgIcon } from '../../../ui/icon'
import { ReactComponent as CalendarIcon } from '../../../icons/outline/calendar.svg'
import { ReactComponent as ClockIcon } from '../../../icons/outline/clock.svg'
import { ReactComponent as VideoCameraIcon } from '../../../icons/outline/video-camera.svg'
import FormattedDateTime from '../../../ui/formatted-date-time'
import { WEB_URL_PATTERN } from '../../../../utils/url'
import { TaskType } from '../../../../types/task'

interface Props {
  taskId: string
}

const TaskVisits = ({ taskId }: Props): ReactElement | null => {
  const task = useAppSelector((state) => getTaskById(state, taskId))
  const options: Options | null =
    task?.taskType === TaskType.COMPLETE_PHARMD_VISIT ||
    task?.taskType === TaskType.COMPLETE_PRESCRIBER_VISIT ||
    task?.taskType === TaskType.COMPLETE_LIFESTYLE_VISIT
      ? {
          taskId,
        }
      : null

  const { data, isValidating } = useGetTaskVisits(options)

  if (!task || !options || (!data?.totalCount && !isValidating)) {
    return null
  }

  const isLoading = !data && isValidating

  const onLinkClick: MouseEventHandler = (event) => {
    event.stopPropagation()
  }

  return (
    <div
      className={clsx('border-t border-dashed border-rivaOffblack-300 mt-4 pt-4', {
        'animate-pulse': isLoading,
      })}
    >
      {isLoading ? (
        <dl className="grid grid-cols-[24px_1fr] gap-x-2 gap-y-3 text-sm font-medium text-rivaOffblack-700">
          <dt className="h-6 text-rivaOffblack-400">
            <SvgIcon Icon={CalendarIcon} title="Date" />
          </dt>
          <dd className="h-6">
            <span className="inline-block bg-rivaOffblack-300 rounded-full w-40 h-4 my-1"></span>
          </dd>
          <dt className="h-6 text-rivaOffblack-400">
            <SvgIcon Icon={ClockIcon} title="Time" />
          </dt>
          <dd className="h-6">
            <span className="inline-block bg-rivaOffblack-300 rounded-full w-40 h-4 my-1"></span>
          </dd>
        </dl>
      ) : null}
      {data?.results.map(({ endTime, id, location, startTime }) => {
        const match = location ? location.match(WEB_URL_PATTERN) : null

        function toLinks(str: string, matches: string[]): ReactNode[] {
          if (!str || !matches.length) {
            return [str]
          }

          const [m, ...rest] = matches
          const parts = str.split(m)
          const last = parts.pop()

          const result: ReactNode[] = []

          parts.forEach((part) => {
            result.push(
              part,
              <a
                key={m}
                className="text-rivaBlue-500 visited:text-rivaPurple-500 hover:underline"
                href={m}
                onClick={onLinkClick}
                rel="noreferrer"
                target="_blank"
              >
                {m}
              </a>,
            )
          })

          return [...result, ...toLinks(last ?? '', rest)]
        }

        const video = toLinks(location ?? '', match ?? [])

        return (
          <dl
            className="grid grid-cols-[24px_1fr] gap-x-2 gap-y-3 text-sm font-medium text-rivaOffblack-700"
            data-visitid={id}
            key={id}
          >
            <dt className="h-6 text-rivaRed-400">
              <SvgIcon Icon={CalendarIcon} title="Date" />
            </dt>
            <dd>
              <FormattedDateTime value={startTime} weekday="long" day="numeric" month="long" year="numeric" />
            </dd>
            <dt className="h-6 text-rivaBlue-400">
              <SvgIcon Icon={ClockIcon} title="Time" />
            </dt>
            <dd>
              <FormattedDateTime value={startTime} hour12 hour="numeric" minute="numeric" />
              -
              <FormattedDateTime value={endTime} hour12 hour="numeric" minute="numeric" timeZoneName="long" />
            </dd>
            {location && (
              <>
                <dt className="text-rivaOffblack-500">
                  <SvgIcon Icon={VideoCameraIcon} title="Time" />
                </dt>
                <dd className="break-words overflow-hidden whitespace-pre-wrap">{video}</dd>
              </>
            )}
          </dl>
        )
      })}
    </div>
  )
}

export default TaskVisits

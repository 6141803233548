import {
  FetchAllergyIntoleranceFailedAction,
  FetchAllergyIntoleranceRequestedAction,
  FetchAllergyIntoleranceSucceededAction,
} from '../redux/actions/allergy-intolerance'
import {
  FetchBloodPressureMeasurementsFailed,
  FetchBloodPressureMeasurementsFulfilled,
  FetchingBloodPressureMeasurements,
} from './blood-pressure-measurement'
import {
  CarePathwayCancelFailedAction,
  CarePathwayCancelFulfilledAction,
  CarePathwayCancelRequestedAction,
  CarePathwayStartFailedAction,
  CarePathwayStartFulfilledAction,
  CarePathwayStartRequestedAction,
  FetchCarePathwaysFailedAction,
  FetchCarePathwaysFulfilledAction,
  FetchCarePathwaysRequestedAction,
} from './care-pathway'
import { AssignDeviceToPatientSucceededAction } from './device'
import { ListItem } from './lists'
import {
  ActivatePatientFulfilled,
  CreatePatientFulfilled,
  DeactivatePatientFulfilled,
  FetchingPatientsAction,
  FetchPatientsFailedAction,
  FetchPatientsFulfilledAction,
  Patient,
  UpdatePatientFulfilled,
} from './patient'
import { FETCHING_PERSONS, FETCH_PERSONS_FAILED, FETCH_PERSONS_FULFILLED, Person } from './person'
import {
  FETCHING_PRACTITIONER,
  FETCH_PRACTITIONER_FAILED,
  FETCH_PRACTITIONER_FULFILLED,
  PractitionerResponse,
} from './practitioner'
import { SurveyRule, SurveySubmission } from './survey'
import {
  Task,
  TaskNote,
  TaskEvent,
  TaskStatus,
  FetchTaskCarePathwayRequestedAction,
  FetchTaskCarePathwayFulfilledAction,
  FetchTaskCarePathwayFailedAction,
  TaskChoiceSelectRequestedAction,
  TaskChoiceSelectFulfilledAction,
  TaskChoiceSelectFailedAction,
} from './task'

export const FETCH_SURVEY_RULES_SUCCEEDED = 'FETCH_SURVEY_RULES_SUCCEEDED'
export const FETCH_SURVEY_RULES_FAILED = 'FETCH_SURVEY_RULES_FAILED'

export interface FetchSurveyRulesSucceededAction {
  type: typeof FETCH_SURVEY_RULES_SUCCEEDED
  payload: {
    rules: SurveyRule[]
  }
}

export interface FetchSurveyRulesFailedAction {
  type: typeof FETCH_SURVEY_RULES_FAILED
  payload: Record<string, never>
}

export const FETCH_SURVEY_SUBMISSIONS_REQUESTED = 'FETCH_SURVEY_SUBMISSIONS_REQUESTED'
export const FETCH_SURVEY_SUBMISSIONS_SUCCEEDED = 'FETCH_SURVEY_SUBMISSIONS_SUCCEEDED'
export const FETCH_SURVEY_SUBMISSIONS_FAILED = 'FETCH_SURVEY_SUBMISSIONS_FAILED'
export const SURVEY_MARKED_AS_SEEN = 'SURVEY_MARKED_AS_SEEN'
export const SURVEY_MARKED_AS_SEEN_SUCCEEDED = 'SURVEY_MARKED_AS_SEEN_SUCCEEDED'
export const SURVEY_MARKED_AS_SEEN_FAILED = 'SURVEY_MARKED_AS_SEEN_FAILED'
export const SURVEY_MARKED_AS_UNSEEN = 'SURVEY_MARKED_AS_UNSEEN'
export const SURVEY_MARKED_AS_UNSEEN_SUCCEEDED = 'SURVEY_MARKED_AS_UNSEEN_SUCCEEDED'
export const SURVEY_MARKED_AS_UNSEEN_FAILED = 'SURVEY_MARKED_AS_UNSEEN_FAILED'

export interface FetchSurveySubmissionsRequestedAction {
  type: typeof FETCH_SURVEY_SUBMISSIONS_REQUESTED
  payload: {
    listId: string
  }
}

export interface FetchSurveySubmissionsSucceededAction {
  type: typeof FETCH_SURVEY_SUBMISSIONS_SUCCEEDED
  payload: {
    listId: string
    surveySubmissions: SurveySubmission[]
    totalCount: number
  }
}

export interface FetchSurveySubmissionsFailedAction {
  type: typeof FETCH_SURVEY_SUBMISSIONS_FAILED
  payload: {
    listId: string
  }
}

export interface SurveyMarkedAsSeenAction {
  type: typeof SURVEY_MARKED_AS_SEEN
  payload: ListItem & {
    personId: string
  }
}

export interface SurveyMarkedAsSeenSucceededAction {
  type: typeof SURVEY_MARKED_AS_SEEN_SUCCEEDED
  payload: ListItem
}

export interface SurveyMarkedAsSeenFailedAction {
  type: typeof SURVEY_MARKED_AS_SEEN_FAILED
  payload: ListItem
}

export interface SurveyMarkedAsUnseenAction {
  type: typeof SURVEY_MARKED_AS_UNSEEN
  payload: ListItem & {
    personId: string
  }
}

export interface SurveyMarkedAsUnseenSucceededAction {
  type: typeof SURVEY_MARKED_AS_UNSEEN_SUCCEEDED
  payload: ListItem
}

export interface SurveyMarkedAsUnseenFailedAction {
  type: typeof SURVEY_MARKED_AS_UNSEEN_FAILED
  payload: ListItem
}

export interface FetchingPersonsAction {
  type: typeof FETCHING_PERSONS
  payload: Record<string, never>
}

export interface FetchPersonsFulfilledAction {
  type: typeof FETCH_PERSONS_FULFILLED
  payload: {
    persons: Person[]
  }
}

export interface FetchPersonsFailedAction {
  type: typeof FETCH_PERSONS_FAILED
  payload: {
    error: Error
  }
}

export interface FetchingPractitionerAction {
  type: typeof FETCHING_PRACTITIONER
  payload: Record<string, never>
}

export interface FetchPractitionerFulfilledAction {
  type: typeof FETCH_PRACTITIONER_FULFILLED
  payload: PractitionerResponse
}

export interface FetchPractitionerFailedAction {
  type: typeof FETCH_PRACTITIONER_FAILED
  payload: {
    error: Error
  }
}

export const FETCH_TASKS_REQUESTED = 'FETCH_TASKS_REQUESTED'
export const FETCH_TASKS_FAILED = 'FETCH_TASKS_FAILED'
export const FETCH_TASKS_SUCCEEDED = 'FETCH_TASKS_SUCCEEDED'

export interface FetchTasksRequestedAction {
  type: typeof FETCH_TASKS_REQUESTED
  payload: {
    listId: string
  }
}

export interface FetchTasksFailedAction {
  type: typeof FETCH_TASKS_FAILED
  payload: {
    listId: string
  }
}

export interface FetchTasksSucceededAction {
  type: typeof FETCH_TASKS_SUCCEEDED
  payload: {
    hash: string
    listId: string
    startIndex: number
    tasks: Task[]
    totalCount: number
  }
}

export const FETCH_TASK_BY_ID_SUCCEEDED = 'FETCH_TASK_BY_ID_SUCCEEDED'
export const FETCH_TASK_BY_ID_FAILED = 'FETCH_TASK_BY_ID_FAILED'

export interface FetchTaskByIdSucceededAction {
  type: typeof FETCH_TASK_BY_ID_SUCCEEDED
  payload: {
    id: string
    task: Task
  }
}

export interface FetchTaskByIdFailedAction {
  type: typeof FETCH_TASK_BY_ID_FAILED
  payload: {
    id: string
  }
}

export const PREFETCH_TASKS_SUCCEEDED = 'PREFETCH_TASKS_SUCCEEDED'

export interface PrefetchTasksSucceededAction {
  type: typeof PREFETCH_TASKS_SUCCEEDED
  payload: {
    hash: string
    listId: string
    tasks: Task[]
    totalCount: number
  }
}

export const LIST_RESET = 'LIST_RESET'

export interface ListResetAction {
  type: typeof LIST_RESET
  payload: {
    listId: string
  }
}

export const TASK_ASSIGNED = 'TASK_ASSIGNED'
export const TASK_ASSIGN_SUCCEEDED = 'TASK_ASSIGN_SUCCEEDED'
export const TASK_ASSIGN_FAILED = 'TASK_ASSIGN_FAILED'

export interface TaskAssignedAction {
  type: typeof TASK_ASSIGNED
  payload: {
    listId: string
    id: string
    assignee: string
  }
}

export interface TaskAssignSucceededAction {
  type: typeof TASK_ASSIGN_SUCCEEDED
  payload: ListItem & {
    event: TaskEvent
  }
}

export interface TaskAssignFailedAction {
  type: typeof TASK_ASSIGN_FAILED
  payload: ListItem
}

export const TASK_STATUS_UPDATED = 'TASK_STATUS_UPDATED'
export const TASK_STATUS_UPDATE_SUCCEEDED = 'TASK_STATUS_UPDATE_SUCCEEDED'
export const TASK_STATUS_UPDATE_FAILED = 'TASK_STATUS_UPDATE_FAILED'

export interface TaskStatusUpdatedAction {
  type: typeof TASK_STATUS_UPDATED
  payload: ListItem & {
    status: TaskStatus
  }
}

export interface TaskStatusUpdateSucceededAction {
  type: typeof TASK_STATUS_UPDATE_SUCCEEDED
  payload: ListItem & {
    event: TaskEvent
    status: TaskStatus
  }
}

export interface TaskStatusUpdateFailedAction {
  type: typeof TASK_STATUS_UPDATE_FAILED
  payload: ListItem & {
    status: TaskStatus
  }
}

export const TASK_EVENT_UNDONE = 'TASK_EVENT_UNDONE'
export const TASK_EVENT_UNDO_SUCCEEDED = 'TASK_EVENT_UNDO_SUCCEEDED'
export const TASK_EVENT_UNDO_FAILED = 'TASK_EVENT_UNDO_FAILED'

export interface TaskEventUndoneAction {
  type: typeof TASK_EVENT_UNDONE
  payload: ListItem & {
    eventId: string
  }
}

export interface TaskEventUndoSucceededAction {
  type: typeof TASK_EVENT_UNDO_SUCCEEDED
  payload: ListItem & {
    event: TaskEvent
    eventId: string
  }
}

export interface TaskEventUndoFailedAction {
  type: typeof TASK_EVENT_UNDO_FAILED
  payload: ListItem & {
    eventId: string
  }
}

export const TASK_DUE_DATE_CHANGED = 'TASK_DUE_DATE_CHANGED'
export const TASK_DUE_DATE_CHANGE_SUCCEEDED = 'TASK_DUE_DATE_CHANGE_SUCCEEDED'
export const TASK_DUE_DATE_CHANGE_FAILED = 'TASK_DUE_DATE_CHANGE_FAILED'

export interface TaskDueDateChangedAction {
  type: typeof TASK_DUE_DATE_CHANGED
  payload: {
    id: string
    dueDate: string
  }
}

export interface TaskDueDateChangeSucceededAction {
  type: typeof TASK_DUE_DATE_CHANGE_SUCCEEDED
  payload: {
    event: TaskEvent
    id: string
  }
}

export interface TaskDueDateChangeFailedAction {
  type: typeof TASK_DUE_DATE_CHANGE_FAILED
  payload: {
    id: string
  }
}

export const TASK_OPENED = 'TASK_OPENED'

export interface TaskOpenedAction {
  type: typeof TASK_OPENED
  payload: {
    id: string
    listId: string
  }
}

export const TASK_NOTE_ADD_REQUESTED = 'TASK_NOTE_ADD_REQUESTED'
export const TASK_NOTE_ADD_SUCCEEDED = 'TASK_NOTE_ADD_SUCCEEDED'
export const TASK_NOTE_ADD_FAILED = 'TASK_NOTE_ADD_FAILED'

export interface TaskNoteAddRequestedAction {
  type: typeof TASK_NOTE_ADD_REQUESTED
  payload: {
    taskId: string
    temporaryNote: TaskNote
  }
}

export interface TaskNoteAddSucceededAction {
  type: typeof TASK_NOTE_ADD_SUCCEEDED
  payload: {
    event: TaskEvent
    taskId: string
  }
}

export interface TaskNoteAddFailedAction {
  type: typeof TASK_NOTE_ADD_FAILED
  payload: {
    taskId: string
    temporaryNote: TaskNote
  }
}

export const CURSOR_MOVED = 'CURSOR_MOVED'
export const CURSOR_RESET = 'CURSOR_RESET'
export const CURSOR_MOVED_TO_THE_TOP = 'CURSOR_MOVED_TO_THE_TOP'
export const CURSOR_SET_BY_ID = 'CURSOR_SET_BY_ID'

export interface CursorMovedAction {
  type: typeof CURSOR_MOVED
  payload: {
    listId: string
    direction: 'up' | 'down'
  }
}

export interface CursorResetAction {
  type: typeof CURSOR_RESET
  payload: {
    listId: string
  }
}

export interface CursorMovedToTheTopAction {
  type: typeof CURSOR_MOVED_TO_THE_TOP
  payload: {
    listId: string
  }
}

export interface CursorSetByIdAction {
  type: typeof CURSOR_SET_BY_ID
  payload: ListItem
}

export const COPY_TEXT_SUCCEEDED = 'COPY_TEXT_SUCCEEDED'
export const COPY_TEXT_FAILED = 'COPY_TEXT_FAILED'

export interface CopyTextSucceededAction {
  type: typeof COPY_TEXT_SUCCEEDED
  payload: Record<string, never>
}

export interface CopyTextFailedAction {
  type: typeof COPY_TEXT_FAILED
  payload: Record<string, never>
}

export const DEBUG_PROFILE_TOGGLED = 'DEBUG_PROFILE_TOGGLED'

export interface DebugProfileToggleAction {
  type: typeof DEBUG_PROFILE_TOGGLED
  payload: Record<string, never>
}

export const PATIENT_VIEW_HEADER_EXPANSION_TOGGLED = 'PATIENT_VIEW_HEADER_EXPANSION_TOGGLED'
export interface PatientViewHeaderExpansionAction {
  type: typeof PATIENT_VIEW_HEADER_EXPANSION_TOGGLED
  payload: Record<string, never>
}

export const PATIENT_VIEW_SUMMARY_EXPANSION_TOGGLED = 'PATIENT_VIEW_SUMMARY_EXPANSION_TOGGLED'
export interface PatientViewSummaryExpansionAction {
  type: typeof PATIENT_VIEW_SUMMARY_EXPANSION_TOGGLED
  payload: Record<string, never>
}

export const FETCH_PATIENT_BY_ID_REQUESTED = 'FETCH_PATIENT_BY_ID_REQUESTED'
export const FETCH_PATIENT_BY_ID_SUCCEEDED = 'FETCH_PATIENT_BY_ID_SUCCEEDED'
export const FETCH_PATIENT_BY_ID_FAILED = 'FETCH_PATIENT_BY_ID_FAILED'

export interface FetchPatientByIdRequestedAction {
  type: typeof FETCH_PATIENT_BY_ID_REQUESTED
  payload: {
    id: string
  }
}

export interface FetchPatientByIdSucceededAction {
  type: typeof FETCH_PATIENT_BY_ID_SUCCEEDED
  payload: {
    id: string
    patient: Patient
  }
}

export interface FetchPatientByIdFailedAction {
  type: typeof FETCH_PATIENT_BY_ID_FAILED
  payload: {
    id: string
  }
}

export const FETCH_PATIENT_BY_CHAT_CHANNEL_REQUESTED = 'FETCH_PATIENT_BY_CHAT_CHANNEL_REQUESTED'
export const FETCH_PATIENT_BY_CHAT_CHANNEL_SUCCEEDED = 'FETCH_PATIENT_BY_CHAT_CHANNEL_SUCCEEDED'
export const FETCH_PATIENT_BY_CHAT_CHANNEL_FAILED = 'FETCH_PATIENT_BY_CHAT_CHANNEL_FAILED'

export interface FetchPatientByChatChannelRequestedAction {
  type: typeof FETCH_PATIENT_BY_CHAT_CHANNEL_REQUESTED
  payload: {
    channelUrl: string
  }
}

export interface FetchPatientByChatChannelSucceededAction {
  type: typeof FETCH_PATIENT_BY_CHAT_CHANNEL_SUCCEEDED
  payload: {
    channelUrl: string
    patient: Patient
  }
}

export interface FetchPatientByChatChannelFailedAction {
  type: typeof FETCH_PATIENT_BY_CHAT_CHANNEL_FAILED
  payload: {
    channelUrl: string
  }
}

export const TOAST_CLOSED = 'TOAST_CLOSED'

export interface ToastClosedAction {
  type: typeof TOAST_CLOSED
  payload: {
    id: string
  }
}

export type Action =
  | FetchSurveyRulesSucceededAction
  | FetchSurveyRulesFailedAction
  | FetchSurveySubmissionsRequestedAction
  | FetchSurveySubmissionsSucceededAction
  | FetchSurveySubmissionsFailedAction
  | SurveyMarkedAsSeenAction
  | SurveyMarkedAsSeenSucceededAction
  | SurveyMarkedAsSeenFailedAction
  | SurveyMarkedAsUnseenAction
  | SurveyMarkedAsUnseenSucceededAction
  | SurveyMarkedAsUnseenFailedAction
  | FetchingPersonsAction
  | FetchPersonsFulfilledAction
  | FetchPersonsFailedAction
  | FetchingPractitionerAction
  | FetchPractitionerFulfilledAction
  | FetchPractitionerFailedAction
  | AssignDeviceToPatientSucceededAction
  | FetchingPatientsAction
  | FetchPatientsFailedAction
  | FetchPatientsFulfilledAction
  | CreatePatientFulfilled
  | UpdatePatientFulfilled
  | DeactivatePatientFulfilled
  | ActivatePatientFulfilled
  | FetchTasksRequestedAction
  | FetchTasksFailedAction
  | FetchTasksSucceededAction
  | FetchTaskByIdSucceededAction
  | FetchTaskByIdFailedAction
  | PrefetchTasksSucceededAction
  | ListResetAction
  | TaskAssignedAction
  | TaskAssignSucceededAction
  | TaskAssignFailedAction
  | TaskStatusUpdatedAction
  | TaskStatusUpdateSucceededAction
  | TaskStatusUpdateFailedAction
  | TaskEventUndoneAction
  | TaskEventUndoSucceededAction
  | TaskEventUndoFailedAction
  | TaskDueDateChangedAction
  | TaskDueDateChangeSucceededAction
  | TaskDueDateChangeFailedAction
  | TaskOpenedAction
  | TaskNoteAddRequestedAction
  | TaskNoteAddSucceededAction
  | TaskNoteAddFailedAction
  | CursorMovedAction
  | CursorResetAction
  | CursorMovedToTheTopAction
  | CursorSetByIdAction
  | CopyTextSucceededAction
  | CopyTextFailedAction
  | DebugProfileToggleAction
  | PatientViewHeaderExpansionAction
  | PatientViewSummaryExpansionAction
  | FetchPatientByIdRequestedAction
  | FetchPatientByIdSucceededAction
  | FetchPatientByIdFailedAction
  | FetchPatientByChatChannelRequestedAction
  | FetchPatientByChatChannelSucceededAction
  | FetchPatientByChatChannelFailedAction
  | ToastClosedAction
  | FetchAllergyIntoleranceRequestedAction
  | FetchAllergyIntoleranceFailedAction
  | FetchAllergyIntoleranceSucceededAction
  | FetchingBloodPressureMeasurements
  | FetchBloodPressureMeasurementsFulfilled
  | FetchBloodPressureMeasurementsFailed
  | FetchTaskCarePathwayRequestedAction
  | FetchTaskCarePathwayFulfilledAction
  | FetchTaskCarePathwayFailedAction
  | CarePathwayStartRequestedAction
  | CarePathwayStartFulfilledAction
  | CarePathwayStartFailedAction
  | CarePathwayCancelRequestedAction
  | CarePathwayCancelFulfilledAction
  | CarePathwayCancelFailedAction
  | FetchCarePathwaysRequestedAction
  | FetchCarePathwaysFulfilledAction
  | FetchCarePathwaysFailedAction
  | TaskChoiceSelectRequestedAction
  | TaskChoiceSelectFulfilledAction
  | TaskChoiceSelectFailedAction

export type ActionCreator<A extends Action> = (payload: A['payload']) => A

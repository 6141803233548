import React, { ReactElement } from 'react'
import Tooltip from '../../../ui/tooltip'
import { ReactComponent as ClockIcon } from '../../../icons/solid/clock.svg'
import { getIsLatest12pointMovingAverageStale } from '../../../../redux/selectors/blood-pressure-average'
import { MOVING_AVERAGE_STALE_INDICATOR } from '../../../../consts/data-testids'
import useTooltip from '../../../../hooks/use-tooltip'
import { useAppSelector } from '../../../../redux'

interface Props {
  patientId: string
}

const MovingAverageStaleIndicator = ({ patientId }: Props): ReactElement | null => {
  const isLatest12pointMovingAverageStale = useAppSelector((state) =>
    getIsLatest12pointMovingAverageStale(state, patientId),
  )
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [12, 8],
  })

  if (!isLatest12pointMovingAverageStale) {
    return null
  }

  return (
    <>
      <span
        data-testid={MOVING_AVERAGE_STALE_INDICATOR}
        className="h-4 w-4 inline-block fill-current text-rivaGold-400 ml-2"
        {...getTriggerProps()}
      >
        <ClockIcon />
      </span>
      <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
        Last 12-MA was more than 3 days ago
      </Tooltip>
    </>
  )
}

export default MovingAverageStaleIndicator

import { ReactElement } from 'react'
import { Option, Select } from '../select'

export enum EmergencyContactRelationship {
  SPOUSE = 'SPOUSE',
  PARENT = 'PARENT',
  CHILD = 'CHILD',
  SIBLING = 'SIBLING',
  FRIEND = 'FRIEND',
  COUSIN = 'COUSIN',
  GUARDIAN = 'GUARDIAN',
  OTHER = 'OTHER',
}

const stateNames = {
  [EmergencyContactRelationship.SPOUSE]: 'Spouse',
  [EmergencyContactRelationship.PARENT]: 'Parent',
  [EmergencyContactRelationship.CHILD]: 'Child',
  [EmergencyContactRelationship.SIBLING]: 'Sibling',
  [EmergencyContactRelationship.FRIEND]: 'Friend',
  [EmergencyContactRelationship.COUSIN]: 'Cousin',
  [EmergencyContactRelationship.GUARDIAN]: 'Guardian',
  [EmergencyContactRelationship.OTHER]: 'Other',
}

interface Props {
  id: string
  onChange: (state?: EmergencyContactRelationship) => void
  value?: EmergencyContactRelationship
}

const EmergencyContactRelationshipSelector = ({ id, onChange, value }: Props): ReactElement => {
  return (
    <Select id={id} onChange={onChange} placeholder="Select relationship to patient" value={value}>
      {Object.values(EmergencyContactRelationship).map((state) => {
        return (
          <Option key={state} value={state}>
            {stateNames[state]}
          </Option>
        )
      })}
    </Select>
  )
}

export default EmergencyContactRelationshipSelector

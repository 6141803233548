import { ReactElement, useEffect } from 'react'
import { IconSize, SvgIcon } from '../../ui/icon'
import { ReactComponent as TeaIcon } from '../../icons/outline/tea.svg'
import { I13n } from '../../../types/i13n'
import { track } from '../../../i13n'

interface Props {
  i13n?: I13n
}

const EmptyState = ({ i13n }: Props): ReactElement => {
  useEffect(() => {
    if (!i13n) {
      return
    }

    track(i13n.eventName, i13n.properties)
  }, [i13n])

  return (
    <div className="text-center pt-7">
      <SvgIcon Icon={TeaIcon} size={IconSize.SMALL} />
      <h3 className="font-semibold text-sm text-rivaOffblack-900 mt-2">Nothing to see yet</h3>
      <p className="font-medium text-sm text-rivaOffblack-500">Enjoy some tea in the meantime</p>
    </div>
  )
}

export default EmptyState

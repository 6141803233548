import clsx from 'clsx'
import { ComponentProps, ReactElement, ReactNode } from 'react'
import ReactModal from 'react-modal'

interface ModalProps {
  isOpen: boolean
  children: ReactNode
  className?: string
  closeModal: () => void
  'data-testid'?: string
  shouldCloseOnOverlayClick?: boolean
  size?: 'md' | 'lg' | 'xl' | '2xl'
  style?: ComponentProps<typeof ReactModal>['style']
}

export function Modal({
  isOpen,
  children,
  className,
  closeModal,
  'data-testid': dataTestId,
  shouldCloseOnOverlayClick = true,
  size = 'md',
  style,
}: ModalProps): ReactElement {
  return (
    <ReactModal
      testId={dataTestId}
      className={clsx(
        'absolute flex flex-col w-full h-full bg-white mx-auto top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 transform shadow-xl overflow-y-auto',
        {
          'vmd:w-auto vmd:h-auto vmd:rounded-modal': size === 'md',
          'vlg:w-auto vlg:h-auto vlg:rounded-modal': size === 'lg',
          'vxl:w-auto vxl:h-auto vxl:rounded-modal': size === 'xl',
        },
        className,
      )}
      overlayClassName="fixed top-0 right-0 left-0 bottom-0 bg-rivaOffblack-900 bg-opacity-40 z-20"
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc
      style={style}
      overlayElement={(props, contentElement) => (
        <div {...props} onClick={(event) => event.stopPropagation()} onKeyDown={(event) => event.stopPropagation()}>
          {contentElement}
        </div>
      )}
    >
      {children}
    </ReactModal>
  )
}

import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  TooltipProps,
} from 'recharts'
import { DateTime } from 'luxon'
import range from 'lodash.range'
import deepEquals from 'lodash.isequal'
import { useApolloClient, useQuery } from '@apollo/client'
import flatten from 'lodash.flatten'
import Heading from '../../../../ui/heading'
import PatientProfileSection from '../../patient-profile-section'
import ButtonGroup from '../../../../ui/button-group'
import { ButtonSize, ButtonVariant, ToggleButton } from '../../../../ui/button'
import { useAppDispatch, useAppSelector } from '../../../../../redux'
import { getBloodPressureMeasurementsByPatient } from '../../../../../redux/selectors/blood-pressure-measurement'
import { getPatientBloodPressureGoal } from '../../../../../redux/selectors/patient'
import { buildCarePathwayListId } from '../../../../../utils/lists'
import {
  CarePathwayStatus,
  CarePathwayType,
  MedicationType,
  MedicationsPaidQueryQuery,
  TaskEventType,
} from '../../../../../__generated__/graphql'
import { getListItemIds } from '../../../../../redux/selectors/lists'
import { getCarePathwayById, getCarePathwayEventsByCarePathwayId } from '../../../../../redux/selectors/care-pathway'
import { fetchCarePathways } from '../../../../../redux/thunks/care-pathway'
import { CarePathway } from '../../../../../types/care-pathway'
import medicationsPaidQuery from '../../../../../schemas/medications-paid-query'
import useGetBloodPressureMovingAverage from '../../../../../api/hooks/use-get-blood-pressure-moving-average'
import { BloodPressureMeasurement } from '../../../../../types/blood-pressure-measurement'
import FormattedDateTime from '../../../../ui/formatted-date-time'
import { getIsDebugProfileEnabled } from '../../../../../redux/selectors/ui'
import useFlag from '../../../../../hooks/use-flag'
import { Features } from '../../../../../types/features'

type Medication = NonNullable<
  NonNullable<NonNullable<MedicationsPaidQueryQuery['patient']>['medications']>['medications'][number][number]
>

interface MeasurementPoint extends BloodPressureMeasurement {
  createdAt: number
}

const formatDate = (millis: number): string => {
  const dateTime = DateTime.fromMillis(millis)

  if (dateTime.diffNow('days').days > 0) {
    return ''
  }

  return dateTime.toLocaleString({
    month: 'short',
    day: 'numeric',
  })
}

type DateRange = '14d' | '30d' | '3m' | '6m'

const getInterval = (range: DateRange): number => {
  switch (range) {
    case '14d':
      return 0
    case '30d':
      return 7
    default:
      return 30
  }
}

const getDayCount = (range: DateRange): number => {
  switch (range) {
    case '14d':
      return 14
    case '30d':
      return 30
    case '3m':
      return 90
    default:
      return 180
  }
}

const syncMethod = (
  ticks: { value: number }[],
  data: {
    activeLabel: number
  },
) => {
  const index = ticks.findIndex(({ value }) => value === data.activeLabel)

  return index >= 0 ? index : undefined
}

interface MovingAverage {
  createdAt: number
  diastolicAverage: number | null
  systolicAverage: number | null
  beatsPerMinuteAverage: number | null
}

interface Props {
  patientId: string
}

const renderMeasurementsTooltip = ({ payload }: TooltipProps<number, string>) => {
  const systolic = payload?.find((p) => p.name === 'systolicBloodPressure')
  const average = payload?.find((p) => p.name === 'systolicAverage')

  if (!systolic || !average) {
    return <div></div>
  }

  const averagePoint = average.payload as MovingAverage | undefined
  const measurement = systolic.payload as MeasurementPoint | undefined

  return (
    <div className="shadow-md z-120 bg-rivaOffblack-900 rounded box-border block p-3 text-white leading-10 animate-fadein">
      {averagePoint ? (
        <table>
          <thead className="text-rivaOffblack-400 text-xxs font-semibold text-left">
            <tr>
              <th className="w-20">BP Average</th>
              <th className="w-20">HR Average</th>
            </tr>
          </thead>
          <tbody className="text-sm font-normal">
            <tr>
              <td>
                {averagePoint.systolicAverage}/{averagePoint.diastolicAverage}
              </td>
              <td>{averagePoint.beatsPerMinuteAverage}</td>
            </tr>
          </tbody>
        </table>
      ) : null}
      {measurement ? (
        <table>
          <thead className="text-rivaOffblack-400 text-xxs font-semibold text-left">
            <tr>
              <th className="w-20">Date</th>
              <th className="w-60">Time</th>
              <th className="w-20">BP</th>
              <th className="w-20">HR</th>
            </tr>
          </thead>
          <tbody className="text-sm font-normal">
            <tr>
              <td>
                <FormattedDateTime value={measurement.created.at} month="numeric" day="numeric" year="2-digit" />
              </td>
              <td>
                <FormattedDateTime
                  value={measurement.created.at}
                  hour="numeric"
                  minute="2-digit"
                  hour12
                  timeZoneName="short"
                />
              </td>
              <td>
                {measurement.systolicBloodPressure}/{measurement.diastolicBloodPressure}
              </td>
              <td>{measurement.beatsPerMinute}</td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </div>
  )
}

const renderTimelineTooltip = () => <div></div>

const BloodPressureGraph = ({ patientId }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const apolloClient = useApolloClient()
  const isDebugging = useAppSelector(getIsDebugProfileEnabled)
  const isFlagEnabled = useFlag(Features.BLOOD_PRESSURE_GRAPH)
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>('14d')
  const { systolicBloodPressure: systolicGoal, diastolicBloodPressure: diastolicGoal } =
    useAppSelector((state) => getPatientBloodPressureGoal(state, patientId)) ?? {}

  const isEnabled = isDebugging && isFlagEnabled

  const set14d = useCallback(() => setSelectedDateRange('14d'), [])
  const set30d = useCallback(() => setSelectedDateRange('30d'), [])
  const set3m = useCallback(() => setSelectedDateRange('3m'), [])
  const set6m = useCallback(() => setSelectedDateRange('6m'), [])

  const { data: activeMedications } = useQuery(medicationsPaidQuery, {
    variables: {
      medicationType: MedicationType.Active,
      patientId,
    },
  })

  const { data: maData } = useGetBloodPressureMovingAverage(patientId, { lookBack: 180 })
  const movingAverages = maData?.data

  const meds = flatten(activeMedications?.patient?.medications.medications ?? [])
    .filter((m: unknown): m is Medication => !!m)
    .map((m) => {
      const startedAt = m.events.reduce((startedAt, event) => {
        if (startedAt) {
          return startedAt
        }

        return event?.type === 'ENTER' || event?.type === 'ORDER' ? event.eventdate : ''
      }, '')

      return {
        id: String(m.medicationentryid) || m.medication,
        medication: m.medication,
        startedAt: DateTime.fromFormat(startedAt, 'MM/dd/yyyy'),
      }
    })

  const carePathwayListId = buildCarePathwayListId(
    patientId,
    [CarePathwayType.LifestyleChange],
    [CarePathwayStatus.InProgress, CarePathwayStatus.Completed],
  )
  const carePathways = useAppSelector(
    (state) =>
      getListItemIds(state, carePathwayListId)
        .map((id) => getCarePathwayById(state, id))
        .filter((c: CarePathway | undefined): c is CarePathway => !!c)
        .map(({ created, id: carePathwayId, status, type }) => {
          const events = getCarePathwayEventsByCarePathwayId(state, carePathwayId)
          const completed = events.find(({ eventType }) => eventType === TaskEventType.WorkflowComplete)
          const result: {
            completedAt?: DateTime
            createdAt: DateTime
            id: string
            status: CarePathwayStatus
            type: CarePathwayType
          } = {
            completedAt: completed?.created.at ? DateTime.fromISO(completed.created.at) : undefined,
            createdAt: DateTime.fromISO(created.at),
            id: carePathwayId,
            status,
            type,
          }

          return result
        }),
    deepEquals,
  )

  const measurements = useAppSelector(
    (state) =>
      getBloodPressureMeasurementsByPatient(state, patientId).map(({ created, ...rest }): MeasurementPoint => {
        return {
          createdAt: DateTime.fromISO(created.at).toMillis(),
          created,
          ...rest,
        }
      }),
    deepEquals,
  )
  const ticks = useMemo(() => {
    const count = getDayCount(selectedDateRange)

    return range(0, count + 2).map((x) =>
      DateTime.now()
        .plus({
          days: x - count,
        })
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toMillis(),
    )
  }, [selectedDateRange])
  const averages = useMemo(() => {
    return ticks.map((tick): MovingAverage => {
      const tickDateTime = DateTime.fromMillis(tick)

      const average = movingAverages?.find((ma) => {
        const latestTimestamp = DateTime.fromISO(ma.latestTimestamp)
        const diff = tickDateTime.diff(latestTimestamp, 'days').days

        return diff > -1 && diff <= 0
      })

      if (!average) {
        return {
          createdAt: tick,
          diastolicAverage: null,
          systolicAverage: null,
          beatsPerMinuteAverage: null,
        }
      }

      return {
        createdAt: tick,
        diastolicAverage: Math.round(average.diastolicAverage.average) || null,
        systolicAverage: Math.round(average.systolicAverage.average) || null,
        beatsPerMinuteAverage: Math.round(average.beatsPerMinuteAverage.average) || null,
      }
    })
  }, [movingAverages, ticks])
  const points = useMemo(
    () =>
      measurements.filter((measurement) => {
        const createdAt = DateTime.fromMillis(measurement.createdAt)

        return createdAt.diffNow('days').days >= -getDayCount(selectedDateRange)
      }),
    [measurements, selectedDateRange],
  )
  const domain = useMemo(() => [ticks[0], ticks.slice(-1)[0]], [ticks])
  const timelineTicks = useMemo(() => [...ticks, ...points.map((m) => m.createdAt)].sort(), [points, ticks])
  const carePathwayPoints = carePathways.map((carePathway) => {
    return {
      id: carePathway.id,
      data: timelineTicks.map((tick) => {
        const tickDateTime = DateTime.fromMillis(tick)
        let inPoint = false
        if (carePathway.completedAt) {
          inPoint =
            carePathway.createdAt.diff(tickDateTime, 'days').days <= 0 &&
            carePathway.completedAt.diff(tickDateTime, 'days').days > 0
        } else {
          inPoint = carePathway.createdAt.diff(tickDateTime, 'days').days <= 0
        }

        return {
          id: carePathway.id,
          value: inPoint ? `${carePathway.createdAt.toMillis()}-${carePathway.id}` : null,
          x: tick,
        }
      }),
    }
  })
  const medPoints = meds.map((med) => {
    return {
      id: med.id,
      medication: med.medication,
      data: timelineTicks.map((tick) => {
        const tickDateTime = DateTime.fromMillis(tick)
        const inPoint = med.startedAt.diff(tickDateTime, 'days').days <= 0

        return {
          id: med.id,
          value: inPoint ? `${med.startedAt.toMillis()}-${med.id}` : null,
          x: tick,
        }
      }),
    }
  })

  const renderCustomizedScatterShape = (props: unknown) => {
    const { className, cx, cy, payload } = props as {
      className: string
      cx: number
      cy: number
      payload: MeasurementPoint
      x: number
      y: number
    }

    if (payload.comment) {
      return (
        <svg
          x={cx - 3}
          y={cy - 3}
          width="6"
          height="6"
          viewBox="0 0 6 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="4" height="4" rx="2" fill="white" />
          <rect x="1" y="1" width="4" height="4" rx="2" stroke="#BEAAFF" stroke-width="2" />
        </svg>
      )
    }

    return (
      <svg x={cx - 3} y={cy - 3} width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect className={className} width="6" height="6" rx="3" />
      </svg>
    )
  }

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    dispatch(fetchCarePathways(carePathwayListId, apolloClient))
  }, [apolloClient, carePathwayListId, dispatch, isEnabled])

  if (!isEnabled) {
    return null
  }

  return (
    <PatientProfileSection>
      <header className="relative flex px-4 pt-4 pb-7 items-center">
        <Heading level={2}>Graphs</Heading>
      </header>
      <main>
        <div className="px-4">
          <ButtonGroup>
            <ToggleButton
              onClick={set14d}
              pressed={selectedDateRange === '14d'}
              size={ButtonSize.XXS}
              variant={ButtonVariant.SECONDARY}
            >
              14D
            </ToggleButton>
            <ToggleButton
              onClick={set30d}
              pressed={selectedDateRange === '30d'}
              size={ButtonSize.XXS}
              variant={ButtonVariant.SECONDARY}
            >
              30D
            </ToggleButton>
            <ToggleButton
              onClick={set3m}
              pressed={selectedDateRange === '3m'}
              size={ButtonSize.XXS}
              variant={ButtonVariant.SECONDARY}
            >
              3M
            </ToggleButton>
            <ToggleButton
              onClick={set6m}
              pressed={selectedDateRange === '6m'}
              size={ButtonSize.XXS}
              variant={ButtonVariant.SECONDARY}
            >
              6M
            </ToggleButton>
          </ButtonGroup>
        </div>
        <div className="text-rivaOffblack-500">
          <ResponsiveContainer width="100%" height={600}>
            <ComposedChart syncId="my-sync-id" syncMethod={syncMethod}>
              <CartesianGrid
                className="stroke-rivaOffblack-300"
                stroke="currentColor"
                strokeLinecap="round"
                strokeDasharray="1 4"
              />
              <XAxis
                className="text-xxs tabular-nums fill-rivaOffblack-500 stroke-rivaOffblack-200"
                dataKey="createdAt"
                domain={domain}
                fill=""
                interval={getInterval(selectedDateRange)}
                minTickGap={0}
                orientation="top"
                stroke=""
                tickFormatter={() => ''}
                tickLine={{
                  stroke: 'transparent',
                }}
                tickMargin={8}
                ticks={ticks.concat()}
                type="number"
              />
              <YAxis
                className="text-xxs tabular-nums fill-rivaOffblack-500 stroke-rivaOffblack-200"
                domain={[50, 200]}
                padding={{
                  top: 16,
                  bottom: 16,
                }}
                stroke=""
                tickCount={16}
                tickLine={{
                  className: 'stroke-rivaOffblack-500',
                  stroke: '',
                }}
                type="number"
              />
              <ReferenceLine
                className="stroke-rivaPurple-300"
                label={{
                  position: 'insideBottomRight',
                  value: 'SYS Goal',
                  className: 'fill-rivaPurple-700 stroke-none text-xxs font-semibold',
                  fill: '',
                  fillOpacity: 1,
                  stroke: '',
                }}
                stroke=""
                strokeOpacity={1}
                y={systolicGoal}
              />
              <ReferenceLine
                className="stroke-rivaBlue-300"
                label={{
                  position: 'insideBottomRight',
                  value: 'DIA Goal',
                  className: 'fill-rivaBlue-700 stroke-none text-xxs font-medium',
                }}
                stroke=""
                strokeOpacity={1}
                y={diastolicGoal}
              />
              <Scatter
                activeShape={{
                  className: 'fill-rivaPurple-500',
                }}
                className="fill-rivaPurple-300"
                data={points}
                dataKey="systolicBloodPressure"
                shape={renderCustomizedScatterShape}
              />
              <Scatter
                activeShape={{
                  className: 'fill-rivaBlue-700',
                }}
                className="fill-rivaBlue-300"
                data={points}
                dataKey="diastolicBloodPressure"
                shape={renderCustomizedScatterShape}
              />
              <Line
                activeDot={false}
                className="stroke-rivaPurple-500"
                data={averages}
                dataKey="systolicAverage"
                dot={false}
                stroke=""
                strokeWidth={2}
                type="monotone"
              />
              <Line
                activeDot={false}
                className="stroke-rivaBlue-500"
                data={averages}
                dataKey="diastolicAverage"
                dot={false}
                stroke=""
                strokeWidth={2}
                type="monotone"
              />
              <Tooltip content={renderMeasurementsTooltip} />
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="100%" height={(carePathwayPoints.length + medPoints.length + 1) * 34 + 10}>
            <ComposedChart syncId="my-sync-id" syncMethod={syncMethod}>
              <defs>
                <linearGradient
                  id="lifestyleIndefiniteEnd"
                  x1="0%"
                  x2="100%"
                  y1="0%"
                  y2="0%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stop-color="#C8EEE7" />
                  <stop offset="90%" stop-color="#C8EEE7" />
                  <stop offset="100%" stop-color="#C8EEE7" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient
                  id="lifestyleIndefiniteStart"
                  x1="0%"
                  x2="100%"
                  y1="0%"
                  y2="0%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stop-color="#C8EEE7" stopOpacity={0.3} />
                  <stop offset="10%" stop-color="#C8EEE7" />
                  <stop offset="100%" stop-color="#C8EEE7" />
                </linearGradient>
                <linearGradient
                  id="lifestyleIndefiniteStartEnd"
                  x1="0%"
                  x2="100%"
                  y1="0%"
                  y2="0%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stop-color="#C8EEE7" stopOpacity={0.3} />
                  <stop offset="10%" stop-color="#C8EEE7" />
                  <stop offset="90%" stop-color="#C8EEE7" />
                  <stop offset="100%" stop-color="#C8EEE7" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient
                  id="medicationIndefiniteEnd"
                  x1="0%"
                  x2="100%"
                  y1="0%"
                  y2="0%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stop-color="#FCE7F3" />
                  <stop offset="90%" stop-color="#FCE7F3" />
                  <stop offset="100%" stop-color="#FCE7F3" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient
                  id="medicationIndefiniteStart"
                  x1="0%"
                  x2="100%"
                  y1="0%"
                  y2="0%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stop-color="#FCE7F3" stopOpacity={0.3} />
                  <stop offset="10%" stop-color="#FCE7F3" />
                  <stop offset="100%" stop-color="#FCE7F3" />
                </linearGradient>
                <linearGradient
                  id="medicationIndefiniteStartEnd"
                  x1="0%"
                  x2="100%"
                  y1="0%"
                  y2="0%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stop-color="#FCE7F3" stopOpacity={0.3} />
                  <stop offset="10%" stop-color="#FCE7F3" />
                  <stop offset="90%" stop-color="#FCE7F3" />
                  <stop offset="100%" stop-color="#FCE7F3" stopOpacity={0.3} />
                </linearGradient>
              </defs>
              <CartesianGrid
                className="stroke-rivaOffblack-300"
                stroke="currentColor"
                strokeLinecap="round"
                strokeDasharray="1 4"
              />
              <XAxis
                className="text-xxs tabular-nums fill-rivaOffblack-500 stroke-rivaOffblack-200"
                dataKey="x"
                domain={domain}
                fill=""
                interval={getInterval(selectedDateRange)}
                minTickGap={0}
                stroke=""
                tickFormatter={formatDate}
                tickLine={{
                  className: 'stroke-rivaOffblack-500',
                  stroke: '',
                }}
                tickMargin={8}
                ticks={ticks}
                type="number"
              />
              <YAxis
                className="text-xxs tabular-nums fill-rivaOffblack-500 stroke-rivaOffblack-200"
                domain={[10, carePathways.length * 10]}
                interval={0}
                padding={{
                  top: 20,
                  bottom: 20,
                }}
                tickFormatter={(id: string) => {
                  if (carePathways.length > 0 && id.includes(carePathways[0].id)) {
                    return 'Lifestyle'
                  }

                  if (medPoints.length > 0 && id.includes(medPoints[0].id)) {
                    return 'Medication'
                  }

                  return ''
                }}
                stroke=""
                tickLine={{
                  className: 'stroke-rivaOffblack-500',
                  stroke: '',
                }}
                type="category"
                reversed
              />
              {medPoints.map(({ id, medication, data }) => {
                let stroke = '#FCE7F3'
                if (data.length > 0) {
                  if (data[data.length - 1].value !== null && data[0].value !== null) {
                    stroke = 'url(#medicationIndefiniteStartEnd)'
                  } else if (data[data.length - 1].value) {
                    stroke = 'url(#medicationIndefiniteEnd)'
                  } else if (data[0].value !== null) {
                    stroke = 'url(#medicationIndefiniteStart)'
                  }
                }

                return (
                  <Line
                    activeDot={false}
                    data={data}
                    dataKey="value"
                    dot={false}
                    key={id}
                    label={({ x, y, index, value }: { x: number; y: number; index: number; value: string }) => {
                      const isFirst = index === 0 || data[index - 1].value === null

                      if (!isFirst || x === null || y === null) {
                        return <g></g>
                      }

                      return (
                        <g transform={`translate(${x + 4},${y - 11})`}>
                          <text className="fill-rivaGreen-800 text-xxs" x={0} y={0} dy={16} fill="" stroke="none">
                            {medication}
                          </text>
                        </g>
                      )
                    }}
                    stroke={stroke}
                    strokeWidth={28}
                  />
                )
              })}
              {carePathwayPoints.map(({ id, data }) => {
                let stroke = '#C8EEE7'
                if (data.length > 0) {
                  if (data[data.length - 1].value !== null && data[0].value !== null) {
                    stroke = 'url(#lifestyleIndefiniteStartEnd)'
                  } else if (data[data.length - 1].value) {
                    stroke = 'url(#lifestyleIndefiniteEnd)'
                  } else if (data[0].value !== null) {
                    stroke = 'url(#lifestyleIndefiniteStart)'
                  }
                }

                return (
                  <Line
                    activeDot={false}
                    data={data}
                    dataKey="value"
                    dot={false}
                    key={id}
                    label={({ x, y, index, value }: { x: number; y: number; index: number; value: string }) => {
                      const isFirst = index === 0 || data[index - 1].value === null

                      return (
                        <g transform={`translate(${x + 4},${y - 11})`}>
                          <text className="fill-rivaGreen-800 text-xxs" x={0} y={0} dy={16} fill="" stroke="none">
                            {isFirst ? value : ''}
                          </text>
                        </g>
                      )
                    }}
                    stroke={stroke}
                    strokeWidth={28}
                  />
                )
              })}
              <Tooltip content={renderTimelineTooltip} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </main>
    </PatientProfileSection>
  )
}

export default BloodPressureGraph

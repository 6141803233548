import { MouseEventHandler, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '../../redux'
import { copyTextFailed } from '../../redux/actions/clipboard'
import { ReactComponent as CopyIcon } from '../icons/outline/copy.svg'
import { ReactComponent as CheckIcon } from '../icons/outline/check.svg'
import { uuid } from '../../utils/uuid'
import { Button, ButtonSize, ButtonVariant } from './button'
import { SvgIcon } from './icon'

interface Props {
  copiedText?: ReactNode
  onClick?: MouseEventHandler
  size?: ButtonSize
  text: string
  toCopyText?: ReactNode
  variant?: ButtonVariant
}

const GLOBAL_COPY_EVENT_NAME = 'text-copied-to-clipboard'

declare global {
  interface ElementEventMap {
    [GLOBAL_COPY_EVENT_NAME]: CustomEvent<string>
  }
}

const CopyButton = ({
  copiedText = 'Copied',
  onClick,
  size,
  text,
  toCopyText = 'Copy to clipboard',
  variant,
}: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const [copyTimeout, setCopyTimeout] = useState(-1)
  const id = useMemo(() => uuid(), [])

  const handleClick = useCallback<MouseEventHandler>(
    async (event) => {
      event.stopPropagation()

      try {
        await navigator.clipboard.writeText(text)

        const customEvent = new CustomEvent(GLOBAL_COPY_EVENT_NAME, {
          detail: id,
        })

        document.body.dispatchEvent(customEvent)
      } catch (_) {
        dispatch(copyTextFailed({}))
      }

      setCopyTimeout(window.setTimeout(() => setCopyTimeout(-1), 10000))

      if (onClick) {
        onClick(event)
      }
    },
    [dispatch, id, onClick, text],
  )

  useEffect(() => {
    const onCopied = (event: CustomEvent<string>) => {
      if (event.detail === id) {
        return
      }

      clearTimeout(copyTimeout)
      setCopyTimeout(-1)
    }

    document.body.addEventListener(GLOBAL_COPY_EVENT_NAME, onCopied)

    return () => {
      document.body.removeEventListener(GLOBAL_COPY_EVENT_NAME, onCopied)
    }
  }, [copyTimeout, id, text])

  useEffect(() => {
    return () => {
      if (copyTimeout >= 0) {
        clearTimeout(copyTimeout)
      }
    }
  }, [copyTimeout])

  const isCopied = copyTimeout >= 0

  return (
    <Button onClick={handleClick} size={size} variant={variant}>
      <SvgIcon Icon={isCopied ? CheckIcon : CopyIcon} />
      <span className="ml-2">{isCopied ? copiedText : toCopyText}</span>
    </Button>
  )
}

export default CopyButton

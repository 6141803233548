import { MouseEventHandler, ReactElement, useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import useGetChannelUnreadCount from '../../../api/hooks/sendbird/use-get-channel-unread-count'
import { useAppSelector } from '../../../redux'
import { getPatientPrimaryChatChannel } from '../../../redux/selectors/patient'
import { Button, ButtonSize, ButtonVariant } from '../../ui/button'
import { IconSize, SvgIcon } from '../../ui/icon'
import { ReactComponent as IconChat } from '../../icons/outline/chat-alt.svg'
import { ReactComponent as IconSpinner } from '../../icons/spinner.svg'
import NewIndicator from '../sidebar/new-indicator'
import { getLoggedInPersonId } from '../../../redux/selectors/app'
import useIsPersonInChannel from '../../../api/hooks/sendbird/use-is-person-in-channel'
import Tooltip from '../../ui/tooltip'
import useTooltip from '../../../hooks/use-tooltip'
import { I13n } from '../../../types/i13n'
import { track } from '../../../i13n'

interface Props {
  i13n?: Partial<I13n>
  patientId: string
  size?: ButtonSize
  text?: string
  variant?: ButtonVariant
}

const PatientChatButton = ({
  i13n,
  patientId,
  size,
  variant = ButtonVariant.SECONDARY,
}: Props): ReactElement | null => {
  const isChatView = !!useRouteMatch('/chat')
  const channelUrl = useAppSelector((state) => getPatientPrimaryChatChannel(state, patientId))
  const personId = useAppSelector((state) => getLoggedInPersonId(state))
  const { inChannel, isValidating } = useIsPersonInChannel(channelUrl, personId)
  const unreadCount = useGetChannelUnreadCount(inChannel ? channelUrl : null)
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    offset: [0, 8],
    placement: 'bottom',
  })
  const history = useHistory()
  const handleClick = useCallback<MouseEventHandler>(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      track(i13n?.eventName ?? 'Send Message Clicked', {
        ...i13n?.properties,
        Patientid: patientId,
      })
      history.push(`/chat/${channelUrl}`)
    },
    [channelUrl, history, i13n?.eventName, i13n?.properties, patientId],
  )
  const disabled = !inChannel
  const [isTakingTooLong, setIsTakingTooLong] = useState(false)

  useEffect(() => {
    if (inChannel || !isValidating) {
      setIsTakingTooLong(false)

      return
    }

    const timeout = setTimeout(() => setIsTakingTooLong(true), 2000)

    return () => clearTimeout(timeout)
  }, [inChannel, isValidating])

  if (isChatView) {
    return null
  }

  return (
    <>
      <Button
        {...getTriggerProps()}
        disabled={disabled}
        onClick={handleClick}
        size={size}
        title={disabled && !isValidating ? 'You are not in this chat channel' : undefined}
        variant={variant}
      >
        <span
          className={clsx('relative flex items-center justify-center', {
            'h-6 w-6': size !== ButtonSize.XS,
            'h-4 w-4': size === ButtonSize.XS,
          })}
        >
          <SvgIcon
            className={clsx({
              'animate-spin': isTakingTooLong,
            })}
            Icon={isTakingTooLong ? IconSpinner : IconChat}
            size={size === ButtonSize.XS ? IconSize.X_SMALL : IconSize.SMALL}
          />
          {unreadCount > 0 && <NewIndicator backgroundType="white" />}
        </span>
        <span
          className={clsx({
            'ml-2': size !== ButtonSize.XS,
            'ml-1': size === ButtonSize.XS,
          })}
        >
          Send message
        </span>
      </Button>
      <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
        {unreadCount === 0
          ? 'No unread messages in this chat channel'
          : unreadCount === 1
          ? '1 unread message in this chat channel'
          : `${unreadCount} unread messages in this chat channel`}
      </Tooltip>
    </>
  )
}

export default PatientChatButton

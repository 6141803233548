import { useCallback, useEffect } from 'react'
import useSurveysApi from '../../api/hooks/use-surveys-api'
import { useAppDispatch, useAppSelector } from '../../redux'
import { fetchSurveySubmissions } from '../../redux/thunks/surveys'
import { getAppRecordState } from '../../redux/selectors/app'
import { ListContentType } from '../../types/lists'
import { RecordState } from '../../types/record-state'
import { buildListId } from '../../utils/lists'
import { SURVEY_QUERY_UNSEEN } from '../../types/survey'

const usePrefetchUnseenSurveys = (): void => {
  const dispatch = useAppDispatch()
  const surveysApi = useSurveysApi()
  const recordState = useAppSelector((state) => getAppRecordState(state))
  const listId = buildListId({
    listContentType: ListContentType.SURVEY_SUBMISSION,
    query: SURVEY_QUERY_UNSEEN,
  })
  const prefetchSurveys = useCallback(
    () => dispatch(fetchSurveySubmissions(listId, surveysApi)),
    [dispatch, listId, surveysApi],
  )

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (recordState === RecordState.LOADED) {
      interval = setInterval(prefetchSurveys, 60000)
      prefetchSurveys()
    }

    const handleFocus = () => {
      if (recordState === RecordState.LOADED) {
        prefetchSurveys()
      }
    }

    window.addEventListener('focus', handleFocus)

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      // window.removeEventListener('focus', handleFocus)
    }
  }, [dispatch, listId, prefetchSurveys, recordState, surveysApi])
}

export default usePrefetchUnseenSurveys

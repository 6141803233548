import { datadogLogs } from '@datadog/browser-logs'
import { ApiError, HttpMethod } from './api/http'

class FeatureLogger {
  private name: string

  private message: string

  private metadata: Record<string, unknown>

  private startTime: number

  constructor(name: string, message: string, metadata?: Record<string, unknown>) {
    this.name = name
    this.message = message
    this.metadata = metadata ?? {}
    this.startTime = Date.now()
  }

  logSuccess(metadata?: Record<string, unknown>) {
    datadogLogs.logger.log(this.message, {
      ...this.metadata,
      ...metadata,
      componentName: this.name,
      duration: Date.now() - this.startTime,
      featureEvent: 'success',
    })
  }

  logFailure(error?: Error, metadata?: Record<string, unknown>) {
    datadogLogs.logger.error(error?.message ?? this.message, {
      ...this.metadata,
      ...metadata,
      componentName: this.name,
      duration: Date.now() - this.startTime,
      errorMessage: error?.message,
      errorStack: error?.stack,
      featureEvent: 'failure',
    })
  }
}

class ApiLogger {
  private feature: FeatureLogger

  private method: HttpMethod

  private metadata: Record<string, unknown> | undefined

  constructor(method: HttpMethod, apiName: string, metadata?: Record<string, unknown>) {
    this.feature = new FeatureLogger('api-logger', `API Request: ${apiName}`, {
      apiName,
    })
    this.metadata = metadata
    this.method = method
  }

  logSuccess({ statusCode }: { statusCode: number }) {
    this.feature.logSuccess({
      ...this.metadata,
      http: {
        ...(this.metadata?.http as Record<string, unknown>),
        method: this.method,
        status_code: statusCode,
      },
    })
  }

  logFailure(error?: unknown) {
    if (!(error instanceof ApiError)) {
      if (error instanceof Error) {
        this.feature.logFailure(error)
      } else {
        this.feature.logFailure()
      }

      return
    }

    if (error.statusCode === 304) {
      this.logSuccess({
        statusCode: 304,
      })

      return
    }

    this.feature.logFailure(error, {
      ...this.metadata,
      ...error.metadata,
      http: {
        ...(this.metadata?.http as Record<string, unknown>),
        status_code: error.statusCode,
        status_text: error.statusText,
      },
    })
  }
}

export const feature = (name: string, message: string, metadata?: Record<string, unknown>): FeatureLogger =>
  new FeatureLogger(name, message, metadata)

export const createApiLogger = (method: HttpMethod, apiName: string, metadata?: Record<string, unknown>): ApiLogger =>
  new ApiLogger(method, apiName, metadata)

import { ReactElement } from 'react'
import { useAppSelector } from '../../../../../redux'
import { getTaskById } from '../../../../../redux/selectors/tasks'
import { TaskNote, TaskEvent } from '../../../../../types/task'
import { TaskEventType } from '../../../../../__generated__/graphql'
import TaskNoteItem from './task-note-item'
import TaskEventItem from './task-event-item'

interface Props {
  taskId: string
}

const isNote = (item: TaskNote | TaskEvent): item is TaskNote => typeof (item as TaskNote).body === 'string'

const TaskActivity = ({ taskId }: Props): ReactElement => {
  const task = useAppSelector((state) => getTaskById(state, taskId))
  const events = (task?.events ?? []).reduce((events, event) => {
    if (event.eventType === TaskEventType.CancelEvent) {
      const index = events.findIndex(({ id }) => id === event.data.eventId)

      if (index >= 0) {
        events.splice(index, 1)
      }

      return events
    }

    events.push(event)

    return events
  }, [] as TaskEvent[])
  const items = [...events, ...(task?.notes ?? [])].sort((a, b) => {
    if (a.created.at < b.created.at) {
      return -1
    }

    if (a.created.at > b.created.at) {
      return 1
    }

    return 0
  })

  return (
    <div className="flex-1 overflow-y-auto p-7">
      {items.length ? (
        <h2 className="mb-7 text-lg font-semibold" key="h2">
          Activity
        </h2>
      ) : null}
      {items.map((item) =>
        isNote(item) ? <TaskNoteItem note={item} key={item.id} /> : <TaskEventItem event={item} key={item.id} />,
      )}
    </div>
  )
}

export default TaskActivity

import { ReactElement } from 'react'
import { useAppSelector } from '../../../../../redux'
import { getTaskById } from '../../../../../redux/selectors/tasks'
import { TaskActionType } from '../../../../../types/task'
import ExternalLinkAction from './external-link-action'

interface Props {
  taskId: string
}

const TaskActions = ({ taskId }: Props): ReactElement | null => {
  const task = useAppSelector((state) => getTaskById(state, taskId))

  if (!task?.potentialAction?.length) {
    return null
  }

  return (
    <div className="flex justify-end mt-4 border-t border-dashed border-rivaOffblack-300 flex-wrap">
      {task.potentialAction.map((action) => {
        switch (action.type) {
          case TaskActionType.EXTERNAL_LINK:
            return <ExternalLinkAction key={action.id} action={action} taskId={taskId} />
          default:
            return null
        }
      })}
    </div>
  )
}

export default TaskActions

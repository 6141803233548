import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  className?: string
  children?: ReactNode
  colspan?: 1 | 2
}

const FieldSet = ({ children, className, colspan = 1 }: Props): ReactElement => (
  <fieldset
    className={clsx('flex flex-col', className, {
      'col-span-2': colspan === 2,
    })}
  >
    {children}
  </fieldset>
)

export default FieldSet

import Ajv, { JSONSchemaType } from 'ajv'
import { SWRResponse } from 'swr'
import { Visit } from '../../types/visit'
import { visitSchema } from '../schema/visit'
import useApi from './use-api'

export interface GetVisitsResponse {
  results: Visit[]
  totalCount: number
}

const schema: JSONSchemaType<GetVisitsResponse> = {
  type: 'object',
  properties: {
    results: {
      type: 'array',
      items: visitSchema,
    },
    totalCount: {
      type: 'number',
    },
  },
  required: ['totalCount'],
}

const ajv = new Ajv()
const validate = ajv.compile(schema)

export interface Options {
  taskId: string
}

const useGetTaskVisits = (options: Options | null): SWRResponse<GetVisitsResponse> => {
  return useApi(options === null ? null : '/v1/task/:taskId/visits', {
    params: {
      taskId: options?.taskId ?? '',
    },
    validate,
  })
}

export default useGetTaskVisits

import { usePopper } from 'react-popper'
import type { Placement } from '@popperjs/core'
import {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { uuid } from '../../utils/uuid'

interface TooltipProps {
  delay?: number
  disabled?: boolean
  fallbackPlacements?: Placement[]
  offset?: [number, number]
  placement?: Placement
}

type HtmlElementProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>

type PropGetter = () => HtmlElementProps & {
  ref: (element: HTMLElement | null) => void
}

interface UseTooltipHook extends Partial<ReturnType<typeof usePopper>> {
  getArrowProps: () => HtmlElementProps
  getTriggerProps: PropGetter
  getTooltipProps: PropGetter
  isVisible: boolean
}

const useTooltip = ({
  delay = 500,
  disabled = false,
  fallbackPlacements = ['top-end', 'right'],
  offset: [offsetX, offsetY] = [0, 0],
  placement = 'bottom-end',
}: TooltipProps = {}): UseTooltipHook => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement>()
  const [tooltipElement, setTooltipElement] = useState<HTMLElement>()

  const [isVisible, setIsVisible] = useState(false)

  const tooltipId = useMemo(() => uuid('tooltip-'), [])

  const modifiers = useMemo(() => {
    const modifiers: { name: string; options?: Record<string, unknown> }[] = [
      ...(typeof offsetX === 'number' && typeof offsetY === 'number'
        ? [
            {
              name: 'offset',
              options: {
                offset: [offsetX, offsetY],
              },
            },
          ]
        : []),
      {
        name: 'arrow',
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements,
        },
      },
    ]

    return modifiers
  }, [fallbackPlacements, offsetX, offsetY])
  const popper = usePopper(referenceElement, tooltipElement, {
    placement,
    modifiers,
  }) as ReturnType<typeof usePopper> | undefined

  const getArrowProps = useCallback(
    () => ({
      ...popper?.attributes.arrow,
      style: popper?.styles.arrow,
    }),
    [popper?.attributes.arrow, popper?.styles.arrow],
  )
  const getTooltipProps = useCallback(() => {
    const hidden = !isVisible

    return {
      ...popper?.attributes.popper,
      id: tooltipId,
      hidden,
      ref: (element: HTMLElement | null) => {
        if (element) {
          setTooltipElement(element)
        }
      },
      style: {
        ...popper?.styles.popper,
        visibility: (hidden ? 'hidden' : 'visible') as CSSProperties['visibility'],
      },
    }
  }, [isVisible, popper?.attributes.popper, popper?.styles.popper, tooltipId])

  // Allow for delaying the tooltip
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const onMouseEnter = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => setIsVisible(true), delay)
  }, [delay])
  const onMouseLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setIsVisible(false)
  }, [])

  const getTriggerProps = useCallback(
    () => ({
      'aria-describedby': tooltipId,
      onMouseEnter,
      onMouseLeave,
      ref: (element: HTMLElement | null) => {
        if (element) {
          setReferenceElement(element)
        }
      },
    }),
    [onMouseEnter, onMouseLeave, tooltipId],
  )

  useEffect(() => {
    let handler: number

    if (isVisible && popper?.forceUpdate) {
      handler = requestAnimationFrame(popper.forceUpdate)
    }

    return () => cancelAnimationFrame(handler)
    // This is very much on purpose.
    // Do not remove unless you know what you are doing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  useEffect(() => {
    const onClick = () => setIsVisible(false)

    document.documentElement.addEventListener('click', onClick, {
      capture: true,
    })

    return () => {
      document.documentElement.removeEventListener('click', onClick)
    }
  }, [])

  useEffect(() => {
    if (disabled && isVisible) {
      setIsVisible(false)
    }
  }, [disabled, isVisible])

  return useMemo<UseTooltipHook>(
    () => ({
      ...popper,
      getArrowProps,
      getTriggerProps,
      getTooltipProps,
      isVisible,
    }),
    [getArrowProps, getTooltipProps, getTriggerProps, isVisible, popper],
  )
}

export default useTooltip

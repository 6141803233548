import { ReactElement, ReactNode } from 'react'

export interface Props {
  children: ReactNode
}

const SelectHeading = ({ children }: Props): ReactElement => {
  return (
    <h6 aria-hidden className="pt-4 first:pt-0 pb-4 px-3 text-xxs font-semibold text-rivaOffblack-500 capitalize">
      {children}
    </h6>
  )
}

export default SelectHeading

import { ReactElement } from 'react'
import { TaskNote as TaskNoteType } from '../../../../../types/task'
import PractitionerReference from '../../../../ui/practitioner-reference'
import TaskActivityItem from './task-activity-item'

interface Props {
  note: TaskNoteType
}

const TaskNoteItem = ({ note }: Props): ReactElement => {
  return (
    <TaskActivityItem
      createdAt={note.created.at}
      id={note.id}
      kind="note"
      note={note.body}
      practitionerId={note.created.by}
    >
      <PractitionerReference practitionerId={note.created.by} /> added a note
    </TaskActivityItem>
  )
}

export default TaskNoteItem

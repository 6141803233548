import React, { ComponentProps, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as XButton } from '../icons/outline/x.svg'
import { ReactComponent as ChevronLeftIcon } from '../icons/outline/chevron-left.svg'
import { IconSize, SvgIcon } from './icon'

interface CloseButtonLinkProps extends ComponentProps<typeof Link> {
  kind?: 'close' | 'back'
  size?: IconSize
}

export function CloseButtonLink({
  kind = 'close',
  size = IconSize.MEDIUM,
  to,
  ...rest
}: CloseButtonLinkProps): ReactElement {
  return (
    <Link to={to} {...rest}>
      <SvgIcon Icon={kind === 'close' ? XButton : ChevronLeftIcon} className="text-rivaOffblack-900" size={size} />
    </Link>
  )
}

interface CloseButtonProps {
  close: () => void
  kind?: 'close' | 'back'
  size?: IconSize
}
export function CloseButton({
  kind = 'close',
  close,
  size = IconSize.MEDIUM,
  ...rest
}: CloseButtonProps): ReactElement {
  return (
    <div className="flex justify-end">
      <button onClick={close} {...rest}>
        <SvgIcon Icon={kind === 'close' ? XButton : ChevronLeftIcon} className="text-rivaOffblack-900" size={size} />
      </button>
    </div>
  )
}

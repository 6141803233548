import { RecordState } from '../../types/record-state'
import { RootState } from '../reducers'

const emptyItemIds: string[] = []

export const getListItemIds = (state: RootState, listId: string): string[] =>
  state.lists[listId]?.itemIds ?? emptyItemIds
export const getListRecordState = (state: RootState, listId: string): RecordState =>
  state.lists[listId]?.recordState ?? RecordState.DOES_NOT_EXIST
export const getListTotalCount = (state: RootState, listId: string): number | undefined =>
  state.lists[listId]?.totalCount

export const getListSelectedIndex = (state: RootState, listId: string): number =>
  state.lists[listId]?.selectedIndex ?? -1

export const getListSelectedItemId = (state: RootState, listId: string): string | undefined => {
  const selectedIndex = state.lists[listId]?.selectedIndex

  if (typeof selectedIndex !== 'number') {
    return undefined
  }

  return state.lists[listId]?.itemIds[selectedIndex]
}

export const getListHash = (state: RootState, listId: string): string => state.lists[listId]?.hash ?? ''

export const isListStale = (state: RootState, listId: string): boolean => state.lists[listId]?.isStale ?? false

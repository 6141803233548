import uniq from 'lodash.uniq'
import { ReactElement, useMemo } from 'react'
import useGetSurveySubmissionsByPatient from '../../../../api/hooks/use-get-survey-submissions-by-patient'
import useGetSurveys from '../../../../api/hooks/use-get-surveys'
import { Option, Select } from '../../../ui/select'

interface Props {
  onChange: (surveyId?: number) => void
  patientId: string
  value: number
}

const SurveyFilter = ({ onChange, patientId, value }: Props): ReactElement | null => {
  const { data: { submissions } = {} } = useGetSurveySubmissionsByPatient(patientId)
  const { data: { surveys } = {} } = useGetSurveys()

  const items = useMemo(() => {
    const submittedSurveyIds = uniq(submissions?.map(({ surveyId }) => surveyId) ?? [])

    return surveys?.filter(({ id }) => submittedSurveyIds.includes(id)) ?? []
  }, [submissions, surveys])

  if (!submissions || !surveys || items.length <= 1) {
    return null
  }

  return (
    <Select onChange={onChange} value={value}>
      {[{ id: -1, name: 'All surveys' }].concat(items).map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  )
}

export default SurveyFilter

import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import FormattedDateTime from './formatted-date-time'
import FormattedRelativeTime from './formatted-relative-time'

interface Props {
  className?: string
  titleHidden?: boolean
  value: string
}

const NaturalTime = ({ className, titleHidden, value }: Props): ReactElement => {
  const dateTime = DateTime.fromISO(value)
  const now = DateTime.now()

  return dateTime.diffNow('weeks').weeks > -1 ? (
    <FormattedRelativeTime className={className} titleVisible={!titleHidden} value={value} style="narrow" />
  ) : dateTime.year === now.year ? (
    <FormattedDateTime className={className} titleVisible={!titleHidden} value={value} day="numeric" month="numeric" />
  ) : (
    <FormattedDateTime
      className={className}
      titleVisible={!titleHidden}
      value={value}
      day="numeric"
      month="numeric"
      year="2-digit"
    />
  )
}

export default NaturalTime

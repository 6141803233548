import clsx from 'clsx'
import { Fragment, ReactElement } from 'react'

interface Props {
  children: string
  className?: string
  highlight: string
}

const TextHighlight = ({ children, className, highlight }: Props): ReactElement => {
  const regExp = new RegExp(`(${highlight})`, 'gi')

  return (
    <span className="whitespace-pre">
      {children.split(regExp).map((part, i) => {
        return part.toLowerCase() === highlight.toLowerCase() ? (
          <mark className={clsx('bg-transparent font-bold', className)} key={i}>
            {part}
          </mark>
        ) : (
          <Fragment key={i}>{part}</Fragment>
        )
      })}
    </span>
  )
}

export default TextHighlight

import { ReactElement } from 'react'
import { EthnicityCodeToName, HL7EthniticyCode } from '../../../types/patient'
import { Option, Select } from '../select'

interface Props {
  onChange: (value?: HL7EthniticyCode) => void
  value?: HL7EthniticyCode
}

const EthnicitySelector = ({ onChange, value }: Props): ReactElement => {
  return (
    <Select label="Ethnicity" onChange={onChange} placeholder="Select ethnicity" value={value}>
      {Object.values(HL7EthniticyCode).map((code) => {
        return (
          <Option key={code} value={code}>
            {EthnicityCodeToName[code]}
          </Option>
        )
      })}
    </Select>
  )
}

export default EthnicitySelector

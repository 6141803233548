import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
  title?: string
}

const FormSection = ({ children, title }: Props): ReactElement => {
  return (
    <section className="mb-7 grid grid-cols-2 gap-7 border-t border-rivaOffblack-200 mt-7 pt-7 first:mt-0 first:border-t-0 first:pt-0">
      {title && <h3 className="text-rivaOffblack-900 text-xxs font-black uppercase col-span-2">{title}</h3>}
      {children}
    </section>
  )
}

export default FormSection

import React, { ReactElement } from 'react'
import { ReactComponent as IconUser } from '../icons/solid/user-group.svg'
import { Card } from './card'
import { IconSize, SvgIcon } from './icon'

interface EmptyStateProps {
  borderless?: boolean
  header: string
  subtext: string
  'data-testid'?: string
}

export function EmptyState({ borderless, header, subtext, 'data-testid': dataTestId }: EmptyStateProps): ReactElement {
  return (
    <div data-testid={dataTestId} className="flex justify-center">
      <Card className="flex flex-col w-full items-center justify-center" borderless={borderless}>
        <div className="pt-16 p-6">
          <SvgIcon Icon={IconUser} size={IconSize.MEDIUM} className="text-rivaOffblack" />
        </div>
        <h3 className="font-semibold text-3xl">{header}</h3>
        <p className="p-2 mb-16">{subtext}</p>
      </Card>
    </div>
  )
}

import {
  FetchingPractitionerAction,
  FetchPractitionerFailedAction,
  FetchPractitionerFulfilledAction,
} from '../../types/actions'
import {
  FETCHING_PRACTITIONER,
  FETCH_PRACTITIONER_FULFILLED,
  FETCH_PRACTITIONER_FAILED,
  PractitionerResponse,
} from '../../types/practitioner'

export function fetchingPractitioner(): FetchingPractitionerAction {
  return {
    type: FETCHING_PRACTITIONER,
    payload: {},
  }
}

export function fetchPractitionerFulfilled(payload: PractitionerResponse): FetchPractitionerFulfilledAction {
  return {
    type: FETCH_PRACTITIONER_FULFILLED,
    payload,
  }
}

export function fetchPractitionerFailed(error: Error): FetchPractitionerFailedAction {
  return {
    type: FETCH_PRACTITIONER_FAILED,
    payload: {
      error,
    },
  }
}

import clsx from 'clsx'
import React, { ReactElement, ReactNode } from 'react'

interface ModalContainerProps {
  children: ReactNode
  className?: string
  width?: 'long' | 'auto'
}
export function ModalContainer({ children, className, width = 'auto' }: ModalContainerProps): ReactElement {
  return (
    <div className="h-full w-full overflow-auto">
      <div
        className={clsx(
          'flex flex-col mx-auto pb-24',
          {
            'w-[468px]': width === 'long',
          },
          className,
        )}
      >
        {children}
      </div>
    </div>
  )
}

import { AuditStatus } from './audit-status'

export const WIRELESS_CUFF_DEVICE_TYPE = 'WIRELESS_CUFF'

export interface Device {
  activationState: string
  created: AuditStatus
  deviceId: string
  hardwareVersion: string
  id: string
  manufacturer: string
  modelName: string
  patientId: string
  timeZone: {
    timeZoneOffset: number
    updated: AuditStatus
  }
  type: string
  updated: AuditStatus
}

export const ASSIGN_DEVICE_TO_PATIENT_SUCCEEDED = 'ASSIGN_DEVICE_TO_PATIENT_SUCCEEDED'
export const ASSIGN_DEVICE_TO_PATIENT_FAILED = 'ASSIGN_DEVICE_TO_PATIENT_FAILED'

export interface AssignDeviceToPatientSucceededAction {
  type: typeof ASSIGN_DEVICE_TO_PATIENT_SUCCEEDED
  payload: {
    device: Device
  }
}

export interface AssignDeviceToPatientFailedAction {
  type: typeof ASSIGN_DEVICE_TO_PATIENT_FAILED
  payload: {
    patientId: string
    error: {
      message: string
    }
  }
}

import { ReactElement, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import useTasksApi from '../../../api/hooks/use-tasks-api'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { resetList } from '../../../redux/actions/tasks'
import { isListStale } from '../../../redux/selectors/lists'
import { fetchTasks } from '../../../redux/thunks/tasks'
import { IconSize, SvgIcon } from '../../ui/icon'
import { ReactComponent as TicketIcon } from '../../icons/outline/ticket.svg'
import { track } from '../../../i13n'

interface Props {
  listId: string
}

const TaskRefreshIndicator = ({ listId }: Props): ReactElement | null => {
  const tasksApi = useTasksApi()
  const dispatch = useAppDispatch()
  const isStale = useAppSelector((state) => isListStale(state, listId))
  const onClick = useCallback(() => {
    dispatch(
      resetList({
        listId,
      }),
    )

    dispatch(fetchTasks({ listId, offset: 0 }, tasksApi))

    track('New Task Toast Refresh Page Clicked')
  }, [dispatch, listId, tasksApi])

  useEffect(() => {
    if (!isStale) {
      return
    }

    track('New Task Toast Shown')
  }, [isStale])

  return (
    <div
      className={clsx(
        'fixed bottom-0 left-[92px] xl:left-[252px] mb-3 bg-rivaOffblack-900 text-white box-border p-4 flex items-center w-[583px] h-[56px] shadow-xl z-10 rounded border border-rivaOffblack-500 transform-gpu transition-transform duration-150',
        {
          'translate-y-0 ease-out': isStale,
          'translate-y-[68px] ease-in': !isStale,
        },
      )}
    >
      <SvgIcon Icon={TicketIcon} size={IconSize.MEDIUM} className="text-rivaOffblack-400 mr-3" />
      <div className="flex-1 text-sm">New tasks</div>
      <button onClick={onClick} className="text-sm font-semibold text-rivaPurple-200">
        Refresh
      </button>
    </div>
  )
}

export default TaskRefreshIndicator

import { MouseEventHandler, ReactElement, ReactNode, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import IconButton from '../icon-button'
import { ReactComponent as XIcon } from '../../icons/solid/x.svg'
import { IconSize } from '../icon'
import { useAppDispatch } from '../../../redux'
import { closeToast } from '../../../redux/actions/ui'
import { track } from '../../../i13n'

interface Props {
  buttonRight?: ReactNode
  expanded: boolean
  icon: ReactNode
  id: string
  subtitle?: ReactNode
  timeout?: number
  title: ReactNode
}

const Toast = ({ buttonRight, expanded, icon, id, subtitle, timeout = 3000, title }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const dispatchClose = useCallback(() => {
    dispatch(
      closeToast({
        id,
      }),
    )
  }, [dispatch, id])
  const onClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()

      dispatchClose()

      track('Toast Dismissed')
    },
    [dispatchClose],
  )

  useEffect(() => {
    const t = setTimeout(dispatchClose, timeout)

    return () => {
      clearTimeout(t)
    }
  }, [dispatchClose, timeout])

  useEffect(() => {
    track('Toast Shown', {
      'Toast type': title,
    })
  }, [title])

  return (
    <div
      className={clsx('flex items-center rounded-lg bg-white border border-rivaOffblack-200 p-4 shadow-xl w-full', {
        'absolute second-of-type:scale-[0.95] second-of-type:translate-y-2 second-of-type:-z-10 third-of-type:scale-90 third-of-type:translate-y-4 third-of-type:-z-20':
          !expanded,
        'mt-2 z-50': expanded,
      })}
      role="alert"
    >
      {icon ? <span className="self-start">{icon}</span> : null}
      <div className="flex-1 ml-3">
        <h6 className="text-sm font-medium">{title}</h6>
        {subtitle ? <p className="text-sm font-medium text-rivaOffblack-500">{subtitle}</p> : null}
      </div>
      {buttonRight}
      <IconButton icon={XIcon} rounded size={IconSize.MEDIUM} onClick={onClick} />
    </div>
  )
}

export default Toast

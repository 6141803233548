import clsx from 'clsx'
import { ReactElement } from 'react'
import { useRouteMatch } from 'react-router-dom'
import useTooltip from '../../../hooks/use-tooltip'
import { useAppSelector } from '../../../redux'
import { getListTotalCount } from '../../../redux/selectors/lists'
import { buildMyOpenTasksListId } from '../../../utils/lists'
import { ReactComponent as IconClipboardCheckOutline } from '../../icons/outline/clipboard-check.svg'
import { ReactComponent as IconClipboardCheckSolid } from '../../icons/solid/clipboard-check.svg'
import Tooltip from '../../ui/tooltip'
import { ReactComponent as IconCheckCircleSolid } from '../../icons/solid/check-circle.svg'
import { ReactComponent as IconCheckCircleOutline } from '../../icons/outline/check-circle.svg'
import { ReactComponent as IconCollectionOutline } from '../../icons/outline/collection.svg'
import { ReactComponent as IconCollectionSolid } from '../../icons/solid/collection.svg'
import { TaskQueryParam } from '../../../types/task'
import { getLoggedInPersonId } from '../../../redux/selectors/app'
import { getPractitionerByPersonId } from '../../../redux/selectors/practitioner'
import MyOpenTasksNavItem from './my-open-tasks-nav-item'
import NavigationItem from './navigation-item'
import NewIndicator from './new-indicator'

const TasksNavigationItem = (): ReactElement => {
  const path = '/tasks'
  const isPageActive = !!useRouteMatch(path)
  const loggedInPersonId = useAppSelector((state) => getLoggedInPersonId(state))
  const practitionerId = useAppSelector((state) => getPractitionerByPersonId(state, loggedInPersonId)?.id)
  const myOpenTasksListId = buildMyOpenTasksListId(practitionerId ?? '')
  const createdTaskCount = useAppSelector((state) => getListTotalCount(state, myOpenTasksListId) ?? 0)
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    offset: [0, 20],
    placement: 'right',
  })

  return (
    <>
      <li
        className={clsx('rounded', {
          'bg-white bg-opacity-10': isPageActive,
        })}
        {...getTriggerProps()}
      >
        <NavigationItem
          exact
          level="SECONDARY"
          iconLeft={() => (
            <div className="w-6 h-6 relative">
              {isPageActive ? <IconClipboardCheckSolid /> : <IconClipboardCheckOutline />}
              {!isPageActive && createdTaskCount > 0 ? <NewIndicator /> : null}
            </div>
          )}
          iconRight={() =>
            !isPageActive && createdTaskCount > 0 ? (createdTaskCount > 99 ? '99+' : createdTaskCount) : undefined
          }
          label="Tasks"
          to={path}
        />
        {isPageActive && (
          <>
            <MyOpenTasksNavItem />
            <NavigationItem
              className="capitalize border-b"
              level="SECONDARY"
              to={`${path}/${TaskQueryParam.CLOSED}`}
              label="My closed tasks"
              iconLeft={({ isActive }) => (isActive ? <IconCheckCircleSolid /> : <IconCheckCircleOutline />)}
            />
            <hr className="border border-white/30" />
            <NavigationItem
              className="capitalize border-t"
              level="SECONDARY"
              to={`${path}/${TaskQueryParam.ALL}`}
              label="All tasks"
              iconLeft={({ isActive }) => (isActive ? <IconCollectionSolid /> : <IconCollectionOutline />)}
            />
          </>
        )}
      </li>
      {!isPageActive ? (
        <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
          {createdTaskCount === 0
            ? 'No open tasks'
            : createdTaskCount === 1
            ? 'You have 1 open task'
            : `You have ${createdTaskCount} open tasks`}
        </Tooltip>
      ) : null}
    </>
  )
}

export default TasksNavigationItem

import { ReactElement } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../../../redux'
import { getTaskStatus } from '../../../../redux/selectors/tasks'
import { TaskStatus } from '../../../../types/task'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { getStatusIcon, getStatusText } from '../../../../utils/tasks'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import { TASK_LIST_ITEM_STATUS } from '../../../../consts/data-testids'

interface Props {
  taskId: string
}

const StatusIndicator = ({ taskId }: Props): ReactElement => {
  const status = useAppSelector((state) => getTaskStatus(state, taskId))
  const icon = getStatusIcon(status)
  const { getArrowProps, getTriggerProps, getTooltipProps, isVisible } = useTooltip({
    offset: [-4, 0],
    placement: 'bottom-start',
  })

  return (
    <>
      <SvgIcon
        {...getTriggerProps()}
        className={clsx({
          'text-rivaBlue-400': status === TaskStatus.TaskInProgress,
          'text-rivaOffblack-400': status === TaskStatus.TaskCancelled || status === TaskStatus.TaskNa,
          'text-rivaGreen-500': status === TaskStatus.TaskCompleted || status === TaskStatus.TaskCompleting,
          'text-rivaBlue-700': status === TaskStatus.TaskCreated || status === TaskStatus.TaskPending,
          'text-rivaFuchsia-500': status === TaskStatus.TaskErrored,
        })}
        data-status={status}
        data-testid={TASK_LIST_ITEM_STATUS}
        Icon={icon}
        size={IconSize.MEDIUM}
        title={isVisible ? undefined : getStatusText(status)}
      />
      <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()} className="z-50">
        {getStatusText(status)}
      </Tooltip>
    </>
  )
}

export default StatusIndicator

import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import clsx from 'clsx'
import { Menu, MenuItem } from '../../ui/menu'
import { Button, ButtonVariant, MenuButton } from '../../ui/button'
import { Page, PageHeader } from '../../ui/page'
import { ReactComponent as PlusCircle } from '../../icons/outline/plus.svg'
import { fetchPatients } from '../../../redux/thunks/patient'
import { RecordState } from '../../../types/record-state'
import { LoadingIndicator, LoadingIndicatorSize } from '../../ui/loading-indicator'
import { AddPatientModal } from '../../modals/add-patient'
import { PATIENT_LIST_HEADER } from '../../../consts/data-testids'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { IconSize, SvgIcon } from '../../ui/icon'
import { ReactComponent as IconChevronDown } from '../../icons/solid/chevron-down.svg'
import { downloadAllPatientBpReport, downloadChatSummaryReport } from '../../../redux/thunks/reports'
import { getListItemIds, getListRecordState, isListStale } from '../../../redux/selectors/lists'
import useGetListIdFromRoute from '../../../hooks/use-get-list-id-from-route'
import useMousetrap from '../../../hooks/use-mousetrap'
import { Routes } from '../../../types/route'
import { PatientView } from './patient-view'
import { Panel } from './panel'
import PatientFilterMenu from './panel/patient-filter-menu'

function PatientsHeader({ listId }: { listId: string }) {
  const [isOpen, setIsOpen] = useState(false)
  const auth0 = useAuth0()
  const dispatch = useAppDispatch()

  const onBpReportDownloadClick = useCallback(() => {
    dispatch(downloadAllPatientBpReport({ auth0 }))
  }, [auth0, dispatch])

  const onChatSummariesDownloadClick = useCallback(() => {
    dispatch(downloadChatSummaryReport({ auth0 }))
  }, [auth0, dispatch])

  const openCreatePatient = useCallback(() => setIsOpen(true), [])

  useMousetrap('c', openCreatePatient)

  return (
    <PageHeader>
      <h1 data-testid={PATIENT_LIST_HEADER}>Patients</h1>
      <div className="flex">
        <Button className="pr-4 mr-3" onClick={openCreatePatient}>
          <span className="mx-2">Create Patient</span>
          <SvgIcon Icon={PlusCircle} size={IconSize.MEDIUM} />
        </Button>
        <PatientFilterMenu className="mr-3" currentListId={listId} />
        <Menu
          align="end"
          menuButton={({ open }) => (
            <MenuButton active={open} className="pr-4" variant={ButtonVariant.SECONDARY}>
              <span className="mx-2">More Actions</span>
              <SvgIcon
                Icon={IconChevronDown}
                size={IconSize.MEDIUM}
                className={clsx('transition-transform', {
                  '-rotate-180': open,
                })}
              />
            </MenuButton>
          )}
        >
          <MenuItem>
            <a
              className="w-full"
              target="_blank"
              rel="noreferrer"
              href="https://rivahealth.github.io/riva-docs-internal"
            >
              Task Workflows
            </a>
          </MenuItem>
          <MenuItem onClick={onBpReportDownloadClick}>Download all patient BP data</MenuItem>
          <MenuItem onClick={onChatSummariesDownloadClick}>Download chat summaries</MenuItem>
        </Menu>
      </div>
      {isOpen && <AddPatientModal isOpen={isOpen} close={() => setIsOpen(false)} key={String(isOpen)} />}
    </PageHeader>
  )
}

export function Patients(): ReactElement {
  const dispatch = useAppDispatch()
  const listId = useGetListIdFromRoute()
  const recordState = useAppSelector((state) => getListRecordState(state, listId))
  const arePatientsEmpty = useAppSelector((state) => getListItemIds(state, listId).length === 0)
  const isStale = useAppSelector((state) => isListStale(state, listId))

  useEffect(() => {
    dispatch(fetchPatients(listId))
  }, [dispatch, listId])

  useEffect(() => {
    if (isStale) {
      dispatch(fetchPatients(listId))
    }
  }, [dispatch, isStale, listId])

  if (recordState === RecordState.DOES_NOT_EXIST || (arePatientsEmpty && recordState === RecordState.LOADING)) {
    return <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
  }

  return (
    <Page>
      <PatientsHeader listId={listId} />
      <Panel listId={listId} />

      <Switch>
        <Route path={Routes.PATIENT_LIST_PATIENT} component={PatientView}></Route>
        <Route path={Routes.DASHBOARD_PATIENT} component={PatientView}></Route>
      </Switch>
    </Page>
  )
}

import { ComponentProps, useCallback, useRef, useState } from 'react'
import { uuid } from '../../../utils/uuid'
import TabPanel from './tab-panel'
import TabList from './tab-list'

interface Props {
  initialSelectedIndex?: number
  selectedIndex?: number
}

interface TabProps {
  'aria-controls': string
  id: string
  onClick: () => void
  selected: boolean
}

interface UseTabsHook {
  getTabListProps: () => Omit<ComponentProps<typeof TabList>, 'children'>
  getTabPanelProps: (index: number) => Partial<ComponentProps<typeof TabPanel>>
  getTabProps: (index: number) => TabProps
  selectedIndex: number
}

const useTabs = ({ initialSelectedIndex = 0, selectedIndex: controlledSelectedIndex }: Props = {}): UseTabsHook => {
  const tabListIdRef = useRef<string>(uuid())
  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex)
  const calculatedSelectedIndex = typeof controlledSelectedIndex === 'number' ? controlledSelectedIndex : selectedIndex

  const getTabPanelId = (index: number) => `tabpanel-${tabListIdRef.current}-${index}`
  const getTabId = (index: number) => `tab-${tabListIdRef.current}-${index}`

  const getTabListProps = useCallback(
    () => ({
      calculatedSelectedIndex,
      selectedTabId: getTabId(calculatedSelectedIndex),
    }),
    [calculatedSelectedIndex],
  )

  const getTabPanelProps = useCallback(
    (index: number) => ({
      active: index === calculatedSelectedIndex,
      'aria-labelledby': getTabId(index),
      id: getTabPanelId(index),
    }),
    [calculatedSelectedIndex],
  )

  const getTabProps = useCallback(
    (index: number) => ({
      'aria-controls': getTabPanelId(index),
      id: getTabId(index),
      onClick: () => setSelectedIndex(index),
      selected: index === calculatedSelectedIndex,
    }),
    [calculatedSelectedIndex],
  )

  return {
    getTabListProps,
    getTabPanelProps,
    getTabProps,
    selectedIndex: calculatedSelectedIndex,
  }
}

export default useTabs

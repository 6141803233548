import { ReactElement, useReducer, useMemo } from 'react'
import useIsPersonInChannel from '../../../api/hooks/sendbird/use-is-person-in-channel'
import useGetListIdFromRoute from '../../../hooks/use-get-list-id-from-route'
import usePollForNewTasks from '../../../hooks/use-poll-for-new-tasks'
import { useAppSelector } from '../../../redux'
import { getLoggedInPersonId } from '../../../redux/selectors/app'
import { getListSelectedItemId } from '../../../redux/selectors/lists'
import { getPatientPrimaryChatChannel } from '../../../redux/selectors/patient'
import { getTaskById } from '../../../redux/selectors/tasks'
import RightRail, { RightRailReducer } from '../../ui/right-rail'
import { RightRailPanel } from '../../ui/right-rail/types'
import ToastContainer from '../../ui/toasts/toast-container'
import TaskList from './task-list'

const Tasks = (): ReactElement => {
  const listId = useGetListIdFromRoute()
  const [rightRailPanel, rightRailDispatch] = useReducer(RightRailReducer, {
    panel: null,
  })
  const selectedTaskId = useAppSelector((state) => getListSelectedItemId(state, listId))
  const selectedTask = useAppSelector((state) => (selectedTaskId ? getTaskById(state, selectedTaskId) : undefined))
  const selectedPatientId = selectedTask?.patientId ?? ''
  const loggedInPersonId = useAppSelector(getLoggedInPersonId)
  const channelUrl = useAppSelector((state) => getPatientPrimaryChatChannel(state, selectedPatientId))
  const { inChannel } = useIsPersonInChannel(channelUrl, loggedInPersonId)
  const panels = useMemo(() => {
    const panels: RightRailPanel[] = ['task-details', 'separator', 'patient-profile']

    if (inChannel) {
      panels.push('chat')
    }

    return panels
  }, [inChannel])

  usePollForNewTasks(listId)

  return (
    <div className="h-full flex flex-row">
      <ToastContainer />
      <TaskList listId={listId} rightRailDispatch={rightRailDispatch} />
      {selectedTaskId && (
        <RightRail
          panels={panels}
          channelUrl={channelUrl}
          listId={listId}
          onPanelButtonClick={rightRailDispatch}
          selectedPanel={rightRailPanel}
          patientId={selectedPatientId}
          variant="dark"
        />
      )}
    </div>
  )
}

export default Tasks

import { FormAction } from '../../../../types/form'
import formReducerFactory from '../form-reducer-factory'
import {
  ACTIVATE_PATIENT_FAILED,
  ACTIVATE_PATIENT_FORM_INITIALIZED,
  ACTIVATING_PATIENT,
  ACTIVATE_PATIENT_FULFILLED,
} from '../../../../types/patient'

export const activatePatientForm = formReducerFactory({
  [FormAction.INITIALIZED]: [ACTIVATE_PATIENT_FORM_INITIALIZED],
  [FormAction.SUBMITTING]: [ACTIVATING_PATIENT],
  [FormAction.SUBMITTED]: [ACTIVATE_PATIENT_FULFILLED],
  [FormAction.ERRORED]: [ACTIVATE_PATIENT_FAILED],
})

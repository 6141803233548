import { Auth0ContextInterface } from '@auth0/auth0-react'
import { DOWNLOAD_REPORT_FAILED, DOWNLOAD_REPORT_REQUESTED, DOWNLOAD_REPORT_SUCCEEDED } from '../../types/reports'
import { AppThunk } from '../reducers'

export const downloadReport =
  ({ auth0, urlPath }: { auth0: Auth0ContextInterface; urlPath: string }): AppThunk =>
  async (dispatch) => {
    dispatch({
      type: DOWNLOAD_REPORT_REQUESTED,
    })

    try {
      const accessToken = await auth0.getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${urlPath}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-CSRF-Token': 'true',
        },
      })

      if (!response.ok) {
        dispatch({
          type: DOWNLOAD_REPORT_FAILED,
          payload: {
            error: {
              message: `Failed to download report (Status ${response.status})`,
            },
          },
        })

        return
      }

      const url = URL.createObjectURL(await response.blob())
      dispatch({
        type: DOWNLOAD_REPORT_SUCCEEDED,
      })
      const link = document.createElement('a')
      link.href = url
      link.download = 'report.csv'
      link.click()
      URL.revokeObjectURL(url)
    } catch (err) {
      dispatch({
        type: DOWNLOAD_REPORT_FAILED,
        payload: {
          error: {
            message: `Failed to download report`,
          },
        },
      })
    }
  }

export const downloadAllPatientBpReport =
  ({ auth0 }: { auth0: Auth0ContextInterface }): AppThunk =>
  async (dispatch) =>
    dispatch(downloadReport({ auth0, urlPath: '/v1/reports/rolling-moving-average' }))

export const downloadChatSummaryReport =
  ({ auth0 }: { auth0: Auth0ContextInterface }): AppThunk =>
  async (dispatch) =>
    dispatch(downloadReport({ auth0, urlPath: '/v1/reports/chat-summaries?format=csv' }))

import { DateTime } from 'luxon'
import { FunctionComponent } from 'react'
import {
  CHANGE_STATUS_COMPLETED_KEYS,
  CHANGE_STATUS_IN_PROGRESS_KEYS,
  CHANGE_STATUS_NOT_APPLICABLE_KEYS,
  CHANGE_STATUS_NOT_STARTED_KEYS,
  CHANGE_STATUS_SKIPPED_KEYS,
} from '../components/pages/tasks/task-commands/keys'
import { Task, TaskQueryParam, TaskStatus } from '../types/task'
import { ReactComponent as XCircleIcon } from '../components/icons/outline/x-circle.svg'
import { ReactComponent as CheckCircleIcon } from '../components/icons/outline/check-circle.svg'
import { ReactComponent as NotStartedIcon } from '../components/icons/outline/not-started.svg'
import { ReactComponent as ExclamationCircleIcon } from '../components/icons/outline/exclamation-circle.svg'
import { ReactComponent as ArrowCircleIcon } from '../components/icons/outline/arrow-circle-broken-right.svg'
import { ReactComponent as InProgressIcon } from '../components/icons/outline/in-progress.svg'
import { parseListId } from './lists'

const queryParamTaskStatus: Record<string, TaskStatus[]> = {
  [TaskQueryParam.OPEN]: [TaskStatus.TaskCreated, TaskStatus.TaskPending, TaskStatus.TaskInProgress],
  [TaskQueryParam.CLOSED]: [
    TaskStatus.TaskCancelled,
    TaskStatus.TaskNa,
    TaskStatus.TaskCompleting,
    TaskStatus.TaskCompleted,
    TaskStatus.TaskCompletingSkipping,
    TaskStatus.TaskCompletedSkipped,
  ],
  [TaskQueryParam.ALL]: Object.values(TaskStatus),
}

export const getTaskStatusFromQueryParam = (queryParam: string): TaskStatus[] => queryParamTaskStatus[queryParam] ?? []

export const getStatusText = (status: TaskStatus): string => {
  switch (status) {
    case TaskStatus.TaskCancelled:
      return 'Cancelled'
    case TaskStatus.TaskCompleted:
    case TaskStatus.TaskCompleting:
      return 'Completed'
    case TaskStatus.TaskCompletedSkipped:
    case TaskStatus.TaskCompletingSkipping:
      return 'Skipped'
    case TaskStatus.TaskCreated:
      return 'Not started'
    case TaskStatus.TaskInProgress:
      return 'In progress'
    case TaskStatus.TaskNa:
      return 'Not applicable'
    case TaskStatus.TaskPending:
      return 'Not started'
    default:
      return 'Errored'
  }
}

export const getStatusIcon = (status: TaskStatus): FunctionComponent => {
  switch (status) {
    case TaskStatus.TaskCompleted:
    case TaskStatus.TaskCompleting:
      return CheckCircleIcon
    case TaskStatus.TaskCompletedSkipped:
    case TaskStatus.TaskCompletingSkipping:
      return ArrowCircleIcon
    case TaskStatus.TaskCreated:
    case TaskStatus.TaskPending:
      return NotStartedIcon
    case TaskStatus.TaskErrored:
      return ExclamationCircleIcon
    case TaskStatus.TaskInProgress:
      return InProgressIcon
    default:
      return XCircleIcon
  }
}

export const getStatusKeyboardHint = (status: TaskStatus): string => {
  switch (status) {
    case TaskStatus.TaskCreated:
      return CHANGE_STATUS_NOT_STARTED_KEYS
    case TaskStatus.TaskInProgress:
      return CHANGE_STATUS_IN_PROGRESS_KEYS
    case TaskStatus.TaskCompleted:
    case TaskStatus.TaskCompleting:
      return CHANGE_STATUS_COMPLETED_KEYS
    case TaskStatus.TaskCompletedSkipped:
    case TaskStatus.TaskCompletingSkipping:
      return CHANGE_STATUS_SKIPPED_KEYS
    default:
      return CHANGE_STATUS_NOT_APPLICABLE_KEYS
  }
}

export const isTaskStatusClosed = (status: TaskStatus): boolean =>
  status === TaskStatus.TaskCancelled ||
  status === TaskStatus.TaskCompleted ||
  status === TaskStatus.TaskCompleting ||
  status === TaskStatus.TaskCompletedSkipped ||
  status === TaskStatus.TaskCompletingSkipping ||
  status === TaskStatus.TaskErrored ||
  status === TaskStatus.TaskNa

export const isTaskDueDateOverdue = (dueDate: string): boolean => {
  // Tasks are considered overdue when the current time is after
  // 0:00 AM of the day after the due date in Eastern Time
  return (
    DateTime.fromISO(dueDate, {
      zone: 'America/New_York',
    }).diffNow('days').days < -1
  )
}

export const isTaskOverdue = (task: Task | undefined): boolean => {
  if (!task) {
    return false
  }

  if (isTaskStatusClosed(task.status) || !task.dueDate) {
    return false
  }

  return isTaskDueDateOverdue(task.dueDate)
}

export const getTaskListTitleByListId = (listId: string): string => {
  const query = parseListId(listId).query

  switch (query) {
    case TaskQueryParam.OPEN:
      return 'My open tasks'
    case TaskQueryParam.CLOSED:
      return 'My closed tasks'
    default:
      return 'All tasks'
  }
}

import { gql } from '../__generated__/gql'

export default gql(`#graphql
query medicationsPaidQuery($patientId: ID! $medicationType: MedicationType!) {
  patient(patientId: $patientId) {
    id
    medications(medicationType: $medicationType) {
      lastupdated
      medications {
        events {
          eventdate
          type
          userdisplayname
        }
        medication
        medicationentryid
        unstructuredsig
      }
    }
  }
}
`)

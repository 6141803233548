import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import type { TypedTypePolicies } from '../__generated__/graphql'
import { feature } from '../logger'

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/query`,
  preserveHeaderCase: true,
})

const logLink = new ApolloLink((operation, forward) => {
  const logger = feature('apollo-client', `GraphQL query: ${operation.operationName}`, {
    operationName: operation.operationName,
    query: operation.query,
    variables: operation.variables,
  })

  return forward(operation).map((result) => {
    if (result.errors) {
      logger.logFailure(new Error('Apollo Client failure'), {
        errors: result.errors,
      })
    } else {
      logger.logSuccess()
    }

    return result
  })
})

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      patient: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
    },
  },
}

const apolloClient = new ApolloClient({
  link: logLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies,
  }),
  connectToDevTools: process.env.REACT_APP_ENV_NAME === 'dev',
})

export default apolloClient

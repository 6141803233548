import Ajv, { JSONSchemaType } from 'ajv'
import { SWRResponse } from 'swr'
import { BloodPressureAverage } from '../../types/blood-pressure-moving-average'
import bloodPressureAverageSchema from '../schema/blood-pressure-average'
import useApi from './use-api'

const ajv = new Ajv()

export interface GetBloodPressureMovingAverage {
  data?: BloodPressureAverage[] | null
  baseline?: BloodPressureAverage | null
}

const schema: JSONSchemaType<GetBloodPressureMovingAverage> = {
  type: 'object',
  properties: {
    data: {
      type: 'array',
      items: bloodPressureAverageSchema,
      nullable: true,
    },
    baseline: {
      ...bloodPressureAverageSchema,
      nullable: true,
    },
  },
  required: [],
}

const validate = ajv.compile(schema)

const useGetBloodPressureMovingAverage = (
  patientId: string,
  { lookBack }: { lookBack?: number } = {},
): SWRResponse<GetBloodPressureMovingAverage> => {
  const query: Record<string, string[]> = {}

  if (lookBack) {
    query.lookBack = [String(lookBack)]
  }

  return useApi(patientId ? '/v2/patient/:patientId/bloodPressureMovingAverage' : null, {
    params: {
      patientId,
    },
    query,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    validate,
  })
}

export default useGetBloodPressureMovingAverage

import { JSONSchemaType } from 'ajv'
import { StudyEnrollment } from '../../types/study'

export const studyEnrollmentSchema: JSONSchemaType<StudyEnrollment> = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    studyId: {
      type: 'string',
    },
  },
  required: ['id', 'patientId', 'studyId'],
}

import { ReactElement } from 'react'
import { MedicationsQueryQuery } from '../../../../__generated__/graphql'

// See https://www.hl7.org/fhir/R4/valueset-units-of-time.html
const getUnit = (unit: string, plural: boolean): string => {
  switch (unit) {
    case 's':
      return plural ? 'seconds' : 'second'
    case 'min':
      return plural ? 'minutes' : 'minute'
    case 'h':
      return plural ? 'hours' : 'hour'
    case 'd':
      return plural ? 'days' : 'day'
    case 'wk':
      return plural ? 'weeks' : 'week'
    case 'mo':
      return plural ? 'months' : 'month'
    case 'a':
      return plural ? 'years' : 'year'
    default:
      return ''
  }
}

export type DosageInstructionType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<NonNullable<MedicationsQueryQuery['patient']>['medicationRequests']>[number]
    >['dosageInstruction']
  >[number]
>

interface Props {
  instruction: DosageInstructionType
}

const DosageInstruction = ({ instruction }: Props): ReactElement => {
  const { asNeededBoolean, doseAndRate, method, route, timing } = instruction
  const doseQuantity = doseAndRate?.[0]?.doseQuantity
  const { duration, durationUnit, frequency, period, periodUnit } = timing?.repeat ?? {}
  const routeDisplay = route?.coding?.[0]?.display

  // Visual reference from Athenahealth:
  // https://github.com/RivaHealth/riva-web/assets/347002/bf1cd470-1ff8-4884-bde4-422e1d24d66b
  return (
    <p>
      {method?.coding?.[0]?.display} {doseQuantity?.value} {doseQuantity?.unit}
      {!asNeededBoolean && timing?.repeat
        ? !frequency || frequency < 1
          ? null
          : frequency === 1
          ? ' once'
          : ` ${frequency} times`
        : null}
      {asNeededBoolean ? ' as needed' : null}
      {routeDisplay ? ` by ${routeDisplay.toLowerCase()}` : null}
      {!asNeededBoolean && timing?.repeat
        ? period
          ? ` every ${period > 1 ? `${period} ` : ''}${getUnit(periodUnit ?? '', period > 1)}`
          : null
        : null}
      {!asNeededBoolean && timing?.repeat
        ? duration
          ? ` for ${duration} ${getUnit(durationUnit ?? '', duration > 1)}`
          : null
        : null}
    </p>
  )
}

export default DosageInstruction

import { ReactElement } from 'react'
import { Redirect, Route, Switch, generatePath } from 'react-router-dom'
import { Routes } from '../types/route'
import { buildDefaultPatientListDescriptor, serializeListIdForRoute } from '../utils/lists'
import { TaskQueryParam } from '../types/task'
import { useAppSelector } from '../redux'
import { getLoggedInPractitionerId } from '../redux/selectors/app'
import useFlag from '../hooks/use-flag'
import { Features } from '../types/features'
import Search from './pages/search'
import { Patients } from './pages/patients'
import PatientListPaged from './pages/patients/panel/patient-list-paged'
import { Chat } from './pages/chat'
import Surveys from './pages/surveys'
import Tasks from './pages/tasks'
import { VIPs } from './pages/vip'

const Navigation = (): ReactElement => {
  const loggedInPractitionerId = useAppSelector(getLoggedInPractitionerId)
  const isPaginationEnabled = useFlag(Features.PATIENT_PAGES)
  const defaultPatientUrl = generatePath(Routes.PATIENT_LIST, {
    serializedListId: serializeListIdForRoute(buildDefaultPatientListDescriptor(loggedInPractitionerId)),
  })

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={
          isPaginationEnabled
            ? defaultPatientUrl
            : generatePath(Routes.DASHBOARD, {
                serializedListId: serializeListIdForRoute({
                  query: 'all',
                }),
              })
        }
      />
      <Route path={Routes.SEARCH} component={Search} />
      <Redirect
        exact
        from="/dashboard"
        to={generatePath(Routes.DASHBOARD, {
          serializedListId: serializeListIdForRoute({
            query: 'all',
          }),
        })}
      />
      <Route path={Routes.DASHBOARD} component={Patients} />
      <Redirect exact from="/patients" to={defaultPatientUrl} />
      <Route path={Routes.PATIENT_LIST} component={PatientListPaged} />
      <Route path="/chat" component={Chat} />
      <Redirect exact from="/surveys" to="/surveys/new" />
      <Route path="/surveys/:query" component={Surveys} />
      <Redirect exact from="/tasks" to={`/tasks/${TaskQueryParam.OPEN}`} />
      <Route path="/tasks/:query" component={Tasks} />
      <Route path="/vip" component={VIPs} />
    </Switch>
  )
}

export default Navigation

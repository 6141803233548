import { ComponentProps, MouseEventHandler, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ControlledMenu, MenuItem, useMenuState } from '../../../ui/menu'
import { ReactComponent as DotsIcon } from '../../../icons/outline/dots-horizontal.svg'
import { ButtonSize, ButtonVariant, MenuButton } from '../../../ui/button'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import ReassignTaskModal from '../../../modals/reassign-task'
import ChangeDueDateModal from '../../../modals/change-due-date-modal'
import useOnClickOutside from '../../../../hooks/use-on-click-outside'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { setCursor } from '../../../../redux/actions/lists'
import { PanelAction } from '../../../ui/right-rail/types'
import { getTaskStatus, getTaskSummary } from '../../../../redux/selectors/tasks'
import { getStatusText } from '../../../../utils/tasks'
import { track } from '../../../../i13n'

type MenuItemProps = ComponentProps<typeof MenuItem>

interface Props {
  isScrolling?: boolean
  listId: string
  rightRailDispatch: (action: PanelAction) => void
  taskId: string
}

const I13N_VIEW = {
  View: 'Task List',
}

const OverflowMenu = ({ isScrolling, listId, rightRailDispatch, taskId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const taskStatus = useAppSelector((state) => getStatusText(getTaskStatus(state, taskId)))
  const taskTitle = useAppSelector((state) => getTaskSummary(state, taskId))
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const { menuProps, closeMenu, toggleMenu } = useMenuState()
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    disabled: menuProps.state === 'open',
    offset: [-12, 6],
    placement: 'bottom-start',
  })
  const { ref: triggerRef, ...triggerProps } = getTriggerProps()
  const menuRef = useRef<HTMLElement>(null)
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false)
  const [isDueDateModalOpen, setIsDueDateModalOpen] = useState(false)

  const onAddNoteClick = useCallback(() => {
    closeMenu()

    rightRailDispatch({
      type: 'open',
      panel: 'task-details',
      autofocus: true,
    })

    dispatch(
      setCursor({
        id: taskId,
        listId,
      }),
    )

    track('Task List Add Note Clicked', {
      'Task ID': taskId,
      'Task Status': taskStatus,
      'Task Title': taskTitle,
    })
  }, [closeMenu, dispatch, listId, rightRailDispatch, taskId, taskStatus, taskTitle])
  const onReassignClick = useCallback<NonNullable<MenuItemProps['onClick']>>(
    (event) => {
      event.stopPropagation = true
      closeMenu()

      setIsReassignModalOpen(true)

      track('Task Change Due Date Clicked', {
        'Task ID': taskId,
        'Task Status': taskStatus,
        'Task Title': taskTitle,
        View: 'Task List',
      })
    },
    [closeMenu, taskId, taskStatus, taskTitle],
  )
  const onChangeDueDateClick = useCallback<NonNullable<MenuItemProps['onClick']>>(
    (event) => {
      event.stopPropagation = true
      closeMenu()

      setIsDueDateModalOpen(true)

      track('Task Change Due Date Clicked', {
        'Task ID': taskId,
        'Task Status': taskStatus,
        'Task Title': taskTitle,
        View: 'Task List',
      })
    },
    [closeMenu, taskId, taskStatus, taskTitle],
  )
  const onReassignTaskClose = useCallback(() => setIsReassignModalOpen(false), [])
  const onViewTaskDetailsClick = useCallback(() => {
    closeMenu()

    rightRailDispatch({
      type: 'open',
      panel: 'task-details',
    })

    dispatch(
      setCursor({
        id: taskId,
        listId,
      }),
    )

    track('Task List View Task Details Clicked', {
      'Task ID': taskId,
      'Task Status': taskStatus,
      'Task Title': taskTitle,
    })
  }, [closeMenu, dispatch, listId, rightRailDispatch, taskId, taskStatus, taskTitle])
  const onDueDateClose = useCallback(() => setIsDueDateModalOpen(false), [])
  const onMenuButtonClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      toggleMenu()

      track('Task List More Actions Clicked', {
        'Task ID': taskId,
        'Task Status': taskStatus,
        'Task Title': taskTitle,
      })
    },
    [taskId, taskStatus, taskTitle, toggleMenu],
  )
  const onMenuClick = useCallback<MouseEventHandler>((event) => event.stopPropagation(), [])

  useOnClickOutside(menuRef, closeMenu)

  useEffect(() => {
    if (isScrolling) {
      closeMenu()
    }
  }, [isScrolling, closeMenu])

  return (
    <>
      <MenuButton
        {...triggerProps}
        active={menuProps.state === 'open'}
        aria-label="More actions"
        className="!px-1"
        onClick={onMenuButtonClick}
        ref={(el) => {
          triggerRef(el)

          menuButtonRef.current = el
        }}
        size={ButtonSize.XXS}
        variant={ButtonVariant.SECONDARY}
      >
        <SvgIcon Icon={DotsIcon} size={IconSize.X_SMALL} />
        <Tooltip className="z-50" {...getTooltipProps()} arrowProps={getArrowProps()}>
          More actions
        </Tooltip>
      </MenuButton>
      <ControlledMenu
        {...menuProps}
        align="start"
        anchorRef={menuButtonRef}
        className="cursor-default"
        ref={menuRef}
        onClick={onMenuClick}
      >
        <MenuItem onClick={onAddNoteClick}>Add a note</MenuItem>
        <MenuItem onClick={onChangeDueDateClick}>Change due date</MenuItem>
        <MenuItem onClick={onReassignClick}>Reassign task</MenuItem>
        <MenuItem onClick={onViewTaskDetailsClick}>View task details</MenuItem>
      </ControlledMenu>
      {isReassignModalOpen ? (
        <ReassignTaskModal
          i13n={I13N_VIEW}
          isOpen={isReassignModalOpen}
          listId={listId}
          onClose={onReassignTaskClose}
          taskId={taskId}
          key={taskId}
        />
      ) : null}
      {isDueDateModalOpen ? (
        <ChangeDueDateModal
          i13n={I13N_VIEW}
          isOpen={isDueDateModalOpen}
          listId={listId}
          onClose={onDueDateClose}
          taskId={taskId}
          key={taskId}
        />
      ) : null}
    </>
  )
}

export default OverflowMenu

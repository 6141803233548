import { ReactElement } from 'react'
import cn from 'clsx'
import Lottie from 'lottie-react'
import { LOADING_INDICATOR } from '../../../consts/data-testids'
import loadingAnimation from './animation.json'

export enum LoadingIndicatorSize {
  LARGE = 'LOADING_SIZE_LARGE',
  MEDIUM = 'LOADING_SIZE_MEDIUM',
  SMALL = 'LOADING_SIZE_SMALL',
}

export enum LoadingIndicatorPosition {
  CENTER = 'LOADING_POSITION_CENTER',
  RIGHT = 'LOADING_POSITION_RIGHT',
  LEFT = 'LOADING_POSITION_LEFT',
}

interface LoadingIndicatorProps {
  flex?: boolean
  size?: LoadingIndicatorSize
  position?: LoadingIndicatorPosition
  className?: string
}

export function LoadingIndicator({
  flex = false,
  size = LoadingIndicatorSize.MEDIUM,
  position = LoadingIndicatorPosition.CENTER,
  className = '',
}: LoadingIndicatorProps): ReactElement | null {
  const baseClasses = 'w-full h-full flex items-center'
  const positionClass = getPositionClass(position)

  return (
    <div
      className={cn(
        baseClasses,
        positionClass,
        {
          'flex-1': flex,
        },
        className,
      )}
      data-testid={LOADING_INDICATOR}
    >
      <div className={getSizeClass(size)}>
        <Lottie animationData={loadingAnimation} />
      </div>
    </div>
  )
}

function getPositionClass(position: LoadingIndicatorPosition) {
  switch (position) {
    case LoadingIndicatorPosition.CENTER:
      return 'justify-center'
    case LoadingIndicatorPosition.RIGHT:
      return 'justify-end'
    case LoadingIndicatorPosition.LEFT:
      return ''
  }
}

function getSizeClass(size: LoadingIndicatorSize) {
  switch (size) {
    case LoadingIndicatorSize.SMALL:
      return 'h-6 w-8'
    case LoadingIndicatorSize.MEDIUM:
      return 'h-9 w-12'
    case LoadingIndicatorSize.LARGE:
      return 'h-12 w-16'
  }
}

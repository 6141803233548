import clsx from 'clsx'
import { MouseEventHandler, forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { I13n } from '../../../types/i13n'
import { track } from '../../../i13n'

interface Props extends Omit<NavLinkProps, 'className'> {
  i13n?: Partial<I13n>
  selected?: boolean
}

const LinkTab = forwardRef<HTMLAnchorElement, Props>(({ children, i13n, onClick, selected, ...props }, ref) => {
  const innerRef = useRef<HTMLAnchorElement>(null)

  useImperativeHandle<HTMLAnchorElement | null, HTMLAnchorElement | null>(ref, () => innerRef.current)

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      track(i13n?.eventName ?? 'Tab Clicked', {
        Tab: (event.target as HTMLAnchorElement).textContent ?? '',
        ...i13n?.properties,
      })
      onClick && onClick(event)
    },
    [i13n?.eventName, i13n?.properties, onClick],
  )

  return (
    <NavLink
      {...props}
      aria-selected={selected}
      className={clsx('text-sm ml-7 py-4 inline-block font-semibold', {
        'text-rivaOffblack-900': !selected,
        'text-rivaPurple-500': selected,
      })}
      onClick={handleClick}
      ref={ref}
      role="tab"
    >
      {children}
    </NavLink>
  )
})

export default LinkTab

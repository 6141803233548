import { useEffect, useState } from 'react'
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react'
import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler'
import type { GetSdk } from 'SendbirdUIKitGlobal'

const useGetTotalUnreadCount = (): number => {
  const [unreadCount, setUnreadCount] = useState(0)
  const context = useSendbirdStateContext()
  const sdk = sendbirdSelectors.getSdk(context) as GetSdk

  useEffect(() => {
    if (!sdk?.addUserEventHandler || !sdk?.groupChannel) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const count = await sdk.groupChannel.getTotalUnreadMessageCount()

      setUnreadCount(count)
    })()

    const userHandler = new UserEventHandler()

    userHandler.onTotalUnreadMessageCountUpdated = setUnreadCount

    sdk.addUserEventHandler('chat-unread-count', userHandler)

    return () => {
      sdk.removeUserEventHandler('chat-unread-count')
    }
  }, [sdk, sdk?.addUserEventHandler, sdk?.groupChannel])

  return unreadCount
}

export default useGetTotalUnreadCount

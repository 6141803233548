import { ReactElement } from 'react'
import useGetSurveys from '../../../../../api/hooks/use-get-surveys'
import SurveyAttachment from '../../../surveys/survey-attachment'
import { useAppSelector } from '../../../../../redux'
import { getIdBySubmissionId } from '../../../../../redux/selectors/surveys'

interface Props {
  submissionId: number
  surveyId: number
}

const TaskSurveyItem = ({ submissionId, surveyId }: Props): ReactElement | null => {
  const id = useAppSelector((state) => getIdBySubmissionId(state, submissionId))
  const { data: { surveys } = {} } = useGetSurveys()
  const surveyName = surveys?.find((survey) => survey.id === surveyId)?.name ?? `Survey ${surveyId}`

  if (!id) {
    return null
  }

  return (
    <article className="bg-rivaOffblack-100 text-sm text-rivaOffblack-900 col-start-3 col-span-2 px-3 py-2">
      <h5 className="text-rivaOffblack-900 text-xxs font-extrabold uppercase mb-1">{surveyName}</h5>
      <SurveyAttachment id={id} />
    </article>
  )
}

export default TaskSurveyItem

import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'
import useImageState from '../../hooks/use-image-state'
import { useAppSelector } from '../../redux'
import { getFirstName, getLastName } from '../../redux/selectors/person'
import { getPractitioner, getPractitionerPhotoUrl } from '../../redux/selectors/practitioner'
import { PractitionerPhotoSize } from '../../types/practitioner'
import { ReactComponent as UserIcon } from '../icons/solid/user.svg'
import { IconSize, SvgIcon } from './icon'

export enum AvatarSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  XL = 'XL',
}

interface Props extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'style'> {
  backgroundVariant?: 'white' | 'lightgray'
  border?: boolean
  colorVariant?: 'normal' | 'alert'
  practitionerId: string
  rounded?: boolean
  size?: AvatarSize
}

const stringToColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }

  return colour
}

const PractitionerAvatar = forwardRef<HTMLDivElement, Props>(
  (
    {
      backgroundVariant = 'white',
      border,
      className,
      colorVariant = 'normal',
      practitionerId,
      rounded = true,
      size = AvatarSize.MEDIUM,
      ...props
    },
    ref,
  ) => {
    const practitioner = useAppSelector((state) => getPractitioner(state, practitionerId))
    const firstName = useAppSelector((state) => getFirstName(state, practitioner?.personId ?? ''))
    const lastName = useAppSelector((state) => getLastName(state, practitioner?.personId ?? ''))
    const photoUrl = useAppSelector((state) =>
      getPractitionerPhotoUrl(
        state,
        practitionerId ?? '',
        size === AvatarSize.SMALL ? PractitionerPhotoSize.SMALL : PractitionerPhotoSize.MEDIUM,
      ),
    )
    const fallback = useImageState(photoUrl) !== 'loaded'

    return (
      <span
        {...props}
        data-practitionerid={practitionerId}
        data-url={photoUrl}
        className={clsx(
          'box-border text-xxs flex-none tracking-tighter inline-block text-center overflow-hidden',
          className,
          {
            'leading-5': size === AvatarSize.SMALL,
            'leading-6': size === AvatarSize.MEDIUM,
            'leading-10': size === AvatarSize.XL,
            border: border && size !== AvatarSize.XL,
            'border-2': border && size === AvatarSize.XL,
            'border-rivaOffblack-200': colorVariant === 'normal',
            'border-rivaYellow-600': colorVariant === 'alert',
            'w-5 h-5': size === AvatarSize.SMALL,
            'w-6 h-6': size === AvatarSize.MEDIUM,
            'w-10 h-10': size === AvatarSize.XL,
            'rounded-full': rounded,
            'text-rivaOffblack-400': !practitioner,
            'text-white': !!practitioner,
            'bg-white': backgroundVariant === 'white' && !(practitioner && fallback),
            'bg-rivaOffblack-200': backgroundVariant === 'lightgray' && !(practitioner && fallback),
          },
        )}
        style={{
          backgroundColor: practitioner && fallback ? stringToColor(firstName + lastName) : '',
        }}
        ref={ref}
      >
        {!practitioner ? <SvgIcon Icon={UserIcon} size={IconSize.SMALL} /> : null}
        {practitioner && fallback ? `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}` : null}
        {practitioner && !fallback ? (
          <img
            className={clsx('max-w-none', {
              'w-5 h-5': size === AvatarSize.SMALL,
              'w-6 h-6': size === AvatarSize.MEDIUM,
              'w-10 h-10': size === AvatarSize.XL,
            })}
            src={photoUrl}
            alt="Practitioner avatar"
          />
        ) : null}
      </span>
    )
  },
)

export default PractitionerAvatar

import { JSONSchemaType } from 'ajv'
import { Visit } from '../../types/visit'

export const visitSchema: JSONSchemaType<Visit> = {
  type: 'object',
  properties: {
    carePathwayId: {
      type: 'string',
    },
    createdAt: {
      type: 'string',
    },
    endTime: {
      type: 'string',
    },
    id: {
      type: 'string',
    },
    location: {
      type: 'string',
      nullable: true,
    },
    patientId: {
      type: 'string',
    },
    practitionerId: {
      type: 'string',
    },
    startTime: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
    visitType: {
      type: 'string',
    },
  },
  required: [
    'carePathwayId',
    'createdAt',
    'endTime',
    'id',
    'patientId',
    'practitionerId',
    'startTime',
    'status',
    'taskId',
    'updatedAt',
    'visitType',
  ],
}

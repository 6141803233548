import { JSONSchemaType } from 'ajv'
import { TaskNote } from '../../types/task'
import { auditStatusSchema } from './audit-status'

export const taskNoteSchema: JSONSchemaType<TaskNote> = {
  type: 'object',
  properties: {
    body: {
      type: 'string',
    },
    created: auditStatusSchema,
    id: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
    updated: auditStatusSchema,
  },
  required: ['body', 'created', 'id', 'taskId', 'updated'],
}

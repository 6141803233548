import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
  colspan?: 1 | 2
  hint?: string
  title: string
}

const DetailsItem = ({ children, colspan, hint, title }: Props): ReactElement => {
  return (
    <div
      className={clsx('flex flex-col items-start', {
        'col-span-2': colspan === 2,
      })}
    >
      <div className="text-xxs leading-4 font-semibold text-rivaOffblack-500" title={hint}>
        {title}
      </div>
      <div className="text-sm font-medium text-md">{children}</div>
    </div>
  )
}

export default DetailsItem

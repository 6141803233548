import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { selectIds, selectRecordState } from '../../../../redux/selectors/studies'
import { buildEligibleStudiesListId, buildEnrolledStudiesListId } from '../../../../utils/lists'
import { MenuItem } from '../../../ui/menu'
import { RecordState } from '../../../../types/record-state'
import { enrollToStudy, fetchStudies, withdrawFromStudy } from '../../../../redux/thunks/study'
import WithdrawFromStudyModal from '../../../modals/withdraw-from-study-modal'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import useStudiesApi from '../../../../api/hooks/use-studies-api'

const INSIGHTS_STUDY_ID = 'CP-0003'

interface Props {
  patientId: string
}

const StudyEnrollmentMenuItem = ({ patientId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const apolloClient = useApolloClient()
  const studiesApi = useStudiesApi()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = useCallback(() => setIsModalOpen(true), [])
  const closeModal = useCallback(() => setIsModalOpen(false), [])

  const eligibleListId = buildEligibleStudiesListId(patientId)
  const enrolledListId = buildEnrolledStudiesListId(patientId)

  const recordState = useAppSelector((state) => selectRecordState(state, eligibleListId))
  const eligibleIds = useAppSelector((state) => selectIds(state, eligibleListId))
  const enrolledIds = useAppSelector((state) => selectIds(state, enrolledListId))

  const isEligible = eligibleIds.includes(INSIGHTS_STUDY_ID)
  const isEnrolled = enrolledIds.includes(INSIGHTS_STUDY_ID)
  const isLoading = !eligibleIds.length && recordState !== RecordState.LOADED

  const enroll = useCallback(() => {
    dispatch(
      enrollToStudy({
        listId: enrolledListId,
        studyId: INSIGHTS_STUDY_ID,
        studiesApi,
      }),
    )
  }, [dispatch, enrolledListId, studiesApi])

  const withdraw = useCallback(
    (withdrawDate: string) => {
      closeModal()

      dispatch(
        withdrawFromStudy({
          listId: enrolledListId,
          studyId: INSIGHTS_STUDY_ID,
          studiesApi,
          withdrawDate,
        }),
      )
    },
    [closeModal, dispatch, enrolledListId, studiesApi],
  )

  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    disabled: isEligible,
    offset: [0, 8],
    placement: 'left',
  })

  useEffect(() => {
    dispatch(
      fetchStudies({
        apolloClient,
        eligibleListId,
        enrolledListId,
      }),
    )
  }, [dispatch, apolloClient, eligibleListId, enrolledListId])

  return (
    <>
      <MenuItem disabled={isLoading || !isEligible} onClick={isEnrolled ? openModal : enroll}>
        <span {...getTriggerProps()}>{isEnrolled ? 'Withdraw from Insights Study' : 'Enroll in Insights Study'}</span>
      </MenuItem>
      <Tooltip {...getTooltipProps()} className="z-[2323223]" arrowProps={getArrowProps()}>
        This patient is not eligible
      </Tooltip>
      <WithdrawFromStudyModal
        isOpen={isModalOpen}
        onAccept={withdraw}
        onCancel={closeModal}
        patientId={patientId}
        studyId={INSIGHTS_STUDY_ID}
      />
    </>
  )
}

export default StudyEnrollmentMenuItem

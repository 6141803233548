import { ReactElement, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getTaskSummary } from '../../../../redux/selectors/tasks'
import { TaskStatusUpdateSuccessToast as TaskStatusUpdateSuccessToastType } from '../../../../types/toast'
import { getStatusText } from '../../../../utils/tasks'
import { SvgIcon } from '../../icon'
import Toast from '../toast'
import { ReactComponent as CheckCircleIcon } from '../../../icons/outline/check-circle.svg'
import { Button, ButtonVariant } from '../../button'
import { TaskStatus } from '../../../../types/task'
import { cancelTaskEvent } from '../../../../redux/thunks/tasks'
import useTasksApi from '../../../../api/hooks/use-tasks-api'
import { closeToast } from '../../../../redux/actions/ui'
import { track } from '../../../../i13n'

interface Props extends TaskStatusUpdateSuccessToastType {
  expanded: boolean
}

const TaskStatusUpdateSuccessToast = ({ action, expanded, ...rest }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const { id, event, listId, status } = action.payload
  const subtitle = useAppSelector((state) => getTaskSummary(state, id))
  const onUndo = useCallback(() => {
    dispatch(
      cancelTaskEvent(
        {
          id: id,
          eventId: event.id,
          listId: listId,
        },
        tasksApi,
      ),
    )
    dispatch(
      closeToast({
        id: rest.id,
      }),
    )

    track('Task Toast Undo Clicked')
  }, [dispatch, id, event.id, listId, tasksApi, rest.id])

  return (
    <Toast
      {...rest}
      buttonRight={
        status === TaskStatus.TaskCompleting ? (
          <Button onClick={onUndo} variant={ButtonVariant.TEXT}>
            Undo
          </Button>
        ) : null
      }
      expanded={expanded}
      icon={<SvgIcon Icon={CheckCircleIcon} className="text-rivaGreen-500" />}
      title={`Task marked as ${getStatusText(status)}`}
      timeout={10000}
      subtitle={subtitle}
    />
  )
}

export default TaskStatusUpdateSuccessToast

import { ReactElement, useCallback, useEffect } from 'react'
import { SvgIcon } from '../../ui/icon'
import { ReactComponent as EmojiSadIcon } from '../../icons/outline/emoji-sad.svg'
import { useAppDispatch } from '../../../redux'
import { resetList } from '../../../redux/actions/tasks'
import { fetchTasks } from '../../../redux/thunks/tasks'
import useTasksApi from '../../../api/hooks/use-tasks-api'
import { Button } from '../../ui/button'
import { track } from '../../../i13n'

interface Props {
  listId: string
}

const TaskListErrorState = ({ listId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const onClick = useCallback(() => {
    dispatch(
      resetList({
        listId,
      }),
    )

    dispatch(fetchTasks({ listId, offset: 0 }, tasksApi))

    track('Task Error Refresh Clicked')
  }, [dispatch, listId, tasksApi])

  useEffect(() => {
    track('Task Error Shown')
  }, [])

  return (
    <div className="flex-1 bg-white flex flex-col items-center justify-center">
      <SvgIcon className="text-rivaRed-400" Icon={EmojiSadIcon} />
      <h2 className="text-sm font-semibold m-0">Something went wrong</h2>
      <p className="text-sm font-medium text-rivaOffblack-500">Please try again</p>
      <Button className="mt-7" onClick={onClick}>
        Refresh page
      </Button>
    </div>
  )
}

export default TaskListErrorState

import { ReactElement } from 'react'
import { DateTime } from 'luxon'
import { useQuery } from '@apollo/client'
import ErrorState from '../error-state'
import { LoadingIndicator, LoadingIndicatorSize } from '../../../ui/loading-indicator'
import FormattedDateTime from '../../../ui/formatted-date-time'
import useTabs from '../../../ui/tabs/use-tabs'
import { Tab, TabList } from '../../../ui/tabs'
import EmptyState from '../empty-state'
import medicationsQuery from '../../../../schemas/medications-query'
import { MedicationStatus } from '../../../../types/chart'
import MedicationRequest from './medication-request'

interface Props {
  patientId: string
}

const MedicationsCurrentI13n = {
  eventName: 'Tab - Medications - Current - Clicked',
}
const MedicationsHistoricalI13n = {
  eventName: 'Tab - Medications - Historical - Clicked',
}

const MedicationsTab = ({ patientId }: Props): ReactElement => {
  const { getTabListProps, getTabProps, selectedIndex } = useTabs()
  const { data, loading, error } = useQuery(medicationsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      patientId,
    },
  })

  if (error) {
    return <ErrorState />
  }

  if (!data && loading) {
    return (
      <div className="h-full">
        <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
      </div>
    )
  }

  const lastUpdated =
    data?.patient?.medicationRequests?.reduce((last, next) => {
      const lastDateTime = DateTime.fromISO(last)
      const nextDateTime = DateTime.fromISO(next?.meta?.lastUpdated ?? '')

      if (!nextDateTime.isValid) {
        return last
      }

      if (!lastDateTime.isValid) {
        return next?.meta?.lastUpdated ?? ''
      }

      return nextDateTime.diff(lastDateTime).milliseconds > 0 ? next?.meta?.lastUpdated ?? '' : last
    }, '') ?? ''

  const meds = data?.patient?.medicationRequests?.filter((med) => {
    if (!med) {
      return false
    }

    if (med.status === MedicationStatus.ENTERED_IN_ERROR) {
      return false
    }

    const isActive =
      med.status === MedicationStatus.ACTIVE ||
      med.status === MedicationStatus.DRAFT ||
      med.status === MedicationStatus.COMPLETED

    return selectedIndex === 0 ? isActive : !isActive
  })

  return (
    <section>
      <TabList {...getTabListProps()} className="bg-rivaOffblack-50">
        <Tab i13n={MedicationsCurrentI13n} {...getTabProps(0)}>
          Current
        </Tab>
        <Tab i13n={MedicationsHistoricalI13n} {...getTabProps(1)}>
          Historical
        </Tab>
      </TabList>
      {meds?.length ? (
        <>
          <header className="pt-7 px-7 pb-3">
            <h2 className="text-lg font-semibold m-0 text-rivaOffblack-900">
              {selectedIndex === 0 ? 'Current' : 'Historical'} medications
            </h2>
            {lastUpdated ? (
              <p className="text-sm font-medium text-rivaOffblack-500">
                Updated <FormattedDateTime value={lastUpdated} day="numeric" month="numeric" year="numeric" />
              </p>
            ) : null}
          </header>
          <main className="mx-7">
            {meds.map((med) => {
              if (!med?.id) {
                return null
              }

              return <MedicationRequest key={med.id} medicationRequests={med} />
            })}
          </main>
        </>
      ) : (
        <EmptyState />
      )}
    </section>
  )
}

export default MedicationsTab

import { ReactElement } from 'react'
import { useAppSelector } from '../../../../redux'
import { getTaskSummary } from '../../../../redux/selectors/tasks'
import {
  TaskChoiceSelectSuccessToast as TaskChoiceSelectSuccessToastType,
  TaskChoiceSelectFailedToast as TaskChoiceSelectFailedToastType,
} from '../../../../types/toast'
import { getStatusText } from '../../../../utils/tasks'
import { SvgIcon } from '../../icon'
import Toast from '../toast'
import { ReactComponent as CheckCircleIcon } from '../../../icons/outline/check-circle.svg'
import { ReactComponent as XCircleIcon } from '../../../icons/outline/x-circle.svg'
import { TaskStatus } from '../../../../types/task'

export const TaskChoiceSelectSuccessToast = ({
  action,
  ...rest
}: TaskChoiceSelectSuccessToastType & { expanded: boolean }): ReactElement => {
  const { id } = action.payload
  const subtitle = useAppSelector((state) => getTaskSummary(state, id))

  return (
    <Toast
      {...rest}
      icon={<SvgIcon Icon={CheckCircleIcon} className="text-rivaGreen-500" />}
      title={`Task marked as ${getStatusText(TaskStatus.TaskCompleted)}`}
      subtitle={subtitle}
    />
  )
}

export const TaskChoiceSelectFailedToast = ({
  action,
  ...rest
}: TaskChoiceSelectFailedToastType & { expanded: boolean }): ReactElement => {
  const { id } = action.payload
  const subtitle = useAppSelector((state) => getTaskSummary(state, id))

  return (
    <Toast
      {...rest}
      icon={<SvgIcon Icon={XCircleIcon} className="text-rivaFuchsia-500" />}
      title={`Failed to complete task`}
      subtitle={subtitle}
    />
  )
}

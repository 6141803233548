import { ReactElement } from 'react'
import useTooltip from '../../../../hooks/use-tooltip'
import { useAppSelector } from '../../../../redux'
import { getPractitionerName, getPractitionerShortName } from '../../../../redux/selectors/practitioner'
import PractitionerAvatar, { AvatarSize } from '../../../ui/practitioner-avatar'
import Tooltip from '../../../ui/tooltip'
import Pill from '../../../ui/pill'

interface Props {
  isOnDarkBackground?: boolean
  practitionerId: string
  selected?: boolean
}
const Assignee = ({ isOnDarkBackground, practitionerId, selected }: Props): ReactElement => {
  const assigneeName = useAppSelector((state) => getPractitionerName(state, practitionerId))
  const shortName = useAppSelector((state) => getPractitionerShortName(state, practitionerId))
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [-12, 6],
    placement: 'bottom-start',
  })

  return (
    <Pill
      {...getTriggerProps()}
      className="pl-[2px]"
      data-assignee={practitionerId}
      isOnDarkBackground={isOnDarkBackground}
      variant={selected ? 'inverted' : 'normal'}
    >
      <PractitionerAvatar practitionerId={practitionerId} rounded size={AvatarSize.SMALL} key={practitionerId} />
      <span className="ml-2">{shortName || 'Unassigned'}</span>
      <Tooltip className="z-50" {...getTooltipProps()} arrowProps={getArrowProps()}>
        {assigneeName ? `Assigned to ${assigneeName}` : 'Assign to'}
      </Tooltip>
    </Pill>
  )
}

export default Assignee

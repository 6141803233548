import { ReactElement, useEffect, useState } from 'react'
import clsx from 'clsx'
import deepEquals from 'lodash.isequal'
import { DateTime } from 'luxon'
import { Modal } from '../ui/modal'
import { updatePatientFormInitialized } from '../../redux/actions/patient'
import { FormIds, FormSubmissionState } from '../../types/form'
import { getFormSubmissionState } from '../../redux/selectors/form'
import { LoadingIndicator, LoadingIndicatorPosition } from '../ui/loading-indicator'
import { Button, ButtonVariant } from '../ui/button'
import {
  CommunicationPreferences,
  AcquisitionChannel,
  BloodPressureGoal,
  PatientType,
  MaritalStatus,
  LanguageISO6392Code,
  HL7RaceCode,
  HL7EthniticyCode,
  ReminderTimes,
} from '../../types/patient'
import {
  getPatientAcquisitionChannel,
  getPatientBloodPressureGoal,
  getPatientBloodPressureManagementStatus,
  getPatientBloodPressureNotificationDisabled,
  getPatientCareTeam,
  getPatientCommunicationPreferences,
  getPatientDateOfBirth,
  getPatientDepartmentId,
  getPatientDeviceType,
  getPatientEmail,
  getPatientFirstName,
  getPatientLastName,
  getPatientMedicalRecordNumber,
  getPatientPhoneNumber,
  getPatientPreferredName,
  getPatientPronouns,
  getPatientReferringProvider,
  getPatientReminderTimes,
  getPatientStatus,
  getPatientType,
  getPatientWirelessCuffId,
} from '../../redux/selectors/patient'
import { getAllPractitionerNamesAndIds } from '../../redux/selectors/practitioner'
import { updatePatient } from '../../redux/thunks/patient'
import { DeviceTypes } from '../ui/device-types'
import { useAppDispatch, useAppSelector } from '../../redux'
import FieldSet from '../ui/field-set'
import FormLabel from '../ui/form-label'
import TextInput from '../ui/text-input'
import { Option, Select } from '../ui/select'
import useToggle from '../../hooks/use-toggle'
import { IconSize, SvgIcon } from '../ui/icon'
import { ReactComponent as ChevronDown } from '../icons/solid/chevron-down.svg'
import { ReactComponent as InformationCircle } from '../icons/solid/information-circle.svg'
import Checkbox from '../ui/checkbox'
import { ModalContainer } from '../ui/modal/modal-container'
import ModalFooter from '../ui/modal/modal-footer'
import FormSection from '../ui/form-section'
import { StateAbbreviations, athenaDateToIsoDate, formatPhoneNumber } from '../../utils/l10n'
import WirelessCuffSelector from '../ui/forms/wireless-cuff-selector'
import { getProviderIdsAndNames } from '../../redux/selectors/provider'
import { ReferringProvider } from '../../types/provider'
import MedicalRecordNumberInput from '../ui/forms/medical-record-number-input'
import { CommunicationPreferencesSelector } from '../ui/forms/communication-preferences'
import DepartmentIdSelector from '../ui/forms/department-id-selector'
import AcquisitionChannelRadioGroup from '../ui/forms/acquisition-channel-radio-group'
import FormTitle from '../ui/forms/form-title'
import PronounsSelector from '../ui/forms/pronouns-selector'
import DateInput from '../ui/date-input'
import { PATIENT_FIRST_NAME_INPUT } from '../../consts/data-testids'
import { getUpdatedPronouns, shouldHaveReferringProvider } from '../../utils/patient'
import StateSelector from '../ui/forms/state-selector'
import EmergencyContactRelationshipSelector, {
  EmergencyContactRelationship,
} from '../ui/forms/emergency-contact-relationship-selector'
import { Radio, RadioGroup, RadioLabel, useRadioGroup } from '../ui/radio'
import LanguageSelector from '../ui/forms/language-selector'
import RaceSelector from '../ui/forms/race-selector'
import EthnicitySelector from '../ui/forms/ethnicity-selector'
import ConsentRadioGroup from '../ui/forms/consent-radio-group'
import useHasAthenaProfile from '../../hooks/use-has-athena-profile'
import { PatientAthenaProfilePaidQueryQuery } from '../../__generated__/graphql'
import ReminderTimeSelector from '../ui/forms/reminder-time-selector'
import Confirm from './confirm'

interface UpdatePatientModalProps {
  athenaProfile?: NonNullable<PatientAthenaProfilePaidQueryQuery['patient']>['athenaProfile']
  isOpen: boolean
  close: () => void
  patientId: string
}

function getInitialMinute(minute: number) {
  if (minute !== 0 && minute !== 15 && minute !== 30 && minute !== 45) {
    return -1
  }

  return minute
}

export function UpdatePatientModal({ athenaProfile, isOpen, close, patientId }: UpdatePatientModalProps): ReactElement {
  const dispatch = useAppDispatch()
  const status = useAppSelector((state) => getPatientStatus(state, patientId))
  const practitioners = useAppSelector(getAllPractitionerNamesAndIds, deepEquals)

  const initialBloodPressureNotificationDisabled = useAppSelector((state) =>
    getPatientBloodPressureNotificationDisabled(state, patientId),
  )
  const initialDeviceType = useAppSelector((state) => getPatientDeviceType(state, patientId))
  const initialCommunicationPreferences = useAppSelector((state) =>
    getPatientCommunicationPreferences(state, patientId),
  )
  const initialReminderTimes = useAppSelector((state) => getPatientReminderTimes(state, patientId))
  const initialFirstName = useAppSelector((state) => getPatientFirstName(state, patientId))
  const initialLastName = useAppSelector((state) => getPatientLastName(state, patientId))
  const initialPreferredName = useAppSelector((state) => getPatientPreferredName(state, patientId))
  const initialPronouns = useAppSelector((state) => getPatientPronouns(state, patientId))
  const initialEmail = useAppSelector((state) => getPatientEmail(state, patientId))
  const initialMrn = useAppSelector((state) => getPatientMedicalRecordNumber(state, patientId))
  const initialBpGoal = useAppSelector((state) => getPatientBloodPressureGoal(state, patientId))
  const initialPatientType = useAppSelector((state) => getPatientType(state, patientId))
  const initialAcquisitionChannel = useAppSelector((state) => getPatientAcquisitionChannel(state, patientId))
  const initialReferringProvider = useAppSelector((state) => getPatientReferringProvider(state, patientId))
  const initialDateOfBirth = useAppSelector((state) => getPatientDateOfBirth(state, patientId))
  const initialDeparmentId = useAppSelector((state) => getPatientDepartmentId(state, patientId))
  const initialCareTeam = useAppSelector((state) => getPatientCareTeam(state, patientId), deepEquals)
  const [dateOfBirth, setDateOfBirth] = useState(initialDateOfBirth)
  const initialWirelessCuffId = useAppSelector((state) => getPatientWirelessCuffId(state, patientId))
  const phoneNumber = useAppSelector((state) => getPatientPhoneNumber(state, patientId))
  const bloodPressureManagementStatus = useAppSelector((state) =>
    getPatientBloodPressureManagementStatus(state, patientId),
  )
  const patientFormState = useAppSelector((state) => getFormSubmissionState(state, FormIds.UPDATE_PATIENT))
  const providers = useAppSelector((state) => getProviderIdsAndNames(state))

  const [acquisitionChannel, setAcquisitionChannel] = useState<AcquisitionChannel>(
    initialAcquisitionChannel as AcquisitionChannel,
  )
  const [patientType, setPatientType] = useState(initialPatientType)
  const hasAthenaProfile = useHasAthenaProfile(acquisitionChannel, patientType)

  // Devices taken from https://github.com/devicekit/DeviceKit/blob/master/Source/Device.generated.swift#L1106
  const [bpNotifDisabled, setBpNotifDisabled] = useState(initialBloodPressureNotificationDisabled)
  const [email, setEmail] = useState(initialEmail)
  const [mrn, setMrn] = useState(initialMrn)
  const [firstName, setFirstName] = useState(initialFirstName)
  const [lastName, setLastName] = useState(initialLastName)
  const [preferredName, setPreferredName] = useState(initialPreferredName)
  const [pronouns, setPronouns] = useState<string>(getUpdatedPronouns(initialPronouns))
  const [deviceType, setDeviceType] = useState(initialDeviceType)
  const [communicationPreferencesDays, setComunicationPreferencesDays] = useState(
    initialCommunicationPreferences.dayOfWeek,
  )
  const [amReminderHour, setAmReminderHour] = useState(initialReminderTimes.am.hour || -1)
  const [amReminderMinute, setAmReminderMinute] = useState(getInitialMinute(initialReminderTimes.am.minute))
  const [pmReminderHour, setPmReminderHour] = useState(initialReminderTimes.pm.hour || -1)
  const [pmReminderMinute, setPmReminderMinute] = useState(getInitialMinute(initialReminderTimes.pm.minute))
  const [referringProvider, setReferringProvider] = useState(initialReferringProvider)
  const [wirelessCuffId, setWirelessCuffId] = useState(initialWirelessCuffId ?? '')
  const [isWirelessCuffIdInvalid, setIsWirelessCuffIdInvalid] = useState(false)
  const [coachId, setCoachId] = useState(initialCareTeam.coach.id)
  const [pharmacistId, setPharmacistId] = useState(initialCareTeam.pharmacist.id)
  const [prescriberId, setPrescriberId] = useState(initialCareTeam.prescriber.id)
  const [departmentId, setDepartmentId] = useState(initialDeparmentId)

  const initialSystolicBloodPressure = initialBpGoal?.systolicBloodPressure ? initialBpGoal?.systolicBloodPressure : 0
  const initialDiastolicBloodPressure = initialBpGoal?.diastolicBloodPressure
    ? initialBpGoal?.diastolicBloodPressure
    : 0
  const [systolicGoal, setSystolicGoal] = useState(initialSystolicBloodPressure)
  const [diastolicGoal, setDiastolicGoal] = useState(initialDiastolicBloodPressure)
  const [isAdministrativeVisible, toggleAdministrativeVisible] = useToggle()
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  const [driversLicenseNumber, setDriversLicenseNumber] = useState(athenaProfile?.driversLicenseNumber ?? '')
  const isoDriversLicenseExpirationDate = athenaProfile?.driversLicenseExpirationDate
    ? athenaDateToIsoDate(athenaProfile.driversLicenseExpirationDate)
    : ''
  const [driversLicenseExpirationDate, setDriversLicenseExpirationDate] = useState(isoDriversLicenseExpirationDate)
  const [driversLicenseStateId, setDriversLicenseStateIdId] = useState(
    (athenaProfile?.driversLicenseStateId ?? '') as StateAbbreviations,
  )
  const [streetAddress, setStreetAddress] = useState(athenaProfile?.address1 ?? '')
  const [city, setCity] = useState(athenaProfile?.city ?? '')
  const [locationState, setLocationState] = useState<StateAbbreviations>(
    (athenaProfile?.state ?? '') as StateAbbreviations,
  )
  const [zipCode, setZipCode] = useState(athenaProfile?.zip ?? '')
  const [emergencyContactName, setEmergencyContactName] = useState(athenaProfile?.contactName ?? '')
  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] = useState(
    athenaProfile?.contactMobilePhone ?? '',
  )
  const [emergencyContactRelationship, setEmergencyContactRelationship] = useState<
    EmergencyContactRelationship | undefined
  >(athenaProfile?.contactRelationship as EmergencyContactRelationship)
  const [consentToCall, setConsentToCall] = useState<'Yes' | 'No' | undefined>(
    athenaProfile?.consentToCall === true ? 'Yes' : athenaProfile?.consentToCall === false ? 'No' : undefined,
  )
  const [consentToText, setConsentToText] = useState<'Yes' | 'No' | undefined>(
    athenaProfile?.consentToText === true ? 'Yes' : athenaProfile?.consentToText === false ? 'No' : undefined,
  )
  const [legalSex, setLegalSex] = useState<string | undefined>(athenaProfile?.sex ?? undefined)
  const legalSexRadioGroup = useRadioGroup({
    onChange: setLegalSex,
    value: legalSex,
  })
  const [sexAssignedAtBirth, setSexAssignedAtBirth] = useState<string | undefined>(
    athenaProfile?.assignedAtBirthSex ?? undefined,
  )
  const sexAssignedAtBirthRadioGroup = useRadioGroup({
    onChange: setSexAssignedAtBirth,
    value: sexAssignedAtBirth,
  })
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus | undefined>(
    (athenaProfile?.maritalStatus ?? undefined) as MaritalStatus | undefined,
  )
  const [language, setLanguage] = useState<LanguageISO6392Code | undefined>(
    (athenaProfile?.language6392Code ?? undefined) as LanguageISO6392Code | undefined,
  )
  const [race, setRace] = useState<HL7RaceCode | undefined>(
    (athenaProfile?.race?.[0] ?? undefined) as HL7RaceCode | undefined,
  )
  const [ethnicity, setEthnicity] = useState<HL7EthniticyCode | undefined>(
    (athenaProfile?.ethnicityCodes?.[0] ?? undefined) as HL7EthniticyCode | undefined,
  )

  const hasReferringProvider = shouldHaveReferringProvider(acquisitionChannel)

  const departmentIdInvalid = hasAthenaProfile && !departmentId
  const dateOfBirthInvalid = !dateOfBirth
  const acquisitionChannelInvalid = !acquisitionChannel
  const referringProviderInvalid = hasReferringProvider && !referringProvider
  const careTeamInvalid = !coachId || !prescriberId || !pharmacistId

  useEffect(() => {
    if (isOpen) {
      dispatch(updatePatientFormInitialized())
    }
  }, [isOpen, dispatch])

  useEffect(() => {
    if (patientFormState === FormSubmissionState.SUBMITTED) {
      dispatch(updatePatientFormInitialized())
      close()
    }
  }, [patientFormState, close, dispatch])

  useEffect(() => {
    if (!hasReferringProvider) {
      setReferringProvider('')
    }
  }, [hasReferringProvider])

  useEffect(() => {
    if (patientType === PatientType.VIP) {
      setAcquisitionChannel(AcquisitionChannel.N_A)
    }
  }, [patientType])

  useEffect(() => {
    if (!hasAthenaProfile && departmentId) {
      setDepartmentId('')
    }
  }, [departmentId, hasAthenaProfile])

  const dispatchUpdatePatient = () => {
    const reminderTimes: ReminderTimes = {
      am: {
        hour: amReminderHour,
        minute: amReminderMinute,
      },
      pm: {
        hour: pmReminderHour,
        minute: pmReminderMinute,
      },
    }

    const communicationPreferences: CommunicationPreferences = {
      dayOfWeek: communicationPreferencesDays,
      hour: '',
    }
    const bpGoal: BloodPressureGoal | null =
      systolicGoal && diastolicGoal
        ? {
            systolicBloodPressure: systolicGoal,
            diastolicBloodPressure: diastolicGoal,
            updated: initialBpGoal!.updated,
          }
        : {
            systolicBloodPressure: 0,
            diastolicBloodPressure: 0,
            updated: initialBpGoal!.updated,
          }

    dispatch(
      updatePatient(patientId, {
        firstName,
        lastName,
        pronouns,
        email,
        departmentId,
        deviceType,
        status,
        reminderTimes,
        communicationPreferences,
        bloodPressureManagementStatus,
        bloodPressureGoal: bpGoal,
        medicalRecordNumber: mrn,
        type: patientType,
        bloodPressureNotificationDisabled: bpNotifDisabled,
        acquisitionChannel,
        referringProvider,
        dateOfBirth,
        preferredName,
        wirelessCuffId,
        prescriberId,
        pharmacistId,
        coachId,

        // athena fields
        driversLicenseNumber,
        driversLicenseExpirationDate,
        driversLicenseStateId,
        streetAddress,
        city,
        locationState,
        zipCode,
        emergencyContactName,
        emergencyContactPhoneNumber,
        emergencyContactRelationship,
        consentToCall: consentToCall === 'Yes' ? true : consentToCall === 'No' ? false : undefined,
        consentToText: consentToText === 'Yes' ? true : consentToText === 'No' ? false : undefined,
        legalSex,
        sexAssignedAtBirth,
        maritalStatus,
        language,
        race,
        ethnicity,
      }),
    )
  }

  return (
    <Modal isOpen={isOpen} closeModal={close} size="2xl">
      <Confirm
        confirmButtonText="Change patient to VIP"
        confirmButtonVariant={ButtonVariant.ERROR}
        isOpen={isConfirmationDialogOpen}
        onAccept={() => {
          setConfirmationDialogOpen(false)
          dispatchUpdatePatient()
        }}
        onCancel={() => {
          setConfirmationDialogOpen(false)
        }}
        title="Are you sure you want to change the patient into a VIP?"
        subtitle="VIPs are hidden from Riva Management and will not receive care"
      >
        <p className="flex items-center mb-10 px-3 bg-rivaOffblack-100 rounded h-8 text-xs text-rivaOffblack-900">
          <SvgIcon Icon={InformationCircle} size={IconSize.SMALL} className="mr-2" />
          To change a VIP back into a patient, you must contact the engineering team
        </p>
      </Confirm>
      <ModalContainer width="long">
        <form
          className="flex flex-col"
          onSubmit={(e) => {
            e.preventDefault()

            // if any of the select fields are invalid, don't submit
            if (
              dateOfBirthInvalid ||
              acquisitionChannelInvalid ||
              departmentIdInvalid ||
              referringProviderInvalid ||
              isWirelessCuffIdInvalid ||
              careTeamInvalid
            ) {
              return
            }

            if (initialPatientType !== PatientType.VIP && patientType === PatientType.VIP) {
              setConfirmationDialogOpen(true)

              return
            }

            dispatchUpdatePatient()
          }}
        >
          <header className="pt-10 pb-7 flex-none">
            <h2 className="text-rivaOffblack-900 text-lg font-semibold mb-1">
              {patientType === PatientType.VIP ? 'Edit VIP details' : 'Edit patient details'}
            </h2>
            <p className="text-rivaOffblack-600 text-sm font-normal opacity-80">
              {patientType === PatientType.VIP
                ? 'Update the VIP’s information. Fields marked with an asterisk (*) are required'
                : 'Update the patient’s information. Fields marked with an asterisk (*) are required'}
            </p>
            {patientFormState === FormSubmissionState.ERRORED && (
              <p className="text-rivaRed mb-2">
                Sorry, we failed to edit the patient. Please refresh the page and contact support if the problem
                persists.
              </p>
            )}
          </header>
          <main className="flex-1">
            <FormSection>
              <FormTitle>Acquisition Channel</FormTitle>
              <FieldSet colspan={2}>
                <AcquisitionChannelRadioGroup onChange={setAcquisitionChannel} required value={acquisitionChannel} />
              </FieldSet>
              {hasReferringProvider && (
                <FieldSet colspan={2}>
                  <Select
                    value={referringProvider}
                    onChange={(value) => setReferringProvider(value ?? ReferringProvider.NOT_APPLICABLE)}
                    id="referringProvider"
                    invalid={referringProviderInvalid}
                    label="Referring Provider"
                    placeholder="Referring Provider"
                    required
                  >
                    {providers
                      .map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))
                      .concat([
                        <Option key={ReferringProvider.NOT_LISTED} value={ReferringProvider.NOT_LISTED}>
                          Provider not listed
                        </Option>,
                        <Option key={ReferringProvider.NOT_APPLICABLE} value={ReferringProvider.NOT_APPLICABLE}>
                          Not applicable
                        </Option>,
                      ])}
                  </Select>
                </FieldSet>
              )}
              {hasAthenaProfile ? (
                <DepartmentIdSelector
                  acquisitionChannel={acquisitionChannel}
                  isSubmitted
                  value={departmentId}
                  onChange={setDepartmentId}
                  required
                />
              ) : null}
            </FormSection>
            <FormSection>
              <FormTitle>Care Team</FormTitle>
              <FieldSet colspan={2}>
                <Select
                  value={coachId}
                  onChange={(value) => setCoachId(value ?? '')}
                  id="coach"
                  invalid={!coachId}
                  placeholder="Coach"
                >
                  {practitioners.map((practitioner) => (
                    <Option key={practitioner.id} value={practitioner.id}>
                      {practitioner.name}
                    </Option>
                  ))}
                </Select>
                <FormLabel htmlFor="coach" required invalid={!coachId}>
                  Coach
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <Select
                  value={pharmacistId}
                  onChange={(value) => setPharmacistId(value ?? '')}
                  id="pharmacist"
                  invalid={!pharmacistId}
                  placeholder="Pharmacist"
                >
                  {practitioners.map((practitioner) => (
                    <Option key={practitioner.id} value={practitioner.id}>
                      {practitioner.name}
                    </Option>
                  ))}
                </Select>
                <FormLabel htmlFor="pharmacist" required invalid={!pharmacistId}>
                  Pharmacist
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <Select
                  value={prescriberId}
                  onChange={(value) => setPrescriberId(value ?? '')}
                  id="prescriber"
                  invalid={!prescriberId}
                  placeholder="Prescriber"
                >
                  {practitioners.map((practitioner) => (
                    <Option key={practitioner.id} value={practitioner.id}>
                      {practitioner.name}
                    </Option>
                  ))}
                </Select>
                <FormLabel htmlFor="prescriber" required invalid={!prescriberId}>
                  Prescriber
                </FormLabel>
              </FieldSet>
            </FormSection>
            <FormSection>
              <FormTitle>Identification</FormTitle>
              <FieldSet>
                <TextInput
                  data-testid={PATIENT_FIRST_NAME_INPUT}
                  required
                  id="firstName"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
                <FormLabel htmlFor="firstName" required>
                  First Name
                </FormLabel>
              </FieldSet>
              <FieldSet>
                <TextInput
                  required
                  id="lastName"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
                <FormLabel htmlFor="lastName" required>
                  Last Name
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <TextInput
                  id="preferredName"
                  value={preferredName}
                  onChange={(event) => setPreferredName(event.target.value)}
                />
                <FormLabel htmlFor="preferredName">Preferred Name</FormLabel>
              </FieldSet>
              <PronounsSelector isSubmitted onChange={setPronouns} value={pronouns} />
              <FieldSet colspan={2}>
                <DateInput
                  id="dateOfBirth"
                  max={DateTime.now().toISODate() ?? ''}
                  min="1880-01-01"
                  onChange={setDateOfBirth}
                  required
                  value={dateOfBirth}
                />
                <FormLabel htmlFor="dateOfBirth" required>
                  Date of birth
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <MedicalRecordNumberInput
                  acquisitionChannel={acquisitionChannel}
                  dateOfBirth={dateOfBirth}
                  firstName={firstName}
                  lastName={lastName}
                  required={patientType !== PatientType.VIP}
                  value={mrn}
                  onChange={setMrn}
                />
              </FieldSet>
              {hasAthenaProfile ? (
                <div className="grid auto-cols-fr grid-flow-col gap-2 col-span-2">
                  <FieldSet>
                    <TextInput
                      id="driversLicenseNumber"
                      onChange={(event) => setDriversLicenseNumber(event.target.value)}
                      value={driversLicenseNumber}
                    />
                    <FormLabel htmlFor="driversLicenseNumber">Driver's license number</FormLabel>
                  </FieldSet>
                  <FieldSet>
                    <DateInput
                      id="driversLicenseExpirationDate"
                      onChange={setDriversLicenseExpirationDate}
                      value={driversLicenseExpirationDate}
                    />
                    <FormLabel htmlFor="driversLicenseExpirationDate">Expiration date</FormLabel>
                  </FieldSet>
                  <FieldSet>
                    <StateSelector
                      id="driversLicenseStateId"
                      onChange={(value) => setDriversLicenseStateIdId((value ?? '') as StateAbbreviations)}
                      value={driversLicenseStateId}
                    />
                    <FormLabel htmlFor="driversLicenseStateId">State</FormLabel>
                  </FieldSet>
                </div>
              ) : null}
            </FormSection>
            <FormSection>
              <FormTitle>Personal contact information</FormTitle>
              <FieldSet colspan={2}>
                <TextInput
                  type="email"
                  required
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <FormLabel htmlFor="email" required>
                  Email
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <TextInput disabled id="phoneNumber" value={formatPhoneNumber(phoneNumber)} />
                <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
              </FieldSet>
              {hasAthenaProfile ? (
                <>
                  <FieldSet colspan={2}>
                    <TextInput
                      id="streetAddress"
                      placeholder="Street address"
                      value={streetAddress}
                      onChange={(event) => setStreetAddress(event.target.value)}
                    />
                    <FormLabel htmlFor="streetAddress">Street address</FormLabel>
                  </FieldSet>
                  <FieldSet colspan={2}>
                    <TextInput
                      id="city"
                      maxLength={30}
                      onChange={(event) => setCity(event.target.value)}
                      placeholder="City"
                      value={city}
                    />
                    <FormLabel htmlFor="city">City</FormLabel>
                  </FieldSet>
                  <FieldSet>
                    <StateSelector
                      id="locationState"
                      onChange={(value) => setLocationState((value ?? '') as StateAbbreviations)}
                      value={locationState}
                    />
                    <FormLabel htmlFor="locationState">State</FormLabel>
                  </FieldSet>
                  <FieldSet>
                    <TextInput
                      id="zipCode"
                      max={99999}
                      min={10000}
                      onChange={(event) => setZipCode(event.target.value)}
                      placeholder="Zip code"
                      type="number"
                      value={zipCode}
                    />
                    <FormLabel htmlFor="zipCode">Zip code</FormLabel>
                  </FieldSet>
                </>
              ) : null}
            </FormSection>
            <FormSection>
              <FormTitle>Clinical goals</FormTitle>
              <FieldSet>
                <TextInput
                  type="number"
                  id="Systolic Goal"
                  value={systolicGoal || undefined}
                  onChange={(event) => setSystolicGoal(parseInt(event.target.value, 10))}
                  min={70}
                  max={200}
                />
                <FormLabel htmlFor="Systolic Goal">
                  Goal BP
                  <br />
                  <span className="font-medium">Less than:</span>
                </FormLabel>
              </FieldSet>
              <FieldSet>
                <TextInput
                  type="number"
                  id="Diastolic Goal"
                  value={diastolicGoal || undefined}
                  onChange={(event) => setDiastolicGoal(parseInt(event.target.value, 10))}
                  min={45}
                  max={120}
                />
                <FormLabel htmlFor="Diastolic Goal">
                  &nbsp;
                  <br />
                  &nbsp;
                </FormLabel>
              </FieldSet>
            </FormSection>
            {hasAthenaProfile ? (
              <FormSection>
                <FormTitle>Emergency contact information</FormTitle>
                <FieldSet colspan={2}>
                  <TextInput
                    id="emergencyContactName"
                    onChange={(event) => setEmergencyContactName(event.target.value)}
                    value={emergencyContactName}
                  />
                  <FormLabel htmlFor="emergencyContactName">Name</FormLabel>
                </FieldSet>
                <FieldSet colspan={2}>
                  <TextInput
                    id="emergencyContactPhoneNumber"
                    onChange={(event) => setEmergencyContactPhoneNumber(event.target.value)}
                    type="tel"
                    value={emergencyContactPhoneNumber}
                  />
                  <FormLabel htmlFor="emergencyContactPhoneNumber">Phone number</FormLabel>
                </FieldSet>
                <FieldSet colspan={2}>
                  <EmergencyContactRelationshipSelector
                    id="emergencyContactRelationship"
                    onChange={setEmergencyContactRelationship}
                    value={emergencyContactRelationship}
                  />
                  <FormLabel htmlFor="emergencyContactRelationship">Relationship to patient</FormLabel>
                </FieldSet>
              </FormSection>
            ) : null}
            <FormSection>
              <FormTitle>Patient communication preferences</FormTitle>
              {hasAthenaProfile ? (
                <>
                  <FieldSet colspan={2}>
                    <ConsentRadioGroup label="Consent to call" onChange={setConsentToCall} value={consentToCall} />
                  </FieldSet>
                  <FieldSet colspan={2}>
                    <ConsentRadioGroup label="Consent to text" onChange={setConsentToText} value={consentToText} />
                  </FieldSet>
                </>
              ) : null}
              <CommunicationPreferencesSelector
                communicationPreferencesDays={communicationPreferencesDays}
                setCommunicationPreferencesDays={setComunicationPreferencesDays}
              />
              <ReminderTimeSelector
                hourValue={amReminderHour}
                minuteValue={amReminderMinute}
                onHourChange={setAmReminderHour}
                onMinuteChange={setAmReminderMinute}
                timeOfDay="am"
              />
              <ReminderTimeSelector
                hourValue={pmReminderHour}
                minuteValue={pmReminderMinute}
                onHourChange={setPmReminderHour}
                onMinuteChange={setPmReminderMinute}
                timeOfDay="pm"
              />
            </FormSection>
            {hasAthenaProfile ? (
              <FormSection>
                <FormTitle>Demographic information</FormTitle>
                <FieldSet colspan={2}>
                  <FormLabel {...legalSexRadioGroup.getGroupLabelProps()} order={0}>
                    Legal sex
                  </FormLabel>
                  <RadioGroup {...legalSexRadioGroup.getRadioGroupProps()}>
                    <RadioLabel label="Male">
                      <Radio {...legalSexRadioGroup.getRadioProps('M')} />
                    </RadioLabel>
                    <RadioLabel label="Female">
                      <Radio {...legalSexRadioGroup.getRadioProps('F')} />
                    </RadioLabel>
                  </RadioGroup>
                </FieldSet>
                <FieldSet colspan={2}>
                  <FormLabel {...sexAssignedAtBirthRadioGroup.getGroupLabelProps()} order={0}>
                    Sex assigned at birth
                  </FormLabel>
                  <RadioGroup {...sexAssignedAtBirthRadioGroup.getRadioGroupProps()}>
                    <RadioLabel label="Male">
                      <Radio {...sexAssignedAtBirthRadioGroup.getRadioProps('M')} />
                    </RadioLabel>
                    <RadioLabel label="Female">
                      <Radio {...sexAssignedAtBirthRadioGroup.getRadioProps('F')} />
                    </RadioLabel>
                    <RadioLabel label="Choose not to disclose">
                      <Radio {...sexAssignedAtBirthRadioGroup.getRadioProps('N')} />
                    </RadioLabel>
                    <RadioLabel label="Unknown">
                      <Radio {...sexAssignedAtBirthRadioGroup.getRadioProps('U')} />
                    </RadioLabel>
                  </RadioGroup>
                </FieldSet>
                <FieldSet colspan={2}>
                  <Select
                    id="maritalStatus"
                    label="Marital status"
                    onChange={(value) => setMaritalStatus(value)}
                    placeholder="Select marital status"
                    value={maritalStatus}
                  >
                    <Option value={MaritalStatus.Divorced}>Divorced</Option>
                    <Option value={MaritalStatus.Married}>Married</Option>
                    <Option value={MaritalStatus.Single}>Single</Option>
                    <Option value={MaritalStatus.Unknown}>Unknown</Option>
                    <Option value={MaritalStatus.Widowed}>Widowed</Option>
                    <Option value={MaritalStatus.Separated}>Separated</Option>
                    <Option value={MaritalStatus.Partner}>Partner</Option>
                  </Select>
                </FieldSet>
                <FieldSet colspan={2}>
                  <LanguageSelector onChange={setLanguage} value={language} />
                </FieldSet>
                <FieldSet colspan={2}>
                  <RaceSelector onChange={setRace} value={race} />
                </FieldSet>
                <FieldSet colspan={2}>
                  <EthnicitySelector onChange={setEthnicity} value={ethnicity} />
                </FieldSet>
              </FormSection>
            ) : null}
            <FormSection>
              <FormTitle>Device information</FormTitle>
              <FieldSet colspan={2}>
                <DeviceTypes deviceType={deviceType} setDeviceType={(value) => setDeviceType(value || '')} />
              </FieldSet>
              <WirelessCuffSelector
                initialValue={initialWirelessCuffId ?? ''}
                onChange={setWirelessCuffId}
                onInvalidChange={setIsWirelessCuffIdInvalid}
                patientId={patientId}
                value={wirelessCuffId}
              />
            </FormSection>
            <section className="border-t border-rivaOffblack-200 py-10 relative">
              <h3
                className="uppercase text-rivaOffblack-900 text-xxs font-black cursor-pointer"
                onClick={toggleAdministrativeVisible}
              >
                Administrative options
              </h3>
              <button
                type="button"
                className={clsx(
                  'p-1 h-7 w-7 absolute top-9 right-0 transform-gpu',
                  isAdministrativeVisible ? 'rotate-180' : '',
                )}
                onClick={toggleAdministrativeVisible}
              >
                <SvgIcon Icon={ChevronDown} size={IconSize.SMALL} />
              </button>
              <div className={clsx('grid grid-cols-2 gap-7 mt-2', isAdministrativeVisible ? '' : 'hidden')}>
                <FieldSet>
                  <div className="flex flex-row items-center pt-4">
                    <label className="flex-1 text-sm" htmlFor="bpNotifDisabled">
                      Enable BP Notifications
                    </label>
                    <Checkbox
                      checked={!bpNotifDisabled}
                      id="bpNotifDisabled"
                      onChange={(checked) => setBpNotifDisabled(!checked)}
                    />
                  </div>
                </FieldSet>
                <div>{/* spacer */}</div>
                <FieldSet className="relative mb-7" colspan={2}>
                  <Select
                    value={patientType}
                    label={
                      <span className={patientType === PatientType.VIP ? 'text-rivaGold-800' : ''}>User Type</span>
                    }
                    onChange={(value) => setPatientType(value ?? PatientType.MANAGED)}
                    warning={patientType === PatientType.VIP}
                  >
                    <Option value={PatientType.MANAGED}>Patient</Option>
                    <Option value={PatientType.VIP}>VIP</Option>
                  </Select>
                  {patientType === PatientType.VIP && (
                    <p className="text-rivaGold-800 text-xxs leading-6 px-1 py-2 absolute -bottom-10 left-0 order-3">
                      VIPs are hidden from Riva Management
                    </p>
                  )}
                </FieldSet>
              </div>
            </section>
          </main>
          <ModalFooter>
            {patientFormState === FormSubmissionState.SUBMITTING ? (
              <LoadingIndicator className="mr-4 mt-4 mb-2" position={LoadingIndicatorPosition.RIGHT} />
            ) : (
              <>
                <Button className="mr-3" variant={ButtonVariant.SECONDARY} onClick={close} type="button">
                  Cancel
                </Button>
                <Button
                  variant={patientType === PatientType.VIP ? ButtonVariant.ERROR : ButtonVariant.ACTION}
                  type="submit"
                >
                  {initialPatientType !== PatientType.VIP && patientType === PatientType.VIP
                    ? 'Change patient to VIP'
                    : 'Save'}
                </Button>
              </>
            )}
          </ModalFooter>
        </form>
      </ModalContainer>
    </Modal>
  )
}

import '@szhsin/react-menu/dist/index.css'

import { ReactElement, useCallback } from 'react'
import { DrawerNavigation } from '../../../ui/drawer-navigation'
import {
  getPatientBloodPressureManagementStatus,
  convertBloodPressureManagementStatusToDisplayName,
  getPatientBloodPressureGoalForDisplay,
  getPatientDateOfBirth,
  getPatientPhoneNumber,
  getPatientAcquisitionChannel,
  getPatientReferringProvider,
  getPatientPersonId,
  getPatientTimeZone,
} from '../../../../redux/selectors/patient'
import { getBloodPressureAverage } from '../../../../redux/selectors/blood-pressure-average'
import MovingAverageStaleIndicator from '../../../pages/patients/panel/moving-average-stale-indicator'
import { PATIENT_VIEW_HEADER } from '../../../../consts/data-testids'
import { useAppSelector } from '../../../../redux'
import { formatPhoneNumber } from '../../../../utils/l10n'
import useGetBaselineMovingAverage, {
  getBaselineBloodPressureForDisplay,
} from '../../../../api/hooks/use-get-baseline-moving-average'
import { AcquisitionChannelNames } from '../../../../types/patient'
import HeaderBlock from '../../../ui/header-block'
import { Features } from '../../../../types/features'
import { getDobAndAge } from '../utils'
import useIsTabEnabled from '../use-is-tab-enabled'
import Link from '../../../ui/link'
import { track } from '../../../../i13n'
import AllergyHeaderContent from './allergy-header-content'
import MedicalRecordNumber from './medical-record-number'

export function Header({ patientId, isExpanded }: { patientId: string; isExpanded: boolean }): ReactElement {
  const bloodPressureManagementStatus = useAppSelector((state) =>
    getPatientBloodPressureManagementStatus(state, patientId),
  )
  const bpGoal = useAppSelector((state) => getPatientBloodPressureGoalForDisplay(state, patientId))
  const dateOfBirth = useAppSelector((state) => getPatientDateOfBirth(state, patientId))
  const timeZone = useAppSelector((state) => getPatientTimeZone(state, patientId))
  const ma12 = useAppSelector((state) => getBloodPressureAverage(state, patientId, 0))
  const personId = useAppSelector((state) => getPatientPersonId(state, patientId))
  const { data } = useGetBaselineMovingAverage(personId)
  const phoneNumber = useAppSelector((state) => getPatientPhoneNumber(state, patientId))
  const acquisitionChannel = useAppSelector((state) => getPatientAcquisitionChannel(state, patientId))
  const referringProvider = useAppSelector((state) => getPatientReferringProvider(state, patientId))
  const allergiesTabEnabled = useIsTabEnabled(Features.ALLERGIES_TAB, patientId)
  const onPhoneNumberClick = useCallback(() => {
    track('Clicked Patient Phone Number', {
      service: 'Dialpad',
    })
  }, [])

  return (
    <header className="bg-white px-7 flex-none" data-testid={PATIENT_VIEW_HEADER}>
      <div className="pb-7">
        <div className="grid grid-cols-3 gap-4">
          <HeaderBlock label="Date of Birth" content={getDobAndAge(dateOfBirth, timeZone)} />
          <HeaderBlock label="MRN" content={<MedicalRecordNumber patientId={patientId} />} />
          <HeaderBlock
            label="Latest 12-MA"
            content={
              <>
                <span>{ma12}</span>
                <MovingAverageStaleIndicator patientId={patientId} />
              </>
            }
          />
          {isExpanded ? (
            <>
              <HeaderBlock
                label="Phone Number"
                content={
                  <Link href={`dialpad:${phoneNumber}`} onClick={onPhoneNumberClick}>
                    {formatPhoneNumber(phoneNumber)}
                  </Link>
                }
              />
              <HeaderBlock
                label="Acquisition Source"
                content={AcquisitionChannelNames[acquisitionChannel as keyof typeof AcquisitionChannelNames] || '-'}
              />
              <HeaderBlock
                label="Status"
                content={convertBloodPressureManagementStatusToDisplayName(bloodPressureManagementStatus)}
              />
              <HeaderBlock label="Referring Provider" content={referringProvider || '-'} />
              <HeaderBlock label="Baseline 12-MA" content={getBaselineBloodPressureForDisplay(data)} />
              <HeaderBlock label="Goal BP" content={bpGoal !== '-' ? `< ${bpGoal}` : bpGoal} />
              {allergiesTabEnabled ? (
                <HeaderBlock label="Allergies" content={<AllergyHeaderContent patientId={patientId} />} />
              ) : null}
            </>
          ) : null}
        </div>

        <DrawerNavigation navigationData={getNavigationData(patientId)} />
      </div>
    </header>
  )
}

function getNavigationData(patientId: string) {
  return [
    // {
    //   dataTestId: PARTICIPANT_VIEW_HEADER_BLOOD_PRESSURE_SETS_LINK,
    //   to: `/dashboard/patients/${patientId}/blood-pressure-sets`,
    //   text: 'BloodPressureSets',
    // },
    // {
    //   dataTestId: PARTICIPANT_VIEW_HEADER_NOTES_LINK,
    //   to: `/dashboard/patients/${patientId}/notes`,
    //   text: 'Notes',
    // },
  ]
}

import deepEquals from 'lodash.isequal'
import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useAppSelector } from '../../../../../redux'
import { getPatient } from '../../../../../redux/selectors/patient'
import { getPerson } from '../../../../../redux/selectors/person'
import { getPractitioner } from '../../../../../redux/selectors/practitioner'
import { getProviderById } from '../../../../../redux/selectors/provider'
import { getTaskById } from '../../../../../redux/selectors/tasks'
import { initialHelper, surveySparrowId, urlParamHelper } from '../../../../../utils/template'
import useGetTaskVisits, { Options } from '../../../../../api/hooks/use-get-task-visits'
import { TaskType } from '../../../../../types/task'
import { getCarePathwayById, getCarePathwayEventById } from '../../../../../redux/selectors/care-pathway'
import { TaskEventType } from '../../../../../__generated__/graphql'
import { BPEscalationMeasurementReceivedEvent } from '../../../../../types/care-pathway'

const useGetTemplateData = ({ taskId }: { taskId: string }): unknown => {
  const task = useAppSelector((state) => getTaskById(state, taskId))
  const patient = useAppSelector((state) => getPatient(state, task?.patientId ?? ''))
  const assignee = useAppSelector((state) => getPractitioner(state, task?.assignee ?? ''))
  const assigneePerson = useAppSelector((state) => getPerson(state, assignee?.personId ?? ''))
  const referringProvider = useAppSelector((state) => getProviderById(state, patient?.referringProvider ?? ''))
  const coach = useAppSelector((state) => getPractitioner(state, patient?.careTeam?.coachId ?? ''))
  const coachPerson = useAppSelector((state) => getPerson(state, coach?.personId ?? ''))
  const pharmacist = useAppSelector((state) => getPractitioner(state, patient?.careTeam?.pharmacistId ?? ''))
  const pharmacistPerson = useAppSelector((state) => getPerson(state, pharmacist?.personId ?? ''))
  const prescriber = useAppSelector((state) => getPractitioner(state, patient?.careTeam?.prescriberId ?? ''))
  const prescriberPerson = useAppSelector((state) => getPerson(state, prescriber?.personId ?? ''))
  const eventIds = useAppSelector((state) => getCarePathwayById(state, task?.carePathwayId ?? '')?.eventIds)
  const escalationEvents = useAppSelector(
    (state) => eventIds?.map((id) => getCarePathwayEventById(state, id)) ?? [],
    deepEquals,
  )
  const escalationMeasurementEvent = escalationEvents.find(
    (event): event is BPEscalationMeasurementReceivedEvent =>
      event?.eventType === TaskEventType.BpEscalationMeasurementReceived,
  )
  const currentHour = DateTime.now().hour
  const { taskType = '' } = task ?? {}
  const options: Options | null = useMemo(
    () =>
      taskType === TaskType.COMPLETE_PHARMD_VISIT ||
      taskType === TaskType.COMPLETE_PRESCRIBER_VISIT ||
      taskType === TaskType.ASK_IF_PATIENT_HAS_PHARMD_VISIT_QUESTIONS ||
      taskType === TaskType.ASK_IF_PATIENT_HAS_PRESCRIBER_VISIT_QUESTIONS ||
      taskType === TaskType.SEND_VISIT_SURVEY_PRESCRIBER
        ? {
            taskId,
          }
        : null,
    [taskId, taskType],
  )
  const { data: visitData } = useGetTaskVisits(options)
  const visit = visitData?.results[0]

  return useMemo(() => {
    const visitDateTime = DateTime.fromISO(visit?.startTime ?? '')
    const escalationDateTime = DateTime.fromISO(escalationMeasurementEvent?.created.at ?? '')

    return {
      patient: {
        ...patient,
        preferredName: patient?.preferredName || patient?.firstName || patient?.lastName || '',
        referringProvider,
        careTeam: {
          ...patient?.careTeam,
          coach: {
            ...coachPerson,
            ...coach,
          },
          pharmacist: {
            ...pharmacistPerson,
            ...pharmacist,
          },
          prescriber: {
            ...prescriberPerson,
            ...prescriber,
          },
        },
      },
      assignee: {
        ...assignee,
        ...assigneePerson,
      },
      acuityDomain: process.env.REACT_APP_ACUITY_DOMAIN,
      initial: () => initialHelper,
      urlParam: () => urlParamHelper,
      surveySparrowId: () => surveySparrowId,
      timeOfDay: currentHour < 11 ? 'morning' : currentHour < 16 ? 'afternoon' : 'evening',
      visit: {
        ...visit,
        formattedDateTime: visitDateTime.isValid
          ? visitDateTime.toLocaleString({
              day: 'numeric',
              month: 'long',
              hour: 'numeric',
              minute: '2-digit',
              hour12: true,
              timeZone: patient?.timeZone || undefined,
              timeZoneName: 'short',
            })
          : '[date/time]',
      },
      escalationMeasurementEvent: {
        ...escalationMeasurementEvent,
        formattedDateTime: escalationDateTime.isValid
          ? escalationDateTime.toLocaleString({
              day: 'numeric',
              month: 'long',
              hour: 'numeric',
              minute: '2-digit',
              hour12: true,
              timeZone: patient?.timeZone || undefined,
              timeZoneName: 'short',
            })
          : '[Invalid Date]',
      },
    }
  }, [
    assignee,
    assigneePerson,
    coach,
    coachPerson,
    currentHour,
    escalationMeasurementEvent,
    patient,
    pharmacist,
    pharmacistPerson,
    prescriber,
    prescriberPerson,
    referringProvider,
    visit,
  ])
}

export default useGetTemplateData

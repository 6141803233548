import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'

interface Props {
  children: React.ReactElement
}

class Layer extends React.Component<Props> {
  el: HTMLElement

  constructor(props: Props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount(): void {
    const drawerRoot = document.getElementById('drawer-root')

    if (drawerRoot) {
      drawerRoot.appendChild(this.el)
    }
  }

  componentWillUnmount(): void {
    const drawerRoot = document.getElementById('drawer-root')

    if (drawerRoot) {
      drawerRoot.removeChild(this.el)
    }
  }

  render(): ReactElement {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default Layer

import clsx from 'clsx'
import { AnchorHTMLAttributes, DetailedHTMLProps, ReactElement } from 'react'

type Props = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

const Link = ({ children, className, ...props }: Props): ReactElement => {
  return (
    <a
      {...props}
      className={clsx(
        'text-rivaPurple-500 hover:text-rivaPurple-600 active:text-rivaPurple-800 underline cursor-pointer',
        className,
      )}
    >
      {children}
    </a>
  )
}

export default Link

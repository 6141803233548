import { produce } from 'immer'
import { Reducer, combineReducers } from 'redux'
import uniq from 'lodash.uniq'
import {
  CARE_PATHWAY_CANCEL_REQUESTED,
  CarePathway,
  CarePathwayEvent,
  FETCH_CARE_PATHWAYS_FULFILLED,
} from '../../types/care-pathway'
import { Action } from '../../types/actions'
import { FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED } from '../../types/blood-pressure-measurement'
import { FETCH_TASK_CARE_PATHWAY_FULFILLED } from '../../types/task'

const byId: Reducer<Record<string, CarePathway>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED:
      action.payload.measurements.forEach(({ escalation }) => {
        if (escalation) {
          const { events, ...carePathway } = escalation

          draft[carePathway.id] = {
            ...carePathway,
            eventIds: events?.map(({ id }) => id) ?? [],
          }
        }
      })
      break
    case FETCH_TASK_CARE_PATHWAY_FULFILLED:
      draft[action.payload.carePathway.id] = {
        ...action.payload.carePathway,
        eventIds: action.payload.carePathway.events?.map(({ id }) => id) ?? [],
      }
      break
    case FETCH_CARE_PATHWAYS_FULFILLED:
      action.payload.carePathways.forEach((carePathway) => {
        draft[carePathway.id] = carePathway
      })
      break
    case CARE_PATHWAY_CANCEL_REQUESTED:
      delete draft[action.payload.carePathwayId]
      break
  }
}, {})

const carePathwayEventsById: Reducer<Record<string, CarePathwayEvent>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED:
    case FETCH_TASK_CARE_PATHWAY_FULFILLED:
      action.payload.events.forEach((event) => {
        draft[event.id] = event
      })
      break
  }
}, {})

const carePathwayEventIdsByCarePathwayId: Reducer<Record<string, string[]>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED:
    case FETCH_TASK_CARE_PATHWAY_FULFILLED:
      uniq(action.payload.events.map(({ carePathwayId }) => carePathwayId)).forEach((carePathwayId) => {
        if (!carePathwayId) {
          return
        }

        draft[carePathwayId] = action.payload.events
          .filter(({ carePathwayId: eventCarePathwayId }) => eventCarePathwayId === carePathwayId)
          .map(({ id }) => id)
      })
      break
  }
}, {})

const escalationIdByMeasurementId: Reducer<Record<string, string>, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED:
      action.payload.measurements.forEach(({ id, escalation }) => {
        if (escalation) {
          draft[id] = escalation.id
        }
      })
      break
  }
}, {})

export default combineReducers({
  byId,
  carePathwayEventIdsByCarePathwayId,
  carePathwayEventsById,
  escalationIdByMeasurementId,
})

import Ajv, { JSONSchemaType } from 'ajv'
import { SWRResponse } from 'swr'
import { SurveySubmission } from '../../types/survey'
import useApi from './use-api'
import SurveySubmissionSchema from './schema/survey-submission'

const ajv = new Ajv()

interface GetSurveySubmissionsResponse {
  submissions: SurveySubmission[] | null | undefined
}

const schema: JSONSchemaType<GetSurveySubmissionsResponse> = {
  type: 'object',
  properties: {
    submissions: {
      type: 'array',
      items: SurveySubmissionSchema,
      nullable: true,
    },
  },
  required: [],
}

const validate = ajv.compile(schema)

const useGetSurveySubmissionsByPatient = (patientId: string): SWRResponse<GetSurveySubmissionsResponse> =>
  useApi('/v1/patient/:patientId/survey', {
    params: {
      patientId,
    },
    validate,
  })

export default useGetSurveySubmissionsByPatient

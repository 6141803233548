import clsx from 'clsx'
import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react'
import { IconSize, SvgIcon } from '../icon'
import { ReactComponent as CalendarIcon } from '../../icons/outline/calendar.svg'

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const HtmlInputDate = forwardRef<HTMLInputElement, Props>(({ className, value, ...inputProps }, ref) => {
  return (
    <span className="w-full relative flex flex-col">
      <input
        {...inputProps}
        className={clsx(
          className,
          'peer riva-input order-2 border disabled:border-rivaOffblack-300 disabled:text-rivaOffblack-500 disabled:bg-rivaOffblack-100 rounded py-3 pl-3 pr-10 text-sm box-border h-10 outline-none bg-white border-rivaOffblack-300 hover:border-rivaOffblack focus:border-rivaPurple-500 w-full',
          {
            'text-rivaOffblack-900': value,
            'text-rivaOffblack-400': !value,
          },
        )}
        ref={ref}
        type="date"
        value={value}
      />
      <SvgIcon
        className="absolute bottom-[10px] right-3 pointer-events-none order-3"
        Icon={CalendarIcon}
        size={IconSize.SMALL}
      />
    </span>
  )
})

export default HtmlInputDate

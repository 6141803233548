import { Component, ErrorInfo, ReactNode } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { ReactComponent as EmojiSadIcon } from '../icons/outline/emoji-sad.svg'
import { track } from '../../i13n'
import { SvgIcon } from './icon'

interface Props {
  children: ReactNode
  sectionName: string
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  state: State

  constructor(props: Props) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: unknown): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    track('Crash', {
      sectionName: this.props.sectionName,
    })

    datadogLogs.logger.error(
      'crash',
      {
        componentName: 'error-boundary',
        componentStack: errorInfo.componentStack,
        errorMessage: error.message,
        errorStack: error.stack,
      },
      error,
    )
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="flex-1 bg-white flex flex-col items-center justify-center h-full">
          <SvgIcon className="text-rivaRed-400" Icon={EmojiSadIcon} />
          <h2 className="text-sm font-semibold m-0">Something went wrong</h2>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

import { ReactElement } from 'react'
import { ProblemsQueryQuery } from '../../../../__generated__/graphql'
import { SvgIcon } from '../../../ui/icon'
import { ReactComponent as InformationIcon } from '../../../icons/solid/information-circle.svg'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import FormattedDateTime from '../../../ui/formatted-date-time'

type patient = NonNullable<ProblemsQueryQuery['patient']>
type ConditionType = NonNullable<NonNullable<patient['conditions']>[number]>

interface Props {
  condition: ConditionType
}

const Condition = ({ condition: { code, onsetDateTime, severity } }: Props): ReactElement => {
  const { getArrowProps, getTriggerProps, getTooltipProps } = useTooltip({
    offset: [12, 8],
  })

  return (
    <div className="py-2 border-b border-rivaOffblack-200 last:border-none flex">
      <div className="flex-1 text-sm font-medium text-rivaOffblack-900">{code?.text}</div>
      <div {...getTriggerProps()} className="w-6 flex-none cursor-pointer" hidden={!severity && !onsetDateTime}>
        <SvgIcon Icon={InformationIcon} className="text-rivaOffblack-700" />
      </div>
      <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
        <table className="mx-7 my-3">
          <thead className="text-xxs text-rivaOffblack-400 text-left">
            <tr>
              {severity ? <th className="pl-10 font-semibold">Severity</th> : null}
              {onsetDateTime ? <th className="font-semibold">Onset date</th> : null}
            </tr>
          </thead>
          <tbody>
            <tr>
              {severity ? <td className="pl-10 leading-6">{severity.text}</td> : null}
              {onsetDateTime ? (
                <td className="leading-6">
                  <FormattedDateTime value={onsetDateTime ?? ''} day="numeric" month="numeric" year="numeric" />
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </Tooltip>
    </div>
  )
}

export default Condition

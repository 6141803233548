import {
  ActionCreator,
  DebugProfileToggleAction,
  DEBUG_PROFILE_TOGGLED,
  ToastClosedAction,
  TOAST_CLOSED,
  PatientViewHeaderExpansionAction,
  PatientViewSummaryExpansionAction,
  PATIENT_VIEW_HEADER_EXPANSION_TOGGLED,
  PATIENT_VIEW_SUMMARY_EXPANSION_TOGGLED,
} from '../../types/actions'

export const toggleDebugProfile = (): DebugProfileToggleAction => ({
  type: DEBUG_PROFILE_TOGGLED,
  payload: {},
})

export const closeToast: ActionCreator<ToastClosedAction> = (payload) => ({
  type: TOAST_CLOSED,
  payload,
})

export const togglePatientViewHeaderExpansion = (): PatientViewHeaderExpansionAction => ({
  type: PATIENT_VIEW_HEADER_EXPANSION_TOGGLED,
  payload: {},
})

export const togglePatientViewSummaryExpansion = (): PatientViewSummaryExpansionAction => ({
  type: PATIENT_VIEW_SUMMARY_EXPANSION_TOGGLED,
  payload: {},
})

import { ReactElement, ReactNode } from 'react'
import { TASK_DETAILS_TASK_EVENT, TASK_DETAILS_TASK_NOTE } from '../../../../../consts/data-testids'
import NaturalTime from '../../../../ui/natural-time'
import PractitionerAvatar, { AvatarSize } from '../../../../ui/practitioner-avatar'
import SystemAvatar from '../../../../ui/system-avatar'

interface Props {
  children: ReactNode
  createdAt: string
  id: string
  kind: 'event' | 'note'
  note?: string
  practitionerId: string
}

const TaskActivityItem = ({ children, createdAt, id, kind, note, practitionerId }: Props): ReactElement => {
  return (
    <div
      className="mt-3 first:mt-0 grid gap-x-3 gap-y-2 grid-cols-[40px_1fr_100px] text-sm font-medium"
      data-id={id}
      data-testid={kind === 'note' ? TASK_DETAILS_TASK_NOTE : TASK_DETAILS_TASK_EVENT}
    >
      <div className="row-span-3 flex flex-col">
        {practitionerId ? (
          <PractitionerAvatar border practitionerId={practitionerId} size={AvatarSize.XL} />
        ) : (
          <SystemAvatar size={AvatarSize.XL} />
        )}
        <div className="w-5 flex-1 border-r border-dashed border-rivaOffblack-300 group-last:hidden"></div>
      </div>
      <div>{children}</div>
      <NaturalTime className="text-right text-rivaOffblack-500" value={createdAt} />
      {note ? (
        <p className="p-3 bg-rivaOffblack-100 rounded col-span-2 whitespace-pre-wrap break-words">{note}</p>
      ) : null}
      <div className="h-1"></div>
    </div>
  )
}

export default TaskActivityItem

import { useCallback } from 'react'
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react'
import useSWR, { SWRResponse } from 'swr'
import type { GroupChannel } from '@sendbird/chat/groupChannel'

const useGetChannel = (channelUrl: string): SWRResponse<GroupChannel | undefined> => {
  const context = useSendbirdStateContext()
  const getGroupChannel = sendbirdSelectors.getGetGroupChannel(context)
  const fetcher = useCallback(async () => {
    try {
      const channel = await getGroupChannel(channelUrl)

      await channel.refresh()

      return channel
    } catch (_) {
      return undefined
    }
    // Note: do not remove. Sendbird does not cache the selector factories
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelUrl])

  return useSWR(channelUrl, fetcher, {
    refreshInterval: 30000,
  })
}

export default useGetChannel

import clsx from 'clsx'
import flatten from 'lodash.flatten'
import { ReactElement, ReactNode } from 'react'

interface Props {
  className?: string
  keys: string
  variant?: 'light' | 'dark'
}

const tokenize = (keys: string): string[] => {
  return flatten(
    keys
      .split(' ')
      .reduce(
        (combinations, combination) =>
          combinations.length > 0 ? [...combinations, 'then', combination] : [combination],
        [] as string[],
      )
      .map((combination) => combination.split('+')),
  )
}

const KeyboardInput = ({ children, variant = 'light' }: { children: ReactNode; variant?: 'light' | 'dark' }) => (
  <kbd
    className={clsx('inline-block ml-1 rounded-sm w-6 h-6 leading-6 text-center text-xs capitalize text-white', {
      'bg-white/25': variant === 'light',
      'bg-black/25': variant === 'dark',
    })}
    style={{
      fontFamily: 'sans-serif',
    }}
  >
    {children}
  </kbd>
)

const tokenToString = (token: string): string => {
  switch (token) {
    case 'backspace':
      return '⌫'
    case 'command':
      return '⌘'
    case 'del':
      return '⌦'
    case 'down':
      return '↓'
    case 'enter':
    case 'return':
      return '↵'
    case 'esc':
    case 'escape':
      return 'Esc'
    case 'left':
      return '←'
    case 'option':
      return '⌥'
    case 'right':
      return '→'
    case 'shift':
      return '⇧'
    case 'tab':
      return '↹'
    case 'up':
      return '↑'
    default:
      return token
  }
}

const Token = ({ token, variant }: { token: string; variant?: 'light' | 'dark' }): ReactElement => {
  if (token === 'then') {
    return <span className="ml-1 text-xxs text-white">then</span>
  }

  return <KeyboardInput variant={variant}>{tokenToString(token)}</KeyboardInput>
}

const MousetrapHint = ({ className, keys, variant }: Props): ReactElement => {
  const tokens = tokenize(keys)

  return (
    <span className={clsx('h-6 inline-flex', className)}>
      {tokens.map((token) => (
        <Token key={token} token={token} variant={variant} />
      ))}
    </span>
  )
}

export default MousetrapHint

import { ReactElement } from 'react'

const SimpleLoadingIndicator = (): ReactElement => {
  return (
    <div className="lds-ring">
      <div className="border-t-rivaPurple-500"></div>
      <div className="border-t-rivaPurple-500"></div>
      <div className="border-t-rivaPurple-500"></div>
      <div className="border-t-rivaPurple-500"></div>
    </div>
  )
}

export default SimpleLoadingIndicator

import { ReactElement, useEffect, useRef } from 'react'
import ErrorBoundary from '../../../ui/error-boundary'
import PatientSummary from './patient-summary'
import BloodPressureLog from './blood-pressure-log'
import BloodPressureGraph from './blood-pressure-graph'

export function BloodPressureTabPanel({ patientId }: { patientId: string }): ReactElement {
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    ref.current?.parentElement?.scrollTo?.({
      top: 0,
    })
  }, [patientId])

  return (
    <ErrorBoundary sectionName="BP Measurements">
      <section className="bg-rivaOffblack-200" ref={ref}>
        <PatientSummary patientId={patientId} />
        <BloodPressureGraph patientId={patientId} />
        <BloodPressureLog patientId={patientId} />
      </section>
    </ErrorBoundary>
  )
}

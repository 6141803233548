import { DateTime } from 'luxon'
import { BloodPressureSetTimeOfDay } from '../types/blood-pressure-set'

const MS_IN_DAY = 24 * 60 * 60 * 1000

export function getLastNDaysFromDate(
  n: number,
  d: Date,
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
): string[] {
  const days = []

  for (let i = 0; i < n; i++) {
    d.setDate(d.getDate() - 1)
    days.push(formatDate(d, timeZone))
  }

  return days
}

export function formatDate(d: Date, timeZone: string, fullYear = false): string {
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    year: fullYear ? 'numeric' : '2-digit',
    month: 'numeric',
    day: 'numeric',
  })

  return dateFormatter.format(d)
}

export function formatTime(t: Date, timeZone: string): string {
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: timeZone,
  })

  return timeFormatter.format(t)
}

export function getTimeOfDayString(timeOfDay: BloodPressureSetTimeOfDay): string {
  switch (timeOfDay) {
    case BloodPressureSetTimeOfDay.EVENING:
      return 'PM'
    case BloodPressureSetTimeOfDay.MORNING:
      return 'AM'
    default:
      return 'Invalid'
  }
}

/**
 * Adds "numToAdd" days to the given date.
 */
export function addDaysToDate(date: Date, numToAdd: number): Date {
  const newDate = new Date(date.getTime())

  newDate.setTime(newDate.getTime() + MS_IN_DAY * numToAdd)

  return newDate
}

/**
 * Converts a JavaScript Date object to a string in the format of YYYY-MM-DD
 *
 * @param date {Date} A JavaScript Date object
 */
export function convertDateToDateString(date: Date, timeZone?: string): string {
  const options: { timeZone?: string } = {}

  if (timeZone) {
    options.timeZone = timeZone
  }

  const dateString = date.toLocaleDateString('en-US', options)

  // this is to keep backwards-compatibility with the rest of the app, which expects the format in YYYY-MM-DD
  const parts = dateString.split('/')
  let [month, day] = parts
  const year = parts[2]

  if (month.length < 2) {
    month = '0' + month
  }

  if (day.length < 2) {
    day = '0' + day
  }

  return [year, month, day].join('-')
}

/**
 * Converts a Date string in the format of YYYY-MM-DD to a JavaScript Date object
 */
export function convertDateStringToDate(dateString: string): Date {
  const [yearString, monthString, dayString] = dateString.split('-')

  const year = parseInt(yearString, 10)
  const month = parseInt(monthString, 10)
  const day = parseInt(dayString, 10)

  return new Date(year, month - 1, day, 0, 0, 0, 0)
}

/**
 * Returns the difference in days between a date and now. If the date is in the past,
 * it returns a positive number. If the date is in the future, it returns a negative number.
 * Partial days are rounded.
 *
 * @param dateTime {string|Luxon.DateTime} the time to compare to. If a string, it must be an ISO8601 timestamp
 * @returns {number} number of days this date is in the past
 */
export const daysFromNow = (dateTime: string | DateTime): number => {
  if (typeof dateTime === 'string') {
    dateTime = DateTime.fromISO(dateTime)
  }

  const result = Math.round(dateTime.diffNow('days').days)

  // Luxon returns the difference as negative when the date is in the past
  // Math.round(-0.2) is -0, not 0, so simplifying here
  return result === 0 ? 0 : -1 * result
}

const padZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`)

export const buildISO8601Date = (month: number, day: number, year: number): string =>
  `${year}-${padZero(month)}-${padZero(day)}`

export const iso8601DateStringToParts = (
  dateString: string,
): [number | undefined, number | undefined, number | undefined] => {
  const [year, month, day] = dateString.split('-')

  return [parseInt(month, 10) || undefined, parseInt(day, 10) || undefined, parseInt(year, 10) || undefined]
}

export const isWeekday = (date: Date): boolean => DateTime.fromJSDate(date).weekday <= 5

/** Calculate the age given a date of birth in the format YYYY-MM-DD */
export function calculateAge(dateOfBirth: string, today: DateTime): number {
  return Math.floor(today.diff(DateTime.fromISO(dateOfBirth), 'years').as('years'))
}

/** Returns whether it is someone's birthday given a date of birth in the format YYYY-MM-DD */
export function isBirthday(dateOfBirth: string, today: DateTime): boolean {
  const birthDate = DateTime.fromISO(dateOfBirth)

  return today.day === birthDate.day && today.month === birthDate.month
}

/** Returns a date in the format MM/DD/YYYY given a date of birth in the format YYYY-MM-DD */
export function stringifyDateOfBirth(dateOfBirth: string): string {
  return DateTime.fromISO(dateOfBirth).toLocaleString({
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

import { ReactElement } from 'react'
import { generatePath, useParams, useRouteMatch } from 'react-router-dom'
import Layer from '../../../ui/layer'
import { PATIENT_VIEW } from '../../../../consts/data-testids'
import { PatientBloodPressureSetsParams } from '../../../../consts/routes'
import PatientProfile from '../../../views/patient-profile'
import { CloseButtonLink } from '../../../ui/close-button'
import useQueryParams from '../../../../hooks/use-query-params'
import { IconSize } from '../../../ui/icon'
import { Routes } from '../../../../types/route'

interface ListParams {
  serializedListId?: string
}

export function PatientView(): ReactElement {
  const { patientId } = useParams<PatientBloodPressureSetsParams>()
  const taskMatch = useRouteMatch('/tasks/:taskId')
  const surveysMatch = useRouteMatch('/surveys/:query')
  const patientListMatch = useRouteMatch<ListParams>(Routes.PATIENT_LIST)
  const filteredDashboardMatch = useRouteMatch<ListParams>(Routes.DASHBOARD)
  const searchMatch = useRouteMatch<ListParams>(Routes.SEARCH)
  const query = useQueryParams()

  return (
    <Layer>
      <div
        className="absolute top-0 right-0 w-[calc(100vw-80px)] md:w-1/2 md:min-w-[600px] h-screen overflow-hidden bg-rivaOffwhite shadow-xl flex flex-col"
        data-testid={PATIENT_VIEW}
      >
        <div className="absolute left-7 top-[30px]">
          <CloseButtonLink
            size={IconSize.SMALL}
            to={
              taskMatch
                ? taskMatch.url
                : surveysMatch
                ? surveysMatch.url
                : patientListMatch
                ? generatePath(Routes.PATIENT_LIST, {
                    serializedListId: patientListMatch.params.serializedListId ?? '',
                  })
                : searchMatch
                ? generatePath(Routes.SEARCH, {
                    serializedListId: searchMatch.params.serializedListId ?? '',
                  })
                : filteredDashboardMatch
                ? `/dashboard${
                    filteredDashboardMatch.params.serializedListId
                      ? '/' + filteredDashboardMatch.params.serializedListId
                      : ''
                  }`
                : '/dashboard'
            }
          />
        </div>
        <PatientProfile initialSelectedTab={query.has('surveys') ? 1 : undefined} patientId={patientId} />
      </div>
    </Layer>
  )
}

import { Features } from '../types/features'
import { AcquisitionChannel, MedicalRecordSource, PatientType } from '../types/patient'
import { getPatientMedicalRecordSource } from '../utils/patient'
import useFlag from './use-flag'

const useHasAthenaProfile = (acquisitionChannel: AcquisitionChannel | '', patientType?: PatientType): boolean => {
  return (
    useFlag(Features.ATHENA_PATIENT_SYNC) &&
    getPatientMedicalRecordSource(acquisitionChannel, patientType) === MedicalRecordSource.ATHENA
  )
}

export default useHasAthenaProfile

import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode | ReactNode[]
  collapsible?: boolean
  expanded?: boolean
  onExpandCollapse?: () => void
  className?: string
  'data-testid'?: string
  'data-testid-expand-button'?: string
  subtitle?: string
  title: ReactNode
}

const HighlightCard = ({ children, className, 'data-testid': testId, subtitle, title }: Props): ReactElement => (
  <section className={clsx('bg-white rounded-lg p-3 shadow-sm', className)} data-testid={testId}>
    <header className="px-4 pt-4 pb-7">
      <h2 className="mb-1 font-bold text-2xl">{title}</h2>
      {subtitle ? <p className="text-base text-rivaOffblack-600">{subtitle}</p> : null}
    </header>
    <main>{children}</main>
  </section>
)

export default HighlightCard

import { JSONSchemaType } from 'ajv'
import { BloodPressureGoal, CommunicationPreferences, Patient, ReminderTime, ReminderTimes } from '../../types/patient'
import { Device } from '../../types/device'
import { auditStatusSchema } from './audit-status'

const bloodPressureGoalSchema: JSONSchemaType<BloodPressureGoal> = {
  type: 'object',
  properties: {
    diastolicBloodPressure: {
      type: 'number',
    },
    systolicBloodPressure: {
      type: 'number',
    },
    updated: auditStatusSchema,
  },
  required: ['diastolicBloodPressure', 'systolicBloodPressure', 'updated'],
}

const deviceSchema: JSONSchemaType<Device> = {
  type: 'object',
  properties: {
    activationState: {
      type: 'string',
    },
    created: auditStatusSchema,
    deviceId: {
      type: 'string',
    },
    hardwareVersion: {
      type: 'string',
    },
    id: {
      type: 'string',
    },
    manufacturer: {
      type: 'string',
    },
    modelName: {
      type: 'string',
    },
    patientId: {
      type: 'string',
    },
    timeZone: {
      type: 'object',
      properties: {
        timeZoneOffset: {
          type: 'number',
        },
        updated: auditStatusSchema,
      },
      required: [],
    },
    type: {
      type: 'string',
    },
    updated: auditStatusSchema,
  },
  required: [
    'activationState',
    'created',
    'deviceId',
    'hardwareVersion',
    'id',
    'manufacturer',
    'modelName',
    'patientId',
    'timeZone',
    'type',
    'updated',
  ],
}

const communicationPreferencesSchema: JSONSchemaType<CommunicationPreferences> = {
  type: 'object',
  properties: {
    dayOfWeek: {
      type: 'string',
    },
    hour: {
      type: 'string',
    },
  },
  required: [],
}

const reminderTimeSchema: JSONSchemaType<ReminderTime> = {
  type: 'object',
  properties: {
    hour: {
      type: 'number',
    },
    minute: {
      type: 'number',
    },
  },
  required: ['hour', 'minute'],
}

const reminderTimesSchema: JSONSchemaType<ReminderTimes> = {
  type: 'object',
  properties: {
    am: reminderTimeSchema,
    pm: reminderTimeSchema,
  },
  required: ['am', 'pm'],
}

export const patientSchema: JSONSchemaType<Patient> = {
  type: 'object',
  properties: {
    acquisitionChannel: {
      type: 'string',
    },
    bloodPressureGoal: bloodPressureGoalSchema,
    bloodPressureManagementStatus: {
      type: 'string',
    },
    bloodPressureNotificationDisabled: {
      type: 'boolean',
    },
    careTeam: {
      type: 'object',
      properties: {
        coachId: {
          type: 'string',
        },
        pharmacistId: {
          type: 'string',
        },
        prescriberId: {
          type: 'string',
        },
      },
      required: ['coachId', 'pharmacistId', 'prescriberId'],
      nullable: true,
    },
    careTeamIds: {
      type: 'array',
      items: {
        type: 'string',
      },
      nullable: false,
    },
    created: auditStatusSchema,
    dateOfBirth: {
      type: 'string',
    },
    departmentId: {
      type: 'string',
    },
    devices: {
      type: 'array',
      items: deviceSchema,
      nullable: true,
    },
    deviceType: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    firstName: {
      type: 'string',
    },
    id: {
      type: 'string',
    },
    lastName: {
      type: 'string',
    },
    medicalRecordNumber: {
      type: 'string',
    },
    personId: {
      type: 'string',
    },
    phoneNumber: {
      type: 'string',
    },
    photoUrl: {
      type: 'string',
    },
    preferredName: {
      type: 'string',
    },
    primaryChatChannel: {
      type: 'string',
    },
    pronouns: {
      type: 'string',
    },
    referringProvider: {
      type: 'string',
    },
    communicationPreferences: communicationPreferencesSchema,
    reminderTimes: reminderTimesSchema,
    status: {
      type: 'string',
    },
    timeZone: {
      type: 'string',
    },
    type: {
      type: 'string',
    },
  },
  required: [
    'acquisitionChannel',
    'bloodPressureGoal',
    'bloodPressureManagementStatus',
    'bloodPressureNotificationDisabled',
    'careTeamIds',
    'created',
    'dateOfBirth',
    'departmentId',
    'deviceType',
    'email',
    'firstName',
    'id',
    'lastName',
    'medicalRecordNumber',
    'personId',
    'phoneNumber',
    'photoUrl',
    'preferredName',
    'primaryChatChannel',
    'pronouns',
    'referringProvider',
    'communicationPreferences',
    'reminderTimes',
    'status',
    'timeZone',
    'type',
  ],
}

import { AppThunk } from '../reducers'
import { fetchChatTokenFailed, fetchChatTokenFulfilled, fetchingChatToken } from '../actions/chat'
import { getChatRecordState, getExpiresAt } from '../selectors/chat'
import { RecordState } from '../../types/record-state'

export const fetchChatToken =
  (): AppThunk =>
  async (dispatch, getState, { apiClient }) => {
    // TODO: Check for expiry and existence
    const state = getState()
    const expiresAt = getExpiresAt(state)
    const recordState = getChatRecordState(state)

    if (recordState === RecordState.LOADING || (recordState === RecordState.LOADED && expiresAt > Date.now())) {
      return
    }

    dispatch(fetchingChatToken())

    try {
      const { token, expiresAt } = (await apiClient.getChatToken()) || []

      dispatch(fetchChatTokenFulfilled(token, expiresAt))
    } catch (e) {
      dispatch(fetchChatTokenFailed(e as Error))
    }
  }

import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  arrowProps: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
  children: ReactNode
}

export default forwardRef<HTMLDivElement, Props>(function Tooltip(
  { arrowProps, children, className, hidden, ...props },
  ref,
) {
  const containerRef = useRef<HTMLElement>()

  useEffect(() => {
    if (!containerRef.current) {
      let element = document.getElementById('tooltip-container')

      if (!element) {
        element = document.createElement('div')
        element.id = 'tooltip-container'
        document.body.appendChild(element)
      }

      containerRef.current = element
    }
  }, [])

  if (!containerRef.current || hidden) {
    return null
  }

  return createPortal(
    <div
      {...props}
      ref={ref}
      className={clsx(
        'shadow-md z-120 bg-rivaOffblack-900 rounded text-sm box-border block px-3 text-white leading-10 animate-fadein',
        className,
      )}
      role="tooltip"
    >
      <span {...arrowProps} className="absolute invisible h-2 w-2 bg-inherit" data-popper-arrow>
        <span className="visible absolute h-2 w-2 bg-inherit transform rotate-45"></span>
      </span>
      {children}
    </div>,
    containerRef.current,
  )
})

import { MouseEventHandler, ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import deepEquals from 'lodash.isequal'
import clsx from 'clsx'
import { Button, ButtonVariant, MenuButton } from '../../../ui/button'
import { SvgIcon } from '../../../ui/icon'
import { ControlledMenu, useMenuState } from '../../../ui/menu'
import { ReactComponent as AdjustmentsIcon } from '../../../icons/outline/adjustments.svg'
import FieldSet from '../../../ui/field-set'
import FormLabel from '../../../ui/form-label'
import { buildTaskListId, parseListId, serializeListIdForRoute } from '../../../../utils/lists'
import { TaskQueryParam, TaskStatus } from '../../../../types/task'
import { ListDescriptor } from '../../../../types/lists'
import { useAppSelector } from '../../../../redux'
import { getPractitionerName } from '../../../../redux/selectors/practitioner'
import { getPatientName } from '../../../../redux/selectors/patient'
import { getStatusText } from '../../../../utils/tasks'
import useOnClickOutside from '../../../../hooks/use-on-click-outside'
import Checkbox from '../../../ui/checkbox'
import PatientMultiCombobox from '../../../ui/forms/patient-multi-combobox'
import PractitionerMultiCombobox from '../../../ui/forms/practitioner-multi-combobox'
import { Item } from '../../../ui/forms/types'
import { track } from '../../../../i13n'

interface Props {
  currentListId: string
}

const TaskFilterMenu = ({ currentListId }: Props): ReactElement => {
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLElement>(null)
  const { closeMenu, menuProps, toggleMenu } = useMenuState()
  const {
    practitionerId: currentPractitionerId,
    patientId: currentPatientId,
    taskStatus: currentStatuses,
  } = parseListId(currentListId)
  const currentPractitionerItems = useAppSelector(
    (state) =>
      currentPractitionerId?.map(
        (id): Item => ({
          name: getPractitionerName(state, id),
          type: 'option',
          value: id,
        }),
      ),
    deepEquals,
  )
  const currentPatientItems = useAppSelector(
    (state) =>
      currentPatientId?.map(
        (id): Item => ({
          name: getPatientName(state, id),
          type: 'option',
          value: id,
        }),
      ),
    deepEquals,
  )

  const [selectedPractitioners, setSelectedPractitioners] = useState<Item[]>(currentPractitionerItems ?? [])
  const [selectedPatients, setSelectedPatients] = useState<Item[]>(currentPatientItems ?? [])
  const [selectedStatuses, setSelectedStatuses] = useState<TaskStatus[]>(currentStatuses ?? [])

  const descriptor = useMemo(
    (): Partial<ListDescriptor> => ({
      practitionerId: selectedPractitioners.length ? selectedPractitioners.map(({ value }) => value) : undefined,
      patientId: selectedPatients.length ? selectedPatients.map(({ value }) => value) : undefined,
      taskStatus: selectedStatuses.length ? selectedStatuses : undefined,
    }),
    [selectedPatients, selectedPractitioners, selectedStatuses],
  )
  const newListId = useMemo(
    () =>
      buildTaskListId({
        ...descriptor,
        query: TaskQueryParam.ALL,
        sortOrder: 'DESC',
      }),
    [descriptor],
  )

  const pendingChecked = selectedStatuses.includes(TaskStatus.TaskPending)
  const togglePending = useCallback(() => {
    if (pendingChecked) {
      setSelectedStatuses(selectedStatuses.filter((status) => status !== TaskStatus.TaskPending))
    } else {
      setSelectedStatuses([...selectedStatuses, TaskStatus.TaskPending])
    }
  }, [pendingChecked, selectedStatuses])

  const inProgressChecked = selectedStatuses.includes(TaskStatus.TaskInProgress)
  const toggleInProgress = useCallback(() => {
    if (inProgressChecked) {
      setSelectedStatuses(selectedStatuses.filter((status) => status !== TaskStatus.TaskInProgress))
    } else {
      setSelectedStatuses([...selectedStatuses, TaskStatus.TaskInProgress])
    }
  }, [inProgressChecked, selectedStatuses])

  const completedChecked = selectedStatuses.includes(TaskStatus.TaskCompleted)
  const toggleCompleted = useCallback(() => {
    if (completedChecked) {
      setSelectedStatuses(selectedStatuses.filter((status) => status !== TaskStatus.TaskCompleted))
    } else {
      setSelectedStatuses([...selectedStatuses, TaskStatus.TaskCompleted])
    }
  }, [completedChecked, selectedStatuses])

  const skippedChecked = selectedStatuses.includes(TaskStatus.TaskCompletedSkipped)
  const toggleSkipped = useCallback(() => {
    if (skippedChecked) {
      setSelectedStatuses(selectedStatuses.filter((status) => status !== TaskStatus.TaskCompletedSkipped))
    } else {
      setSelectedStatuses([...selectedStatuses, TaskStatus.TaskCompletedSkipped])
    }
  }, [selectedStatuses, skippedChecked])

  const notApplicableChecked = selectedStatuses.includes(TaskStatus.TaskNa)
  const toggleNotApplicable = useCallback(() => {
    if (notApplicableChecked) {
      setSelectedStatuses(selectedStatuses.filter((status) => status !== TaskStatus.TaskNa))
    } else {
      setSelectedStatuses([...selectedStatuses, TaskStatus.TaskNa])
    }
  }, [notApplicableChecked, selectedStatuses])

  const history = useHistory()
  const onClear = useCallback(() => {
    if (selectedPractitioners.length) {
      setSelectedPractitioners([])
    }

    if (selectedPatients.length) {
      setSelectedPatients([])
    }

    if (selectedStatuses.length) {
      setSelectedStatuses([])
    }

    closeMenu()
    history.push(`/tasks/${TaskQueryParam.ALL}`)

    track('Filter Tasks Cleared')
  }, [closeMenu, history, selectedPatients.length, selectedPractitioners.length, selectedStatuses.length])

  const onApply = useCallback(() => {
    history.push(`/tasks/${TaskQueryParam.ALL}/${serializeListIdForRoute(descriptor)}`)

    track('Filter Tasks Submitted', {
      'Practitioner filters': selectedPractitioners.map((item) => item.name),
      'Patient filters': selectedPatients.length,
      'Status filters': selectedStatuses.map(getStatusText),
    })
  }, [descriptor, history, selectedPatients.length, selectedPractitioners, selectedStatuses])

  const onMenuButtonClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      toggleMenu()

      track('Filter Tasks Clicked')
    },
    [toggleMenu],
  )
  useOnClickOutside(menuRef, closeMenu)

  const currentFilterNumber =
    (currentPatientId?.length || 0) + (currentPractitionerId?.length || 0) + (currentStatuses?.length || 0)

  return (
    <>
      <MenuButton
        active={menuProps.state === 'open'}
        className={clsx('group', {
          'pr-3': currentFilterNumber,
        })}
        onClick={onMenuButtonClick}
        ref={menuButtonRef}
        variant={ButtonVariant.SECONDARY}
      >
        <SvgIcon Icon={AdjustmentsIcon} />
        <span className="ml-2">Filter</span>
        {currentFilterNumber ? (
          <span className="inline-block w-6 h-6 ml-3 rounded-sm text-sm leading-6 font-semibold text-rivaOffblack-900 bg-rivaOffblack-200 group-active:bg-white group-[.active]:bg-white">
            {currentFilterNumber}
          </span>
        ) : null}
      </MenuButton>
      <ControlledMenu {...menuProps} align="start" anchorRef={menuButtonRef} ref={menuRef} className="cursor-default">
        <div
          className="w-[384px] p-7"
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
        >
          <FieldSet>
            <PractitionerMultiCombobox selectedItems={selectedPractitioners} onChange={setSelectedPractitioners} />
          </FieldSet>
          <FieldSet className="pt-7">
            <PatientMultiCombobox selectedItems={selectedPatients} onChange={setSelectedPatients} />
          </FieldSet>
          <FieldSet className="pt-7">
            <FormLabel order={-1}>Status</FormLabel>
            <div className="grid grid-cols-[24px_1fr] gap-x-2 gap-y-3">
              <Checkbox
                checked={pendingChecked}
                id={`filter-menu-${TaskStatus.TaskPending}`}
                onChange={togglePending}
              />
              <label
                className="text-sm font-medium leading-6 h-6"
                htmlFor={`filter-menu-${TaskStatus.TaskPending}`}
                onClick={togglePending}
              >
                {getStatusText(TaskStatus.TaskPending)}
              </label>
              <Checkbox
                checked={inProgressChecked}
                id={`filter-menu-${TaskStatus.TaskInProgress}`}
                onChange={toggleInProgress}
              />
              <label
                className="text-sm font-medium leading-6 h-6"
                htmlFor={`filter-menu-${TaskStatus.TaskInProgress}`}
                onClick={toggleInProgress}
              >
                {getStatusText(TaskStatus.TaskInProgress)}
              </label>
              <Checkbox
                checked={completedChecked}
                id={`filter-menu-${TaskStatus.TaskCompleted}`}
                onChange={toggleCompleted}
              />
              <label
                className="text-sm font-medium leading-6 h-6"
                htmlFor={`filter-menu-${TaskStatus.TaskCompleted}`}
                onClick={toggleCompleted}
              >
                {getStatusText(TaskStatus.TaskCompleted)}
              </label>
              <Checkbox
                checked={skippedChecked}
                id={`filter-menu-${TaskStatus.TaskCompletedSkipped}`}
                onChange={toggleSkipped}
              />
              <label
                className="text-sm font-medium leading-6 h-6"
                htmlFor={`filter-menu-${TaskStatus.TaskCompletedSkipped}`}
                onClick={toggleSkipped}
              >
                {getStatusText(TaskStatus.TaskCompletedSkipped)}
              </label>
              <Checkbox
                checked={notApplicableChecked}
                id={`filter-menu-${TaskStatus.TaskNa}`}
                onChange={toggleNotApplicable}
              />
              <label
                className="text-sm font-medium leading-6 h-6"
                htmlFor={`filter-menu-${TaskStatus.TaskNa}`}
                onClick={toggleNotApplicable}
              >
                {getStatusText(TaskStatus.TaskNa)}
              </label>
            </div>
          </FieldSet>
        </div>
        <footer className="p-7 flex justify-end border-t border-rivaOffblack-200">
          <Button
            disabled={
              !selectedPatients.length &&
              !selectedPractitioners.length &&
              !selectedStatuses.length &&
              !currentFilterNumber
            }
            onClick={onClear}
            variant={ButtonVariant.SECONDARY}
          >
            Clear filters
          </Button>
          <Button className="ml-3" disabled={newListId === currentListId} onClick={onApply}>
            Apply filters
          </Button>
        </footer>
      </ControlledMenu>
    </>
  )
}

export default TaskFilterMenu

import clsx from 'clsx'
import { ReactElement } from 'react'

interface Props {
  backgroundType?: 'sidebar' | 'white'
}

const NewIndicator = ({ backgroundType = 'sidebar' }: Props): ReactElement => (
  <span
    className={clsx(
      'w-[8px] h-[8px] absolute top-0 -right-[2px] rounded-full bg-rivaRed-400 outline outline-2 outline-offset-0 animate-fadein',
      {
        'outline-rivaPurple-900': backgroundType === 'sidebar',
        'outline-white': backgroundType === 'white',
      },
    )}
  ></span>
)

export default NewIndicator

import {
  ActionCreator,
  FetchTaskByIdFailedAction,
  FetchTaskByIdSucceededAction,
  FetchTasksFailedAction,
  FetchTasksRequestedAction,
  FetchTasksSucceededAction,
  FETCH_TASKS_FAILED,
  FETCH_TASKS_REQUESTED,
  FETCH_TASKS_SUCCEEDED,
  FETCH_TASK_BY_ID_FAILED,
  FETCH_TASK_BY_ID_SUCCEEDED,
  ListResetAction,
  LIST_RESET,
  PrefetchTasksSucceededAction,
  PREFETCH_TASKS_SUCCEEDED,
  TaskAssignedAction,
  TaskAssignFailedAction,
  TaskAssignSucceededAction,
  TaskNoteAddFailedAction,
  TaskNoteAddRequestedAction,
  TaskNoteAddSucceededAction,
  TaskDueDateChangedAction,
  TaskDueDateChangeFailedAction,
  TaskDueDateChangeSucceededAction,
  TaskOpenedAction,
  TaskStatusUpdatedAction,
  TaskStatusUpdateFailedAction,
  TaskStatusUpdateSucceededAction,
  TASK_ASSIGNED,
  TASK_ASSIGN_FAILED,
  TASK_ASSIGN_SUCCEEDED,
  TASK_NOTE_ADD_FAILED,
  TASK_NOTE_ADD_REQUESTED,
  TASK_NOTE_ADD_SUCCEEDED,
  TASK_DUE_DATE_CHANGED,
  TASK_DUE_DATE_CHANGE_FAILED,
  TASK_DUE_DATE_CHANGE_SUCCEEDED,
  TASK_OPENED,
  TASK_STATUS_UPDATED,
  TASK_STATUS_UPDATE_FAILED,
  TASK_STATUS_UPDATE_SUCCEEDED,
  TaskEventUndoneAction,
  TASK_EVENT_UNDONE,
  TaskEventUndoSucceededAction,
  TASK_EVENT_UNDO_SUCCEEDED,
  TaskEventUndoFailedAction,
  TASK_EVENT_UNDO_FAILED,
} from '../../types/actions'
import {
  FETCH_TASK_CARE_PATHWAY_FAILED,
  FETCH_TASK_CARE_PATHWAY_FULFILLED,
  FETCH_TASK_CARE_PATHWAY_REQUESTED,
  FetchTaskCarePathwayFailedAction,
  FetchTaskCarePathwayFulfilledAction,
  FetchTaskCarePathwayRequestedAction,
  TASK_CHOICE_SELECT_FAILED,
  TASK_CHOICE_SELECT_FULFILLED,
  TASK_CHOICE_SELECT_REQUESTED,
  TaskChoiceSelectFailedAction,
  TaskChoiceSelectFulfilledAction,
  TaskChoiceSelectRequestedAction,
} from '../../types/task'

export const fetchTasksRequested: ActionCreator<FetchTasksRequestedAction> = (payload) => ({
  type: FETCH_TASKS_REQUESTED,
  payload,
})

export const fetchTasksFailed: ActionCreator<FetchTasksFailedAction> = (payload) => ({
  type: FETCH_TASKS_FAILED,
  payload,
})

export const fetchTasksSucceeded: ActionCreator<FetchTasksSucceededAction> = (payload) => ({
  type: FETCH_TASKS_SUCCEEDED,
  payload,
})

export const taskAssigned: ActionCreator<TaskAssignedAction> = (payload) => ({
  type: TASK_ASSIGNED,
  payload,
})

export const taskAssignSucceeded: ActionCreator<TaskAssignSucceededAction> = (payload) => ({
  type: TASK_ASSIGN_SUCCEEDED,
  payload,
})

export const taskAssignFailed: ActionCreator<TaskAssignFailedAction> = (payload) => ({
  type: TASK_ASSIGN_FAILED,
  payload,
})

export const taskStatusUpdated: ActionCreator<TaskStatusUpdatedAction> = (payload) => ({
  type: TASK_STATUS_UPDATED,
  payload,
})

export const taskStatusUpdateSucceeded: ActionCreator<TaskStatusUpdateSucceededAction> = (payload) => ({
  type: TASK_STATUS_UPDATE_SUCCEEDED,
  payload,
})

export const taskStatusUpdateFailed: ActionCreator<TaskStatusUpdateFailedAction> = (payload) => ({
  type: TASK_STATUS_UPDATE_FAILED,
  payload,
})

export const taskDueDateChanged: ActionCreator<TaskDueDateChangedAction> = (payload) => ({
  type: TASK_DUE_DATE_CHANGED,
  payload,
})

export const taskDueDateChangeSucceeded: ActionCreator<TaskDueDateChangeSucceededAction> = (payload) => ({
  type: TASK_DUE_DATE_CHANGE_SUCCEEDED,
  payload,
})

export const taskDueDateChangeFailed: ActionCreator<TaskDueDateChangeFailedAction> = (payload) => ({
  type: TASK_DUE_DATE_CHANGE_FAILED,
  payload,
})

export const prefetchTasksSucceeded: ActionCreator<PrefetchTasksSucceededAction> = (payload) => ({
  type: PREFETCH_TASKS_SUCCEEDED,
  payload,
})

export const resetList: ActionCreator<ListResetAction> = (payload) => ({
  type: LIST_RESET,
  payload,
})

export const openTask: ActionCreator<TaskOpenedAction> = (payload) => ({
  type: TASK_OPENED,
  payload,
})

export const fetchTaskByIdSucceeded: ActionCreator<FetchTaskByIdSucceededAction> = (payload) => ({
  type: FETCH_TASK_BY_ID_SUCCEEDED,
  payload,
})

export const fetchTaskByIdFailed: ActionCreator<FetchTaskByIdFailedAction> = (payload) => ({
  type: FETCH_TASK_BY_ID_FAILED,
  payload,
})

export const taskNoteAddRequested: ActionCreator<TaskNoteAddRequestedAction> = (payload) => ({
  type: TASK_NOTE_ADD_REQUESTED,
  payload,
})

export const taskNoteAddSucceeded: ActionCreator<TaskNoteAddSucceededAction> = (payload) => ({
  type: TASK_NOTE_ADD_SUCCEEDED,
  payload,
})

export const taskNoteAddFailed: ActionCreator<TaskNoteAddFailedAction> = (payload) => ({
  type: TASK_NOTE_ADD_FAILED,
  payload,
})

export const taskEventUndone: ActionCreator<TaskEventUndoneAction> = (payload) => ({
  type: TASK_EVENT_UNDONE,
  payload,
})

export const taskEventUndoSucceeded: ActionCreator<TaskEventUndoSucceededAction> = (payload) => ({
  type: TASK_EVENT_UNDO_SUCCEEDED,
  payload,
})

export const taskEventUndoFailed: ActionCreator<TaskEventUndoFailedAction> = (payload) => ({
  type: TASK_EVENT_UNDO_FAILED,
  payload,
})

export const fetchTaskCarePathwayRequested: ActionCreator<FetchTaskCarePathwayRequestedAction> = (payload) => ({
  type: FETCH_TASK_CARE_PATHWAY_REQUESTED,
  payload,
})

export const fetchTaskCarePathwayFulfilled: ActionCreator<FetchTaskCarePathwayFulfilledAction> = (payload) => ({
  type: FETCH_TASK_CARE_PATHWAY_FULFILLED,
  payload,
})

export const fetchTaskCarePathwayFailed: ActionCreator<FetchTaskCarePathwayFailedAction> = (payload) => ({
  type: FETCH_TASK_CARE_PATHWAY_FAILED,
  payload,
})

export const taskChoiceSelectRequested: ActionCreator<TaskChoiceSelectRequestedAction> = (payload) => ({
  type: TASK_CHOICE_SELECT_REQUESTED,
  payload,
})

export const taskChoiceSelectFulfilled: ActionCreator<TaskChoiceSelectFulfilledAction> = (payload) => ({
  type: TASK_CHOICE_SELECT_FULFILLED,
  payload,
})

export const taskChoiceSelectFailed: ActionCreator<TaskChoiceSelectFailedAction> = (payload) => ({
  type: TASK_CHOICE_SELECT_FAILED,
  payload,
})

import {
  MouseEventHandler,
  ReactElement,
  ReactNode,
  RefCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { useCombobox, useMultipleSelection } from 'downshift'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'
import { IconSize, SvgIcon } from '../icon'
import TextInput from '../text-input'
import { ReactComponent as ChevronDown } from '../../icons/solid/chevron-down.svg'
import { List, ListItem } from '../list'
import { sameWidthModifier } from '../../../utils/popper'
import Checkbox from '../checkbox'
import { Button, ButtonVariant } from '../button'
import FormLabel from '../form-label'
import Layer from '../layer'
import { SelectDivider } from '../select'

export interface ComboboxOptionItem<T = string> {
  hasDivider?: boolean
  type: 'option'
  value: T
}

type Item = ComboboxOptionItem

type IndexedOption<T> = T & {
  index: number
}

interface Props<T extends Item> {
  clearable?: boolean
  containerClassName?: string
  disabled?: boolean
  errored?: boolean
  filtered?: boolean
  invalid?: boolean
  itemMatches: (inputValue: string, item: T) => boolean
  items: T[]
  itemToKey: (item: T) => string
  label?: string
  loading?: boolean
  maxHeight?: 'normal' | 'short'
  onChange: (selectedItems: T[]) => void
  placeholder?: string
  placement?: Placement
  renderInput?: (isOpen: boolean, props: Record<string, unknown>) => ReactNode
  renderItem?: (item: T) => ReactNode
  // This component is controlled for now
  selectedItems: T[]
  warning?: boolean
}

function MultiCombobox<T extends Item>({
  clearable,
  containerClassName,
  disabled,
  errored,
  filtered,
  invalid,
  itemMatches,
  items,
  itemToKey,
  label,
  loading,
  maxHeight,
  onChange,
  placeholder,
  placement = 'bottom-end',
  renderInput,
  renderItem,
  selectedItems,
  warning,
}: Props<T>): ReactElement {
  const indexedItems: IndexedOption<T>[] = items.map((item, index) => ({
    ...item,
    index,
  }))

  const options = indexedItems.filter(({ type }) => type === 'option')

  const [inputElement, setInputElement] = useState<HTMLElement>()
  const [menuElement, setMenuElement] = useState<HTMLDivElement>()
  const filterRef = useRef<HTMLInputElement>(null)

  const menuRefCallback = useCallback<RefCallback<HTMLDivElement>>((element) => {
    if (element) {
      setMenuElement(element)
    }
  }, [])

  const { attributes, styles, update } = usePopper(inputElement, menuElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      sameWidthModifier,
    ],
  })

  const { addSelectedItem, getDropdownProps, setSelectedItems } = useMultipleSelection({
    selectedItems,
    onStateChange: ({ selectedItems: newSelectedItems, type }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
          onChange(newSelectedItems ?? [])
          break
      }
    },
  })
  const {
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    inputValue,
  } = useCombobox({
    initialIsOpen: false,
    items: options,
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (selectedItems.find((item) => item.value === selectedItem.value)) {
              setSelectedItems(selectedItems.filter((item) => item.value !== selectedItem.value))
            } else {
              addSelectedItem(selectedItem)
            }
          }
          break
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges

      switch (type) {
        case useCombobox.stateChangeTypes.FunctionSelectItem:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...changes,
            inputValue: state.inputValue,
            isOpen: true,
          }
        case useCombobox.stateChangeTypes.InputChange:
          return changes
        default:
          return {
            ...changes,
            inputValue: state.inputValue,
          }
      }
    },
  })

  const inputRefCallback = useCallback<RefCallback<HTMLInputElement>>((element) => {
    if (element) {
      setInputElement(element)
    }
  }, [])

  const inputProps = getInputProps({
    ...getDropdownProps({ preventKeyAction: isOpen, ref: inputRefCallback }),
    autoCorrect: 'off',
    className: clsx('w-full pr-16 transition-all duration-150 text-sm outline-none rounded border', {
      'border-rivaOffblack-300': !isOpen && (!invalid || errored) && !warning,
      'border-rivaPurple-500': isOpen && (!invalid || errored) && !warning,
      'border-rivaGold-300': warning,
      'border-rivaFuchsia-500': invalid,
      'bg-rivaGold-50': warning,
      'bg-rivaFuchsia-50': invalid,
      'text-rivaOffblack-900': (!invalid || errored) && !warning,
      'text-rivaGold-800': warning,
      'text-rivaFuchsia-800': invalid,
      'hover:border-rivaOffblack-500': !isOpen && (!invalid || errored) && !warning,
      'hover:border-rivaGold-800': !isOpen && warning,
      'hover:border-rivaFuchsia-800': !isOpen && invalid,
    }),
    disabled,
  })

  const onClearClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      setSelectedItems([])
    },
    [setSelectedItems],
  )

  useEffect(() => {
    if (isOpen && update) {
      update()
    }
  }, [isOpen, update, items.length])

  useEffect(() => {
    if (isOpen && filterRef.current) {
      filterRef.current.focus()
    }
  }, [isOpen])

  const inputInvalid = invalid || errored ? 'invalid' : undefined
  const visibleItems = indexedItems.filter((item) => itemMatches(inputValue ?? '', item))

  return (
    <>
      <div className={clsx('order-2 relative', containerClassName)}>
        {renderInput ? (
          renderInput(isOpen, {
            ...inputProps,
            invalid: inputInvalid,
            placeholder,
          })
        ) : (
          <>
            <TextInput {...inputProps} placeholder={placeholder} variant={inputInvalid ? 'invalid' : undefined} />
            {selectedItems.length ? (
              <span className="bg-rivaOffblack-200 rounded-sm text-rivaOffblack-900 absolute right-9 top-2 w-6 h-6 leading-6 text-sm font-semibold text-center">
                {selectedItems.length}
              </span>
            ) : null}
            <button
              {...getToggleButtonProps({
                className: 'absolute h-5 w-5 top-[10px] right-3',
                type: 'button',
              })}
            >
              <SvgIcon
                Icon={ChevronDown}
                size={IconSize.SMALL}
                className={clsx('fill-current transform transition-transform ease-out duration-150', {
                  'text-rivaOffblack-900': !isOpen && (!invalid || errored),
                  'text-rivaPurple-500': isOpen && (!invalid || errored),
                  'text-rivaFuchsia-800': invalid,
                  'rotate-180': isOpen,
                })}
              />
            </button>
          </>
        )}
        <Layer>
          <div
            {...getMenuProps({
              ...attributes.popper,
              className: clsx('z-[9999999] border border-rivaOffblack-200 shadow-xl rounded bg-white', {
                hidden: !isOpen || (!options.length && !loading),
              }),
              style: styles.popper,
              ref: menuRefCallback,
            })}
            hidden={!isOpen}
          >
            <List maxHeight={maxHeight} paddingLess={filtered}>
              {filtered ? (
                <li className="border-b border-rivaOffblack-100 bg-white sticky top-0">
                  <input
                    {...inputProps}
                    className="border-none pl-4 py-4 pr-14 outline-none text-sm leading-[18px] w-full bg-white"
                    placeholder={placeholder}
                    ref={filterRef}
                  />
                  {selectedItems.length ? (
                    <span className="bg-rivaOffblack-200 rounded-sm text-rivaOffblack-900 absolute right-3 top-[13px] w-6 h-6 leading-6 text-sm font-semibold text-center">
                      {selectedItems.length}
                    </span>
                  ) : null}
                </li>
              ) : null}
              {!loading &&
                !errored &&
                visibleItems.map((item) => (
                  <>
                    <ListItem
                      {...getItemProps({
                        className: clsx('cursor-pointer h-8 mb-1', {
                          'bg-rivaPurple-100': highlightedIndex === item.index,
                        }),
                        index: item.index,
                        item,
                      })}
                      key={itemToKey(item)}
                    >
                      {renderItem ? renderItem(item) : item.value}
                      <Checkbox
                        checked={!!selectedItems.find((selectedItem) => selectedItem.value === item.value)}
                        data-practitionerid={item.value}
                      />
                    </ListItem>
                    {item.hasDivider ? <SelectDivider className="!mt-2 mb-2" key={`divider-${item.index}`} /> : null}
                  </>
                ))}
              {loading && <ListItem className="text-rivaOffblack-400 cursor-default">Loading...</ListItem>}
              {errored && <ListItem className="text-rivaRed-400 cursor-default">Something went wrong</ListItem>}
            </List>
            {!loading && clearable ? (
              <footer className="mt-1 px-4 py-5 border-t border-rivaOffblack-300 flex justify-end">
                <Button
                  disabled={selectedItems.length === 0}
                  onClick={onClearClick}
                  type="button"
                  variant={ButtonVariant.UNDERSTATED}
                >
                  Clear
                </Button>
              </footer>
            ) : null}
          </div>
        </Layer>
      </div>
      {label ? <FormLabel {...getLabelProps()}>{label}</FormLabel> : null}
    </>
  )
}

export default MultiCombobox

import clsx from 'clsx'
import { ComponentProps, ReactElement, useCallback } from 'react'
import { matchPath, useRouteMatch } from 'react-router-dom'
import { ReactComponent as IconHeartHandSolid } from '../../icons/solid/heart-hand.svg'
import { ReactComponent as IconHeartHandOutline } from '../../icons/outline/heart-hand.svg'
import { ReactComponent as IconUserGroupSolid } from '../../icons/solid/user-group.svg'
import { ReactComponent as IconUserGroupOutline } from '../../icons/outline/user-group.svg'
import { useAppSelector } from '../../../redux'
import { getLoggedInPractitionerId } from '../../../redux/selectors/app'
import {
  MAX_PATIENTS_PER_PAGE,
  buildDefaultPatientListDescriptor,
  parseSerializedListId,
  serializeListIdForRoute,
} from '../../../utils/lists'
import { BloodPressureManagementStatus, PatientType } from '../../../types/patient'
import { ReactComponent as IconInfinityOutline } from '../../icons/outline/infinity.svg'
import useFlag from '../../../hooks/use-flag'
import { Features } from '../../../types/features'
import NavigationItem from './navigation-item'

const PatientsNavigationItem = (): ReactElement | null => {
  const isPaginationEnabled = useFlag(Features.PATIENT_PAGES)
  const basePath = '/patients'
  const isPageActive = !!useRouteMatch({
    path: basePath,
    exact: false,
  })
  const loggedInPractitionerId = useAppSelector(getLoggedInPractitionerId)
  const isMineActive = useCallback<NonNullable<ComponentProps<typeof NavigationItem>['isActive']>>(
    (_, location) => {
      const match = matchPath<{ serializedListId: string }>(location.pathname, {
        path: '/patients/:serializedListId',
      })

      if (!match?.params.serializedListId) {
        return false
      }

      const { practitionerId } = parseSerializedListId(match.params.serializedListId)

      return (practitionerId?.length === 1 && practitionerId[0] === loggedInPractitionerId) ?? false
    },
    [loggedInPractitionerId],
  )
  const isAllActive = useCallback<NonNullable<ComponentProps<typeof NavigationItem>['isActive']>>(
    (_, location) => !isMineActive(_, location),
    [isMineActive],
  )

  if (!isPaginationEnabled) {
    return (
      <li>
        <NavigationItem
          tabIndex={0}
          to="/dashboard"
          label="Patients"
          iconLeft={({ isActive }) => (isActive ? <IconUserGroupSolid /> : <IconUserGroupOutline />)}
        />
      </li>
    )
  }

  return (
    <>
      <li
        className={clsx('rounded', {
          'bg-white bg-opacity-10': isPageActive,
        })}
      >
        <NavigationItem
          exact
          iconLeft={({ isActive }) => (isActive ? <IconUserGroupSolid /> : <IconUserGroupOutline />)}
          label="Patients"
          to={basePath}
        />
        {isPageActive ? (
          <>
            <NavigationItem
              className="capitalize border-b"
              iconLeft={({ isActive }) => (isActive ? <IconHeartHandSolid /> : <IconHeartHandOutline />)}
              isActive={isMineActive}
              label="My patients"
              level="SECONDARY"
              to={`${basePath}/${serializeListIdForRoute(buildDefaultPatientListDescriptor(loggedInPractitionerId))}`}
            />
            <NavigationItem
              className="capitalize border-b"
              iconLeft={() => <IconInfinityOutline />}
              isActive={isAllActive}
              label="All patients"
              level="SECONDARY"
              to={`${basePath}/${serializeListIdForRoute({
                limit: MAX_PATIENTS_PER_PAGE,
                managementStatus: BloodPressureManagementStatus.ACTIVE_MANAGEMENT,
                offset: 0,
                patientType: [PatientType.MANAGED],
              })}`}
            />
          </>
        ) : null}
      </li>
    </>
  )
}

export default PatientsNavigationItem

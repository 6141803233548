import { useState, useEffect, ReactElement } from 'react'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import deepEquals from 'lodash.isequal'
import { Modal } from '../ui/modal'
import { FormIds, FormSubmissionState } from '../../types/form'
import { getFormSubmissionState } from '../../redux/selectors/form'
import { LoadingIndicator, LoadingIndicatorPosition } from '../ui/loading-indicator'
import { Button, ButtonVariant } from '../ui/button'
import { createPatient } from '../../redux/thunks/patient'
import { getAllPractitionerNamesAndIds } from '../../redux/selectors/practitioner'
import { createPatientFormInitialized } from '../../redux/actions/patient'
import { DeviceTypes } from '../ui/device-types'
import { useAppDispatch, useAppSelector } from '../../redux'
import { Option, Select } from '../ui/select'
import FieldSet from '../ui/field-set'
import FormLabel from '../ui/form-label'
import {
  AcquisitionChannel,
  CommunicationPreferences,
  HL7EthniticyCode,
  HL7RaceCode,
  LanguageISO6392Code,
  MaritalStatus,
  MedicalRecordSource,
  PatientType,
  ReminderTimes,
} from '../../types/patient'
import { IconSize, SvgIcon } from '../ui/icon'
import { ReactComponent as ChevronDown } from '../icons/solid/chevron-down.svg'
import { ReactComponent as InformationCircle } from '../icons/solid/information-circle.svg'
import TextInput from '../ui/text-input'
import useToggle from '../../hooks/use-toggle'
import {
  ADD_PATIENT_MODAL,
  EMERGENCY_CONTACT_NAME_INPUT,
  EMERGENCY_PHONE_INPUT,
  PATIENT_FIRST_NAME_INPUT,
  PATIENT_PHONE_INPUT,
} from '../../consts/data-testids'
import { ModalContainer } from '../ui/modal/modal-container'
import ModalFooter from '../ui/modal/modal-footer'
import FormSection from '../ui/form-section'
import WirelessCuffSelector from '../ui/forms/wireless-cuff-selector'
import { ReferringProvider } from '../../types/provider'
import { getProviderIdsAndNames } from '../../redux/selectors/provider'
import TextInputNotice from '../ui/text-input-notice'
import MedicalRecordNumberInput from '../ui/forms/medical-record-number-input'
import { CommunicationPreferencesSelector } from '../ui/forms/communication-preferences'
import DepartmentIdSelector from '../ui/forms/department-id-selector'
import useFlag from '../../hooks/use-flag'
import { Features } from '../../types/features'
import useGetListIdFromRoute from '../../hooks/use-get-list-id-from-route'
import PronounsSelector from '../ui/forms/pronouns-selector'
import FormTitle from '../ui/forms/form-title'
import DateInput from '../ui/date-input'
import StateSelector from '../ui/forms/state-selector'
import EmergencyContactRelationshipSelector, {
  EmergencyContactRelationship,
} from '../ui/forms/emergency-contact-relationship-selector'
import AcquisitionChannelRadioGroup from '../ui/forms/acquisition-channel-radio-group'
import ConsentRadioGroup from '../ui/forms/consent-radio-group'
import { Radio, RadioGroup, RadioLabel, useRadioGroup } from '../ui/radio'
import LanguageSelector from '../ui/forms/language-selector'
import RaceSelector from '../ui/forms/race-selector'
import EthnicitySelector from '../ui/forms/ethnicity-selector'
import { getPatientMedicalRecordSource, shouldHaveReferringProvider } from '../../utils/patient'
import { StateAbbreviations } from '../../utils/l10n'
import useHasAthenaProfile from '../../hooks/use-has-athena-profile'
import ReminderTimeSelector from '../ui/forms/reminder-time-selector'
import Confirm from './confirm'

// this is a string because this is what the html pattern attribute expects.
// it does NOT expect a javascript regex.
export const TELEPHONE_NUMBER_REGEX = '[0-9]{10}$'

interface AddPatientModalProps {
  isOpen: boolean
  close: () => void
}
export function AddPatientModal({ isOpen, close }: AddPatientModalProps): ReactElement {
  const listId = useGetListIdFromRoute()
  const dispatch = useAppDispatch()

  const patientFormState = useAppSelector((state) => getFormSubmissionState(state, FormIds.CREATE_PATIENT))
  const practitioners = useAppSelector(getAllPractitionerNamesAndIds, deepEquals)
  const providers = useAppSelector((state) => getProviderIdsAndNames(state))
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [preferredName, setPreferredName] = useState('')
  const [departmentId, setDepartmentId] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [mrn, setMrn] = useState('')
  const [pronouns, setPronouns] = useState('')
  const [deviceType, setDeviceType] = useState('')
  const [coachId, setCoachId] = useState('')
  const [pharmacistId, setPharmacistId] = useState('')
  const [prescriberId, setPrescriberId] = useState('')

  // device types taken from https://github.com/devicekit/DeviceKit/blob/master/Source/Device.generated.swift#L1106
  const [amReminderHour, setAmReminderHour] = useState(-1)
  const [amReminderMinute, setAmReminderMinute] = useState(-1)
  const [pmReminderHour, setPmReminderHour] = useState(-1)
  const [pmReminderMinute, setPmReminderMinute] = useState(-1)
  const [patientType, setPatientType] = useState(PatientType.MANAGED)
  const [isAdministrativeVisible, toggleAdministrativeVisible] = useToggle(false)
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [acquisitionChannel, setAcquisitionChannel] = useState<AcquisitionChannel | undefined>()
  const [referringProvider, setReferringProvider] = useState('')
  const [wirelessCuffId, setWirelessCuffId] = useState('')
  const [isWirelessCuffIdInvalid, setIsWirelessCuffIdInvalid] = useState(false)
  const [communicationPreferencesDays, setComunicationPreferencesDays] = useState('')

  const [driversLicenseNumber, setDriversLicenseNumber] = useState('')
  const [driversLicenseExpirationDate, setDriversLicenseExpirationDate] = useState('')
  const [driversLicenseStateId, setDriversLicenseStateId] = useState<StateAbbreviations | undefined>()
  const [streetAddress, setStreetAddress] = useState('')
  const [city, setCity] = useState('')
  const [locationState, setLocationState] = useState<StateAbbreviations | undefined>()
  const [zipCode, setZipCode] = useState('')
  const [emergencyContactName, setEmergencyContactName] = useState('')
  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] = useState('')
  const [emergencyContactRelationship, setEmergencyContactRelationship] = useState<
    EmergencyContactRelationship | undefined
  >()
  const [consentToCall, setConsentToCall] = useState<'Yes' | 'No' | undefined>()
  const [consentToText, setConsentToText] = useState<'Yes' | 'No' | undefined>()
  const [legalSex, setLegalSex] = useState<string | undefined>()
  const legalSexRadioGroup = useRadioGroup({
    onChange: setLegalSex,
    value: legalSex,
  })
  const [sexAssignedAtBirth, setSexAssignedAtBirth] = useState<string | undefined>()
  const sexAssignedAtBirthRadioGroup = useRadioGroup({
    onChange: setSexAssignedAtBirth,
    value: sexAssignedAtBirth,
  })
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus | undefined>()
  const [language, setLanguage] = useState<LanguageISO6392Code | undefined>()
  const [race, setRace] = useState<HL7RaceCode | undefined>()
  const [ethnicity, setEthnicity] = useState<HL7EthniticyCode | undefined>()

  const hasAthenaProfile = useHasAthenaProfile(acquisitionChannel ?? '', patientType)
  const includeDepartmentId =
    getPatientMedicalRecordSource(acquisitionChannel ?? '', patientType) === MedicalRecordSource.ATHENA

  const isAthenaPatientSyncEnabled = useFlag(Features.ATHENA_PATIENT_SYNC) && hasAthenaProfile
  const includeReferringProvider = shouldHaveReferringProvider(acquisitionChannel ?? '')

  useEffect(() => {
    if (!includeReferringProvider) {
      setReferringProvider('')
    }
  }, [includeReferringProvider])

  useEffect(() => {
    if (patientType === PatientType.VIP) {
      setAcquisitionChannel(AcquisitionChannel.N_A)
    }
  }, [patientType, setAcquisitionChannel])

  useEffect(() => {
    if (patientFormState === FormSubmissionState.SUBMITTED) {
      dispatch(createPatientFormInitialized())
      close()
    }
  }, [patientFormState, close, dispatch])

  useEffect(() => {
    if (!includeDepartmentId && departmentId) {
      setDepartmentId('')
    }
  }, [departmentId, includeDepartmentId])

  const isMrnManual = patientType !== PatientType.VIP && !isAthenaPatientSyncEnabled

  const acquisitionChannelInvalid = !acquisitionChannel
  const referringProviderInvalid = includeReferringProvider && !referringProvider
  const rivaCareTeamInvalid = !prescriberId || !coachId || !pharmacistId
  const departmentIdInvalid = includeDepartmentId && !departmentId
  const mrnInvalid = isMrnManual && !mrn

  const dispatchAddPatient = () => {
    const reminderTimes: ReminderTimes = {
      am: {
        hour: amReminderHour,
        minute: amReminderMinute,
      },
      pm: {
        hour: pmReminderHour,
        minute: pmReminderMinute,
      },
    }

    const communicationPreferences: CommunicationPreferences = {
      dayOfWeek: communicationPreferencesDays,
      hour: '',
    }

    dispatch(
      createPatient(listId, {
        firstName,
        lastName,
        pronouns,
        phoneNumber,
        email,
        departmentId,
        deviceType,
        reminderTimes,
        communicationPreferences,
        medicalRecordNumber: mrn,
        type: patientType,
        acquisitionChannel: acquisitionChannel ?? '',
        referringProvider,
        dateOfBirth,
        preferredName,
        wirelessCuffId,
        prescriberId,
        pharmacistId,
        coachId,

        //athena fields
        driversLicenseNumber,
        driversLicenseExpirationDate,
        driversLicenseStateId,
        streetAddress,
        city,
        locationState,
        zipCode,
        emergencyContactName,
        emergencyContactPhoneNumber,
        emergencyContactRelationship,
        consentToCall: consentToCall === 'Yes' ? true : consentToCall === 'No' ? false : undefined,
        consentToText: consentToText === 'Yes' ? true : consentToText === 'No' ? false : undefined,
        legalSex,
        sexAssignedAtBirth,
        maritalStatus,
        language,
        race,
        ethnicity,
      }),
    )
  }

  return (
    <Modal isOpen={isOpen} closeModal={close} data-testid={ADD_PATIENT_MODAL} size="2xl">
      <Confirm
        confirmButtonText="Add VIP"
        confirmButtonVariant={ButtonVariant.ERROR}
        isOpen={isConfirmationDialogOpen}
        onAccept={() => {
          setConfirmationDialogOpen(false)
          dispatchAddPatient()
        }}
        onCancel={() => {
          setConfirmationDialogOpen(false)
        }}
        title="Are you sure you want to add the user as a VIP?"
        subtitle="VIPs are hidden from Riva Management and will not receive care"
      >
        <p className="flex items-center mb-10 px-3 bg-rivaOffblack-100 rounded h-8 text-xs text-rivaOffblack-900">
          <SvgIcon Icon={InformationCircle} size={IconSize.SMALL} className="mr-2" />
          To change a VIP back into a patient, you must contact the engineering team
        </p>
      </Confirm>
      <ModalContainer width="long">
        <form
          className="flex flex-col"
          onSubmit={(e) => {
            e.preventDefault()

            setIsSubmitted(true)

            if (
              !dateOfBirth ||
              acquisitionChannelInvalid ||
              departmentIdInvalid ||
              referringProviderInvalid ||
              isWirelessCuffIdInvalid ||
              mrnInvalid ||
              rivaCareTeamInvalid
            ) {
              return
            }

            if (patientType === PatientType.VIP) {
              setConfirmationDialogOpen(true)

              return
            }

            dispatchAddPatient()
          }}
        >
          <header className="pt-10 pb-7 flex-none">
            <h2 className="text-rivaOffblack-900 text-lg font-semibold mb-1">
              {patientType === PatientType.VIP ? 'Create VIP' : 'Create new patient'}
            </h2>
            <p className="text-rivaOffblack-600 text-sm font-normal opacity-80">
              Fields marked with an asterisk (*) are required
            </p>
            {patientFormState === FormSubmissionState.ERRORED && (
              <p className="text-rivaRed mb-2">
                Sorry, we failed to create the patient. Please refresh the page and contact support if the problem
                persists.
              </p>
            )}
          </header>
          <main className="flex-1">
            <FormSection>
              <FormTitle>Acquisition Channel</FormTitle>
              <FieldSet colspan={2}>
                <AcquisitionChannelRadioGroup onChange={setAcquisitionChannel} required value={acquisitionChannel} />
              </FieldSet>
              {includeReferringProvider && (
                <FieldSet colspan={2}>
                  <Select
                    value={referringProvider}
                    onChange={(value) => setReferringProvider(value ?? '')}
                    id="referringProvider"
                    invalid={isSubmitted && referringProviderInvalid}
                    label="Referring Provider"
                    placeholder="Referring Provider"
                    required
                  >
                    {providers
                      .map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))
                      .concat([
                        <Option key={ReferringProvider.NOT_LISTED} value={ReferringProvider.NOT_LISTED}>
                          Provider not listed
                        </Option>,
                        <Option key={ReferringProvider.NOT_APPLICABLE} value={ReferringProvider.NOT_APPLICABLE}>
                          Not applicable
                        </Option>,
                      ])}
                  </Select>
                </FieldSet>
              )}
              {includeDepartmentId ? (
                <DepartmentIdSelector
                  acquisitionChannel={acquisitionChannel ?? ''}
                  isSubmitted={isSubmitted}
                  value={departmentId}
                  onChange={setDepartmentId}
                  required
                />
              ) : null}
            </FormSection>
            <FormSection>
              <FormTitle>Care Team</FormTitle>
              <FieldSet colspan={2}>
                <Select
                  value={coachId}
                  onChange={(value) => setCoachId(value ?? '')}
                  id="coach"
                  invalid={isSubmitted && !coachId}
                  data-testid="coach"
                  placeholder="Coach"
                >
                  {practitioners.map((practitioner) => (
                    <Option key={practitioner.id} value={practitioner.id}>
                      {practitioner.name}
                    </Option>
                  ))}
                </Select>
                <FormLabel htmlFor="coach" required invalid={isSubmitted && !coachId}>
                  Coach
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <Select
                  value={pharmacistId}
                  onChange={(value) => setPharmacistId(value ?? '')}
                  id="pharmacist"
                  invalid={isSubmitted && !pharmacistId}
                  placeholder="Pharmacist"
                >
                  {practitioners.map((practitioner) => (
                    <Option key={practitioner.id} value={practitioner.id}>
                      {practitioner.name}
                    </Option>
                  ))}
                </Select>
                <FormLabel htmlFor="pharmacist" required invalid={isSubmitted && !pharmacistId}>
                  Pharmacist
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <Select
                  value={prescriberId}
                  invalid={isSubmitted && !prescriberId}
                  onChange={(value) => setPrescriberId(value ?? '')}
                  id="prescriber"
                  placeholder="Prescriber"
                >
                  {practitioners.map((practitioner) => (
                    <Option key={practitioner.id} value={practitioner.id}>
                      {practitioner.name}
                    </Option>
                  ))}
                </Select>
                <FormLabel htmlFor="prescriber" required invalid={isSubmitted && !prescriberId}>
                  Prescriber
                </FormLabel>
              </FieldSet>
            </FormSection>
            <FormSection>
              <FormTitle>Identification</FormTitle>
              <FieldSet>
                <TextInput
                  required={isSubmitted}
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  data-testid={PATIENT_FIRST_NAME_INPUT}
                />
                <FormLabel htmlFor="firstName" required>
                  First Name
                </FormLabel>
              </FieldSet>
              <FieldSet>
                <TextInput
                  required={isSubmitted}
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
                <FormLabel htmlFor="lastName" required>
                  Last Name
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <TextInput
                  id="preferredName"
                  placeholder="Preferred Name"
                  value={preferredName}
                  onChange={(event) => setPreferredName(event.target.value)}
                />
                <FormLabel htmlFor="preferredName">Preferred Name</FormLabel>
              </FieldSet>
              <PronounsSelector isSubmitted={isSubmitted} onChange={setPronouns} value={pronouns} />
              <FieldSet colspan={2}>
                <DateInput
                  id="dateOfBirth"
                  data-testid="html-input-date"
                  max={DateTime.now().toISODate() ?? ''}
                  min={DateTime.now().minus({ years: 125 }).toISODate() ?? ''}
                  name="dateOfBirth"
                  onChange={setDateOfBirth}
                  required={isSubmitted}
                  value={dateOfBirth}
                />
                <FormLabel htmlFor="dateOfBirth" id="dateOfBirth-label" required>
                  Date of birth
                </FormLabel>
              </FieldSet>
              {isMrnManual ? (
                <FieldSet colspan={2}>
                  <MedicalRecordNumberInput
                    acquisitionChannel={acquisitionChannel ?? ''}
                    dateOfBirth={dateOfBirth}
                    firstName={firstName}
                    lastName={lastName}
                    onChange={setMrn}
                    required={isSubmitted}
                    value={mrn}
                  />
                </FieldSet>
              ) : null}
              <div className="col-span-2 grid grid-cols-3 gap-2">
                {isAthenaPatientSyncEnabled ? (
                  <FieldSet>
                    <TextInput
                      className="w-full"
                      id="driversLicense"
                      maxLength={20}
                      placeholder="License Number"
                      value={driversLicenseNumber}
                      onChange={(event) => setDriversLicenseNumber(event.target.value)}
                    />
                    <FormLabel htmlFor="driversLicense">Driver’s License Number</FormLabel>
                  </FieldSet>
                ) : null}
                {isAthenaPatientSyncEnabled ? (
                  <FieldSet>
                    <DateInput
                      id="driversLicenseExpirationDate"
                      value={driversLicenseExpirationDate}
                      onChange={setDriversLicenseExpirationDate}
                    />
                    <FormLabel htmlFor="driversLicenseExpirationDate">Expiration date</FormLabel>
                  </FieldSet>
                ) : null}
                {isAthenaPatientSyncEnabled ? (
                  <FieldSet>
                    <StateSelector
                      id="driversLicenseStateId"
                      value={driversLicenseStateId}
                      onChange={setDriversLicenseStateId}
                    />
                    <FormLabel htmlFor="driversLicenseStateId">State</FormLabel>
                  </FieldSet>
                ) : null}
              </div>
            </FormSection>
            <FormSection>
              <FormTitle>Personal contact information</FormTitle>
              <FieldSet colspan={2}>
                <TextInput
                  type="email"
                  id="email"
                  required={isSubmitted}
                  placeholder="Email Address"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <FormLabel htmlFor="email" required>
                  Email
                </FormLabel>
              </FieldSet>
              <FieldSet colspan={2}>
                <TextInput
                  data-testid={PATIENT_PHONE_INPUT}
                  type="tel"
                  required={isSubmitted}
                  id="phoneNumber"
                  pattern={TELEPHONE_NUMBER_REGEX}
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />
                <FormLabel htmlFor="phoneNumber" required>
                  Phone Number
                </FormLabel>
              </FieldSet>
              {isAthenaPatientSyncEnabled ? (
                <FieldSet colspan={2}>
                  <TextInput
                    id="streetAddress"
                    placeholder="Street address"
                    value={streetAddress}
                    onChange={(event) => setStreetAddress(event.target.value)}
                  />
                  <FormLabel htmlFor="streetAddress">Street address</FormLabel>
                </FieldSet>
              ) : null}
              {isAthenaPatientSyncEnabled ? (
                <FieldSet colspan={2}>
                  <TextInput
                    id="city"
                    maxLength={30}
                    onChange={(event) => setCity(event.target.value)}
                    placeholder="City"
                    value={city}
                  />
                  <FormLabel htmlFor="city">City</FormLabel>
                </FieldSet>
              ) : null}
              {isAthenaPatientSyncEnabled ? (
                <FieldSet>
                  <StateSelector id="locationState" onChange={setLocationState} value={locationState} />
                  <FormLabel htmlFor="locationState">State</FormLabel>
                </FieldSet>
              ) : null}
              {isAthenaPatientSyncEnabled ? (
                <FieldSet>
                  <TextInput
                    id="zipCode"
                    max={99999}
                    min={10000}
                    onChange={(event) => setZipCode(event.target.value)}
                    placeholder="Zip code"
                    type="number"
                    value={zipCode}
                  />
                  <FormLabel htmlFor="zipCode">Zip code</FormLabel>
                </FieldSet>
              ) : null}
            </FormSection>
            {isAthenaPatientSyncEnabled ? (
              <FormSection>
                <FormTitle>Emergency contact information</FormTitle>
                <FieldSet colspan={2}>
                  <TextInput
                    data-testid={EMERGENCY_CONTACT_NAME_INPUT}
                    id="emergencyContactName"
                    onChange={(event) => setEmergencyContactName(event.target.value)}
                    placeholder="Name"
                    required={isSubmitted}
                    value={emergencyContactName}
                  />
                  <FormLabel htmlFor="emergencyContactName" required>
                    Name
                  </FormLabel>
                </FieldSet>
                <FieldSet colspan={2}>
                  <TextInput
                    data-testid={EMERGENCY_PHONE_INPUT}
                    id="emergencyContactPhoneNumber"
                    onChange={(event) => setEmergencyContactPhoneNumber(event.target.value)}
                    pattern={TELEPHONE_NUMBER_REGEX}
                    placeholder="Phone number"
                    required={isSubmitted}
                    type="tel"
                    value={emergencyContactPhoneNumber}
                  />
                  <FormLabel htmlFor="emergencyContactPhoneNumber" required>
                    Phone number
                  </FormLabel>
                </FieldSet>
                <FieldSet colspan={2}>
                  <EmergencyContactRelationshipSelector
                    id="emergencyContactRelationship"
                    onChange={setEmergencyContactRelationship}
                    value={emergencyContactRelationship}
                  />
                  <FormLabel htmlFor="emergencyContactRelationship">Relationship to patient</FormLabel>
                </FieldSet>
              </FormSection>
            ) : null}
            <FormSection>
              <FormTitle>Patient communication preferences</FormTitle>
              {isAthenaPatientSyncEnabled ? (
                <FieldSet colspan={2}>
                  <ConsentRadioGroup label="Consent to call" onChange={setConsentToCall} value={consentToCall} />
                </FieldSet>
              ) : null}
              {isAthenaPatientSyncEnabled ? (
                <FieldSet colspan={2}>
                  <ConsentRadioGroup label="Consent to text" onChange={setConsentToText} value={consentToText} />
                </FieldSet>
              ) : null}
              <CommunicationPreferencesSelector
                communicationPreferencesDays={communicationPreferencesDays}
                setCommunicationPreferencesDays={(value) => setComunicationPreferencesDays(value || '')}
              />
              <ReminderTimeSelector
                hourValue={amReminderHour}
                minuteValue={amReminderMinute}
                onHourChange={setAmReminderHour}
                onMinuteChange={setAmReminderMinute}
                timeOfDay="am"
              />
              <ReminderTimeSelector
                hourValue={pmReminderHour}
                minuteValue={pmReminderMinute}
                onHourChange={setPmReminderHour}
                onMinuteChange={setPmReminderMinute}
                timeOfDay="pm"
              />
            </FormSection>
            {isAthenaPatientSyncEnabled ? (
              <FormSection>
                <FormTitle>Demographic information</FormTitle>
                <FieldSet colspan={2}>
                  <FormLabel {...legalSexRadioGroup.getGroupLabelProps()} order={0}>
                    Legal sex
                  </FormLabel>
                  <RadioGroup {...legalSexRadioGroup.getRadioGroupProps()}>
                    <RadioLabel label="Male">
                      <Radio {...legalSexRadioGroup.getRadioProps('M')} />
                    </RadioLabel>
                    <RadioLabel label="Female">
                      <Radio {...legalSexRadioGroup.getRadioProps('F')} />
                    </RadioLabel>
                  </RadioGroup>
                </FieldSet>
                <FieldSet colspan={2}>
                  <FormLabel {...sexAssignedAtBirthRadioGroup.getGroupLabelProps()} order={0}>
                    Sex assigned at birth
                  </FormLabel>
                  <RadioGroup {...sexAssignedAtBirthRadioGroup.getRadioGroupProps()}>
                    <RadioLabel label="Male">
                      <Radio {...sexAssignedAtBirthRadioGroup.getRadioProps('M')} />
                    </RadioLabel>
                    <RadioLabel label="Female">
                      <Radio {...sexAssignedAtBirthRadioGroup.getRadioProps('F')} />
                    </RadioLabel>
                    <RadioLabel label="Choose not to disclose">
                      <Radio {...sexAssignedAtBirthRadioGroup.getRadioProps('N')} />
                    </RadioLabel>
                    <RadioLabel label="Unknown">
                      <Radio {...sexAssignedAtBirthRadioGroup.getRadioProps('U')} />
                    </RadioLabel>
                  </RadioGroup>
                </FieldSet>
                <FieldSet colspan={2}>
                  <Select
                    id="maritalStatus"
                    label="Marital status"
                    onChange={(value) => setMaritalStatus(value)}
                    placeholder="Select marital status"
                    value={maritalStatus}
                  >
                    <Option value={MaritalStatus.Divorced}>Divorced</Option>
                    <Option value={MaritalStatus.Married}>Married</Option>
                    <Option value={MaritalStatus.Single}>Single</Option>
                    <Option value={MaritalStatus.Unknown}>Unknown</Option>
                    <Option value={MaritalStatus.Widowed}>Widowed</Option>
                    <Option value={MaritalStatus.Separated}>Separated</Option>
                    <Option value={MaritalStatus.Partner}>Partner</Option>
                  </Select>
                </FieldSet>
                <FieldSet colspan={2}>
                  <LanguageSelector onChange={setLanguage} value={language} />
                </FieldSet>
                <FieldSet colspan={2}>
                  <RaceSelector onChange={setRace} value={race} />
                </FieldSet>
                <FieldSet colspan={2}>
                  <EthnicitySelector onChange={setEthnicity} value={ethnicity} />
                </FieldSet>
              </FormSection>
            ) : null}
            <FormSection>
              <FormTitle>Device information</FormTitle>
              <WirelessCuffSelector
                initialValue=""
                onChange={setWirelessCuffId}
                onInvalidChange={setIsWirelessCuffIdInvalid}
                value={wirelessCuffId}
              />
              <DeviceTypes deviceType={deviceType} setDeviceType={(value) => setDeviceType(value || '')} />
            </FormSection>
            <section className="border-t border-rivaOffblack-200 py-5 relative">
              <h3
                className="uppercase text-rivaOffblack-700 py-5 text-xxs font-extrabold cursor-pointer"
                onClick={toggleAdministrativeVisible}
              >
                Administrative options
              </h3>
              <button
                type="button"
                className={clsx(
                  'p-1 h-7 w-7 absolute top-9 right-0 transform transition-transform duration-150 ease-in-out',
                  isAdministrativeVisible ? 'rotate-180' : '',
                )}
                onClick={toggleAdministrativeVisible}
                title="Toggle administrative options"
              >
                <SvgIcon Icon={ChevronDown} size={IconSize.SMALL} />
              </button>
              <div className={clsx('grid grid-cols-2 gap-7 mt-2', isAdministrativeVisible ? '' : 'hidden')}>
                <FieldSet className="relative mb-7" colspan={2}>
                  <Select
                    value={patientType}
                    label={
                      <span className={patientType === PatientType.VIP ? 'text-rivaGold-600' : ''}>User Type</span>
                    }
                    onChange={(value) => setPatientType(value ?? PatientType.MANAGED)}
                    warning={patientType === PatientType.VIP}
                    hasAlert
                  >
                    <Option value={PatientType.MANAGED}>Patient</Option>
                    <Option value={PatientType.VIP}>VIP</Option>
                  </Select>
                  {patientType === PatientType.VIP && (
                    <div className="absolute -bottom-10 left-0">
                      <TextInputNotice severity="warning">VIPs are hidden from Riva Management</TextInputNotice>
                    </div>
                  )}
                </FieldSet>
              </div>
            </section>
          </main>
          <ModalFooter>
            {patientFormState === FormSubmissionState.SUBMITTING ? (
              <LoadingIndicator className="mr-4 mt-4 mb-2" position={LoadingIndicatorPosition.RIGHT} />
            ) : (
              <>
                <Button className="mr-3" variant={ButtonVariant.SECONDARY} onClick={close} type="button">
                  Cancel
                </Button>
                <Button
                  variant={patientType === PatientType.VIP ? ButtonVariant.ERROR : ButtonVariant.ACTION}
                  type="submit"
                >
                  {patientType === PatientType.VIP ? 'Create VIP' : 'Create Patient'}
                </Button>
              </>
            )}
          </ModalFooter>
        </form>
      </ModalContainer>
    </Modal>
  )
}

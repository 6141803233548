import clsx from 'clsx'
import { ReactElement } from 'react'

interface Props {
  className?: string
}

const SelectDivider = ({ className }: Props): ReactElement => {
  return <hr aria-hidden className={clsx('border-dashed border-rivaOffblack-300 mt-3 mx-3', className)} />
}

export default SelectDivider

import { createSelector } from 'reselect'
import { RecordState } from '../../types/record-state'
import { SurveyRule, SurveySubmission } from '../../types/survey'
import { RootState } from '../reducers'

export const getRuleById = (state: RootState, id: string): SurveyRule | undefined => state.surveys.rules.byId[id]

const getAllRules = (state: RootState): Record<string, SurveyRule> => state.surveys.rules.byId

export const getSurveyIdsWithRules = createSelector(getAllRules, (rules): number[] => {
  return Object.values(rules).reduce((surveyIds, rule) => {
    if (!surveyIds.includes(rule.surveyId)) {
      surveyIds.push(rule.surveyId)
    }

    return surveyIds
  }, [] as number[])
})

export const getSurveyRulesBySurveyId = (state: RootState, surveyId: number): SurveyRule[] =>
  Object.values(state.surveys.rules.byId).filter((rule) => rule.surveyId === surveyId)

export const getSurveyRuleIdsBySurveyId = (state: RootState, surveyId: number): string[] =>
  getSurveyRulesBySurveyId(state, surveyId).map((rule) => rule.id)

export const getSurveyRulesState = (state: RootState): RecordState => state.surveys.rules.recordState

export const getSurveySubmissionById = (state: RootState, id: string): SurveySubmission | undefined =>
  state.surveys.submissions[id]

export const getSurveySubmissionReviewedBy = (state: RootState, id: string): string | undefined =>
  state.surveys.submissions[id]?.seenBy?.[0]

export const getIdBySubmissionId = (state: RootState, submissionId: number): string | undefined =>
  state.surveys.idBySubmissionId[String(submissionId)]

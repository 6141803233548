import { RecordState } from './record-state'

export const FETCHING_ORGANIZATIONS = 'FETCHING_ORGANIZATIONS'
export const FETCH_ORGANIZATIONS_FULFILLED = 'FETCH_ORGANIZATIONS_FULFILLED'
export const FETCH_ORGANIZATIONS_FAILED = 'FETCH_ORGANIZATIONS_FAILED'

export interface Organization {
  id: string
  name: string
}

export interface OrganizationState {
  recordState: RecordState
  organizationMap: OrganizationMap
}

export interface OrganizationMap {
  [organizationId: string]: Organization
}

export interface FetchingOrganizations {
  type: typeof FETCHING_ORGANIZATIONS
  payload: undefined
}

export interface FetchOrganizationsFulfilled {
  type: typeof FETCH_ORGANIZATIONS_FULFILLED
  payload: {
    organizations: Organization[]
  }
}

export interface FetchOrganizationsFailed {
  type: typeof FETCH_ORGANIZATIONS_FAILED
  payload: {
    error: Error
  }
}

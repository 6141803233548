import { ReactElement, useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router'
import { Button, ButtonVariant } from '../ui/button'
import { Modal } from '../ui/modal'
import { ModalContainer } from '../ui/modal/modal-container'
import { ReactComponent as IconBadgeCheck } from '../icons/outline/badge-check.svg'
import { IconSize, SvgIcon } from '../ui/icon'
import { FormIds, FormSubmissionState } from '../../types/form'
import { getFormSubmissionState } from '../../redux/selectors/form'
import { LoadingIndicator, LoadingIndicatorPosition } from '../ui/loading-indicator'
import { RootRoutes } from '../../consts/routes'
import { getPatientName } from '../../redux/selectors/patient'
import { activatePatientFormInitialized } from '../../redux/actions/patient'
import { activatePatient } from '../../redux/thunks/patient'
import { useAppDispatch, useAppSelector } from '../../redux'
import ModalHeader from '../ui/modal/modal-header'
import FieldSet from '../ui/field-set'
import TextArea from '../ui/text-area'
import FormLabel from '../ui/form-label'
import TextInput from '../ui/text-input'
import ModalFooter from '../ui/modal/modal-footer'

interface ActivatePatientModalProps {
  isOpen: boolean
  close: () => void
  patientId: string
}

export function ActivatePatientModal({ isOpen, close, patientId }: ActivatePatientModalProps): ReactElement {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const name = useAppSelector((state) => getPatientName(state, patientId))
  const [confirmationText, setConfirmationText] = useState('')
  const [noteText, setNoteText] = useState('')
  const activatePatientFormState = useAppSelector((state) => getFormSubmissionState(state, FormIds.ACTIVATE_PATIENT))

  useEffect(() => {
    if (isOpen) {
      dispatch(activatePatientFormInitialized())
    }
  }, [isOpen, dispatch])

  useEffect(() => {
    if (activatePatientFormState === FormSubmissionState.SUBMITTED) {
      // need to initialize it after submitting,
      // otherwise the form will close immediately when this is run after a successful submission
      dispatch(activatePatientFormInitialized())
      history.push(generatePath(RootRoutes.PATIENTS, {}))
      close()
    }
  }, [activatePatientFormState, close, dispatch, history])

  return (
    <Modal isOpen={isOpen} closeModal={close}>
      <ModalContainer>
        <ModalHeader
          subtitle="Reactivating a patient's account restore access to the app and re-enroll them into care."
          title={`Reactivate ${name}'s account?`}
        ></ModalHeader>

        {activatePatientFormState === FormSubmissionState.ERRORED && (
          <p className="text-rivaRed mb-2">
            Sorry, we failed to reactivate the patient. Please refresh the page and contact support if the problem
            persists.
          </p>
        )}

        <form
          className="mx-10"
          onSubmit={(e) => {
            e.preventDefault()
            dispatch(activatePatient(patientId, noteText))
          }}
        >
          <FieldSet className="pt-5">
            <TextArea
              className="resize-none"
              id="deactivationReason"
              onChange={(event) => setNoteText(event.target.value)}
              required
              rows={5}
              value={noteText}
            />
            <FormLabel className="normal-case" htmlFor="deactivationReason" required>
              Tell us why the patient is getting reactivated
            </FormLabel>
          </FieldSet>

          <FieldSet className="py-5">
            <TextInput
              id="confirmationText"
              onChange={(event) => setConfirmationText(event.target.value)}
              pattern="[rR]{1}[eE]{1}[aA]{1}[cC]{1}[tT]{1}[iI]{1}[vV]{1}[aA]{1}[tT]{1}[eE]{1}"
              required
              value={confirmationText}
            />
            <FormLabel className="normal-case" htmlFor="confirmationText" required>
              Type "Reactivate" to confirm
            </FormLabel>
          </FieldSet>

          {activatePatientFormState === FormSubmissionState.SUBMITTING ? (
            <LoadingIndicator position={LoadingIndicatorPosition.LEFT} className="ml-4 mt-4 mb-6" />
          ) : (
            <ModalFooter>
              <Button className="mr-3" variant={ButtonVariant.SECONDARY} onClick={close} type="button">
                Cancel
              </Button>
              <Button variant={ButtonVariant.ACTION} disabled={confirmationText.toUpperCase().trim() !== 'REACTIVATE'}>
                <SvgIcon className="mr-1" Icon={IconBadgeCheck} size={IconSize.SMALL} />
                Reactivate {name}
              </Button>
            </ModalFooter>
          )}
        </form>
      </ModalContainer>
    </Modal>
  )
}

import { ReactElement, ReactNode } from 'react'
import { TaskEvent, TaskStatus } from '../../../../../types/task'
import PractitionerReference from '../../../../ui/practitioner-reference'
import { useAppSelector } from '../../../../../redux'
import { getPractitionerByPersonId } from '../../../../../redux/selectors/practitioner'
import FormattedDateTime from '../../../../ui/formatted-date-time'
import { getStatusText } from '../../../../../utils/tasks'
import { getLoggedInPractitionerId } from '../../../../../redux/selectors/app'
import { TaskEventType } from '../../../../../__generated__/graphql'
import TaskActivityItem from './task-activity-item'

interface Props {
  event: TaskEvent
}

const TaskEventItem = ({ event }: Props): ReactElement | null => {
  let content: ReactNode
  const createdBy = useAppSelector((state) => getPractitionerByPersonId(state, event.created.by)?.id ?? '')
  const loggedInPractitionerId = useAppSelector(getLoggedInPractitionerId)
  const creator = createdBy ? (
    <PractitionerReference practitionerId={createdBy} />
  ) : (
    <strong className="font-semibold">Riva Management</strong>
  )

  switch (event.eventType) {
    case TaskEventType.CreateTask:
      content = <span>{creator} created the task</span>
      break
    case TaskEventType.AssignTask:
      {
        const { assignee } = event.data

        if (createdBy === assignee) {
          content = (
            <span>
              {creator}{' '}
              {createdBy === loggedInPractitionerId ? 'assigned this task to yourself' : 'self-assigned this task'}
            </span>
          )
        } else {
          content = (
            <span>
              {creator} assigned this task to <PractitionerReference practitionerId={event.data.assignee} />
            </span>
          )
        }
      }
      break
    case TaskEventType.SetDueDate:
      content = (
        <span>
          {creator} changed the due date to{' '}
          <FormattedDateTime value={event.data.dueDate} day="numeric" month="numeric" year="2-digit" />
        </span>
      )
      break
    case TaskEventType.UpdateTaskStatus:
      content = (
        <span>
          {creator} marked this task as{' '}
          <strong className="font-semibold">{getStatusText(event.data.taskStatus as TaskStatus)}</strong>
        </span>
      )
      break
    case TaskEventType.VisitCancelled:
      content = <span>This visit was cancelled</span>
      break
    case TaskEventType.VisitRescheduled:
      content = (
        <span>
          This visit was rescheduled to{' '}
          <FormattedDateTime
            value={event.data.startTime}
            day="numeric"
            month="numeric"
            hour="numeric"
            minute="numeric"
          />
        </span>
      )
      break
    default:
      return null
  }

  return (
    <TaskActivityItem
      createdAt={event.created.at}
      id={event.id}
      kind="event"
      note={event.note}
      practitionerId={createdBy}
    >
      {content}
    </TaskActivityItem>
  )
}

export default TaskEventItem

import Ajv, { JSONSchemaType } from 'ajv'
import { SurveySubmission } from '../../../types/survey'
import SurveySubmissionSchema from '../schema/survey-submission'
import * as http from '../../http'
import { uuid } from '../../../utils/uuid'
import { createApiLogger } from '../../../logger'

const ajv = new Ajv()

export interface GetSurveySubmissionsResponse {
  submissions: SurveySubmission[] | null | undefined
  total_count: number
}

const schema: JSONSchemaType<GetSurveySubmissionsResponse> = {
  type: 'object',
  properties: {
    submissions: {
      type: 'array',
      items: SurveySubmissionSchema,
      nullable: true,
    },
    total_count: {
      type: 'number',
    },
  },
  required: ['total_count'],
}

const validate = ajv.compile(schema)

const getSurveySubmissionsWithFilter = async (
  accessToken: string,
  filter: 'seen' | 'unseen',
): Promise<GetSurveySubmissionsResponse> => {
  const requestId = uuid()
  const logger = createApiLogger('GET', '/v1/survey_submissions/:filter', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, statusCode } = await http.get(`/v1/survey_submissions/${filter}`, { accessToken, requestId })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    logger.logSuccess({
      statusCode,
    })

    return body
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default getSurveySubmissionsWithFilter

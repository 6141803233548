import { ReactElement, useCallback } from 'react'
import useTasksApi from '../../../../api/hooks/use-tasks-api'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getListSelectedItemId } from '../../../../redux/selectors/lists'
import { useGetPersonFullName } from '../../../../redux/selectors/person'
import { getAllPractitioners } from '../../../../redux/selectors/practitioner'
import { getTaskById } from '../../../../redux/selectors/tasks'
import { assignTask } from '../../../../redux/thunks/tasks'
import { Practitioner } from '../../../../types/practitioner'
import Command from '../../../ui/command'
import PractitionerAvatar from '../../../ui/practitioner-avatar'
import { ASSIGN_COMMAND_KEYS } from './keys'

interface Props {
  listId: string
}

const AssignCommand = ({ listId }: Props): ReactElement | null => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const practitioners = useAppSelector(getAllPractitioners)
  const getPersonFullName = useGetPersonFullName()
  const selectedItemId = useAppSelector((state) => getListSelectedItemId(state, listId))
  const task = useAppSelector((state) => (selectedItemId ? getTaskById(state, selectedItemId) : undefined))
  const itemKey = useCallback<(practitioner: Practitioner) => string>(({ id }) => id, [])
  const itemToString = useCallback<(practitioner: Practitioner) => string>(
    (practitioner) => getPersonFullName(practitioner.personId),
    [getPersonFullName],
  )
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSelected = useCallback(
    (practitioner: Practitioner) => {
      dispatch(
        assignTask(
          {
            listId,
            id: task?.id ?? '',
            assignee: practitioner.id,
          },
          tasksApi,
        ),
      )
    },
    [dispatch, listId, task?.id, tasksApi],
  )
  const renderItem = useCallback(
    (practitioner: Practitioner) => (
      <div data-practitionerid={practitioner.id} className="flex items-center">
        <PractitionerAvatar practitionerId={practitioner.id} />
        <span className="ml-2">{getPersonFullName(practitioner.personId)}</span>
      </div>
    ),
    [getPersonFullName],
  )

  return (
    <Command
      command={ASSIGN_COMMAND_KEYS}
      entity={
        <div className="h-6 px-2 bg-rivaOffblack-100 text-xs leading-6 text-rivaOffblack-600 rounded inline-block">{`Task - ${task?.id} ${task?.summary}`}</div>
      }
      items={practitioners}
      itemKey={itemKey}
      itemToString={itemToString}
      listId={listId}
      onSelected={onSelected}
      placeholder="Assign to..."
      renderItem={renderItem}
    />
  )
}

export default AssignCommand

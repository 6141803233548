import { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
}
function Navigation({ children }: Props): ReactElement {
  return <ul className="text-sm font-semibold flex flex-col flex-1">{children}</ul>
}

export default Navigation

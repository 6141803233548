import {
  BloodPressureAveragesResponse,
  FETCH_BLOOD_PRESSURE_AVERAGES_FAILED,
  FETCH_BLOOD_PRESSURE_AVERAGES_FULFILLED,
  FetchBloodPressureAveragesFailed,
  FetchBloodPressureAveragesFulfilled,
  FETCHING_BLOOD_PRESSURE_AVERAGES,
  FetchingBloodPressureAverages,
} from '../../types/blood-pressure-moving-average'

export function fetchingBloodPressureAverages(patientId: string): FetchingBloodPressureAverages {
  return {
    type: FETCHING_BLOOD_PRESSURE_AVERAGES,
    payload: { patientId },
  }
}

export function fetchBloodPressureAveragesFulfilled(
  bloodPressureAverages: BloodPressureAveragesResponse,
  patientId: string,
): FetchBloodPressureAveragesFulfilled {
  return {
    type: FETCH_BLOOD_PRESSURE_AVERAGES_FULFILLED,
    payload: {
      bloodPressureAverages,
      patientId,
    },
  }
}

export function fetchBloodPressureAveragesFailed(error: Error, patientId: string): FetchBloodPressureAveragesFailed {
  return {
    type: FETCH_BLOOD_PRESSURE_AVERAGES_FAILED,
    payload: {
      error,
      patientId,
    },
  }
}

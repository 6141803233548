import { ReactElement, useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import useHasAthenaProfile from '../../../../hooks/use-has-athena-profile'
import { useAppSelector } from '../../../../redux'
import { getPatientAcquisitionChannel, getPatientType } from '../../../../redux/selectors/patient'
import { UpdatePatientModal } from '../../../modals/update-patient'
import { MenuItem } from '../../../ui/menu'
import patientAthenaProfilePaidQuery from '../../../../schemas/patient-athena-profile-paid-query'

interface Props {
  patientId: string
}

const EditPatientMenuItem = ({ patientId }: Props): ReactElement => {
  const acquisitionChannel = useAppSelector((state) => getPatientAcquisitionChannel(state, patientId))
  const patientType = useAppSelector((state) => getPatientType(state, patientId))
  const hasAthenaProfile = useHasAthenaProfile(acquisitionChannel ?? '', patientType)
  const [isUpdatePatientModalOpen, setIsUpdatePatientModalOpen] = useState(false)

  const skip = !hasAthenaProfile
  const { data, error, loading, refetch } = useQuery(patientAthenaProfilePaidQuery, {
    context: {
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
    skip,
    variables: {
      patientId,
    },
  })
  const { athenaProfile } = data?.patient ?? {}
  const openModal = useCallback(() => setIsUpdatePatientModalOpen(true), [])

  const onClose = useCallback(() => {
    setIsUpdatePatientModalOpen(false)
    refetch()
  }, [refetch])

  return (
    <>
      <MenuItem disabled={(!!error && error.message !== 'resource not found') || loading} onClick={openModal}>
        Edit Patient Details
      </MenuItem>
      <UpdatePatientModal
        athenaProfile={athenaProfile}
        key={patientId + isUpdatePatientModalOpen}
        isOpen={isUpdatePatientModalOpen}
        close={onClose}
        patientId={patientId}
      />
    </>
  )
}

export default EditPatientMenuItem

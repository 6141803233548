import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useMemo } from 'react'
import * as http from '../../http'
import { uuid } from '../../../utils/uuid'
import { createApiLogger } from '../../../logger'
import getSeenSubmissionsMethod from './get-seen'
import getNewSubmissionsMethod from './get-unseen'

export interface SurveysApi {
  markAsSeen: (id: string, personId: string) => Promise<void>
  markAsUnseen: (id: string, personId: string) => Promise<void>
  getSeenSubmissions: () => ReturnType<typeof getSeenSubmissionsMethod>
  getUnseenSubmissions: () => ReturnType<typeof getNewSubmissionsMethod>
}

const useSurveysApi = (): SurveysApi => {
  const { getAccessTokenSilently } = useAuth0()

  const markAsSeen = useCallback(
    async (id: string, personId: string): Promise<void> => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      const requestId = uuid()
      const logger = createApiLogger('PUT', '/v1/survey_submission/:id/mark_as_seen', {
        http: {
          request_id: requestId,
        },
      })

      try {
        const { statusCode } = await http.put(
          `/v1/survey_submission/${encodeURIComponent(id)}/mark_as_seen`,
          {
            personId,
          },
          {
            accessToken,
            requestId,
          },
        )

        logger.logSuccess({ statusCode })
      } catch (error) {
        logger.logFailure(error)

        throw error
      }
    },
    [getAccessTokenSilently],
  )

  const markAsUnseen = useCallback(
    async (id: string, personId: string): Promise<void> => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        },
      })

      const requestId = uuid()
      const logger = createApiLogger('PUT', '/v1/survey_submission/:id/mark_as_unseen', {
        http: {
          request_id: requestId,
        },
      })

      try {
        const { statusCode } = await http.put(
          `/v1/survey_submission/${encodeURIComponent(id)}/mark_as_unseen`,
          {
            personId,
          },
          {
            accessToken,
            requestId,
          },
        )

        logger.logSuccess({ statusCode })
      } catch (error) {
        logger.logFailure(error)

        throw error
      }
    },
    [getAccessTokenSilently],
  )

  const getSeenSubmissions = useCallback(async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      },
    })

    return getSeenSubmissionsMethod(accessToken)
  }, [getAccessTokenSilently])

  const getUnseenSubmissions = useCallback(async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      },
    })

    return getNewSubmissionsMethod(accessToken)
  }, [getAccessTokenSilently])

  return useMemo(
    () => ({
      markAsSeen,
      markAsUnseen,
      getSeenSubmissions,
      getUnseenSubmissions,
    }),
    [getSeenSubmissions, getUnseenSubmissions, markAsSeen, markAsUnseen],
  )
}

export default useSurveysApi

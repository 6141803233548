import { ReactElement, useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import flatten from 'lodash.flatten'
import clsx from 'clsx'
import { MedicationType } from '../../../../__generated__/graphql'
import medicationsPaidQuery from '../../../../schemas/medications-paid-query'
import EmptyState from '../empty-state'
import ErrorState from '../error-state'
import { LoadingIndicator, LoadingIndicatorSize } from '../../../ui/loading-indicator'
import { Button, ButtonSize, ButtonVariant } from '../../../ui/button'
import { IconSize, SvgIcon } from '../../../ui/icon'
import { ReactComponent as RefreshIcon } from '../../../icons/outline/refresh.svg'
import useTooltip from '../../../../hooks/use-tooltip'
import Tooltip from '../../../ui/tooltip'
import dedupeMeds from './dedupe-meds'
import MedicationItem from './medication-item'

interface Props {
  medicationType: MedicationType
  patientId: string
}

const MedicationList = ({ medicationType, patientId }: Props): ReactElement => {
  const [noCache, setNoCache] = useState(false)
  const { data, loading, error, refetch } = useQuery(medicationsPaidQuery, {
    fetchPolicy: noCache ? 'network-only' : 'cache-and-network',
    variables: {
      medicationType,
      patientId,
    },
    context: {
      headers: noCache
        ? {
            'Cache-Control': 'no-cache',
          }
        : {},
    },
  })
  const [loadingRequested, setLoadingRequested] = useState(false)
  const { getArrowProps, getTooltipProps, getTriggerProps } = useTooltip({
    offset: [0, 8],
  })

  let title = ''

  switch (medicationType) {
    case MedicationType.Active:
      title = 'Current'
      break
    case MedicationType.Denied:
      title = 'Denied'
      break
    case MedicationType.Historical:
      title = 'Historical'
      break
  }

  const onClick = useCallback(async () => {
    setNoCache(true)
    setLoadingRequested(true)
    await refetch()
    setLoadingRequested(false)
  }, [refetch])

  if (!data && loading) {
    return (
      <div className="h-full flex items-center">
        <LoadingIndicator size={LoadingIndicatorSize.LARGE} />
      </div>
    )
  }

  if (error) {
    return <ErrorState />
  }

  if (!data?.patient?.medications.medications.length) {
    return <EmptyState />
  }

  const { lastupdated } = data.patient.medications
  const medications = flatten(data.patient.medications.medications)

  return (
    <div>
      <header className="pt-7 px-7 pb-3 flex items-center">
        <div className="flex-1">
          <h2 className="text-lg font-semibold m-0 text-rivaOffblack-900">{title} medications</h2>
          {lastupdated ? <p className="text-sm font-medium text-rivaOffblack-500">Last updated {lastupdated}</p> : null}
        </div>
        <Button
          {...getTriggerProps()}
          disabled={loading || loadingRequested}
          onClick={onClick}
          size={ButtonSize.XXS}
          variant={ButtonVariant.SECONDARY}
        >
          <span>Refresh</span>
          <SvgIcon
            Icon={RefreshIcon}
            size={IconSize.X_SMALL}
            className={clsx('ml-1', {
              'animate-spin-reverse': loading || loadingRequested,
            })}
          ></SvgIcon>
        </Button>
        <Tooltip {...getTooltipProps()} arrowProps={getArrowProps()}>
          Last refreshed
        </Tooltip>
      </header>
      <main className="mx-7">
        {dedupeMeds(medications).map((medication) => {
          return <MedicationItem key={medication.medicationentryid} medication={medication} />
        })}
      </main>
    </div>
  )
}

export default MedicationList

import { Children, ReactElement, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import type { Props as TabProps } from './tab'

type Child = ReactElement<TabProps> | null

interface Props {
  children: Child[]
  className?: string
  hideBar?: boolean
  selectedTabId: string
}

const TabList = ({ children, className, hideBar, selectedTabId }: Props): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [indicatorLeft, setIndicatorLeft] = useState(0)
  const [indicatorWidth, setIndicatorWidth] = useState(0)
  const childrenCount = Children.toArray(children).length

  useEffect(() => {
    const container = containerRef.current

    if (!container) {
      return
    }

    const calculate = () => {
      const selectedTab = container.querySelector(`#${selectedTabId}`)
      const selectedTabWidth = selectedTab?.getBoundingClientRect().width ?? 0
      let left = parseInt(getComputedStyle(container.children[0]).marginLeft.replace('px', ''), 10)

      let i = 0
      while (i < container.children.length) {
        const child = container.children[i]

        if (child === selectedTab) {
          break
        }

        left += child.getBoundingClientRect().width
        left += parseInt(getComputedStyle(child).marginLeft.replace('px', ''), 10)

        i++
      }
      setIndicatorLeft(left)
      setIndicatorWidth(selectedTabWidth)
    }
    calculate()

    document.fonts?.addEventListener('loadingdone', calculate)

    return () => {
      document.fonts?.removeEventListener('loadingdone', calculate)
    }
  }, [childrenCount, selectedTabId])

  return (
    <div
      className={clsx('relative border-b border-rivaOffblack-200 h-14 overflow-hidden', className)}
      role="tablist"
      ref={containerRef}
    >
      {children}
      {indicatorWidth > 0 && !hideBar && (
        <div
          role="presentation"
          className="absolute bottom-0 left-0 h-1 w-1 bg-rivaPurple-500 transition-all ease-in-out duration-250 transform-gpu"
          style={{
            width: `${indicatorWidth}px`,
            transform: `translateX(${indicatorLeft}px)`,
          }}
        />
      )}
    </div>
  )
}

export default TabList

import { ReactElement, useCallback } from 'react'
import { DateTime } from 'luxon'
import { generatePath } from 'react-router-dom'
import { TableRow } from '../../../ui/table'
import { useAppSelector } from '../../../../redux'
import {
  convertBloodPressureManagementStatusToDisplayName,
  getPatientBloodPressureManagementStatus,
  getPatientDateOfBirth,
  getPatientName,
  getPatientPreferredName,
} from '../../../../redux/selectors/patient'
import MedicalRecordNumber from '../../../views/patient-profile/header/medical-record-number'
import { Routes } from '../../../../types/route'
import { parseListId, serializeListIdForRoute } from '../../../../utils/lists'
import PatientChatButton from '../../../views/patient-profile/patient-chat-button'
import { ButtonSize, ButtonVariant } from '../../../ui/button'
import { track } from '../../../../i13n'
import TextHighlight from '../../../ui/text-highlight'

interface Props {
  listId: string
  patientId: string
}

const i13n = {
  properties: {
    Location: 'Search',
  },
}

const PatientSearchResultItem = ({ listId, patientId }: Props): ReactElement => {
  const { query = '' } = parseListId(listId)
  let patientName = useAppSelector((state) => getPatientName(state, patientId))
  const preferredName = useAppSelector((state) => getPatientPreferredName(state, patientId))
  const dateOfBirth = useAppSelector((state) => getPatientDateOfBirth(state, patientId))
  const managementStatus = useAppSelector((state) => getPatientBloodPressureManagementStatus(state, patientId))

  const onClick = useCallback(() => {
    track('Search Result Clicked', {})
  }, [])

  if (preferredName) {
    patientName += ` (${preferredName})`
  }

  const parsedDob = DateTime.fromISO(dateOfBirth)

  return (
    <TableRow
      className="flex text-sm"
      onClick={onClick}
      to={generatePath(Routes.SEARCH_PATIENT, {
        patientId,
        serializedListId: serializeListIdForRoute(parseListId(listId)),
      })}
    >
      <div className="basis-72 truncate flex-0 h-12 flex items-center" role="cell">
        <TextHighlight highlight={query}>{patientName}</TextHighlight>
      </div>
      <div className="basis-36 pl-3 truncate flex-0 h-12 flex items-center" role="cell">
        {parsedDob.isValid
          ? parsedDob.toLocaleString({
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : '-'}
      </div>
      <div className="basis-36 pl-3 truncate flex-0 h-12 flex items-center" role="cell">
        <MedicalRecordNumber i13n={i13n} patientId={patientId} />
      </div>
      <div className="basis-40 pl-3 truncate flex-0 h-12 flex items-center" role="cell">
        {convertBloodPressureManagementStatusToDisplayName(managementStatus)}
      </div>
      <div className="flex-1 h-12 flex flex-row items-center justify-end invisible group-hover:visible" role="cell">
        <PatientChatButton
          i13n={i13n}
          patientId={patientId}
          size={ButtonSize.XS}
          text="Send message"
          variant={ButtonVariant.PRIMARY}
        />
      </div>
    </TableRow>
  )
}

export default PatientSearchResultItem

import { createContext } from 'react'

export interface WindowSize {
  width: number
  height: number
}

export const context = createContext<WindowSize>({
  width: 0,
  height: 0,
})

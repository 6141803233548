import { ReactNode, ReactElement } from 'react'
import { IconSize, SvgIcon } from '../../ui/icon'
import { ReactComponent as ChevronDown } from '../../icons/solid/chevron-down.svg'
import { ReactComponent as ChevronUp } from '../../icons/solid/chevron-up.svg'
import PatientProfileSection from './patient-profile-section'

export const SummaryContainer = ({
  expanded,
  children,
  collapsible,
  title,
  subtitle,
  onExpandCollapse,
  'data-testid': testId,
  'data-testid-expand': testIdExpand,
}: {
  expanded?: boolean
  children: ReactNode
  collapsible: boolean
  title: string
  subtitle?: string
  onExpandCollapse?: () => void
  'data-testid'?: string
  'data-testid-expand'?: string
}): ReactElement => {
  return (
    <PatientProfileSection data-testid={testId || ''}>
      <header className="p-4">
        <h2 className="m-0 font-semibold text-lg">
          {collapsible ? (
            <button
              className="flex items-center justify-between w-full"
              onClick={onExpandCollapse}
              data-testid={testIdExpand || ''}
            >
              <span>{title}</span>
              <SvgIcon Icon={expanded ? ChevronDown : ChevronUp} size={IconSize.MEDIUM} />
            </button>
          ) : (
            title
          )}
        </h2>
        {subtitle ? <p className="text-base text-rivaOffblack-600">{subtitle}</p> : null}
      </header>
      {!collapsible || expanded ? <main>{children}</main> : null}
    </PatientProfileSection>
  )
}

import { RecordState } from './record-state'

export const FETCHING_PERSONS = 'FETCHING_PERSONS'
export const FETCH_PERSONS_FULFILLED = 'FETCH_PERSONS_FULFILLED'
export const FETCH_PERSONS_FAILED = 'FETCH_PERSONS_FAILED'

export interface Person {
  id: string
  firstName: string
  lastName: string
  email?: string
  phoneNumber?: string
  photoUrl?: string
  pronouns?: string
}

export interface PersonState {
  recordState: RecordState
  personMap: PersonMap
}

export interface PersonMap {
  [personId: string]: Person
}

import { ClickEvent, MenuItem as ReactMenuItem } from '@szhsin/react-menu'
import clsx from 'clsx'
import { ComponentProps, ReactElement, useCallback, useRef } from 'react'
import { I13n } from '../../../types/i13n'
import { track } from '../../../i13n'

type Props = ComponentProps<typeof ReactMenuItem> & { i13n?: Partial<I13n> }

const MenuItem = ({ className, onClick, i13n, ...props }: Props): ReactElement => {
  const ref = useRef<HTMLElement>(null)

  const trackClick = useCallback(() => {
    track(i13n?.eventName ?? 'Menu Item Clicked', {
      'Menu Item': ref.current?.textContent ?? '',
      ...i13n?.properties,
    })
  }, [i13n])

  const handleClick = useCallback(
    (e: ClickEvent) => {
      trackClick()
      onClick && onClick(e)
    },
    [trackClick, onClick],
  )

  return <ReactMenuItem onClick={handleClick} {...props} className={clsx(className, 'px-5')} ref={ref} />
}

export default MenuItem

import { DateTime } from 'luxon'

export const formatPhoneNumber = (phoneNumber: string): string =>
  `(${phoneNumber.charAt(2)}${phoneNumber.charAt(3)}${phoneNumber.charAt(4)}) ${phoneNumber.charAt(
    5,
  )}${phoneNumber.charAt(6)}${phoneNumber.charAt(7)}-${phoneNumber.charAt(8)}${phoneNumber.charAt(
    9,
  )}${phoneNumber.charAt(10)}${phoneNumber.charAt(11)}`

export enum StateAbbreviations {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export const StateNames = {
  [StateAbbreviations.AL]: 'Alabama',
  [StateAbbreviations.AK]: 'Alaska',
  [StateAbbreviations.AZ]: 'Arizona',
  [StateAbbreviations.AR]: 'Arkansas',
  [StateAbbreviations.CA]: 'California',
  [StateAbbreviations.CO]: 'Colorado',
  [StateAbbreviations.CT]: 'Connecticut',
  [StateAbbreviations.DE]: 'Delaware',
  [StateAbbreviations.FL]: 'Florida',
  [StateAbbreviations.GA]: 'Georgia',
  [StateAbbreviations.HI]: 'Hawaii',
  [StateAbbreviations.ID]: 'Idaho',
  [StateAbbreviations.IL]: 'Illinois',
  [StateAbbreviations.IN]: 'Indiana',
  [StateAbbreviations.IA]: 'Iowa',
  [StateAbbreviations.KS]: 'Kansas',
  [StateAbbreviations.KY]: 'Kentucky',
  [StateAbbreviations.LA]: 'Louisiana',
  [StateAbbreviations.ME]: 'Maine',
  [StateAbbreviations.MD]: 'Maryland',
  [StateAbbreviations.MA]: 'Massachusetts',
  [StateAbbreviations.MI]: 'Michigan',
  [StateAbbreviations.MN]: 'Minnesota',
  [StateAbbreviations.MS]: 'Mississippi',
  [StateAbbreviations.MO]: 'Missouri',
  [StateAbbreviations.MT]: 'Montana',
  [StateAbbreviations.NE]: 'Nebraska',
  [StateAbbreviations.NV]: 'Nevada',
  [StateAbbreviations.NH]: 'New Hampshire',
  [StateAbbreviations.NJ]: 'New Jersey',
  [StateAbbreviations.NM]: 'New Mexico',
  [StateAbbreviations.NY]: 'New York',
  [StateAbbreviations.NC]: 'North Carolina',
  [StateAbbreviations.ND]: 'North Dakota',
  [StateAbbreviations.OH]: 'Ohio',
  [StateAbbreviations.OK]: 'Oklahoma',
  [StateAbbreviations.OR]: 'Oregon',
  [StateAbbreviations.PA]: 'Pennsylvania',
  [StateAbbreviations.RI]: 'Rhode Island',
  [StateAbbreviations.SC]: 'South Carolina',
  [StateAbbreviations.SD]: 'South Dakota',
  [StateAbbreviations.TN]: 'Tennessee',
  [StateAbbreviations.TX]: 'Texas',
  [StateAbbreviations.UT]: 'Utah',
  [StateAbbreviations.VT]: 'Vermont',
  [StateAbbreviations.VA]: 'Virginia',
  [StateAbbreviations.WA]: 'Washington',
  [StateAbbreviations.WV]: 'West Virginia',
  [StateAbbreviations.WI]: 'Wisconsin',
  [StateAbbreviations.WY]: 'Wyoming',
}

export const athenaDateToIsoDate = (dateStr: string): string =>
  DateTime.fromFormat(dateStr, 'mm/dd/yyyy').toISODate() ?? ''

export enum SexCode {
  M = 'M',
  F = 'F',
}

export const SexCodeToName = {
  [SexCode.M]: 'Male',
  [SexCode.F]: 'Female',
}

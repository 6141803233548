import { createSelector } from 'reselect'
import { Practitioner, PractitionerNameAndId, PractitionerPhotoSize } from '../../types/practitioner'
import { RootState } from '../reducers'
import { getFirstName, getLastName } from './person'

/**
 * @param practitionerId The id of the practitioner to get
 * @returns Practitioner
 */
export function getPractitioner(state: RootState, practitionerId: string): Practitioner | null {
  return state.practitioner.practitionerMap[practitionerId] || null
}

/**
 * @param practitionerId The id of the practitioner we want to get the name of
 * @returns the practitioner name
 */
export function getPractitionerName(state: RootState, practitionerId: string): string {
  const practitioner = getPractitioner(state, practitionerId)

  return practitioner
    ? `${getFirstName(state, practitioner.personId)} ${getLastName(state, practitioner.personId)}`
    : ''
}

export const getPractitionerShortName = (state: RootState, practitionerId: string): string => {
  const practitioner = getPractitioner(state, practitionerId)

  return practitioner
    ? `${getLastName(state, practitioner.personId)}, ${getFirstName(state, practitioner.personId).charAt(0)}.`
    : ''
}

export function getPractitionerByPersonId(state: RootState, personId: string): Practitioner | null {
  const practitionerId = state.practitioner.personIdToPractitionerIdMap[personId]

  return getPractitioner(state, practitionerId)
}

const getPractitionerMap = (state: RootState) => state.practitioner.practitionerMap

export const getPractitionerIds = createSelector(getPractitionerMap, (byId) => Object.keys(byId))

export function getPractitionerNameAndId(state: RootState, practitionerId: string): PractitionerNameAndId {
  return {
    name: getPractitionerName(state, practitionerId),
    id: practitionerId,
  }
}

export const getAllPractitionerNamesAndIds = (state: RootState): PractitionerNameAndId[] => {
  return getPractitionerIds(state)
    .map((id) => getPractitionerNameAndId(state, id))
    .sort(({ name: a }, { name: b }) => {
      a = a.toLowerCase()
      b = b.toLowerCase()

      if (a < b) {
        return -1
      }

      if (a > b) {
        return 1
      }

      return 0
    })
}

export const getAllPractitioners = createSelector(getPractitionerMap, (practitionerMap) =>
  Object.values(practitionerMap),
)

export const getPractitionerPhotoUrl = (
  state: RootState,
  id: string,
  size: PractitionerPhotoSize = PractitionerPhotoSize.MEDIUM,
): string => {
  return state.practitioner.practitionerMap[id]?.images[size] ?? ''
}

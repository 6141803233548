import clsx from 'clsx'
import { DetailedHTMLProps, LabelHTMLAttributes, ReactElement } from 'react'

interface Props extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  invalid?: boolean
  order?: number
  required?: boolean
  warning?: boolean
}

const FormLabel = ({ children, className, invalid, order = 1, required, warning, ...props }: Props): ReactElement => {
  return (
    <label
      className={clsx('riva-form-label mb-3 ml-1 text-xxs leading-4 font-semibold capitalize', className, {
        'riva-invalid text-rivaFuchsia-500 riva-invalid': invalid,
        'riva-warning text-rivaYellow-600': !invalid && warning,
        'text-rivaOffblack-500': !invalid && !warning,
        'order-1': order === 1,
      })}
      {...props}
    >
      {children}
      {required ? <span className="text-rivaFuchsia-500"> *</span> : null}
    </label>
  )
}

export default FormLabel

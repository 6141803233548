import { produce } from 'immer'
import { Reducer, combineReducers } from 'redux'
import { RecordState } from '../../types/record-state'
import {
  FetchingBloodPressureMeasurements,
  FETCHING_BLOOD_PRESSURE_MEASUREMENTS,
  FetchBloodPressureMeasurementsFailed,
  FetchBloodPressureMeasurementsFulfilled,
  FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED,
  FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED,
  BloodPressureMeasurementState,
  BloodPressureMeasurementMap,
  BloodPressureSetIdToBloodPressureMeasurementIdMap,
  BloodPressureMeasurementType,
  UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED,
  UpdateBloodPressureMeasurementFulfilled,
} from '../../types/blood-pressure-measurement'

type BloodPressureMeasurementActions =
  | FetchingBloodPressureMeasurements
  | FetchBloodPressureMeasurementsFulfilled
  | FetchBloodPressureMeasurementsFailed
  | UpdateBloodPressureMeasurementFulfilled

const recordStateByListId = produce((draft: Record<string, RecordState>, action: BloodPressureMeasurementActions) => {
  switch (action.type) {
    case FETCHING_BLOOD_PRESSURE_MEASUREMENTS:
      draft[action.payload.listId] = RecordState.LOADING
      break
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED:
      draft[action.payload.listId] = RecordState.LOADED
      break
    case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FAILED:
      draft[action.payload.listId] = RecordState.ERRORED
      break
    default:
      return draft
  }
}, {})

const bloodPressureMeasurementMap = produce(
  (draft: BloodPressureMeasurementMap, action: BloodPressureMeasurementActions) => {
    switch (action.type) {
      case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED: {
        const { measurements } = action.payload

        measurements.forEach((bloodPressureMeasurement) => {
          draft[bloodPressureMeasurement.id] = bloodPressureMeasurement
        })

        return
      }
      case UPDATE_BLOOD_PRESSURE_MEASUREMENT_FULFILLED: {
        const { bloodPressureMeasurement } = action.payload

        draft[bloodPressureMeasurement.id] = bloodPressureMeasurement

        return
      }
    }
  },
  {},
)

const bloodPressureSetIdToBloodPressureMeasurementIdMap = produce(
  (draft: BloodPressureSetIdToBloodPressureMeasurementIdMap, action: BloodPressureMeasurementActions) => {
    switch (action.type) {
      case FETCH_BLOOD_PRESSURE_MEASUREMENTS_FULFILLED: {
        const { measurements } = action.payload
        const newMeasurements: BloodPressureSetIdToBloodPressureMeasurementIdMap = {}

        measurements.forEach((bloodPressureMeasurement) => {
          if (!Object.prototype.hasOwnProperty.call(newMeasurements, bloodPressureMeasurement.bloodPressureSetId)) {
            newMeasurements[bloodPressureMeasurement.bloodPressureSetId] = {
              auscultation: [],
              cuff: [],
              phone: [],
            }
          }

          if (bloodPressureMeasurement.type === BloodPressureMeasurementType.Cuff) {
            newMeasurements[bloodPressureMeasurement.bloodPressureSetId].cuff.push(bloodPressureMeasurement.id)
          } else if (bloodPressureMeasurement.type === BloodPressureMeasurementType.RivaApp) {
            newMeasurements[bloodPressureMeasurement.bloodPressureSetId].phone.push(bloodPressureMeasurement.id)
          }
        })

        // we override the existing value for any new bloodPressureSet that comes in,
        // because we fetch all measurements for an bloodPressureSet at once.
        // if this precondition changes, then we will have to write some merging logic.
        return {
          ...draft,
          ...newMeasurements,
        }
      }
      default:
        return draft
    }
  },
  {},
)

export const bloodPressureMeasurement: Reducer<BloodPressureMeasurementState, BloodPressureMeasurementActions> =
  combineReducers({
    recordStateByListId,
    bloodPressureMeasurementMap,
    bloodPressureSetIdToBloodPressureMeasurementIdMap,
  })

import { createSelector } from 'reselect'
import { RootState } from '../reducers'
import { BloodPressureManagementStatus, PatientMap, PatientStatus, PatientType } from '../../types/patient'
import { getListItemIds } from './lists'

export function getPatientMap(state: RootState): PatientMap {
  return state.patient.patientMap
}

export interface PatientLists {
  activeManagement: string[]
  maintenance: string[]
  onboarding: string[]
  establishingCare: string[]
  inactive: string[]
}

export const getPatientLists = createSelector(
  getPatientMap,
  getListItemIds,
  (patientMap: PatientMap, patientIdsFromList: string[]) => {
    const lists: PatientLists = {
      activeManagement: [],
      maintenance: [],
      onboarding: [],
      establishingCare: [],
      inactive: [],
    }

    // TODO: support more kinds of lists
    const patientIds = patientIdsFromList.filter((patientId) => patientMap[patientId].type === PatientType.MANAGED)

    patientIds.forEach((patientId) => {
      const { status, bloodPressureManagementStatus } = patientMap[patientId]

      if (status === PatientStatus.INACTIVE) {
        lists.inactive.push(patientId)
      } else if (bloodPressureManagementStatus === BloodPressureManagementStatus.ACTIVE_MANAGEMENT) {
        lists.activeManagement.push(patientId)
      } else if (bloodPressureManagementStatus === BloodPressureManagementStatus.ONBOARDING) {
        lists.onboarding.push(patientId)
      } else if (bloodPressureManagementStatus === BloodPressureManagementStatus.ESTABLISHING_CARE) {
        lists.establishingCare.push(patientId)
      } else if (bloodPressureManagementStatus === BloodPressureManagementStatus.MAINTENANCE) {
        lists.maintenance.push(patientId)
      }
    })

    const byLastName = (a: string, b: string) => {
      const patientA = patientMap[a]
      const patientB = patientMap[b]
      if (patientA.lastName < patientB.lastName) {
        return -1
      } else if (patientA.lastName > patientB.lastName) {
        return 1
      } else {
        if (patientA.firstName < patientB.firstName) {
          return -1
        } else {
          return 1
        }
      }
    }

    lists.inactive.sort(byLastName)
    lists.activeManagement.sort(byLastName)
    lists.onboarding.sort(byLastName)
    lists.establishingCare.sort(byLastName)
    lists.maintenance.sort(byLastName)

    return lists
  },
)

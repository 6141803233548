import { ReactElement } from 'react'
import { Option, Select } from '../select'
import { StateAbbreviations, StateNames } from '../../../utils/l10n'

interface Props {
  id: string
  onChange: (state?: StateAbbreviations) => void
  value?: StateAbbreviations
}

const StateSelector = ({ id, onChange, value }: Props): ReactElement => {
  return (
    <Select id={id} onChange={onChange} placeholder="State" value={value}>
      {Object.values(StateAbbreviations).map((state) => {
        return (
          <Option key={state} value={state}>
            {StateNames[state]}
          </Option>
        )
      })}
    </Select>
  )
}

export default StateSelector

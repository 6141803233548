import React from 'react'
import ReactDOM from 'react-dom/client'
import { enableMapSet } from 'immer'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Modal from 'react-modal'
import mixpanel from 'mixpanel-browser'
import { datadogLogs } from '@datadog/browser-logs'
import { ApolloProvider } from '@apollo/client'
import { createStore } from './redux/create-store'
import { rootReducer } from './redux/reducers'
import { App } from './components/app'

import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import apolloClient from './api/apollo-client'
import AuthenticationProvider from './authentication-provider'

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: process.env.REACT_APP_ENV_NAME !== 'prod',
  })
}

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_DATADOG_TOKEN &&
  process.env.GITHUB_EVENT_NAME !== 'pull_request' &&
  process.env.GITHUB_EVENT_NAME !== 'pull_request_target'
) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    site: 'datadoghq.com',
    service: 'riva-web',
    env: process.env.REACT_APP_ENV_NAME,
    version: process.env.GITHUB_REF_NAME,
  })
}

// https://immerjs.github.io/immer/installation
// dummy comment to trigger build
enableMapSet()

const { store, apiClient } = createStore(rootReducer)

const appRoot = document.createElement('div')
appRoot.id = 'app-root'
appRoot.classList.add('h-full')

const drawerRoot = document.createElement('div')
drawerRoot.id = 'drawer-root'

document.body.appendChild(appRoot)
document.body.appendChild(drawerRoot)

/**
 * Hack to get redux dev tools to work with sets:
 * https://github.com/zalmoxisus/redux-devtools-extension/issues/124
 */
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-extend-native
  Set.prototype.toJSON = function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return JSON.stringify([...this])
  }
}

const root = ReactDOM.createRoot(appRoot)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthenticationProvider>
        <Provider store={store}>
          <ApolloProvider client={apolloClient}>
            <App apiClient={apiClient} />
          </ApolloProvider>
        </Provider>
      </AuthenticationProvider>
    </BrowserRouter>
  </React.StrictMode>,
)

Modal.setAppElement('#app-root')

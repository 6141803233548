import { ReactElement } from 'react'
import clsx from 'clsx'
import { ReactComponent as MagicWandIcon } from '../icons/outline/magic-wand.svg'
import { IconSize, SvgIcon } from './icon'
import { AvatarSize } from './practitioner-avatar'

interface Props {
  size?: AvatarSize
}

const SystemAvatar = ({ size = AvatarSize.MEDIUM }: Props): ReactElement => {
  return (
    <div
      className={clsx(
        'bg-rivaBlue-400 rounded-full box-border border-2 border-rivaOffblack-200 flex items-center justify-center text-white overflow-hidden',
        {
          'w-6 h-6': size === AvatarSize.MEDIUM,
          'w-10 h-10': size === AvatarSize.XL,
        },
      )}
    >
      <SvgIcon Icon={MagicWandIcon} size={size === AvatarSize.MEDIUM ? IconSize.X_SMALL : IconSize.MEDIUM} />
    </div>
  )
}

export default SystemAvatar

import { CarePathwayStatus, CarePathwayType } from '../../__generated__/graphql'
import { CarePathway, CarePathwayEvent } from '../../types/care-pathway'
import { RootState } from '../reducers'

export const getCarePathwayById = (state: RootState, id: string): CarePathway | undefined => state.carePathway.byId[id]

export const getCarePathwayEventById = (state: RootState, id: string): CarePathwayEvent | undefined =>
  state.carePathway.carePathwayEventsById[id]

export const getEscalationIdByMeasurementId = (state: RootState, id: string): string | undefined =>
  state.carePathway.escalationIdByMeasurementId[id]

export const findOpenCarePathwayByType = (
  state: RootState,
  patientId: string,
  carePathwayType: CarePathwayType,
): CarePathway | undefined =>
  Object.values(state.carePathway.byId).find(
    (carePathway) =>
      carePathway.type === carePathwayType &&
      carePathway.patientId === patientId &&
      (carePathway.status === CarePathwayStatus.Created || carePathway.status === CarePathwayStatus.InProgress),
  )

const emptyCarePathwayEventIds: string[] = []

export const getCarePathwayEventIdsByCarePathwayId = (state: RootState, carePathwayId: string): string[] =>
  state.carePathway.carePathwayEventIdsByCarePathwayId[carePathwayId] ?? emptyCarePathwayEventIds

export const getCarePathwayEventsByCarePathwayId = (state: RootState, carePathwayId: string): CarePathwayEvent[] => {
  const eventIds = getCarePathwayEventIdsByCarePathwayId(state, carePathwayId)
  const result: CarePathwayEvent[] = []

  eventIds.forEach((id) => {
    const carePathwayEvent = getCarePathwayEventById(state, id)

    if (!carePathwayEvent) {
      return
    }

    result.push(carePathwayEvent)
  })

  return result
}

import { LoadingApp, LoadedApp, FailedAppLoad, APP_LOADING, APP_LOADED, APP_FAILED_TO_LOAD } from '../../types/app'

export function loadingApp(): LoadingApp {
  return {
    type: APP_LOADING,
    payload: undefined,
  }
}

export function loadedApp(loggedInPersonId: string): LoadedApp {
  return {
    type: APP_LOADED,
    payload: {
      loggedInPersonId,
    },
  }
}

export function failedToLoadApp(error: Error): FailedAppLoad {
  return {
    type: APP_FAILED_TO_LOAD,
    payload: {
      error,
    },
  }
}

import {
  AcquisitionChannel,
  BloodPressureManagementStatus,
  DepartmentId,
  MedicalRecordSource,
  PatientType,
  Pronouns,
} from '../types/patient'

export const getDepartmentName = (departmentId: DepartmentId): string => {
  switch (departmentId) {
    case DepartmentId.COLORADO:
      return 'Telehealth, Colorado'
    case DepartmentId.FLORIDA:
      return 'Telehealth, Florida'
  }
}

export const getPatientMedicalRecordSource = (
  acquisitionChannel: AcquisitionChannel | '',
  patientType?: PatientType,
): MedicalRecordSource => {
  if (patientType === PatientType.VIP) {
    return MedicalRecordSource.UNKNOWN
  }

  if (acquisitionChannel === AcquisitionChannel.UCH) {
    return MedicalRecordSource.EPIC
  }

  return MedicalRecordSource.ATHENA
}

export const shouldHaveReferringProvider = (acquisitionChannel: AcquisitionChannel | ''): boolean =>
  acquisitionChannel === AcquisitionChannel.UCH

export const getUpdatedPronouns = (pronouns: string): Pronouns | '' => {
  switch (pronouns) {
    case 'He / Him / His':
    case Pronouns.HE_HIM:
      return Pronouns.HE_HIM
    case 'She / Her / Hers':
    case Pronouns.SHE_HER:
      return Pronouns.SHE_HER
    case 'They / Them / Theirs':
    case Pronouns.THEY_THEM:
      return Pronouns.THEY_THEM
    default:
      return ''
  }
}

export const getListTitleByManagementStatus = (status?: BloodPressureManagementStatus): string => {
  switch (status) {
    case BloodPressureManagementStatus.ACTIVE_MANAGEMENT:
      return 'Active management'
    case BloodPressureManagementStatus.DEACTIVATED:
      return 'Deactivated'
    case BloodPressureManagementStatus.ESTABLISHING_CARE:
      return 'Establishing care'
    case BloodPressureManagementStatus.MAINTENANCE:
      return 'Maintenance'
    case BloodPressureManagementStatus.ONBOARDING:
      return 'Onboarding'
    default:
      return 'All patients'
  }
}

import { ReactElement } from 'react'
import useTabs from '../../../ui/tabs/use-tabs'
import { Tab, TabList, TabPanel } from '../../../ui/tabs'
import { MedicationType } from '../../../../__generated__/graphql'
import MedicationList from './medication-list'

interface Props {
  patientId: string
}

const MedicationsCurrentI13n = {
  eventName: 'Tab - Medications - Current - Clicked',
}
const MedicationsHistoricalI13n = {
  eventName: 'Tab - Medications - Historical - Clicked',
}
const MedicationsDeniedI13n = {
  eventName: 'Tab - Medications - Denied - Clicked',
}

const MedicationsPaidTab = ({ patientId }: Props): ReactElement => {
  const { getTabPanelProps, getTabListProps, getTabProps } = useTabs()

  return (
    <section className="min-h-full flex flex-col overflow-hidden">
      <TabList {...getTabListProps()} className="bg-rivaOffblack-50 flex-none">
        <Tab i13n={MedicationsCurrentI13n} {...getTabProps(0)}>
          Current
        </Tab>
        <Tab i13n={MedicationsHistoricalI13n} {...getTabProps(1)}>
          Historical
        </Tab>
        <Tab i13n={MedicationsDeniedI13n} {...getTabProps(2)}>
          Denied
        </Tab>
      </TabList>
      <TabPanel {...getTabPanelProps(0)} className="flex-1 overflow-auto">
        <MedicationList medicationType={MedicationType.Active} patientId={patientId} />
      </TabPanel>
      <TabPanel {...getTabPanelProps(1)} className="flex-1 overflow-auto">
        <MedicationList medicationType={MedicationType.Historical} patientId={patientId} />
      </TabPanel>
      <TabPanel {...getTabPanelProps(2)} className="flex-1 overflow-auto">
        <MedicationList medicationType={MedicationType.Denied} patientId={patientId} />
      </TabPanel>
    </section>
  )
}

export default MedicationsPaidTab

import { produce } from 'immer'
import { combineReducers, Reducer } from 'redux'
import { Action, FETCH_SURVEY_RULES_FAILED, FETCH_SURVEY_RULES_SUCCEEDED } from '../../../types/actions'
import { RecordState } from '../../../types/record-state'
import { SurveyRule } from '../../../types/survey'

const recordState: Reducer<RecordState, Action> = (state = RecordState.DOES_NOT_EXIST, action) => {
  switch (action.type) {
    case FETCH_SURVEY_RULES_SUCCEEDED:
      return RecordState.LOADED
    case FETCH_SURVEY_RULES_FAILED:
      return RecordState.ERRORED
    default:
      return state
  }
}

type ByIdState = Record<string, SurveyRule>

const byId: Reducer<ByIdState, Action> = produce((draft = {}, action) => {
  switch (action.type) {
    case FETCH_SURVEY_RULES_SUCCEEDED: {
      const { rules } = action.payload

      rules.forEach((surveyRule) => {
        draft[surveyRule.id] = surveyRule
      })

      return
    }
  }
}, {})

const surveyRules = combineReducers({
  byId,
  recordState,
})

export default surveyRules

import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes } from 'react'

type Props = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

const TextArea = forwardRef<HTMLTextAreaElement, Props>(function TextArea({ className, ...props }, ref) {
  return (
    <textarea
      className={clsx(
        'peer riva-input order-2 border border-rivaOffblack-300 hover:border-rivaOffblack focus:border-rivaPurple disabled:border-rivaOffblack-300 disabled:text-rivaOffblack-500 disabled:bg-rivaOffblack-100 rounded px-3 py-2 text-sm text-rivaOffblack-900 box-border outline-none',
        className,
      )}
      {...props}
      ref={ref}
    />
  )
})

export default TextArea

import type { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { compile } from 'path-to-regexp'
import { PractitionerResponse } from '../types/practitioner'
import {
  AcquisitionChannel,
  BloodPressureManagementStatus,
  CreatePatientPayload,
  Patient,
  PatientResponse,
  PatientStatus,
  PatientType,
  UpdatePatientPayload,
} from '../types/patient'
import { Note } from '../types/note'
import { BloodPressureSet } from '../types/blood-pressure-set'
import { Organization } from '../types/organization'
import { Site } from '../types/site'
import { BloodPressureMeasurement, BloodPressureMeasurementType } from '../types/blood-pressure-measurement'
import { Person } from '../types/person'
import { ChatTokenResponse } from '../types/chat'
import { BloodPressureAveragesResponse } from '../types/blood-pressure-moving-average'
import { GetSurveyRulesResponse, SurveyRuleStyle } from '../types/survey'
import { Device } from '../types/device'

import { uuid } from '../utils/uuid'
import { createApiLogger } from '../logger'
import * as http from './http'
import { HttpResponse } from './http'

interface GetPatientsOptions {
  acquisitionChannels?: AcquisitionChannel[]
  bloodPressureManagementStatus?: BloodPressureManagementStatus
  limit?: number
  offset?: number
  name?: string
  patientIds?: string[]
  patientType?: PatientType[]
  practitionerIds?: string[]
}

export interface IApiClient {
  setAccessTokenGetter(getter: () => Promise<string>): void

  getOrganization(organizationId: string): Promise<Organization>

  getSites(organizationIds: string[]): Promise<Site[]>

  getPractitionerByPersonId(personId: string): Promise<PractitionerResponse>

  getPractitionerByOrganizationIds(organizationIds: string[]): Promise<PractitionerResponse>

  getPerson(personId: string): Promise<Person>

  getPatients(options: GetPatientsOptions): Promise<PatientResponse>

  getPatientById(id: string): Promise<{
    patient: Patient
  }>

  getPatientByChatChannel(channelUrl: string): Promise<{ patient: Patient }>

  getNotesForPatient(patientId: string): Promise<Note[]>

  getBloodPressureSetsForPatient(patientId: string): Promise<BloodPressureSet[]>

  getBloodPressureMeasurementsForPatient(
    patientId: string,
    measurementType?: BloodPressureMeasurementType,
  ): Promise<BloodPressureMeasurement[]>

  /**
   * Create a note about a patient.
   */
  createNoteForPatient(text: string, patientId: string): Promise<Note>

  updateNote(noteId: string, text: string): Promise<Note>

  createPatient(payload: CreatePatientPayload): Promise<Patient>

  updatePatientStatusWithNote(
    patientId: string,
    text: string,
    status: PatientStatus,
    bloodPressureManagementStatus: BloodPressureManagementStatus,
  ): Promise<{
    note: Note
    patient: Patient
  }>

  deactivatePatient(patientId: string): Promise<Patient>

  getCuffMeasurementDownloadSignedUrl(measurementId: string): Promise<{ signedUrl: string; expirationTime: string }>

  getChatToken(): Promise<ChatTokenResponse>

  updatePatient(patientId: string, payload: UpdatePatientPayload): Promise<Patient>

  getPatientBloodPressureMovingAverages(patientId: string): Promise<BloodPressureAveragesResponse>

  getSurveyRules(): Promise<GetSurveyRulesResponse>

  assignDeviceToPatient(patientId: string, deviceId: string): Promise<Device>
  unassignDeviceFromPatient(patientId: string, deviceId: string): Promise<void>
}

interface RequestOptions {
  params?: Record<string, string>
  query?: Record<string, string[] | string>
}

export class ApiClient implements IApiClient {
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>

  constructor() {
    this.getAccessTokenSilently = async () => ''
  }

  setAccessTokenGetter(getter: (options?: GetTokenSilentlyOptions) => Promise<string>): void {
    this.getAccessTokenSilently = getter
  }

  private async getAccessToken(): Promise<string> {
    return this.getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      },
    })
  }

  private async httpGet(url: string, { params, query }: RequestOptions): Promise<HttpResponse> {
    const accessToken = await this.getAccessToken()
    const requestId = uuid()
    const logger = createApiLogger('GET', url, {
      http: {
        request_id: requestId,
      },
    })
    const toPath = compile(url, { encode: encodeURIComponent })
    let path = toPath(params)

    if (query && Object.keys(query).length > 0) {
      const searchParams = new URLSearchParams()

      Object.keys(query).forEach((key) => {
        const value = query[key]
        if (Array.isArray(value)) {
          value.forEach((v) => {
            searchParams.append(key, v)
          })
        } else {
          searchParams.append(key, value)
        }
      })

      path += '?' + searchParams
    }

    try {
      const response = await http.get(path, { accessToken, requestId })

      logger.logSuccess({
        statusCode: response.statusCode,
      })

      return response
    } catch (error) {
      logger.logFailure(error)

      throw error
    }
  }

  private async httpPost(url: string, body: unknown, { params, query }: RequestOptions): Promise<HttpResponse> {
    const accessToken = await this.getAccessToken()
    const requestId = uuid()
    const logger = createApiLogger('GET', url, {
      http: {
        request_id: requestId,
      },
    })
    const toPath = compile(url, { encode: encodeURIComponent })
    let path = toPath(params)

    if (query && Object.keys(query).length > 0) {
      const searchParams = new URLSearchParams()

      Object.keys(query).forEach((key) => {
        const value = query[key]
        if (Array.isArray(value)) {
          value.forEach((v) => {
            searchParams.append(key, v)
          })
        } else {
          searchParams.append(key, value)
        }
      })

      path += '?' + searchParams
    }

    try {
      return http.post(path, body, { accessToken, requestId })
    } catch (error) {
      logger.logFailure(error)

      throw error
    }
  }

  private async httpPatch(url: string, body: unknown, { params, query }: RequestOptions): Promise<HttpResponse> {
    const accessToken = await this.getAccessToken()
    const requestId = uuid()
    const logger = createApiLogger('GET', url, {
      http: {
        request_id: requestId,
      },
    })
    const toPath = compile(url, { encode: encodeURIComponent })
    let path = toPath(params)

    if (query && Object.keys(query).length > 0) {
      const searchParams = new URLSearchParams()

      Object.keys(query).forEach((key) => {
        const value = query[key]
        if (Array.isArray(value)) {
          value.forEach((v) => {
            searchParams.append(key, v)
          })
        } else {
          searchParams.append(key, value)
        }
      })

      path += '?' + searchParams
    }

    try {
      return http.patch(path, body, { accessToken, requestId })
    } catch (error) {
      logger.logFailure(error)

      throw error
    }
  }

  private async httpPut(url: string, body: unknown, { params, query }: RequestOptions): Promise<HttpResponse> {
    const accessToken = await this.getAccessToken()
    const requestId = uuid()
    const logger = createApiLogger('GET', url, {
      http: {
        request_id: requestId,
      },
    })
    const toPath = compile(url, { encode: encodeURIComponent })
    let path = toPath(params)

    if (query && Object.keys(query).length > 0) {
      const searchParams = new URLSearchParams()

      Object.keys(query).forEach((key) => {
        const value = query[key]
        if (Array.isArray(value)) {
          value.forEach((v) => {
            searchParams.append(key, v)
          })
        } else {
          searchParams.append(key, value)
        }
      })

      path += '?' + searchParams
    }

    try {
      return http.put(path, body, { accessToken, requestId })
    } catch (error) {
      logger.logFailure(error)

      throw error
    }
  }

  private async httpDelete(url: string, { params, query }: RequestOptions): Promise<HttpResponse> {
    const accessToken = await this.getAccessToken()
    const requestId = uuid()
    const logger = createApiLogger('GET', url, {
      http: {
        request_id: requestId,
      },
    })
    const toPath = compile(url, { encode: encodeURIComponent })
    let path = toPath(params)

    if (query && Object.keys(query).length > 0) {
      const searchParams = new URLSearchParams()

      Object.keys(query).forEach((key) => {
        const value = query[key]
        if (Array.isArray(value)) {
          value.forEach((v) => {
            searchParams.append(key, v)
          })
        } else {
          searchParams.append(key, value)
        }
      })

      path += '?' + searchParams
    }

    try {
      return http.del(path, { accessToken, requestId })
    } catch (error) {
      logger.logFailure(error)

      throw error
    }
  }

  /**
   * @param organizationId The organization to get the organization of
   * @returns Promise<Organization>
   */
  async getOrganization(organizationId: string): Promise<Organization> {
    const { body } = await this.httpGet('/v1/organization/:organizationId', {
      params: {
        organizationId,
      },
    })

    return body as Organization
  }

  /**
   * @param organizationIds string[] The organizations that we want to get sites for
   * @returns Promise<Site[]> A list of sites associated with an organization
   */
  async getSites(organizationIds: string[]): Promise<Site[]> {
    const { body } = await this.httpGet('/v1/site', {
      query: {
        organizationIds,
      },
    })

    return body as Site[]
  }

  /**
   * @param personId The person that we want to get practitioner for
   * @returns Promise<Practitioner> The practitioner that this person is a part of
   */
  async getPractitionerByPersonId(personId: string): Promise<PractitionerResponse> {
    const { body } = await this.httpGet('/v1/practitioner', {
      query: {
        personId,
      },
    })

    return body as PractitionerResponse
  }

  /**
   * @param organizationIds The organizations that we want to get practitioner for
   * @returns Promise<Practitioner> The practitioner that belongs to the orgs that this person is a part of
   */
  async getPractitionerByOrganizationIds(organizationIds: string[]): Promise<PractitionerResponse> {
    const { body } = await this.httpGet('/v1/practitioner', {
      query: {
        organizationIds,
      },
    })

    return body as PractitionerResponse
  }

  async getPerson(personId: string): Promise<Person> {
    const { body } = await this.httpGet('/v1/person/:personId', {
      params: {
        personId,
      },
    })

    return body as Person
  }

  async getPatients({
    acquisitionChannels,
    bloodPressureManagementStatus,
    limit,
    offset,
    name,
    patientIds,
    patientType,
    practitionerIds,
  }: GetPatientsOptions): Promise<PatientResponse> {
    const query: Record<string, string[] | string> = {}

    if (acquisitionChannels?.length) {
      query.acquisitionChannel = acquisitionChannels
    }

    if (bloodPressureManagementStatus) {
      query.bloodPressureManagementStatus = bloodPressureManagementStatus
    }

    if (practitionerIds?.length) {
      query.practitionerId = practitionerIds
    }

    if (typeof limit === 'number') {
      query.limit = String(limit)
    }

    if (typeof offset === 'number') {
      query.offset = String(offset)
    }

    if (patientType?.length) {
      query.type = patientType
    }

    if (name) {
      query.name = name
    }

    const { body } = await this.httpGet('/v1/patient', {
      query,
    })

    let filteredPatients = (body as PatientResponse).patients ?? []

    if (patientIds?.length) {
      filteredPatients = filteredPatients.filter((patient: Patient) => patientIds.includes(patient.id))
    }

    if (practitionerIds?.length) {
      filteredPatients = filteredPatients.filter((patient: Patient) => {
        if (!patient.careTeam) {
          return false
        }

        return (
          practitionerIds.includes(patient.careTeam.coachId) ||
          practitionerIds.includes(patient.careTeam.pharmacistId) ||
          practitionerIds.includes(patient.careTeam.prescriberId)
        )
      })
    }

    if (acquisitionChannels?.length) {
      filteredPatients = filteredPatients.filter((patient: Patient) =>
        acquisitionChannels.includes(patient.acquisitionChannel as AcquisitionChannel),
      )
    }

    return {
      count: filteredPatients.length,
      patients: filteredPatients,
      totalCount: (body as PatientResponse).totalCount,
    }
  }

  async getPatientById(id: string): Promise<{ patient: Patient }> {
    const { body } = await this.httpGet('/v1/patient/:id', {
      params: {
        id,
      },
    })

    return body as { patient: Patient }
  }

  async getPatientByChatChannel(channelUrl: string): Promise<{ patient: Patient }> {
    const { body } = await this.httpGet('/v1/chat/channel/:channelUrl/patient', {
      params: {
        channelUrl,
      },
    })

    return body as { patient: Patient }
  }

  async getNotesForPatient(patientId: string): Promise<Note[]> {
    const { body } = await this.httpGet('/v1/note', {
      query: {
        patientId,
      },
    })

    return body as Note[]
  }

  async getBloodPressureSetsForPatient(patientId: string): Promise<BloodPressureSet[]> {
    const { body } = await this.httpGet('/v1/blood-pressure-set', {
      query: {
        patientId,
      },
    })

    return (body ?? []) as BloodPressureSet[]
  }

  async getBloodPressureMeasurementsForPatient(
    patientId: string,
    measurementType?: BloodPressureMeasurementType,
  ): Promise<BloodPressureMeasurement[]> {
    const query: Record<string, string> = {
      patientId,
      limit: '1000',
    }

    if (measurementType) {
      query.measurementType = measurementType
    }

    const { body } = await this.httpGet('/v1/blood-pressure-measurement', {
      query,
    })

    return (body ?? []) as BloodPressureMeasurement[]
  }

  /**
   * Create a note about a patient.
   */
  async createNoteForPatient(text: string, patientId: string): Promise<Note> {
    const { body } = await this.httpPost(
      `/v1/note`,
      {
        text,
        patientId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // returns the IANA timezone
      },
      {},
    )

    return body as Note
  }

  async updateNote(noteId: string, text: string): Promise<Note> {
    const { body } = await this.httpPatch(
      '/v1/note/:noteId',
      { text },
      {
        params: {
          noteId,
        },
      },
    )

    return body as Note
  }

  async createPatient(payload: CreatePatientPayload): Promise<Patient> {
    const { body } = await this.httpPost('/v1/patient', payload, {})

    return body as Patient
  }

  async updatePatientStatusWithNote(
    patientId: string,
    text: string,
    status: PatientStatus,
    bloodPressureManagementStatus: BloodPressureManagementStatus,
  ): Promise<{
    note: Note
    patient: Patient
  }> {
    const note = await this.createNoteForPatient(text, patientId)
    const patient = await this.updatePatient(patientId, {
      status,
      bloodPressureManagementStatus,
    })

    return { note, patient }
  }

  async deactivatePatient(patientId: string): Promise<Patient> {
    const { body } = await this.httpPut(
      '/v1/patient/:patientId/deactivate',
      {},
      {
        params: {
          patientId,
        },
      },
    )

    return body as Patient
  }

  async getCuffMeasurementDownloadSignedUrl(
    measurementId: string,
  ): Promise<{ signedUrl: string; expirationTime: string }> {
    const { body } = await this.httpPost('/v1/cuff-measurement/download', { measurementId }, {})

    return body as { signedUrl: string; expirationTime: string }
  }

  async getChatToken(): Promise<ChatTokenResponse> {
    const { body } = await this.httpPost('/v1/chat/token', {}, {})

    return body as ChatTokenResponse
  }

  async updatePatient(patientId: string, payload: UpdatePatientPayload): Promise<Patient> {
    const { body } = await this.httpPatch('/v1/patient/:patientId', payload, {
      params: {
        patientId,
      },
    })

    return body as Patient
  }

  async getPatientBloodPressureMovingAverages(patientId: string): Promise<BloodPressureAveragesResponse> {
    const { body } = await this.httpGet('/v2/patient/:patientId/bloodPressureMovingAverage', {
      params: {
        patientId,
      },
    })

    return body as BloodPressureAveragesResponse
  }

  async getSurveyRules(): Promise<GetSurveyRulesResponse> {
    const { rules } = (await import('./survey-rules.json' /* webpackChunkName: "survey-rules" */)).default
    const response: GetSurveyRulesResponse = {
      rules: rules.map(({ style, ...rule }, i) => {
        if (style !== 'none' && style !== 'highlight' && style !== 'alert') {
          throw new TypeError(`Invalid survey rule style: ${style}`)
        }

        return {
          ...rule,
          style: style as SurveyRuleStyle,
          id: String(i),
        }
      }),
    }

    return response
  }

  async assignDeviceToPatient(patientId: string, deviceId: string): Promise<Device> {
    const { body } = await this.httpPut(
      '/v1/device/:deviceId',
      {
        patientId,
      },
      {
        params: {
          deviceId,
        },
      },
    )

    return body as Device
  }

  async unassignDeviceFromPatient(patientId: string, deviceId: string): Promise<void> {
    await this.httpDelete('/v1/patient/:patientId/device/:deviceId', {
      params: {
        deviceId,
        patientId,
      },
    })
  }
}

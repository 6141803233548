import { MenuAlign, MenuModifiers } from '@szhsin/react-menu'
import clsx from 'clsx'

export const getMenuClassName = ({ dir }: MenuModifiers, align?: MenuAlign): string =>
  clsx('border border-solid border-rivaOffblack-300 shadow-xl', {
    'animate-appear-right-down origin-top-left': dir === 'bottom' && align === 'start',
    'animate-appear-left-down origin-top-right': dir === 'bottom' && align === 'end',
    'animate-appear-right-up origin-bottom-left': dir === 'top' && align === 'start',
    'animate-scale-fade origin-bottom-right': dir === 'top' && align === 'end',
    'animate-appear-down': dir === 'bottom' && !align,
    'animate-appear-up': dir === 'top' && !align,
  })

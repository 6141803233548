import { ReactElement, useCallback } from 'react'
import Mustache from 'mustache'
import { useAppSelector } from '../../../../redux'
import { getTaskById } from '../../../../redux/selectors/tasks'
import { ButtonVariant } from '../../../ui/button'
import CopyButton from '../../../ui/copy-button'
import { TASK_LIST_ITEM_COPY } from '../../../../consts/data-testids'
import { track } from '../../../../i13n'
import useGetTemplateData from './use-get-template-data'

interface Props {
  taskId: string
}

const compiled = new Set<string>()

const TaskCopy = ({ taskId }: Props): ReactElement | null => {
  const task = useAppSelector((state) => getTaskById(state, taskId))
  const templateData = useGetTemplateData({ taskId })
  const taskCopy = task?.copy ?? ''
  let renderedTaskCopy = taskCopy

  const onClick = useCallback(() => {
    track('Task Copy Message Clicked')
  }, [])

  try {
    if (!compiled.has(taskCopy)) {
      compiled.add(taskCopy)

      Mustache.parse(taskCopy)
    }

    renderedTaskCopy = Mustache.render(taskCopy, templateData)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }

  return (
    <div className="border border-rivaOffblack-200 bg-rivaOffblack-50 rounded px-5 py-4 text-sm mt-4">
      <div className="whitespace-pre-wrap break-words" data-testid={TASK_LIST_ITEM_COPY}>
        {renderedTaskCopy}
      </div>
      <div className="flex justify-end mt-4">
        <CopyButton onClick={onClick} text={renderedTaskCopy} variant={ButtonVariant.SECONDARY} />
      </div>
    </div>
  )
}

export default TaskCopy

import { RecordState } from './record-state'

export const FETCHING_SITES = 'FETCHING_SITES'
export const FETCH_SITES_FULFILLED = 'FETCH_SITES_FULFILLED'
export const FETCH_SITES_FAILED = 'FETCH_SITES_FAILED'

export interface Site {
  id: string
  name: string
  organizationId: string
}

export interface SiteState {
  recordState: RecordState
  siteMap: SiteMap
}

export interface SiteMap {
  [siteId: string]: Site
}

export interface FetchingSites {
  type: typeof FETCHING_SITES
  payload: undefined
}

export interface FetchSitesFulfilled {
  type: typeof FETCH_SITES_FULFILLED
  payload: {
    sites: Site[]
  }
}

export interface FetchSitesFailed {
  type: typeof FETCH_SITES_FAILED
  payload: {
    error: Error
  }
}

import Ajv from 'ajv'
import { TaskEvent, TaskStatus } from '../../../types/task'
import * as http from '../../http'
import { taskEventSchema } from '../../schema/task-event'
import { createApiLogger } from '../../../logger'
import { uuid } from '../../../utils/uuid'
import { TaskEventType } from '../../../__generated__/graphql'

const ajv = new Ajv()

const validate = ajv.compile(taskEventSchema)

interface TaskEventPayload {
  carePathwayId: string
  data: Record<string, unknown>
  eventType: string
  note?: string
  patientId: string
}

interface AssignTaskEventPayload extends TaskEventPayload {
  eventType: TaskEventType.AssignTask
  data: {
    assignee: string
  }
}

interface UpdateStatusEventPayload extends TaskEventPayload {
  eventType: TaskEventType.UpdateTaskStatus
  data: {
    taskStatus: TaskStatus
  }
}

interface SetDueDateEventPayload extends TaskEventPayload {
  eventType: TaskEventType.SetDueDate
  data: {
    dueDate: string
  }
}

interface AddNotePayload extends TaskEventPayload {
  eventType: TaskEventType.AddNote
  data: {
    body: string
  }
}

interface CancelPreviousEventPayload extends TaskEventPayload {
  eventType: TaskEventType.CancelEvent
  data: {
    eventId: string
  }
}

type CreateEventPayload =
  | AssignTaskEventPayload
  | UpdateStatusEventPayload
  | SetDueDateEventPayload
  | AddNotePayload
  | CancelPreviousEventPayload

const createEvent = async (taskId: string, event: CreateEventPayload, accessToken: string): Promise<TaskEvent> => {
  const requestId = uuid()
  const logger = createApiLogger('POST', '/v1/task/:taskId/events', {
    http: {
      request_id: requestId,
    },
  })

  try {
    const { body, statusCode } = await http.post(`/v1/task/${taskId}/events`, event, { accessToken, requestId })

    if (!validate(body)) {
      const { message, keyword, instancePath, schemaPath, propertyName } = validate.errors?.[0] ?? {}

      throw new http.ApiError(statusCode, '', `API Parse error: ${message}`, {
        keyword,
        instancePath,
        schemaPath,
        propertyName,
      })
    }

    logger.logSuccess({
      statusCode,
    })

    return body
  } catch (error) {
    logger.logFailure(error)

    throw error
  }
}

export default createEvent

import { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../redux'
import { getTaskOptions, getTaskSummary, isTaskClosed } from '../../../../../redux/selectors/tasks'
import { Button, ButtonVariant } from '../../../../ui/button'
import useTasksApi from '../../../../../api/hooks/use-tasks-api'
import { selectTaskChoice } from '../../../../../redux/thunks/tasks'
import { getStatusText } from '../../../../../utils/tasks'
import { TaskStatus } from '../../../../../__generated__/graphql'
import { track } from '../../../../../i13n'

interface Props {
  listId: string
  taskId: string
}

const TaskOptions = ({ listId, taskId }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const tasksApi = useTasksApi()
  const options = useAppSelector((state) => getTaskOptions(state, taskId))
  const summary = useAppSelector((state) => getTaskSummary(state, taskId))
  const taskClosed = useAppSelector((state) => isTaskClosed(state, taskId))

  return (
    <div>
      {options.map((option, i) => {
        return (
          <div className="flex justify-end" key={i}>
            {option.choices.map(({ event, label }, j) => {
              return (
                <Button
                  className="ml-2 first:ml-0"
                  disabled={taskClosed}
                  key={j}
                  onClick={() => {
                    dispatch(
                      selectTaskChoice(
                        {
                          id: taskId,
                          listId,
                          event,
                        },
                        tasksApi,
                      ),
                    )

                    track('Task Status Updated', {
                      'Task ID': taskId,
                      'Task Input': label,
                      'Task Status': getStatusText(TaskStatus.TaskCompleted),
                      'Task Title': summary,
                      Status: getStatusText(TaskStatus.TaskCompleted),
                      View: 'Task List',
                    })
                  }}
                  variant={ButtonVariant.SECONDARY}
                >
                  {label}
                </Button>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default TaskOptions
